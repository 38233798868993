import { calcSeverity } from './calcSeverity'

import { formatCurrency } from './formatCurrency'
import { OriginationModelReworked } from '@generated/graphql'
import { CashFlow } from '../../../../../../../store/types/OriginationModelRedux'
import { calcArrayAverage, regenerateCashFlow } from 'origination-model'
import { ReduxHelper } from '../../../../../../../store'

const cloneDeep = require('lodash.clonedeep')
export function calcNOIFCFV(cashFLow: CashFlow, settings: any, model: OriginationModelReworked) {
    const out: any = {}
    out.columnTitles = settings.columnTitles
    out.rowTitles = settings.rowTitles
    out.xAxisName = settings.xAxisName
    out.yAxisName = settings.yAxisName
    out.data = []

    for (let i = 0; i < settings.rowValues.length; i++) {
        //   model.cashFlow[0].
        const row = []
        for (let j = 0; j < settings.columnValues.length; j++) {
            row.push(calcCell(cashFLow, settings.columnValues[j], settings.rowValues[i], model))
        }
        out.data.push(row)
    }
    calcSeverity(out.data)
    return out
}
function calcCell(cashFlow: any, vacancy: number, rentGrowth: number, model: OriginationModelReworked) {
    let newModel: OriginationModelReworked = cloneDeep(model)
    const years = newModel.underwritingAssumption.organicRentGrowth.length
    newModel.underwritingAssumption.organicRentGrowth = new Array(years).fill(rentGrowth)
    newModel.underwritingAssumption.vacancy = new Array(years).fill(vacancy)
    const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
    let cashFlowData = regenerateCashFlow({ cashFlow, model: newModel, unitMix: unitMix })
    const noi = calcArrayAverage(cashFlowData.noi.years)
    const cf = calcArrayAverage(cashFlowData.cashFlowAfterDebtService.years)
    cashFlowData = null
    newModel = null
    return {
        value: `${formatCurrency(noi)} / ${formatCurrency(cf)}`,
        sValue: cf,
        severity: 0.5,
    }
}
