import React, { useCallback, useMemo } from 'react'
import { AddRow } from './AddRow'
import { TextField } from '../../components/TextField'
import { NumberField, NumberFieldFormat } from '../../components/NumberField'
import { useField, useForm } from 'react-final-form'
import { CashFlowRow } from '../../../../../store/types/OriginationModelRedux'
import numeral from 'numeral'
type Props = {
    fieldName?: string
    readOnly?: boolean
    addRowGroup?: string
    pos?: number
    onRemove?: (pos: number) => void
    className?: string
    onlyTitles?: boolean
    inverseValues?: boolean
    isNewDev?: boolean
    showTxAndAdjusted?: boolean
    showTrend?: boolean
    numberFormat?: any
    loanTerm?: number
    interestOnly?: number
    isRefiTable?: boolean
    isPDF?: boolean
}

export const CashFlowRowComp = React.memo(function CashFlowRowComp(props: Props) {
    const form = useForm()
    const fieldName = `${props.fieldName}${props.pos != null ? `[${props.pos}]` : ''}`
    const field = useField(fieldName)
    const row = field.input.value
    const inverseValues = props?.inverseValues //&& row.originalCFGroup != 'fcfBeforeDebt'
    const readOnlyForm = form.getState().values.readOnly
    const numberFormat = props.numberFormat
    const tableLength = row.years?.length || 0
    const loanTerm = props.loanTerm
    const interestOnly = props.interestOnly
    const isRefiTable = props.isRefiTable
    const cellWidth = useMemo(() => {
        return props.isPDF ? '150px' : '200px'
    }, [props.isPDF])
    //if (row.id == 'taxes')
    //console.log('row', row.id, row, row.apr, row.originalApr)

    if (row.id && row.id.indexOf('t12-adjustment-new-row') >= 0) {
        row.originalApr = 0
    }
    const addRow = useCallback(() => {
        if (props.addRowGroup) {
            form.mutators.push(props.addRowGroup, {
                id: '',
                title: '',
                apr: 0,
                originalApr: 0,
                years: new Array(tableLength).fill(0),
                isNew: true,
            })
        }
    }, [tableLength])
    const removeRow = useCallback(() => {
        if (props.onRemove) {
            props.onRemove(props.pos)
        }
    }, [props.onRemove, props.pos])

    const toggleField = useField(`computed.rowExpanded${props.pos}`)
    const toggleRow = useCallback(() => {
        const value = toggleField.input.value || false
        form.change(`computed.rowExpanded${props.pos}`, !value)
    }, [toggleField])
    const isExpanded = toggleField.input.value || false

    const adjustment = useMemo(() => {
        if (!row?.subRows) return { apr: 0, years: [] }
        const apr = row.subRows?.reduce((apr: number, item: CashFlowRow) => {
            apr += item.apr
            return apr
        }, 0)

        const years = row.subRows?.reduce((acc: number[], item: CashFlowRow) => {
            for (const i in item.years) {
                acc[i] += item.years[i]
            }
            return acc
        }, new Array(row.years.length).fill(0))

        return {
            apr: Math.round(row.apr - apr),
            years: years?.map((item, i) => {
                return Math.round(row?.years[i] - item)
            }, []),
        }
    }, [row?.apr, row?.years])

    const { trendIdx, trendType } = useMemo(() => {
        if (!props.showTrend) return { trendIdx: null, trendType: null }
        if (row.id == 'rentPremiumRenovation' || row.id == 'vacancyRenovation') return { trendIdx: -1, trendType: null }
        const cfTrends = form.getState().values.cfTrends
        if (!cfTrends) return { trendIdx: null, trendType: null }
        const trendIdx = cfTrends?.trends?.findIndex((t) => t.id == row.id)
        //console.log(row.id, trendIdx, 'cfTrends', cfTrends)
        const trendType: NumberFieldFormat = 'percent' //row.id == 'replacementReserves' ? 'unit' : 'percent'
        return { trendIdx, trendType }
    }, [props.showTrend, row])
    return (
        <>
            <React.Fragment>
                {!props.isPDF && props.addRowGroup && !readOnlyForm && (
                    <AddRow onClick={addRow} hideButton={props.onlyTitles} />
                )}
                <tr className={`${props.className} ${row.id} `}>
                    {props.onlyTitles && (
                        <React.Fragment>
                            <td className={'title'} colSpan={2}>
                                <div onClick={toggleRow}>
                                    {!props.isPDF && row.subRows && (
                                        <i className="material-icons-outlined">
                                            {isExpanded ? 'expand_less' : 'expand_more'}
                                        </i>
                                    )}
                                    <TextField
                                        name={`${fieldName}.title`}
                                        readOnly={props.readOnly}
                                        containerClassName={row.subRows ? 'expandable' : ''}
                                    />
                                </div>
                            </td>
                            {/*<td style={{ width: 0 }}></td>*/}

                            {!props.isPDF && props.showTxAndAdjusted && (
                                <td className={row.id} colSpan={2}>
                                    {/*{props?.readOnly && }*/}

                                    {props.isNewDev ? (
                                        ''
                                    ) : (
                                        <NumberField
                                            name={`${fieldName}.originalApr`}
                                            numberFormat={'currency'}
                                            readOnly={props.readOnly}
                                            inverseValues={inverseValues}
                                        />
                                    )}
                                </td>
                            )}
                            {!props.isPDF && props.showTxAndAdjusted && (
                                <td className={row.id} colSpan={2}>
                                    {/*{props?.readOnly && }*/}

                                    <NumberField
                                        name={`${fieldName}.apr`}
                                        numberFormat={'currency'}
                                        readOnly={props.readOnly}
                                        inverseValues={inverseValues}
                                    />
                                </td>
                            )}
                            {!props.isPDF && props.showTrend && (
                                <td className={row.id} colSpan={2}>
                                    {trendIdx > -1 && (
                                        <NumberField
                                            name={`cfTrends.trends[${trendIdx}].trend`}
                                            numberFormat={trendType}
                                            readOnly={false}
                                            inverseValues={inverseValues}
                                        />
                                    )}
                                </td>
                            )}
                            {/*<td></td>*/}
                        </React.Fragment>
                    )}
                    {!props.onlyTitles &&
                        row?.years?.map((fld, ind) => {
                            if (props.isPDF && ind > 5) return null
                            return (
                                <td
                                    key={`${row.id}-${ind}`}
                                    className={`${row.id}`}
                                    style={{
                                        paddingLeft: ind === 0 ? '20px' : '0px',
                                        background:
                                            (!!loanTerm && ind === loanTerm - 1) ||
                                            (!!interestOnly && ind === interestOnly - 1)
                                                ? '#4486ff0d'
                                                : 'initial',
                                    }}
                                >
                                    {props.showTrend && row.id == 'taxes' ? (
                                        <NumberField
                                            name={`cfTrendTax[${ind}].val`}
                                            numberFormat={'currency'}
                                            readOnly={ind == 0}
                                            inverseValues={true}
                                        />
                                    ) : (
                                        <>
                                            {props.readOnly && !isRefiTable ? (
                                                <div className={'editCell'}>
                                                    {(function () {
                                                        const val = row.years[ind]
                                                        const tmp = numeral(Math.abs(val)).format('$0,0')
                                                        if ((val > 0 && inverseValues) || (val < 0 && !inverseValues))
                                                            return '(' + tmp + ')'
                                                        else return tmp
                                                    })()}
                                                </div>
                                            ) : (
                                                <NumberField
                                                    name={`${fieldName}.years[${ind}]`}
                                                    numberFormat={numberFormat || 'currency'}
                                                    readOnly={props.readOnly}
                                                    inverseValues={inverseValues}
                                                />
                                            )}
                                        </>
                                    )}
                                    {!props.isPDF && ind == row.years.length - 1 && !props.readOnly && row.isNew && (
                                        <div className={'delete-row'} onClick={removeRow}>
                                            <img className={'normal'} src={'/_img/lenderDashboard/trash.svg'} />
                                            <img className={'hover'} src={'/_img/lenderDashboard/trash-red.svg'} />
                                        </div>
                                    )}
                                </td>
                            )
                        })}
                </tr>

                {/*subrows*/}
                {row.subRows &&
                    isExpanded &&
                    row.subRows.map((subRow, subRowInd) => {
                        subRow.years = subRow.years || []
                        return (
                            <tr className={`${props.className} ${subRow.id}`} key={`${row.id}-${subRowInd}`}>
                                {props.onlyTitles && (
                                    <React.Fragment>
                                        <td className={'sub-title'} colSpan={2}>
                                            {subRow.title}
                                        </td>
                                        {/*<td style={{ width: 0 }}></td>*/}
                                        {props.showTxAndAdjusted && (
                                            <td className={subRow.id} colSpan={2}>
                                                {/*{props?.readOnly && }*/}

                                                <NumberField
                                                    name={`${fieldName}.subRows[${subRowInd}].originalApr`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    inverseValues={inverseValues}
                                                />
                                            </td>
                                        )}

                                        {props.showTxAndAdjusted && (
                                            <td className={subRow.id} colSpan={2}>
                                                {/*{props?.readOnly && }*/}

                                                <NumberField
                                                    name={`${fieldName}.subRows[${subRowInd}].apr`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    inverseValues={inverseValues}
                                                />
                                            </td>
                                        )}
                                        {props.showTrend && (
                                            <td className={row.id} colSpan={2}>
                                                {/*{props?.readOnly && }*/}
                                            </td>
                                        )}
                                        {/*<td></td>*/}
                                    </React.Fragment>
                                )}
                                {!props.onlyTitles &&
                                    subRow.years.map((fld, ind) => (
                                        <td
                                            key={`${subRow.id}-${ind}`}
                                            className={`${subRow.id}`}
                                            style={{
                                                paddingLeft: ind === 0 ? '20px' : '0px',
                                            }}
                                        >
                                            <NumberField
                                                name={`${fieldName}.subRows[${subRowInd}].years[${ind}]`}
                                                numberFormat={'currency'}
                                                readOnly={true}
                                                inverseValues={inverseValues}
                                            />
                                        </td>
                                    ))}
                            </tr>
                        )
                    })}
                {/* adjusted*/}
                {row.subRows && isExpanded && (
                    <tr className={`${props.className} row-adjustment`}>
                        {props.onlyTitles && (
                            <React.Fragment>
                                <td className={'sub-title'} colSpan={2}>
                                    Adjustment
                                </td>
                                {props.showTxAndAdjusted && <td className={'sub-title'} colSpan={2}></td>}
                                {props.showTxAndAdjusted && (
                                    <td colSpan={2}>{numeral(adjustment?.apr).format('$0,0')}</td>
                                )}
                                {props.showTrend && <td colSpan={2}></td>}
                            </React.Fragment>
                        )}
                        {!props.onlyTitles &&
                            adjustment?.years.map((value, idx) => (
                                <td key={row.id + 'adj' + idx}>{numeral(value).format('$0,0')}</td>
                            ))}
                    </tr>
                )}
                {/*language=SCSS*/}
                <style jsx>
                    {`
                        @import './src/scss/colors.scss';
                        @media print {
                            tr {
                                td {
                                    height: 35px;
                                    width: 130px !important;
                                    min-width: 130px !important;
                                    max-width: 130px !important;
                                }
                            }
                        }
                        tr {
                            td {
                                height: 35px;
                                width: ${cellWidth};
                                min-width: ${cellWidth};
                                max-width: ${cellWidth};
                            }
                        }
                        .editCell {
                            text-align: right;
                            text-align: right;
                            font-weight: 500;
                            font-size: 14px;
                            color: #161c26;
                            height: 26px;
                            border-top: 1px solid transparent;
                            background-color: transparent;
                        }
                        .add-row {
                        }
                        td.title {
                            padding-left: 30px;
                            width: 300px;
                            min-width: 300px;
                            max-width: 300px;
                            div {
                                display: flex;
                                flex-direction: row;
                            }

                            .material-icons-outlined {
                                cursor: pointer;
                            }
                            :global(.input-container) {
                                display: flex;
                                flex: 1;
                            }
                            :global(.expandable) {
                                cursor: pointer;
                                :global(input) {
                                    cursor: pointer !important;
                                }
                            }
                        }
                        td.sub-title {
                            padding-left: 50px;
                            width: 300px;
                            min-width: 300px;
                            max-width: 300px;
                        }
                        tr.readOnly {
                            background-color: $grid-row-selected-bg;
                            :global(input) {
                                background-color: transparent !important;
                            }
                            td.title {
                                padding-left: 19px;
                                font-size: 15px;
                            }
                        }
                        tr.net_operating_income {
                            background-color: transparent;
                            border-bottom: 1px solid $grid-row-selected-bg;
                            td:nth-child(2) {
                                border-right: 1px solid $grid-row-selected-bg;
                            }

                            td.title,
                            :global(input) {
                                font-weight: 500 !important;
                                font-size: 21px !important;
                                line-height: 27px;
                            }
                        }
                        td:last-child {
                            position: relative;
                        }
                        .delete-row {
                            position: absolute;
                            top: -2px;
                            right: -33px;
                            background-color: #fff;
                            padding: 0;
                            height: 32px;
                            width: 25px;
                            cursor: pointer;
                            border-bottom-right-radius: 3px;
                            border-right: 1px solid $border;
                            border-bottom: 1px solid $border;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                margin: 0 !important;
                            }
                            img.hover {
                                display: none;
                            }
                            &:hover {
                                z-index: 100;
                                img.normal {
                                    display: none;
                                }
                                img.hover {
                                    display: block;
                                }
                            }
                        }

                        :global(.noi) {
                            background-color: rgba(217, 255, 229, 0.75) !important;
                            :global(*) {
                                font-weight: bold !important;
                            }
                        }
                        :global(.retailnoi) {
                            /*
                            background-color: rgba(217, 255, 229, 0.75) !important;
                            :global(*) {
                                font-weight: bold !important;
                            }
                            
                         */
                        }
                        .row-adjustment {
                            td {
                                text-align: right;
                            }
                        }
                    `}
                </style>
            </React.Fragment>
        </>
    )
})
