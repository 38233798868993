import React, { forwardRef, useState } from 'react'
import closeIcon from './close.svg'
import dragIcon from './drag-icon.svg'
import openRightFineTuneImg from './open-right-fine-tune-tab.svg'
import openBottomFineTuneImg from './open-bottom-fine-tune-tab.svg'
import { NumberField } from '../../../../../components/NumberField'
import { LoadDealWindow } from '../LoadDealWindow/LoadDealWindow'
import { BooleanField } from '../../../../../components/BooleanField'

type FineTuneDealProps = {
    form?: any
    hide?: () => void
    expanded?: boolean
    localContext?: any
    toggleLoadDeal?: boolean
    changeFineTuneMode?: (v: string) => void
    setToggleLoadDeal?: (v: boolean) => void
    setSaveDealModalVisible?: (v: boolean) => void
    showMez?: boolean
    useNCFnotNOI: boolean
}
export const DragDropWindow = forwardRef((props: FineTuneDealProps, ref) => {
    const [activeTab, setActiveTab] = useState<string>('restrictions')
    const {
        form,
        hide,
        localContext,
        toggleLoadDeal,
        setToggleLoadDeal,
        changeFineTuneMode,
        setSaveDealModalVisible,
        showMez,
        useNCFnotNOI,
    } = props

    return (
        <div className={`fine-tune-dnd`}>
            <strong className="cursor draggable-button">
                <img src={dragIcon} />
            </strong>
            <div className={'dnd-content-wrapper'}>
                <div className={'heading'}>
                    <div className={'title'}>Fine-tune deal</div>
                    <div className={'heading-buttons'}>
                        <img
                            src={openRightFineTuneImg}
                            onClick={() => changeFineTuneMode('RightBar')}
                            className={'close-button'}
                        />
                        <img
                            src={openBottomFineTuneImg}
                            onClick={() => changeFineTuneMode('BottomBar')}
                            className={'close-button'}
                        />
                        <img src={closeIcon} onClick={hide} className={'close-button'} />
                    </div>
                </div>
                <div className={'tabs'}>
                    <div
                        className={activeTab == 'restrictions' ? 'tab active' : 'tab'}
                        onClick={() => setActiveTab('restrictions')}
                    >
                        Restrictions {showMez ? 'Senior' : ''}
                    </div>
                    <div className={activeTab == 'loan' ? 'tab active' : 'tab'} onClick={() => setActiveTab('loan')}>
                        Loan Terms {showMez ? 'Senior' : ''}
                    </div>
                    {showMez && (
                        <>
                            <div
                                className={activeTab == 'restrictionsMez' ? 'tab active' : 'tab'}
                                onClick={() => setActiveTab('restrictionsMez')}
                            >
                                Restrictions Mezzanine
                            </div>
                            <div
                                className={activeTab == 'loanMez' ? 'tab active' : 'tab'}
                                onClick={() => setActiveTab('loanMez')}
                            >
                                Loan Terms Mezzanine
                            </div>
                        </>
                    )}
                    <div
                        className={activeTab == 'vacancy' ? 'tab active' : 'tab'}
                        onClick={() => setActiveTab('vacancy')}
                    >
                        Vacancy
                    </div>
                    <div
                        className={activeTab == 'assumptions' ? 'tab active' : 'tab'}
                        onClick={() => setActiveTab('assumptions')}
                    >
                        Exit Assumptions
                    </div>
                    <div
                        className={activeTab == 'valuation' ? 'tab active' : 'tab'}
                        onClick={() => setActiveTab('valuation')}
                    >
                        Estimated Valuation
                    </div>
                    <div
                        className={activeTab == 'borrower' ? 'tab active' : 'tab'}
                        onClick={() => setActiveTab('borrower')}
                    >
                        Proforma Comparison
                    </div>
                </div>
                <div className={'content'}>
                    <div className={'fine-tune-form'}>
                        <div className={'tabs-content'}>
                            {activeTab === 'restrictions' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Min DSCR</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'decimal'} name={'minDSCR'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <BooleanField
                                            name={'useSeniorMaxLTC'}
                                            label={'Max LTC'}
                                            className={'radio'}
                                            disableUncheck={true}
                                        />
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'maxLTC'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <BooleanField
                                            name={'useSeniorMaxLTV'}
                                            label={'Max LTV'}
                                            className={'radio'}
                                            disableUncheck={true}
                                        />
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'maxLTV'} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === 'loan' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Amortization Period</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'years'} name={'amortizationTerm'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Term</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'years'} name={'loanTerm'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>IO Period</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'years'} name={'ioPeriod'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Rate</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'rate'} />
                                        </div>
                                    </div>
                                </>
                            )}

                            {activeTab === 'restrictionsMez' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Min DSCR</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'decimal'} name={'mezMinDSCR'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <BooleanField
                                            name={'useMezMaxLTC'}
                                            label={'Max LTC'}
                                            className={'radio'}
                                            disableUncheck={true}
                                        />
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'mezMaxLTC'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <BooleanField
                                            name={'useMezMaxLTV'}
                                            label={'Max LTV'}
                                            className={'radio'}
                                            disableUncheck={true}
                                        />
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'mezMaxLTV'} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === 'loanMez' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Amortization Period</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'years'} name={'mezAmortizationTerm'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Term</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'years'} name={'mezLoanTerm'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>IO Period</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'years'} name={'mezIoPeriod'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Rate</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'mezRate'} />
                                        </div>
                                    </div>
                                </>
                            )}

                            {activeTab === 'vacancy' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Annualized vacancy</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'vacancy'} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === 'assumptions' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Exit Cap rate</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'exitCapRate'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Refinanced LTV</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'refinanceLTV'} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === 'valuation' && (
                                <>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Valuation Cap rate</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'valuationCapRate'} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeTab === 'borrower' && (
                                <>
                                    <div className={'form-row'}>
                                        <BooleanField
                                            name={'useBorrowerNOI'}
                                            label={useNCFnotNOI ? 'Net CF' : 'NOI'}
                                            className={'radio'}
                                        />
                                        <div className={'value'}>
                                            <NumberField numberFormat={'currency'} name={'borrowerNOI'} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={'bottom-buttons'}>
                        <div className={'deal-buttons'}>
                            {toggleLoadDeal && (
                                <LoadDealWindow setToggleLoadDeal={setToggleLoadDeal} localContext={localContext} />
                            )}
                            <div className={'link'} onClick={() => setSaveDealModalVisible(true)}>
                                Save Scenario
                            </div>
                            <div
                                className={'link'}
                                onClick={() => setToggleLoadDeal(true)}
                                style={{ color: toggleLoadDeal ? 'black' : '#4485ff' }}
                            >
                                Load Scenario
                            </div>
                        </div>
                        <div className={'close-reset-buttons'}>
                            <div className={'link'} onClick={() => form.reset()}>
                                Reset
                            </div>
                            <div className={'apply'} onClick={hide}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .fine-tune-dnd {
                    width: 800px;
                    transition: width 300ms;
                    overflow: visible;
                    display: flex;
                    flex-direction: row;
                    .draggable-button {
                        padding: 11px 7px 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-self: flex-start;
                        background: #97a2b4;
                        border-radius: 3px 0 0 3px;
                        img {
                            pointer-events: none;
                            margin-bottom: 0;
                        }
                        &.cursor {
                            cursor: move;
                        }
                    }
                    .dnd-content-wrapper {
                        background: #fff;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        border-radius: 0 3px 3px 3px;
                        overflow: hidden;
                    }
                    .heading {
                        background: #97a2b4;
                        display: flex;
                        justify-content: space-between;
                        position: relative;
                        padding: 10px 18px 10px 23px;
                        .title {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 18px;
                            color: $white;
                        }
                        .heading-buttons {
                            display: flex;
                            justify-content: flex-end;
                            .close-button {
                                cursor: pointer;
                                &:not(:last-child) {
                                    margin-right: 19px;
                                }
                            }
                        }
                    }
                    .tabs {
                        display: flex;
                        justify-content: space-between;
                        background: $white;
                        padding: 21px 19px;
                        .tab {
                            padding-right: 15px;
                            display: block;
                            font-size: 15px;
                            line-height: 18px;
                            color: $dark-gray;
                            position: relative;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                cursor: default;
                                &:before {
                                    position: absolute;
                                    content: '';
                                    width: 100%;
                                    height: 3px;
                                    background: $blue;
                                    bottom: -7px;
                                    left: 0;
                                    border-radius: 30px 30px 0 0;
                                }
                            }
                        }
                    }
                    .content {
                        background: $white;
                    }
                    .fine-tune-form {
                        width: 100%;
                        overflow-y: auto;
                        padding-left: 19px;
                        padding-right: 19px;
                    }
                    .form-row {
                        display: flex;
                        flex-direction: row;
                        margin: 8px 0;
                        .label {
                            flex: 2;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                        }
                        .value {
                            flex: 1;
                            :global(.input-container:after) {
                                border-bottom: 1px solid #97a2b4;
                            }
                            :global(.input) {
                                font-size: 15px;
                                line-height: 18px;
                            }
                            :global(.input:focus) {
                                border-width: 0;
                            }
                        }
                        :global(.radio) {
                            margin-right: 25px;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                        }
                    }
                    .bottom-buttons {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        padding: 11px;
                        margin-top: 21px;
                        border-top: 1px solid $border;
                        .deal-buttons {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            justify-content: flex-start;
                            position: relative;
                            .link {
                                cursor: pointer;
                                border: none;
                                font-size: 15px;
                                line-height: 18px;
                                color: $accent;
                                background: none;
                                height: 18px;
                                user-select: none;

                                &:not(:last-child) {
                                    margin-right: 20px;
                                }
                            }
                            :global(.load-deal-window) {
                                top: -20px;
                                left: 0;
                            }
                        }
                        .close-reset-buttons {
                            display: flex;
                            align-items: center;
                            .link {
                                cursor: pointer;
                                border: none;
                                font-size: 15px;
                                line-height: 18px;
                                color: #687893;
                                background: none;
                                height: 18px;
                                margin-right: 20px;
                                user-select: none;
                            }
                            .apply {
                                color: white;
                                background: $accent;
                                padding: 10px 30px;
                                border-radius: 4px;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                cursor: pointer;
                                user-select: none;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
})
