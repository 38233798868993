import React, { useCallback, useEffect, useMemo, useState } from 'react'
import numeral from 'numeral'
import { Form, useForm, useFormState } from 'react-final-form'
import { Finance } from 'financejs'
import { CashFlowRowComp } from '../../../../Cashflow/CashFlowRow'
import { useSelector } from 'react-redux'
import { ReduxStoreState, ReduxHelper } from '../../../../../../../../store'
import { calcDebtService } from '../lib'
const cloneDeep = require('lodash.clonedeep')

type Props = any
export function Table(props: Props) {
    const onSubmit = useCallback((values: any) => {
        ReduxHelper.mergeIn(
            // @ts-ignore
            ['lender', 'originationModel', 'fannieExitScenarioRefinance', 'refinanceAssumptions', `${props.page}`],
            cloneDeep(values),
        )
    }, [])

    return (
        <>
            <Form onSubmit={onSubmit} initialValues={props.defaultData}>
                {() => (
                    <TableForm
                        selectedPricingOption={props.selectedPricingOption}
                        isSupplemental={props.isSupplemental}
                        page={props.page}
                    />
                )}
            </Form>
        </>
    )
}

const TableForm = (props: Props) => {
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlows = useSelector((state: ReduxStoreState) => state.lender.alternameCashFlows)
    const { selectedPricingOption, isSupplemental } = props

    useEffect(() => {
        if (form) form.submit()
    }, [formValues])

    const loanTerm = useMemo(
        () => model.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.loanDetails?.termInYears,
        [model.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.loanDetails?.termInYears],
    )

    const interestOnly = useMemo(
        () => model.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.loanDetails?.yearsOfIo,
        [model.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.loanDetails?.yearsOfIo],
    )

    useEffect(() => {
        if (isSupplemental && !!model?.fanniePricing?.supplemental?.[0]) {
            const originalLoan = model.fanniePricing.supplemental[0]
            const loanAmount = originalLoan?.priorLien?.upbOriginalLoanAmount || 0
            const amortizationTermYears = originalLoan?.priorLien?.amortizationYears || 30
            const rate = originalLoan?.priorLien?.intrestRate || 0
            const ioPeriodYears = originalLoan?.priorLien?.ioYears || 0
            const unit = 'year'
            const periodYears = originalLoan?.terms?.termYears || 10
            const debtService = calcDebtService({
                loanAmount,
                amortizationTermYears,
                rate,
                ioPeriodYears,
                unit,
                periodYears: amortizationTermYears,
            })

            const newValues = formState.values.debtServiceExistingLoan.years.map(
                (item, i) => debtService[i]?.total || null,
            )
            form.change('debtServiceExistingLoan.years', newValues)
        }
    }, [
        formValues.debtServiceExistingLoan.years.length,
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.assumptions?.amortization,
        isSupplemental,
        model?.fanniePricing?.supplemental?.[0],
    ])

    useEffect(() => {
        const newValues = formValues.debtServiceNewLoan.years.map((item, i) =>
            !!item && !!formValues.debtServiceExistingLoan.years[i]
                ? item + formValues.debtServiceExistingLoan.years[i]
                : null,
        )
        form.change('totalDebtService.years', newValues)
    }, [formValues.debtServiceExistingLoan.years, formValues.debtServiceNewLoan.years])

    useEffect(() => {
        const newValues = formValues.ncf.years.map((item, i) =>
            !!item && !!formValues.totalDebtService.years[i]
                ? (item / formValues.totalDebtService.years[i]).toFixed(2)
                : null,
        )
        form.change('combinedDscr.years', newValues)
    }, [formValues.ncf.years, formValues.totalDebtService.years])

    useEffect(() => {
        if (isSupplemental && !!model?.fanniePricing?.supplemental?.[0]) {
            const originalLoan = model.fanniePricing.supplemental[0]

            const originalClosingDate = new Date(originalLoan?.priorLien?.closed)
            const currentClosingDate = model?.fanniePricing?.loanInfo?.dateOfPricing
                ? new Date(model.fanniePricing.loanInfo.dateOfPricing)
                : new Date()
            const timeDiff = currentClosingDate.getTime() - originalClosingDate.getTime()
            const monthDiff = timeDiff / (1000 * 3600 * 24) / 30

            const loanAmount = originalLoan?.priorLien?.upbOriginalLoanAmount || 0
            const amortizationTermYears = originalLoan?.priorLien?.amortizationYears || 30
            const rate = originalLoan?.priorLien?.intrestRate || 0
            const ioPeriodYears = originalLoan?.priorLien?.ioYears - +(monthDiff / 12).toFixed(0) - 1 || 0
            const unit = 'year'
            const periodYears = originalLoan?.terms?.termYears || 10
            const debtService = calcDebtService({
                loanAmount,
                amortizationTermYears,
                rate,
                ioPeriodYears,
                unit,
                periodYears: amortizationTermYears,
            })

            const newValues = formState.values.balanceExistingLoan.years.map(
                (item, i) => debtService[i]?.endingBalance || null,
            )
            form.change('balanceExistingLoan.years', newValues)
        }
    }, [
        formValues.balanceExistingLoan.years.length,
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.assumptions?.amortization,
        isSupplemental,
        model?.fanniePricing?.supplemental?.[0],
        model?.fanniePricing?.loanInfo?.dateOfPricing,
    ])

    useEffect(() => {
        const newValues = formValues.balanceNewLoan.years.map((item, i) =>
            !!item && !!formValues.balanceExistingLoan.years[i] ? item + formValues.balanceExistingLoan.years[i] : null,
        )
        form.change('balanceCombined.years', newValues)
    }, [formValues.balanceExistingLoan.years, formValues.balanceNewLoan.years])

    useEffect(() => {
        if (!!cashFlows) {
            if (props.page === 0 && !!cashFlows?.exitScr?.noi?.years) {
                form.change('ncf.years', cloneDeep(cashFlows?.exitScr?.noi?.years))
            }

            if (props.page === 1 && !!cashFlows?.exitScrAlterntive?.noi?.years) {
                form.change('ncf.years', cloneDeep(cashFlows?.exitScrAlterntive?.noi?.years))
            }
        }
    }, [cashFlows?.exitScr?.noi?.years, cashFlows?.exitScrAlterntive?.noi?.years])

    useEffect(() => {
        const capRate =
            model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.rateAssumptions
                ?.calculatedTerminalCapRate
        if (!!capRate) {
            form.change('valueAtCapRate.title', `Value at Cap Rate ${numeral(capRate).format('0.[00]%')}`)
            form.change('ltvAtCapRate.title', `LTV at Cap Rate ${numeral(capRate).format('0.[00]%')}`)
        }
    }, [
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.rateAssumptions
            ?.calculatedTerminalCapRate,
    ])

    useEffect(() => {
        const capRate =
            model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.rateAssumptions
                ?.calculatedTerminalCapRate

        if (!!capRate) {
            const newValues = formValues.ncf.years.map((item) => (!!item ? item / capRate : null))
            form.change('valueAtCapRate.years', newValues)
        }
    }, [
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.rateAssumptions
            ?.calculatedTerminalCapRate,
        formValues.ncf.years,
    ])

    useEffect(() => {
        const newValues = formValues.balanceNewLoan.years.map((item, i) =>
            !!item && !!formValues.valueAtCapRate.years[i] ? item / formValues.valueAtCapRate.years[i] : null,
        )
        form.change('ltvAtCapRate.years', newValues)
    }, [formValues.balanceNewLoan.years, formValues.valueAtCapRate.years])

    useEffect(() => {
        const exitRefiRate =
            model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.rateAssumptions
                ?.calculatedExitRefiRate
        if (!!exitRefiRate) {
            const finance = new Finance()
            const pmt = finance.PMT(exitRefiRate / 12, 360, -1)
            const dscr = formValues.balanceNewLoan.years.map((item, i) => {
                if (!item || !formValues.ncf.years[i + 1] || !pmt) return null
                return (formValues.ncf.years[i + 1] / (item * (pmt * 12))).toFixed(2)
            })

            form.change('upbDscrAtRefiRate.years', dscr)
        }
    }, [
        formValues.balanceNewLoan.years,
        formValues.ncf.years,
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.rateAssumptions?.calculatedExitRefiRate,
    ])

    const [isNM, setIsNM] = useState(ReduxHelper.store.getState()?.user.isAdmin || false)
    useEffect(() => {
        const isAdmin = ReduxHelper.store.getState()?.user.isAdmin
        if (isAdmin) setIsNM(true)
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [model])

    useEffect(() => {
        if (selectedPricingOption) {
            const loanAmount = selectedPricingOption.sizer?.actual?.loanAmount || 0
            const amortizationTermYears =
                selectedPricingOption.scenario.underwritten.financeOptions.amortization ||
                model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.assumptions?.amortization ||
                30
            const rate = selectedPricingOption.scenario?.actual?.loanPricing?.actualintrestRate || 0
            /*
            //Dror Fix, to be same as NM excel March 3 2023

             */
            /*
             const ioPeriodYears = selectedPricingOption.scenario?.actual?.financeOptions?.io || 0
             const unit = 'year'
             const periodYears = selectedPricingOption.scenario?.actual?.financeOptions?.loanTerm || 10



             const debtService = calcDebtService({
                 loanAmount,
                 amortizationTermYears,
                 rate,
                 ioPeriodYears,
                 unit,
                 periodYears: amortizationTermYears,
             })
             console.log('debtService calc', loanAmount, rate, amortizationTermYears, debtService)
             const newValues = formState.values.debtServiceNewLoan.years.map((item, i) => debtService[i]?.total || null)

              */
            const finance = new Finance()
            const anotherDebtService = finance.PMT(rate / 12, amortizationTermYears * 12, -1 * loanAmount) * 12
            const newValues = []
            for (let i = 0; i < model.exitAssumption.saleYearAnalysisPeriod; i++) newValues.push(anotherDebtService)
            form.change('debtServiceNewLoan.years', newValues)
            // console.log('newValues', newValues)
        }
    }, [
        selectedPricingOption,
        formState.values.debtServiceNewLoan.years.length,
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.assumptions?.amortization,
        isNM,
    ])

    useEffect(() => {
        const newValues = formState.values.newLoanDSCR.years.map((item, i) =>
            !!formState.values.ncf.years[i] && !!formState.values.debtServiceNewLoan.years[i]
                ? (formState.values.ncf.years[i] / formState.values.debtServiceNewLoan.years[i]).toFixed(2)
                : null,
        )
        form.change('newLoanDSCR.years', newValues)
    }, [
        formState.values.ncf.years,
        formState.values.debtServiceNewLoan.years,
        formState.values.newLoanDSCR.years.length,
    ])

    useEffect(() => {
        if (selectedPricingOption) {
            const loanAmount = selectedPricingOption.sizer?.actual?.loanAmount || 0
            const amortizationTermYears =
                selectedPricingOption.scenario.underwritten.financeOptions.amortization ||
                model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.assumptions?.amortization ||
                30
            const rate = selectedPricingOption.scenario?.actual?.loanPricing?.actualintrestRate || 0
            const ioPeriodYears = selectedPricingOption.scenario?.actual?.financeOptions?.io || 0
            const unit = 'month'
            const periodYears = selectedPricingOption.scenario?.actual?.financeOptions?.loanTerm || 10
            const startDate = model?.fanniePricing?.loanInfo?.dateOfPricing
                ? new Date(model.fanniePricing.loanInfo.dateOfPricing)
                : new Date()
            const debtService = calcDebtService({
                loanAmount,
                amortizationTermYears,
                rate,
                ioPeriodYears,
                unit,
                periodYears: amortizationTermYears,
                firstYear: startDate.getFullYear(),
                firstMonth: startDate.getMonth(),
            })
            // console.log('debtService', debtService)
            const newValues = formState.values.balanceNewLoan.years.map(
                (item, i) => debtService[(i + 1) * 12]?.startingBalance || null,
            )
            form.change('balanceNewLoan.years', newValues)
        }
    }, [
        selectedPricingOption,
        formState.values.balanceNewLoan.years.length,
        model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[props.page]?.assumptions?.amortization,
        model?.fanniePricing?.loanInfo?.dateOfPricing,
    ])

    const fillYears = (idx) => {
        for (let key in formState.values) {
            if (formState.values[key].years.length <= idx) {
                form.change(`${key}.years.${idx}`, null)
            }
        }
    }

    const tableTitles = useMemo(() => {
        const titles = []
        for (let i = 1; i <= (formState.values?.ncf?.years?.length || 5); i++) {
            const isTermEnd = i === loanTerm
            const isInterestOnly = i === interestOnly
            titles.push(
                <th
                    key={i}
                    className={'columnTitles'}
                    style={{
                        background: isTermEnd || isInterestOnly ? '#4486ff0d' : 'initial',
                    }}
                >
                    {isTermEnd && !isInterestOnly && <p className={'highlightedTitle'}>TERM END</p>}
                    {isInterestOnly && !isTermEnd && <p className={'highlightedTitle'}>INTEREST ONLY</p>}
                    YEAR {i}
                    {isTermEnd && isInterestOnly && <p className={'highlightedTitle'}>TERM END</p>}
                    {isTermEnd && isInterestOnly && (
                        <p className={'highlightedTitle'} style={{ top: 'initial', bottom: -15 }}>
                            INTEREST ONLY
                        </p>
                    )}
                </th>,
            )
            fillYears(i - 1)
        }
        return titles
    }, [formState.values?.ncf?.years, loanTerm])

    return (
        <form
            className={'form-container'}
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <div className="tables-container">
                <table className="part-1 table-fixed" style={{ width: '500px' }} cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th colSpan={4} style={{ height: 70 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        <CashFlowRowComp fieldName={'ncf'} readOnly onlyTitles />
                        <tr>
                            <td className={'group'} colSpan={4}>
                                Refinance Analysis
                            </td>
                        </tr>
                        <CashFlowRowComp fieldName={'debtServiceNewLoan'} readOnly onlyTitles />
                        <CashFlowRowComp fieldName={'newLoanDSCR'} readOnly onlyTitles />
                        {isSupplemental && !!model?.fanniePricing?.supplemental?.[0] && (
                            <>
                                <CashFlowRowComp fieldName={'debtServiceExistingLoan'} readOnly onlyTitles />
                                <CashFlowRowComp fieldName={'totalDebtService'} readOnly onlyTitles />
                                <CashFlowRowComp fieldName={'combinedDscr'} readOnly onlyTitles />
                            </>
                        )}

                        <tr>
                            <td className={'group'} colSpan={4}>
                                Debt-Balance Analysis
                            </td>
                        </tr>
                        <CashFlowRowComp fieldName={'balanceNewLoan'} readOnly onlyTitles />
                        {isSupplemental && !!model?.fanniePricing?.supplemental?.[0] && (
                            <>
                                <CashFlowRowComp fieldName={'balanceExistingLoan'} readOnly onlyTitles />
                                <CashFlowRowComp fieldName={'balanceCombined'} readOnly onlyTitles />
                            </>
                        )}
                        <CashFlowRowComp fieldName={'valueAtCapRate'} readOnly onlyTitles />
                        <CashFlowRowComp fieldName={'ltvAtCapRate'} readOnly onlyTitles />
                        <CashFlowRowComp fieldName={'upbDscrAtRefiRate'} readOnly onlyTitles />
                    </tbody>
                </table>

                <div className={'scrollable-container'}>
                    <table
                        className="part-1 table-scrollable'"
                        id={'table-scrollable'}
                        style={{ width: '170px' }}
                        cellPadding={0}
                        cellSpacing={0}
                    >
                        <thead>
                            <tr>{tableTitles}</tr>
                        </thead>
                        <tbody>
                            <CashFlowRowComp
                                fieldName={'ncf'}
                                readOnly
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                            <tr>
                                <td className={'group'} colSpan={30} style={{ height: 54 }}></td>
                            </tr>

                            <CashFlowRowComp
                                fieldName={'debtServiceNewLoan'}
                                readOnly
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                            <CashFlowRowComp
                                fieldName={'newLoanDSCR'}
                                readOnly
                                numberFormat={'decimal'}
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                            {isSupplemental && !!model?.fanniePricing?.supplemental?.[0] && (
                                <>
                                    <CashFlowRowComp
                                        fieldName={'debtServiceExistingLoan'}
                                        readOnly
                                        loanTerm={loanTerm}
                                        interestOnly={interestOnly}
                                        isRefiTable
                                    />
                                    <CashFlowRowComp
                                        fieldName={'totalDebtService'}
                                        readOnly
                                        loanTerm={loanTerm}
                                        interestOnly={interestOnly}
                                        isRefiTable
                                    />
                                    <CashFlowRowComp
                                        fieldName={'combinedDscr'}
                                        numberFormat={'decimal'}
                                        readOnly
                                        loanTerm={loanTerm}
                                        interestOnly={interestOnly}
                                        isRefiTable
                                    />
                                </>
                            )}
                            <tr>
                                <td className={'group'} colSpan={30} style={{ height: 54 }}></td>
                            </tr>
                            <CashFlowRowComp
                                fieldName={'balanceNewLoan'}
                                readOnly
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                            {isSupplemental && !!model?.fanniePricing?.supplemental?.[0] && (
                                <>
                                    <CashFlowRowComp
                                        fieldName={'balanceExistingLoan'}
                                        readOnly
                                        loanTerm={loanTerm}
                                        interestOnly={interestOnly}
                                        isRefiTable
                                    />
                                    <CashFlowRowComp
                                        fieldName={'balanceCombined'}
                                        readOnly
                                        loanTerm={loanTerm}
                                        interestOnly={interestOnly}
                                        isRefiTable
                                    />
                                </>
                            )}

                            <CashFlowRowComp
                                fieldName={'valueAtCapRate'}
                                readOnly
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                            <CashFlowRowComp
                                fieldName={'ltvAtCapRate'}
                                readOnly
                                numberFormat={'percent'}
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                            <CashFlowRowComp
                                fieldName={'upbDscrAtRefiRate'}
                                readOnly
                                numberFormat={'decimal'}
                                loanTerm={loanTerm}
                                interestOnly={interestOnly}
                                isRefiTable
                            />
                        </tbody>
                    </table>
                </div>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .form-container {
                    background-color: #fff;
                    border-radius: 3px;
                    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    padding-bottom: 0;
                    padding-top: 5px;
                    margin-top: 50px;
                }

                table.part-1 {
                    width: 100%;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;

                    thead {
                        :global(th) {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 17px;
                            color: $secondary-text-color;
                            text-align: right;
                            position: relative;
                            height: 58px;
                        }
                        th:first-child {
                            width: 300px;
                        }

                        :global(th.columnTitles) {
                            position: relative;
                            padding-right: 10px;
                            padding-top: 5px;
                            height: 70px;

                            :global(p.highlightedTitle) {
                                color: #4486ff;
                                position: absolute;
                                right: 10px;
                                top: 5px;
                            }
                        }
                    }
                    .group {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 400;
                        color: $default-text-color;
                        padding-top: 20px;
                        padding-bottom: 10px;
                        padding-left: 10px;
                    }

                    :global(td:nth-child(2)),
                    th:nth-child(2),
                    .group {
                        padding-right: 24.5px;
                    }

                    :global(.input-container:after) {
                        bottom: 0px;
                        border-top: 1px solid #d9e0e5;
                        opacity: 1;
                    }

                    :global(.input-container) {
                        width: auto;
                        position: relative;
                        display: flex;

                        :global(input) {
                            flex: 1;
                        }
                    }

                    :global(td:nth-child(n + 2)) {
                        :global(.input-container) {
                            margin-left: 20%;
                        }
                    }

                    :global(.input-container.readOnly:after) {
                        display: none;
                    }

                    :global(.ncf) {
                        background-color: rgba(217, 255, 229, 0.75) !important;
                        :global(*) {
                            font-weight: bold !important;
                        }
                    }
                }

                tbody {
                    :global(tr) {
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                }

                .tables-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    margin-bottom: 20px;
                }

                .scrollable-container {
                    overflow-x: auto;
                    white-space: nowrap;
                    flex: 1;
                    padding-bottom: 2px;
                    border-left: 3px solid $grid-row-selected-bg;
                    overflow-y: hidden;
                    transform: rotateX(180deg);
                    //position: relative;
                    //top: -7px;
                    margin-top: -7px;

                    #table-scrollable {
                        transform: rotateX(180deg);
                    }

                    &::-webkit-scrollbar {
                        height: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        border-radius: 2px;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: $gray-blue;
                        border-radius: 2px;
                    }
                }

                .table-scrollable {
                    border-right-width: 0;
                    border-left-width: 0;
                }

                .table-fixed {
                    border-right-width: 0;
                }
            `}</style>
        </form>
    )
}
