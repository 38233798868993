import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { WidgetWrapper } from '../../../components/WidgetWrapper'

type Props = any

export function ReviewsFromWeb(props: Props) {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const reviews = project?.webReviews || []
    const [loading, setLoading] = useState(!!project)
    useEffect(() => {
        if (project) setLoading(false)
    }, [project])
    return (
        <WidgetWrapper title={'Reviews from the web'} showProgress={loading} className={'feedback-from-web'}>
            {reviews.map((r, ind) => (
                <div className={'review'} key={`${ind}`}>
                    <a href={r.url} target={'_blank'} rel={'noreferrer'}>
                        {r.name}
                    </a>
                    <div>{r.score}</div>
                    <div>{r.vote}</div>
                </div>
            ))}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                :global(.feedback-from-web) {
                    height: 100% !important;
                }
                .review {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    margin: 5px 0;
                    a {
                        color: $accent;
                        display: block;
                        flex: 2;
                    }
                    div {
                        flex: 1;
                    }
                }
            `}</style>
        </WidgetWrapper>
    )
}
