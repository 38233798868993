import React, { CSSProperties, useCallback, SyntheticEvent, useState } from 'react'
import { Spinner } from '../../../controls/Spinner'

type Props = {
    className?: string
    title?: string
    style?: CSSProperties
    contentStyle?: CSSProperties
    showProgress?: boolean
    saveCallback?: any
    editCallback?: any
    closeBtnStitle?: string
}

export function EditableWidgetWrapper(props: React.PropsWithChildren<Props>) {
    const [isEdit, setEdit] = useState(false)
    const closeBtnStitle = props?.closeBtnStitle ? props.closeBtnStitle : 'Save'
    const doEdit = useCallback((e: SyntheticEvent) => {
        setEdit(true)
        if (props.editCallback) props.editCallback(e)
    }, [])
    const doSave = useCallback((e: SyntheticEvent) => {
        if (props.saveCallback) props.saveCallback(e)
        setEdit(false)
    }, [])
    return (
        <>
            <div className={`widget-container ` + (props.className || '')} style={props.style}>
                {props.title && (
                    <div className={'widget-title'}>
                        <span className="material-icons-outlined edit" onClick={doEdit}>
                            mode_edit
                        </span>
                        <span className={'title'}>{props.title}</span>
                        <span className={!isEdit ? 'save hide' : 'save'} onClick={doSave}>
                            {closeBtnStitle}
                        </span>
                    </div>
                )}
                {props.showProgress ? (
                    <div className={'content-loading'}>
                        <Spinner />
                    </div>
                ) : (
                    <div className={'widget-body'} style={props.contentStyle}>
                        {props.children}
                    </div>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .widget-container {
                        background-color: $white;
                        border: 1px solid $border;
                        border-radius: 3px;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        .content-loading {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-grow: 1;
                        }
                    }
                    .widget-title {
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                        border-bottom: 1px solid $border;
                        padding: 10px 20px;
                        .title {
                            margin-left: 10px;
                            top: -5px;
                            position: relative;
                        }
                        .edit {
                            cursor: pointer;
                        }
                        .hide {
                            display: none;
                        }
                        .save {
                            position: relative;
                            float: right;
                            color: #4485ff;
                            right: 10px;
                            cursor: pointer;
                        }
                        height: 43px;
                    }
                    .widget-body {
                        padding: 20px;
                    }
                `}
            </style>
        </>
    )
}
