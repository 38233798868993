import React, { useMemo } from 'react'
import { Gauge } from '../widgets/Gauge'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { calcRowSum } from 'origination-model'

export const BorrowerGauges = () => {
    const lender = useSelector((state: ReduxStoreState) => state?.lender)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)

    const thresholds = useMemo(() => {
        return lender?.settings?.data
    }, [lender])

    const numberOfUnits = useMemo(() => {
        return lender?.originationModel?.numberOfUnits || 0
    }, [lender])

    const cashFlow = useMemo(() => {
        return lender?.originationModel?.cashFlow
    }, [lender])

    const payroll = cashFlow?.operatingExpenses?.find((item) => item.id == 'payroll')?.apr || 0
    const maintenance = cashFlow?.operatingExpenses?.find((item) => item.id == 'maintenance')?.apr || 0
    const gpr = cashFlow?.rentalIncome?.find((item) => item.id == 'grossPotentialRent')?.apr || 0

    const payrollPerUnit = useMemo(() => {
        const payrollPerUnit = payroll / numberOfUnits
        return payrollPerUnit
    }, [payroll, numberOfUnits])

    const payrollPercent = useMemo(() => {
        const payrollPerUnit = payroll / numberOfUnits
        let out =
            (payrollPerUnit - thresholds?.payrollPerUnitBottomThreshold) /
            (thresholds?.payrollPerUnitTopThreshold - thresholds?.payrollPerUnitBottomThreshold)
        if (out < 0) {
            out = 0
        }
        if (out > 1) {
            out = 1
        }

        return out
    }, [payroll, numberOfUnits, thresholds])

    const maintenancePerUnit = useMemo(() => {
        const maintenancePerUnit = maintenance / numberOfUnits
        return maintenancePerUnit
    }, [maintenance, numberOfUnits])

    const maintenancePercent = useMemo(() => {
        const maintenancePerUnit = maintenance / numberOfUnits
        let out =
            (maintenancePerUnit - thresholds?.maintenancePerUnitBottomThreshold) /
            (thresholds?.maintenancePerUnitTopThreshold - thresholds?.maintenancePerUnitBottomThreshold)
        if (out < 0) {
            out = 0
        }
        if (out > 1) {
            out = 1
        }
        return out
    }, [maintenance, numberOfUnits, thresholds])

    const noiToGpr = useMemo(() => {
        let out = cashFlow?.noi?.adjustedT12 / gpr
        if (out < 0) {
            out = 0
        }
        if (out > 1) {
            out = 1
        }
        return out
    }, [gpr, cashFlow])

    const t3tot12 = useMemo(() => {
        if (!cashFlow) return 0
        const t3 = cashFlow?.effectiveGrossIncome.originalApr
        const t12 = cashFlow?.effectiveGrossIncome.t12
        return (t3 - t12) / t12
    }, [cashFlow])

    return (
        <div className="row mb-4 justify-content-center">
            <div className="col-3">
                <Gauge
                    title={'Payroll per unit vs Threshold'}
                    value={numeral(payrollPerUnit).format('$0,0')}
                    percent={payrollPercent}
                    leftHint={'Threshold'}
                    leftValue={numeral(thresholds?.payrollPerUnitBottomThreshold || 0).format('$0,0')}
                    rightHint={'Threshold'}
                    rightValue={numeral(thresholds?.payrollPerUnitTopThreshold || 0).format('$0,0')}
                    hover={'Payroll per unit'}
                    loading={false}
                    error={'error'}
                />
            </div>

            <div className="col-3">
                <Gauge
                    title={'Maintenance per unit vs Threshold'}
                    value={numeral(maintenancePerUnit).format('$0,0')}
                    percent={maintenancePercent}
                    leftHint={'Threshold'}
                    leftValue={numeral(thresholds?.maintenancePerUnitBottomThreshold || 0).format('$0,0')}
                    rightHint={'Threshold'}
                    rightValue={numeral(thresholds?.maintenancePerUnitTopThreshold || 0).format('$0,0')}
                    hover={'Maintenance per unit'}
                    loading={false}
                    error={'error'}
                />
            </div>

            <div className="col-3">
                <Gauge
                    title={model?.useNCFnotNOI ? 'Net CF/GRP' : 'NOI/GPR'}
                    value={`${numeral(noiToGpr).format('%0.00')}`}
                    percent={noiToGpr}
                    leftHint={'Threshold'}
                    leftValue={'0%'}
                    rightHint={'Threshold'}
                    rightValue={'100%'}
                    hover={'Maintenance per unit'}
                    loading={false}
                    error={'error'}
                />
            </div>

            <div className="col-3">
                <Gauge
                    title={'T3/T12 Vs T12 (income)'}
                    value={`${numeral(t3tot12).format('%0.00')}`}
                    percent={0.5 + t3tot12 / 2}
                    leftHint={'Threshold'}
                    leftValue={'-100%'}
                    rightHint={'Threshold'}
                    rightValue={'100%'}
                    hover={'T3/T12 Vs T12 (income)'}
                    loading={false}
                    error={'error'}
                />
            </div>
        </div>
    )
}
