import React, {
    FunctionComponent,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
    Fragment,
    useRef,
} from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { ReduxStoreState } from '../../../../../../store'
import { useForm, useFormState } from 'react-final-form'
import { Spinner } from '../../../../../controls/Spinner'
import { SagaHelper } from '../../../../../../store/sagaHelper'
import { OnlyNumberField } from '../../../components/OnlyNumberField'
import { showErrorNotification } from '../../../../../pages/fullproject/notifications'

import {
    SizerTitlesCol,
    ScenarioTitlesCol,
    CreaditFeesTitlesCol,
    OptionTitle,
    LoanSizerColumn,
    LoanScenariosColumn,
    FeesColumn,
    CapCostTitlesCol,
    CapCostColumn,
    ExitAnalysisSummaryTitlesCol,
    ExitAnalysisSummaryCol,
    RateBreakdownTitlesCol,
    RateBreakdownColumn,
} from './FormParts'

import { FormWrapper } from './FormWrapper'

const cloneDeep = require('lodash.clonedeep')

enum ARMType {
    structured = 'Structured',
    notStructured = 'ARM 7-6',
}

type Props = any

export const AdjustablePage = React.memo(function AdjustablePage(props: Props) {
    const [loading, setLoading] = useState(false)

    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit

    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const isArmActivated = useMemo(
        () =>
            generalInputs?.loanDetails?.ARMType === ARMType.structured ||
            generalInputs?.loanDetails?.ARMType === ARMType.notStructured,
        [generalInputs?.loanDetails?.ARMType],
    )

    useEffect(() => {
        let interval
        if (!isArmActivated && !interval) {
            interval = setInterval(() => {
                SagaHelper.run(['lenderGeneralInputs', 'getData'], { projectId: projectId })
            }, 3000)
        }

        if (isArmActivated && !!interval) {
            clearInterval(interval)
        }

        return () => {
            if (!!interval) clearInterval(interval)
        }
    }, [isArmActivated])

    useEffect(() => {
        if (loading && isArmActivated) setLoading(false)
    }, [loading, isArmActivated])

    const handleClick = async () => {
        const clonedGeneralInputs = cloneDeep(generalInputs)
        clonedGeneralInputs.loanDetails.interestType = 'Variable Rate'
        clonedGeneralInputs.loanDetails.ARMType = ARMType.structured
        setLoading(true)
        const isSuccessful = await SagaHelper.run(
            ['lenderGeneralInputs', 'setGeneralInputs'],
            {
                data: clonedGeneralInputs,
                projectId,
            },
            '',
        )
        if (!isSuccessful) {
            showErrorNotification('Error changing General Inputs')
            setLoading(false)
        }
    }

    return (
        <>
            {isArmActivated ? (
                <FormWrapper isArmPage component={AdjustablePricingForm} isNM={props.isNM} />
            ) : (
                <>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <div className={'addDiv'}>
                            <div onClick={handleClick} className={'addBtn'}>
                                New Loan Pricing
                            </div>
                        </div>
                    )}
                </>
            )}
            {/*language=scss*/}
            <style jsx>
                {`
                    .addDiv {
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    .addBtn {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        color: #ffffff;
                        width: 144px;
                        height: 36px;
                        background: #4486ff;
                        border-radius: 4px;
                        padding-top: 10px;
                        cursor: pointer;
                    }
                `}
            </style>
        </>
    )
})

const AdjustablePricingForm: FunctionComponent<Props> = (props: Props) => {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values
    const isSupplemental = props.isSupplemental

    const [hasArmOptions, setHasArmOptions] = useState(false)

    const optionTitleTopOffset = useRef()

    const isArm76 = useMemo(() => {
        return generalInputs?.loanDetails?.ARMType === ARMType.notStructured
    }, [generalInputs?.loanDetails?.ARMType])

    useEffect(() => {
        if (isArm76) {
            formValues?.fanniePricing?.pricingOptions?.forEach((option, i) => {
                if (option.isArm && option.name.match(/Structured ARM #\d+/)) {
                    const newName = option.name.replace('Structured ARM', 'ARM 7-6')
                    form.change(`fanniePricing.pricingOptions.${i}.name`, newName)
                }
            })
        } else {
            formValues?.fanniePricing?.pricingOptions?.forEach((option, i) => {
                if (option.isArm && option.name.match(/ARM 7-6 #\d+/)) {
                    const newName = option.name.replace('ARM 7-6', 'Structured ARM')
                    form.change(`fanniePricing.pricingOptions.${i}.name`, newName)
                }
            })
        }
    }, [isArm76])

    const resetPrepayment = useCallback(
        (idx) => {
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`, null)
            form.submit()
        },
        [form],
    )

    const addPricing = useCallback(() => {
        const { id: fixedDealId } = form.mutators.addItem({
            isArm: false,
            isDefaultFixedRate: true,
        })
        const selectedOption = formValues?.fanniePricing?.pricingOptions.find((item) => item.isSelected && !item.isArm)
        const { id: armDealId } = form.mutators.addItem({
            isArm: true,
            fixedOptionReferenceId: selectedOption?.id || selectedOption?.id === 0 ? selectedOption?.id : fixedDealId,
            fixedOptionLoanTerm:
                selectedOption?.id || selectedOption?.id === 0
                    ? selectedOption?.scenario?.underwritten?.financeOptions?.loanTerm
                    : 10,
        })

        form.mutators.changeArmOptionRef(fixedDealId, [armDealId])
        if (!!selectedOption) {
            if (!!selectedOption.armOptionsReferenceIds?.length) {
                form.mutators.changeArmOptionRef(selectedOption.id, [
                    ...selectedOption.armOptionsReferenceIds,
                    armDealId,
                ])
            } else {
                form.mutators.changeArmOptionRef(selectedOption.id, [armDealId])
            }
        }
        if (!!selectedOption) {
            const armOptionIdx = formValues?.fanniePricing?.pricingOptions.findIndex(
                (option) => option.id === armDealId,
            )

            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.sizer.underwritten.fixedRateSizing`,
                selectedOption.sizer.underwritten.maxFirstPosLoan,
            )
            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.sizer.actual.fixedRateSizing`,
                selectedOption.sizer.actual.maxFirstPosLoan,
            )
        }
    }, [formValues?.fanniePricing?.pricingOptions])

    const onlyArmOptions = useMemo(
        () =>
            formValues?.fanniePricing?.pricingOptions?.filter(
                (option) => option.isArm && !!option.fixedOptionReferenceId,
            ),
        [formValues?.fanniePricing?.pricingOptions],
    )

    useEffect(() => {
        if (!!onlyArmOptions && onlyArmOptions.length === 0) {
            addPricing()
        }
    }, [onlyArmOptions])

    useEffect(() => {
        if (!!formValues?.fanniePricing?.pricingOptions) {
            formValues.fanniePricing.pricingOptions.forEach((option) => {
                if (option.isArm) {
                    if (
                        !formValues.fanniePricing.pricingOptions.find(
                            (item) => item.isDefaultFixedRate && item.armOptionsReferenceIds.includes(option.id),
                        )
                    ) {
                        const { id: fixedDealId } = form.mutators.addItem({
                            isArm: false,
                            isDefaultFixedRate: true,
                        })
                        form.mutators.changeArmOptionRef(fixedDealId, [option.id])
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        if (form) form.submit()
    }, [formValues])

    useEffect(() => {
        setHasArmOptions(!!formValues?.fanniePricing?.pricingOptions.find((option) => option.isArm))
    }, [formValues?.fanniePricing])

    const refreshRates = useCallback(async () => {
        formValues?.fanniePricing?.pricingOptions?.map((t, idx) => {
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.indexRate`, 0)
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.indexRate`, 0)
        })
        form.change('computed.refreshIndexRate', new Date().getTime())
    }, [form])

    const changeOptionCollapsed = useCallback(
        (idx, value) => {
            form.change(`fanniePricing.pricingOptions.${idx}.isCollapsed`, value)
        },
        [form],
    )

    const removeOption = useCallback(
        (idx) => {
            form.mutators.removeItem(idx)
            const fixedOptionReferenceId = formValues?.fanniePricing?.pricingOptions[idx]?.fixedOptionReferenceId
            let fixedOptionReferenceIdx
            const fidexOption = formValues?.fanniePricing?.pricingOptions.find((option, index) => {
                if (option.id === fixedOptionReferenceId) {
                    fixedOptionReferenceIdx = index
                    return true
                }
                return false
            })
            if (fidexOption?.isDefaultFixedRate) form.mutators.removeItem(fixedOptionReferenceIdx)
        },
        [form],
    )

    const changeOptionSelected = useCallback(
        (idx, value) => {
            form.change(`fanniePricing.pricingOptions.${idx}.isSelected`, value)
        },
        [form],
    )

    const selectOffer = useCallback(
        (idx) => {
            form.mutators.selectOffer(idx)
        },
        [form],
    )

    const getUnderwrittenDSCRperUnit = useCallback(
        (idx) => {
            return model?.numberOfUnits
                ? formValues?.fanniePricing.pricingOptions[idx].sizer.underwritten.debtServiceConstrainedLoan /
                      model.numberOfUnits
                : 0
        },
        [model, formValues],
    )

    const getActualDSCRperUnit = useCallback(
        (idx) => {
            return model?.numberOfUnits
                ? formValues?.fanniePricing.pricingOptions[idx].sizer.actual.debtServiceConstrainedLoan /
                      model.numberOfUnits
                : 0
        },
        [model, formValues],
    )

    const changeFixedOptionRef = (armOptionId, fixedOptionId) => {
        form.mutators.changeFixedOptionRef(armOptionId, fixedOptionId)

        const fixedOption = formValues?.fanniePricing?.pricingOptions.find((option) => option.id === fixedOptionId)
        const armOptionIdx = formValues?.fanniePricing?.pricingOptions.findIndex((option) => option.id === armOptionId)

        form.batch(() => {
            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.sizer.underwritten.fixedRateSizing`,
                fixedOption.sizer.underwritten.maxFirstPosLoan,
            )
            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.sizer.actual.fixedRateSizing`,
                fixedOption.sizer.actual.maxFirstPosLoan,
            )
            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.scenario.underwritten.financeOptions.loanTerm`,
                fixedOption.scenario.underwritten.financeOptions.loanTerm,
            )
            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.scenario.actual.financeOptions.loanTerm`,
                fixedOption.scenario.underwritten.financeOptions.loanTerm,
            )
            form.change(
                `fanniePricing.pricingOptions.${armOptionIdx}.creditFees.loanTerms.loanTerm`,
                fixedOption.scenario.underwritten.financeOptions.loanTerm,
            )
        })
    }

    const changeArmOptionsRefs = (fixedOptionId, armOptionId) => {
        const fixedOption = formValues?.fanniePricing?.pricingOptions.find((option) => option.id === fixedOptionId)
        if (fixedOption?.armOptionsReferenceIds?.includes(armOptionId)) return

        let armOptionsIds
        if (!!fixedOption?.armOptionsReferenceIds?.length) {
            armOptionsIds = [...fixedOption?.armOptionsReferenceIds, armOptionId]
        } else {
            armOptionsIds = [armOptionId]
        }
        form.mutators.changeArmOptionRef(fixedOptionId, armOptionsIds)
    }

    const changeExitAnalysisField = useCallback(
        (idx, type, field, value) => {
            form.change(`fanniePricing.pricingOptions.${idx}.exitAnalysis.${type}.${field}`, value)
        },
        [form],
    )

    const [showLoanSizerExpand, setshowLoanSizerExpand] = useState(true)
    const toggleshowLoanSizerExpand = useCallback(
        (e: SyntheticEvent) => {
            setshowLoanSizerExpand((state) => !state)
        },
        [showLoanSizerExpand],
    )

    const [showLoanScenarioExpand, setshowLoanScenariorExpand] = useState(true)
    const toggleshowLoanScenarioExpand = useCallback(
        (e: SyntheticEvent) => {
            setshowLoanScenariorExpand((state) => !state)
        },
        [showLoanScenarioExpand],
    )

    const [showRateBreakdownExpand, setshowRateBreakdownExpand] = useState(true)
    const toggleshowRateBreakdownExpand = useCallback(
        (e: SyntheticEvent) => {
            setshowRateBreakdownExpand(!showRateBreakdownExpand)
        },
        [showRateBreakdownExpand],
    )

    const [showCreditFeesExpand, setshowCreditFeesExpand] = useState(true)
    const toggleshowCreditFeesExpand = useCallback(
        (e: SyntheticEvent) => {
            setshowCreditFeesExpand((state) => !state)
        },
        [showCreditFeesExpand],
    )

    const [showCapCostExpand, setshowCapCostExpand] = useState(true)
    const toggleshowCapCostExpand = useCallback(
        (e: SyntheticEvent) => {
            setshowCapCostExpand((state) => !state)
        },
        [showCapCostExpand],
    )

    const [showExitAnalysisSummaryExpand, setshowExitAnalysisSummaryExpand] = useState(true)
    const toggleshowExitAnalysisSummaryExpand = useCallback(
        (e: SyntheticEvent) => {
            setshowExitAnalysisSummaryExpand((state) => !state)
        },
        [showExitAnalysisSummaryExpand],
    )

    return (
        <>
            <div className={'MainPricingForm'}>
                <div className={'addDiv'}>
                    <div onClick={addPricing} className={'addBtn'}>
                        New Loan Pricing
                    </div>
                </div>
                <div id={'hiddenValues'} style={{ display: 'none' }}>
                    {Object.keys(formValues.computed).map((name, index) => (
                        <div key={'hiddendiv' + index}>
                            {name}
                            <OnlyNumberField name={`computed.${name}`} />
                        </div>
                    ))}
                </div>
                {hasArmOptions && (
                    <div className={'MainTables'}>
                        <div className={'sizerAndScenarioTablesMain'}>
                            <div className={'titlesMain'}>
                                <div className={'titlesColumns'}>
                                    <div className={'titleCol'}>
                                        <div className={'title empty'} style={{ backgroundColor: 'white ' }}></div>
                                    </div>
                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm && !!option.fixedOptionReferenceId) {
                                            let fixedOptionIdx
                                            const fixedOption = formValues?.fanniePricing?.pricingOptions.find(
                                                (item, idx) => {
                                                    if (item.id === option.fixedOptionReferenceId) {
                                                        fixedOptionIdx = idx
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                },
                                            )
                                            const onlyArmOptions = formValues.fanniePricing.pricingOptions.filter(
                                                (option) => option.isArm && option.fixedOptionReferenceId,
                                            )

                                            const orderSelectboxOptions = onlyArmOptions.map((option, i) => ({
                                                label: i + 1,
                                                value: option.id,
                                            }))

                                            let defaultFixedDeal
                                            const fixedDealSelectOptions = formValues.fanniePricing.pricingOptions
                                                .filter((item) => !item.isArm)
                                                .filter((item) => {
                                                    if (!item.isDefaultFixedRate) {
                                                        return true
                                                    } else {
                                                        if (
                                                            !!item.armOptionsReferenceIds &&
                                                            item.armOptionsReferenceIds.includes(option.id)
                                                        ) {
                                                            defaultFixedDeal = item
                                                            return false
                                                        } else {
                                                            return false
                                                        }
                                                    }
                                                })
                                                .map((item) => ({ label: item.name, value: item.id }))
                                            if (!!defaultFixedDeal) {
                                                fixedDealSelectOptions.unshift({
                                                    label: isSupplemental
                                                        ? 'FNMA Supplemental Fixed'
                                                        : 'New Fixed Rate',
                                                    value: defaultFixedDeal.id,
                                                })
                                            }
                                            return (
                                                <Fragment key={'titleoption' + idx}>
                                                    <OptionTitle
                                                        option={fixedOption}
                                                        ref={optionTitleTopOffset}
                                                        allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        idx={fixedOptionIdx}
                                                        armOptionId={option.id}
                                                        armReferenceToFixed={option.fixedOptionReferenceId}
                                                        changeOptionCollapsed={changeOptionCollapsed}
                                                        removeOption={removeOption}
                                                        changeFixedOptionRef={changeFixedOptionRef}
                                                        changeArmOptionsRefs={changeArmOptionsRefs}
                                                        isArm
                                                        fixedDealSelectOptions={fixedDealSelectOptions}
                                                    />

                                                    <OptionTitle
                                                        option={option}
                                                        ref={optionTitleTopOffset}
                                                        allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        idx={idx}
                                                        changeOptionCollapsed={changeOptionCollapsed}
                                                        removeOption={removeOption}
                                                        isArm
                                                        orderSelectboxOptions={orderSelectboxOptions}
                                                        form={form}
                                                    />
                                                </Fragment>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>
                            </div>
                            <div className={'sizerMain'}>
                                <div className={'sectionsTitle'}>
                                    <span className={'collapseSpan'} onClick={toggleshowLoanSizerExpand}>
                                        <i className="material-icons-outlined">
                                            {showLoanSizerExpand ? 'expand_less' : 'expand_more'}
                                        </i>
                                    </span>
                                    Loan Sizer
                                </div>

                                {showLoanSizerExpand && (
                                    <div className={'sizerColumns'}>
                                        <SizerTitlesCol isSupplemental={isSupplemental} isArm isNM={props.isNM} />
                                        {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                            if (option.isArm && !!option.fixedOptionReferenceId) {
                                                let fixedOptionIdx
                                                const fixedOption = formValues?.fanniePricing?.pricingOptions.find(
                                                    (item, idx) => {
                                                        if (item.id === option.fixedOptionReferenceId) {
                                                            fixedOptionIdx = idx
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    },
                                                )
                                                return (
                                                    <Fragment key={'sizeroption' + idx}>
                                                        <LoanSizerColumn
                                                            option={fixedOption}
                                                            idx={fixedOptionIdx}
                                                            isSupplemental={isSupplemental}
                                                            getUnderwrittenDSCRperUnit={getUnderwrittenDSCRperUnit}
                                                            getActualDSCRperUnit={getActualDSCRperUnit}
                                                            changeOptionSelected={changeOptionSelected}
                                                            selectOffer={selectOffer}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                            isNM={props.isNM}
                                                        />
                                                        <LoanSizerColumn
                                                            option={option}
                                                            idx={idx}
                                                            isSupplemental={isSupplemental}
                                                            getUnderwrittenDSCRperUnit={getUnderwrittenDSCRperUnit}
                                                            getActualDSCRperUnit={getActualDSCRperUnit}
                                                            changeOptionSelected={changeOptionSelected}
                                                            selectOffer={selectOffer}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                            isNM={props.isNM}
                                                        />
                                                    </Fragment>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={'scenarioMain'}>
                                <div className={'sectionsTitle'}>
                                    <span className={'collapseSpan'} onClick={toggleshowLoanScenarioExpand}>
                                        <i className="material-icons-outlined">
                                            {showLoanScenarioExpand ? 'expand_less' : 'expand_more'}
                                        </i>
                                    </span>
                                    Loan Scenario
                                </div>
                                {showLoanScenarioExpand && (
                                    <div className={'scenariosColumns'}>
                                        <ScenarioTitlesCol />
                                        {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                            if (option.isArm && !!option.fixedOptionReferenceId) {
                                                let fixedOptionIdx
                                                const fixedOption = formValues?.fanniePricing?.pricingOptions.find(
                                                    (item, idx) => {
                                                        if (item.id === option.fixedOptionReferenceId) {
                                                            fixedOptionIdx = idx
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    },
                                                )
                                                return (
                                                    <Fragment key={'scenariooption' + idx}>
                                                        <LoanScenariosColumn
                                                            option={fixedOption}
                                                            idx={fixedOptionIdx}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                            isArm76={isArm76}
                                                            form={form}
                                                        />
                                                        <LoanScenariosColumn
                                                            option={option}
                                                            idx={idx}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                            isArm76={isArm76}
                                                            form={form}
                                                        />
                                                    </Fragment>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={'creditFeesTablsMain'}>
                                <div className={'titlesMain'}>
                                    <div className={'titlesColumns'}>
                                        <div className={'titleCol'}>
                                            <div className={'title empty'} style={{ backgroundColor: 'white' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'sectionsTitle'}>
                                    <span className={'collapseSpan'} onClick={toggleshowCreditFeesExpand}>
                                        <i className="material-icons-outlined">
                                            {showCreditFeesExpand ? 'expand_less' : 'expand_more'}
                                        </i>
                                    </span>
                                    Fees & Waivers
                                </div>
                                {showCreditFeesExpand && (
                                    <div className={'creditFeesColumns'}>
                                        <CreaditFeesTitlesCol isSupplemental={isSupplemental} />

                                        {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                            if (option.isArm && !!option.fixedOptionReferenceId) {
                                                let fixedOptionIdx
                                                const fixedOption = formValues?.fanniePricing?.pricingOptions.find(
                                                    (item, idx) => {
                                                        if (item.id === option.fixedOptionReferenceId) {
                                                            fixedOptionIdx = idx
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    },
                                                )
                                                return (
                                                    <Fragment key={'creditFeesOption' + idx}>
                                                        <FeesColumn
                                                            option={fixedOption}
                                                            idx={fixedOptionIdx}
                                                            isSupplemental={isSupplemental}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        />
                                                        <FeesColumn
                                                            option={option}
                                                            idx={idx}
                                                            isSupplemental={isSupplemental}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        />
                                                    </Fragment>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={'rateBreakdownMain'}>
                                <div className={'sectionsTitle'}>
                                    <span className={'collapseSpan'} onClick={toggleshowRateBreakdownExpand}>
                                        <i className="material-icons-outlined">
                                            {showRateBreakdownExpand ? 'expand_less' : 'expand_more'}
                                        </i>
                                    </span>
                                    Rate Breakdown
                                </div>
                                {showRateBreakdownExpand && (
                                    <div className={'rateBreakdownColumns'}>
                                        <RateBreakdownTitlesCol isArm refreshRates={refreshRates} />
                                        {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                            if (option.isArm && !!option.fixedOptionReferenceId) {
                                                let fixedOptionIdx
                                                const fixedOption = formValues?.fanniePricing?.pricingOptions.find(
                                                    (item, idx) => {
                                                        if (item.id === option.fixedOptionReferenceId) {
                                                            fixedOptionIdx = idx
                                                            return true
                                                        } else {
                                                            return false
                                                        }
                                                    },
                                                )
                                                return (
                                                    <Fragment key={'rateBreakdownOption' + idx}>
                                                        <RateBreakdownColumn
                                                            option={fixedOption}
                                                            idx={fixedOptionIdx}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        />
                                                        <RateBreakdownColumn
                                                            option={option}
                                                            idx={idx}
                                                            isArm
                                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        />
                                                    </Fragment>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={'capCostTableMain'}>
                            <div className={'titlesMain'}>
                                <div className={'titlesColumns'}>
                                    <div className={'titleCol'}>
                                        <div className={'title empty'} style={{ backgroundColor: 'white' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className={'sectionsTitle'}>
                                <span className={'collapseSpan'} onClick={toggleshowCapCostExpand}>
                                    <i className="material-icons-outlined">
                                        {showCapCostExpand ? 'expand_less' : 'expand_more'}
                                    </i>
                                </span>
                                Cap Cost Buildup
                            </div>

                            {showCapCostExpand && (
                                <div style={{ display: 'flex' }}>
                                    <CapCostTitlesCol />
                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm && !!option.fixedOptionReferenceId) {
                                            const fixedOption = formValues.fanniePricing.pricingOptions.find(
                                                (newOption) => newOption.id === option.fixedOptionReferenceId,
                                            )
                                            return (
                                                <Fragment key={'capCostOption' + idx}>
                                                    <div className={'capCostColumns'}>
                                                        <div
                                                            style={{ paddingRight: fixedOption.isCollapsed ? 60 : 320 }}
                                                        />

                                                        <CapCostColumn option={option} idx={idx} />
                                                    </div>
                                                </Fragment>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>
                            )}
                        </div>

                        <div className={'exitAnalysisSummaryMain'}>
                            <div className={'titlesMain'}>
                                <div className={'titlesColumns'}>
                                    <div className={'titleCol'}>
                                        <div className={'title empty'} style={{ backgroundColor: 'white' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className={'sectionsTitle'}>
                                <span className={'collapseSpan'} onClick={toggleshowExitAnalysisSummaryExpand}>
                                    <i className="material-icons-outlined">
                                        {showExitAnalysisSummaryExpand ? 'expand_less' : 'expand_more'}
                                    </i>
                                </span>
                                Exit Analysis Summary
                            </div>
                            {showExitAnalysisSummaryExpand && (
                                <div className={'exitAnalysisSummaryColumns'}>
                                    <ExitAnalysisSummaryTitlesCol />

                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm && !!option.fixedOptionReferenceId) {
                                            let fixedOptionIdx
                                            const fixedOption = formValues?.fanniePricing?.pricingOptions.find(
                                                (item, i) => {
                                                    if (item.id === option.fixedOptionReferenceId) {
                                                        fixedOptionIdx = i
                                                        return true
                                                    } else {
                                                        return false
                                                    }
                                                },
                                            )
                                            return (
                                                <Fragment key={'exitAnalysisSummaryOption' + idx}>
                                                    <ExitAnalysisSummaryCol
                                                        option={fixedOption}
                                                        isArm
                                                        allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        changeField={changeExitAnalysisField}
                                                        idx={fixedOptionIdx}
                                                    />
                                                    <ExitAnalysisSummaryCol
                                                        option={option}
                                                        isArm
                                                        allOptions={formValues?.fanniePricing?.pricingOptions}
                                                        changeField={changeExitAnalysisField}
                                                        idx={idx}
                                                    />
                                                </Fragment>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div style={{ height: '40px' }}></div>
            </div>
            {/*language=scss*/}
            <style jsx>
                {`
                    .MainPricingForm {
                    }
                    .MainTables {
                        background-color: white;
                        width: fit-content;
                        padding-bottom: 20px;
                    }
                    .sizerAndScenarioTablesMain {
                        background-color: white;
                    }
                    .creditFeesTablsMain {
                        margin-left: 0px;
                        background-color: white;
                    }
                    .creditFeesTabls {
                        display: flex;
                    }
                    .titlesColumns {
                        display: flex;
                    }
                    .sizerColumns {
                        display: flex;
                    }
                    .exitAnalysisSummaryColumns {
                        display: flex;
                    }
                    .scenarioMain {
                        margin-top: 40px;
                    }
                    .rateBreakdownMain {
                        margin-top: 40px;
                    }
                    .scenariosColumns {
                        display: flex;
                    }
                    .rateBreakdownColumns {
                        display: flex;
                    }
                    .creditFeesColumns {
                        display: flex;
                    }
                    .capCostColumns {
                        display: flex;
                    }
                    .title {
                        width: 360px;
                        height: 40px;
                        text-indent: 30px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        padding-top: 10px;
                    }
                    .title:nth-child(even) {
                        background-color: rgba(217, 224, 229, 0.15);
                    }
                    .sectionsTitle {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                    .collapseSpan {
                        cursor: pointer;
                        cursor: pointer;
                        top: 5px;
                        position: relative;
                        margin-right: 5px;
                    }
                    .addDiv {
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    .addBtn {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        color: #ffffff;
                        width: 144px;
                        height: 36px;
                        background: #4486ff;
                        border-radius: 4px;
                        padding-top: 10px;
                        cursor: pointer;
                    }
                `}
            </style>
        </>
    )
}
