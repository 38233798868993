import createDecorator from 'final-form-calculate'

export const getPriorLoansDecorator = () => {
    return createDecorator(
        {
            field: /supplemental.*.priorLien.intrestRate/,
            updates: (value, name, values: any) => {
                //console.log('priorLien', name, value)
                const out = {}
                if (!values.supplemental[1]?.priorLien.intrestRate) {
                    out['existingTotal.intrestRate'] = values.supplemental[0].priorLien.intrestRate
                } else {
                    out['existingTotal.intrestRate'] =
                        (values.supplemental[0].priorLien.intrestRate *
                            values.supplemental[0].priorLien.estimatedUpbAtSupClosing +
                            values.supplemental[1].priorLien.intrestRate *
                                values.supplemental[1].priorLien.estimatedUpbAtSupClosing) /
                        (values.supplemental[0].priorLien.estimatedUpbAtSupClosing +
                            values.supplemental[1].priorLien.estimatedUpbAtSupClosing)
                }

                return out
            },
        },
        {
            field: /supplemental.0.priorLien.closed/,
            updates: (value, name, values: any) => {
                const newDate =
                    typeof value == 'string' && value.match(/^[0-9]*$/) ? new Date(parseInt(value)) : new Date(value)
                // console.log('priorLien', name, value, newDate, values)
                newDate.setMonth(newDate.getMonth() + (newDate.getDate() == 1 ? 1 : 2))
                newDate.setDate(1)
                const maturityDate = new Date(newDate)
                maturityDate.setMonth(maturityDate.getMonth() + values.supplemental[0]._terms.termMonths)
                const out = {}
                out['supplemental.0.terms.firstPayment'] = newDate
                out['supplemental.0.terms.maturityDate'] = maturityDate
                out['supplemental.0.terms.termMonths'] = values.supplemental[0]._terms.termMonths
                out['supplemental.0.terms.termYears'] = values.supplemental[0]._terms.termMonths / 12
                out['supplemental.0.terms.ymMonths'] = values.supplemental[0]._terms.termMonths - 6
                out['supplemental.0.terms.ymYears'] = (values.supplemental[0]._terms.termMonths - 6) / 12
                out['supplemental.0.priorLien.maturityDate'] = maturityDate
                return out
            },
        },
        {
            field: /supplemental.0.priorLien.maturityDate/,
            updates: (value, name, values: any) => {
                const out = {}
                out['supplemental.0.terms.maturityDate'] = value
                return out
            },
        },
    )
}
