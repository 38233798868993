import { CashFlow, OriginationModelRedux } from '../../../../../../../store/types/OriginationModelRedux'
import { CashFlowGraphQl, OriginationModelReworked, SensitivityChartData, UnitMix } from '@generated/graphql'
import numeral from 'numeral'

import { getMetrics, regenerateCashFlow } from 'origination-model'
import { getCFRow } from './irrSensitivity'
import { ReduxHelper } from '../../../../../../../store'
const cloneDeep = require('lodash.clonedeep')

export function dscrSensivity(
    cashFlow: CashFlow,
    model: OriginationModelRedux,
    unitMix: UnitMix[],
): SensitivityChartData {
    const yearCount = model.exitAssumption.saleYearAnalysisPeriod
    const vacancy = []
    //Vacancy- ( 7%- 15%, increase factor 1%)
    for (let i = 0.07; i <= 0.15; i += 0.01) {
        vacancy.push(i)
    }
    let unitCount = 0
    let rent = 0
    for (const unit of unitMix) {
        rent += unit.rentprice * unit.numofunits
        unitCount += unit.numofunits
    }
    const avgUnitRent = Math.round(rent / unitCount)
    const arrMinus = []
    const arrPlus = []

    const step = 100
    for (let i = 1; i <= 2; i++) {
        arrMinus.push(Math.floor((avgUnitRent - i * step) / 100) * 100)
        arrPlus.push(Math.round((avgUnitRent + i * step) / 100) * 100)
    }
    const averageRent = [...arrMinus.reverse(), avgUnitRent, ...arrPlus]
    const data = []
    for (const row in averageRent) {
        const rowData = []
        for (const col in vacancy) {
            rowData.push(calcCell(cashFlow, model, vacancy[col], averageRent[row]))
        }
        data.push(rowData)
    }

    const out = {
        xAxisName: 'Vacancy',
        yAxisName: 'Average Rent',
        rowTitles: averageRent.map((el) => numeral(el).format('$0,0')),
        columnTitles: vacancy.map((el) => numeral(el).format('0.[00]%')),
        rowValues: averageRent,
        columnValues: vacancy,
        data,
    }

    return out
}
function calcCell(cashFlow: any, model: any, vacancy: number, averageRent: number) {
    let newModel: OriginationModelReworked = cloneDeep(model)
    let newCF: CashFlowGraphQl = cloneDeep(cashFlow)
    const years = newModel.exitAssumption.saleYearAnalysisPeriod
    newModel.underwritingAssumption.organicRentGrowth = new Array(years).fill(0)
    newModel.underwritingAssumption.vacancy = new Array(years).fill(vacancy)
    const adj = newModel.t12AdjustmentsIncome.find((r) => r.id == 'grossPotentialRent')
    adj.value = averageRent * 12
    const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
    newCF = regenerateCashFlow({
        cashFlow: newCF,
        model: newModel,
        unitMix: unitMix,
    })
    const metrics = getMetrics({ cashFlow: newCF, model: newModel })
    //console.log('GPR', newCF.rentalIncome[0].years)
    const avgDSCR = metrics.dscr
    newModel = null
    newCF = null
    return {
        value: numeral(avgDSCR).format('0.[00]'),
        severity: avgDSCR <= 1 ? 1 : avgDSCR < 1.1 ? 0.5 : 0,
    }
}
