import React from 'react'
import { MonthlyTabChart } from './MonthlyTabChart'
import { MonthlyCashFlowTable } from './MonthlyCashFlowTable'

export const MonthlyCashFlowTab = (props) => {
    return (
        <div className="cash-flow-tab-wrapper">
            <div className="widget-title">Acquisition Financing</div>
            <MonthlyTabChart />

            <MonthlyCashFlowTable />
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .cash-flow-tab-wrapper {
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                }
            `}</style>
        </div>
    )
}
