import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from 'store'
import { showErrorNotification } from 'components/pages/fullproject/notifications'
import { SagaHelper } from 'store/sagaHelper'
import { Spinner } from 'components/controls/Spinner'
import { KPIBox } from './KPIBox'
import { DateField } from 'components/controls/inputs/generalinputs/DateField'

type FormTypes = {
    availableUnits: string
    allUnitsAvailableDate: Date
    avgProjectedWeeklyMoveIns: string
}

type Props = {
    close: () => void
    avgWeeklyMoveIns: number | null
    rentRollDate: any
}

export const CreateLeaseUpModal = (props: Props) => {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)

    const [loading, setLoading] = useState(false)

    const { close, avgWeeklyMoveIns } = props

    const onSubmit = useCallback(
        async (values: FormTypes) => {
            setLoading(true)
            try {
                const response = await SagaHelper.run(['lenderLeaseUp', 'createLeaseUp'], {
                    projectId: project.id,
                    availableUnits: +values.availableUnits,
                    allUnitsAvailableDate: values.allUnitsAvailableDate,
                    avgProjectedWeeklyMoveIns: +values.avgProjectedWeeklyMoveIns,
                })

                if (!response) {
                    showErrorNotification('Failed creating lease up')
                    return
                } else {
                    close()
                }
            } catch (e) {
                console.log(e.message)
                setLoading(false)
                showErrorNotification('Failed creating lease up')
            }
        },
        [project],
    )

    const handleClose = (e: SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()
        close()
    }

    return (
        <div className={'lender-modal-layout'} onClick={handleClose}>
            <div className="lender-modal" onClick={(e) => e.stopPropagation()}>
                <h2 className="lender-modal-title">Create Lease-Up</h2>
                <div className="lender-modal-content">
                    {!loading && (
                        <Form
                            onSubmit={onSubmit}
                            validate={(values: FormTypes) => {
                                if (
                                    !values.availableUnits ||
                                    !values.allUnitsAvailableDate ||
                                    !values.avgProjectedWeeklyMoveIns ||
                                    +values.availableUnits < 0 ||
                                    +values.avgProjectedWeeklyMoveIns <= 0
                                ) {
                                    return {
                                        FORM_ERROR,
                                    }
                                }
                            }}
                            render={({ handleSubmit, submitting, hasValidationErrors }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="topMsg">
                                            Please enter your parameters, and we'll populate the Lease-Up projections
                                            automatically. You can tweak it as you go.
                                        </div>
                                        <div className="topMsg">The data from your Rent Roll shows:</div>
                                        <div className="row">
                                            <div className="col-6" style={{ paddingRight: 5 }}>
                                                <KPIBox
                                                    title={'Total Units'}
                                                    value={model?.numberOfUnits?.toString() || '-'}
                                                    className="createLeaseUpKpi"
                                                />
                                            </div>
                                            <div className="col-6" style={{ paddingLeft: 5 }}>
                                                <KPIBox
                                                    title={'Avg. Weekly Move-Ins'}
                                                    value={avgWeeklyMoveIns?.toString() || '-'}
                                                    className="createLeaseUpKpi"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="inputLabel">
                                                    Number of CO’s at this time <sup>*</sup>
                                                </div>
                                                <Field
                                                    component={'input'}
                                                    className="lender-modal-input"
                                                    name="availableUnits"
                                                    initialValue={''}
                                                    type="number"
                                                    style={{ height: 35 }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="inputLabel">
                                                    Expected 100% CO’s date <sup>*</sup>
                                                </div>
                                                <DateField
                                                    name={'allUnitsAvailableDate'}
                                                    type="date"
                                                    displayFormat={'MM/dd/yyyy'}
                                                    style={{ width: '100%', height: 35 }}
                                                    minDate={new Date(props.rentRollDate)}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <div className="inputLabel">
                                                    Avg. projected weekly move-ins <sup>*</sup>
                                                </div>
                                                <Field
                                                    component={'input'}
                                                    className="lender-modal-input"
                                                    name="avgProjectedWeeklyMoveIns"
                                                    initialValue={avgWeeklyMoveIns?.toString() || ''}
                                                    type="number"
                                                    style={{ height: 35 }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row buttons-row">
                                            <div className="col-6" style={{ paddingRight: 5 }} />
                                            <div className="col-6 lender-control-buttons" style={{ paddingLeft: 5 }}>
                                                <button className="cancel-button" onClick={close}>
                                                    Cancel
                                                </button>
                                                <button
                                                    className="create-button"
                                                    onClick={handleSubmit}
                                                    disabled={submitting || hasValidationErrors}
                                                >
                                                    Create Lease-Up
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        />
                    )}
                    {loading && (
                        <div className="spinner-wrapper">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .lender-modal-layout {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(14px);
                    z-index: 10;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lender-modal {
                        background: $white;
                        width: 550px;
                        border: 1px solid $border;
                        border-radius: 4px;
                        padding: 40px;
                        &-title {
                            color: $black;
                            font-size: 16px;
                            font-weight: 400;
                            margin-bottom: 50px;
                        }
                        &-content {
                            .topMsg {
                                color: #687893;
                                margin-bottom: 20px;
                            }

                            .inputLabel {
                                margin-bottom: 10px;
                                color: #687893;
                                font-size: 13px;
                            }

                            .multiple-ids-msg {
                                color: #687893;
                                margin-top: 10px;
                            }

                            .row {
                                margin-bottom: 20px;
                            }

                            .buttons-row {
                                margin-top: 40px;
                                margin-bottom: initial;
                            }

                            :global(.createLeaseUpKpi) {
                                width: 100%;
                                padding: 15px;
                                border-radius: 4px;
                                background-color: #f5f6f9;

                                :global(.title) {
                                    color: #687893;
                                    margin-bottom: 5px;
                                }

                                :global(.value) {
                                    font-size: 22px;
                                    font-weight: 500;
                                    color: #161c26;
                                }
                            }

                            .spinner-wrapper {
                                flex: 1;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }

                    :global(.lender-modal-input) {
                        border-radius: 3px;
                        background: $white;
                        border: 1px solid $border;
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                    }

                    .lender-control-buttons {
                        display: flex;
                        gap: 5px;

                        button {
                            font-size: 13px;
                            box-shadow: none;
                            border-radius: 4px;
                            height: 35px;

                            &.cancel-button {
                                color: $blue;
                                border: 1px solid $blue;
                                background: $white;
                                font-weight: 400;
                                width: 80px;
                            }

                            &.create-button {
                                color: $white;
                                font-weight: 500;
                                padding: 5px;
                                background: #5086fb;
                                flex: 1;
                                border: 1px solid transparent;
                            }

                            &:focus {
                                outline: none;
                            }

                            &:disabled {
                                opacity: 0.5;
                                cursor: initial;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
