import React from 'react'
import dynamic from 'next/dynamic'
import { CHART_COLOR } from '../../utils/constants/chartColors'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type ChartData = {
    name: string
    data: Array<number>
}

type Props = {
    title?: string
    data: Array<ChartData>
    labels: Array<string>
    titleColor?: string
    legendColor?: string
}

export function ApexLinearChart(props: Props) {
    const { title, data, labels } = props

    const options: any = {
        series: data,
        options: {
            chart: {
                height: 350,
                type: 'line' as any,
                toolbar: {
                    show: false,
                },
            },
            colors: CHART_COLOR.APEX_CHARTS_PALETTE_7,
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'bottom' as any,
                horizontalAlign: 'center',
                labels: {
                    colors: props?.legendColor ? [props.legendColor] : ['#fff'],
                },
            },
            stroke: {
                curve: 'smooth',
            },
            tooltip: {
                theme: 'dark',
            },
            title: {
                text: title || '',
                align: 'center',
                style: {
                    color: props?.titleColor ? props.titleColor : '#fff',
                },
            },
            grid: {
                row: {
                    colors: ['transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5,
                },
            },
            xaxis: {
                categories: labels,
            },
        },
    }

    return <Chart options={options.options as ApexOptions} series={options.series} type="line" height={350} />
}
