import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import { orgenizeCFForPresentation, regenerateCashFlow } from 'origination-model'
import numeral from 'numeral'

type Props = any

type CFLine = {
    title: string
    value: number
    comment: string
}
export const QuoteCFSummary = React.memo(function MainPricing(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = orgenizeCFForPresentation({
        cashFlow: regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix }),
    })
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const gpr = useMemo(() => {
        return cashFlow?.rentalIncome?.[0]?.adjustedT12
    }, [cashFlow?.rentalIncome?.[0]?.adjustedT12])
    const netRentIncome = useMemo(() => {
        let total = 0
        if (cashFlow.rentalIncome) {
            cashFlow.rentalIncome.map((item) => (total += item.adjustedT12))
        }
        if (cashFlow.rentalLoss) {
            cashFlow.rentalLoss.map((item) => (total += item.adjustedT12))
        }
        return total
    }, [cashFlow])

    const { otherIncome, otherIncomeComment } = useMemo(() => {
        let otherIncome = 0
        if (cashFlow.otherIncome) {
            cashFlow.otherIncome.map((item) => (otherIncome += item.adjustedT12))
        }

        let otherIncomeComment = model?.t12AdjustmentsIncome?.find((item) => item.id == 'otherIncomeMain')?.comment

        if (otherIncomeComment) {
            otherIncomeComment =
                otherIncomeComment.indexOf(',') > -1
                    ? otherIncomeComment.substring(0, otherIncomeComment.indexOf(','))
                    : otherIncomeComment
            otherIncomeComment = otherIncomeComment.replace(/\[.*\]/g, '')
            if (otherIncomeComment.indexOf('Borrower Proforma') > -1) {
                otherIncomeComment = props.isNM ? 'NM Pro Forma' : 'Borrower Proforma'
            } else if (otherIncomeComment.indexOf('Borrower Budget') > -1) {
                otherIncomeComment = 'Borrower Budget'
            } else if (otherIncomeComment.indexOf('Pro-forma') > -1 && props.isNM) {
                otherIncomeComment = 'NM Pro Forma'
            } else {
            }
        } else {
            otherIncomeComment = 'T-' + (model.t12Annualized || 12)
        }
        return { otherIncome, otherIncomeComment }
    }, [cashFlow, model.t12Annualized])

    const effectiveGrossIncome = useMemo(() => {
        return netRentIncome + otherIncome
    }, [netRentIncome, otherIncome])

    const { operatingExpense, expenses, capex } = useMemo(() => {
        const operatingExpense = {
            title: 'Operating Expenses',
            value: 0,
            comment: '',
        }
        const capex = {
            title: 'Replacement Reserves',
            value: 0,
            comment: '',
        }
        const expenses = []
        cashFlow?.operatingExpenses?.map((cfItem) => {
            let comment = cfItem.comment || model?.t12Adjustments?.find((item) => item.id == cfItem.id)?.comment
            if (comment) {
                comment = comment.indexOf(',') > -1 ? comment.substring(0, comment.indexOf(',')) : comment
                comment = comment.replace(/\[.*\]/g, '')
                if (comment.indexOf('Borrower Proforma') > -1) {
                    comment = props.isNM ? 'NM Pro Forma' : 'Borrower Proforma'
                } else if (comment.indexOf('Borrower Budget') > -1) {
                    comment = 'Borrower Budget'
                } else if (comment.indexOf('Pro-forma') > -1 && props.isNM) {
                    comment = 'NM Pro Forma'
                } else {
                }
            }
            // console.log('cfItem.id', cfItem.id)
            if (cfItem.id == 'replacementReserves') {
                capex.value = cfItem.adjustedT12
                capex.comment = comment
            } else {
                let cmt = comment
                if (cfItem.id == 'assetManagementFee') {
                    cmt = numeral(model.underwritingAssumption.assetManagementFee).format('0.[00]%')
                }
                if (cfItem.adjustedT12) {
                    const expense = {
                        title: cfItem.title.replace(/total/i, ''),
                        value: cfItem.adjustedT12,
                        comment: cmt,
                    }
                    if (cfItem.id == 'utilities') {
                        expense.title = 'Total Utilities + Trash'
                        if (!cmt || cmt == '') {
                            const comments = []
                            const subrows = model.t12Adjustments.find((adj) => adj.id == cfItem.id)?.subRows
                            if (subrows)
                                for (const sub of subrows) {
                                    if (sub.comment && sub.comment != '' && !comments.includes(sub.comment))
                                        comments.push(sub.comment)
                                }
                            expense.comment = comments.join(' / ')
                        }
                    }
                    expenses.push(expense)
                }
                operatingExpense.value += cfItem.adjustedT12
            }
        })
        operatingExpense.comment = numeral(operatingExpense.value / effectiveGrossIncome).format('0.[00]%') + ' of EGI'
        return { operatingExpense, expenses, capex }
    }, [cashFlow, effectiveGrossIncome, props.isNM])

    const netCF = useMemo(() => {
        return effectiveGrossIncome - operatingExpense.value - capex.value
    }, [effectiveGrossIncome, operatingExpense.value, capex.value])

    const capRate = useMemo(() => {
        if (model?.fanniePricing?.valuationComparison?.length > 0) {
            for (const v of model?.fanniePricing?.valuationComparison) {
                if (v.selected) return v.capRate
            }
            return 0.0525
        }
        return 0.0525
    }, [model?.fanniePricing?.valuationComparison])

    return (
        <>
            <div className={'QuoteCFSummary'}>
                {/*<div className={'title'}>{project?.accountName} Finance Estimated Underwriting</div>*/}
                <div className={'title'}>{props.isNM ? 'Northmarq ' : ''}Capital Finance Estimated Underwriting</div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className={'notes'}>notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={'txtLeft'}>Gross Potential Rent</td>
                            <td>{numeral(gpr).format('$0,0')}</td>
                            <td>
                                {numeral(model?.numberOfUnits ? gpr / 12 / model?.numberOfUnits : 0).format('$0,0')}
                                {' /unit/mo '}
                            </td>
                            <td className={'commentdata'}>
                                {cashFlow?.rentalIncome?.[0]?.comment || model?.t12AdjustmentsIncome?.[0]?.comment}
                            </td>
                        </tr>
                        <tr>
                            <td className={'bold txtLeft'}>Net Rental Income</td>
                            <td className={'bold'}>{numeral(netRentIncome).format('$0,0')}</td>
                            <td className={'bold'}>
                                {numeral(netRentIncome / 12).format('$0,0')}
                                {' /mo '}
                            </td>
                            <td className={'commentdata'}>
                                {gpr > 0 && Math.abs(0.95 - netRentIncome / gpr) < 0.0001
                                    ? '95% FNMA min'
                                    : cashFlow?.rentalLoss?.[0]?.comment ||
                                      model?.t12AdjustmentsIncome
                                          ?.find((item) => item.id == 'economic-vacancy')
                                          ?.comment?.replace('collections ', '')
                                          .replace('smooth ', '') ||
                                      model?.t12AdjustmentsIncome?.find((item) => item.id == 'vacancy')?.comment ||
                                      '95% FNMA min'}
                            </td>
                        </tr>
                        <tr>
                            <td className={'txtLeft'}>Other Income</td>
                            <td>{numeral(otherIncome).format('$0,0')}</td>
                            <td>
                                {numeral(model?.numberOfUnits ? otherIncome / model?.numberOfUnits : 0).format('$0,0')}
                                {' /unit '}
                            </td>
                            <td className={'commentdata'}>{otherIncomeComment}</td>
                        </tr>
                        <tr>
                            <td className={'bold txtLeft'}>Effective Gross Income</td>
                            <td className={'bold'}>{numeral(effectiveGrossIncome).format('$0,0')}</td>
                            <td className={'bold'}>
                                {numeral(effectiveGrossIncome / 12).format('$0,0')}
                                {' /mo '}
                            </td>
                            <td className={'commentdata'}></td>
                        </tr>

                        {expenses.map((e) => (
                            <tr key={e.title}>
                                <td className={'txtLeft'}>{e.title}</td>
                                <td>{numeral(e.value).format('$0,0')}</td>
                                <td>
                                    {numeral(model?.numberOfUnits ? e.value / model?.numberOfUnits : 0).format('$0,0')}
                                    {' /unit '}
                                </td>
                                <td className={'commentdata'}>{e.comment}</td>
                            </tr>
                        ))}

                        <tr>
                            <td className={'bold txtLeft'}>{operatingExpense.title}</td>
                            <td className={'bold'}>{numeral(operatingExpense.value).format('$0,0')}</td>
                            <td className={'bold'}>
                                {numeral(operatingExpense.value / 12).format('$0,0')}
                                {' /mo '}
                            </td>
                            <td className={'commentdata'}>{operatingExpense.comment}</td>
                        </tr>

                        <tr>
                            <td className={'txtLeft'}>{capex.title}</td>
                            <td>{numeral(capex.value).format('$0,0')}</td>
                            <td>
                                {numeral(model?.numberOfUnits ? capex.value / model?.numberOfUnits : 0).format('$0,0')}
                                {' /unit '}
                            </td>
                            <td className={'commentdata'}>{capex.comment}</td>
                        </tr>

                        <tr>
                            <td className={'bold txtLeft'}>Net Cash Flow</td>
                            <td className={'bold'}>{numeral(netCF).format('$0,0')}</td>
                            <td className={'bold'}>
                                {numeral(netCF / 12).format('$0,0')}
                                {' /mo '}
                            </td>
                            <td className={'commentdata'}></td>
                        </tr>

                        <tr>
                            <td className={'bold txtLeft'}>Assumed Value</td>
                            <td className={'bold'}>{numeral(netCF / capRate).format('$0,0')}</td>
                            <td className={'bold'}>
                                {numeral(netCF / capRate / 12).format('$0,0')}
                                {' /mo '}
                            </td>
                            <td className={'commentdata'}>{'@ ' + numeral(capRate).format('0.[000]%')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                .QuoteCFSummary {
                    text-align: right;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    .notes {
                        width: 200px;
                    }

                    color: #161c26;
                    .title {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;

                        // color: #ffffff;
                        color: black;
                        height: 49px;

                        // background: #002857;
                        background: transparent;
                        mix-blend-mode: normal;
                        width: 570px;
                        text-align: left;
                        padding-top: 10px;
                        text-indent: 10px;

                        border: 1px solid #ddd;
                        border-bottom: none;
                        line-height: 20px;
                    }
                    .commentdata {
                        text-align: left;
                    }
                    table {
                        width: 570px;
                        border: 1px solid #ddd;
                        border-top: none;
                    }
                    .bold {
                        font-weight: bold;
                    }
                    td {
                        padding-right: 10px;
                        height: 35px;
                        padding-left: 10px;
                        font-size: 10px;

                        height: 20px;
                    }
                    th {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: 0.5px;
                        //text-transform: uppercase;
                        color: #687893;
                        height: 35px;
                        text-align: left;
                        padding-left: 10px;
                        font-size: 10px;
                        //text-transform: uppercase;
                        height: 20px;
                    }
                    .txtLeft {
                        text-align: left;
                    }
                    tbody {
                        tr {
                            width: 650px;
                        }
                        tr:nth-child(even) {
                            background-color: rgba(217, 224, 229, 0.15);
                        }
                    }
                }
            `}</style>
        </>
    )
})
