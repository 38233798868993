import React, { SyntheticEvent, useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { useRouter } from 'next/router'
import { hideProgress, showErrorNotification, showProgress } from '../../../fullproject/notifications'
import { ReduxHelper } from '../../../../../store'
import { SagaHelper } from '../../../../../store/sagaHelper'

type FormTypes = {
    notesUrl: string
}

type Props = {
    notesUrl: string
    projectId: number
    close: (e: SyntheticEvent) => void
    setNoteModalVisible?: (boolean) => void
}

export const CreateNoteModal = (props: Props) => {
    const router = useRouter()
    const { close, setNoteModalVisible, projectId, notesUrl } = props
    const onSubmit = useCallback(
        async (values: FormTypes) => {
            await showProgress()
            try {
                await ReduxHelper.setIn(['lenderProjects', 'project', 'notesUrl'], values.notesUrl)
                await SagaHelper.run(['lenderProjects', 'setProjectNotesUrl'], {
                    projectId,
                    notesUrl: values?.notesUrl ? values.notesUrl : '',
                })
                setNoteModalVisible(false)
            } catch (e) {
                await showErrorNotification(e.message)
            } finally {
                await hideProgress()
            }
        },
        [router],
    )

    return (
        <div className={'notes-modal-layout'} onClick={close}>
            <div className="notes-modal" onClick={(e) => e.stopPropagation()}>
                <div className="close-button" onClick={close}>
                    X
                </div>
                <h2 className="notes-modal-title">Create a note</h2>
                <div className="notes-modal-content">
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Field
                                                component={'input'}
                                                className="notes-url-input"
                                                name="notesUrl"
                                                placeholder="Give the url to for note "
                                                initialValue={notesUrl ? notesUrl : ''}
                                            />
                                        </div>

                                        <div
                                            style={{ marginTop: '20px' }}
                                            className="col-12 d-flex justify-content-end notes-control-buttons"
                                        >
                                            <button className="btn-blue btn btn-primary regular" onClick={handleSubmit}>
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .notes-modal-layout {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(14px);
                    z-index: 10000;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    .notes-modal {
                        background: $white;
                        width: 500px;
                        border: 1px solid $border;
                        border-radius: 4px;
                        padding: 40px;
                        position: relative;
                        top: 15%;
                        .close-button {
                            position: absolute;
                            top: 0;
                            right: 10px;
                            color: #8691a2;
                            cursor: pointer;
                        }
                        &-title {
                            color: $black;
                            font-size: 14px;
                            font-weight: 400;
                            margin-bottom: 30px;
                        }
                        &-content {
                        }
                    }

                    :global(.notes-url-input) {
                        border-radius: 3px;
                        background: $white;
                        border: 1px solid $border;
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                    }

                    .notes-control-buttons {
                        button {
                            margin-left: 10px;
                            font-size: 13px;
                            box-shadow: none;
                            &.ghost {
                                color: $blue;
                                border: 1px solid $blue;
                                background: $white;
                                font-weight: 400;
                            }
                            &.regular {
                                font-weight: 500;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
