import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { ReduxStoreState } from '../../../../../store'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { DashboardDebtCoverage } from './widgets/DebtCoverage/DashboardDebtCoverage'
import { DashboardProforma } from './widgets/DashboardProforma'
import { OperatingExpenses } from './widgets/OperatingExpenses'
import { LenderDashboardTable } from './widgets/SensitivityAnalysis/LenderDashboardTable'
import { SensitivityChartData } from '@generated/graphql'
import { ExpensesByCategory } from './widgets/ExpencesByCategory/ExpensesByCategory'
import { GrossPotentialRent } from './widgets/GrossPotentialRent'
import { EffectiveGrossIncome } from './widgets/EffectiveGrossIncome'
import { NetCashFlow } from './widgets/NetCashFlow'
import { TotalOperatingExpensesWOReserves } from './widgets/TotalOperatingExpensesWOReserves'
import { CapexReplacementReserves } from './widgets/CapexReplacementReserves'
import { PropertyOverview } from './widgets/PropertyOverview'
import { ReturnMetrics } from './widgets/ReturnMetrics'
import { useSelector } from 'react-redux'
import { BorrowerGauges } from './gauges-blocks/BorrowerGauges'
import { LenderGauges } from './gauges-blocks/LenderGauges'
import { PropertyImage } from './widgets/PropertyImage'
import { SensitivityAnalysisBorrower } from './widgets/SensitivityAnalysisBorrower'
import { PropertyHistoryChart } from '../../../expose/propertyHistory/PropertyHistoryChart'
import { graphQLRequest } from '../../../../../store/graphQLClient'
import gql from 'graphql-tag'
import { UnitMixChart } from './widgets/UnitMix/UnitMixChart'
import { UnitMixTable } from './widgets/UnitMix/UnitMixTable'
import { ReviewsFromWeb } from './widgets/ReviewsFromWeb'

type Props = {
    address?: string
    user: any
    sensitivity: {
        sensitivityCOCvsIRR: SensitivityChartData
        sensitivityNOIvsFCFRent: SensitivityChartData
        sensitivityNOIvsFCFVacancyMortgage: SensitivityChartData
    }
}

export const Dashboard = (props: Props) => {
    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const [propertyHistory, setPropertyHistory] = useState(null)
    useEffect(() => {
        if (project?.id) {
            const loadHistory = function (attempt: number) {
                graphQLRequest(
                    gql`
                        query ($projectId: Int!) {
                            propertyHistory(projectId: $projectId) {
                                enabled
                                noi
                                propertyClass
                                historicalOccupancyPerformance {
                                    year
                                    propertyClassValue
                                    projectValue
                                }
                                historicalRentPerformance {
                                    year
                                    propertyClassValue
                                    projectValue
                                }
                                perUnitMonthlyRentalRates {
                                    label
                                    unitCount
                                    unitSize
                                    unitsPerYear {
                                        count
                                        year
                                    }
                                }
                                perSqFtMonthlyRentalRates {
                                    label
                                    unitCount
                                    unitSize
                                    unitsPerYear {
                                        count
                                        year
                                    }
                                }
                            }
                        }
                    `,
                    {
                        projectId: project.id,
                    },
                ).then((res) => {
                    // console.log('res?.data?.propertyHistory', res?.data?.propertyHistory)
                    if (attempt == 0 && !res?.data?.propertyHistory.enabled) {
                        graphQLRequest(
                            gql`
                                mutation ($projectId: Int!) {
                                    getPropertyHistory(projectId: $projectId)
                                }
                            `,
                            {
                                projectId: project.id,
                            },
                        ).then((res) => {
                            loadHistory(1)
                        })
                    } else {
                        //console.log('set   property ', attempt)
                        setPropertyHistory(res?.data?.propertyHistory)
                    }
                })
            }

            loadHistory(0)

            SagaHelper.run(['fullProjectManagementCompany', 'getData'], { projectId: project.id })
        }
    }, [project])

    const feedbacks = useSelector((state: ReduxStoreState) => state.fullProject.managementCompany.data.feedbacks)

    const accountType = useMemo(() => {
        return project?.accountType
    }, [project])
    return (
        <div className="container-fluid mt-4 px-0">
            {
                //project?.accountType == 'borrower' && (
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <PropertyOverview />
                    </div>
                    <div className="col-md-4 mb-4">
                        <ReturnMetrics />
                    </div>
                    <div className="col-md-4 mb-4">
                        <PropertyImage />
                    </div>
                </div>
                //)
            }
            <div className="row">
                <div className="col-md-4 mb-4">
                    <GrossPotentialRent />
                </div>

                <div className="col-md-4 mb-4">
                    <EffectiveGrossIncome />
                </div>

                <div className="col-md-4 mb-4">
                    <TotalOperatingExpensesWOReserves />
                </div>

                <div className="col-md-4 mb-4">
                    <DashboardDebtCoverage projectId={projectId} />
                </div>
                <div className="col-md-8 mb-4">
                    <DashboardProforma projectId={projectId} />
                </div>
                {accountType == 'borrower' && (
                    <>
                        <div className="col-md-4 mb-4">
                            <UnitMixChart project={project} />
                        </div>
                        <div className="col-md-8 mb-4">
                            <UnitMixTable project={project} />
                        </div>
                    </>
                )}
                <div className="col-md-4 mb-4">
                    <CapexReplacementReserves />
                </div>

                <div className="col-md-4 mb-4">
                    <NetCashFlow />
                </div>

                <div className="col-md-4 mb-4">
                    <OperatingExpenses projectId={projectId} title={'Op Ex Ratio w/o Reserves'} />
                </div>

                {accountType == 'lender' && <LenderGauges projectId={projectId} />}

                {accountType == 'borrower' && (
                    <div className="col-12">
                        <BorrowerGauges />
                    </div>
                )}

                {accountType == 'borrower' ? (
                    <div className="col-md-12 mb-4">
                        <SensitivityAnalysisBorrower projectId={projectId} />
                    </div>
                ) : (
                    <div className="col-md-12 mb-4">
                        <LenderDashboardTable projectId={projectId} />
                    </div>
                )}
                <div className={'col-lg-12'}>
                    <ExpensesByCategory />
                </div>

                <div className="col-md-4 mb-4">
                    {propertyHistory && (
                        <div className="chart-wrapper">
                            <>
                                <div className="title">Historical Occupancy Performance</div>
                                <PropertyHistoryChart
                                    items={propertyHistory?.historicalOccupancyPerformance || []}
                                    projectChartName={`${project?.name} Occupancy`}
                                    propertyChartName={`Class-${propertyHistory?.propertyClass} Occupancy`}
                                    chartTitle={'Historical Occupancy Performance'}
                                    titleColor={'#fff'}
                                    legendColor={'#000'}
                                />
                            </>
                        </div>
                    )}
                </div>

                <div className="col-md-4 mb-4">
                    <div className="chart-wrapper">
                        {propertyHistory && (
                            <>
                                <div className="title">Historical Rent Performance</div>
                                <PropertyHistoryChart
                                    items={propertyHistory?.historicalRentPerformance || []}
                                    projectChartName={`${project?.name} Effective Rent`}
                                    propertyChartName={`Class-${propertyHistory?.propertyClass} Rents`}
                                    chartTitle={'Historical Rent Performance'}
                                    titleColor={'#fff'}
                                    legendColor={'#000'}
                                />
                            </>
                        )}
                    </div>
                </div>

                <div className="col-md-4 mb-4">
                    <ReviewsFromWeb />
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .dashboard-title {
                    font-size: 1.25rem;
                    color: #ffffff;
                }
                .op-link {
                    border: 1px solid white;
                    text-decoration: none;
                    border-radius: 3px;
                    padding: 2px;
                }
                .chart-wrapper {
                    background-color: #fff;
                    margin: 0px;
                    border: 1px solid #d9e0e5;
                    height: 420px;
                    border-radius: 3px;
                    .title {
                        font-size: 15px;
                        font-weight: 500;
                        color: #161c26;
                        border-bottom: 1px solid #d9e0e5;
                        padding: 10px 20px;
                    }
                }
                .feedback-item {
                    padding: 20px;
                    margin: 20px 0;
                    transition: 0.2s all linear;
                    &__content {
                        font-size: 1rem;
                    }
                    &__author {
                        text-align: right;
                        font-size: 0.7rem;
                        color: rgba(0, 0, 0, 0.5);
                        font-style: italic;
                    }
                }
            `}</style>
        </div>
    )
}
