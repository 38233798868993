import React, { useMemo } from 'react'
import { LenderDashboardChart } from '../../LenderDashboardChart'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'

type Props = {}

export const MonthlyTabChart = (props: Props) => {
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel.cashFlow)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const series = useMemo(() => {
        const defaultSeries = [
            {
                name: 'Effective Gross Income',
                data: [],
            },
            {
                name: 'Total Operating Expenses',
                data: [],
            },
            {
                name: model?.useNCFnotNOI ? 'Net CF ' : 'NOI',
                data: [],
            },
            {
                name: 'Free Cash Flow',
                data: [],
            },
        ]

        cashFlow?.effectiveGrossIncome?.years?.map((item) => {
            for (let i = 0; i < 12; i++) {
                defaultSeries[0].data.push(item / 12)
            }
        })

        cashFlow?.totalOperatingExpenses?.years?.map((item) => {
            for (let i = 0; i < 12; i++) {
                defaultSeries[1].data.push(item / 12)
            }
        })

        cashFlow?.noi?.years?.map((item) => {
            for (let i = 0; i < 12; i++) {
                defaultSeries[2].data.push(item / 12)
            }
        })

        cashFlow?.cashFlowAfterDebtService?.months?.map((item) => {
            //for (let i = 0; i < 12; i++) {

            defaultSeries[3].data.push(item)
            //}
        })
        defaultSeries[0].data.pop()
        defaultSeries[1].data.pop()
        defaultSeries[2].data.pop()
        defaultSeries[3].data.pop()

        return defaultSeries
    }, [cashFlow])

    return <LenderDashboardChart series={series} xaxisTitle={'Months'} yaxisTitle={'Amount'} />
}
