import React from 'react'
import { Spinner } from '../../../controls/Spinner'
type Props = {
    error: string | null
    height?: string
}
export function SpinnerOrError(props: Props) {
    return (
        <div className={'content-loading'} style={{ height: props.height || '100%' }}>
            {props.error ? <div className={'error'}>{props.error}</div> : <Spinner />}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .content-loading {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $white;
                }
                .error {
                    color: red;
                    border-width: 0;
                }
            `}</style>
        </div>
    )
}
