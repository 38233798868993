import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import numeral from 'numeral'
import { OriginationModelReworked } from '@generated/graphql'
import { getUnitMixMetrics, getLoanMetrics, mortgagePaymentSchedule } from 'origination-model'
type Props = {
    isFineTuneOn?: boolean
    debt?: string
}

export const LoanSizing = React.memo(function LoanSizing(props: Props) {
    const isSenior = props.debt == 'Senior'
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)

    const debtService = cashFlow?.other.find((item) => item.id == 'debtServiceInterest') || null
    const debtServiceMez = cashFlow?.other.find((item) => item.id == 'debtServiceInterestMez') || null

    //console.log('debtServiceMez ' + props.debt, debtServiceMez)
    //console.log('debtService ' + props.debt, debtService)

    const unitMix = useSelector((state: ReduxStoreState) => state.lender?.unitMix)
    const unitMixMetrics = useMemo(() => (unitMix ? getUnitMixMetrics(unitMix) : null), [unitMix])
    const loanPerUnit = useMemo(() => {
        return model
            ? (isSenior ? model?.sources?.seniorDebt : model?.sources?.mezzanine) / unitMixMetrics?.numOfUnits
            : 0
    }, [unitMixMetrics, model])
    const loanPerSqFt = useMemo(() => {
        return model
            ? (isSenior ? model?.sources?.seniorDebt : model?.sources?.mezzanine) / unitMixMetrics?.rentableSizeSF
            : 0
    }, [unitMixMetrics, model])

    const loanMetrics = getLoanMetrics(model, cashFlow)

    const valuationCapRate = useSelector((state: ReduxStoreState) => state.lender?.loanSizer?.valuationCapRate)
    const valuation = cashFlow?.noi?.adjustedT12 ? cashFlow?.noi?.adjustedT12 / valuationCapRate : 0
    const ltvValuation =
        valuation > 0
            ? (isSenior
                  ? model?.sources?.seniorDebt
                  : model?.sources?.mezzanine > 0
                  ? model?.sources?.seniorDebt + model?.sources?.mezzanine
                  : 0) / valuation
            : 0

    const showLoanToPurchase = false
    return (
        <div className={'loan-sizing'}>
            <WidgetWrapper
                style={{ padding: 0 }}
                title={'Loan Sizing ' + props.debt}
                showProgress={!model || !cashFlow || !settings}
            >
                <div className={'top-grid-block'}>
                    <GreyDataBlock
                        title={'Loan Amount'}
                        value={numeral(isSenior ? model?.sources.seniorDebt : model?.sources.mezzanine).format('$0,0')}
                        subValue={''}
                    />
                    <GreyDataBlock title={'LTV'} value={numeral(ltvValuation).format('0.[00]%')} />
                    <GreyDataBlock
                        title={'LTC' + (showLoanToPurchase ? ' / Loan to Purchase Price' : '')}
                        value={
                            numeral(isSenior ? loanMetrics.ltc : loanMetrics.ltcMez).format('0.[00]%') +
                            (showLoanToPurchase
                                ? '  /  ' + numeral(isSenior ? loanMetrics.ltv : loanMetrics.ltvMez).format('0.[00]%')
                                : '')
                        }
                    />

                    <GreyDataBlock
                        title={'DSCR'}
                        value={numeral(isSenior ? loanMetrics.dscrAdjusted : loanMetrics.dscrMezAdjusted).format(
                            '0.[00]',
                        )}
                    />
                </div>
                <div className={'grid-block'}>
                    <DataBlock
                        title={'Loan Per Unit / Per SqFt'}
                        value={numeral(loanPerUnit).format('$0,0') + ' / ' + numeral(loanPerSqFt).format('$0,0.[00]')}
                    />
                    <DataBlock
                        title={'Debt Yield'}
                        value={numeral(
                            isSenior ? loanMetrics?.debtYieldAdjusted : loanMetrics?.debtYieldMezAdjusted,
                        ).format('0.[00]%')}
                    />
                    <DataBlock
                        title={model?.useNCFnotNOI ? 'Net CF' : 'NOI'}
                        value={numeral(cashFlow?.noi?.adjustedT12).format('$0,0')}
                    />
                    <DataBlock
                        title={'Debt Service'}
                        value={numeral(isSenior ? -debtService?.years[0] : -debtServiceMez?.years[0]).format('$0,0')}
                    />
                </div>
                <div className={'diviver'}></div>
                <div className={'grid-block'}>
                    <DataBlock
                        title={'Rate'}
                        value={numeral(
                            isSenior ? model?.debtAssumptionSenior.rate : model?.debtAssumptionMez?.rate,
                        ).format('0.[00]%')}
                    />
                    <DataBlock
                        title={'Amortization Period'}
                        value={
                            (isSenior
                                ? model?.debtAssumptionSenior.amortizationTerm
                                : model?.debtAssumptionMez?.amortizationTerm) + ' Years'
                        }
                    />
                    <DataBlock
                        title={'Term'}
                        value={
                            isSenior
                                ? model?.debtAssumptionSenior.loanTerm + ' Years'
                                : model?.debtAssumptionMez?.loanTerm
                                ? model?.debtAssumptionMez?.loanTerm + ' Years'
                                : ''
                        }
                    />

                    <DataBlock
                        title={'IO Period'}
                        value={
                            isSenior
                                ? model?.debtAssumptionSenior.ioPeriod + ' Years'
                                : model?.debtAssumptionMez?.ioPeriod
                                ? model?.debtAssumptionMez?.ioPeriod + ' Years'
                                : ''
                        }
                    />
                </div>
            </WidgetWrapper>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .loan-sizing {
                    :global(.widget-container) {
                        height: 344px;
                    }
                    :global(.widget-body) {
                        display: flex;
                        flex-direction: column;
                        .top-grid-block {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            grid-template-rows: 1fr;
                            grid-gap: 20px;
                            margin-bottom: 20px;

                            @media screen and (max-width: 1440px) {
                                grid-template-columns: ${props.isFineTuneOn ? '1fr 1fr' : '1fr 1fr 1fr 1fr'};
                                grid-template-rows: ${props.isFineTuneOn ? '1fr 1fr' : '1fr'};
                            }
                        }
                        .grid-block {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            grid-template-rows: auto;
                            grid-gap: 20px;
                            &:not(:last-child) {
                                @media screen and (max-width: 1440px) {
                                    grid-template-columns: ${props.isFineTuneOn ? '1fr 1fr' : '1fr 1fr 1fr 1fr'};
                                    grid-template-rows: ${props.isFineTuneOn ? '1fr 1fr' : '1fr'};
                                }
                            }
                        }
                    }
                    .diviver {
                        width: 100%;
                        background: #d9e0e5;
                        opacity: 0.5;
                        height: 1px;
                        margin: 20px 0;
                    }
                    .metrics-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        :global(.grey-bar) {
                            margin-right: 20px;
                        }
                        :global(.grey-bar:last-child) {
                            margin-right: 0;
                        }
                    }
                }
            `}</style>
        </div>
    )
})
type GrayBarProps = {
    title: string
    value: string
    subValue?: string
    style?: React.CSSProperties
}
function GreyDataBlock(props: GrayBarProps) {
    return (
        <div className={'grey-bar'} style={props.style}>
            <div className={'title'}>{props.title}</div>
            <div className={'value'}>{props.value}</div>
            <div className={'subValue'}>{props.subValue}</div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .grey-bar {
                        background: #f5f6f9;
                        border-radius: 4px;
                        height: 109px;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 15px;
                        .title {
                            font-size: 14px;
                            line-height: 18px;
                            color: #97a2b4;
                        }
                        .value {
                            font-weight: 500;
                            font-size: 21px;
                            line-height: 27px;
                            color: $default-text-color;
                            margin-top: 5px;
                        }
                        .subValue {
                            font-size: 15px;
                            line-height: 18px;
                            color: #687893;
                        }
                    }
                `}
            </style>
        </div>
    )
}
type DataBlockProps = {
    title: string
    value: string
}
function DataBlock(props: DataBlockProps) {
    return (
        <div className={'data-block'}>
            <div className={'title'}>{props.title}</div>
            <div className={'value'}>{props.value}</div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .data-block {
                    flex-grow: 1;
                    .title {
                        font-size: 13px;
                        line-height: 17px;
                        color: #97a2b4;
                    }
                    .value {
                        font-weight: 500;
                        font-size: 21px;
                        line-height: 25px;
                        color: $default-text-color;
                    }
                }
            `}</style>
        </div>
    )
}
