import { FV, RATE } from 'origination-model'
const isNumber = require('lodash/isNumber')
const isFinite = require('lodash/isFinite')

export function nmGetLTC(purchasePrice: any, loanAmount: any, budgetedCapEx: any) {
    return purchasePrice ? loanAmount / (purchasePrice * 1.03 + (budgetedCapEx || 0)) : 0
}

export function nmGetLTV(capRate: any, noi: any, loanAmount: any) {
    const uwValue = capRate ? noi / capRate : 0
    return uwValue ? loanAmount / uwValue : 0
}

export function nmGetTotalCostBasis(
    purchasePrice: any,
    escrowedImprovements: any,
    closingCostsMultiplier: number | null | undefined,
) {
    return (purchasePrice || 0) * (closingCostsMultiplier || 1.015) + (escrowedImprovements || 0)
}

export function nmGetMaximumBuiltUpCost(totalCostBasis) {
    // return totalCostBasis * 0.8
    return totalCostBasis
}

export function nmGetLTPP(purchasePrice: any, loanAmount: any) {
    return purchasePrice ? loanAmount / purchasePrice : 0
}

export function nmGetMaximumCashNeutralAmount(
    loanAmount: any,
    currentUPB: any,
    prepay: any,
    escrowedImprovements: any,
    purchasePrice: any,
    closingCostsMultiplier: number | null | undefined,
    isAcquisition: boolean,
) {
    if (isAcquisition) return (purchasePrice || 0) * (closingCostsMultiplier || 1.015) + (escrowedImprovements || 0)
    return (currentUPB || 0) + (prepay || 0) + (escrowedImprovements || 0)
}

export function pi(maxFirstPosLoan: any, actualLoanConstant: any) {
    return !!maxFirstPosLoan && !!actualLoanConstant ? (maxFirstPosLoan * actualLoanConstant) / 12 : null
}

export function balloon(actualRate: any, loanTerm: any, io: any, maxFirstPosLoan: any, pi: any, factor?: any) {
    if (!isFinite(io) || !isNumber(io)) io = 0
    if (!!actualRate && !!loanTerm && !!maxFirstPosLoan && !!pi) {
        const rate = (actualRate * (factor || 1)) / 12
        const periods = (loanTerm - io) * 12
        const result = FV(rate, periods, pi, -maxFirstPosLoan, 0)
        if (!!result && !(result instanceof Error)) return result
    }
    return null
}

export function exitRefiRate(amortization: any, ncf: any, dscr: any, balloon: any) {
    if (!!amortization && !!ncf && !!dscr && !!balloon) {
        const numberPeriods = amortization * 12
        const paymentPeriod = (ncf / dscr / 12) * -1

        let rate
        const guesses = [0.01, 0.03, 0.05]
        for (let guess of guesses) {
            const result = RATE(numberPeriods, paymentPeriod, balloon, 0, 0, guess)
            if (!(result instanceof Error)) {
                rate = result
                break
            }
        }

        if (!!rate) return rate * 12
    }
    return null
}

export function exitCapRate(ncf: any, balloon: any, ltv: any) {
    if (!!ncf && !!balloon && !!ltv) {
        return ncf / (balloon / ltv)
    }
    return null
}
