import { mortgagePaymentSchedule } from 'origination-model'

export const defaultRefinanceAssumptions = {
    ncf: {
        id: 'ncf',
        title: 'Net Cash Flow',
        years: [],
    },
    debtServiceNewLoan: {
        id: 'debtServiceNewLoan',
        title: 'Debt Service New Loan',
        years: [],
    },
    debtServiceExistingLoan: {
        id: 'debtServiceExistingLoan',
        title: 'Debt Service Existing Loan(s)',
        years: [],
    },
    totalDebtService: {
        id: 'totalDebtService',
        title: 'Total Debt Service',
        years: [],
    },
    combinedDscr: {
        id: 'combinedDscr',
        title: 'Combined DSCR',
        years: [],
    },
    newLoanDSCR: {
        id: 'newLoanDSCR',
        title: 'New Loan DSCR',
        years: [],
    },
    balanceNewLoan: {
        id: 'balanceNewLoan',
        title: 'Balance New Loan',
        years: [],
    },
    balanceExistingLoan: {
        id: 'balanceExistingLoan',
        title: 'Existing Loan(s)',
        years: [],
    },
    balanceCombined: {
        id: 'balanceCombined',
        title: 'Balance: Combined',
        years: [],
    },
    valueAtCapRate: {
        id: 'valueAtCapRate',
        title: 'Value at Cap Rate',
        years: [],
    },
    ltvAtCapRate: {
        id: 'ltvAtCapRate',
        title: 'LTV at Cap Rate',
        years: [],
    },
    upbDscrAtRefiRate: {
        id: 'upbDscrAtRefiRate',
        title: 'UPB DSCR at Refi Rate',
        years: [],
    },
}

export function calcDebtService(p: {
    loanAmount: number
    amortizationTermYears: number
    rate: number
    ioPeriodYears: number
    unit?: 'year' | 'month'
    periodYears: number
    firstYear?: number
    firstMonth?: number
}) {
    return mortgagePaymentSchedule(p)?.schedule || []
}
