import React from 'react'
import { ButtonBlueEdge } from '../Cashflow/ButtonBlueEdge'

type Props = {
    onClick: () => void
    className?: string
}

export function InsertColumn(props: Props) {
    return (
        <>
            <img
                className={props.className}
                onClick={props.onClick}
                src="/_img/lenderDashboard/add_row.svg"
                alt="edit"
            />
            {/*language=SCSS*/}
            <style jsx>
                {`
                    img {
                        transform: rotate(270deg);
                    }
                `}
            </style>
        </>
    )
}
