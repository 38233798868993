import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ReduxStoreState } from '../../../../../store'

import { OriginationModelRedux } from '../../../../../store/types/OriginationModelRedux'

import { useSelector } from 'react-redux'
import { MonthlyCashFlowTab } from './cashFlowTabs/monthly/MonthlyCashFlowTab'
import { FinancingPage } from './cashFlowTabs/financing/FinancingPage'
import { Spinner } from '../../../../controls/Spinner'
import { PromotePage } from './cashFlowTabs/promote/PromotePage'

import { BorrowerCashFlow } from './cashFlowTabs/BorrowerCashFlow/BorrowerCashFlow'
import { LenderCashFlow } from './cashFlowTabs/LenderCashFlow/LenderCashFlow'
import { RetailCashFlow } from './cashFlowTabs/RetailCashFlow/RetailCashFlow'

type Props = {
    model: OriginationModelRedux | null
    saveDeal?: any
}

export const CashFlowTab = React.memo(function CashFlowTab(props: Props) {
    const { model } = props
    const loading = !model

    const accountType = useSelector((state: ReduxStoreState) => state.lenderProjects?.project?.accountType)

    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const isNewDev = project.type && project.type == 'miltifamily.new_dev'

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const CASHFLOW_TAB = Object.freeze({
        OVERVIEW: 'OVERVIEW',
        MONTHLY_CASH_FLOW: 'MONTHLY_CASH_FLOW',
        FINANCING: 'FINANCING',
        PROMOTE: 'PROMOTE',
        ADJUSTED_PROJECTION: 'ADJUSTED_PROJECTION',
        RETAIL_PROJECTION: 'RETAIL_PROJECTION',
    })
    const [activeCashFlowTab, setActiveCashFlowTab] = useState(CASHFLOW_TAB.OVERVIEW)
    //console.log('model', model)
    const hasRetail = useMemo(() => {
        if (model?.unitMixRetail && model?.unitMixRetail.length > 0) return true
        return false
    }, [model, model?.unitMixRetail])
    return (
        <div className=" cash-flow-tab">
            <div className="dashboard-tabs-wrapper">
                <div
                    className={`dashboard-tab ${activeCashFlowTab == CASHFLOW_TAB.OVERVIEW ? 'active' : ''}`}
                    onClick={(e: SyntheticEvent) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setActiveCashFlowTab(CASHFLOW_TAB.OVERVIEW)
                    }}
                >
                    Overview
                </div>
                {(accountType == 'lender' || accountType == 'borrower') && (
                    <div
                        className={`dashboard-tab ${
                            activeCashFlowTab == CASHFLOW_TAB.ADJUSTED_PROJECTION ? 'active' : ''
                        }`}
                        onClick={(e: SyntheticEvent) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveCashFlowTab(CASHFLOW_TAB.ADJUSTED_PROJECTION)
                        }}
                    >
                        {isNewDev ? 'Underwriting Projections' : 'Adjusted Projection'}
                    </div>
                )}
                {hasRetail && (
                    <div
                        className={`dashboard-tab ${
                            activeCashFlowTab == CASHFLOW_TAB.RETAIL_PROJECTION ? 'active' : ''
                        }`}
                        onClick={(e: SyntheticEvent) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveCashFlowTab(CASHFLOW_TAB.RETAIL_PROJECTION)
                        }}
                    >
                        Retail
                    </div>
                )}
                <div
                    className={`dashboard-tab ${activeCashFlowTab == CASHFLOW_TAB.MONTHLY_CASH_FLOW ? 'active' : ''}`}
                    onClick={(e: SyntheticEvent) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setActiveCashFlowTab(CASHFLOW_TAB.MONTHLY_CASH_FLOW)
                    }}
                >
                    Monthly Cash Flow
                </div>
                <div
                    className={`dashboard-tab ${activeCashFlowTab == CASHFLOW_TAB.FINANCING ? 'active' : ''}`}
                    onClick={(e: SyntheticEvent) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setActiveCashFlowTab(CASHFLOW_TAB.FINANCING)
                    }}
                >
                    Financing
                </div>
                {(accountType == 'lender' || accountType == 'borrower') && (
                    <div
                        className={`dashboard-tab ${activeCashFlowTab == CASHFLOW_TAB.PROMOTE ? 'active' : ''}`}
                        onClick={(e: SyntheticEvent) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveCashFlowTab(CASHFLOW_TAB.PROMOTE)
                        }}
                    >
                        Promote
                    </div>
                )}
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {activeCashFlowTab == CASHFLOW_TAB.OVERVIEW && (
                        <>
                            {false && accountType == 'borrower' && (
                                <BorrowerCashFlow model={model} saveDeal={props.saveDeal} />
                            )}
                            {(accountType == 'lender' || accountType == 'borrower') && (
                                <LenderCashFlow saveDeal={props.saveDeal} />
                            )}
                        </>
                    )}

                    {activeCashFlowTab == CASHFLOW_TAB.MONTHLY_CASH_FLOW && <MonthlyCashFlowTab />}

                    {activeCashFlowTab == CASHFLOW_TAB.FINANCING && <FinancingPage />}
                    {(accountType == 'lender' || accountType == 'borrower') &&
                        activeCashFlowTab == CASHFLOW_TAB.PROMOTE && <PromotePage isDashboard={true} />}
                    {(accountType == 'lender' || accountType == 'borrower') &&
                        activeCashFlowTab == CASHFLOW_TAB.ADJUSTED_PROJECTION && (
                            <BorrowerCashFlow model={model} saveDeal={props.saveDeal} />
                        )}
                    {activeCashFlowTab == CASHFLOW_TAB.RETAIL_PROJECTION && <RetailCashFlow />}
                </>
            )}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .cash-flow-tab {
                        position: relative;
                        width: calc(100vw - 80px);
                        margin-top: 1.5rem !important;
                        margin-bottom: 1.5rem !important;
                        .dashboard-tabs-wrapper {
                            display: flex;
                            margin-bottom: 10px;
                            .dashboard-tab {
                                cursor: pointer;
                                margin-right: 5px;
                                background-color: rgba(151, 162, 180, 0.2);
                                border-radius: 3px;
                                color: $black;
                                border: 1px solid $border;
                                padding: 3px 13px;
                                font-size: 15px;
                                &.active {
                                    color: $blue;
                                    border: 1px solid $blue;
                                    background-color: rgba(68, 134, 255, 0.2);
                                }
                            }
                        }
                    }
                `}
            </style>
        </div>
    )
})
