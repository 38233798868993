import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { regenerateCashFlow } from 'origination-model'
import { nmGetLTC, nmGetLTV } from '../northMarkFormulas'
type Props = any
export const ConstructionAssumption = React.memo(function ConstructionAssumption(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })

    const loanAmount = useMemo(() => {
        return generalInputs?.loanDetails?.requestedLoanAmount
    }, [generalInputs?.loanDetails?.requestedLoanAmount])

    const noi = useMemo(() => {
        return cashFlow?.noi?.adjustedT12
    }, [cashFlow, cashFlow?.noi?.adjustedT12])

    const capRate = useMemo(() => {
        if (model?.fanniePricing?.valuationComparison?.length > 0) {
            for (const v of model.fanniePricing.valuationComparison) {
                if (v.selected) return v.capRate
            }
            return 0.0525
        }
        return 0.0525
    }, [model?.fanniePricing?.valuationComparison])

    const LTC = useMemo(() => {
        return nmGetLTC(model?.uses?.purchasePrice, loanAmount, generalInputs?.dealDetails?.budgetedCapEx)
    }, [model?.uses?.purchasePrice, loanAmount, generalInputs?.dealDetails?.budgetedCapEx])

    const LTV = useMemo(() => {
        return nmGetLTV(capRate, noi, loanAmount)
    }, [capRate, noi, loanAmount])

    return (
        <>
            <div className={'pricingAnalyticsComponent'}>
                <div className={'title'}>Construction Assumption</div>
                <div className={'mainPart'}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Recent Construction Assumption</td>
                                <td>{generalInputs?.dealDetails?.recentConstruction}</td>
                            </tr>
                            <tr>
                                <td>Construction Cost</td>
                                <td>{numeral(generalInputs?.dealDetails?.constructionCost).format('$0,0')}</td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '20px' }}>per unit</td>
                                <td>
                                    {numeral(
                                        model?.numberOfUnits
                                            ? generalInputs?.dealDetails?.constructionCost / model?.numberOfUnits
                                            : 0,
                                    ).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Budgeted Cap Ex</td>
                                <td>{numeral(generalInputs?.dealDetails?.budgetedCapEx).format('$0,0')}</td>
                            </tr>
                            <tr>
                                <td style={{ textIndent: '20px' }}>per unit</td>
                                <td>
                                    {numeral(
                                        model?.numberOfUnits
                                            ? generalInputs?.dealDetails?.budgetedCapEx / model?.numberOfUnits
                                            : 0,
                                    ).format('$0,0')}
                                </td>
                            </tr>

                            <tr>
                                <td className={'impText'}>LTV</td>
                                <td className={'impVal'}>{numeral(LTV).format('0.[00]%')}</td>
                            </tr>
                            <tr>
                                <td className={'impText'}>LTC (including Cap Ex)</td>
                                <td className={'impVal'}>{numeral(LTC).format('0.[00]%')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .pricingAnalyticsComponent {
                    background: white;
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-bottom: 20px;
                    width: 600px;
                    height: 366px;
                    margin-right: 20px;
                }
                .title {
                    border-bottom: 1px solid #d9e0e5;
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                }

                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;
                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                    td {
                        width: 300px;
                        &:nth-of-type(even) {
                            text-align: right;
                            padding-right: 20px;
                        }
                    }
                }
                .impText {
                    background-color: rgba(68, 134, 255, 0.1);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 27px;
                    color: #161c26;
                }
                .impVal {
                    background-color: rgba(68, 134, 255, 0.1);
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 27px;
                    color: #161c26;
                }
            `}</style>
        </>
    )
})
