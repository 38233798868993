import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ButtonBlueEdge } from '../../ButtonBlueEdge'
import { GreenButton } from '../../GreenButton'
import { CashFlowEdit } from '../../CashFlowEdit'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../store'
import { SagaHelper } from '../../../../../../../store/sagaHelper'
import { useRouter } from 'next/router'

import { CashFlow } from '../../../../../../../store/types/OriginationModelRedux'
import { CashFlowCommonButtons } from '../../../../components/CashFlowCommonButtons/CashFlowCommonButtons'
import { orgenizeCFForPresentation, regenerateCashFlow } from 'origination-model'
const cloneDeep = require('lodash.clonedeep')

type Props = {
    showKPIs?: boolean
    showOther?: boolean
    showTrend?: boolean
    showTxAndAdjusted?: boolean
    trendsName?: string
    updateModelUnderwritingAssumption?: boolean
    model?: any
    saveDeal?: any
    isDashboard?: boolean
}
export const BorrowerCashFlow = React.memo(function BorrowerCashFlow(props: Props) {
    // console.log('Borrower CF')
    const showKPIs = props.showKPIs != null ? props.showKPIs : true
    const showOther = props.showOther != null ? props.showOther : true
    const showTrend = props.showTrend != null ? props.showTrend : false
    const showTxAndAdjusted = props.showTxAndAdjusted != null ? props.showTxAndAdjusted : true

    const router = useRouter()
    const model = useMemo(() => {
        // console.log('model memo')
        if (props.model) return props.model
        else return ReduxHelper.store.getState().lender.originationModel
    }, [props.model])
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)

    const settings = useSelector((store: ReduxStoreState) => store.lender.projectSettings)

    const [mode, setMode] = useState('view')
    const toggleMode = useCallback(() => {
        if (mode == 'view') {
            setMode('edit')
        } else {
            setMode('view')
        }
    }, [mode])
    const formRef = React.createRef<any>()
    const viewRef = React.createRef<any>()

    const [cashFlow, setCashFlow] = useState(() => {
        // console.log('use memo cf')
        const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })
        return orgenizeCFForPresentation({ cashFlow: cashFlow })
    })

    //const [cfTrendTax, setCfTrendTax] = useState(null)

    const saveTrendsAndRecalcCF = useCallback(
        (cfTrends, underwritingAssumption) => {
            // console.log('saveTrendsAndRecalcCF')
            ReduxHelper.setIn(['lender', 'originationModel', 'cfTrends'], cloneDeep(cfTrends))
            if (props.updateModelUnderwritingAssumption)
                ReduxHelper.setIn(
                    ['lender', 'originationModel', 'underwritingAssumption'],
                    cloneDeep(underwritingAssumption),
                )
            //console.log('saveTrendsAndRecalcCF cfTrends', cloneDeep(cfTrends))
            const cashFlowTmp = regenerateCashFlow({
                model: ReduxHelper.store.getState().lender.originationModel,
                cashFlow: cashFlow,
                unitMix: unitMix,
                trendsName: props.trendsName,
            })
            // console.log('regenerateCashFlow with trends', cloneDeep(cashFlow.rentalIncome[0].years))
            const cashFlowTmpOrg = orgenizeCFForPresentation({ cashFlow: cashFlowTmp })
            setCashFlow(cashFlowTmpOrg)
            ReduxHelper.setIn(['lender', 'alternameCashFlows', props.trendsName], cloneDeep(cashFlowTmpOrg))
            return cashFlowTmpOrg
        },
        [cashFlow, props.trendsName, props.updateModelUnderwritingAssumption],
    )
    const isZeroArr = (arr: any) => {
        for (let i = 0; i < arr.length && i < 5; i++) if (arr[i]) return false
        return true
    }
    const getTrendInitValue = (model: any, id: string, group: string, adjValue: number, gpr: number) => {
        let trend = null
        //  if(adj.id=='grossPotentialRent' && isZeroArr(model.underwritingAssumption.organicRentGrowth))
        if (
            [
                'grossPotentialRent',
                'vacancy',
                'lossToLease',
                'badDebt',
                'concession',
                'economic-vacancy',
                'marketToMarket',
            ].includes(id)
        ) {
            const assumptionId =
                id == 'economic-vacancy'
                    ? 'vacancy'
                    : id == 'marketToMarket'
                    ? 'mtm'
                    : id === 'grossPotentialRent'
                    ? 'organicRentGrowth'
                    : id
            if (isZeroArr(model.underwritingAssumption[assumptionId])) {
                trend = id == 'grossPotentialRent' ? 0.02 : gpr ? Math.abs(adjValue / gpr) : null
                model.underwritingAssumption[assumptionId] = []
                for (let k = 0; k < model.exitAssumption.saleYearAnalysisPeriod; k++)
                    model.underwritingAssumption[assumptionId].push(trend)
            } else {
                trend = model.underwritingAssumption[assumptionId][0]
            }
        } else if (id == 'assetManagementFee') trend = model.underwritingAssumption.assetManagementFee
        /*
        else if (adj.id == 'replacementReserves') {
            if (!model.underwritingAssumption.replacementReserves[0]) {
                model.underwritingAssumption.replacementReserves = new Array(
                    model.exitAssumption.saleYearAnalysisPeriod,
                ).fill(0.03)
            }
            trend = model.underwritingAssumption.replacementReserves[0]
        } */ else if (group == 'rentalLoss') trend = gpr ? Math.abs(adjValue / gpr) : null
        else if (group == 'otherIncome' && model.underwritingAssumption.otherIncomeGrowth?.[0])
            trend = model.underwritingAssumption.otherIncomeGrowth[0]
        else if (getDefTrend(id) != null) trend = getDefTrend(id)
        else if (group == 'otherIncome') trend = 0.02
        else if (group == 'operatingExpenses' && model.underwritingAssumption.expenseGrown?.[0] != null)
            trend = model.underwritingAssumption.expenseGrown[0]
        else if (group == 'operatingExpenses') {
            model.underwritingAssumption.expenseGrown = new Array(model.exitAssumption.saleYearAnalysisPeriod).fill(
                0.03,
            )
            trend = model.underwritingAssumption.expenseGrown[0]
        }
        return trend
    }

    const cftrend = useMemo(() => {
        // console.log('get cftrend', props.trendsName)
        if (!showTrend) return null
        let cftrend = null

        if (props.trendsName && model) {
            if (!model.cfTrends) {
                // console.log('get cftrend no model trends')
                model.cfTrends = []
            }
            cftrend = model.cfTrends.find((t) => t.trendsName == props.trendsName)
            const gpr = model?.t12AdjustmentsIncome?.find((a) => a.id == 'grossPotentialRent')?.value
            if (!cftrend) {
                //  console.log('get cftrend no trend, creating', props.trendsName)
                const trends = []

                for (const list of ['t12AdjustmentsIncome', 't12Adjustments']) {
                    const adjList = model[list]
                    for (const adj of adjList) {
                        const trend = getTrendInitValue(model, adj.id, adj.group, adj.value, gpr)

                        trends.push({ id: adj.id, trend: trend })
                    }
                }
                const gprs = new Array(model.exitAssumption.saleYearAnalysisPeriod || 16).fill(null)

                //fill tax
                const taxTmp = createTaxArr(cashFlow, trends, 0.03, model.exitAssumption.saleYearAnalysisPeriod || 16)

                //console.log('taxTmp', taxTmp)
                cftrend = {
                    trendsName: props.trendsName,
                    trends: trends,
                    gpr: gprs,
                    tax: cloneDeep(taxTmp),
                }
                model.cfTrends.push(cftrend)
            } else {
                //console.log('model', model)
                // console.log('cftrend', cftrend)
                const trends = cftrend.trends

                const economicVacancy = model.t12AdjustmentsIncome.find((t: any) => t.id == 'economic-vacancy')
                let idsToAdd = []
                if (economicVacancy) {
                    idsToAdd = ['economic-vacancy']
                } else {
                    idsToAdd = ['vacancy', 'badDebt', 'concession', 'prepaids-adjustments']
                }

                const idsToExclude = ['economic-vacancy', 'vacancy', 'badDebt', 'concession', 'prepaids-adjustments']
                for (const list of ['t12AdjustmentsIncome', 't12Adjustments']) {
                    const adjList = model[list]
                    for (const adj of adjList) {
                        if (idsToExclude.includes(adj.id)) continue
                        const trend = adj ? trends.find((t: any) => t.id == adj.id) : null
                        if (!trend) {
                            idsToAdd.push(adj.id)
                        }
                    }
                }
                //  console.log('idsToAdd', idsToAdd)
                for (const id of idsToAdd) {
                    let adj = model.t12AdjustmentsIncome.find((t: any) => t.id == id)
                    if (!adj) adj = model.t12Adjustments.find((t: any) => t.id == id)
                    if (!adj) {
                        continue
                    }
                    let trend = adj ? trends.find((t: any) => t.id == adj.id) : null
                    if (!trend) {
                        trend = getTrendInitValue(model, adj.id, adj.group, adj.value, gpr)
                        trends.push({ id: adj.id, trend: trend })
                    }
                }
                //   console.log('trends', trends)
                // console.log('found  cftrend, checking if need override tax')
                const initValue = -cashFlow.operatingExpenses.find((t) => t.id == 'taxes')?.years?.[0] || 0
                //if tax changes in assumptions, need to  override
                if (Math.abs(cftrend.tax[0].val - initValue) > 0.01) {
                    //console.log('override taxes')
                    const taxTmp = createTaxArr(
                        cashFlow,
                        cftrend.trends,
                        0.03,
                        model.exitAssumption.saleYearAnalysisPeriod || 16,
                    )

                    const cftrendIdx = model.cfTrends.findIndex((t) => t.trendsName == props.trendsName)
                    model.cfTrends[cftrendIdx].tax = taxTmp
                }
            }
        } else {
        }
        saveTrendsAndRecalcCF(model.cfTrends, model.underwritingAssumption)
        //setCfTrendTax(cftrend.tax)
        return cftrend
    }, [props.trendsName, model])

    //  console.log('get cftrend', props.trendsName, cftrend)

    const updateTrends = useCallback(
        (sidx, value, type) => {
            let save = false

            let taxTmp = null
            const cftrend = model.cfTrends.find((t) => t.trendsName == props.trendsName)
            const idx = parseInt(sidx)
            //   console.log('updateTrends cftrend.trends', type, idx, value, cftrend.trends)

            if (type == 'trend') {
                if (!cftrend.trends[idx]) {
                    //  console.log('notrend', idx)
                    return
                }
                if (cftrend.trends[idx].trend != value) {
                    cftrend.trends[idx].trend = value
                    if (props.updateModelUnderwritingAssumption) {
                        const rowId = cftrend.trends[idx].id
                        let updateRedux = false
                        if (
                            [
                                'grossPotentialRent',
                                'vacancy',
                                'lossToLease',
                                'badDebt',
                                'concession',
                                'economic-vacancy',
                                'marketToMarket',
                                //    'replacementReserves',
                            ].includes(rowId)
                        ) {
                            const assumptionId =
                                rowId == 'economic-vacancy'
                                    ? 'vacancy'
                                    : rowId == 'marketToMarket'
                                    ? 'mtm'
                                    : rowId == 'grossPotentialRent'
                                    ? 'organicRentGrowth'
                                    : rowId
                            model.underwritingAssumption[assumptionId] = []
                            if (rowId == 'grossPotentialRent') model.underwritingAssumption['otherIncomeGrowth'] = []
                            for (let k = 0; k < model.exitAssumption.saleYearAnalysisPeriod; k++) {
                                model.underwritingAssumption[assumptionId].push(value)
                                if (rowId == 'grossPotentialRent')
                                    model.underwritingAssumption['otherIncomeGrowth'].push(value)
                            }
                            updateRedux = true
                        } else if (['assetManagementFee']) {
                            model.underwritingAssumption.assetManagementFee = value
                            updateRedux = true
                        }

                        if (rowId == 'grossPotentialRent') {
                            const otherIncomeLines = model?.t12AdjustmentsIncome?.filter(
                                (a) => a.group == 'otherIncome',
                            )
                            //  console.log('otherIncomeLines', otherIncomeLines)
                            for (const line of otherIncomeLines) {
                                const t = cftrend.trends.find((ct) => ct.id == line.id)
                                t.trend = value
                                //  console.log(line.id, t)
                            }
                            updateRedux = true
                        }
                        if (updateRedux)
                            ReduxHelper.setIn(
                                ['lender', 'originationModel', 'underwritingAssumption'],
                                cloneDeep(model.underwritingAssumption),
                            )
                    }
                    //  console.log('model assumptions', model.underwritingAssumption)
                    //  console.log('cftrend.trends[parseInt(idx)].id', cftrend.trends[parseInt(idx)].id)
                    if (cftrend.trends[idx].id == 'taxes') {
                        taxTmp = []

                        //fill tax
                        let initValue = cftrend.tax[0].val
                        taxTmp.push({
                            override: false,
                            val: initValue,
                        })
                        const taxTrend = value || 0.03
                        for (let i = 1; i < (model.exitAssumption.saleYearAnalysisPeriod || 16); i++) {
                            initValue =
                                cftrend.tax[i].override && cftrend.tax[i].val
                                    ? cftrend.tax[i].val
                                    : initValue * (1 + taxTrend)
                            taxTmp.push({
                                override: cftrend.tax[i].override,
                                val: initValue,
                                previousVal: cftrend.tax[i].val,
                            })
                        }
                        cftrend.tax = taxTmp
                        const cftrendIdx = model.cfTrends.findIndex((t) => t.trendsName == props.trendsName)
                        model.cfTrends[cftrendIdx].tax = taxTmp
                        //console.log('cftrend.tax on trend', cloneDeep(cftrend.tax))
                        //console.log('taxTmp on trend', cloneDeep(taxTmp))
                        //console.log('model.cfTrends after trebds', model.cfTrends)
                    }
                    save = true
                }
            } else if (type == 'gpr') {
                if (cftrend.gpr[idx] != value) {
                    cftrend.gpr[idx] = value
                    save = true
                }
            } else if (type == 'tax') {
                if (
                    value &&
                    cftrend?.tax?.[idx]?.previousVal != value &&
                    //cfTrendTax[idx].previousVal != value &&
                    cftrend.tax[idx].val != value
                    // &&  cfTrendTax[idx].val != value
                ) {
                    //console.log('change tax', idx, value, cloneDeep(cftrend.tax))
                    //  const cfTrendTaxTmp = cloneDeep(cfTrendTax)
                    const cfTrendTaxTmp = cloneDeep(cftrend.tax)
                    cfTrendTaxTmp[idx].val = value
                    cfTrendTaxTmp[idx].override = true

                    taxTmp = []
                    //fill tax
                    let initValue = cfTrendTaxTmp[0].val
                    taxTmp.push({
                        override: false,
                        val: initValue,
                    })
                    const taxTrend = cftrend?.trends?.find((t) => t.id == 'taxes')?.trend || 0.03
                    for (let i = 1; i < (model.exitAssumption.saleYearAnalysisPeriod || 16); i++) {
                        initValue = cfTrendTaxTmp[i].override && value ? value : initValue * (1 + taxTrend)
                        taxTmp.push({
                            override: cfTrendTaxTmp[i].override,
                            val: initValue,
                            // previousVal: cfTrendTax[i].val,
                            previousVal: cftrend.tax[i].val,
                        })
                    }
                    cftrend.tax = taxTmp
                    const cftrendIdx = model.cfTrends.findIndex((t) => t.trendsName == props.trendsName)
                    model.cfTrends[cftrendIdx].tax = taxTmp
                    //console.log('update 1', cloneDeep(taxTmp))
                    //console.log('update 2', cloneDeep(model.cfTrends[cftrendIdx]))
                    save = true
                }
            }
            if (save) saveTrendsAndRecalcCF(model.cfTrends, model.underwritingAssumption)
            //  if (taxTmp) setCfTrendTax(taxTmp)
            // if (taxTmp) console.log('taxTmp', cloneDeep(taxTmp))
        },
        [model?.cfTrends, model?.underwritingAssumption, props.trendsName /*, cfTrendTax*/],
    )

    const save = useCallback(() => {
        // console.log('BorrowerCashFlow save')
        const values = formRef.current?.getValues() as CashFlow
        if (!values) {
            return
        }

        SagaHelper.run(['lenderOriginationModel', 'saveCashFlow'], {
            cashFlow: values,
            projectId: +router.query.id,
        })
        ReduxHelper.setIn(['lender', 'originationModel', 'cashFlow'], cloneDeep(values))
        setMode('view')
    }, [formRef])
    return (
        <>
            <React.Fragment>
                <CashFlowCommonButtons saveDeal={props.saveDeal}>
                    {/*
                    {mode == 'view' ? (
                        <ButtonBlueEdge onClick={toggleMode} disabled={settings?.blockCFChanges || false}>
                            Edit <img src="/_img/lenderDashboard/edit.svg" alt="edit" />
                        </ButtonBlueEdge>
                    ) : (
                        <>
                            <ButtonBlueEdge onClick={toggleMode}>Cancel</ButtonBlueEdge>
                            <GreenButton onClick={save} style={{ marginLeft: '8px' }}>
                                Save Changes
                            </GreenButton>
                        </>
                    )}
                     */}
                </CashFlowCommonButtons>

                {mode == 'view' ? (
                    <CashFlowEdit
                        key="view"
                        data={cashFlow}
                        mode={'view'}
                        ref={viewRef}
                        save={save}
                        showKPIs={showKPIs}
                        showOther={showOther}
                        showTrend={showTrend}
                        showTxAndAdjusted={showTxAndAdjusted}
                        cfTrends={cftrend}
                        //cfTrendTax={cfTrendTax}
                        updateTrends={updateTrends}
                        trendName={props.trendsName}
                        model={model}
                        isDashboard={props.isDashboard}
                    />
                ) : (
                    <CashFlowEdit
                        key="edit"
                        data={cashFlow}
                        ref={formRef}
                        save={save}
                        showOther={showOther}
                        showTrend={showTrend}
                        showTxAndAdjusted={showTxAndAdjusted}
                        cfTrends={cftrend}
                        // cfTrendTax={cfTrendTax}
                        updateTrends={updateTrends}
                        trendName={props.trendsName}
                        model={model}
                        isDashboard={props.isDashboard}
                    />
                )}
                {/*language=SCSS*/}
                <style jsx>
                    {`
                        @import './src/scss/colors.scss';
                        .notes-button-wrapper {
                            display: inline-flex;
                            align-items: center;
                            height: 36px;
                            padding: 0 18.5px;
                            border-radius: 3px;
                            margin-right: 8px;
                            span {
                                cursor: pointer;
                            }
                            .edit-notes-button {
                                width: 10px;
                                height: auto;
                                border-top: 0.3em solid;
                                border-right: 0.3em solid transparent;
                                border-bottom: 0;
                                border-left: 0.3em solid transparent;
                            }
                        }

                        .buttons-bar {
                            position: absolute;
                            right: 0;
                            top: -60px;
                            display: flex;
                            align-items: center;
                        }
                        @media (max-width: 1025px) {
                            .buttons-bar {
                                position: relative;
                                right: 0;
                                top: 0;
                                margin-bottom: 10px;
                            }
                        }
                    `}
                </style>
            </React.Fragment>
        </>
    )
})

const DefualtTrend = [
    { id: 'grossPotentialRent', trend: 0.02 },
    { id: 'laundry-vending', trend: 0.02 },
    { id: 'parking', trend: 0.02 },
    { id: 'rubs', trend: 0.02 },
    { id: 'commercial', trend: 0.02 },
    { id: 'retailIncome', trend: 0.02 },
    { id: 'otherIncomeMain', trend: 0.02 },
    { id: 'otherIncome', trend: 0.02 },
    { id: 'short-term-premiums', trend: 0.02 },
    { id: 'insurance', trend: 0.03 },
    { id: 'waterandsewer', trend: 0.03 },
    { id: 'utilities', trend: 0.03 },
    { id: 'maintenance', trend: 0.03 },
    { id: 'payroll', trend: 0.03 },
    { id: 'advertisingmarketing', trend: 0.03 },
    { id: 'generaladministrative', trend: 0.03 },
    { id: 'professional-fees', trend: 0.03 },
    { id: 'ground-rent', trend: 0.03 },
    { id: 'other-expenses', trend: 0.03 },
    { id: 'taxes', trend: 0.03 },
    { id: 'assetManagementFee', trend: 0.03 },
    { id: 'replacementReserves', trend: 0 },
]
function getDefTrend(id: string) {
    const tmp = DefualtTrend.find((o) => o.id == id)
    if (tmp) return tmp.trend
    if (id.startsWith('otherIncome')) return 0.02
    if (id.startsWith('operatingExpenses')) return 0.03
    return 0
}

function createTaxArr(cashFlow: any, trends: any, defaultValue: number, period: number) {
    const taxTmp = []
    let initValue = -cashFlow?.operatingExpenses?.find((t) => t.id == 'taxes')?.years?.[0] || 0
    taxTmp.push({
        override: false,
        val: initValue,
    })
    const taxTrend = trends.find((t) => t.id == 'taxes')?.trend || defaultValue
    for (let i = 1; i < period; i++) {
        initValue = initValue * (1 + taxTrend)
        taxTmp.push({
            override: false,
            val: initValue,
        })
    }
    return taxTmp
}
