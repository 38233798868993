import React, { useCallback, useState, useEffect } from 'react'
import * as yup from 'yup'
import { SagaHelper } from 'store/sagaHelper'
import { ProjectGraphQl, VersionObject } from '@generated/graphql'
import { ModalLayout } from 'components/ModalLayout'
import { yupValidateObject } from 'utils'
import { REG_EXP } from 'utils/constants/regExps'
import { Spinner } from '../../../../controls/Spinner'

type Props = {
    project: ProjectGraphQl
    close: () => void
    customSubmitFn?: (
        versionIdToLoad: number,
        versionName: string,
        versionDescription: string,
        versionDate: string,
    ) => void
    isCopy?: boolean
    version?: VersionObject
}

export function SaveNarrativeVersion(props: Props) {
    const [versionName, setVersionName] = useState('')
    const [versionDescription, setVersionDescription] = useState('')
    const [defaultNarrativeId, setDefaultNarrativeId] = useState(null)
    const [versionsLoading, setVersionsLoading] = useState(false)

    useEffect(() => {
        const getDefaultNarrativeId = async () => {
            return await SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                projectId: props.project.id,
            })
        }
        if (!!props.project) {
            if (props.project.defaultNarrativeId === 0 || !!props.project.defaultNarrativeId) {
                setDefaultNarrativeId(props.project.defaultNarrativeId)
            } else {
                getDefaultNarrativeId().then((narrativeId) => {
                    if (!!narrativeId) setDefaultNarrativeId(narrativeId)
                })
            }
        }
    }, [props.project])

    const handleSubmit = useCallback(() => {
        if (
            (props.project.originalProjectId === 0 || !!props.project.originalProjectId) &&
            (defaultNarrativeId === 0 || !!defaultNarrativeId)
        ) {
            const errors = yupValidateObject(
                yup.object().shape({
                    versionName: yup.mixed().test('username', "Please enter version's name", (value) => {
                        return value && REG_EXP.NO_EMPTY.test(value)
                    }),
                    versionDescription: yup.mixed().test('username', "Please enter version's description", (value) => {
                        return value && REG_EXP.NO_EMPTY.test(value)
                    }),
                }),
                {
                    versionName,
                    versionDescription,
                },
            )

            if (!errors) {
                if (props.isCopy && !!props.version && !!props.customSubmitFn) {
                    props.customSubmitFn(props.version.versionId, versionName, versionDescription, props.version.date)
                    props.close()
                } else {
                    setVersionsLoading(true)
                    SagaHelper.run(['lenderProjects', 'saveNarrativeVersion'], {
                        projectId: props.project.originalProjectId,
                        versionName,
                        versionDescription,
                        narrativeId: defaultNarrativeId,
                    }).then(() => {
                        setTimeout(() => {
                            setVersionsLoading(false)
                            props.close()
                        }, 2000)
                    })
                }
            }
        }
    }, [
        props.project.originalProjectId,
        versionName,
        versionDescription,
        props.close,
        defaultNarrativeId,
        props.customSubmitFn,
        props.version,
        props.isCopy,
    ])

    const handleCloseClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        props.close()
    }

    const changeVersionName = (e) => {
        setVersionName(e.target.value)
    }

    const changeVersionDescription = (e) => {
        setVersionDescription(e.target.value)
    }

    return (
        <ModalLayout title={
            props.isCopy && !!props.version
                ? `Let's save work before loading ${props.version.name}`
                : `Save Narrative Version (${props.project.name || props.project.address})`
        }>
            {versionsLoading ? (
                <Spinner />
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <label>
                                Version Name:
                                <br />
                                <input
                                    name="versionName"
                                    type="text"
                                    value={versionName}
                                    onChange={changeVersionName}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label>
                                Version Description:
                                <br />
                                <textarea
                                    name="versionDescription"
                                    rows={4}
                                    cols={52}
                                    value={versionDescription}
                                    onChange={changeVersionDescription}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end lender-control-buttons">
                            <button className="btn-blue btn btn-primary ghost" onClick={handleCloseClick}>
                                Cancel
                            </button>
                            <button className="btn-blue btn btn-primary regular" onClick={handleSubmit}>
                                {props.isCopy ? 'Create' : 'Save'}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </ModalLayout>
    )
}
