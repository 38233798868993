import React, { useEffect, useMemo, useState } from 'react'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { useMetrics } from '../../../hooks/useMetrics'

type Props = any

export function ReturnMetrics(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state.lender.unitMix)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)

    const metrics = useMetrics()

    const isNewDev = project?.type && project?.type == 'miltifamily.new_dev'

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (model && project?.id && unitMix.length) setLoading(false)
    }, [model, project, unitMix])

    const {
        dealIRR,
        netIRR,
        pricePerUnit,
        dealEquityMultiply,
        coc,
        goingInCapRate,
        adjustedCapRate,
        loanAmount,
        ltv,
        ltc,
        loanPerUnit,
        loanPerSqFt,
        debtYield,
    } = useMemo(() => {
        // console.log('chenges', model, cashFlow)
        const pricePerUnit = metrics.pricePerUnit
        const dealEquityMultiply = metrics.dealEquityMultiply
        const coc = metrics.coc
        const goingInCapRate = metrics.goingInCapRate
        const adjustedCapRate = metrics.adjustedCapRate
        const loanAmount = metrics.loanAmount
        const ltv = metrics.ltv
        const ltc = metrics.ltc
        const loanPerUnit = metrics.loanPerUnit
        const loanPerSqFt = metrics.loanPerUnit
        const debtYield = metrics.debtYield
        const dealIRR = metrics.dealIRR
        //console.log('**** dealIRR', dealIRR)
        const netIRR = metrics.netIRR
        return {
            dealIRR,
            netIRR,
            pricePerUnit,
            dealEquityMultiply,
            coc,
            goingInCapRate,
            adjustedCapRate,
            loanAmount,
            ltv,
            ltc,
            loanPerUnit,
            loanPerSqFt,
            debtYield,
        }
    }, [model, cashFlow, unitMix, metrics])

    const tdstyle = {
        paddingLeft: '10px',
    }

    // if (!model) return null
    return (
        <WidgetWrapper title={'Return Metrics'} showProgress={loading}>
            <table>
                <tbody>
                    <tr>
                        <td> {isNewDev ? 'Land Cost' : 'Purchase Price'}</td>
                        <td className={'value'} style={tdstyle}>
                            {numeral(model?.uses?.purchasePrice || 0).format('($0,0)')}
                        </td>
                    </tr>
                    <tr>
                        <td>Price Per Unit:</td>
                        <td className={'value'} style={tdstyle}>
                            {numeral(pricePerUnit).format('($0,0)')}
                        </td>
                    </tr>
                    {project?.accountType == 'borrower' && (
                        <>
                            <tr>
                                <td>Deal IRR/Net IRR:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(dealIRR).format('0.[00]%')}/{numeral(netIRR).format('0.[00]%')}
                                </td>
                            </tr>
                            <tr>
                                <td>Equity Multiple:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(dealEquityMultiply).format('0.[00]')}
                                </td>
                            </tr>
                            <tr>
                                <td>CoC Average:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(coc).format('0.[00]%')}
                                </td>
                            </tr>
                            <tr>
                                <td>T12 Going-in Cap Rate:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(goingInCapRate).format('0.[00]%')}
                                </td>
                            </tr>
                            <tr>
                                <td>Adjusted Going-in Cap Rate:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(adjustedCapRate).format('0.[00]%')}
                                </td>
                            </tr>
                        </>
                    )}
                    {project?.accountType == 'lender' && (
                        <>
                            <tr>
                                <td>Loan Amount:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(loanAmount).format('($0,0)')}
                                </td>
                            </tr>
                            <tr>
                                <td>LTV / LTC:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(ltv).format('0.[00]%')} / {numeral(ltc).format('0.[00]%')}
                                </td>
                            </tr>
                            <tr>
                                <td>Loan per Unit:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(loanPerUnit).format('($0,0)')}
                                </td>
                            </tr>
                            <tr>
                                <td>Loan per SF:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(loanPerSqFt).format('($0,0)')}
                                </td>
                            </tr>
                            <tr>
                                <td>Debt Yield:</td>
                                <td className={'value'} style={tdstyle}>
                                    {numeral(debtYield).format('0.[00]%')}
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
            {/*language=SCSS*/}
            <style jsx>{`
                .value {
                    font-weight: bold;
                }
            `}</style>
        </WidgetWrapper>
    )
}
