import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import { NumberField } from '../../../components/NumberField'
import { Field, Form, useForm, useFormState } from 'react-final-form'
import { Spinner } from '../../../../../controls/Spinner'
import createDecorator from 'final-form-calculate'
import { TextField } from '../../../components/TextField'
import { FormApi } from 'final-form'
import { removeItemFromArray } from '../../../../../../utils'
import { regenerateCashFlow } from 'origination-model'
import trash from '../icons/trash.svg'
import { usePrevious } from 'utils/usePrevious'
import { FormContext } from '../../../Dashboard'

const cloneDeep = require('lodash.clonedeep')

type Props = any

const DEFAULT_COMMENTS = [
    'Use For LTV',
    'Per NMCF Estimate (NCF +5%)',
    'Per Purchase Price / Built-Up cost',
    'Per Northmarq',
    'Per Borrower',
    'Per OM',
]
export const ValuationComparison = React.memo(function ValuationComparison(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const localContext = useMemo(() => ({ form: null as FormApi }), [])

    const { formModified, setFormModified, setFormModifiedViaMutators, formVisited, setFormVisited } =
        useContext(FormContext)

    const noi = useMemo(() => {
        return cashFlow?.noi?.adjustedT12
    }, [cashFlow?.noi?.adjustedT12])

    const isRefinance = useMemo(() => {
        return generalInputs?.loanDetails?.acquisitionOrRefinance == 'Refinance'
    }, [generalInputs?.loanDetails?.acquisitionOrRefinance])

    const onSubmit = useCallback(
        (values: any) => {
            //console.log('submit ValuationComparison', cloneDeep(values.fanniePricing.valuationComparison))
            if (values.fanniePricing?.valuationComparison) {
                if (!formModified.valuationComparison && formVisited?.valuationComparison) {
                    setFormModified((prevState) => ({
                        ...prevState,
                        valuationComparison: true,
                    }))
                }

                ReduxHelper.setIn(
                    ['lender', 'originationModel', 'fanniePricing', 'valuationComparison'],
                    cloneDeep(values.fanniePricing.valuationComparison),
                )
                if (
                    isRefinance &&
                    values?.fanniePricing?.valuationComparison &&
                    values.fanniePricing.valuationComparison.length > 0
                )
                    for (const valuationComparison of values.fanniePricing.valuationComparison) {
                        if (valuationComparison.selected) {
                            const newPropertyValue = valuationComparison.capRate ? noi / valuationComparison.capRate : 0
                            if (newPropertyValue)
                                ReduxHelper.setIn(
                                    ['lender', 'originationModel', 'uses', 'purchasePrice'],
                                    newPropertyValue,
                                )
                        }
                    }
            }
        },
        [model, noi, isRefinance, formModified?.valuationComparison, formVisited?.valuationComparison],
    )

    const getDefaultValuation = useCallback(() => {
        // console.log('getDefaultValuation')
        return {
            selected: true,
            //assumedValue: model?.uses?.purchasePrice ? model?.uses?.purchasePrice : 0,
            //  capRate: model?.uses?.purchasePrice ? cashFlow?.noi?.adjustedT12 / model?.uses?.purchasePrice : 0,
            assumedValue: noi / 0.0525,
            capRate: 0.0525,
            comment: DEFAULT_COMMENTS[0],
        }
    }, [noi])

    const [initialValues, setInitialValues] = useState(null)
    useEffect(() => {
        if (!initialValues && model) {
            const newModel = cloneDeep(model)
            if (!newModel.fanniePricing.valuationComparison) newModel.fanniePricing.valuationComparison = []
            if (newModel.fanniePricing.valuationComparison.length == 0) {
                const arr: any = []
                arr.push(getDefaultValuation())
                for (let i = 1; i < DEFAULT_COMMENTS.length; i++) {
                    arr.push({
                        selected: false,
                        assumedValue: 0,
                        capRate: 0,
                        comment: DEFAULT_COMMENTS[i],
                    })
                }
                newModel.fanniePricing.valuationComparison = arr
            }
            setInitialValues(newModel)
        }
    }, [model, getDefaultValuation, initialValues])

    const computedValues = useMemo(() => {
        return createDecorator({
            field: /selected$/,
            updates: (value, name, values: any) => {
                console.log('computedValues selected', name, value, value)
                if (value == null) return {}
                if (isNaN(value)) return {}
                const idx = name.split('.')[2]
                const out = {}
                //  out[`fanniePricing.valuationComparison.${idx}.selected`] = true
                if (value) {
                    for (let i = 0; i < values?.fanniePricing?.valuationComparison?.length; i++) {
                        if ('' + i == idx) continue
                        out[`fanniePricing.valuationComparison.${i}.selected`] = false
                    }
                }

                return out
            },
        })
    }, [])

    const mutators = useMemo(
        () => ({
            addItem(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    valuationComparison: true,
                }))
                form.changeValue(state, `fanniePricing.valuationComparison`, (list) => {
                    const obj: any = {
                        assumedValue: 0,
                        capRate: 0,
                        comment: null,
                        selected: list.length == 0 ? true : false,
                    }

                    list.push(obj)
                    return list
                })
                localContext.form.submit()
            },
            removeItem(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    valuationComparison: true,
                }))
                form.changeValue(state, `fanniePricing.valuationComparison`, (list) => {
                    //console.log('removeAdjustedItem', params[0], params[1], cloneDeep(t12Adjustments))
                    const changeSelected = list[params[0]].selected
                    const newList = removeItemFromArray(list, params[0])
                    if (newList.length == 0) {
                        newList.push(getDefaultValuation())
                        for (let i = 1; i < DEFAULT_COMMENTS.length; i++) {
                            newList.push({
                                selected: false,
                                assumedValue: 0,
                                capRate: 0,
                                comment: DEFAULT_COMMENTS[i],
                            })
                        }
                    }
                    if (changeSelected && newList.length >= 1) newList[0].selected = true
                    return newList
                })
                localContext.form.submit()
            },
        }),
        [getDefaultValuation],
    )

    useEffect(() => {
        return () =>
            setFormVisited((prevState) => ({
                ...prevState,
                valuationComparison: false,
            }))
    }, [])

    return (
        <>
            {model ? (
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    decorators={[computedValues]}
                    mutators={mutators}
                >
                    {({ form, visited }) => {
                        localContext.form = form
                        if (visited) {
                            if (Object.values(visited).includes(true)) {
                                if (formVisited?.valuationComparison === false) {
                                    setFormVisited((prevState) => ({
                                        ...prevState,
                                        valuationComparison: true,
                                    }))
                                }
                            }
                        }
                        return <ValuationComparisonForm noi={noi} />
                    }}
                </Form>
            ) : (
                <Spinner />
            )}{' '}
        </>
    )
})

const ValuationComparisonItem = ({ ind, noi }) => {
    const form = useForm()
    const formState = useFormState()

    const prevAssumedValue = useRef(null)
    const prevCapRate = useRef(null)
    const prevNoi = usePrevious(noi)

    const prevLength = usePrevious(formState?.values?.fanniePricing?.valuationComparison?.length)
    useEffect(() => {
        const currentLength = formState?.values?.fanniePricing?.valuationComparison?.length

        if (currentLength && prevLength && currentLength !== prevLength) {
            prevAssumedValue.current = null
            prevCapRate.current = null
        }
    }, [formState?.values?.fanniePricing?.valuationComparison?.length])

    useEffect(() => {
        prevAssumedValue.current = null
        prevCapRate.current = null
    }, [noi])

    useEffect(() => {
        const assumedValue = formState.values.fanniePricing.valuationComparison[ind].assumedValue
        const capRate = formState.values.fanniePricing.valuationComparison[ind].capRate

        if (noi && ((assumedValue && !isNaN(assumedValue)) || (capRate && !isNaN(capRate)))) {
            if (prevAssumedValue.current !== assumedValue || prevCapRate.current !== capRate || noi !== prevNoi) {
                let newCapRate
                let newAssumedValue

                if (ind === 0) {
                    if (
                        (!!prevAssumedValue.current || !!assumedValue) &&
                        prevAssumedValue.current !== assumedValue &&
                        (prevCapRate.current === capRate || !prevCapRate.current || !capRate)
                    ) {
                        newCapRate = noi / assumedValue
                        newAssumedValue = assumedValue
                    }

                    if (
                        (!!prevCapRate.current || !!capRate) &&
                        prevCapRate.current !== capRate &&
                        (prevAssumedValue.current === assumedValue || !prevAssumedValue.current || !assumedValue)
                    ) {
                        newAssumedValue = noi / capRate
                        newCapRate = capRate
                    }
                } else {
                    if (
                        (!!prevCapRate.current || !!capRate) &&
                        prevCapRate.current !== capRate &&
                        (prevAssumedValue.current === assumedValue || !prevAssumedValue.current || !assumedValue)
                    ) {
                        newAssumedValue = noi / capRate
                        newCapRate = capRate
                    }

                    if (
                        (!!prevAssumedValue.current || !!assumedValue) &&
                        prevAssumedValue.current !== assumedValue &&
                        (prevCapRate.current === capRate || !prevCapRate.current || !capRate)
                    ) {
                        newCapRate = noi / assumedValue
                        newAssumedValue = assumedValue
                    }
                }

                if (newAssumedValue && newCapRate) {
                    prevAssumedValue.current = newAssumedValue
                    prevCapRate.current = newCapRate

                    form.batch(() => {
                        form.change(`fanniePricing.valuationComparison.${ind}.capRate`, newCapRate)
                        form.change(`fanniePricing.valuationComparison.${ind}.assumedValue`, newAssumedValue)
                    })
                }
            }
        }
    }, [
        formState.values.fanniePricing.valuationComparison[ind].assumedValue,
        formState.values.fanniePricing.valuationComparison[ind].capRate,
        prevAssumedValue.current,
        prevCapRate.current,
        prevNoi,
        noi,
        ind,
    ])

    return (
        <tr>
            {/* <td style={{ textAlign: 'center' }}>
                <Field name={`fanniePricing.valuationComparison.${ind}.selected`} component={'input'} type="checkbox" />
            </td> */}

            <td style={{ width: '160px' }}>
                <NumberField
                    name={`fanniePricing.valuationComparison.${ind}.assumedValue`}
                    numberFormat={'currency'}
                    cssClassName={'numberFieldClass evlauation'}
                />
            </td>
            <td>
                <NumberField
                    name={`fanniePricing.valuationComparison.${ind}.capRate`}
                    numberFormat={'percent'}
                    cssClassName={'numberFieldClass capRate'}
                />
            </td>

            <td style={{ width: '330px' /*'240px'*/ }}>
                <TextField name={`fanniePricing.valuationComparison.${ind}.comment`} className={'textFieldClass1'} />
            </td>
            <td>
                <div
                    className={'trashdiv'}
                    onClick={() => {
                        form.mutators.removeItem(ind)
                    }}
                >
                    <img className={'trashImg'} src={trash} />
                </div>
            </td>
        </tr>
    )
}

const ValuationComparisonForm: FunctionComponent<Props> = (props: Props) => {
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values
    const { noi } = props

    const addValuationComparison = useCallback(() => {
        form.mutators.addItem()
    }, [])

    useEffect(() => {
        if (form) form.submit()
    }, [formValues.fanniePricing?.valuationComparison])

    return (
        <>
            <div className={'pricingAnalyticsComponent'}>
                <div className={'title'}>Valuation Comparison</div>
                <div className={'mainPart'}>
                    <table>
                        <thead>
                            <tr>
                                {/*<td>Selected</td>*/}
                                <td style={{ width: '135px' }}>Assumed Value</td>
                                <td>Cap Rate</td>
                                <td>Comments</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {formValues?.fanniePricing?.valuationComparison?.map((v, ind) => {
                                return (
                                    <React.Fragment key={'valuationComparison' + ind}>
                                        <ValuationComparisonItem ind={ind} noi={noi} />
                                    </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div onClick={addValuationComparison} className={'addBtn'}>
                    Add
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .addBtn {
                    background: #4486ff;
                    border-radius: 4px;
                    width: 50px;
                    height: 25px;
                    text-align: center;
                    color: white;
                    margin-left: 20px;
                    margin-top: 10px;
                    cursor: pointer;
                }
                .pricingAnalyticsComponent {
                    background: white;
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-bottom: 20px;
                    width: 600px;
                    height: 366px;
                    margin-right: 20px;
                }
                .title {
                    border-bottom: 1px solid #d9e0e5;
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                }
                thead {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    color: #676f7a;
                }
                td {
                    text-align: left;
                }
                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;

                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                }
                :global(.textFieldClass1) {
                    text-align: left;
                    width: 90% !important;
                    //text-indent: 20px;
                }
                :global(.numberFieldClass) {
                    text-align: left;
                    position: relative;
                }
                :global(.evlauation) {
                    width: 100px !important;
                }
                :global(.capRate) {
                    width: 65px !important;
                }
                .trashdiv {
                    width: 20px;
                    margin-top: -10px;
                    right: 25px;
                    position: relative;
                    background: transparent;
                    cursor: pointer;
                }
                .trashImg {
                    width: 100%;
                    height: 100%;
                }
            `}</style>
        </>
    )
}
