import { useMemo } from 'react'
import DateBox from 'devextreme-react/date-box'
import { differenceInDays } from 'date-fns'
import { ReduxHelper } from 'store'
import { KPIBox } from './KPIBox'
import { getDateString } from './utils/dates'

export const RateLock = ({ rateLockItem, stabilizationItem, allUnitsAvailableDate, rentRollDate }) => {
    const daysToStabilizations = useMemo(() => {
        if (rateLockItem?.week && stabilizationItem?.week) {
            return differenceInDays(new Date(stabilizationItem.week), new Date(rateLockItem.week))
        } else {
            return '-'
        }
    }, [rateLockItem, stabilizationItem])

    return (
        <div className="rate-lock-section-wrapper">
            <KPIBox title="Week" value={rateLockItem ? getDateString(rateLockItem.week) : '-'} />
            <KPIBox
                title="Projected Occupancy"
                value={
                    rateLockItem?.projectedOccupancyOnTotalUnitsCo
                        ? `${(rateLockItem.projectedOccupancyOnTotalUnitsCo * 100).toFixed(2)}%`
                        : '-'
                }
            />
            <KPIBox
                title="Projected Time to Stabilization"
                value={
                    daysToStabilizations
                        ? `${daysToStabilizations} Days`
                        : '-'
                }
            />
            <KPIBox
                title="100% CO's Approved"
                datebox={
                    <DateBox
                        className={'date-field'}
                        value={allUnitsAvailableDate}
                        type="date"
                        onValueChanged={(e) => {
                            ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'allUnitsAvailableDate'], e.value)
                        }}
                        displayFormat="shortDate"
                        useMaskBehavior={true}
                        placeholder="Select date"
                        min={new Date(rentRollDate)}
                    />
                }
            />
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .rate-lock-section-wrapper {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;
                    margin-top: 10px;

                    .date-field {
                        width: 180px;
                        height: 38px;
                        border: 1px solid #97a2b4;
                        border-radius: 4px;
                        background-color: white !important;
                        .dx-dropdowneditor-icon:before {
                            color: $black !important;
                        }
                        .dx-texteditor-input {
                            color: $black !important;
                            font-weight: 500 !important;
                            font-size: 14px !important;
                        }
                        &.dx-dropdowneditor-active {
                            border: 1px solid $blue !important;
                            border-radius: 3px !important;
                        }
                    }
                    .dx-calendar-caption-button {
                        .dx-button-text {
                            color: $blue !important;
                        }
                    }
                    .dx-calendar-navigator-next-view.dx-button .dx-icon-chevronright {
                        color: $blue;
                    }
                }
            `}</style>
        </div>
    )
}
