import React, { FunctionComponent, useEffect, useState } from 'react'
import numeral from 'numeral'
import dynamic from 'next/dist/next-server/lib/dynamic'
import { CHART_COLOR } from '../../../../../../../utils/constants/chartColors'
import { ApexOptions } from 'apexcharts'
import { show } from '../../../../../../../store/sagas/notification'

const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    series: Array<{ name: string; data: number[] }>
    enableCustomCategories?: boolean
    customCategories?: any
}

export const MixedChart: FunctionComponent<Props> = (props: Props) => {
    const { series, enableCustomCategories, customCategories } = props
    const options = {
        series,
        options: {
            chart: {
                height: 350,
                type: 'line',
                width: '100%',
                redrawOnWindowResize: true,
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
            },
            colors: CHART_COLOR.LENDER_DASHBOARD_PALETTE,
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'left',
                labels: {
                    colors: ['#000'],
                },
            },
            stroke: {
                width: 2,
                curve: 'smooth',
            },
            tooltip: {
                theme: 'light',
            },
            title: {
                text: '',
                align: 'center',
                style: {
                    color: '#000',
                },
            },
            grid: {
                row: {
                    colors: ['transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5,
                },
            },
            xaxis: {
                title: {
                    text: '',
                    style: {
                        fontWeight: 400,
                    },
                },
                labels: {
                    formatter: (val) => val,
                },
                categories: enableCustomCategories ? customCategories : null,
            },
            yaxis: [
                {
                    seriesName: 'NET RENTAL INCOME',
                    show: false,
                    labels: {
                        formatter: (val) => numeral(val).format('$0,0'),
                    },
                },
                {
                    seriesName: 'ECONOMIC VACANCIES',
                    show: false,
                    labels: {
                        formatter: (val) => numeral(val / 100).format('0.[00]%'),
                    },
                    max: 100,
                    min: 0,
                },
            ],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [70, 100],
                },
            },
            markers: {
                size: 5,
                colors: ['#fff'],
                shape: 'circle',
                strokeColor: CHART_COLOR.LENDER_DASHBOARD_PALETTE,
                strokeWidth: 2,
            },
            dataLabels: {
                enabledOnSeries: [1],
                enabled: true,
                formatter: (val) => numeral(val / 100).format('0.[00]%'),
                offsetX: 0,
                offsetY: -5,
                textAnchor: 'middle',
                distributed: false,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Inter, Arial, sans-serif',
                    fontWeight: '500',
                    colors: CHART_COLOR.LENDER_DASHBOARD_PALETTE,
                },
                background: {
                    enabled: false,
                    dropShadow: {
                        enabled: false,
                    },
                },
                dropShadow: {
                    enabled: false,
                },
            },
        },
    }

    const [chartWidth, setChartWidth] = useState(200)
    useEffect(() => {
        const chartWrapper = document.getElementById('chart-wrapper')
        setChartWidth(chartWrapper?.offsetWidth - 20)
    }, [])

    return (
        <div className={'chart-wrapper'} id={'chart-wrapper'}>
            <Chart
                // @ts-ignore
                options={options.options}
                series={options.series}
                type="area"
                height={350}
                width={options.options.chart.width}
            />
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .chart-wrapper {
                    background-color: $white;
                    width: 100%;
                    .chart-title {
                        padding: 12px 20px;
                        border-bottom: 1px solid $border;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                }
            `}</style>
        </div>
    )
}
