import numeral from 'numeral'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { NumberField } from '../../../components/NumberField'
import { useModel } from '../../../hooks/useModel'
import { useSelector } from 'react-redux'
type Props = {
    fieldGroup: string
    fields: Array<{
        fld: string
        label: string
    }>
}
export const FieldPlusPerUnitComponent = (props: Props) => {
    const fieldGroup = props.fieldGroup
    const model = useModel()

    const numberOfUnits = model.numberOfUnits
    // const total = uses?.reduce((acc, item) => acc + item.value, 0)
    const fields = props.fields
    const form = useForm()
    useEffect(() => {
        for (const fld of fields) {
            recalcTotal(fld.fld, form.getState().values[fieldGroup][fld.fld])
        }
    }, [model, form])

    const recalcTotal = useCallback(
        (name, value) => {
            const val = numeral(value).value()

            form.change(
                `computed.${fieldGroup}.${name}_per_unit`,
                numberOfUnits && numberOfUnits > 0 ? Math.round(val / numberOfUnits) : 0,
            )

            const values = form.getState().values[fieldGroup]
            if (!values) return
            let total = 0
            for (const fld of fields) {
                const value = numeral(values[fld.fld]).value()
                total += value
            }

            const totalPerUnit = numberOfUnits && numberOfUnits > 0 ? Math.round(total / numberOfUnits) : 0

            form.change(`computed.${fieldGroup}.total`, total)
            form.change(`computed.${fieldGroup}.totalPerUnit`, totalPerUnit)
        },
        [form, fields],
    )

    return (
        <div className="container" style={{ paddingLeft: 27 }}>
            <div className={'row model-table-title'}>
                <div className="col-6 p-0 text-left">Category</div>
                <div className="col-3 p-0 text-right">$ Total</div>
                <div className="col-3 p-0 text-right">$ per Unit</div>
            </div>

            {fields?.map((item, i) => {
                const fldName = item.fld
                return (
                    <div className="row model-field-wrapper" key={i}>
                        <div className="col-5 p-0 model-field-title">{item.label}</div>
                        <div className="col-4 p-0">
                            <div className="model-field-value model-field-value-editable">
                                <NumberField name={`${fieldGroup}.${fldName}`} numberFormat={'currency'} />
                                <OnChange name={`${fieldGroup}.${fldName}`}>
                                    {(value, previous) => {
                                        if (value && !isNaN(value)) {
                                            console.log('in')
                                            recalcTotal(`${fldName}`, value)
                                        }
                                    }}
                                </OnChange>
                            </div>
                        </div>
                        <div className="col-3 p-0">
                            <div className="model-field-value">
                                <NumberField
                                    name={`computed.${fieldGroup}.${fldName}_per_unit`}
                                    numberFormat={'currency'}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className={'row model-field-wrapper'}>
                <div className="col-6 p-0">
                    <div className="model-field-title">Total</div>
                </div>
                <div className="col-3 p-0">
                    <div className="model-field-value">
                        <NumberField name={`computed.${fieldGroup}.total`} numberFormat={'currency'} readOnly={true} />
                    </div>
                </div>
                <div className="col-3 p-0">
                    <div className="model-field-value">
                        <NumberField
                            name={`computed.${fieldGroup}.totalPerUnit`}
                            numberFormat={'currency'}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .model-table-title {
                    color: $gray;
                    font-size: 12px;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }

                .model-field-wrapper {
                    margin-bottom: 20px;

                    .model-field-title {
                        font-weight: 500;
                        font-size: 15px;
                        color: $black;
                    }

                    .model-field-value {
                        font-weight: 500;
                        font-size: 14px;
                        color: $black;
                        text-align: right;
                        margin: 0 5px;
                        padding: 5px 0 10px;
                    }
                }
            `}</style>
        </div>
    )
}
