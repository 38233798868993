type OptionString = {
    value: string
    label: string
}

export const InterestBasisList: Array<OptionString> = [
    { value: 'None', label: 'None' },
    { value: 'Actual/360', label: 'Actual / 360' },
    { value: '30/360', label: '30/360' },
]

function onlyNumbers(str) {
    return /^\d+$/.test(str)
}

export const getFormattedDate = (date: string) => {
    const dateObject = onlyNumbers(date) ? new Date(+date) : new Date(date)
    return dateObject.getMonth() + 1 + '/' + dateObject.getDate() + '/' + dateObject.getFullYear()
}

export const existingTotalDefaults = {
    intrestRate: null,
}

export const getQuarterOfYear = (date: Date) => Math.ceil((date.getMonth() + 1) / 3)

// export const getActualDSCROptions = (quarter: number, year: number) => {
//     let options = new Array(4).fill(null)
//     options = options.map(() => {
//         if (quarter === 2 || quarter === 1) {
//             quarter = 4
//             year--
//         } else {
//             quarter--
//         }
//         return ({
//             value: `Q${quarter} ${year}`, label: `Q${quarter} ${year}`
//         })
//     })

//     return options
// }

export const getActualDSCRQuarterOptions = (quarter: number) => {
    let options = new Array(4).fill(null)
    options = options.map(() => {
        if (quarter === 2 || quarter === 1) {
            quarter = 4
        } else {
            quarter--
        }
        return ({
            value: `Q${quarter}`, label: quarter === 4 ? 'Year End' : `Q${quarter}`
        })
    })

    options.length = 3

    return options
}

export const getActualDSCRYearOptions = (quarter: number, year: number) => {
    let years = new Array(4).fill(null)
    years = years.map(() => {
        if (quarter === 2 || quarter === 1) {
            quarter = 4
            year--
        } else {
            quarter--
        }
        return `${year}`
    })

    let uniqueYears = years.filter((element, index) => {
        return years.indexOf(element) === index;
    })

    const currentYear = new Date().getFullYear().toString()

    uniqueYears = uniqueYears.map((year) => ({
        value: year,
        label: year === currentYear ? 'Current Year' : year
    }))

    if (quarter === 4) uniqueYears.length = 1
    
    return uniqueYears
}
