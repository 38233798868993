import React, { FunctionComponent, useEffect, useState } from 'react'
import numeral from 'numeral'
import dynamic from 'next/dist/next-server/lib/dynamic'
import { CHART_COLOR } from '../../../../../../../utils/constants/chartColors'
import { ApexOptions } from 'apexcharts'

const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    labels?: any[]
    values?: any[]
}

export const DonutChart: FunctionComponent<Props> = (props: Props) => {
    const options = {
        series: props.values,
        chart: {
            width: 300,
            type: 'donut',
        },
        colors: CHART_COLOR.LENDER_DASHBOARD_PALETTE,
        labels: props.labels,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return numeral(val / 100).format('0%')
            },
        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                let total = 0
                for (let x of series) {
                    total += x
                }
                let selected = series[seriesIndex]

                return `
                    <div class="donut-chart-custom-tooltip">
                        <div>
                            <span class="tooltip-colored-circle" style='background-color: ${
                                w.config.colors[seriesIndex]
                            }'>
                            </span>
                            ${w.config.labels[seriesIndex]} 
                        </div>
                        <div>
                            ${selected} units (${numeral(selected / total).format('0%')})
                        </div>
                    </div>`
            },
        },
        responsive: [
            {
                breakpoint: 1441,
                options: {
                    chart: {
                        width: 250,
                    },
                },
            },
            {
                breakpoint: 1367,
                options: {
                    chart: {
                        width: 300,
                    },
                },
            },
        ],
    }

    return (
        <div className={'donut-chart-wrapper'} id={'chart-wrapper'}>
            <Chart options={options as ApexOptions} series={options.series} width={options.chart.width} type="donut" />
            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';
                .donut-chart-wrapper {
                    background-color: $white;
                    width: 100%;
                    .chart-title {
                        padding: 12px 20px;
                        border-bottom: 1px solid $border;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .donut-chart-custom-tooltip {
                        background: #fff;
                        padding: 13px 35px;
                        border: 1px solid #d9e0e5;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 3px;
                        color: #262b35;
                        .tooltip-colored-circle {
                            width: 12px;
                            height: 12px;
                            display: inline-block;
                            border-radius: 50%;
                            margin-right: 5px;
                            margin-left: -20px;
                        }
                        > div:first-child {
                            font-size: 13px;
                        }
                        > div:last-child {
                            font-weight: 500;
                            font-size: 15px;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
