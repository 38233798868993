import createDecorator from 'final-form-calculate'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import {
    calcLoanSizeFromDebtService,
    getLoanSizeByLtc,
    getMultiplierByValueTypeForOrigTotal,
    getTotalUses,
    mortgagePaymentSchedule,
} from 'origination-model'
import { OriginationModelReworked } from '@generated/graphql'
import { regenerateCashFlow } from 'origination-model'
import { useSelector } from 'react-redux'
const cloneDeep = require('lodash.clonedeep')

function calcRefinance(refinanceLTV: number, exitCapRate: number, allValues: any, newIntrestRate: number) {
    const model: OriginationModelReworked = ReduxHelper.store.getState().lender.originationModel
    const cashFlow = ReduxHelper.store.getState().lender.originationModel.cashFlow

    const originalLoanPaymentHoldingPeriod = mortgagePaymentSchedule({
        loanAmount: model?.sources.seniorDebt,
        amortizationTermYears: model?.debtAssumptionSenior.amortizationTerm,
        rate: model?.debtAssumptionSenior.rate,
        ioPeriodYears: model?.debtAssumptionSenior.ioPeriod,
        unit: 'year',
        periodYears: model.exitAssumption.saleYearAnalysisPeriod,
    }).endBalance

    const originalLoanPaymentIO = mortgagePaymentSchedule({
        loanAmount: model?.sources.seniorDebt,
        amortizationTermYears: model?.debtAssumptionSenior.amortizationTerm,
        rate: model?.debtAssumptionSenior.rate,
        ioPeriodYears: model?.debtAssumptionSenior.ioPeriod,
        unit: 'year',
        periodYears: model?.debtAssumptionSenior.ioPeriod,
    }).endBalance

    const originalLoanPaymentHoldingByYear = []
    for (let i = 0; i < cashFlow?.noi?.years?.length; i++) {
        originalLoanPaymentHoldingByYear.push(
            mortgagePaymentSchedule({
                loanAmount: model?.sources.seniorDebt,
                amortizationTermYears: model?.debtAssumptionSenior.amortizationTerm,
                rate: model?.debtAssumptionSenior.rate,
                ioPeriodYears: model?.debtAssumptionSenior.ioPeriod,
                unit: 'year',
                periodYears: i + 1,
            }).endBalance,
        )
    }
    const originalLoanPaymentHoldingPeriodMez = mortgagePaymentSchedule({
        loanAmount: model?.sources.mezzanine,
        amortizationTermYears: model?.debtAssumptionMez.amortizationTerm,
        rate: model?.debtAssumptionMez.rate,
        ioPeriodYears: model?.debtAssumptionMez.ioPeriod,
        unit: 'year',
        periodYears: model.exitAssumption.saleYearAnalysisPeriod,
    }).endBalance

    const originalLoanPaymentIOMez = mortgagePaymentSchedule({
        loanAmount: model?.sources.mezzanine,
        amortizationTermYears: model?.debtAssumptionMez.amortizationTerm,
        rate: model?.debtAssumptionMez.rate,
        ioPeriodYears: model?.debtAssumptionMez.ioPeriod,
        unit: 'year',
        periodYears: model?.debtAssumptionMez.ioPeriod,
    }).endBalance

    const originalLoanPaymentHoldingByYearMez = []
    for (let i = 0; i < cashFlow?.noi?.years?.length; i++) {
        originalLoanPaymentHoldingByYearMez.push(
            mortgagePaymentSchedule({
                loanAmount: model?.sources.mezzanine,
                amortizationTermYears: model?.debtAssumptionMez.amortizationTerm,
                rate: model?.debtAssumptionMez.rate,
                ioPeriodYears: model?.debtAssumptionMez.ioPeriod,
                unit: 'year',
                periodYears: i + 1,
            }).endBalance,
        )
    }

    const refinance: any = {}

    refinance.ltv = refinanceLTV
    refinance.exitCapRate = exitCapRate
    refinance.refinanceType = model?.refinance?.refinanceType ? model?.refinance?.refinanceType : 'end_io'

    if (refinance.refinanceType == 'end_holding') refinance.noi = cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1]
    else if (refinance.refinanceType == 'end_io')
        refinance.noi = cashFlow?.noi.years[model?.debtAssumptionSenior.ioPeriod - 1]
    else refinance.noi = cashFlow?.noi.years[parseInt(refinance.refinanceType) - 1]

    if (refinance.refinanceType == 'end_holding')
        refinance.loanRepayment = originalLoanPaymentHoldingPeriod + originalLoanPaymentHoldingPeriodMez
    else if (refinance.refinanceType == 'end_io')
        refinance.loanRepayment = originalLoanPaymentIO + originalLoanPaymentIOMez
    else
        refinance.loanRepayment =
            originalLoanPaymentHoldingByYear[parseInt(refinance.refinanceType) - 1] +
            originalLoanPaymentHoldingByYearMez[parseInt(refinance.refinanceType) - 1]

    refinance.newPropertyValue = model?.refinance?.noi
        ? model?.refinance?.noi / exitCapRate
        : cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1] / exitCapRate

    refinance.refinanceLoanAmount = refinanceLTV * refinance.newPropertyValue

    refinance.lenderFeePercent = model?.refinance?.lenderFeePercent
        ? model?.refinance?.lenderFeePercent
        : model?.uses?.lenderFeePercent
    refinance.refinanceLoanCost = refinance.refinanceLoanAmount * refinance.lenderFeePercent

    refinance.existingLoanPenaltyPercentage = model?.refinance?.existingLoanPenaltyPercentage
        ? model?.refinance?.existingLoanPenaltyPercentage
        : 0.01

    refinance.remainingCashflowtoBorrower =
        refinance.refinanceLoanAmount -
        refinance.loanRepayment * (1 + refinance.existingLoanPenaltyPercentage) -
        refinance.refinanceLoanCost

    refinance.amortizationTerm = model?.refinance?.amortizationTerm
        ? model?.refinance?.amortizationTerm
        : model?.debtAssumptionSenior?.amortizationTerm

    refinance.amortizationTermMonths = refinance.amortizationTerm * 12

    if (newIntrestRate) refinance.refinanceIntrestRate = newIntrestRate
    else if (model?.refinance?.refinanceIntrestRate)
        refinance.refinanceIntrestRate = model?.refinance?.refinanceIntrestRate
    else refinance.refinanceIntrestRate = model?.debtAssumptionSenior?.rate

    refinance.ioPeriod = model?.refinance?.ioPeriod ? model?.refinance?.ioPeriod : model?.debtAssumptionSenior?.ioPeriod
    refinance.ioPeriodMonths = refinance.ioPeriod * 12
    refinance.loanTerm = model?.refinance?.loanTerm ? model?.refinance?.loanTerm : model?.debtAssumptionSenior?.loanTerm
    refinance.loanTermMonths = refinance.loanTerm * 12

    const newAmortizationPayment = mortgagePaymentSchedule({
        loanAmount: refinance.refinanceLoanAmount,
        amortizationTermYears: refinance.amortizationTerm,
        rate: refinance.refinanceIntrestRate,
        ioPeriodYears: refinance.ioPeriod,
        unit: 'year',
        periodYears: refinance.amortizationTerm,
    }).schedule[0].total

    refinance.newAmortizationPayment = newAmortizationPayment
    refinance.newfcf = refinance.noi - newAmortizationPayment
    refinance.dscr = refinance.noi / newAmortizationPayment

    return refinance
}

function calcBorrowerDebt(allValues: any) {
    //console.log('calcBorrowerDebt', allValues)

    const {
        minDSCR,
        maxLTC,
        maxLTV,
        loanTerm,
        ioPeriod,
        amortizationTerm,
        rate,
        mezMinDSCR,
        mezMaxLTC,
        mezMaxLTV,
        mezLoanTerm,
        mezIoPeriod,
        mezAmortizationTerm,
        mezRate,
        useSeniorMaxLTV,
        useMezMaxLTV,
        valuationCapRate,
        borrowerNOI,
    } = allValues
    const model: OriginationModelReworked = ReduxHelper.store.getState().lender.originationModel
    const noi = borrowerNOI
    const valuation = noi / valuationCapRate

    const borrowerDebt: any = {
        show: true,
        sources: {},
        debtAssumptionSenior: {},
        debtAssumptionMez: {},
        valuation: valuation,
        noi: noi,
    }

    let maxDebtService = noi / minDSCR
    let newLoanSize = calcLoanSizeFromDebtService({
        debtService: maxDebtService,
        loanTerm: amortizationTerm,
        ioPeriod: ioPeriod,
        amortizationTerm: amortizationTerm,
        rate: rate,
    })

    const totalUses = valuation

    let newRatio = newLoanSize / totalUses
    let maxRatio = maxLTC
    if (useSeniorMaxLTV) {
        newRatio = newLoanSize / valuation
        maxRatio = maxLTV
    }

    if (newRatio > maxRatio) {
        newRatio = maxRatio
        newLoanSize = newRatio * valuation

        maxDebtService = mortgagePaymentSchedule({
            loanAmount: newLoanSize,
            amortizationTermYears: amortizationTerm,
            rate: rate,
            ioPeriodYears: ioPeriod,
            periodYears: model.exitAssumption.saleYearAnalysisPeriod,
            unit: 'year',
        }).schedule[0].total
    }

    borrowerDebt.sources.seniorDebt = newLoanSize
    borrowerDebt.sources.seniorLTV = newRatio

    borrowerDebt.sources.seniorDebtPercent = newLoanSize / valuation

    let newLoanSizeTotal = 0
    let newLoanSizeMez = 0
    if (mezMaxLTC > 0 || mezMaxLTV > 0) {
        const maxDebtServiceTotal = noi / mezMinDSCR
        newLoanSizeMez =
            maxDebtServiceTotal - maxDebtService > 0
                ? calcLoanSizeFromDebtService({
                      debtService: maxDebtServiceTotal - maxDebtService,
                      loanTerm: amortizationTerm,
                      ioPeriod: mezIoPeriod,
                      amortizationTerm: mezAmortizationTerm,
                      rate: rate,
                  })
                : 0

        let newRatioMez = (newLoanSizeMez + newLoanSize) / totalUses
        let maxRatioMez = mezMaxLTC
        if (useMezMaxLTV) {
            newRatioMez = (newLoanSizeMez + newLoanSize) / valuation
            maxRatioMez = mezMaxLTV
        }

        if (newRatioMez > maxRatioMez) {
            newRatioMez = maxRatioMez
            newLoanSizeTotal = newRatioMez * valuation
        }
        newLoanSizeMez = newLoanSizeTotal - newLoanSize
        if (newLoanSizeMez < 0) newLoanSizeMez = 0

        borrowerDebt.sources.mezzanine = newLoanSizeMez
        borrowerDebt.sources.mezzanineLTV = newRatioMez

        borrowerDebt.sources.mezzaninePercent = newLoanSizeMez / valuation
    }

    borrowerDebt.debtAssumptionSenior = {
        loanAmount: newLoanSize,
        rate: rate,
        ioPeriod: ioPeriod,
        amortizationTerm: amortizationTerm,
        loanTerm: loanTerm,
    }

    borrowerDebt.debtAssumptionMez = {
        loanAmount: newLoanSizeMez - newLoanSize,
        rate: mezRate,
        ioPeriod: mezIoPeriod,
        amortizationTerm: mezAmortizationTerm,
        loanTerm: mezLoanTerm,
    }

    return borrowerDebt
}
export const fineTuneDealDecorator = createDecorator(
    {
        field: /refinanceLTV/,
        updates: (value, name, allValues: any) => {
            const model: OriginationModelReworked = ReduxHelper.store.getState().lender.originationModel
            if (!model) return {}
            const refinance = calcRefinance(value, allValues.exitCapRate, allValues, null)
            ReduxHelper.setIn(['lender', 'originationModel', 'refinance'], refinance)
            //console.log('new refinance based refinanceLTV', refinance)
            return {}
        },
    },
    {
        field: /valuationCapRate/,
        updates: (value, name, allValues: any) => {
            //console.log('decorator valuationCapRate', value, allValues)
            ReduxHelper.setIn(['lender', 'loanSizer', 'valuationCapRate'], value)
            return {}
        },
    },
    {
        field: /borrowerNOI/,
        updates: (value, name, allValues: any) => {
            //console.log('decorator valuationCapRate', value, allValues)
            if (allValues.useBorrowerNOI) {
                const borrowerDebt = calcBorrowerDebt(allValues)
                ReduxHelper.setIn(['lender', 'borrowerDebt'], borrowerDebt)
            }

            return {}
        },
    },
    {
        field: /useBorrowerNOI/,
        updates: (value, name, allValues: any) => {
            let borrowerDebt = {
                show: value,
            }
            if (value && allValues.borrowerNOI > 0) {
                borrowerDebt = calcBorrowerDebt(allValues)
            }
            ReduxHelper.setIn(['lender', 'borrowerDebt'], borrowerDebt)
            return {}
        },
    },
    {
        field: /exitCapRate/,
        updates: (value, name, allValues: any) => {
            const model: OriginationModelReworked = ReduxHelper.store.getState().lender.originationModel
            if (!model) return {}
            const refinance = calcRefinance(allValues.refinanceLTV, value, allValues, null)
            ReduxHelper.setIn(['lender', 'originationModel', 'refinance'], refinance)
            ReduxHelper.setIn(['lender', 'originationModel', 'exitAssumption', 'exitCapRate'], value)
            // console.log('new refinance based exitCapRate', refinance)
            return {}
        },
    },
    {
        field: /.*/,
        updates: (value, name, allValues: Record<string, any>) => {
            // check to call this method only once on form init
            const out: any = {}
            if (name == 'useBorrowerNOI') return out
            if (name == 'useSeniorMaxLTC') {
                allValues.useSeniorMaxLTV = false
                out.useSeniorMaxLTV = !value
            } else if (name == 'useSeniorMaxLTV') {
                allValues.useSeniorMaxLTC = false
                out.useSeniorMaxLTC = !value
            }

            if (name == 'useMezMaxLTC') {
                allValues.useMezMaxLTV = false
                out.useMezMaxLTV = !value
            } else if (name == 'useMezMaxLTV') {
                allValues.useMezMaxLTC = false
                out.useMezMaxLTC = !value
            }

            const timeDiff = allValues.lastUpdate ? new Date().valueOf() - allValues.lastUpdate : false
            if (timeDiff && timeDiff < 20) {
                return out
            }

            allValues.lastUpdate = new Date().valueOf()
            if (['refinanceLTV', 'exitCapRate'].includes(name)) {
                return out
            }
            const {
                minDSCR,
                maxLTC,
                maxLTV,
                loanTerm,
                ioPeriod,
                amortizationTerm,
                rate,
                vacancy,
                mezMinDSCR,
                mezMaxLTC,
                mezMaxLTV,
                mezLoanTerm,
                mezIoPeriod,
                mezAmortizationTerm,
                mezRate,
                useSeniorMaxLTV,
                useMezMaxLTV,
                valuationCapRate,
            } = allValues

            const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
            const model: OriginationModelReworked = cloneDeep(ReduxHelper.store.getState().lender.originationModel)
            if (!model) return out

            let cashFlow = ReduxHelper.store.getState().lender.originationModel.cashFlow
            model.underwritingAssumption.vacancy = new Array(model.exitAssumption.saleYearAnalysisPeriod).fill(vacancy)

            let vacancyRow = model.t12AdjustmentsIncome.find((exp) => exp.id === 'vacancy')
            const grossPotentialRent = cashFlow?.rentalIncome.find((exp) => exp.id === 'grossPotentialRent')

            if (!vacancyRow) {
                vacancyRow = {
                    id: 'vacancy',
                    title: 'Vacancy',
                }
                model.t12AdjustmentsIncome.push(vacancyRow)
            }

            const adjustmentsSettings = model.adjustmentsSettings
            const totalUnits = model?.numberOfUnits
            const multiplierIncome = getMultiplierByValueTypeForOrigTotal(
                adjustmentsSettings?.incomeValueType,
                totalUnits,
            )
            console.log(
                'grossPotentialRent',
                grossPotentialRent?.adjustedT12,
                grossPotentialRent?.adjustedT12 * multiplierIncome,
            )
            vacancyRow.total = -1 * grossPotentialRent?.adjustedT12 * multiplierIncome * vacancy
            vacancyRow.value = vacancyRow.total / model?.numberOfUnits

            cashFlow = regenerateCashFlow({ cashFlow, model, unitMix: unitMix })

            const noi = cashFlow.noi.adjustedT12 /*years[0]*/

            //console.log('start snior calc')
            let maxDebtService = noi / minDSCR
            let newLoanSize = calcLoanSizeFromDebtService({
                debtService: maxDebtService,
                loanTerm: amortizationTerm,
                ioPeriod: ioPeriod,
                amortizationTerm: amortizationTerm,
                rate: rate,
            })
            //console.log('senior maxDebtService', maxDebtService)
            //console.log('senior newLoanSize', newLoanSize)

            const valuation = noi / valuationCapRate
            model.sources.seniorDebt = newLoanSize
            const lenderFee = getUpdatedLenderFee(model, newLoanSize)
            model.uses.lenderFee = lenderFee
            let totalUses = getTotalUses(model)

            let newRatio = newLoanSize / totalUses
            let maxRatio = maxLTC
            if (useSeniorMaxLTV) {
                newRatio = newLoanSize / valuation
                maxRatio = maxLTV
            }
            //console.log('Senior limits max,new ', maxRatio, newRatio)
            if (newRatio > maxRatio) {
                // console.log('senior ratio to high resizing')
                newRatio = maxRatio
                if (useSeniorMaxLTV) newLoanSize = newRatio * valuation
                else newLoanSize = getLoanSizeByLtc(model, newRatio)

                maxDebtService = mortgagePaymentSchedule({
                    loanAmount: newLoanSize,
                    amortizationTermYears: amortizationTerm,
                    rate: rate,
                    ioPeriodYears: ioPeriod,
                    periodYears: model.exitAssumption.saleYearAnalysisPeriod,
                    unit: 'year',
                }).schedule[0].total

                //console.log('senior maxDebtService', maxDebtService)
                //console.log('senior newLoanSize', newLoanSize)
                /*
                const newDSCR = noi / maxDebtService
                console.log('senior noi', noi)
                console.log('senior newDSCR', newDSCR)
                console.log('senior newLoanSize', newLoanSize)
                console.log('senior payment', schedule.schedule[0].total)

                 */
            }

            model.sources.seniorDebt = newLoanSize
            totalUses = getTotalUses(model)
            //console.log('start mez calc')
            let newLoanSizeTotal = 0
            let newLoanSizeMez = 0
            if (mezMaxLTC > 0 || mezMaxLTV > 0) {
                const maxDebtServiceTotal = noi / mezMinDSCR
                //console.log('maxDebtServicetotal', maxDebtServiceTotal)
                //console.log('mez debtService', maxDebtServiceTotal - maxDebtService)
                //console.log('mez mezLoanTerm', mezLoanTerm)
                //console.log('mez mezIoPeriod', mezIoPeriod)
                newLoanSizeMez =
                    maxDebtServiceTotal - maxDebtService > 0
                        ? calcLoanSizeFromDebtService({
                              debtService: maxDebtServiceTotal - maxDebtService,
                              loanTerm: mezAmortizationTerm,
                              ioPeriod: mezIoPeriod,
                              amortizationTerm: mezAmortizationTerm,
                              rate: mezRate,
                          })
                        : 0
                //console.log('mez newLoanSizeMez', newLoanSizeMez)

                let newRatioMez = (newLoanSizeMez + newLoanSize) / totalUses
                //console.log('mez newRatioMez vs uses(cost)', newRatioMez)

                let maxRatioMez = mezMaxLTC
                if (useMezMaxLTV) {
                    newRatioMez = (newLoanSizeMez + newLoanSize) / valuation
                    // console.log('mez newRatioMez vs valuation', newRatioMez)
                    maxRatioMez = mezMaxLTV
                }

                if (newRatioMez > maxRatioMez) {
                    //console.log(',Mez ratio to high')
                    newRatioMez = maxRatioMez
                    if (useMezMaxLTV) newLoanSizeTotal = newRatioMez * valuation
                    else newLoanSizeTotal = getLoanSizeByLtc(model, newRatioMez)

                    newLoanSizeMez = newLoanSizeTotal - newLoanSize
                    if (newLoanSizeMez < 0) newLoanSizeMez = 0
                    /*
                    const maxDebtServiceMez = mortgagePaymentSchedule({
                        loanAmount: newLoanSizeMez,
                        amortizationTermYears: mezAmortizationTerm,
                        rate: mezRate,
                        ioPeriodYears: mezIoPeriod,
                        periodYears: model.exitAssumption.saleYearAnalysisPeriod,
                        unit: 'year',
                    }).schedule[0].total

                    const newDSCRMez = noi / (maxDebtServiceMez + maxDebtService)
                    console.log('Mez (total) newDSCRMez', newDSCRMez, newLoanSizeMez)

                     */
                }
            }

            const sources = { ...model.sources }
            //console.log('new source senior', newLoanSize)
            //console.log('new source total', newLoanSizeMez)
            //console.log('new source mez', newLoanSizeMez - newLoanSize)
            sources.seniorDebt = newLoanSize
            sources.seniorDebtPercent = newLoanSize / totalUses

            sources.mezzanine = newLoanSizeMez
            sources.mezzaninePercent = newLoanSizeMez / totalUses

            sources.totalEquity = totalUses - newLoanSizeMez - newLoanSize
            sources.totalEquityPercent = sources.totalEquity / totalUses
            //@ts-ignore
            ReduxHelper.setIn(['lender', 'originationModel', 'sources'], sources)
            //console.log('sources', sources)

            const debtAssumptions = { ...model.debtAssumptionSenior }
            debtAssumptions.loanAmount = newLoanSize
            debtAssumptions.rate = rate
            debtAssumptions.ioPeriod = ioPeriod
            debtAssumptions.amortizationTerm = amortizationTerm
            debtAssumptions.loanTerm = loanTerm
            ReduxHelper.setIn(['lender', 'originationModel', 'debtAssumptionSenior'], debtAssumptions)

            const debtAssumptionMez = { ...model.debtAssumptionMez }
            debtAssumptionMez.loanAmount = newLoanSizeMez
            debtAssumptionMez.rate = mezRate
            debtAssumptionMez.ioPeriod = mezIoPeriod
            debtAssumptionMez.amortizationTerm = mezAmortizationTerm
            debtAssumptionMez.loanTerm = mezLoanTerm
            ReduxHelper.setIn(['lender', 'originationModel', 'debtAssumptionMez'], debtAssumptionMez)
            //console.log('debtAssumptionMez', debtAssumptionMez)

            const refinance = calcRefinance(
                allValues.refinanceLTV,
                allValues.exitCapRate,
                allValues,
                name == 'rate' ? rate : null,
            )
            ReduxHelper.setIn(['lender', 'originationModel', 'refinance'], refinance)
            model.sources = sources
            model.debtAssumptionSenior = debtAssumptions
            model.debtAssumptionMez = debtAssumptionMez

            //console.log('model before', model, cashFlow)

            cashFlow = regenerateCashFlow({ cashFlow, model, unitMix: unitMix })
            //console.log('noi', cashFlow.noi.adjustedT12, cashFlow.noi.years[0])
            ReduxHelper.setIn(['lender', 'originationModel', 'cashFlow'], cashFlow)

            if (allValues.useBorrowerNOI) {
                const borrowerDebt = calcBorrowerDebt(allValues)
                ReduxHelper.setIn(['lender', 'borrowerDebt'], borrowerDebt)
            }

            // Recalc lender fee if its specified in percents

            //console.log('fineTuneDealDecorator end totalUses', getTotalUses(model))
            //console.log('model after', model, cashFlow)
            /*
            const scheduleSenior = mortgagePaymentSchedule({
                amortizationTermYears: model.debtAssumptionSenior?.amortizationTerm,
                ioPeriodYears: model.debtAssumptionSenior?.ioPeriod,
                loanAmount: model?.sources?.seniorDebt,
                rate: model.debtAssumptionSenior?.rate,
                periodYears: model?.exitAssumption?.saleYearAnalysisPeriod,
                unit: 'year',
            })
            console.log('scheduleSenior ', scheduleSenior.schedule[0].total)

            const scheduleMez = mortgagePaymentSchedule({
                amortizationTermYears: model.debtAssumptionMez?.amortizationTerm,
                ioPeriodYears: model.debtAssumptionMez?.ioPeriod,
                loanAmount: model?.sources?.mezzanine,
                rate: model.debtAssumptionMez?.rate,
                periodYears: model?.exitAssumption?.saleYearAnalysisPeriod,
                unit: 'year',
            })
            console.log('scheduleMez', scheduleMez.schedule[0].total)

             */

            //console.log('totalUses', totalUses)
            return out
        },
    },
)
function getUpdatedLenderFee(model: OriginationModelReworked, newLoanSize: number) {
    let lenderFee: number = 0
    if (model?.breakdownCosts?.disabled && model?.uses?.lenderFeeType == 'absolute') {
        return +model.uses.lenderFee
    }
    if (model?.breakdownCosts?.disabled && model?.uses?.lenderFeeType == 'percent') {
        lenderFee = +newLoanSize * model?.uses?.lenderFeePercent
    } else if (!model?.breakdownCosts?.disabled) {
        let total = 0
        for (const item of model?.breakdownCosts?.loanCostsList) {
            if (item.type == 'percent') {
                item.abs += +newLoanSize * item.percentage
            }
            total += +item.abs
        }
        lenderFee = total
    }
    ReduxHelper.setIn(['lender', 'originationModel', 'uses', 'lenderFee'], +lenderFee.toFixed(2))
    return lenderFee
}
