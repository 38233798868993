import React, { FunctionComponent, useCallback, useEffect, useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'

import { FormApi } from 'final-form'
import { Form, useForm, useFormState } from 'react-final-form'
import { Spinner } from '../../../../../controls/Spinner'
import createDecorator from 'final-form-calculate'
import { DateField } from '../../../../../controls/inputs/generalinputs/DateField'
import { TextField } from '../../../components/TextField'
import { FormContext } from '../../../Dashboard'
import { regenerateCashFlow } from 'origination-model'
const cloneDeep = require('lodash.clonedeep')

type Props = any
export const LoanInfo = React.memo(function LoanInfo(props: Props) {
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })
    const localContext = useMemo(() => ({ form: null as FormApi }), [])

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const loanAmount = useMemo(() => {
        let total = generalInputs?.loanDetails?.requestedLoanAmount
        if (model?.fanniePricing?.supplemental)
            for (const sup of model.fanniePricing.supplemental) {
                total += sup.priorLien.estimatedUpbAtSupClosing
            }
        return total
    }, [generalInputs?.loanDetails?.requestedLoanAmount, model?.fanniePricing?.supplemental])

    const isModel = model ? true : false
    const initialValues = useMemo(() => {
        // console.log('initialValues model.fanniePricing', model)
        if (model && !model.fanniePricing.loanInfo)
            model.fanniePricing.loanInfo = { type: 'Fixed Rate - DUS', dateOfPricing: new Date() }
        return {
            ...cloneDeep(model),
        }
    }, [isModel])

    const onSubmit = useCallback(
        (values: any) => {
            const cloned = cloneDeep(values.fanniePricing.loanInfo)

            if (!formModified.loanInfo && formVisited?.loanInfo) {
                setFormModified((prevState) => ({
                    ...prevState,
                    loanInfo: true,
                }))
            }

            if (typeof cloned.dateOfPricing == 'number') {
                cloned.dateOfPricing = new Date(cloned.dateOfPricing)
            } else if (typeof cloned.dateOfPricing == 'string' && /^\d+$/.test(cloned.dateOfPricing)) {
                cloned.dateOfPricing = new Date(parseInt(cloned.dateOfPricing))
            }
            //cloned.dateOfPricing = cloned.dateOfPricing.getTime()
            ReduxHelper.setIn(['lender', 'originationModel', 'fanniePricing', 'loanInfo'], cloned)
        },
        [model, formModified?.loanInfo, formVisited?.loanInfo],
    )
    const computedValues = useMemo(() => {
        return createDecorator()
    }, [])
    const mutators = useMemo(() => ({}), [])

    useEffect(() => {
        return () =>
            setFormVisited((prevState) => ({
                ...prevState,
                loanInfo: false,
            }))
    }, [])

    return (
        <>
            {model ? (
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    decorators={[computedValues]}
                    mutators={mutators}
                >
                    {({ form, visited }) => {
                        localContext.form = form
                        if (visited) {
                            if (Object.values(visited).includes(true)) {
                                if (formVisited?.loanInfo === false) {
                                    setFormVisited((prevState) => ({
                                        ...prevState,
                                        loanInfo: true,
                                    }))
                                }
                            }
                        }
                        return (
                            <LoanInfoForm
                                loanAmount={loanAmount}
                                ncf={cashFlow?.noi?.adjustedT12}
                                formVisited={formVisited}
                                setFormVisited={setFormVisited}
                            />
                        )
                    }}
                </Form>
            ) : (
                <Spinner />
            )}
        </>
    )
})

const LoanInfoForm: FunctionComponent<Props> = (props: Props) => {
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)

    useEffect(() => {
        // console.log('useEffect')
        if (form) form.submit()
    }, [formValues.fanniePricing?.loanInfo])
    return (
        <>
            <div className={'pricingAnalyticsComponent'}>
                <div className={'title'}>Loan Information</div>
                <div className={'mainPart'}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Loan Purpose </td>
                                <td>{generalInputs?.loanDetails?.borrowerLoanPurpose}</td>
                            </tr>

                            <tr>
                                <td>Loan Type </td>
                                <td>
                                    <TextField name={`fanniePricing.loanInfo.type`} className={'textFieldClass'} />
                                </td>
                            </tr>

                            <tr>
                                <td className={'impText'}>Debt Yield Proceeds </td>
                                <td className={'impVal'}>
                                    {numeral(props.loanAmount ? props.ncf / props.loanAmount : 0).format('0.[00]%')}
                                </td>
                            </tr>

                            <tr>
                                <td>Date of Pricing </td>
                                <td>
                                    <div style={{ position: 'relative' }}>
                                        <DateField
                                            name={`fanniePricing.loanInfo.dateOfPricing`}
                                            style={{ position: 'absolute', right: 0 }}
                                            type="date"
                                            displayFormat={'MM/dd/yyyy'}
                                            onOpenedCb={() => {
                                                if (props.formVisited?.loanInfo === false) {
                                                    props.setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        loanInfo: true,
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .pricingAnalyticsComponent {
                    background: white;
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-bottom: 20px;
                    width: 600px;
                    height: 366px;
                    margin-right: 20px;
                }
                .title {
                    border-bottom: 1px solid #d9e0e5;
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                }

                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;
                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                    td {
                        width: 300px;
                        &:nth-of-type(even) {
                            text-align: right;
                            padding-right: 20px;
                        }
                    }
                }
                .impText {
                    background-color: rgba(68, 134, 255, 0.1);
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 27px;
                    color: #161c26;
                }
                .impVal {
                    background-color: rgba(68, 134, 255, 0.1);
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 27px;
                    color: #161c26;
                }
                :global(.textFieldClass) {
                    text-align: right;
                    position: relative;
                    right: 20px;
                }
                :global(.pricingAnalyticsComponent .dx-dropdowneditor-icon) {
                    right: 20px;
                    position: relative;
                }
            `}</style>
        </>
    )
}
