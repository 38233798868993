export function calcSeverity(data: any[][]) {
    let min = null
    let max = null
    for (const row of data) {
        for (const cell of row) {
            if (max == null || cell.sValue > max) {
                max = cell.sValue
            }
            if (min == null || min > cell.sValue) {
                min = cell.sValue
            }
        }
    }
    const maxLower = max - (max - min) * 0.25
    const minHigher = min + (max - min) * 0.25
    for (const row of data) {
        for (const cell of row) {
            if (cell.sValue <= max && cell.sValue >= maxLower) {
                cell.severity = 0
            } else if (cell.sValue >= min && cell.sValue <= minHigher) {
                cell.severity = 1
            } else {
                cell.severity = 0.5
            }
        }
    }
}
