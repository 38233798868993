import { OriginationModelReworked, SensitivityChartData } from '@generated/graphql'
import numeral from 'numeral'
const cloneDeep = require('lodash.clonedeep')
import { CashFlowRow, OriginationModelRedux } from '../../../../../../../store/types/OriginationModelRedux'
import { Finance } from 'financejs'
import { CashFlow, regenerateCashFlow, getMetrics } from 'origination-model'
import { ReduxHelper } from '../../../../../../../store'

export function irrSensitivity(cashFlow: CashFlow, model: OriginationModelRedux): SensitivityChartData {
    const yearCount = model.exitAssumption.saleYearAnalysisPeriod
    const exitCapRates = []
    for (let i = 0.055; i <= 0.07; i += 0.0025) {
        exitCapRates.push(i)
    }

    //1%-4% , increase factor 0.5%
    const rentGrowth = []
    for (let i = 0.01; i <= 0.04; i += 0.005) {
        rentGrowth.push(+i.toFixed(3))
    }
    const data = []

    for (const row in rentGrowth) {
        const tableRow = []
        for (const col in exitCapRates) {
            tableRow.push(calcCell(cashFlow, model, rentGrowth[row], exitCapRates[col]))
        }
        data.push(tableRow)
    }
    const out = {
        xAxisName: 'Exit cap rate',
        yAxisName: 'Rent growth',
        rowTitles: rentGrowth.map((el) => numeral(el).format('0.[00]%')),
        columnTitles: exitCapRates.map((el) => numeral(el).format('0.[00]%')),
        rowValues: rentGrowth,
        columnValues: exitCapRates,
        data,
    }

    return out
}
function calcCell(cashFlow: any, model: any, rentGrowth: number, exitCapRate: number) {
    let newModel: OriginationModelReworked = cloneDeep(model)
    const years = newModel.exitAssumption.saleYearAnalysisPeriod
    newModel.underwritingAssumption.organicRentGrowth = new Array(years).fill(rentGrowth)
    newModel.exitAssumption.exitCapRate = exitCapRate
    const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
    let newCF = regenerateCashFlow({ cashFlow, model: newModel, unitMix: unitMix })
    const metrics = getMetrics({ cashFlow: newCF, model: newModel })
    newCF = null
    newModel = null
    if (metrics.netIRR)
        return {
            value: numeral(metrics.netIRR).format('0.[00]%'),
            severity: 0,
        }
    else
        return {
            value: 'X',
            severity: 0,
        }
}
export function getCFRow(cfRowGroup, id): CashFlowRow {
    return cfRowGroup.reduce((out, item) => {
        if (item.id == id) {
            out = item
        }
        return out
    })
}
