import React, { useEffect, useMemo, useState } from 'react'
import { ScrollableTable, TableRow, TableTitle } from '../../ScrollableTable'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { MonthlyCashFlowFixedHeader } from './MonthlyCashFlowFixedHeader'
import { orgenizeCFForPresentation, regenerateCashFlow } from 'origination-model'

type Props = {}

export const MonthlyCashFlowTable = (props: Props) => {
    const storCashFlow = useSelector((store: ReduxStoreState) => store.lender.originationModel?.cashFlow)
    const model = useSelector((state: ReduxStoreState) => state?.lender?.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = useMemo(() => {
        const cashFlowTmp = regenerateCashFlow({ model: model, cashFlow: storCashFlow, unitMix: unitMix })
        return orgenizeCFForPresentation({
            cashFlow: cashFlowTmp,
        })
    }, [storCashFlow])

    const titles: TableTitle[] = generateTitles(
        cashFlow?.totalPotentialGrossRent?.years?.length * 12 || 0,
        model?.exitAssumption?.startAnalysisDate,
    )
    const rows: TableRow[] = []

    const rowTitleStyleLarge = { backgroundColor: '#fff', fontSize: 20 }
    const rowTitleStyleLargeMedium = { paddingLeft: 25, backgroundColor: '#D9E0E5' }
    const rowTitleStyleLargeSmall = { paddingLeft: 35 }

    const currencyFormat = '($0,0)'

    rows.push({
        title: 'Rental Income',
        values: [],
        rowTitleStyle: rowTitleStyleLarge,
    })

    if (cashFlow?.rentalIncome) {
        for (const item of cashFlow?.rentalIncome) {
            rows.push({
                title: item.title,
                values: yearsToMonths(item),
                rowTitleStyle: rowTitleStyleLargeSmall,
                rowNumeralFormat: currencyFormat,
            })
        }
    }

    rows.push({
        title: cashFlow?.totalPotentialGrossRent?.title,
        values: yearsToMonths(cashFlow?.totalPotentialGrossRent),
        rowTitleStyle: rowTitleStyleLargeMedium,
        cellStyle: { backgroundColor: '#D9E0E5' },
        rowNumeralFormat: currencyFormat,
    })

    if (cashFlow?.rentalLoss) {
        for (const item of cashFlow?.rentalLoss) {
            rows.push({
                title: item.title,
                values: yearsToMonths(item, true),
                rowTitleStyle: rowTitleStyleLargeSmall,
                rowNumeralFormat: currencyFormat,
            })
        }
    }

    rows.push({
        title: cashFlow?.effectiveGrossRent?.title,
        values: yearsToMonths(cashFlow?.effectiveGrossRent),
        rowTitleStyle: rowTitleStyleLargeMedium,
        cellStyle: { backgroundColor: '#D9E0E5' },
        rowNumeralFormat: currencyFormat,
    })

    if (cashFlow?.otherIncome) {
        for (const item of cashFlow?.otherIncome) {
            rows.push({
                title: item.title,
                values: yearsToMonths(item),
                rowTitleStyle: rowTitleStyleLargeSmall,
                rowNumeralFormat: currencyFormat,
            })
        }
    }

    rows.push({
        title: cashFlow?.effectiveGrossIncome?.title,
        values: yearsToMonths(cashFlow?.effectiveGrossIncome),
        rowTitleStyle: rowTitleStyleLargeMedium,
        cellStyle: { fontSize: 22, backgroundColor: '#D9E0E5' },
        rowNumeralFormat: currencyFormat,
    })

    rows.push({
        title: 'Operating Expenses',
        values: [],
        rowTitleStyle: rowTitleStyleLarge,
        rowNumeralFormat: currencyFormat,
    })

    if (cashFlow?.operatingExpenses) {
        for (const item of cashFlow?.operatingExpenses) {
            rows.push({
                title: item.title,
                values: yearsToMonths(item, true),
                rowTitleStyle: rowTitleStyleLargeSmall,
                rowNumeralFormat: currencyFormat,
            })
        }
    }

    rows.push({
        title: cashFlow?.totalOperatingExpenses?.title,
        values: yearsToMonths(cashFlow?.totalOperatingExpenses),
        rowTitleStyle: rowTitleStyleLargeMedium,
        cellStyle: { backgroundColor: '#D9E0E5' },
        rowNumeralFormat: currencyFormat,
    })
    rows.push({
        title: cashFlow?.noi?.title,
        values: yearsToMonths(cashFlow?.noi),
        rowTitleStyle: rowTitleStyleLargeMedium,
        cellStyle: { backgroundColor: '#D9E0E5' },
        rowNumeralFormat: currencyFormat,
    })

    if (cashFlow?.other) {
        for (const item of cashFlow?.other) {
            rows.push({
                title: item.title,
                values: yearsToMonths(item, false),
                rowTitleStyle: rowTitleStyleLargeSmall,
                rowNumeralFormat: currencyFormat,
            })
        }
    }
    rows.push({
        title: cashFlow?.cashFlowAfterDebtService?.title,
        values: yearsToMonths(cashFlow?.cashFlowAfterDebtService),
        rowTitleStyle: rowTitleStyleLargeMedium,
        cellStyle: { fontSize: 22, backgroundColor: '#D9E0E5' },
        rowNumeralFormat: currencyFormat,
    })
    const [cashFlowTitlesVisible, setCashFlowTitlesVisible] = useState(false)
    useEffect(() => {
        const handleScroll = (e) => {
            const scrollPosition = window?.scrollY
            if (scrollPosition > 625) {
                setCashFlowTitlesVisible(true)
                const container = document?.getElementById('header-scrollable-container')
                const header = document?.getElementById('table-scrollable-container')
                if (header && container) {
                    container.scrollLeft = header?.scrollLeft
                    container.addEventListener('scroll', () => (header.scrollLeft = container?.scrollLeft))
                }
            } else {
                setCashFlowTitlesVisible(false)
            }
        }
        window.addEventListener('scroll', handleScroll, false)
        return () => {
            window.removeEventListener('scroll', handleScroll, false)
        }
    }, [cashFlowTitlesVisible])

    return (
        <>
            <MonthlyCashFlowFixedHeader titles={titles} visible={cashFlowTitlesVisible} />
            <ScrollableTable rows={rows} titles={titles} />
        </>
    )
}

const yearsToMonths = (source: any, negativeValues = false) => {
    const months = []
    const usenegativeValues = negativeValues //&& source?.originalCFGroup != 'fcfBeforeDebt'

    if (source?.months) {
        for (let month of source.months) {
            if (usenegativeValues) {
                month = month > 0 ? month * -1 : month
            }

            months.push(month)
        }
    } else if (source?.years) {
        for (let year of source?.years) {
            if (usenegativeValues) {
                year = year > 0 ? year * -1 : year
            }
            for (let i = 0; i < 12; i++) {
                months.push(Math.floor(year / 12))
            }
        }
    }

    return months
}

const generateTitles = (length: number, startAnalysis: any) => {
    const titles = []
    const dateToUse = startAnalysis != null ? new Date(startAnalysis) : new Date()
    let month = dateToUse.getMonth() + 1
    let year = dateToUse.getFullYear()
    for (let i = 1; i <= length; i++) {
        titles.push({ title1: `Month ${i}`, title2: `${month}/1/${year}`, style: {} })
        month++
        if (month > 12) {
            month = 1
            year++
        }
    }

    return titles
}
