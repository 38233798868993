import { useForm } from 'react-final-form'
import React, { useCallback } from 'react'
import { FieldArray } from 'react-final-form-arrays'

import { CashFlowRowComp } from './CashFlowRow'

type Props = {
    fieldName: string
    addRowBefore?: string[]
    onlyTitles?: boolean
    inverseValues?: boolean
    readOnly?: boolean
    isNewDev: boolean
    showTxAndAdjusted: boolean
    showTrend: boolean
    isPDF?: boolean
}
export function CashFlowRowGroup(props: Props) {
    const form = useForm()
    const removeRow = useCallback(
        (ind: number) => {
            //@ts-ignore
            if (form?.mutators?.remove) form?.mutators?.remove(props.fieldName, ind)
        },
        [form],
    )
    const readOnly = form.getState().values.readOnly || props.readOnly
    return (
        <>
            <FieldArray name={props.fieldName}>
                {({ fields }) => {
                    return fields.map((name, ind) => {
                        return (
                            <CashFlowRowComp
                                key={name + ind}
                                pos={ind}
                                fieldName={`${props.fieldName}`}
                                readOnly={readOnly}
                                onRemove={removeRow}
                                onlyTitles={props.onlyTitles}
                                inverseValues={props.inverseValues}
                                isNewDev={props.isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                        )
                    })
                }}
            </FieldArray>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    tr {
                        td {
                            height: 35px;
                        }
                    }
                    .add-row {
                    }
                    td.title {
                        padding-left: 30px;
                    }
                    tr.readOnly {
                        background-color: $grid-row-selected-bg;
                        :global(input) {
                            background-color: transparent !important;
                        }
                        td.title {
                            padding-left: 19px;
                            font-size: 15px;
                        }
                    }
                    :global(tr.netOperatingIncome) {
                        background-color: transparent;
                        border-bottom: 1px solid $grid-row-selected-bg;
                        td:nth-child(2) {
                            border-right: 1px solid $grid-row-selected-bg;
                        }

                        td.title,
                        :global(input) {
                            font-weight: 500 !important;
                            font-size: 21px !important;
                            line-height: 27px;
                        }
                    }
                    td:last-child {
                        position: relative;
                    }
                    .delete-row {
                        position: absolute;
                        top: -2px;
                        right: -33px;
                        background-color: #fff;
                        padding: 0;
                        height: 32px;
                        width: 25px;
                        cursor: pointer;
                        border-bottom-right-radius: 3px;
                        border-right: 1px solid $border;
                        border-bottom: 1px solid $border;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin: 0 !important;
                        }
                        img.hover {
                            display: none;
                        }
                        &:hover {
                            z-index: 100;
                            img.normal {
                                display: none;
                            }
                            img.hover {
                                display: block;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
