import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { CashFlowRowGraphQl } from '@generated/graphql'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'
import { SagaHelper } from '../../../../../../../../store/sagaHelper'
import numeral from 'numeral'
import { calcArrayAverage, orgenizeCFForPresentation } from 'origination-model'
const cloneDeep = require('lodash.clonedeep')
import { mergeCashFlow } from './utils'

type Props = any
export const RowValues = React.memo(function RowValues(props: Props) {
    const storCashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel.cashFlow)
    const reduxCashFlow = useMemo(() => {
        return orgenizeCFForPresentation({
            cashFlow: storCashFlow,
        })
    }, [storCashFlow])

    //console.log('reduxCashFlow', reduxCashFlow)
    const proformas = useSelector((store: ReduxStoreState) => store.lender.proforma)
    const t12History = useSelector((store: ReduxStoreState) => store.lender.t12History)
    const columns = useSelector((store: ReduxStoreState) => store.lenderDashboard.lenderCashFlowTab.columnsSelected)
    const showPercentage = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showPercentage,
    )
    for (let i = 0; i < t12History.length; i++) {
        t12History[i].data = orgenizeCFForPresentation({ cashFlow: t12History[i].data })
        //console.log('t12History[i].data', t12History[i].data)
    }
    // console.log('t12History', t12History)
    const cashFlow = useMemo(() => {
        let cashFlow = reduxCashFlow
        if (proformas && proformas.length > 0) {
            for (const proforma of proformas) {
                const label = 'proforma' + (proforma.label && proforma.label != '' ? proforma.label : '')

                cashFlow = mergeCashFlow(cashFlow, proforma, (originalRow, mergedRow) => {
                    if (mergedRow.years.length > 1) {
                        for (const y in mergedRow.years) {
                            originalRow[`${label}${+y + 1}`] = mergedRow.years[y] || 0
                        }
                    } else if (mergedRow.years.length == 1) {
                        originalRow[`${label}`] = mergedRow.years[0] || 0 // 0.000000001 //fill small value to show 0 on UI ,  but not to effect
                    }
                })
            }
        }
        t12History &&
            t12History.forEach((year) => {
                cashFlow = mergeCashFlow(cashFlow, year.data, (originalRow, mergedRow) => {
                    originalRow[`history${year.year}`] = mergedRow.t12
                })
            })
        return cashFlow
    }, [reduxCashFlow, proformas])
    const gpr = valuesHelper(cashFlow.rentalIncome[0])
    const expenses = valuesHelper(cashFlow.totalOperatingExpenses)
    const egi = valuesHelper(cashFlow.effectiveGrossIncome)
    const operatingExpensesRef = useRef<HTMLDivElement>()
    const handleWindowScroll = useCallback(() => {
        const rect = operatingExpensesRef.current?.getBoundingClientRect()

        if (rect.top <= 30) {
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'unitTitle'], '$/UNIT/YEAR')
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'percentTitle'], '%EXPENSES')
        } else {
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'unitTitle'], '$/UNIT/MONTH')
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'percentTitle'], '%GPR')
        }
    }, [])
    useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll, false)
        return () => {
            window.removeEventListener('scroll', handleWindowScroll)
        }
    }, [])
    const cashFlowBeforDebtService = useMemo(() => {
        const other = cashFlow.other.filter(
            (row) => row.id != 'debtServiceInterest' && row.id != 'debtServiceInterestMez',
        )
        const beforeRow: CashFlowRowGraphQl = cloneDeep(cashFlow.noi)
        for (const row of other) {
            beforeRow.apr += row.apr
            beforeRow.originalApr += row.originalApr
            beforeRow.t12 += row.t12
            beforeRow.adjustedT12 += row.adjustedT12
            for (const i in beforeRow.years) {
                beforeRow.years[i] += row.years[i]
            }
        }
        beforeRow.id = 'cashFlowBeforeDebtService'
        return beforeRow
    }, [cashFlow])
    const totalExpensesRatio = useMemo(() => {
        const row = { ...cashFlow.totalOperatingExpenses }
        row.id = 'totalExpensesRatio'
        return row
    }, [cashFlow])
    return (
        <>
            <div style={{ height: '24px' }}>
                <div className={'rental-income'}>
                    {columns?.map((c, ind) => (
                        <ValuesBlock key={ind} values={showPercentage ? [' ', ' ', ' '] : [' ', ' ']} />
                    ))}
                </div>
            </div>

            <RowGroup rows={cashFlow.rentalIncome} percentageBasis={gpr} group={'income'} />
            <RowValue row={cashFlow.totalPotentialGrossRent} type={'bold'} percentageBasis={gpr} group={'income'} />
            <RowGroup rows={cashFlow.rentalLoss} percentageBasis={gpr} group={'income'} />
            <RowValue row={cashFlow.effectiveGrossRent} type={'total-blue'} percentageBasis={gpr} group={'income'} />
            <RowGroup rows={cashFlow.otherIncome} percentageBasis={gpr} group={'income'} />
            <RowValue row={cashFlow.effectiveGrossIncome} type={'total-green'} percentageBasis={gpr} group={'income'} />
            <div className={'operating-expenses'} ref={operatingExpensesRef}>
                {columns?.map((c, ind) => (
                    <OperatingExpensesHeaders key={ind} />
                ))}
            </div>
            <RowGroup rows={cashFlow.operatingExpenses} percentageBasis={expenses} group={'expenses'} />
            <RowValue
                row={cashFlow.totalOperatingExpenses}
                type={'total-blue'}
                percentageBasis={expenses}
                group={'expenses'}
            />
            <RowValue row={totalExpensesRatio} type={'total'} percentageBasis={egi} group={'totalExpensesRatio'} />
            <RowValue row={cashFlow.noi} type={'total'} group={'expenses'} />
            {!props.isPDF && (
                <>
                    <RowGroup
                        rows={cashFlow.other.filter(
                            (row) => row.id != 'debtServiceInterest' && row.id != 'debtServiceInterestMez',
                        )}
                        group={'other'}
                    />
                    <RowValue row={cashFlowBeforDebtService} type={'total-green'} group={'other'} />
                </>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .rental-income {
                    height: 100%;
                    display: inline-flex;
                    flex-direction: row;
                    line-height: 1;
                }
                .operating-expenses {
                    height: 100px;
                    display: inline-flex;
                    flex-direction: row;
                    line-height: 1;
                }
            `}</style>
        </>
    )
})

type TypesList = 'default' | 'bold' | 'total' | 'total-large' | 'total-blue' | 'total-green'
type RowGroupProps = {
    rows: CashFlowRowGraphQl[]
    type?: TypesList
    percentageBasis?: RowValues
    group: 'income' | 'expenses' | 'other'
}
const RowGroup = React.memo(function RowGroup(props: RowGroupProps) {
    return (
        <>
            {props.rows.map((row, i) => (
                <RowValue
                    row={row}
                    key={`${i}`}
                    type={props.type}
                    percentageBasis={props.percentageBasis}
                    group={props.group}
                />
            ))}
        </>
    )
})
type RowTitleProps = {
    row: CashFlowRowGraphQl
    type?: TypesList
    percentageBasis?: RowValues
    group: 'income' | 'expenses' | 'other' | 'totalExpensesRatio'
}
const RowValue = React.memo(function RowValue(props: RowTitleProps) {
    const type = props.type || ''
    const expanded = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.rowsExpanded[props.row.id],
    )
    const selectedIds = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.selectedRowIds)
    const t12History = useSelector((store: ReduxStoreState) => store.lender.t12History)
    const showPercentage = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showPercentage,
    )
    const columns = useSelector((store: ReduxStoreState) => store.lenderDashboard.lenderCashFlowTab.columnsSelected)
    const numberOfUnits = useSelector((state: ReduxStoreState) => state.lender.originationModel?.numberOfUnits || 1)
    // sync height with title rows
    useEffect(() => {
        const titleEl = document.getElementById(`row-title-${props.row.id}`)
        const valueEl = document.getElementById(`row-value-${props.row.id}`)
        const height = titleEl.getBoundingClientRect().height
        valueEl.style.height = `${height}px`
        if (expanded) {
            for (const ind in props.row.subRows) {
                const titleEl = document.getElementById(`subrow-title-${props.row.id}-${ind}`)
                const height = titleEl.getBoundingClientRect().height
                const valueEl = document.getElementById(`subrow-value-${props.row.id}-${ind}`)
                valueEl.style.height = `${height}px`
            }
        }
    }, [expanded])
    //sync row height with title
    const idHover = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.rowIdHover)
    const handleMouseIn = useCallback((id) => {
        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowIdHover'], id)
    }, [])
    const handleMouseOut = useCallback(
        (id) => {
            if (idHover == id) ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowIdHover'], null)
        },
        [idHover],
    )

    const renderValues = useCallback(
        (row: CashFlowRowGraphQl) => {
            const values = valuesHelper(row, t12History)
            return columns?.map((c, ind) => {
                let value: string
                const needChangeSign = 1 //row?.originalCFGroup == 'fcfBeforeDebt' ? -1 : 1
                const v = values[c]
                const newVal = numeral(v).value() * needChangeSign
                let perUnit = ''
                if (props.group == 'income') {
                    value = numeral(newVal).format('($0,0)')
                    perUnit = numeral(newVal / 12 / numberOfUnits).format('($0,0)')
                } else if (props.group == 'expenses') {
                    value = numeral(newVal).format('($0,0)')
                    perUnit = numeral(newVal / numberOfUnits).format('($0,0)')
                } else if (props.group == 'other') {
                    value = numeral(newVal).format('($0,0)')
                } else {
                    value = ''
                    perUnit = ''
                }

                const vals = [value, perUnit]
                if (showPercentage) {
                    vals.push(
                        props.percentageBasis
                            ? numeral(Math.abs(newVal / props.percentageBasis[c])).format('0.[00]%')
                            : ' ',
                    )
                }
                if (c.toLowerCase().indexOf('proforma') > -1 && v == '') {
                    return <ValuesBlock customClassName={'proforma-empty-value'} key={ind} values={vals} />
                } else {
                    return <ValuesBlock key={ind} values={vals} />
                }
            })
        },
        [columns, showPercentage],
    )

    const toggleRow = useCallback((id) => {
        SagaHelper.run(['lenderDashboard', 'lenderCashFlowToggleSelectedRow'], { rowId: id }).finally()
    }, [])

    return (
        <>
            <div
                className={`row-value ${type} ${props.row.subRows ? 'sub-rows' : ''} 
                 ${props.row.id == idHover ? 'hover' : ''} ${
                    expanded || selectedIds.includes(props.row.id) ? 'expanded' : ''
                }
                `}
                id={'row-value-' + props.row.id}
                onMouseEnter={() => handleMouseIn(props.row.id)}
                onMouseLeave={() => handleMouseOut(props.row.id)}
                onClick={() => toggleRow(props.row.id)}
            >
                <div className={'values'}>{renderValues(props.row)}</div>
            </div>
            {expanded &&
                props.row.subRows.map((subRow, ind) => {
                    const subId = `${props.row.id}-${ind}`
                    return (
                        <div
                            style={{ width: showPercentage ? 'auto' : '100%' }}
                            className={`values row-value ${subId == idHover ? 'hover' : ''}
                               ${selectedIds.includes(subId) ? 'expanded' : ''}
                            `}
                            id={`subrow-value-${props.row.id}-${ind}`}
                            key={ind}
                            onMouseEnter={() => handleMouseIn(subId)}
                            onMouseLeave={() => handleMouseOut(subId)}
                            onClick={() => toggleRow(subId)}
                        >
                            {renderValues(subRow)}
                        </div>
                    )
                })}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .row-value {
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;
                    align-items: center;
                    position: relative;
                    &.hover {
                        background: #f0f0f2;
                        :global(.values-block) {
                            background: #f0f0f2;
                        }
                    }
                    &.expanded {
                        background: #e1e2e5;
                        :global(.values-block) {
                            background: #e1e2e5;
                        }
                    }
                }
                :global(#row-value-otherIncome-total_other\ income .values-block .value:nth-child(3)),
                :global(#row-value-effectiveGrossIncome .values-block .value:nth-child(3)) {
                    opacity: ${showPercentage ? '0' : '1'};
                }
                .values {
                    display: inline-flex;
                    flex-direction: row;
                    height: 100%;
                }

                .bold {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    span {
                    }
                }
                .total {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    span {
                    }
                }
                .total-blue {
                    background: #ecf3ff;
                    :global(.values-block) {
                        @extend .total;
                        background: #ecf3ff;
                    }
                }
                .total-green {
                    background: #ecfcf2;
                    :global(.values-block) {
                        @extend .total;
                        background: #ecfcf2;
                    }
                }

                img {
                    position: absolute;
                    top: 14px;
                    left: 20px;
                    cursor: pointer;
                }
            `}</style>
        </>
    )
})
type ValuesBlockProps = {
    values: string[]
    customClassName?: string
}
const ValuesBlock = React.memo(function ValuesBlock(props: ValuesBlockProps) {
    return (
        <div className={`values-block ${props.customClassName ? props.customClassName : ''}`}>
            {props.values.map((v, ind) => (
                <span key={ind} className={'value'}>
                    {v}
                </span>
            ))}
            <div className={'delimiter'} />
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .values-block {
                        display: grid;
                        width: ${props.values.length * 133 + 19}px;
                        grid-template-columns: repeat(${props.values.length}, 1fr) 19px;
                        height: 100%;
                        color: $default-text-color;
                        &.proforma-empty-value {
                            .value {
                                opacity: 0;
                            }
                        }
                        .value {
                            text-align: right;
                            align-self: center;
                        }
                        .delimiter {
                            justify-self: center;
                            width: 1px;
                            background: #eceff2;
                            height: 100%;
                        }
                    }
                `}
            </style>
        </div>
    )
})
const OperatingExpensesHeaders = React.memo(function OperatingExpensesHeaders() {
    const showPercentage = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showPercentage,
    )
    return (
        <div className={'headers-block'}>
            <span className={'header'}>TOTAL</span>
            <span className={'header'}>$/UNIT/YEAR</span>
            {showPercentage && <span className={'header'}>%EXPENSES</span>}
            <div className={'delimiter'} />
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .headers-block {
                        display: grid;
                        width: ${showPercentage ? 418 : 285}px;
                        grid-template-columns: repeat(${showPercentage ? 3 : 2}, 1fr) 19px;
                        height: 100%;
                        .header {
                            text-align: right;
                            align-self: center;
                            font-size: 12px;
                            line-height: 15px;
                            letter-spacing: 0.5px;
                            color: $default-text-color;
                        }
                        .delimiter {
                            justify-self: center;
                            width: 1px;
                            background: #eceff2;
                            height: 100%;
                        }
                    }
                `}
            </style>
        </div>
    )
})
type RowValues = {
    underwriting: number
    //proforma: [number | string]
    t12History: any[]
    t12: number
    t9: number
    t6: number
    t3: number
    t1: number
}
function valuesHelper(row: CashFlowRowGraphQl & { proforma?: any }, t12History?: any[]): RowValues {
    const out: RowValues = {} as RowValues
    /*
    if (row.id == 'taxes' || row.id == 'propertyTaxes') {
        out.underwriting = row.years[0]
    } else {
        out.underwriting = row.adjustedT12 || 0
    }

     */
    out.underwriting = row.adjustedT12 || 0

    out.t12 = row.t12 || 0
    //t6
    let last = row.aprDetails?.slice(-9) || [0]
    out.t9 = +(calcArrayAverage(last) * 12).toFixed(2)
    last = row.aprDetails?.slice(-6) || [0]
    out.t6 = +(calcArrayAverage(last) * 12).toFixed(2)
    last = row.aprDetails?.slice(-3) || [0]
    out.t3 = +(calcArrayAverage(last) * 12).toFixed(2)
    last = row.aprDetails?.slice(-1) || [0]
    out.t1 = +(last[0] * 12).toFixed(2)
    for (const fld in row) {
        if (fld.toLowerCase().indexOf('proforma') > -1 || fld.match(/history\d+/)) {
            out[fld] = Math.abs(row[fld]) ? row[fld].toFixed(2) : ''
        }
    }
    return out
}
