import React, { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
//import nmlogo from './northmarq_logo.png'
import nmlogo from '../../../../../../theme/dealnarrative/icons/NorthMARQ logo.png'
import { TextField } from '../../../components/TextField'
import { DateField } from '../../../../../controls/inputs/generalinputs/DateField'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import { IMAGES } from '../../../../../../utils/constants/assets'
import { useRouter } from 'next/router'

type Props = any

export const QuoteHeader = React.memo(function QuoteHeader(props: Props) {
    const readOnly = !props.isEditable
    const formState = useFormState()
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const router = useRouter()

    const dateStr = useMemo(() => {
        const formDate = formState.values?.quotes?.[0]?.date
        let d = new Date()
        if (typeof formDate == 'string' && formDate.match(/[0-9]+$/)) {
            d = new Date(+formDate)
        } else if (formDate) {
            d = new Date(formDate)
        } else {
        }

        return (
            d.getFullYear() +
            '-' +
            (d.getMonth() + 1 < 10 ? '0' : '') +
            (d.getMonth() + 1) +
            '-' +
            (d.getDate() < 10 ? '0' : '') +
            d.getDate()
        )
    }, [formState.values?.quotes?.[0]?.date])

    const [logo, setLogo] = useState({ alt: '', src: '' })
    useEffect(() => {
        const accountLogoUrlFromUrl = router?.query?.accountLogoUrl
        const accountLogoUrl = accountLogoUrlFromUrl || localStorage?.getItem('accountLogoUrl')
        //  console.log('accountLogoUrl', accountLogoUrl)
        if (accountLogoUrl && accountLogoUrl !== 'null') {
            setLogo(() => ({
                alt: localStorage.getItem('accountName') || '',
                src: '/v1/' + accountLogoUrl || '',
            }))
        } else {
            setLogo(() => ({
                alt: 'Seecares',
                src: IMAGES.SEECARES_LOGO,
            }))
        }
        //setUsername(localStorage.getItem('username') || '')
    }, [])

    const logoStyle: any = {}
    if (!props.isNM) {
        logoStyle.width = '196px'
        logoStyle.height = '39px'
    } else {
        logoStyle.width = '110px'
        logoStyle.height = '110px'
    }

    return (
        <>
            <div className={'FannieQuoteHeader'}>
                <div className={'details'}>
                    <div className={'detailsColumn'}>
                        <div className={'detailRow'}>
                            <div className={'title'}>To:</div>
                            <div>
                                <TextField
                                    name={`quotes.0.to`}
                                    className={'cssTextField details'}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                        <div className={'detailRow'}>
                            <div className={'title'}>From:</div>
                            <div>
                                <TextField
                                    name={`quotes.0.from`}
                                    className={'cssTextField details'}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                        <div className={'detailRow'}>
                            <div className={'title'}>Date:</div>
                            <div className={'details' + readOnly ? ' dateTxt' : ''}>
                                {readOnly ? (
                                    dateStr
                                ) : (
                                    <DateField
                                        name={`quotes.0.date`}
                                        className={'cssDateField'}
                                        readOnly={readOnly}
                                        displayFormat={'MM/dd/yyyy'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'detailsColumn'}>
                        <div className={'detailRow'}>
                            <div className={'title'}>Property:</div>
                            <div className={'details'}>{generalInputs.propertyAndBorrower.propertyName}</div>
                        </div>
                        <div className={'detailRow'}>
                            <div className={'title'}>Location:</div>
                            <div className={'details'}>{project?.address}</div>
                        </div>
                        <div className={'detailRow'}>
                            <div className={'title'}>Units:</div>
                            <div className={'details'}>{model?.numberOfUnits}</div>
                        </div>
                    </div>
                    <div className={'detailsColumn '}>
                        <div className={'detailRow logo'}>
                            <img src={props.isNM ? nmlogo : logo.src || IMAGES.SEECARES_LOGO} style={logoStyle} />
                        </div>
                        <div className={'detailRow'}>
                            <div className={'title'}></div>
                            <div className={'details'}></div>
                        </div>
                        <div className={'detailRow'}>
                            <div className={'title'}></div>
                            <div className={'details'}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .FannieQuoteHeader {
                    display: flex;
                    margin-bottom: 20px;
                }
                .details {
                    display: flex;
                    width: 100%;
                }
                .detailRow {
                    display: flex;
                    height: 30px;
                }

                .detailsColumn {
                    width: 33.3%;
                }
                .dateTxt {
                    left: 0px;
                    position: relative;
                }
                .title {
                    color: #687893;
                    width: 25%;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 140%;
                }
                :global(.details) {
                    /*font-family: 'Inter';*/
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 140%;
                    /* or 21px */

                    /* main */

                    color: #161c26;
                }
                .logo {
                    position: relative;
                    float: right;
                    width: 200px;
                    height: 51.2px;
                }
                .cssTextField {
                    height: 30px;
                }
                .cssDateField {
                    height: 30px;
                }
            `}</style>
        </>
    )
})
