import React, { CSSProperties } from 'react'
import { Field } from 'react-final-form'

type Props = {
    name: string
    readOnly?: boolean
    className?: string
    containerClassName?: string
    containerStyle?: CSSProperties
    style?: CSSProperties
    numberFormat?: 'decimal' | 'int'
}

export function OnlyNumberField(props: Props) {
    return (
        <Field name={props.name}>
            {(fieldProps) => {
                let minus = false
                if (('' + fieldProps?.input?.value)?.[0] == '-') minus = true
                let val
                if (props.numberFormat === 'decimal') {
                    val = fieldProps?.input?.value
                        ? ('' + fieldProps.input.value).replace(/[^0-9.]/g, '')
                        : null
                } else {
                    val = fieldProps?.input?.value ? parseInt(('' + fieldProps.input.value).replace(/\D/g, '')) : null
                }
                if (minus) val = -val
                // console.log('OnlyNumberField', props.name, fieldProps?.input?.value, val, typeof val, minus)
                return (
                    <>
                        <span
                            className={`input-container ${props.readOnly ? 'readOnly' : ''} 
                            ${props.containerClassName || ''}`}
                            style={props.containerStyle}
                        >
                            <input
                                {...fieldProps?.input}
                                className={`input ${props?.className || ''}`}
                                readOnly={props.readOnly}
                                style={props.style}
                                value={val || (minus ? '-' : '')}
                                type={props.numberFormat === 'decimal' ? 'text' : 'number'}
                            />
                        </span>
                        {/*language=SCSS*/}
                        <style jsx>
                            {`
                                @import './src/scss/colors.scss';
                                .input-container {
                                    position: relative;
                                    width: 100%;
                                    &:after {
                                        width: 100%;
                                        border-width: 0;
                                        text-align: right;
                                        font-weight: 500;
                                        font-size: 14px;
                                        color: $default-text-color;
                                        height: 26px;
                                        border-top: 1px solid transparent;
                                        background-color: transparent;
                                    }
                                    &:focus-within:after {
                                        border-top: 1px solid transparent;
                                    }

                                    .input {
                                        width: 100%;
                                        border-width: 0;
                                        color: $black;
                                        height: 26px;
                                        border-top: 1px solid transparent;
                                        background-color: transparent;
                                        &:focus {
                                            border: 1px solid $blue;
                                            border-radius: 3px;
                                            outline: none;
                                        }
                                    }
                                    &.readOnly :global(.input:focus) {
                                        border: inherit;
                                    }
                                }
                                .input-container.readOnly:after {
                                    border-top: 1px solid transparent;
                                }
                            `}
                        </style>
                    </>
                )
            }}
        </Field>
    )
}
