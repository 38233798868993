import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'

import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../store'

import { NumberField } from '../../components/NumberField'

type Props = any

export function NetOperatingExpensesTable(props: Props) {
    const state = useFormState().values
    const yearsCount = state?.cashFlow?.noi?.years?.length || 0
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const accountType = useMemo(() => {
        return project?.accountType
    }, [project])

    return (
        <>
            <table className={'table1'}>
                <tbody>
                    <tr className={'header'}>
                        <td />
                        <td className={'gray-header'}>YEAR 1</td>
                        <td className={'gray-header'}>YEAR {yearsCount}</td>
                    </tr>
                    <tr>
                        <td>{model?.useNCFnotNOI ? 'Net Cash Flow' : 'Net Operating Income'}</td>
                        <td className={'number-large'}>
                            <NumberField numberFormat={'currency'} name={'cashFlow.noi.years[0]'} readOnly={true} />
                        </td>
                        <td className={'number-large'}>
                            <NumberField
                                numberFormat={'currency'}
                                name={`cashFlow.noi.years[${yearsCount - 1}]`}
                                readOnly={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Cash Flow After Debt Service</td>
                        <td>
                            <NumberField
                                numberFormat={'currency'}
                                name={'cashFlow.cashFlowAfterDebtService.years[0]'}
                                readOnly={true}
                            />
                        </td>
                        <td>
                            <NumberField
                                numberFormat={'currency'}
                                name={`cashFlow.cashFlowAfterDebtService.years[${yearsCount - 1}]`}
                                readOnly={true}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className={'table2'}>
                <tbody>
                    {accountType == 'borrower' && (
                        <>
                            <tr>
                                <td>Deal IRR</td>
                                <td>
                                    <NumberField numberFormat={'percent'} name={'computed.dealIRR'} readOnly={true} />
                                </td>
                                <td>CoC</td>
                                <td>
                                    Avg{' '}
                                    <NumberField numberFormat={'percent'} name={'computed.avgCoc'} readOnly={true} />
                                </td>
                            </tr>
                            <tr>
                                <td className={'net-irr'}>Net IRR</td>
                                <td>
                                    <NumberField numberFormat={'percent'} name={'computed.netIRR'} readOnly={true} />
                                </td>
                                <td>RoE</td>
                                <td>
                                    Avg{' '}
                                    <NumberField numberFormat={'percent'} name={'computed.avgRoe'} readOnly={true} />
                                </td>
                            </tr>
                            <tr>
                                <td />
                                <td>Cap rate</td>
                                <td colSpan={2} style={{ textAlign: 'left' }}>
                                    <NumberField
                                        numberFormat={'percent'}
                                        name={'computed.avgCapRate'}
                                        readOnly={true}
                                    />
                                </td>
                            </tr>
                        </>
                    )}

                    {accountType == 'lender' && (
                        <>
                            <tr>
                                <td>DSCR</td>
                                <td>
                                    Avg.{' '}
                                    <NumberField numberFormat={'multiple'} name={'computed.dscr'} readOnly={true} />
                                </td>
                            </tr>
                            <tr>
                                <td>Debt Yield</td>
                                <td>
                                    Avg.{' '}
                                    <NumberField numberFormat={'percent'} name={'computed.debtyield'} readOnly={true} />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    $leftPadding: 38px;
                    $rightPadding: 40px;
                    .gray-header {
                        font-size: 12px;
                        line-height: 15px;
                        /* identical to box height */

                        text-align: right;
                        letter-spacing: 1px;
                        color: $gray-nevada;
                    }
                    .number-large {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        /* identical to box height */
                        color: $black;
                    }
                    table {
                        color: $black;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 18px;
                    }
                    .table1 {
                        border-top: 1px solid $border;
                        td {
                            padding: 6px 0;
                        }
                        td:first-child {
                            padding-left: $leftPadding;
                        }
                        td:last-child {
                            padding-right: $rightPadding;
                        }
                        td:nth-child(2),
                        td:nth-child(3) {
                            text-align: right;
                        }
                        tr:first-child {
                            td {
                                padding-top: 11px;
                            }
                        }
                        tr:nth-child(2) {
                            height: 30px;
                            vertical-align: top;
                        }
                        tr:nth-child(3) {
                            background-color: $border;
                            height: 28px;
                        }
                    }
                    .table2 {
                        td {
                            border-bottom: 1px solid $border;
                            padding: 6px 0;
                        }
                        td:first-child {
                            padding-left: $leftPadding;
                            width: 35%;
                        }
                        td:last-child {
                            padding-right: $rightPadding;
                        }

                        td:nth-child(2) {
                            width: 15%;
                            border-right: 1px solid $border;
                        }
                        tr:last-child td:nth-child(2) {
                            border-right-width: 0;
                        }
                        td:nth-child(3),
                        td:nth-child(4) {
                            text-align: right;
                        }

                        .net-irr {
                            padding-left: 43px !important;
                        }
                    }
                `}
            </style>
        </>
    )
}
