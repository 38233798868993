import React from 'react'
import { WidgetWrapper } from '../../../../components/WidgetWrapper'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { useModel } from '../../../../hooks/useModel'
import { DonutChart } from './DonutChart'
import numeral from 'numeral'
import { CHART_COLOR } from '../../../../../../../utils/constants/chartColors'

type Props = { isFineTuneOn?: boolean }

export function UnitMix(props: Props) {
    /*
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)
    const cashFlow = useSelector((state: ReduxStoreState) => state?.lender?.originationModel?.cashFlow)
    const model = useSelector((state: ReduxStoreState) => state?.lender?.originationModel)
    const model = useModel()
    */
    const unitMixSelector = useSelector((state: ReduxStoreState) => state.lender.unitMix)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    //console.log('UnitMix', unitMixSelector)

    let totalunits = 0
    const bybedrooms = [0, 0, 0, 0, 0, 0]
    const byselection = []
    let keyForRetail = null
    for (let k = 0; k < unitMixSelector.length; k++) {
        const unitmix = unitMixSelector[k]
        totalunits += unitmix.numofunits
        bybedrooms[unitmix.bedrooms] += unitmix.numofunits
        let key: string | number = ''
        if (unitmix.unitType === 'retail') {
            if (keyForRetail === null) keyForRetail = byselection.length
            key = keyForRetail ? keyForRetail : byselection.length
        } else {
            key = unitmix.bedrooms
        }
        let x = byselection[key]
        if (!x) {
            x = {}
            x.floorPlan = unitmix.floorPlan
            x.bedrooms = unitmix.bedrooms
            x.unitType = unitmix.unitType
            x.baths = unitmix.baths
            x.numofunits = unitmix.numofunits
            x.minprice = x.maxprice = unitmix.rentprice
            x.minsize = x.maxsize = unitmix.unitsize
            x._totalSize = unitmix.unitsize * unitmix.numofunits
            x._totalRent =
                unitmix.rentprice * unitmix.numofunits -
                (generalInputs?.propertyAndBorrower.lowRatioAffordable && unitmix?.overhang ? unitmix?.overhang : 0)
        } else {
            x.numofunits += unitmix.numofunits
            if (unitmix.rentprice < x.minprice) x.minprice = unitmix.rentprice
            else if (unitmix.rentprice > x.maxprice) x.maxprice = unitmix.rentprice
            if (unitmix.unitsize < x.minsize) x.minsize = unitmix.unitsize
            else if (unitmix.unitsize > x.maxsize) x.maxsize = unitmix.unitsize

            x._totalSize += unitmix.unitsize * unitmix.numofunits
            x._totalRent +=
                unitmix.rentprice * unitmix.numofunits -
                (generalInputs?.propertyAndBorrower.lowRatioAffordable && unitmix?.overhang ? unitmix?.overhang : 0)
        }

        byselection[key] = x
    }

    let totalSize = 0
    let totalRent = 0
    let totalUnits = 0
    let graphLabels = []
    let graphValues = []

    byselection.map((itm, ind) => {
        totalSize += itm._totalSize
        totalRent += itm._totalRent
        totalUnits += itm.numofunits

        itm.avgRent = itm._totalRent / itm.numofunits
        itm.avgSize = itm._totalSize / itm.numofunits
        itm.color = CHART_COLOR.LENDER_DASHBOARD_PALETTE
        itm.bedrooms > 0
            ? graphLabels.push(itm.bedrooms + ' Bedrooms')
            : itm.unitType === 'retail'
            ? graphLabels.push('Retail')
            : graphLabels.push('Studio')
        graphValues.push(itm.numofunits)
    })
    //console.log(byselection)
    return (
        <>
            <WidgetWrapper title={'Unit Mix'}>
                <div className={'unitMixContent'}>
                    <div className={'unitMixTable'}>
                        <table>
                            <thead>
                                <tr>
                                    <th>UNIT TYPE</th>
                                    <th>RENT/MONTH</th>
                                    <th>RENT/MONTH/SF</th>
                                </tr>
                            </thead>
                            <tbody>
                                {byselection.map((itm, ind) => {
                                    return (
                                        <React.Fragment key={ind.toString()}>
                                            <tr>
                                                <td className={'bedroom'}>
                                                    <span
                                                        className={'colored-circle'}
                                                        style={{ backgroundColor: itm.color[ind] }}
                                                    ></span>
                                                    {itm.bedrooms > 0
                                                        ? itm.bedrooms + ' Bedrooms'
                                                        : itm.unitType === 'retail'
                                                        ? 'Retail'
                                                        : 'Studio'}
                                                </td>
                                                <td className={'price'}>{numeral(itm.avgRent).format('$0,0')}</td>
                                                <td className={'price'}>
                                                    {numeral(itm.avgRent / itm.avgSize).format('0,0.[00]')}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                                <tr className={'avergae'}>
                                    <td className={'bedroom'}>
                                        <span className={'colored-circle'}></span>
                                        Average
                                    </td>
                                    <td className={'price'}>{numeral(totalRent / totalUnits).format('$0,0')}</td>
                                    <td className={'price'}>{numeral(totalRent / totalSize).format('0,0.[00]')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={'unitMixGraph'}>
                        <DonutChart labels={graphLabels} values={graphValues} />
                    </div>
                </div>
            </WidgetWrapper>

            {/*language=SCSS*/}
            <style jsx>{`
                .colored-circle {
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    border-radius: 50%;
                    margin-right: 5px;
                }
                .unitMixContent {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 20px;

                    @media screen and (max-width: 1368px) {
                        grid-template-columns: ${props.isFineTuneOn ? '1fr' : '1fr 1fr'};
                        grid-template-rows: auto auto;
                    }
                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 1fr 0.8fr;
                    }
                }
                .avergae {
                }
                tr {
                    height: 35px;
                }
                .unitMixTable {
                    margin-left: -20px;
                }
                table {
                    width: 100%;

                    tbody {
                        tr {
                            &:nth-child(odd) {
                                background: #f9fafb;
                            }
                            td:last-child {
                                padding-right: 16px;
                            }
                        }
                    }
                }
                .bedroom,
                th:first-child {
                    padding-left: 20px;
                }
                th {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #676f7a;
                    &:not(:first-child) {
                        text-align: right;
                    }
                }
                .price {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: right;
                    color: #161c26;
                }
                .bedroom {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: #262b35;
                    white-space: nowrap;
                }
                .title {
                    width: 100%;
                    padding-bottom: 10px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: #161c26;
                    border-bottom: 1px solid #d9e0e5;
                }
            `}</style>
        </>
    )
}
