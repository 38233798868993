import React, { useMemo } from 'react'
import numeral from 'numeral'
import { animated } from 'react-spring'
import { SpinnerOrError } from '../../../../components/SpinnerOrError'

type Props = {
    cashFlow: number
    treshold: number
    percentage: number
    value: number
    title: string
    cashFlowTitle: string
    tresholdTitle: string
    loading?: boolean
    error?: string
}

export function DebtCoverageComponent(props: Props) {
    const numberFormat = '$0,0'
    const barsCount = 50
    const bars = []
    let lastBgColor = 'rgb(68, 133, 255)'
    const colorsArray = useMemo(() => interpolateColors('rgb(239, 96, 107)', 'rgb(68, 133, 255)', barsCount), [])
    for (let i = 0; i < barsCount; i++) {
        let backgroundColor = '#d9e0e5'
        let height = '100%'
        if (i * 2 < props.percentage) {
            backgroundColor = `rgb(${colorsArray[Math.round((props.percentage / 100) * barsCount) - 1]})`
            lastBgColor = `rgb(${colorsArray[i]})`
        }

        if (i * 2 < props.percentage && (i + 1) * 2 >= props.percentage) {
            height = '120%'
        }

        bars.push(
            <div
                key={i}
                className="item"
                style={{
                    width: 5,
                    marginRight: 2,
                    border: '1px solid rgba(255,255,255,.2)',
                    backgroundColor,
                    height,
                }}
            />,
        )
    }

    return (
        <div className={'coverage-wrapper'}>
            <div className={'coverage-wrapper-title'}>{props.title}</div>
            {!props.loading ? (
                <div style={{ padding: 20 }}>
                    <div className="coverage-wrapper-bar">
                        {bars}
                        <div className="bar-badge">{numeral(props.value).format('0.[00]')}</div>
                    </div>
                    <div className="coverage-wrapper-items">
                        <div className="item">
                            <div className="title">{props.cashFlowTitle}</div>
                            <div className="value">{numeral(props.cashFlow).format(numberFormat)}</div>
                        </div>
                        <div className="item">
                            <div className="title">{props.tresholdTitle}</div>
                            <div className="value">{numeral(props.treshold).format('0.[00]')}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <SpinnerOrError height={'224px'} error={props.error} />
            )}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .coverage-wrapper {
                    background-color: $white;
                    border: 1px solid $border;
                    border-radius: 3px;
                    width: 100%;
                    height: 306px;
                    &-title {
                        font-size: 15px;
                        font-weight: 500;
                        padding: 10px 20px;
                        color: $black;
                        border-bottom: 1px solid $border;
                    }
                    &-bar {
                        height: 20px;
                        display: flex;
                        margin-bottom: 100px;
                        margin-top: 60px;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        .item {
                            width: 4px;
                            height: 100%;
                            background: #d9e0e5;
                            margin-right: 2px;
                        }

                        .bar-badge {
                            position: absolute;
                            top: calc(100% + 15px);
                            background-color: ${lastBgColor};
                            left: calc(${props.percentage + '%'} - 30px);
                            color: black;

                            font-weight: 500;
                            font-size: 20px;
                            text-align: center;
                            padding: 1px 3px;
                            border-radius: 3px;
                            min-width: 55px;
                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 50%;
                                width: 0;
                                height: 0;
                                border: 5px solid transparent;
                                border-bottom-color: ${lastBgColor};
                                border-top: 0;
                                margin-left: -5px;
                                margin-top: -5px;
                            }
                        }
                    }
                    &-items {
                        display: flex;
                        align-items: center;
                        justify-content: left;

                        .item {
                            margin-right: 20px;
                            .title {
                                font-size: 0.8rem;
                                color: $gray;
                                opacity: 0.8;
                                letter-spacing: 0;
                            }
                            .value {
                                font-weight: 500;
                                font-size: 1rem;
                                color: $dark-gray;
                                letter-spacing: 0;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}

export const AnimatedDebtCoverageComponent = animated(DebtCoverageComponent)

function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5
    }
    const result = color1.slice()
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
    }
    return result
}
function interpolateColors(color1, color2, steps) {
    const stepFactor = 1 / (steps - 1)
    const interpolatedColorArray = []
    color1 = color1.match(/\d+/g).map(Number)
    color2 = color2.match(/\d+/g).map(Number)
    for (let i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i))
    }
    return interpolatedColorArray
}
