import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { regenerateCashFlow } from 'origination-model'
import { nmGetLTC, nmGetLTPP, nmGetLTV, nmGetMaximumBuiltUpCost, nmGetTotalCostBasis } from '../northMarkFormulas'
type Props = any
export const PropertyDetails = React.memo(function PropertyDetails(props: Props) {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)

    const loanAmount = useMemo(() => {
        return generalInputs?.loanDetails?.requestedLoanAmount
    }, [generalInputs?.loanDetails?.requestedLoanAmount])

    const noi = useMemo(() => {
        return cashFlow?.noi?.adjustedT12
    }, [cashFlow, cashFlow?.noi?.adjustedT12])

    const capRate = useMemo(() => {
        if (model?.fanniePricing?.valuationComparison?.length > 0) {
            for (const v of model?.fanniePricing?.valuationComparison) {
                if (v.selected) return v.capRate
            }
            return 0.0525
        }
        return 0.0525
    }, [model?.fanniePricing?.valuationComparison])

    const pricingDate = useMemo(() => {
        const d = model?.fanniePricing?.loanInfo?.dateOfPricing || new Date()
        const x = new Date(d)
        return x.getMonth() + 1 + '/' + x.getDate() + '/' + x.getFullYear()
    }, [model?.fanniePricing?.loanInfo?.dateOfPricing])

    const LTC = useMemo(() => {
        return nmGetLTC(model?.uses?.purchasePrice, loanAmount, generalInputs?.dealDetails?.budgetedCapEx)
    }, [model?.uses?.purchasePrice, loanAmount, generalInputs?.dealDetails?.budgetedCapEx])

    const LTV = useMemo(() => {
        return nmGetLTV(capRate, noi, loanAmount)
    }, [capRate, noi, loanAmount])

    const totalCostBasis = useMemo(() => {
        return nmGetTotalCostBasis(
            model?.uses?.purchasePrice,
            generalInputs?.dealDetails?.escrowedImprovements,
            model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
        )
    }, [
        model?.uses?.purchasePrice,
        generalInputs?.dealDetails?.escrowedImprovements,
        model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
    ])

    const maximumBuiltUpCost = useMemo(() => {
        return nmGetMaximumBuiltUpCost(totalCostBasis)
    }, [totalCostBasis])

    const { LTPP, LTCRefianance } = useMemo(() => {
        const LTPP = nmGetLTPP(model?.uses?.purchasePrice, loanAmount)
        let LTCRefianance = 0
        if (maximumBuiltUpCost) {
            LTCRefianance = loanAmount / maximumBuiltUpCost
        } else {
            const uwValue = capRate ? noi / capRate : 0
            if (uwValue) {
                LTCRefianance = loanAmount / uwValue
            }
        }
        return { LTPP, LTCRefianance }
    }, [loanAmount, model?.uses?.purchasePrice, maximumBuiltUpCost, capRate])

    const isSupplemental = useMemo(() => {
        if (project?.type == 'miltifamily.value_add_supplemental') return true
        return false
    }, [project?.type])

    const defaultLoanType = useMemo(() => {
        if (!isSupplemental) return 'Fixed Rate - DUS'
        return 'Fixed Rate - DUS'
    }, [isSupplemental])

    const dealType = useMemo(() => {
        return generalInputs?.loanDetails?.acquisitionOrRefinance || 'acquisition'
    }, [generalInputs?.loanDetails?.acquisitionOrRefinance])

    const isAcquisition = useMemo(() => {
        return dealType.toLowerCase() == 'acquisition'
    }, [dealType])

    const ltvRefinance = useMemo(() => {
        if (capRate) {
            if (isAcquisition) {
                const v = noi / capRate
                return model?.uses?.purchasePrice / v
            }
            return nmGetLTV(capRate, noi, loanAmount)
        }
        return 0
    }, [noi, capRate, isAcquisition])

    const combinedLTV = useMemo(() => {
        if (!isSupplemental) return 0
        let defCombined = generalInputs?.loanDetails?.requestedLoanAmount
        if (model?.fanniePricing?.supplemental)
            for (const sup of model.fanniePricing.supplemental) {
                defCombined += sup.priorLien.estimatedUpbAtSupClosing
            }
        const combinedUPB = pricing?.fanniePricing?.pricingOptions?.[0]?.sizer?.underwritten?.combinedUPB || defCombined

        if (isAcquisition) {
            return combinedUPB / maximumBuiltUpCost
        } else {
            if (!capRate) return 0
            else return combinedUPB / (noi / capRate)
        }
    }, [
        isSupplemental,
        isAcquisition,
        maximumBuiltUpCost,
        noi,
        capRate,
        pricing?.fanniePricing?.pricingOptions?.[0],
        model?.fanniePricing?.supplemental,
        generalInputs?.loanDetails?.requestedLoanAmount,
    ])
    return (
        <>
            <div className={'pricingAnalyticsComponent'}>
                <div className={'title'}>Property Information</div>
                <div className={'mainPart'}>
                    <div className={'mainImg'}>
                        <div className={'imgWrapper'}>
                            <img src={project?.filePath} />
                        </div>
                        <div className={'imgText'}>
                            <div className={'projectName'}> {project?.name}</div>
                            <div className={'projectAddress'}> {project?.address}</div>
                        </div>
                    </div>
                    <div className={'leftSection'}>
                        <div className={'propertyDetailsRow'}>
                            <div className={'box longBox'}>
                                <div className={'boxTitle'}>
                                    {isSupplemental ? 'Supplemental ' : ''}Requested Loan Amount
                                </div>
                                <div className={'boxBigValue'}>{numeral(loanAmount).format('$0,0')}</div>
                            </div>
                            <div className={'box'}>
                                <div className={'boxTitle'}>Per Unit</div>
                                <div className={'boxBigValue'}>
                                    {numeral(model?.numberOfUnits ? loanAmount / model?.numberOfUnits : 0).format(
                                        '$0,0',
                                    )}
                                </div>
                            </div>
                            <div className={'box'}>
                                <div className={'boxTitle'}>Loan Purpose</div>
                                <div className={'boxValue'}>{generalInputs?.loanDetails?.borrowerLoanPurpose}</div>
                            </div>
                            <div className={'box'}>
                                <div className={'boxTitle'}>Loan Type</div>
                                <div className={'boxValue'}>
                                    {model?.fanniePricing?.loanInfo?.type || defaultLoanType}
                                </div>
                            </div>
                            <div className={'box'}>
                                <div className={'boxTitle'}>Debt Yield Proceeds</div>
                                <div className={'boxValue'}>
                                    {numeral(loanAmount ? cashFlow?.noi?.adjustedT12 / loanAmount : 0).format(
                                        '0.[00]%',
                                    )}
                                </div>
                            </div>
                            <div className={'box'}>
                                <div className={'boxTitle'}>Date of Pricing</div>
                                <div className={'boxValue'}>{pricingDate}</div>
                            </div>
                        </div>
                        <div className={'propertyDetailsRow'}>
                            <div className={'box longBox'}>
                                <div className={'boxTitle'}>Number Of Units</div>
                                <div className={'boxValue'}>{model?.numberOfUnits}</div>
                            </div>

                            <div className={'box'}>
                                <div className={'boxTitle'}>Year built</div>
                                <div className={'boxValue'}>{model?.yearBuilt}</div>
                            </div>
                            <div className={'box'}>
                                <div className={'boxTitle'}>Year Renovated</div>
                                <div className={'boxValue'}>{model?.yearRenovated}</div>
                            </div>

                            <div className={'box'}>
                                <div className={'boxTitle'}>Property Type</div>
                                <div className={'boxValue'}>{generalInputs?.propertyAndBorrower?.propertyType}</div>
                            </div>

                            <div className={'box'}>
                                <div className={'boxTitle'}>Borrower</div>
                                <div className={'boxValue'}>{generalInputs?.propertyAndBorrower?.borrowerName}</div>
                            </div>

                            <div className={'box'}>
                                <div className={'boxTitle'}>Fannie Mae Gateway ID</div>
                                <div className={'boxValue'}></div>
                            </div>
                        </div>
                        <div className={'propertyDetailsRow'}>
                            <div className={'box'} style={{ height: '10px' }}>
                                <div className={'boxTitle'}>Quick Summary</div>
                            </div>
                        </div>
                        <div className={'propertyDetailsRow'}>
                            {project?.type != 'miltifamily.value_add_supplemental' && (
                                <>
                                    <div className={'greyBox box'}>
                                        <div className={'boxTitle'}>LTV (construction)</div>
                                        <div className={'boxValue'}>{numeral(LTV).format('0.[00]%')}</div>
                                    </div>
                                    <div className={'greyBox box'}>
                                        <div className={'boxTitle'}>LTC (including Cap Ex)</div>
                                        <div className={'boxValue'}>{numeral(LTC).format('0.[00]%')}</div>
                                    </div>
                                </>
                            )}
                            <div className={'greyBox box'}>
                                <div className={'boxTitle'}>Value (UW NCF)</div>
                                <div className={'boxValue'}>{numeral(capRate ? noi / capRate : 0).format('$0,0')}</div>
                            </div>
                            {project?.type != 'miltifamily.value_add_supplemental' && (
                                <>
                                    {!isAcquisition && (
                                        <div className={'greyBox box'}>
                                            <div className={'boxTitle'}>LTV (refinance)</div>
                                            <div className={'boxValue'}>{numeral(ltvRefinance).format('0.[00]%')}</div>
                                        </div>
                                    )}
                                    <div className={'greyBox box'}>
                                        <div className={'boxTitle'}>LTPP</div>
                                        <div className={'boxValue'}>{numeral(LTPP).format('0.[00]%')}</div>
                                    </div>
                                    <div className={'greyBox box'}>
                                        <div className={'boxTitle'}>LTC</div>
                                        <div className={'boxValue'}>{numeral(LTCRefianance).format('0.[00]%')}</div>
                                    </div>
                                </>
                            )}
                            {project?.type == 'miltifamily.value_add_supplemental' && (
                                <div className={'greyBox box'}>
                                    <div className={'boxTitle'}>Combined LTV</div>
                                    <div className={'boxValue'}>{numeral(combinedLTV).format('0.[00]%')}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .pricingAnalyticsComponent {
                    background: white;
                    border: 1px solid #d9e0e5;
                    /* card shadow */

                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-bottom: 20px;
                    width: 1840px;
                }
                .title {
                    border-bottom: 1px solid #d9e0e5;
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                .mainPart {
                    display: flex;
                }
                .mainImg {
                    width: 359px;
                    height: 305px;
                    position: relative;
                    margin-right: 10px;
                    .imgWrapper {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    .imgText {
                        width: 100%;
                        height: 78px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: rgba(255, 255, 255, 0.85);
                        backdrop-filter: blur(3px);
                        border-radius: 0px 0px 0px 3px;
                        text-indent: 20px;
                        .projectName {
                            margin-top: 15px;
                            font-weight: 500;
                        }
                        .projectAddress {
                            font-weight: 400;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .leftSection {
                    margin-left: 20px;
                }
                .propertyDetailsRow {
                    display: flex;
                    margin-top: 20px;
                }
                .greyBox {
                    background: #f5f6f9;
                    border-radius: 4px;
                    margin-right: 20px;
                    text-indent: 20px;
                    width: 175px !important;
                    height: 80px !important;
                }
                .box {
                    width: 230px;
                    height: 50px;
                    .boxTitle {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #97a2b4;
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }
                    .boxBigValue {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 27px;
                        color: #161c26;
                    }
                    .boxValue {
                        ont-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 18px;
                        color: #161c26;
                    }
                }
                .longBox {
                    width: 300px;
                }
            `}</style>
        </>
    )
})
