import React, { SyntheticEvent, useCallback, useState } from 'react'
import { showErrorNotification } from '../../../../fullproject/notifications'
import { SagaHelper } from 'store/sagaHelper'
import { Spinner } from '../../../../../controls/Spinner'

type Props = {
    projectId: number
    close: () => void
}

export const NewLeaseUpModal = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const { projectId, close } = props

    const handleSubmit = useCallback(
        async () => {
            setLoading(true)
            try {
                const response = await SagaHelper.run(
                    ['lenderLeaseUp', 'deleteLeaseUp'],
                    +projectId
                )
                if (response) {
                    close()
                } else {
                    showErrorNotification('Something went wrong')
                    return
                }
            } catch (e) {
                showErrorNotification(e.message)
            } finally {
                setLoading(false)
            }
        },
        [projectId],
    )

    const handleClose = (e: SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()
        close()
    }

    return (
        <div className={'lender-modal-layout'} onClick={handleClose}>
            <div className="lender-modal" onClick={(e) => e.stopPropagation()}>
                <h2 className="lender-modal-title">New Lease-Up</h2>
                <div className="lender-modal-content">
                    {!loading && (
                        <div>
                            <div className="modal-msg">
                                Are you sure you want to create new Lease-Up? <b>Any current data and calculations will be
                                discarded.</b> This action can’t be undone.
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end lender-control-buttons">
                                    <button className="btn-blue btn btn-primary ghost" onClick={close}>
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-danger regular"
                                        onClick={handleSubmit}
                                    >
                                        Discard and Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {loading && <Spinner />}
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .lender-modal-layout {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(14px);
                    z-index: 10000;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lender-modal {
                        background: $white;
                        width: 500px;
                        border: 1px solid $border;
                        border-radius: 4px;
                        padding: 40px;
                        &-title {
                            color: $black;
                            font-size: 16px;
                            font-weight: 400;
                            margin-bottom: 30px;
                        }
                        &-content {
                            .modal-msg {
                                margin-bottom: 20px;
                            }
                        }
                    }

                    :global(.lender-modal-input) {
                        border-radius: 3px;
                        background: $white;
                        border: 1px solid $border;
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                    }

                    .lender-control-buttons {
                        margin-top: 20px;
                        button {
                            margin-left: 10px;
                            font-size: 13px;
                            box-shadow: none;
                            &.ghost {
                                color: $blue;
                                border: 1px solid $blue;
                                background: $white;
                                font-weight: 400;
                            }
                            &.regular {
                                font-weight: 500;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
