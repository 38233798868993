import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../store'
import numeral from 'numeral'
import { useMemo } from 'react'
import { CashFlowRow } from '../../../../store/types/OriginationModelRedux'

export function useModel() {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    //console.log('model', model)
    return useMemo(() => {
        const purchasePrice = model?.uses.purchasePrice
        // Equity
        const totalEquity = model?.sources.totalEquity
        const loanAmount = model?.sources.seniorDebt

        const emptyRow = () => ({
            id: '',
            title: '',
            apr: 0,
            years: [0, 0, 0, 0, 0, 0],
        })
        const cashFlow = model?.cashFlow || {
            rentalIncome: [],
            rentalLoss: [],
            otherIncome: [],
            operatingExpenses: [],
            other: [],
            totalPotentialGrossRent: emptyRow(),
            effectiveGrossRent: emptyRow(),
            effectiveGrossIncome: emptyRow(),
            totalOperatingExpenses: emptyRow(),
            noi: emptyRow(),
            cashFlowAfterDebtService: emptyRow(),
        }

        let debtServiceSenior: any = emptyRow() as CashFlowRow
        let debtServiceMez: any = emptyRow() as CashFlowRow
        let proceedFromSale = emptyRow() as CashFlowRow
        let loadPayoffRow = emptyRow() as CashFlowRow
        let repairsAndMaintenance = emptyRow() as CashFlowRow

        for (const row of [...(cashFlow?.operatingExpenses || []), ...(cashFlow?.other || [])]) {
            if (row.id == 'debtServiceInterest') {
                debtServiceSenior = row
            }
            if (row.id == 'debtServiceInterestMez') {
                debtServiceMez = row
            }
            if (row.id == 'proceedsFromSale') {
                proceedFromSale = row
            }
            if (row.id == 'loanPayOff') {
                loadPayoffRow = row
            }
            if (row.id == 'maintenance') {
                repairsAndMaintenance = row
            }
        }
        const cashFlowAfterDebtWithoutSale = emptyRow()
        if (cashFlow && cashFlow?.cashFlowAfterDebtService) {
            cashFlowAfterDebtWithoutSale.years = cashFlow?.cashFlowAfterDebtService.years.map((v, i) => {
                const sale = proceedFromSale.years[i] || 0
                const loanPayOff = loadPayoffRow.years[i] || 0
                return v - sale - loanPayOff
            })
        }
        return {
            purchasePrice: numeral(purchasePrice).value(),
            totalEquity: numeral(totalEquity).value(),
            cashFlowAfterDebt: cashFlow?.cashFlowAfterDebtService,
            avgCashFlowAfterDebt: calcAvgValue(cashFlow?.cashFlowAfterDebtService?.years),
            debtService: debtServiceSenior,
            avgDebtService: Math.abs(calcAvgValue(debtServiceSenior.years)),
            noi: cashFlow?.noi,
            avgNoi: calcAvgValue(cashFlow?.noi?.years),
            cashflow: cashFlow,
            numberOfUnits: model?.numberOfUnits || 0,
            grossPotentialRent: cashFlow?.totalPotentialGrossRent,
            avgGrossPotentialRent: calcAvgValue(cashFlow?.totalPotentialGrossRent?.years),
            effectiveGrossIncome: cashFlow?.effectiveGrossIncome,
            avgEffectiveGrossIncome: calcAvgValue(cashFlow?.effectiveGrossIncome?.years),
            effectiveGrossRent: cashFlow?.effectiveGrossRent,
            avgEffectiveGrossRent: calcAvgValue(cashFlow?.effectiveGrossRent?.years),
            totalExpenses: cashFlow?.totalOperatingExpenses,
            avgTotalExpenses: calcAvgValue(cashFlow?.totalOperatingExpenses?.years),
            proceedFromSale,
            cashFlowAfterDebtWithoutSale,
            avgCashFlowAfterDebtWithoutSale: calcAvgValue(cashFlowAfterDebtWithoutSale?.years),
            loanAmount,
            repairsAndMaintenance,
            avgRepairsAndMaintenance: calcAvgValue(repairsAndMaintenance?.years),
            tableLength: cashFlow?.totalPotentialGrossRent?.years?.length || 0,
        }
    }, [model])
}
export function calcAvgValue(row: number[]) {
    let avg = 0
    if (!row || row.length == 0) return 0
    for (let i = 0; i < row.length; i++) {
        if (row[i] != null) {
            avg += numeral(row[i]).value()
        }
    }
    return avg / row.length
}
export function calcMinMax(row: number[]) {
    let val
    if (!row) return { min: null, max: null }
    const out = { min: null, max: null }
    for (let i = 0; i < row.length; i++) {
        val = row[i]
        if (isNaN(val)) {
            continue
        }
        if (out.max == null || val > out.max) {
            out.max = val
        }
        if (out.min == null || val < out.min) {
            out.min = val
        }
    }
    return out
}
