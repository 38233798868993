import React from 'react'
import { NumberField } from '../../../components/NumberField'

type Props = any

export function ReturnMetrics(props: Props) {
    return (
        <>
            <div className="container" style={{ paddingLeft: 27 }}>
                <div className="row model-field-wrapper">
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">Net Profit</div>
                    </div>
                    <div className="col-4 p-0 ">
                        <NumberField name={`computed.npv`} numberFormat={'currency'} readOnly={true} />
                    </div>
                </div>
                <div className="row model-field-wrapper">
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">Deal Equity Multiple</div>
                    </div>
                    <div className="col-4 p-0 ">
                        <NumberField name={`computed.dealEquityMultiply`} numberFormat={'decimal'} readOnly={true} />
                    </div>
                </div>
                <div className="row model-field-wrapper">
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">CoC (YEAR 1)</div>
                    </div>
                    <div className="col-4 p-0 ">
                        <NumberField name={`computed.coc[0]`} numberFormat={'percent'} readOnly={true} />
                    </div>
                </div>
                <div className="row model-field-wrapper">
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">Avg COC</div>
                    </div>
                    <div className="col-4 p-0 ">
                        <NumberField numberFormat={'percent'} name={'computed.avgCoc'} readOnly={true} />
                    </div>
                </div>
                <div className="row model-field-wrapper">
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">RoE (YEAR 1)</div>
                    </div>
                    <div className="col-4 p-0 ">
                        <NumberField name={`computed.roe[0]`} numberFormat={'percent'} readOnly={true} />
                    </div>
                </div>
                <div className="row model-field-wrapper">
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">Avg RoE</div>
                    </div>
                    <div className="col-4 p-0 ">
                        <NumberField numberFormat={'percent'} name={'computed.avgRoe'} readOnly={true} />
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .model-field-wrapper {
                    margin-bottom: 20px;

                    .model-field-title {
                        font-weight: 500;
                        font-size: 15px;
                        color: $black;
                    }

                    .model-field-value {
                        font-weight: 500;
                        font-size: 15px;
                        color: $black;
                        border-bottom: 1px solid $light-gray;
                        text-align: right;
                        padding: 5px 0 10px;
                    }
                }
            `}</style>
        </>
    )
}
