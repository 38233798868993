import { useMemo } from 'react'
import numeral from 'numeral'
import { ReduxHelper } from 'store'
import { InsightsTable } from '../../../../../operating-assumptions/submarket-insights/InsightsTable'

export function Submarket({ submarketData, showSubmarket }) {
    const handleSubmarketClick = () => {
        ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'showSubmarketInfo'], !showSubmarket)
    }

    const tableData = useMemo(() => {
        return {
            header: [
                {
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'MEAN',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'SIMILARLY AGED',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: 'MEDIAN',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
            body: [
                {
                    title: '',
                    cells: [
                        {
                            content: 'Vacancy Rate',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub-Market',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!submarketData?.vacancy?.submarket?.mean
                                ? numeral(submarketData?.vacancy?.submarket?.mean / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.vacancy?.submarket?.similarlyAged
                                ? numeral(submarketData?.vacancy?.submarket?.similarlyAged / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.vacancy?.submarket?.median
                                ? numeral(submarketData?.vacancy?.submarket?.median / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Metro',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!submarketData?.vacancy?.market?.mean
                                ? numeral(submarketData?.vacancy?.market?.mean / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.vacancy?.market?.similarlyAged
                                ? numeral(submarketData?.vacancy?.market?.similarlyAged / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.vacancy?.market?.median
                                ? numeral(submarketData?.vacancy?.market?.median / 100).format('0.[00]%')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: 'Concessions',
                            bordering: false,
                            align: 'left',
                            bold: true,
                            width: 200,
                        },
                        {
                            content: 'Sub-Market',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!submarketData?.concessions?.submarket?.mean
                                ? numeral(submarketData?.concessions?.submarket?.mean).format('0.00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.concessions?.submarket?.similarlyAged
                                ? numeral(submarketData?.concessions?.submarket?.similarlyAged).format('0.00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.concessions?.submarket?.median
                                ? numeral(submarketData?.concessions?.submarket?.median).format('0.00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },

                {
                    title: '',
                    cells: [
                        {
                            content: '',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: 'Metro',
                            bordering: false,
                            align: 'left',
                        },
                        {
                            content: !!submarketData?.concessions?.market?.mean
                                ? numeral(submarketData?.concessions?.market?.mean).format('0.00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.concessions?.market?.similarlyAged
                                ? numeral(submarketData?.concessions?.market?.similarlyAged).format('0.00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                        {
                            content: !!submarketData?.concessions?.market?.median
                                ? numeral(submarketData?.concessions?.market?.median).format('0.00')
                                : 'n/a',
                            bordering: false,
                            align: 'right',
                        },
                    ],
                },
            ],
        }
    }, [submarketData])

    return (
        <div className="submarket-wrapper" style={{ borderLeft: showSubmarket ? '1px solid #d9e0e5' : 'initial' }}>
            <div className="top">
                <span className="submarket-title">
                    {showSubmarket && submarketData?.submarketName && `Submarket: ${submarketData?.submarketName}`}
                </span>

                <span className="show-submarket-btn" onClick={handleSubmarketClick}>
                    {showSubmarket ? 'Hide ' : 'Show '}Submarket
                </span>
            </div>
            {showSubmarket && (
                <div className="main-section">
                    <InsightsTable tableHeader={tableData.header} tableBody={tableData.body} />
                </div>
            )}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .submarket-wrapper {
                        padding-left: 20px;
                        padding-right: 20px;

                        .top {
                            display: flex;
                            justify-content: space-between;

                            .submarket-title {
                                font-weight: 500;
                            }

                            .show-submarket-btn {
                                color: #4486ff;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.75;
                                }
                            }
                        }
                        .main-section {
                            margin-top: 20px;
                        }
                    }
                `}
            </style>
        </div>
    )
}
