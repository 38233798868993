import React from 'react'
import { CapRateGauge } from '../widgets/CapRateGauge'
import { FreeCashFlowBeforeDebtGauge } from '../widgets/FreeCashFlowBeforeDebtGauge'
import { FreeCashFlowAfterDebtGauge } from '../widgets/FreeCashFlowAfterDebtGauge'
import { DebtYieldGauge } from '../widgets/DebtYieldGauge'
type Props = {
    projectId: number
}
export const LenderGauges = (props: Props) => {
    const { projectId } = props
    return (
        <>
            <div className="col-md-3 mb-4">
                <CapRateGauge projectId={projectId} />
            </div>
            <div className="col-md-3 mb-4">
                <FreeCashFlowBeforeDebtGauge projectId={projectId} />
            </div>
            <div className="col-md-3 mb-4">
                <FreeCashFlowAfterDebtGauge projectId={projectId} />
            </div>
            <div className="col-md-3 mb-4">
                <DebtYieldGauge projectId={projectId} />
            </div>
        </>
    )
}
