import React, { useMemo, useCallback, useEffect, FunctionComponent, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Finance } from 'financejs'
import { Field, Form, useForm, useFormState } from 'react-final-form'
import SelectBox from 'devextreme-react/select-box'
import { regenerateCashFlow } from 'origination-model'
import numeral from 'numeral'
import { NumberField } from '../../../../components/NumberField'
import { Spinner } from '../../../../../../controls/Spinner'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../store'
import { WhiteReactSelectAdapter } from '../../../../../../controls/inputs/pricing/whiteReactSelectAdapter'
import { DateField } from '../../../../../../controls/inputs/generalinputs/DateField'
import {
    InterestBasisList,
    getFormattedDate,
    existingTotalDefaults,
    getQuarterOfYear,
    getActualDSCRQuarterOptions,
    getActualDSCRYearOptions,
} from './lib'
import { getLoanMaturityBalance, calcDebtService } from 'origination-model'
import { getPriorLoansDecorator } from './calculations'
import { FormContext } from '../../../../Dashboard'
import { nmGetMaximumBuiltUpCost, nmGetTotalCostBasis } from '../../northMarkFormulas'
import { isAnyAlert } from '../../main/lib'
const cloneDeep = require('lodash.clonedeep')

const mockSupplement = {
    name: 'Supplement',
    priorLien: {
        actualDSCR: null,
        actualDSCRDate: null,
        amortizationYears: 30,
        anualAmortization: 544988.16,
        closed: null,
        estimatedUpbAtSupClosing: 6540000,
        fnmaId: null,
        intrestBasis: 'Actual/360',
        intrestRate: 0.03336999999999999,
        ioYears: 6.75,
        maturityDate: '2026-06-01T00:00:00.000Z',
        minDSCR: 1.35,
        nmLoanId: '531089',
        testApply: false,
        upbAtmaturity: 6540000,
        upbOriginalLoanAmount: 6540000,
    },
    terms: {
        actualDSCR: null,
        firstPayment: '2019-09-01T00:00:00.000Z',
        maturityDate: '2026-06-01T00:00:00.000Z',
        termMonths: 81,
        termYears: 6.75,
        ymMonths: 75,
        ymYears: 6.25,
    },
    valueChanges: {
        appraised: 96000000,
        appraisedCapRate: 0.04,
        underwritten: 96000000,
    },
}

type Props = any

export const PriorLoans = React.memo(function PriorLoans(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const onSubmit = useCallback(
        (values: any) => {
            if (!formModified.priorLoans && formVisited?.priorLoans) {
                setFormModified((prevState) => ({
                    ...prevState,
                    priorLoans: true,
                }))
            }

            const cloned = cloneDeep(values.supplemental)

            const actualDSCRQuarter = cloned?.[0]?.priorLien?.actualDSCRQuarter 
            const actualDSCRYear = cloned?.[0]?.priorLien?.actualDSCRYear
            if (actualDSCRQuarter && actualDSCRYear) {
                cloned[0].priorLien.actualDSCRDate = `${actualDSCRQuarter} ${actualDSCRYear}`
                delete cloned[0].priorLien.actualDSCRQuarter
                delete cloned[0].priorLien.actualDSCRYear
            }

            ReduxHelper.setIn(['lender', 'originationModel', 'fanniePricing', 'supplemental'], cloned)
        },
        [model, formModified?.priorLoans, formVisited?.priorLoans],
    )

    const isModel = !!model ? true : false
    const initialValues = useMemo(() => {
        const cloned = cloneDeep(model?.fanniePricing)

        //for dev, use mokup
        //  if (cloned?.supplemental.length === 1) cloned.supplemental.push(mockSupplement)

        const existingTotal = {
            ...existingTotalDefaults,
        }

        return {
            ...cloned,
            existingTotal,
        }
    }, [isModel])

    const computedValues = useMemo(() => {
        return getPriorLoansDecorator()
    }, [])

    useEffect(() => {
        return () =>
            setFormVisited((prevState) => ({
                ...prevState,
                priorLoans: false,
            }))
    }, [])

    return (
        <>
            <div>
                {model ? (
                    <>
                        {model.fanniePricing?.supplemental?.length > 0 ? (
                            <Form onSubmit={onSubmit} initialValues={initialValues} decorators={[computedValues]}>
                                {({ visited }) => {
                                    if (visited) {
                                        if (Object.values(visited).includes(true)) {
                                            if (formVisited?.priorLoans === false) {
                                                setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    priorLoans: true,
                                                }))
                                            }
                                        }
                                    }
                                    return <PriorLoansForm formVisited={formVisited} setFormVisited={setFormVisited} />
                                }}
                            </Form>
                        ) : null}
                    </>
                ) : (
                    <Spinner />
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{``}</style>
        </>
    )
})

const PriorLoansForm: FunctionComponent<Props> = (props: Props) => {
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })

    const { formModified, setFormModified } = useContext(FormContext)

    const [autoSelectedPricingOptionId, setAutoSelectedPricingOptionId] = useState(null)

    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    const userSelectedOptionId = model?.fanniePricing?.priorLienSelectedOptionId

    const allSelectedPricingOptions = useMemo(() => {
        const pricingOptions = pricing?.fanniePricing?.pricingOptions
        if (!!pricingOptions && !!pricingOptions?.length) {
            const selectedOptions = pricingOptions
                .filter((item) => item.isSelected)
                .map((item) => ({ label: item.name, value: item.id }))

            if (!!selectedOptions.length) {
                setAutoSelectedPricingOptionId(selectedOptions[0].value)
                return selectedOptions
            } else {
                const savedOption = pricingOptions.find(
                    (option) =>
                        (userSelectedOptionId || userSelectedOptionId === 0) && option.id === userSelectedOptionId,
                )
                if (savedOption) {
                    setAutoSelectedPricingOptionId(savedOption.id)
                    return [
                        {
                            label: savedOption.name,
                            value: savedOption.id,
                        },
                    ]
                } else {
                    const firstOption = [
                        {
                            label: pricingOptions[0].name,
                            value: pricingOptions[0].id,
                        },
                    ]
                    setAutoSelectedPricingOptionId(pricingOptions[0].id)
                    return firstOption
                }
            }
        } else {
            setAutoSelectedPricingOptionId(null)
            return []
        }
    }, [pricing?.fanniePricing?.pricingOptions, userSelectedOptionId])

    const selectedOption = useMemo(() => {
        const pricingOptions = pricing?.fanniePricing?.pricingOptions
        const isUserSelectedOptionAvailable =
            (!!userSelectedOptionId || userSelectedOptionId === 0) &&
            !!allSelectedPricingOptions &&
            !!allSelectedPricingOptions?.length &&
            !!allSelectedPricingOptions.find((option) => option.value === userSelectedOptionId)

        if (isUserSelectedOptionAvailable) {
            return pricingOptions?.find((option) => option.id === userSelectedOptionId)
        } else {
            ReduxHelper.setIn(['lender', 'originationModel', 'fanniePricing', 'priorLienSelectedOptionId'], null)
            const isAutoSelectedOptionAvailable =
                (!!autoSelectedPricingOptionId || autoSelectedPricingOptionId === 0) &&
                !!allSelectedPricingOptions &&
                !!allSelectedPricingOptions?.length &&
                !!allSelectedPricingOptions.find((option) => option.value === autoSelectedPricingOptionId)

            if (isAutoSelectedOptionAvailable) {
                return pricingOptions?.find((option) => option.id === autoSelectedPricingOptionId)
            } else {
                return null
            }
        }
    }, [
        allSelectedPricingOptions,
        pricing?.fanniePricing?.pricingOptions,
        autoSelectedPricingOptionId,
        userSelectedOptionId,
    ])

    const handlePricingOptionsChange = (event) => {
        if (props.formVisited?.priorLoans === false) {
            props.setFormVisited((prevState) => ({
                ...prevState,
                priorLoans: true,
            }))
        }
        if (!formModified.priorLoans) {
            setFormModified((prevState) => ({
                ...prevState,
                priorLoans: true,
            }))
        }
        ReduxHelper.setIn(['lender', 'originationModel', 'fanniePricing', 'priorLienSelectedOptionId'], event.value)
    }

    useEffect(() => {
        if (form) form.submit()
    }, [formValues.supplemental])

    const hasSupplemental = useMemo(() => !!(formValues.supplemental.length > 1), [formValues.supplemental])

    const underwrittenNCF = useMemo(() => {
        return cashFlow?.noi?.adjustedT12
    }, [cashFlow?.noi?.adjustedT12])

    const capRate = useMemo(() => {
        if (model?.fanniePricing?.valuationComparison?.length > 0) {
            for (const v of model.fanniePricing.valuationComparison) {
                if (v.selected) return v.capRate
            }
            return 0.0525
        }
        return 0.0525
    }, [model?.fanniePricing?.valuationComparison])

    const existingTotalUpbLoanAmount = useMemo(() => {
        if (!hasSupplemental) return null
        return (
            formValues.supplemental?.[0]?.priorLien.upbOriginalLoanAmount +
            formValues.supplemental?.[1]?.priorLien.upbOriginalLoanAmount
        )
    }, [
        formValues.supplemental?.[0]?.priorLien.upbOriginalLoanAmount,
        formValues.supplemental?.[1]?.priorLien.upbOriginalLoanAmount,
    ])

    const existingTotalUpbAtSupClosing = useMemo(() => {
        if (!hasSupplemental) return null
        return (
            formValues.supplemental?.[0]?.priorLien.estimatedUpbAtSupClosing +
            formValues.supplemental?.[1]?.priorLien.estimatedUpbAtSupClosing
        )
    }, [
        formValues.supplemental?.[0]?.priorLien.estimatedUpbAtSupClosing,
        formValues.supplemental?.[1]?.priorLien.estimatedUpbAtSupClosing,
    ])

    const existingTotalUpbAtMaturity = useMemo(() => {
        if (!hasSupplemental) return null
        return (
            formValues.supplemental?.[0]?.priorLien.upbAtmaturity +
            formValues.supplemental?.[1]?.priorLien.upbAtmaturity
        )
    }, [formValues.supplemental?.[0]?.priorLien.upbAtmaturity, formValues.supplemental?.[1]?.priorLien.upbAtmaturity])

    const proposedTotalEstimatedUpb = useMemo(() => {
        if (hasSupplemental) {
            return (
                existingTotalUpbAtSupClosing +
                (selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                    generalInputs?.loanDetails?.requestedLoanAmount)
            )
        } else {
            return (
                formValues.supplemental?.[0]?.priorLien.estimatedUpbAtSupClosing +
                (selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                    generalInputs?.loanDetails?.requestedLoanAmount)
            )
        }
    }, [
        existingTotalUpbAtSupClosing,
        generalInputs?.loanDetails?.requestedLoanAmount,
        selectedOption?.sizer?.underwritten?.maxFirstPosLoan,
        formValues.supplemental?.[0]?.priorLien.estimatedUpbAtSupClosing,
    ])

    const proposedEstimatedFirstPayment = useMemo(() => {
        const date = new Date(generalInputs?.loanDetails?.targetLoanClosing)
        const newDate = new Date(date)
        newDate.setDate(1)
        newDate.setMonth(newDate.getMonth() + (date.getDate() === 1 ? 1 : 2))
        return newDate.toString()
    }, [generalInputs?.loanDetails?.targetLoanClosing])

    const calculatorMaturityDate = useMemo(() => {
        return formValues.supplemental?.[0]?.terms.maturityDate
        // const targetLoanClosing = new Date(generalInputs.loanDetails.targetLoanClosing)
        // const requestedLoanTerm = generalInputs.loanDetails.requestedLoanTerm || 10

        // return new Date(targetLoanClosing.setMonth(targetLoanClosing.getMonth() + requestedLoanTerm * 12)).toString()
    }, [
        generalInputs?.loanDetails?.targetLoanClosing,
        generalInputs?.loanDetails?.requestedLoanTerm,
        formValues.supplemental?.[0]?.terms.maturityDate,
    ])

    const loanTermInMonths = useMemo(() => {
        // const requestedLoanTerm = generalInputs.loanDetails.requestedLoanTerm || 10
        // return requestedLoanTerm * 12
        const date = new Date(proposedEstimatedFirstPayment)
        const timeDiff = new Date(calculatorMaturityDate).getTime() - date.getTime()
        const monthDiff = parseFloat((timeDiff / (1000 * 3600 * 24) / 30).toFixed(0))
        // console.log('monthDiff', monthDiff)
        // const clonedPricingOptions = cloneDeep(pricing.fanniePricing.pricingOptions)
        // clonedPricingOptions.forEach((option) => {
        //     console.log('option', option)
        //     option.scenario.underwritten.financeOptions.loanTerm  = monthDiff / 12
        //     option.scenario.actual.financeOptions.loanTerm  = monthDiff / 12
        // })
        // console.log(pricing)
        // ReduxHelper.setIn(['lender', 'pricing', 'fanniePricing', 'pricingOptions'], clonedPricingOptions)

        return monthDiff
    }, [calculatorMaturityDate, proposedEstimatedFirstPayment])

    const proposedTotalOriginalUpb = useMemo(() => {
        if (hasSupplemental) {
            return (
                existingTotalUpbLoanAmount +
                (selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                    generalInputs?.loanDetails?.requestedLoanAmount)
            )
        } else {
            return (
                formValues.supplemental?.[0]?.priorLien.upbOriginalLoanAmount +
                (selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                    generalInputs?.loanDetails?.requestedLoanAmount)
            )
        }
    }, [
        existingTotalUpbLoanAmount,
        generalInputs?.loanDetails?.requestedLoanAmount,
        selectedOption?.sizer?.underwritten?.maxFirstPosLoan,
        formValues.supplemental?.[0]?.priorLien.upbOriginalLoanAmount,
        hasSupplemental,
    ])

    const proposedUpbAtMaturity = useMemo(() => {
        if (selectedOption) {
            const maxSupplementalLoan = selectedOption.sizer?.actual?.maxFirstPosLoan
            const amortizationTermYears = selectedOption.scenario?.actual?.financeOptions?.amortization || 30
            const rate = selectedOption?.scenario?.actual?.loanPricing?.actualintrestRate
            const ioPeriodYears = selectedOption?.scenario?.actual?.financeOptions?.io || 0
            const unit = 'month'
            const periodYears = loanTermInMonths / 12 || 10
            /*
            console.log('maxSupplementalLoan', maxSupplementalLoan)
            console.log('rate', rate)
            console.log('periodYears', periodYears)
*/
            if (!rate) {
                return null
            } else {
                return getLoanMaturityBalance({
                    loanAmount: maxSupplementalLoan,
                    amortizationTermYears,
                    rate,
                    ioPeriodYears,
                    unit,
                    periodYears,
                })
            }
        }

        return null
    }, [loanTermInMonths, selectedOption])

    const proposedTotalUpbAtMaturity = useMemo(() => {
        if (hasSupplemental) {
            return existingTotalUpbAtMaturity + proposedUpbAtMaturity
        } else {
            return formValues.supplemental?.[0]?.priorLien.upbAtmaturity + proposedUpbAtMaturity
        }
    }, [existingTotalUpbAtMaturity, proposedUpbAtMaturity, formValues.supplemental?.[0]?.priorLien.upbAtmaturity])

    const proposedInterestRate = useMemo(() => {
        return selectedOption?.scenario?.actual?.loanPricing?.actualintrestRate || null
    }, [selectedOption])

    const proposedTotalInterestRate = useMemo(() => {
        let result =
            formValues.supplemental?.[0]?.priorLien.intrestRate *
                formValues.supplemental?.[0]?.priorLien.estimatedUpbAtSupClosing +
            selectedOption?.scenario?.actual?.loanPricing?.actualintrestRate *
                (selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                    generalInputs?.loanDetails?.requestedLoanAmount)

        if (hasSupplemental) {
            result +=
                formValues.supplemental?.[1]?.priorLien.intrestRate *
                formValues.supplemental?.[1]?.priorLien.estimatedUpbAtSupClosing
        }

        result /= proposedTotalEstimatedUpb

        return isNaN(result) ? null : result
    }, [
        formValues.supplemental?.[0]?.priorLien.intrestRate,
        formValues.supplemental?.[0]?.priorLien.estimatedUpbAtSupClosing,
        formValues.supplemental?.[1]?.priorLien.intrestRate,
        formValues.supplemental?.[1]?.priorLien.estimatedUpbAtSupClosing,
        selectedOption?.scenario?.actual?.loanPricing?.actualintrestRate,
        generalInputs?.loanDetails?.requestedLoanAmount,
        selectedOption?.sizer?.underwritten?.maxFirstPosLoan,
        proposedTotalEstimatedUpb,
        selectedOption,
    ])

    const existingTotalAnnualAmortization = useMemo(() => {
        if (!hasSupplemental) return null
        return (
            formValues.supplemental?.[0]?.priorLien.anualAmortization +
            formValues.supplemental?.[1]?.priorLien.anualAmortization
        )
    }, [
        formValues.supplemental?.[0]?.priorLien.anualAmortization,
        formValues.supplemental?.[1]?.priorLien.anualAmortization,
    ])

    const existingTotalActualDSCR = useMemo(() => {
        let result
        if (!hasSupplemental) {
            result = formValues.supplemental?.[0]?.priorLien.actualDSCR
        } else {
            result = underwrittenNCF / existingTotalActualDSCR
        }
        return isNaN(result) ? null : result
    }, [existingTotalAnnualAmortization, formValues.supplemental?.[0]?.priorLien.actualDSCR, underwrittenNCF])

    // const proposedAnnualAmortization = useMemo(() => {
    //     const loanAmount = generalInputs.loanDetails.requestedLoanAmount
    //     const amortizationTermYears = generalInputs.loanDetails.requestedLoanAmortization || 30
    //     const rate = pricing?.fanniePricing?.pricingOptions?.[0]?.scenario?.actual?.loanPricing?.actualintrestRate
    //     const ioPeriodYears = generalInputs.loanDetails.requestedLoanIO || 0
    //     const unit = 'year'
    //     const periodYears = generalInputs.loanDetails.requestedLoanTerm || 10

    //     if (!rate) {
    //         return null
    //     } else {
    //         return calcDebtService({
    //             loanAmount,
    //             amortizationTermYears,
    //             rate,
    //             ioPeriodYears,
    //             unit,
    //             periodYears,
    //         })
    //     }
    // }, [generalInputs.loanDetails, pricing?.fanniePricing])

    const proposedAnnualAmortization = useMemo(() => {
        if (selectedOption) {
            const actualLoanAmount = selectedOption.sizer?.actual?.loanAmount

            if (actualLoanAmount && formValues.supplemental?.[0]?.priorLien.anualAmortization) {
                const loanConstant = selectedOption.scenario?.actual?.loanPricing?.actualLoanConstant
                // console.log(selectedOption)
                // console.log('actualLoanAmount', actualLoanAmount)
                // console.log('loanConstant', loanConstant)
                // console.log('amo', formValues.supplemental?.[0]?.priorLien.anualAmortization )
                const result =
                    actualLoanAmount * loanConstant + formValues.supplemental?.[0]?.priorLien.anualAmortization || 0

                return result
            }
        }

        return 0
    }, [selectedOption, formValues.supplemental?.[0]?.priorLien.anualAmortization])

    const proposedAnnualAmortizationForMortgage = useMemo(() => {
        const amortization = selectedOption?.scenario?.actual?.financeOptions?.amortization || 30
        const actualLoanAmount = selectedOption?.sizer?.actual?.loanAmount
        const finance = new Finance()
        return finance.PMT(proposedInterestRate / 12, amortization * 12, actualLoanAmount * -1) * 12 || 0
    }, [proposedInterestRate, selectedOption])

    const proposedActualDSCR = useMemo(() => {
        const result = underwrittenNCF / proposedAnnualAmortization
        return isNaN(result) || !isFinite(result) ? null : result
    }, [underwrittenNCF, proposedAnnualAmortization])

    const proposedActualDSCRForMortgage = useMemo(() => {
        const result = underwrittenNCF / proposedAnnualAmortizationForMortgage
        return isNaN(result) || !isFinite(result) ? null : result
    }, [underwrittenNCF, proposedAnnualAmortizationForMortgage])

    const proposedTotalAnnualAmortization = useMemo(() => {
        if (!hasSupplemental) {
            return proposedAnnualAmortizationForMortgage + formValues.supplemental?.[0]?.priorLien.anualAmortization
        } else {
            return existingTotalAnnualAmortization + proposedAnnualAmortizationForMortgage
        }
    }, [
        proposedAnnualAmortization,
        formValues.supplemental?.[0]?.priorLien.anualAmortization,
        existingTotalAnnualAmortization,
    ])

    const proposedTotalActualDSCR = useMemo(() => {
        const result = underwrittenNCF / proposedTotalAnnualAmortization
        return isNaN(result) || !isFinite(result) ? null : result
    }, [proposedTotalAnnualAmortization, underwrittenNCF])

    const dealType = useMemo(() => {
        return generalInputs?.loanDetails?.acquisitionOrRefinance || 'acquisition'
    }, [generalInputs?.loanDetails?.acquisitionOrRefinance])

    const isAcquisition = useMemo(() => {
        return dealType.toLowerCase() == 'acquisition'
    }, [dealType])

    const totalCostBasis = useMemo(() => {
        return nmGetTotalCostBasis(
            model?.uses?.purchasePrice,
            generalInputs?.dealDetails?.escrowedImprovements,
            model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
        )
    }, [
        model?.uses?.purchasePrice,
        generalInputs?.dealDetails?.escrowedImprovements,
        model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
    ])

    const maximumBuiltUpCost = useMemo(() => {
        return nmGetMaximumBuiltUpCost(totalCostBasis)
    }, [totalCostBasis])

    // const actualDSCROptions = useMemo(() => {
    //     const startAnalysisDate = model?.exitAssumption?.startAnalysisDate

    //     if (startAnalysisDate) {
    //         const date = new Date(startAnalysisDate)
    //         const quarter = getQuarterOfYear(date)
    //         const year = date.getFullYear()
    //         if (!isNaN(quarter) && isFinite(quarter) && !isNaN(year) && isFinite(year)) {
    //             return getActualDSCROptions(quarter, year)
    //         } else {
    //             return []
    //         }
    //     } else {
    //         return []
    //     }
    // }, [model?.exitAssumption?.startAnalysisDate])

    const actualDSCRQuarterOptions = useMemo(() => {
        const startAnalysisDate = model?.exitAssumption?.startAnalysisDate

        if (startAnalysisDate) {
            const date = new Date(startAnalysisDate)
            const quarter = getQuarterOfYear(date)
            if (!isNaN(quarter) && isFinite(quarter)) {
                return getActualDSCRQuarterOptions(quarter)
            } else {
                return []
            }
        } else {
            return []
        }
    }, [model?.exitAssumption?.startAnalysisDate])

    const actualDSCRYearOptions = useMemo(() => {
        const startAnalysisDate = model?.exitAssumption?.startAnalysisDate

        if (startAnalysisDate) {
            const date = new Date(startAnalysisDate)
            const quarter = getQuarterOfYear(date)
            const year = date.getFullYear()
            if (!isNaN(quarter) && isFinite(quarter) && !isNaN(year) && isFinite(year)) {
                return getActualDSCRYearOptions(quarter, year)
            } else {
                return []
            }
        } else {
            return []
        }
    }, [model?.exitAssumption?.startAnalysisDate])

    useEffect(() => {
        const actualDSCRDate = formValues.supplemental?.[0]?.priorLien?.actualDSCRDate
        const actualDSCRYear = formValues.supplemental?.[0]?.priorLien?.actualDSCRYear
        const actualDSCRQuarter = formValues.supplemental?.[0]?.priorLien?.actualDSCRQuarter
        const regex = /^Q\d\s\d{4}$/
        if (
            actualDSCRDate &&
            typeof actualDSCRDate === 'string' &&
            actualDSCRDate.match(regex) &&
            !actualDSCRYear &&
            !actualDSCRQuarter
        ) {
            if (actualDSCRQuarterOptions && actualDSCRYearOptions) {
                if (
                    actualDSCRQuarterOptions.find((option) => option.value === actualDSCRDate.split(' ')[0]) &&
                    actualDSCRYearOptions.find((option) => option.value === actualDSCRDate.split(' ')[1])
                ) {
                    form.change('supplemental.0.priorLien.actualDSCRQuarter', actualDSCRDate.split(' ')[0])
                    form.change('supplemental.0.priorLien.actualDSCRYear', actualDSCRDate.split(' ')[1])
                }
            }
        }
    }, [
        formValues.supplemental?.[0]?.priorLien?.actualDSCRDate,
        actualDSCRQuarterOptions,
        actualDSCRYearOptions,
        formValues.supplemental?.[0]?.priorLien?.actualDSCRYear,
        formValues.supplemental?.[0]?.priorLien?.actualDSCRQuarter,
    ])

    return (
        <>
            <div className={'section-table priorLienMortgageTable'}>
                <div className={'mainTitle'}>Prior Lien Mortgage</div>
                <div
                    className={`selectWrapper ${
                        !!selectedOption ? (isAnyAlert(selectedOption) ? 'redBottomBorder' : 'blueBottomBorder') : ''
                    }`}
                >
                    <div className={'selectLabel'}>Loan Scenario</div>
                    <div className={'selectbox'}>
                        <SelectBox
                            value={selectedOption?.id}
                            valueExpr={'value'}
                            displayExpr={'label'}
                            placeholder={''}
                            dataSource={allSelectedPricingOptions}
                            onValueChanged={handlePricingOptionsChange}
                            name={'pricingOptionsSelect'}
                        />
                    </div>
                </div>
                <div className={'mainPart'}>
                    <table>
                        <thead>
                            <tr>
                                <td style={{ width: '323px' }}></td>
                                <td style={{ width: '193px' }}>Original (1st Lien)</td>
                                {hasSupplemental && (
                                    <td style={{ width: '193px' }} className={'left-border'}>
                                        1st Supp (2nd Lien)
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td style={{ width: '193px' }} className={'left-border'}>
                                        Existing Total
                                    </td>
                                )}
                                <td style={{ width: '193px' }} className={'left-border'}>
                                    Proposed
                                </td>
                                <td className={'blueBG align-center'} style={{ width: '193px' }}>
                                    Proposed Total
                                </td>
                            </tr>
                            <tr className={'underlineRow'}>
                                <td></td>
                                <td>
                                    {' '}
                                    <div />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <div />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <div />
                                    </td>
                                )}
                                <td className={'left-border'}>
                                    <div />
                                </td>
                                <td className={'blueBG align-center'}>
                                    <div style={{ borderColor: '#4486FF' }} />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>NorthMarq Loan #</td>
                                <td>
                                    <NumberField name={'supplemental.0.priorLien.nmLoanId'} numberFormat={'number'} />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.nmLoanId'}
                                            numberFormat={'number'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>NA</td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td>FNMA Loan #</td>
                                <td>
                                    <NumberField name={'supplemental.0.priorLien.fnmaId'} numberFormat={'number'} />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField name={'supplemental.1.priorLien.fnmaId'} numberFormat={'number'} />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>NA</td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td>UPB: Original Loan Amount</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.upbOriginalLoanAmount'}
                                        numberFormat={'currency'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.upbOriginalLoanAmount'}
                                            numberFormat={'currency'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        {numeral(existingTotalUpbLoanAmount).format('$0,0')}
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {numeral(
                                        selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                                            generalInputs?.loanDetails?.requestedLoanAmount,
                                    ).format('$0,0')}
                                </td>
                                <td className={'blueBG align-center'}>
                                    {numeral(proposedTotalOriginalUpb).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Estimated UPB at Supplemental Closing</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.estimatedUpbAtSupClosing'}
                                        numberFormat={'currency'}
                                        readOnly={true}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.estimatedUpbAtSupClosing'}
                                            numberFormat={'currency'}
                                            readOnly={true}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        {numeral(existingTotalUpbAtSupClosing).format('$0,0')}
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {numeral(
                                        selectedOption?.sizer?.underwritten?.maxFirstPosLoan ||
                                            generalInputs?.loanDetails?.requestedLoanAmount,
                                    ).format('$0,0')}
                                </td>
                                <td className={'blueBG align-center'}>
                                    {numeral(proposedTotalEstimatedUpb).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td>UPB at Maturity</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.upbAtmaturity'}
                                        numberFormat={'currency'}
                                        readOnly={true}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.upbAtmaturity'}
                                            numberFormat={'currency'}
                                            readOnly={true}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        {numeral(existingTotalUpbAtMaturity).format('$0,0')}
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {numeral(proposedUpbAtMaturity).format('$0,0')}
                                </td>
                                <td className={'blueBG align-center'}>
                                    {numeral(proposedTotalUpbAtMaturity).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Interest Rate</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.intrestRate'}
                                        numberFormat={'percent'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.intrestRate'}
                                            numberFormat={'percent'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        <NumberField name={'existingTotal.intrestRate'} numberFormat={'percent'} />
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {numeral(proposedInterestRate).format('0.[00]%')}
                                </td>
                                <td className={'blueBG align-center'}>
                                    {numeral(proposedTotalInterestRate).format('0.[00]%')}
                                </td>
                            </tr>
                            <tr>
                                <td>Interest Basis</td>
                                <td>
                                    <Field
                                        component={WhiteReactSelectAdapter}
                                        name={'supplemental.0.priorLien.intrestBasis'}
                                        defaultValue={InterestBasisList[0].value}
                                        options={InterestBasisList}
                                        isDisabled={false}
                                        onOpenedCb={() => {
                                            if (props.formVisited?.priorLoans === false) {
                                                props.setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    priorLoans: true,
                                                }))
                                            }
                                        }}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <Field
                                            component={WhiteReactSelectAdapter}
                                            name={'supplemental.1.priorLien.intrestBasis'}
                                            defaultValue={InterestBasisList[0].value}
                                            options={InterestBasisList}
                                            isDisabled={false}
                                            onOpenedCb={() => {
                                                if (props.formVisited?.priorLoans === false) {
                                                    props.setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        priorLoans: true,
                                                    }))
                                                }
                                            }}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>
                                    {selectedOption?.scenario?.actual?.financeOptions?.intrestBasis}
                                </td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td>Closed</td>
                                <td style={{ position: 'relative' }}>
                                    <DateField
                                        name={'supplemental.0.priorLien.closed'}
                                        style={{ position: 'absolute', top: 0 }}
                                        type="date"
                                        displayFormat={'MM/dd/yyyy'}
                                        onOpenedCb={() => {
                                            if (props.formVisited?.priorLoans === false) {
                                                props.setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    priorLoans: true,
                                                }))
                                            }
                                        }}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td style={{ position: 'relative' }} className={'left-border'}>
                                        <DateField
                                            name={'supplemental.1.priorLien.closed'}
                                            style={{ position: 'absolute', top: 0 }}
                                            type="date"
                                            displayFormat={'MM/dd/yyyy'}
                                            onOpenedCb={() => {
                                                if (props.formVisited?.priorLoans === false) {
                                                    props.setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        priorLoans: true,
                                                    }))
                                                }
                                            }}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>
                                    {getFormattedDate(generalInputs?.loanDetails?.targetLoanClosing)}
                                </td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td>Maturity Date</td>
                                <td style={{ position: 'relative' }}>
                                    <DateField
                                        name={'supplemental.0.priorLien.maturityDate'}
                                        style={{ position: 'absolute', top: 0 }}
                                        type="date"
                                        displayFormat={'MM/dd/yyyy'}
                                        onOpenedCb={() => {
                                            if (props.formVisited?.priorLoans === false) {
                                                props.setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    priorLoans: true,
                                                }))
                                            }
                                        }}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td style={{ position: 'relative' }} className={'left-border'}>
                                        <DateField
                                            name={'supplemental.1.priorLien.maturityDate'}
                                            style={{ position: 'absolute', top: 0 }}
                                            type="date"
                                            displayFormat={'MM/dd/yyyy'}
                                            onOpenedCb={() => {
                                                if (props.formVisited?.priorLoans === false) {
                                                    props.setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        priorLoans: true,
                                                    }))
                                                }
                                            }}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        {getFormattedDate(formValues.supplemental?.[0]?.priorLien?.maturityDate)}
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {getFormattedDate(formValues.supplemental?.[0]?.priorLien?.maturityDate)}
                                </td>
                                <td className={'blueBG align-center'}>
                                    {getFormattedDate(formValues.supplemental?.[0]?.priorLien?.maturityDate)}
                                </td>
                            </tr>
                            <tr>
                                <td>Years of Amortization</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.amortizationYears'}
                                        numberFormat={'decimal'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.amortizationYears'}
                                            numberFormat={'decimal'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>
                                    {selectedOption?.scenario?.actual?.financeOptions?.amortization}
                                </td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td>Years of IO (from Origination)</td>
                                <td>
                                    <NumberField name={'supplemental.0.priorLien.ioYears'} numberFormat={'decimal'} />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.ioYears'}
                                            numberFormat={'decimal'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        <NumberField
                                            name={'supplemental.0.priorLien.ioYears'}
                                            numberFormat={'decimal'}
                                            readOnly={true}
                                        />
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {selectedOption?.scenario?.actual?.financeOptions?.io}
                                </td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td>Minimum DSCR for Loan Sizing</td>
                                <td>
                                    <NumberField name={'supplemental.0.priorLien.minDSCR'} numberFormat={'decimal'} />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.minDSCR'}
                                            numberFormat={'decimal'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>
                                    {selectedOption?.scenario?.actual?.financeOptions?.minDSCR}
                                </td>
                                <td className={'blueBG align-center'}>{proposedActualDSCR?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                    <span style={{ whiteSpace: 'nowrap'}}>Actual DSCR as{' '}</span>
                                    <Field
                                        component={WhiteReactSelectAdapter}
                                        name={'supplemental.0.priorLien.actualDSCRQuarter'}
                                        defaultValue={actualDSCRQuarterOptions?.[0]?.value}
                                        options={actualDSCRQuarterOptions}
                                        isDisabled={false}
                                        style={{ width: '120px' }}
                                        onOpenedCb={() => {
                                            props.setFormVisited((prevState) => ({
                                                ...prevState,
                                                priorLoans: true,
                                            }))
                                        }}
                                    />
                                    <Field
                                        component={WhiteReactSelectAdapter}
                                        name={'supplemental.0.priorLien.actualDSCRYear'}
                                        defaultValue={actualDSCRYearOptions?.[0]?.value}
                                        options={actualDSCRYearOptions}
                                        isDisabled={false}
                                        style={{ width: '150px' }}
                                        onOpenedCb={() => {
                                            props.setFormVisited((prevState) => ({
                                                ...prevState,
                                                priorLoans: true,
                                            }))
                                        }}
                                    />
                                </td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.actualDSCR'}
                                        numberFormat={'decimal'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.actualDSCR'}
                                            numberFormat={'decimal'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        {existingTotalActualDSCR?.toFixed(3) + 'x'}
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {proposedActualDSCRForMortgage
                                        ? proposedActualDSCRForMortgage?.toFixed(3) + 'x'
                                        : ''}
                                </td>
                                <td className={'blueBG align-center'}>{proposedTotalActualDSCR?.toFixed(3) + 'x'}</td>
                            </tr>
                            <tr>
                                <td>Annual Amortization</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.priorLien.anualAmortization'}
                                        numberFormat={'currency'}
                                        readOnly={true}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.priorLien.anualAmortization'}
                                            numberFormat={'currency'}
                                            readOnly={true}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && (
                                    <td className={'align-center left-border'}>
                                        {numeral(existingTotalAnnualAmortization).format('$0,0')}
                                    </td>
                                )}
                                <td className={'align-center left-border'}>
                                    {numeral(proposedAnnualAmortizationForMortgage).format('$0,0')}
                                </td>
                                <td className={'blueBG align-center'}>
                                    {numeral(proposedTotalAnnualAmortization).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Does New Loan Test Apply</td>
                                <td>{formValues.supplemental?.[0]?.priorLien?.testApply ? 'Yes' : 'No'}</td>
                                {hasSupplemental && <td className={'left-border'}>NA</td>}
                                {hasSupplemental && <td className={'align-center left-border'}>NA</td>}
                                <td className={'align-center left-border'}>NA</td>
                                <td className={'blueBG align-center'}>NA</td>
                            </tr>
                            <tr>
                                <td className={'whiteBG'}></td>
                                <td className={'whiteBG'}></td>
                                {hasSupplemental && <td className={'left-border whiteBG'}></td>}
                                {hasSupplemental && <td className={'whiteBG left-border'}></td>}
                                <td className={'whiteBG left-border'}></td>
                                <td className={`whiteBG ${!hasSupplemental && 'left-border'}`}></td>
                            </tr>
                            <tr>
                                <td className={'whiteBG secondaryTitle'}>Loan Term Calculator</td>
                                <td className={'whiteBG'}></td>
                                {hasSupplemental && <td className={'left-border whiteBG'}></td>}
                                {hasSupplemental && <td className={'whiteBG left-border'}></td>}
                                <td className={'whiteBG left-border'}></td>
                                <td className={`whiteBG ${!hasSupplemental && 'left-border'}`}></td>
                            </tr>
                            <tr>
                                <td style={{ width: '323px' }}>Estimated First Payment</td>
                                <td style={{ width: '193px' }}>
                                    {getFormattedDate(formValues.supplemental?.[0]?.terms?.firstPayment)}
                                </td>
                                {hasSupplemental && (
                                    <td style={{ width: '193px' }} className={'left-border'}>
                                        {getFormattedDate(formValues.supplemental?.[1]?.terms?.firstPayment)}
                                    </td>
                                )}
                                {hasSupplemental && <td style={{ width: '193px' }} className={'left-border'} />}
                                <td className={'align-center left-border'} style={{ width: '193px' }}>
                                    {getFormattedDate(proposedEstimatedFirstPayment)}
                                </td>
                            </tr>

                            <tr>
                                <td>Maturity Date</td>
                                <td>{getFormattedDate(formValues.supplemental?.[0]?.terms?.maturityDate)}</td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        {getFormattedDate(formValues.supplemental?.[1]?.terms?.maturityDate)}
                                    </td>
                                )}
                                {hasSupplemental && <td className={'left-border'} />}
                                <td className={'align-center left-border'}>
                                    {getFormattedDate(calculatorMaturityDate)}
                                </td>
                            </tr>

                            <tr>
                                <td>Term in months</td>
                                <td>{formValues.supplemental?.[0]?.terms?.termMonths.toFixed(2)}</td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        {formValues.supplemental?.[1]?.terms?.termMonths.toFixed(2)}
                                    </td>
                                )}
                                {hasSupplemental && <td className={'left-border'} />}
                                <td className={'align-center left-border'}>{loanTermInMonths.toFixed(2)}</td>
                            </tr>

                            <tr>
                                <td>Term in years</td>
                                <td>{formValues.supplemental?.[0]?.terms?.termYears.toFixed(2)}</td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        {formValues.supplemental?.[1]?.terms?.termYears.toFixed(2)}
                                    </td>
                                )}
                                {hasSupplemental && <td className={'left-border'} />}
                                <td className={'align-center left-border'}>
                                    {(loanTermInMonths / 12).toFixed(2) || 10}
                                </td>
                            </tr>

                            <tr>
                                <td>YM in months (change if non-std)</td>
                                <td>
                                    <NumberField name={'supplemental.0.terms.ymMonths'} numberFormat={'decimal'} />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField name={'supplemental.1.terms.ymMonths'} numberFormat={'decimal'} />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'left-border'} />}
                                <td className={'align-center left-border'}>{(loanTermInMonths - 6).toFixed(2)}</td>
                            </tr>

                            <tr>
                                <td>YM in years</td>
                                <td>{formValues.supplemental?.[0].terms.ymYears.toFixed(2)}</td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        {formValues.supplemental?.[1]?.terms?.ymYears.toFixed(2)}
                                    </td>
                                )}
                                {hasSupplemental && <td className={'left-border'} />}
                                <td className={'align-center left-border'}>
                                    {((loanTermInMonths - 6) / 12 || 9.5).toFixed(2)}
                                </td>
                            </tr>

                            <tr>
                                <td className={'blueBG'}>Mortgage Actual DSCR</td>
                                <td className={'blueBG'}>
                                    <NumberField name={'supplemental.0.terms.actualDSCR'} numberFormat={'number'} />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border blueBG'}>
                                        <NumberField name={'supplemental.1.terms.actualDSCR'} numberFormat={'number'} />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'blueBG left-border'} />}
                                <td className={'blueBG align-center left-border'}>{proposedActualDSCR?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td className={'whiteBG'}></td>
                                <td className={'whiteBG'}></td>
                                {hasSupplemental && <td className={'left-border whiteBG'}></td>}
                                {hasSupplemental && <td className={'whiteBG left-border'}></td>}
                                <td className={'whiteBG left-border'}></td>
                                <td className={`whiteBG ${!hasSupplemental && 'left-border'}`}></td>
                            </tr>
                            <tr>
                                <td className={'whiteBG secondaryTitle'}>Changes in Property Value</td>
                                <td className={'whiteBG'}></td>
                                {hasSupplemental && <td className={'left-border whiteBG'}></td>}
                                {hasSupplemental && <td className={'whiteBG left-border'}></td>}
                                <td className={'whiteBG left-border'}></td>
                                <td className={`whiteBG ${!hasSupplemental && 'left-border'}`}></td>
                            </tr>
                            <tr>
                                <td style={{ width: '323px' }}>Appraised Value</td>
                                <td style={{ width: '193px' }}>
                                    <NumberField
                                        name={'supplemental.0.valueChanges.appraised'}
                                        numberFormat={'currency'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td style={{ width: '193px' }} className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.valueChanges.appraised'}
                                            numberFormat={'currency'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td style={{ width: '193px' }} className={'left-border'} />}
                                <td className={'align-center left-border'} style={{ width: '193px' }}>
                                    NA
                                </td>
                            </tr>

                            <tr>
                                <td className={'blueBG'}>Underwritten Value</td>
                                <td className={'blueBG'}>
                                    <NumberField
                                        name={'supplemental.0.valueChanges.underwritten'}
                                        numberFormat={'currency'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'blueBG left-border'}>
                                        <NumberField
                                            name={'supplemental.1.valueChanges.underwritten'}
                                            numberFormat={'currency'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'blueBG left-border'} />}
                                <td className={'blueBG align-center left-border'}>
                                    {/* {numeral(capRate ? underwrittenNCF / capRate : 0).format('$0,0')} */}
                                    {numeral(
                                        isAcquisition ? maximumBuiltUpCost : capRate ? underwrittenNCF / capRate : 0,
                                    ).format('$0,0')}
                                </td>
                            </tr>

                            <tr>
                                <td>Appraised Cap Rate</td>
                                <td>
                                    <NumberField
                                        name={'supplemental.0.valueChanges.appraisedCapRate'}
                                        numberFormat={'percent'}
                                    />
                                </td>
                                {hasSupplemental && (
                                    <td className={'left-border'}>
                                        <NumberField
                                            name={'supplemental.1.valueChanges.appraisedCapRate'}
                                            numberFormat={'percent'}
                                        />
                                    </td>
                                )}
                                {hasSupplemental && <td className={'left-border'} />}
                                <td className={'align-center left-border'}>NA</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .blueBG {
                    background-color: rgba(68, 134, 255, 0.1);
                }
                .whiteBG {
                    background-color: #fff;
                }
                .underlineRow {
                    td {
                        padding-right: 10px;
                        padding-left: 10px;
                    }
                    div {
                        height: 100%;
                        border-top: 4px solid #d9e0e5;
                    }
                }
                .left-border {
                    border-left: 1px solid #eceff2;
                }
                .section-table {
                    background: white;
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-right: 20px;

                    :global(.input) {
                        text-align: left !important;
                    }
                }
                .priorLienMortgageTable {
                    width: 1330px;
                    border-bottom: 0px;
                }
                .loanTermTable,
                .changesInValueTable {
                    width: 1330px;
                    border-top: 0px;
                }
                .loanTermTable {
                    border-bottom: 0px;
                }
                .mainTitle {
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-weight: bold;
                    color: #161c26;
                    text-indent: 20px;
                    border-bottom: 1px solid #d9e0e5;
                }
                .secondaryTitle {
                    font-weight: bold;
                    font-size: 15px;
                }
                .mainPart {
                    padding: 20px;
                }
                .loanTermTable .mainPart {
                    padding-top: 0px;
                }
                .changesInValueTable .mainPart {
                    padding-top: 0px;
                }
                table {
                    margin-top: 20px;
                }
                thead {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 15px;
                    font-size: 15px;

                    td {
                        text-align: center;
                        height: 35px;
                    }
                }
                td {
                    text-align: left;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .align-center {
                    text-align: center;
                }
                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;

                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                }
                .selectWrapper {
                    width: 350px;
                    margin-left: 20px;
                    margin-top: 20px;
                    padding-bottom: 5px;

                    &.redBottomBorder {
                        border-bottom: 4px solid #f14b44;
                    }

                    &.blueBottomBorder {
                        border-bottom: 4px solid #4486ff;
                    }

                    .selectLabel {
                        color: #687893;
                        margin-bottom: 10px;
                        height: 25px;
                    }
                }
            `}</style>
        </>
    )
}
