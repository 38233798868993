import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { AdjustablePage } from './main/Adjustable'
import { useRouter } from 'next/router'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { useNotificationSubscription } from '../../../../../utils/notification'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../store'
import { FormWrapper } from './main/FormWrapper'
import { MainPricingForm } from './main/Main'

type Props = any

enum Pages {
    fixed = 'Fixed',
    adjustable = 'Adjustable',
}

export function Pricing(props: Props) {
    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const isPricingLoaded = useSelector((state: ReduxStoreState) => state.lender.isPricingLoaded)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const loadData = useCallback(() => {
        // console.log('loadData')
        if (projectId) {
            SagaHelper.run(['lenderPricing', 'getData'], { projectId: projectId })
        }
    }, [projectId])

    const isSupplemental = useMemo(() => project?.type == 'miltifamily.value_add_supplemental', [project?.type])

    const [page, setPage] = useState<Pages>(Pages.fixed)

    useEffect(() => {
        if (!isPricingLoaded) loadData()
    }, [projectId, isPricingLoaded])
    //Reload data if project was updated in another window
    // useNotificationSubscription('PROJECT_CASH_FLOW_AND_MODEL_UPDATED', async (notification) => {
    //     if (notification.payload.projectId == projectId) {
    //         await SagaHelper.run(['lenderPricing', 'clearData'])
    //         loadData()
    //     }
    // })

    return (
        <>
            <div className="dealanalyticsWrapper">
                <div className={'historicals-tab'}>
                    <div
                        className={`historical-tab ${page === Pages.fixed ? 'active' : ''}`}
                        onClick={(e) => {
                            setPage(Pages.fixed)
                        }}
                    >
                        Fixed
                    </div>
                    {!isSupplemental && (
                        <div
                            className={`historical-tab ${page === Pages.adjustable ? 'active' : ''}`}
                            onClick={(e) => {
                                setPage(Pages.adjustable)
                            }}
                        >
                            Adjustable
                        </div>
                    )}
                </div>
                {page === Pages.fixed && (
                    <FormWrapper component={MainPricingForm} isArmPage={false} isNM={props.isNM} />
                )}
                {page === Pages.adjustable && <AdjustablePage isNM={props.isNM} />}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .dealanalyticsWrapper {
                }
                .historicals-tab {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 20px;
                    .historical-tab {
                        border-radius: 3px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 8px;
                        border: 1px solid #d9e0e5;
                        background: #d9e0e5;
                        color: $blue-gray;
                        cursor: pointer;
                        font-size: 15px;
                        line-height: 16px;
                        &.active {
                            background: #dae7ff;
                            border: 1px solid #4486ff;
                            color: #4486ff;
                        }
                        margin-right: 4px;
                        &:last-child {
                            /*margin-left: 4px;*/
                        }
                    }
                }
                :global(.pricingComponent) {
                    :global(.title) {
                        width: 360px;
                        height: 40px;
                        text-indent: 30px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        padding-top: 10px;
                    }
                    :global(.title:nth-child(even)) {
                        background-color: rgba(217, 224, 229, 0.15);
                    }
                    :global(.grouptitle) {
                        font-size: 13px;
                        background: white !important;
                        font-style: italic;
                        font-weight: 500;
                    }
                    :global(.blueBG) {
                        background-color: rgba(68, 134, 255, 0.1) !important;
                    }
                    :global(.bgWhite) {
                        background-color: white !important;
                    }
                    :global(.tableCol) {
                        display: flex;
                        width: 320px;
                        height: 40px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        padding-top: 10px;
                        border-right: 1px solid #eceff2;
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    :global(.tableCol:nth-child(even)) {
                        background-color: rgba(217, 224, 229, 0.15);
                    }

                    :global(.tableCol input) {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;

                        text-align: right;
                    }

                    :global(.shortCol) {
                        padding-right: 10px;
                        width: 160px;
                        text-align: right;
                        text-indent: 0px;
                    }

                    :global(.shortTitle) {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        margin-top: 10px;
                        text-align: center;
                        color: #4f5662;
                        border-bottom: 1px solid #dde4e9;
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                    :global(.collapsedCol) {
                        width: 60px;
                        border-right: 1px solid #eceff2;
                        border-left: 1px solid #eceff2;
                    }
                    :global(.selectedOptions) {
                        // border: 2px solid black;
                        background-color: rgba(68, 134, 255, 0.1) !important;
                    }
                    :global(.loanBold) {
                        // font-weight: 500 !important;
                        font-weight: bold !important;
                    }
                    :global(.pos1Loan) {
                        font-weight: 500 !important;
                        font-size: 22px !important;
                        line-height: 27px !important;
                    }
                    :global(.greenBG) {
                        background-color: rgba(68, 225, 131, 0.1) !important;
                    }
                    :global(.redBG) {
                        background-color: rgba(241, 75, 68, 0.15) !important;
                    }
                    :global(.indent20) {
                        text-indent: 50px !important;
                    }
                    :global(.sizerAndScenarioTablesMain) {
                        background-color: white;
                    }

                    :global(.creditFeesTabls) {
                        display: flex;
                    }
                    :global(.titlesColumns) {
                        display: flex;
                    }
                    :global(.sizerColumns) {
                        display: flex;
                    }
                    :global(.sizerCol) {
                        //padding-right: 5px;
                    }

                    :global(.scenarioMain) {
                        margin-top: 40px;
                    }
                    :global(.scenariosColumns) {
                        display: flex;
                    }
                    :global(.scenarioCol) {
                        // padding-right: 5px;
                    }
                    :global(.creditFeesColumns) {
                        display: flex;
                    }
                    :global(.creditFeesCol) {
                        //padding-right: 5px;
                    }

                    :global(.sectionsTitle) {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }

                    :global(.alighRight) {
                        display: block;
                        text-align: right;
                    }
                    :global(.addDiv) {
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    :global(.addBtn) {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        color: #ffffff;
                        width: 144px;
                        height: 36px;
                        background: #4486ff;
                        border-radius: 4px;
                        padding-top: 10px;
                        cursor: pointer;
                    }

                    :global(.selectOfferDiv) {
                        margin-bottom: 5px;
                    }
                    :global(.selectOfferBtn) {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        color: #4486ff;
                        border: 1px solid #4486ff;
                        border-radius: 3px;
                        height: 30px;
                        padding-top: 5px;
                        cursor: pointer;
                    }

                    :global(.selectOfferRaw) {
                        margin-bottom: 5px;
                        background-color: white !important;
                    }
                    :global(.sepratorLine) {
                        height: 50px !important;
                    }
                }
            `}</style>
        </>
    )
}
