import React, { FunctionComponent, useCallback, useEffect, useState, useMemo, useContext } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'
import { NumberField } from '../../../../../components/NumberField'
import { FormContext } from '../../../../../Dashboard'
import { RATE } from 'origination-model'
const cloneDeep = require('lodash.clonedeep')

type Props = any

export const RateAssumptions: FunctionComponent<Props> = React.memo((props) => {
    const rateAssumptions = useSelector(
        (state: ReduxStoreState) =>
            state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions[props.page].rateAssumptions,
    )
    const [initialValues, setInitialValues] = useState<any>()

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const onSubmit = useCallback(
        (values: any) => {
            if (!formModified.rateAssumptions && formVisited?.rateAssumptions) {
                setFormModified((prevState) => ({
                    ...prevState,
                    rateAssumptions: true,
                }))
            }
            ReduxHelper.setIn(
                // @ts-ignore
                [
                    'lender',
                    'originationModel',
                    'fannieExitScenarioRefinance',
                    'refinanceAssumptions',
                    `${props.page}`,
                    'rateAssumptions',
                ],
                cloneDeep(values),
            )
        },
        [formModified?.rateAssumptions, formVisited?.rateAssumptions],
    )

    useEffect(() => {
        if (!!rateAssumptions && !initialValues) {
            setInitialValues({
                benchmarkExitRefiRate: rateAssumptions.benchmarkExitRefiRate || 0.0725,
                calculatedExitRefiRate: null,
                calculatedTerminalCapRate: null,
                ...(props.isSupplemental && { originalRefiRate: rateAssumptions.originalRefiRate || null }),
            })
        }
    }, [rateAssumptions, initialValues])

    useEffect(() => {
        return () =>
            setFormVisited((prevState) => ({
                ...prevState,
                rateAssumptions: false,
            }))
    }, [])

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ visited }) => {
                if (visited) {
                    if (Object.values(visited).includes(true)) {
                        if (formVisited?.rateAssumptions === false) {
                            setFormVisited((prevState) => ({
                                ...prevState,
                                rateAssumptions: true,
                            }))
                        }
                    }
                }
                return <RateAssumptionsForm isSupplemental={props.isSupplemental} page={props.page} />
            }}
        </Form>
    )
})

const RateAssumptionsForm: FunctionComponent<Props> = (props: Props) => {
    const refinanceAssumptions = useSelector(
        (state: ReduxStoreState) =>
            state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions[props.page],
    )

    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    useEffect(() => {
        if (form) form.submit()
    }, [formValues])

    const loanTerm = useMemo(
        () => refinanceAssumptions?.loanDetails?.termInYears,
        [refinanceAssumptions?.loanDetails?.termInYears],
    )

    useEffect(() => {
        if (!props.isSupplemental) {
            if (
                !!refinanceAssumptions?.assumptions?.amortization &&
                !!refinanceAssumptions?.assumptions?.dscr &&
                !!refinanceAssumptions?.loanDetails?.maturityNcf &&
                !!refinanceAssumptions?.loanDetails?.maturityBalance
            ) {
                const numberOfPeriods = refinanceAssumptions.assumptions.amortization * 12
                const paymentPerPeriod =
                    (refinanceAssumptions.loanDetails.maturityNcf / refinanceAssumptions.assumptions.dscr / 12) * -1
                let rate
                const guesses = [0.01, 0.03, 0.05]
                for (let guess of guesses) {
                    const result = RATE(
                        numberOfPeriods,
                        paymentPerPeriod,
                        refinanceAssumptions.loanDetails.maturityBalance,
                        0,
                        0,
                        guess,
                    )
                    if (!(result instanceof Error)) {
                        rate = result
                        break
                    }
                }

                if (!!rate) {
                    form.change('calculatedExitRefiRate', rate * 12)
                } else {
                    form.change('calculatedExitRefiRate', null)
                }
            }
        }
    }, [
        refinanceAssumptions?.assumptions?.amortization,
        refinanceAssumptions?.assumptions?.dscr,
        refinanceAssumptions?.loanDetails?.maturityNcf,
        refinanceAssumptions?.loanDetails?.maturityBalance,
        props.isSupplemental,
    ])

    useEffect(() => {
        if (props.isSupplemental) {
            if (
                !!refinanceAssumptions?.assumptions?.amortization &&
                !!refinanceAssumptions?.assumptions?.dscr &&
                !!refinanceAssumptions?.loanDetails?.maturityNcf &&
                !!loanTerm &&
                !!refinanceAssumptions?.balanceCombined?.years?.length
            ) {
                const numberOfPeriods = refinanceAssumptions.assumptions.amortization * 12
                const paymentPerPeriod =
                    (refinanceAssumptions.loanDetails.maturityNcf / refinanceAssumptions.assumptions.dscr / 12) * -1
                const balanceCombined = refinanceAssumptions.balanceCombined.years?.[+loanTerm.toFixed(0) - 1]
                let rate
                const guesses = [0.01, 0.03, 0.05]
                for (let guess of guesses) {
                    const result = RATE(numberOfPeriods, paymentPerPeriod, balanceCombined, 0, 0, guess)
                    if (!(result instanceof Error)) {
                        rate = result
                        break
                    }
                }

                if (!!rate && rate >= 0) {
                    form.change('calculatedExitRefiRate', rate * 12)
                } else {
                    form.change('calculatedExitRefiRate', null)
                }
            }
        }
    }, [
        refinanceAssumptions?.assumptions?.amortization,
        refinanceAssumptions?.assumptions?.dscr,
        refinanceAssumptions?.loanDetails?.maturityNcf,
        props.isSupplemental,
        refinanceAssumptions?.balanceCombined?.years,
        loanTerm,
    ])

    useEffect(() => {
        if (
            !!refinanceAssumptions?.loanDetails?.maturityNcf &&
            !!refinanceAssumptions?.loanDetails?.maturityBalance &&
            !!refinanceAssumptions?.assumptions?.ltv
        ) {
            const terminalCapRate =
                refinanceAssumptions.loanDetails.maturityNcf /
                (refinanceAssumptions.loanDetails.maturityBalance / refinanceAssumptions.assumptions.ltv)
            form.change('calculatedTerminalCapRate', terminalCapRate)
        }
    }, [
        refinanceAssumptions?.loanDetails?.maturityNcf,
        refinanceAssumptions?.loanDetails?.maturityBalance,
        refinanceAssumptions?.assumptions?.ltv,
    ])

    return (
        <>
            <div className={'tableWrapper'}>
                <div className={'title'}>Refi Rate Assumptions</div>
                <div className={'mainPart'}>
                    <div className="table">
                        <div className="table-body">
                            {props.isSupplemental && (
                                <div className="table-row">
                                    <div className="table-cell row-label">Original UW Refi Rate</div>
                                    <div className="table-cell row-value">
                                        <NumberField
                                            name={`originalRefiRate`}
                                            numberFormat={'percent'}
                                            cssClassName={'rateAssumptionNumField'}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="table-row">
                                <div className="table-cell row-label">Benchmark Exit Refi Rate</div>
                                <div className="table-cell row-value">
                                    <NumberField
                                        name={`benchmarkExitRefiRate`}
                                        numberFormat={'percent'}
                                        cssClassName={'rateAssumptionNumField'}
                                    />
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell row-label">Calculated Exit Refi Rate</div>
                                <div className="table-cell row-value">
                                    {!!formValues.calculatedExitRefiRate
                                        ? numeral(formValues.calculatedExitRefiRate).format('0.[00]%')
                                        : 'N/A'}
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="table-cell row-label">Calculated Terminal Cap Rate</div>
                                <div className="table-cell row-value">
                                    {!!formValues.calculatedTerminalCapRate &&
                                        numeral(formValues.calculatedTerminalCapRate).format('0.[00]%')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .tableWrapper {
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    flex: 1;
                    box-sizing: content-box;
                    margin-bottom: 30px;
                    background: white;
                }

                .title {
                    font-family: 'Inter';
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #d9e0e5;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                .table {
                    margin-top: 20px;
                    width: 100%;
                }
                .table-body {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    color: #262b35;

                    .table-row {
                        height: 35px;
                        display: flex;
                        align-items: center;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }

                        .table-cell {
                            text-align: left;
                        }

                        .row-label {
                            flex: 4;
                            padding-left: 20px;
                        }

                        .row-value {
                            flex: 1;
                        }
                    }
                }
                :global(.rateAssumptionNumField) {
                    text-align: left !important;
                }
            `}</style>
        </>
    )
}
