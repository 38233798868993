import React, { useCallback, useMemo, useState } from 'react'
import { LenderDashboardChart } from '../../LenderDashboardChart'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../store'
import { mortgagePaymentSchedule } from 'origination-model'
import { SelectField } from '../../../../components/SelectField'
import { Form } from 'react-final-form'
import { NumberField } from '../../../../components/NumberField'
import createDecorator from 'final-form-calculate'
import { myStorage } from '../../../../../../../store/storage'
import { useRouter } from 'next/router'

const cloneDeep = require('lodash.clonedeep')

export type MortgageSchedule = {
    endBalance: number
    schedule: Array<{ interest: number; principal: number; total: number }>
}

export const REFinancingTab = (props) => {
    const fieldName = 'refinance'
    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const hasMez = useMemo(() => {
        const sobj = myStorage.getItem('hasMez_' + projectId)
        if (sobj != null && sobj.val) {
            myStorage.setItem('hasMez_' + projectId, { val: true })
            return true
        }
        return false
    }, [])
    // console.log('hasMez', hasMez)

    let types = useMemo(() => {
        let out = []
        for (let i = 0; i < model?.cashFlow?.noi?.years?.length; i++) {
            if (i + 1 == model?.debtAssumptionSenior.ioPeriod) out.push({ label: 'End of IO Period', value: 'end_io' })
            else if (i + 1 == model?.cashFlow?.noi?.years?.length)
                out.push({ label: 'End of Holding Period', value: 'end_holding' })
            else out.push({ label: 'End of Year ' + (i + 1), value: '' + (i + 1) })
        }
        return out
    }, [model])

    const handleSubmit = useCallback(async (values) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { unitMix, computed, cashFlow, ...originationModel } = values
    }, [])

    const originalLoanPaymentIO = useMemo(() => {
        return mortgagePaymentSchedule({
            loanAmount: model?.sources.seniorDebt,
            amortizationTermYears: model?.debtAssumptionSenior.amortizationTerm,
            rate: model?.debtAssumptionSenior.rate,
            ioPeriodYears: model?.debtAssumptionSenior.ioPeriod,
            unit: 'year',
            periodYears: model?.debtAssumptionSenior.ioPerio,
        }).endBalance
    }, [model])

    const originalLoanPaymentHoldingPeriod = useMemo(() => {
        return mortgagePaymentSchedule({
            loanAmount: model?.sources.seniorDebt,
            amortizationTermYears: model?.debtAssumptionSenior.amortizationTerm,
            rate: model?.debtAssumptionSenior.rate,
            ioPeriodYears: model?.debtAssumptionSenior.ioPeriod,
            unit: 'year',
            periodYears: model.exitAssumption.saleYearAnalysisPeriod,
        }).endBalance
    }, [model])

    const originalLoanPaymentHoldingByYear = useMemo(() => {
        let out = []
        for (let i = 0; i < model?.cashFlow?.noi?.years?.length; i++) {
            out.push(
                mortgagePaymentSchedule({
                    loanAmount: model?.sources.seniorDebt,
                    amortizationTermYears: model?.debtAssumptionSenior.amortizationTerm,
                    rate: model?.debtAssumptionSenior.rate,
                    ioPeriodYears: model?.debtAssumptionSenior.ioPeriod,
                    unit: 'year',
                    periodYears: i + 1,
                }).endBalance,
            )
        }
        return out
    }, [model])

    const originalLoanPaymentIOMez = useMemo(() => {
        if (!hasMez) return 0
        if (!model?.sources?.mezzanine || model?.sources?.mezzanine == 0) return 0
        return mortgagePaymentSchedule({
            loanAmount: model?.sources.mezzanine,
            amortizationTermYears: model?.debtAssumptionMez.amortizationTerm,
            rate: model?.debtAssumptionMez.rate,
            ioPeriodYears: model?.debtAssumptionMez.ioPeriod,
            unit: 'year',
            periodYears: model?.debtAssumptionMez.ioPerio,
        }).endBalance
    }, [model])

    const originalLoanPaymentHoldingPeriodMez = useMemo(() => {
        if (!hasMez) return 0
        if (!model?.sources?.mezzanine || model?.sources?.mezzanine == 0) return 0
        return mortgagePaymentSchedule({
            loanAmount: model?.sources.mezzanine,
            amortizationTermYears: model?.debtAssumptionMez.amortizationTerm,
            rate: model?.debtAssumptionMez.rate,
            ioPeriodYears: model?.debtAssumptionMez.ioPeriod,
            unit: 'year',
            periodYears: model.exitAssumption.saleYearAnalysisPeriod,
        }).endBalance
    }, [model])

    const originalLoanPaymentHoldingByYearMez = useMemo(() => {
        let out = []
        if (!hasMez || !model?.sources?.mezzanine || model?.sources?.mezzanine == 0) {
            for (let i = 0; i < model?.cashFlow?.noi?.years?.length; i++) {
                out.push(0)
            }
        } else {
            const loanAmount = model?.sources.mezzanine
            const amortizationTermYears =
                model?.debtAssumptionMez.amortizationTerm || model?.debtAssumptionSenior.amortizationTerm
            const rate = model?.debtAssumptionMez.rate || model?.debtAssumptionSenior.rate
            const ioPeriodYears = model?.debtAssumptionMez.ioPeriodYears || model?.debtAssumptionSenior.ioPeriodYears
            for (let i = 0; i < model?.cashFlow?.noi?.years?.length; i++) {
                out.push(
                    mortgagePaymentSchedule({
                        loanAmount: loanAmount,
                        amortizationTermYears: amortizationTermYears,
                        rate: rate,
                        ioPeriodYears: ioPeriodYears,
                        unit: 'year',
                        periodYears: i + 1,
                    }).endBalance,
                )
            }
        }
        return out
    }, [model])

    const refinanceType = useMemo(() => {
        return model?.refinance?.refinanceType ? model?.refinance?.refinanceType : 'end_holding'
    }, [model])

    const exitCapRate = useMemo(() => {
        return model?.refinance?.exitCapRate ? model?.refinance?.exitCapRate : model?.exitAssumption.exitCapRate
    }, [model])

    const lenderFeePercent = useMemo(() => {
        return model?.refinance?.lenderFeePercent ? model?.refinance?.lenderFeePercent : model?.uses?.lenderFeePercent
    }, [model])

    const ltv = useMemo(() => {
        return model?.refinance?.ltv ? model?.refinance?.ltv : 0.65
    }, [model])

    const existingLoanPenaltyPercentage = useMemo(() => {
        return model?.refinance?.existingLoanPenaltyPercentage ? model?.refinance?.existingLoanPenaltyPercentage : 0.01
    }, [model])

    const newPropertyValue = useMemo(() => {
        return model?.refinance?.newPropertyValue
            ? model?.refinance?.newPropertyValue
            : model?.cashFlow?.noi.years[model?.cashFlow?.noi?.years?.length - 1] / model?.exitAssumption.exitCapRate
    }, [model])

    const noi = useMemo(() => {
        if (refinanceType == 'end_holding') return model?.cashFlow?.noi.years[model?.cashFlow?.noi?.years?.length - 1]
        else if (refinanceType == 'end_io') return model?.cashFlow?.noi.years[model?.debtAssumptionSenior.ioPeriod - 1]
        else return model?.cashFlow?.noi.years[parseInt(refinanceType) - 1]
    }, [model])

    const refinanceLoanAmount = useMemo(() => {
        return ltv * newPropertyValue
    }, [model])

    const refinanceLoanCost = useMemo(() => {
        return lenderFeePercent * refinanceLoanAmount
    }, [model])

    const loanRepayment = useMemo(() => {
        if (refinanceType == 'end_holding')
            return originalLoanPaymentHoldingPeriod + originalLoanPaymentHoldingPeriodMez
        if (refinanceType == 'end_io') return originalLoanPaymentIO + originalLoanPaymentIOMez
        else
            return (
                originalLoanPaymentHoldingByYear[parseInt(refinanceType) - 1] +
                originalLoanPaymentHoldingByYearMez[parseInt(refinanceType) - 1]
            )
    }, [model])

    const remainingCashflowtoBorrower = useMemo(() => {
        return refinanceLoanAmount - loanRepayment * (1 + existingLoanPenaltyPercentage) - refinanceLoanCost
    }, [model])

    const refinanceIntrestRate = useMemo(() => {
        return model?.refinance?.refinanceIntrestRate
            ? model?.refinance?.refinanceIntrestRate
            : model?.debtAssumptionSenior?.rate
    }, [model])

    const amortizationTerm = useMemo(() => {
        return model?.refinance?.amortizationTerm
            ? model?.refinance?.amortizationTerm
            : model?.debtAssumptionSenior?.amortizationTerm
    }, [model])

    const amortizationTermMonths = useMemo(() => {
        return amortizationTerm * 12
    }, [model])

    const loanTerm = useMemo(() => {
        return model?.refinance?.loanTerm ? model?.refinance?.loanTerm : model?.debtAssumptionSenior?.loanTerm
    }, [model])

    const loanTermMonths = useMemo(() => {
        return loanTerm * 12
    }, [model])

    const ioPeriod = 0 /*useMemo(() => {
        return model?.refinance?.ioPeriod ? model?.refinance?.ioPeriod : model?.debtAssumptionSenior?.ioPeriod
    }, [model])
    */

    const ioPeriodMonths = useMemo(() => {
        return ioPeriod * 12
    }, [model])

    const newAmortizationPayment = useMemo(() => {
        const payments = mortgagePaymentSchedule({
            loanAmount: refinanceLoanAmount,
            amortizationTermYears: amortizationTerm,
            rate: refinanceIntrestRate,
            ioPeriodYears: ioPeriod,
            unit: 'year',
            periodYears: amortizationTerm,
        })
        return payments.schedule[0].total
    }, [model])

    const newfcf = useMemo(() => {
        return noi - newAmortizationPayment
    }, [model])

    const dscr = useMemo(() => {
        return noi / newAmortizationPayment
    }, [model])

    const refinancedecorator = useMemo(() => {
        return [
            createDecorator(
                /*
                newPropertyValue
                refinanceLoanAmount
                lenderFeePercent
                refinanceLoanCost
                loanRepayment
                remainingCashflowtoBorrower

                 */
                {
                    //field: /^refinance.refinanceType/, // when a deeper field matching this pattern changes...
                    field: /^refinanceType/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        // console.log('refinanceType model', model)
                        //console.log('refinanceType allValues', allValues)
                        const out: any = {}
                        if (value == 'end_holding') {
                            out['refinance.noi'] = model?.cashFlow?.noi.years[model?.cashFlow?.noi?.years?.length - 1]
                            out['refinance.loanRepayment'] =
                                originalLoanPaymentHoldingPeriod + originalLoanPaymentHoldingPeriodMez
                        } else if (value == 'end_io') {
                            out['refinance.noi'] = model?.cashFlow?.noi.years[model?.debtAssumptionSenior.ioPeriod - 1]
                            out['refinance.loanRepayment'] = originalLoanPaymentIO + originalLoanPaymentIOMez
                        } else {
                            out['refinance.noi'] = model?.cashFlow?.noi.years[parseInt(value) - 1]
                            out['refinance.loanRepayment'] =
                                originalLoanPaymentHoldingByYear[parseInt(value) - 1] +
                                originalLoanPaymentHoldingByYearMez[parseInt(value) - 1]
                        }

                        out['refinance.newPropertyValue'] = out['refinance.noi'] / allValues.refinance.exitCapRate
                        out['refinance.refinanceLoanAmount'] =
                            allValues.refinance.ltv * out['refinance.newPropertyValue']

                        out['refinance.refinanceLoanCost'] =
                            out['refinance.refinanceLoanAmount'] * allValues.refinance.lenderFeePercent

                        out['refinance.remainingCashflowtoBorrower'] =
                            out['refinance.refinanceLoanAmount'] -
                            out['refinance.loanRepayment'] * (1 + allValues.refinance.existingLoanPenaltyPercentage) -
                            out['refinance.refinanceLoanCost']

                        const newAmortizationPayment = mortgagePaymentSchedule({
                            loanAmount: out['refinance.refinanceLoanAmount'],
                            amortizationTermYears: allValues.refinance.amortizationTerm,
                            rate: allValues.refinance.refinanceIntrestRate,
                            ioPeriodYears: allValues.refinance.ioPeriod,
                            unit: 'year',
                            periodYears: allValues.refinance.amortizationTerm,
                        }).schedule[0].total

                        out['refinance.newAmortizationPayment'] = newAmortizationPayment
                        out['refinance.newfcf'] = allValues.refinance.noi - newAmortizationPayment
                        out['refinance.dscr'] = allValues.refinance.noi / newAmortizationPayment

                        //console.log('refinanceType end')
                        return out
                    },
                },
                {
                    field: /^refinance.exitCapRate/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        //console.log("model",model)
                        const out: any = {}

                        out['refinance.newPropertyValue'] = allValues.refinance.noi / value
                        out['refinance.refinanceLoanAmount'] =
                            allValues.refinance.ltv * out['refinance.newPropertyValue']

                        out['refinance.refinanceLoanCost'] =
                            out['refinance.refinanceLoanAmount'] * allValues.refinance.lenderFeePercent

                        out['refinance.remainingCashflowtoBorrower'] =
                            out['refinance.refinanceLoanAmount'] -
                            allValues.refinance.loanRepayment *
                                (1 + allValues.refinance.existingLoanPenaltyPercentage) -
                            out['refinance.refinanceLoanCost']

                        const newAmortizationPayment = mortgagePaymentSchedule({
                            loanAmount: out['refinance.refinanceLoanAmount'],
                            amortizationTermYears: allValues.refinance.amortizationTerm,
                            rate: allValues.refinance.refinanceIntrestRate,
                            ioPeriodYears: allValues.refinance.ioPeriod,
                            unit: 'year',
                            periodYears: allValues.refinance.amortizationTerm,
                        }).schedule[0].total

                        out['refinance.newAmortizationPayment'] = newAmortizationPayment
                        out['refinance.newfcf'] = allValues.refinance.noi - newAmortizationPayment
                        out['refinance.dscr'] = allValues.refinance.noi / newAmortizationPayment

                        return out
                    },
                },
                {
                    field: /^refinance.ltv/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        const out: any = {}

                        out['refinance.refinanceLoanAmount'] = value * allValues.refinance.newPropertyValue
                        out['refinance.refinanceLoanCost'] =
                            out['refinance.refinanceLoanAmount'] * allValues.refinance.lenderFeePercent

                        out['refinance.remainingCashflowtoBorrower'] =
                            out['refinance.refinanceLoanAmount'] -
                            allValues.refinance.loanRepayment *
                                (1 + allValues.refinance.existingLoanPenaltyPercentage) -
                            out['refinance.refinanceLoanCost']

                        const newAmortizationPayment = mortgagePaymentSchedule({
                            loanAmount: out['refinance.refinanceLoanAmount'],
                            amortizationTermYears: allValues.refinance.amortizationTerm,
                            rate: allValues.refinance.refinanceIntrestRate,
                            ioPeriodYears: allValues.refinance.ioPeriod,
                            unit: 'year',
                            periodYears: allValues.refinance.amortizationTerm,
                        }).schedule[0].total

                        out['refinance.newAmortizationPayment'] = newAmortizationPayment
                        out['refinance.newfcf'] = allValues.refinance.noi - newAmortizationPayment
                        out['refinance.dscr'] = allValues.refinance.noi / newAmortizationPayment

                        return out
                    },
                },
                {
                    field: /^refinance.lenderFeePercent/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        const out: any = {}

                        out['refinance.refinanceLoanCost'] = value * allValues.refinance.refinanceLoanAmount

                        out['refinance.remainingCashflowtoBorrower'] =
                            allValues.refinance.refinanceLoanAmount -
                            allValues.refinance.loanRepayment *
                                (1 + allValues.refinance.existingLoanPenaltyPercentage) -
                            out['refinance.refinanceLoanCost']

                        return out
                    },
                },
                {
                    field: /^refinance.existingLoanPenaltyPercentage/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        const out: any = {}

                        out['refinance.remainingCashflowtoBorrower'] =
                            allValues.refinance.refinanceLoanAmount -
                            allValues.refinance.loanRepayment * (1 + value) -
                            allValues.refinance.refinanceLoanCost

                        return out
                    },
                },

                {
                    field: /^refinance.refinanceIntrestRate/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        const out: any = {}

                        const newAmortizationPayment = mortgagePaymentSchedule({
                            loanAmount: allValues.refinance.refinanceLoanAmount,
                            amortizationTermYears: allValues.refinance.amortizationTerm,
                            rate: value,
                            ioPeriodYears: allValues.refinance.ioPeriod,
                            unit: 'year',
                            periodYears: allValues.refinance.amortizationTerm,
                        }).schedule[0].total

                        out['refinance.newAmortizationPayment'] = newAmortizationPayment
                        out['refinance.newfcf'] = allValues.refinance.noi - newAmortizationPayment
                        out['refinance.dscr'] = allValues.refinance.noi / newAmortizationPayment
                        return out
                    },
                },

                {
                    field: /^refinance.amortizationTerm$/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        const out: any = {}

                        const newAmortizationPayment = mortgagePaymentSchedule({
                            loanAmount: allValues.refinance.refinanceLoanAmount,
                            amortizationTermYears: value,
                            rate: allValues.refinance.refinanceIntrestRate,
                            ioPeriodYears: allValues.refinance.ioPeriod,
                            unit: 'year',
                            periodYears: value,
                        }).schedule[0].total

                        out['refinance.amortizationTermMonths'] = value * 12

                        out['refinance.newAmortizationPayment'] = newAmortizationPayment
                        out['refinance.newfcf'] = allValues.refinance.noi - newAmortizationPayment
                        out['refinance.dscr'] = allValues.refinance.noi / newAmortizationPayment
                        return out
                    },
                },

                {
                    field: /^refinance.ioPeriod$/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        const out: any = {}

                        const newAmortizationPayment = mortgagePaymentSchedule({
                            loanAmount: allValues.refinance.refinanceLoanAmount,
                            amortizationTermYears: allValues.refinance.amortizationTerm,
                            rate: allValues.refinance.refinanceIntrestRate,
                            ioPeriodYears: value,
                            unit: 'year',
                            periodYears: allValues.refinance.amortizationTerm,
                        }).schedule[0].total

                        out['refinance.ioPeriodMonths'] = value * 12

                        out['refinance.newAmortizationPayment'] = newAmortizationPayment
                        out['refinance.newfcf'] = allValues.refinance.noi - newAmortizationPayment
                        out['refinance.dscr'] = allValues.refinance.noi / newAmortizationPayment
                        return out
                    },
                },
                {
                    field: /.*/, // when a deeper field matching this pattern changes...
                    updates: (value, name, allValues: any) => {
                        //  console.log('generic refinance ', name, value)
                        const out: any = {}

                        return out
                    },
                },
            ),
        ]
    }, [])

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                mutators={{}}
                decorators={refinancedecorator}
                initialValues={{}}
                validate={(v) => {
                    //console.log('v', v)
                    ReduxHelper.setIn(['lender', 'originationModel', 'refinance'], v.refinance)
                    return null
                }}
                render={({ handleSubmit, form, submitting, pristine, values, submitError }) => {
                    //console.log("values",values)

                    return (
                        <>
                            <div className="cash-flow-tab-wrapper">
                                <div className={'debt-assumption-table-wrapper left-half'}>
                                    <div className="debt-assumption-title">ReFinance Projection</div>

                                    <div className="custom-table-wrapper container-fluid">
                                        <div className="custom-table-title row">
                                            <div className="col text-right">
                                                <SelectField
                                                    //name={`${fieldName}.refinanceType`}
                                                    name={'refinanceType'}
                                                    values={types}
                                                    showClearButton={false}
                                                    placeholder={'type'}
                                                    initialValue={refinanceType}
                                                />
                                            </div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>
                                        <div className="custom-table-title row"> </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Cap Rate for Valuation</div>
                                            <div className="col text-right">
                                                <NumberField
                                                    name={`${fieldName}.exitCapRate`}
                                                    numberFormat={'percent'}
                                                    readOnly={false}
                                                    defaultValue={exitCapRate}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col text-right bold-500" />
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Assumed Property Value:</div>
                                            <div className="col text-right bold-500" />
                                            <div className="col text-right">
                                                <NumberField
                                                    name={`${fieldName}.newPropertyValue`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={newPropertyValue}
                                                />
                                            </div>
                                            <div className="col text-right">
                                                <span> (NOI: </span>
                                                <NumberField
                                                    name={`${fieldName}.noi`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={noi}
                                                />
                                                <span> ) </span>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">LTV</div>
                                            <div className="col text-right bold-500" />
                                            <div className="col">
                                                <NumberField
                                                    name={`${fieldName}.ltv`}
                                                    numberFormat={'percent'}
                                                    readOnly={false}
                                                    defaultValue={ltv}
                                                />
                                            </div>
                                            <div className="col text-right">
                                                <span> (</span>
                                                <NumberField
                                                    name={`${fieldName}.refinanceLoanAmount`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={refinanceLoanAmount}
                                                />
                                                <span> ) </span>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Closing Costs</div>
                                            <div className="col text-right bold-500" />
                                            <div className="col">
                                                <NumberField
                                                    name={`${fieldName}.lenderFeePercent`}
                                                    numberFormat={'percent'}
                                                    readOnly={false}
                                                    defaultValue={lenderFeePercent}
                                                />
                                            </div>
                                            <div className="col text-right">
                                                <span> (</span>
                                                <NumberField
                                                    name={`${fieldName}.refinanceLoanCost`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={refinanceLoanCost}
                                                />
                                                <span> ) </span>
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col text-right bold-500" />
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Total Existing Loans Balance</div>
                                            <div className="col text-right">
                                                <NumberField
                                                    name={`${fieldName}.loanRepayment`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={loanRepayment}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col text-right bold-500" />
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Existing Loans Penalty</div>
                                            <div className="col text-right">
                                                <NumberField
                                                    name={`${fieldName}.existingLoanPenaltyPercentage`}
                                                    numberFormat={'percent'}
                                                    defaultValue={existingLoanPenaltyPercentage}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col text-right bold-500" />
                                        </div>

                                        <div className="custom-table-row row"></div>

                                        <div className="custom-table-row row">
                                            <div className="col bold-500">Remaining Cashflow to Borrower</div>
                                            <div className="col text-right">
                                                <NumberField
                                                    name={`${fieldName}.remainingCashflowtoBorrower`}
                                                    numberFormat={'currency'}
                                                    readOnly={false}
                                                    defaultValue={remainingCashflowtoBorrower}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                        </div>
                                    </div>
                                </div>

                                <div className={'debt-assumption-table-wrapper right-half'}>
                                    <div className="debt-assumption-title">DSCR Test</div>
                                    <div className="custom-table-wrapper container-fluid">
                                        <div className="custom-table-row row"> </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Refinance Loan Interest Rate</div>
                                            <div className="col text-right">
                                                <NumberField
                                                    name={`${fieldName}.refinanceIntrestRate`}
                                                    numberFormat={'percent'}
                                                    readOnly={false}
                                                    defaultValue={refinanceIntrestRate}
                                                />
                                            </div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row"> </div>
                                        <div className="custom-table-title row"></div>

                                        <div className="custom-table-title row">
                                            <div className="col"></div>
                                            <div className="col custom-title text-right"># year</div>
                                            <div className="col custom-title text-right"># month</div>
                                            <div className="col"></div>
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Amortization Term</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.amortizationTerm`}
                                                    numberFormat={'years'}
                                                    readOnly={false}
                                                    defaultValue={amortizationTerm}
                                                />
                                            </div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.amortizationTermMonths`}
                                                    numberFormat={'months'}
                                                    readOnly={false}
                                                    defaultValue={amortizationTermMonths}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row" style={{ display: 'none' }}>
                                            <div className="col">Loan Term</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.loanTerm`}
                                                    numberFormat={'years'}
                                                    readOnly={false}
                                                    defaultValue={loanTerm}
                                                />
                                            </div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.loanTermMonths`}
                                                    numberFormat={'months'}
                                                    readOnly={false}
                                                    defaultValue={loanTermMonths}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row" style={{ display: 'none' }}>
                                            <div className="col">I/O Period</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.ioPeriod`}
                                                    numberFormat={'years'}
                                                    readOnly={false}
                                                    defaultValue={ioPeriod}
                                                />
                                            </div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.ioPeriodMonths`}
                                                    numberFormat={'months'}
                                                    readOnly={false}
                                                    defaultValue={ioPeriodMonths}
                                                />
                                            </div>
                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row"> </div>
                                        <div className="custom-table-row row"> </div>

                                        <div className="custom-table-row row">
                                            <div className="col">{model?.useNCFnotNOI ? 'Net CF' : 'NOI'}</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.noi`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={
                                                        model?.cashFlow?.noi.years[
                                                            model?.cashFlow?.noi.years.length - 1
                                                        ]
                                                    }
                                                />
                                            </div>

                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Amortization Payment</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.newAmortizationPayment`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={newAmortizationPayment}
                                                />
                                            </div>

                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row">
                                            <div className="col">Free CashFlow</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.newfcf`}
                                                    numberFormat={'currency'}
                                                    readOnly={true}
                                                    defaultValue={newfcf}
                                                />
                                            </div>

                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>

                                        <div className="custom-table-row row"> </div>

                                        <div className="custom-table-row row">
                                            <div className="col bold-500">DSCR</div>
                                            <div className="col text-right bold-500">
                                                <NumberField
                                                    name={`${fieldName}.dscr`}
                                                    numberFormat={'multiple'}
                                                    readOnly={true}
                                                    defaultValue={dscr}
                                                />
                                            </div>

                                            <div className="col text-right bold-500" />
                                            <div className="col"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }}
            />

            {/*language=scss*/}

            <style jsx>{`
                /* Pattern styles */

                .left-half {
                    float: left;
                    width: 50%;
                    min-height: 400px;
                }

                .right-half {
                    float: left;
                    width: 50%;
                    min-height: 400px;
                }
                @import './src/scss/colors.scss';
                .cash-flow-tab-wrapper {
                    .custom-table-wrapper {
                        .custom-table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            padding: 7px;
                        }
                        .custom-table-row {
                            font-size: 14px;
                            padding: 7px;
                            align-items: center;
                            &:nth-of-type(2n) {
                                background-color: $row-background-2;
                            }
                        }
                    }
                    .bold-500 {
                        font-weight: 900;
                    }
                    .debt-assumption-table-wrapper {
                        border: 1px solid $border;
                        background-color: $white;
                        margin-bottom: 20px;
                        .debt-assumption-title {
                            padding: 14px 22px;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                        }
                    }
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .widget-tabs {
                        display: flex;
                        background-color: $white;
                        padding: 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        border-top: 1px solid $border;
                        .widget-tab {
                            font-size: 15px;
                            margin-right: 32px;
                            font-weight: 500;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -3px;
                                    left: 0;
                                    border-top: 3px solid #4485ff;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                    }
                    .kpi-block {
                        display: flex;
                        background-color: $white;
                        padding: 20px 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        .kpi-item {
                            margin-right: 40px;
                            &__title {
                                font-weight: normal;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 5px;
                            }
                            &__value {
                                font-weight: 500;
                                font-size: 22px;
                                color: $black;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
