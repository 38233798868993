import React from 'react'
import { useFormState } from 'react-final-form'

type Props = {
    onClick?: () => void
    hideButton?: boolean
}

export function AddRow(props: Props) {
    const formState = useFormState()
    const tableLength = formState?.values?.capRate?.length + 2 || 2

    return (
        <>
            <tr className={'add-row'}>
                <td colSpan={tableLength}>
                    {!props.hideButton && <img src="/_img/lenderDashboard/add_row.svg" onClick={props.onClick} />}
                </td>
            </tr>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .add-row {
                        width: calc(100% - 30px);
                        min-width: 500px;
                        position: absolute;
                        height: 1px;
                        background-color: $accent;
                        td {
                            position: absolute;
                            width: 100%;
                        }
                        img {
                            position: absolute;
                            right: -60px;
                            top: -11.5px;
                            cursor: pointer;
                            z-index: 100;
                        }
                    }
                `}
            </style>
        </>
    )
}
