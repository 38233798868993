import React from 'react'
import { ApexLinearChart } from '../../../charts/ApexLinearChart'
import { PropertyHistoryChartData } from '../../../../generated/graphql'

type Props = {
    items: Array<PropertyHistoryChartData>
    chartTitle: string
    propertyChartName: string
    projectChartName: string
    titleColor?: string
    legendColor?: string
}

export function PropertyHistoryChart(props: Props) {
    const { items, chartTitle, propertyChartName, projectChartName } = props

    const data: Array<{ name: string; data: Array<number> }> = [
        {
            name: propertyChartName,
            data: [],
        },
        {
            name: projectChartName,
            data: [],
        },
    ]
    const labels: Array<string> = []

    for (const item of items) {
        labels.push(item?.year?.toString())
        data[0].data.push(item.projectValue)
        data[1].data.push(item.propertyClassValue)
    }

    return (
        <ApexLinearChart
            data={data}
            labels={labels}
            title={chartTitle}
            titleColor={props?.titleColor}
            legendColor={props?.legendColor}
        />
    )
}
