import { KPIBox } from './KPIBox'
import { getDateString } from './utils/dates'

export const StabilizationKPIs = ({ stabilizationItem }) => {
    return (
        <div className="stabilization-section-wrapper">
            <KPIBox title="Week" value={stabilizationItem ? getDateString(stabilizationItem.week) : '-'} />
            <KPIBox
                title="Projected Occupancy"
                value={
                    stabilizationItem?.projectedOccupancyOnTotalUnitsCo
                        ? `${(stabilizationItem.projectedOccupancyOnTotalUnitsCo * 100).toFixed(2)}%`
                        : '-'
                }
            />
            <KPIBox
                title="Stabilization DSCR"
                value={stabilizationItem?.DSCR ? `${stabilizationItem.DSCR.toFixed(2)}` : '-'}
            />
            <KPIBox
                title="Stabilization IO DSCR"
                value={stabilizationItem?.ioDSCR ? `${stabilizationItem.ioDSCR.toFixed(2)}` : '-'}
            />
            {/*language=scss*/}
            <style jsx>{`
                .stabilization-section-wrapper{
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
            `}</style>
        </div>
    )
}
