import React, { FunctionComponent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Form, useForm } from 'react-final-form'
import { IncomeTab } from './IncomeTab'
import { ExpensesTab } from './ExpensesTab'
import { NetOperatingExpensesTable } from './NetOperatingExpensesTable'
import { Spinner } from '../../../../controls/Spinner'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../store'
const cloneDeep = require('lodash.clonedeep')
import { computedValues } from './ModifyModelFormValuesDecorator'
type Props = {
    offset: number
    close: (e: SyntheticEvent) => void
}
export const ModifyModel: FunctionComponent<Props> = (props: Props) => {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const onSubmit = useCallback(
        (values: any) => {
            const out = {}
            for (const key in values) {
                if (key == 'computed') continue
                out[key] = values[key]
            }
            ReduxHelper.setIn(['lender', 'originationModel'], cloneDeep(out))
        },
        [model],
    )

    const initialValues = useMemo(() => {
        return {
            ...cloneDeep(model),
            computed: {
                avgCoc: 0,
                avgRoe: 0,
                avgCapRate: 0,
            },
        }
    }, [model])

    return (
        <>
            {model ? (
                <Form onSubmit={onSubmit} initialValues={initialValues} decorators={[computedValues]}>
                    {(params) => (
                        <form onSubmit={params.handleSubmit}>
                            <ModifyModelForm {...props} />
                        </form>
                    )}
                </Form>
            ) : (
                <Spinner />
            )}
        </>
    )
}
export const ModifyModelForm: FunctionComponent<Props> = (props: Props) => {
    const defaultModel = useSelector((state: ReduxStoreState) => state.lender.originationModelDefault)
    const settings = useSelector((store: ReduxStoreState) => store.lender.projectSettings)
    const [activeTab, setActiveTab] = useState('Operating Assumptions') // expenses
    const { offset } = props
    const tabs = ['Operating Assumptions', 'Underwriting Assumptions']
    const form = useForm()
    useEffect(() => {
        //    form.change('computed:')
    }, [])

    const close = useCallback(async (e) => {
        e.persist()
        //@ts-ignore
        //form.restart(recalcInitialValues(defaultModel))
        props.close(e)
    }, [])

    const save = useCallback(async (e) => {
        e.persist()
        e.preventDefault()
        await form.submit()
        props.close(e)

        ReduxHelper.setIn(['lender', 'isModelChanged'], true)
    }, [])
    const reset = useCallback(() => {
        form.reset()
        // @ts-ignore
        form.restart()
    }, [form, defaultModel])
    return (
        <div className={'modify-model-wrapper'} style={{ left: offset }}>
            <div className={'modify-model-container'}>
                <div className="close-button" onClick={close}>
                    X
                </div>
                <div className="title">Adjust Model Input</div>
                <div className={'tabs'}>
                    {tabs.map((tab) => (
                        <div
                            key={tab}
                            className={`tab ${activeTab == tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </div>
                    ))}
                </div>
                <div className={'tabs-container'}>
                    {activeTab == tabs[0] && <IncomeTab />}
                    {activeTab == tabs[1] && <ExpensesTab />}
                </div>
                <NetOperatingExpensesTable />
                <div className="footer">
                    <div className={'footer-text'} onClick={reset}>
                        Reset to initial values
                    </div>
                    <button className={'footer-button'} onClick={save} disabled={settings?.blockCFChanges || false}>
                        Apply
                    </button>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';
                .modify-model-wrapper {
                    position: fixed;
                    transition: 0.2s;
                    top: 0;
                    width: 500px;

                    max-width: 90%;
                    z-index: 1400;

                    .modify-model-container {
                        background-color: $white;
                        height: 100vh;
                        border-right: 1px solid $border;
                        display: flex;
                        flex-direction: column;
                        .tabs {
                            display: flex;
                            flex-direction: row;
                            margin-left: 40px;
                            margin-top: 29px;
                            .tab {
                                color: $black;
                                font-size: 15px;
                                line-height: 18px;
                                display: block;
                                cursor: pointer;
                                position: relative;
                                &:first-child {
                                    margin-right: 28px;
                                }
                            }
                            .tab.active {
                                font-weight: 600;
                                color: $blue;
                                overflow: visible;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -7px;
                                    left: 0;
                                    border-top: 3px solid $blue;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                        .tabs-container {
                            flex: 1;
                            overflow-y: auto;
                        }
                        .items-wrapper {
                            padding: 30px;
                            .dx-accordion-item-title {
                                padding: 9px 12px 9px 25px;
                                &:before {
                                    float: left;
                                    position: absolute;
                                    left: -10px;
                                    z-index: 100000;
                                    content: '' !important;
                                }
                            }
                        }
                        .dx-widget {
                            box-shadow: none;
                        }
                        .close-button {
                            color: $gray;
                            background-color: $white;
                            padding: 10px 7px 10px 5px;
                            position: absolute;
                            right: -22px;
                            top: 40px;
                            border-right: 1px solid $border;
                            border-top: 1px solid $border;
                            border-bottom: 1px solid $border;
                            border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;
                            cursor: pointer;
                        }
                        .title {
                            font-size: 20px;
                            font-weight: 500;
                            color: $gray;
                            padding: 35px 0 0 40px;
                        }
                        .item {
                            font-weight: 600;
                            font-size: 16px;
                            padding-left: 40px;
                        }
                        .footer {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-top: 1px solid $border;
                            padding: 30px 25px;
                            &-text {
                                font-size: 15px;
                                color: $blue-gray;
                                font-weight: 400;
                                cursor: pointer;
                            }
                            &-button {
                                background-color: $blue;
                                border: 1px solid $blue;
                                color: $white;
                                border-radius: 3px;
                                padding: 10px 45px;
                                &:disabled {
                                    opacity: 0.5;
                                    cursor: not-allowed;
                                }
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
/*
function recalcInitialValues(model: any) {
    if (!model) return {}

    const out: any = {}

    const totalFieldGroups = ['uses', 'sources']
    const numOfUnits = model?.numberOfUnits || 0
    for (const key of totalFieldGroups) {
        let total = 0
        out[key] = {}
        for (const row of model[key]) {
            const value = numeral(row.value).value()
            out[key][`${getKey(row.title)}`] = value
            out[key][`${getKey(row.title)}_per_unit`] = numOfUnits > 0 ? Math.round(value / numOfUnits) : 0
            total += value
        }
        out[key].total = total
        out[key].totalPerUnit = numOfUnits > 0 ? Math.round(total / numOfUnits) : 0
    }
    const defFieldGroups = ['exitAssumption', 'returnMetrics', 'dealLevelFees']
    for (const key of defFieldGroups) {
        out[key] = {}
        for (const row of model[key]) {
            out[key][`${getKey(row.title)}`] = row.value
        }
    }
    out.propertyTaxAssumption = model?.propertyTaxAssumption
    out.underwritingAssumption = {}
    for (const row of model?.underwritingAssumption) {
        out.underwritingAssumption[getKey(row.title)] = {
            year1: numeral(row.year1).value(),
            year2: numeral(row.year2).value(),
            year3: numeral(row.year3).value(),
            year4: numeral(row.year4).value(),
            year5: numeral(row.year5).value(),
            year6: numeral(row.year6).value(),
        }
    }
    return out
}
*/
