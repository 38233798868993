import React from 'react'
import ModifyIcon from './modify.svg'
type Props = {
    onClick: () => void
}

export function FineTuneButton(props: Props) {
    return (
        <button onClick={props.onClick}>
            Fine-tune deal
            <img height={'16px'} width={16} src={ModifyIcon} alt={''} />
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    button {
                        background: $accent;
                        color: white;
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 4px;
                        border-width: 0;
                        padding: 10px 12px;
                        font-weight: 500;
                        font-size: 13px;
                    }
                    img {
                        position: relative;
                        top: -3px;
                        margin-left: 3px;
                    }
                `}
            </style>
        </button>
    )
}
