import React, { useCallback, useEffect, useState } from 'react'
import numeral from 'numeral'
import { useSpring, animated } from 'react-spring'
import { ReduxStoreState } from '../../../../../../store'
import { Spinner } from '../../../../../controls/Spinner'
import { useSelector } from 'react-redux'
import { calcArrayAverage } from 'origination-model'

type Props = {
    projectId: number
}

const numberFormat = '$0,0'

export function DashboardProforma(props: Props) {
    // deal with loading indicator
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState<{ label: string; value: number }[]>([])
    const percents = []
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    useEffect(() => {
        if (!cashFlow || !project) return
        if (project?.accountType == 'borrower') {
            setData([
                { label: 'GPR', value: calcArrayAverage(cashFlow?.rentalIncome[0]?.years) },
                { label: 'Net Rent', value: calcArrayAverage(cashFlow?.effectiveGrossRent?.years) },
                { label: 'Total Expenses', value: calcArrayAverage(cashFlow?.totalOperatingExpenses?.years) },
                { label: 'NOI', value: calcArrayAverage(cashFlow?.noi?.years) },
            ])
        } else {
            setData([
                { label: 'GPR', value: cashFlow?.rentalIncome[0]?.adjustedT12 },
                { label: 'Net Rent', value: cashFlow?.effectiveGrossRent?.adjustedT12 },
                { label: 'Total Expenses', value: cashFlow?.totalOperatingExpenses?.adjustedT12 },
                { label: model?.useNCFnotNOI ? 'Net CF' : 'NOI', value: cashFlow?.noi?.adjustedT12 },
            ])
        }
        setLoading(false)
    }, [cashFlow, project])

    let total = 0
    const funnelSize = []
    for (const key in data) {
        total += data[key].value
    }
    total = total == 0 ? 1 : total
    for (const key in data) {
        funnelSize.push((data[key].value / total) * 100).toFixed()
    }

    for (const item of data) {
        const value = (item.value / data[0].value) * 100
        percents.push(Math.floor(value))
    }

    const duration = 500
    const [firstItem, firstSet] = useSpring(() => ({
        config: {
            duration,
        },
        height: 80,
        scale: 1,
        from: {
            height: 0,
            scale: 1,
        },
    }))
    const [secondItem, secondSet] = useSpring(() => ({
        config: {
            duration,
        },
        height: 66,
        scale: 1,
        from: {
            height: 0,
            scale: 1,
        },
    }))
    const [thirdItem, thirdSet] = useSpring(() => ({
        config: {
            duration,
        },
        height: 54,
        scale: 1,
        from: {
            height: 0,
            scale: 1,
        },
    }))
    const [fourthItem, fourthSet] = useSpring(() => ({
        config: {
            duration,
        },
        height: 45,
        scale: 1,
        from: {
            height: 0,
            scale: 1,
        },
    }))
    const repeatAnimation = useCallback(() => {
        const scaleValue = 1.2
        firstSet({ scale: scaleValue }).then(() => firstSet({ scale: 1 }))
        setTimeout(() => {
            secondSet({ scale: scaleValue }).then(() => secondSet({ scale: 1 }))
        }, 100)
        setTimeout(() => {
            thirdSet({ scale: scaleValue }).then(() => thirdSet({ scale: 1 }))
        }, 200)
        setTimeout(() => {
            fourthSet({ scale: scaleValue }).then(() => fourthSet({ scale: 1 }))
        }, 300)
    }, [firstSet, secondSet, thirdSet, fourthSet])

    return (
        <div className="proforma-wrapper" onMouseEnter={repeatAnimation}>
            <div className="proforma-title">Pro-Forma</div>

            {!loading ? (
                <React.Fragment>
                    <div className="percentages">
                        <AnimatedFunnelItem
                            funnelSize={funnelSize?.[0]?.toFixed(0)}
                            height={firstItem.height}
                            scale={firstItem.scale}
                            title={data?.[0]?.label}
                            value={data?.[0]?.value}
                            percents={percents[0]}
                        />
                        <AnimatedFunnelItem
                            funnelSize={funnelSize?.[1]?.toFixed(0)}
                            height={secondItem.height}
                            scale={secondItem.scale}
                            title={data?.[1]?.label}
                            value={data?.[1]?.value}
                            percents={percents[1]}
                        />
                        <AnimatedFunnelItem
                            funnelSize={funnelSize?.[2]?.toFixed(0)}
                            height={thirdItem.height}
                            scale={thirdItem.scale}
                            title={data?.[2]?.label}
                            value={data?.[2]?.value}
                            percents={percents[2]}
                        />
                        <AnimatedFunnelItem
                            funnelSize={funnelSize?.[3]?.toFixed(0)}
                            height={fourthItem.height}
                            scale={fourthItem.scale}
                            title={data?.[3]?.label}
                            value={data?.[3]?.value}
                            percents={percents[3]}
                        />
                    </div>
                </React.Fragment>
            ) : (
                <div className={'content-loading'}>{error ? <div className={'error'}>{error}</div> : <Spinner />}</div>
            )}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .proforma-wrapper {
                    width: 100%;
                    margin: 0;
                    border: 1px solid $border;
                    background-color: $white;
                    border-radius: 3px;

                    .proforma-title {
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                        border-bottom: 1px solid $border;
                        padding: 10px 20px;
                    }

                    .percentages {
                        display: flex;
                        align-items: center;
                        height: 260px;
                        padding: 20px;
                    }
                }
                .content-loading {
                    width: 100%;
                    height: 260px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .error {
                    color: red;
                }
            `}</style>
        </div>
    )
}

function FunnelItem(props: {
    funnelSize: number
    height: number
    scale?: number
    title?: string
    value?: number
    percents: number
}) {
    const { funnelSize, height, scale, title, value, percents } = props

    return (
        <div className="item-wrapper" style={{ flexGrow: funnelSize }}>
            <div
                className="item"
                style={{
                    height: `${height}%`,
                    transform: `scale(1, ${scale || 1})`,
                }}
            >
                <div className="percentage">{percents}%</div>
            </div>
            <div className="item-text">
                <div className="title">{title}</div>
                <div className="value">{numeral(value).format(numberFormat)}</div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .item-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    height: 150px;
                    .item {
                        height: 150px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 97% 97%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0.8;
                        width: 100%;
                        &:nth-of-type(1) {
                            background-image: url('/_img/trapezoids/1new.png');
                        }
                        &:nth-of-type(2) {
                            background-image: url('/_img/trapezoids/2new.png');
                        }
                        &:nth-of-type(3) {
                            background-image: url('/_img/trapezoids/3new.png');
                        }
                        &:nth-of-type(4) {
                            background-image: url('/_img/trapezoids/4new.png');
                        }
                        .percentage {
                            font-weight: 500;
                        }
                        transition: 0.2s all linear;
                        cursor: pointer;
                        &:hover {
                            opacity: 1;
                            transform: scale(1, 1.1);
                        }
                    }
                    .item-text {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        .title {
                            color: $gray;
                            opacity: 0.8;
                            font-size: 12px;
                        }
                        .value {
                            color: $black;
                            font-weight: 500;
                        }
                    }
                }
            `}</style>
        </div>
    )
}

const AnimatedFunnelItem = animated(FunnelItem)
