import axios from 'axios'
import { BondsGraphQl } from '@generated/graphql'

const parsePct = (str: string) => {
    return parseFloat(str.replace('%', '')) / 100
}

const getAverage = (arr) => {
    const sum = arr.reduce((result, item) => result + item, 0)
    return sum / arr.length
}

export async function getTreasuryBondsQuote(type): Promise<BondsGraphQl> {
    //https://quote.cnbc.com/quote-html-webservice/restQuote/symbolType/symbol?symbols=US7Y|US10Y&requestMethod=itv&noform=1&partnerId=2&fund=1&exthrs=1&output=json&events=1

    const b = {} as BondsGraphQl
    b.timeStamp = '' + new Date().getTime()

    if (type === 'SOFR' || type === 'SOFR 30 Days AVG') {
        try {
            const resp = await axios.get(
                'https://markets.newyorkfed.org/read?productCode=50&eventCodes=520&limit=25&startPosition=0&sort=postDt:-1',
            )
            const data = resp?.data.data
            const yesterday: any = JSON.parse(data[0].data)
            b.sofr = yesterday.dailyRate / 100
            /*
            let total = 0
            for (const d of data) {
                const day: any = JSON.parse(d.data)
                total += day.dailyRate
            }
            b.sofr30 = total / data.length / 100
            */
            const resp2 = await axios.get(
                'https://markets.newyorkfed.org/read?productCode=50&eventCodes=525&limit=25&startPosition=0&sort=postDt:-1',
            )

            const data2 = resp2?.data.data
            const yesterday2: any = JSON.parse(data2[0].data)

            b.sofr30 = yesterday2.averageIndex30days / 100
        } catch (e) {
            console.error('getTreasuryBondsQuote ERROR SOFR', e)
        }
    } else {
        try {
            const params = {
                symbols: 'US10Y|US7Y|US5Y|US1M',
                output: 'json',
            }
            // console.log('fillDebtSenior', params)
            const resp = await axios.get(`https://quote.cnbc.com/quote-html-webservice/restQuote/symbolType/symbol`, {
                params: params,
            })
            // console.log(resp?.data.FormattedQuoteResult?.FormattedQuote)
            if (resp?.data.FormattedQuoteResult?.FormattedQuote.length > 0) {
                for (const q of resp?.data.FormattedQuoteResult?.FormattedQuote) {
                    if (q.symbol == 'US10Y') b.years10 = parsePct(q.last)
                    if (q.symbol == 'US7Y') b.years7 = parsePct(q.last)
                    if (q.symbol == 'US5Y') b.years5 = parsePct(q.last)
                    if (q.symbol == 'US1M') b.days30 = parsePct(q.last)
                }
            }
        } catch (e) {
            console.error('getTreasuryBondsQuote ERROR', e)
        }
    }

    /*
    b.years10 = 0.02
    b.years7 = 0.02
    b.years5 = 0.02
    b.days30 = 0.02
    
 */
    return b
}
