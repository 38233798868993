import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { useRouter } from 'next/router'
type Props = any

export const PricingQuotes: FunctionComponent<Props> = (props: Props) => {
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const router = useRouter()
    const options = useMemo(() => {
        const options = []
        if (pricing) {
            const optionsFixed = pricing.fanniePricing.pricingOptions.filter((pricingOption) => {
                return (
                    pricingOption.isSelected &&
                    pricingOption.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                )
            })

            options.push({ options: optionsFixed, isArm: false })

            const optionsAdjustable = pricing.fanniePricing.pricingOptions.filter((pricingOption) => {
                return (
                    pricingOption.isSelected &&
                    pricingOption.scenario.underwritten.financeOptions.intrestType != 'Fixed'
                )
            })
            options.push({ options: optionsAdjustable, isArm: true })
        }
        return options
    }, [pricing])

    const isSupplemental = useMemo(() => {
        if (project?.type == 'miltifamily.value_add_supplemental') return true
        return false
    }, [project?.type])

    return (
        <>
            <div className={'PricingQuotes'}>
                <div className={'header'}>
                    After preliminary analysis and due diligence, {props.isNM ? 'NorthMarq Capital Finance' : 'We'}{' '}
                    proposes the financing of the above referenced Property with one of the below loan options:
                </div>
                {options.map((o, idx) => {
                    let title = ''
                    if (isSupplemental) title += 'Supplemental '
                    if (o.isArm) title += 'Adjustable '
                    else title += 'Fixed '
                    title += 'Rate Options'
                    if (!o.options || o.options.length == 0) return null
                    return (
                        <table key={'PricingQuotes' + idx}>
                            <thead>
                                <tr>
                                    <th colSpan={15} className={'title darkBlueBG'}>
                                        {title}
                                    </th>
                                </tr>
                                <tr>
                                    <th className={'counterCell'}></th>
                                    <th className={'mixblueBG boldText wideCell'}>Loan Product</th>
                                    <th className={'loanTermCell'}>Loan Term</th>
                                    <th className={'midSizeCell'}>Tier</th>
                                    <th className={'proceeds '}>Proceeds</th>
                                    <th className={'midSizeCell'}>Max LTV</th>
                                    <th className={'midSizeCell'}>Min DSCR</th>
                                    <th className={'mixblueBG refCell'}>Reference Index</th>
                                    <th className={'mixblueBG midSizeCell'}>Index Rate</th>
                                    <th className={'mixblueBG midSizeCell'}>Spread</th>
                                    <th className={'mixblueBG midSizeCell'}> Note Rate</th>
                                    <th className={'prepayyCell'}>Prepay (YM)</th>
                                    <th className={'midSizeCell'}>Amortization</th>
                                    <th className={'midSizeCell'}>IO</th>
                                    <th className={'intrestBasis'}>Interest Basis</th>
                                </tr>
                            </thead>
                            <tbody>
                                {o.options.map((option, idx) => {
                                    return (
                                        <tr key={option.name}>
                                            <td className={'counterCell'}></td>
                                            <td className={'wideCell blueBG boldText'}>
                                                {o.isArm
                                                    ? generalInputs.loanDetails.ARMType == 'Structured'
                                                        ? 'Structured ARM'
                                                        : generalInputs.loanDetails.ARMType
                                                    : option.creditFees?.loanTerms?.propertyType ==
                                                      'Conventional Multifamily'
                                                    ? 'Conventional'
                                                    : option.creditFees?.loanTerms?.propertyType}
                                            </td>
                                            <td className={'loanTermCell'}>
                                                {option.scenario.actual.financeOptions.loanTerm} Years{' '}
                                                {!o.isArm ? 'Fixed' : ''}
                                            </td>
                                            <td className={'midSizeCell'}>
                                                {option.scenario.actual.financeOptions.tier}
                                            </td>
                                            <td className={'proceeds'}>
                                                {numeral(option.sizer.actual.maxFirstPosLoan).format('$0,0')}
                                            </td>
                                            <td className={'midSizeCell'}>
                                                {numeral(option.scenario.actual.financeOptions.maxLTV).format(
                                                    '0.[00]%',
                                                )}
                                            </td>
                                            <td className={'midSizeCell'}>
                                                {option.scenario.actual.financeOptions.minDSCR}
                                            </td>
                                            <td className={'blueBG refCell'}>
                                                {option.scenario.actual.loanPricing.refIndex}
                                            </td>
                                            <td className={'blueBG midSizeCell'}>
                                                {numeral(option.scenario.actual.loanPricing.indexRate).format(
                                                    '0.[00]%',
                                                )}
                                            </td>
                                            <td className={'blueBG midSizeCell'}>
                                                {numeral(option.scenario.actual.loanPricing.totalSpread).format(
                                                    '0.[00]%',
                                                )}
                                            </td>
                                            <td className={'blueBG midSizeCell'}>
                                                {numeral(
                                                    option.scenario.actual.loanPricing.todayEstimateIntrestRate,
                                                ).format('0.[00]%')}
                                            </td>
                                            <td className={'prepayyCell'}>
                                                {option.scenario.actual.financeOptions.prepayment
                                                    ? option.scenario.actual.financeOptions.prepayment + ' Years'
                                                    : '-'}
                                            </td>
                                            <td className={'midSizeCell'}>
                                                {option.scenario.actual.financeOptions.amortization
                                                    ? option.scenario.actual.financeOptions.amortization + ' Years'
                                                    : '-'}
                                            </td>
                                            <td className={'midSizeCell'}>
                                                {option.scenario.actual.financeOptions.io
                                                    ? option.scenario.actual.financeOptions.io + ' Years'
                                                    : '-'}{' '}
                                            </td>
                                            <td className={'intrestBasis'}>
                                                {option.scenario.actual.financeOptions.intrestBasis}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                })}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .PricingQuotes {
                    margin-top: 20px;
                    margin-bottom: 30px;
                    .header {
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 140%;
                        margin-bottom: 20px;
                    }
                    .title {
                        color: white;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: left !important;
                        height: 49px;
                        text-indent: 10px;
                    }
                    table {
                        text-align: center;
                        width: 1500px;
                        left: -30px;
                        position: relative;
                        margin-top: 40px;
                        tbody > tr:nth-child(even) {
                            background-color: rgba(0, 0, 0, 0.05);
                        }
                    }
                    thead {
                        font-weight: 500;
                    }
                    td {
                        padding: 5px 10px 5px 10px;
                        /*font-family: 'Inter';*/
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 34px;
                        color: #161c26;
                    }
                    th {
                        background: #eaeef1;
                        mix-blend-mode: normal;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        height: 46px;
                        // text-transform: uppercase;
                        letter-spacing: 0.5px;
                        //  text-transform: uppercase;

                        color: #002857;
                    }
                    .boldText {
                        font-weight: 500;
                    }
                    .blueBG {
                        background-color: #4486ff0d;
                        mix-blend-mode: normal;
                    }
                    .mixblueBG {
                        background-color: #dbe5f2;
                    }

                    .darkBlueBG {
                        background-color: #002857;
                        mix-blend-mode: normal;
                    }
                    .counterCell {
                        width: 20px !important;
                        max-width: 20px !important;
                    }
                    .midSizeCell {
                        width: 110px !important;
                        max-width: 110px !important;
                    }
                    .loanTermCell {
                        width: 190px !important;
                        max-width: 190px !important;
                    }
                    .prepayyCell {
                        width: 120px !important;
                        max-width: 120px !important;
                    }
                    .refCell {
                        width: 250px !important;
                        max-width: 250px !important;
                    }
                    .intrestBasis {
                        width: 180px !important;
                        max-width: 180px !important;
                    }
                    .proceeds {
                        width: 175px !important;
                        max-width: 175px !important;
                    }
                    .wideCell {
                        width: 210px !important;
                        max-width: 210px !important;
                    }
                }
            `}</style>
        </>
    )
}
