import { calcSeverity } from './calcSeverity'
import { calcAvgValue } from '../../../../hooks/useModel'
import { formatCurrency } from './formatCurrency'
import { OriginationModelReworked } from '@generated/graphql'
import { regenerateCashFlow } from 'origination-model'
import { ReduxHelper } from '../../../../../../../store'
const cloneDeep = require('lodash.clonedeep')
export function calcNOIFCFMortgage(cashFlow: any, settings: any, model: OriginationModelReworked) {
    const out: any = {}
    out.columnTitles = settings.columnTitles
    out.rowTitles = settings.rowTitles
    out.xAxisName = settings.xAxisName
    out.yAxisName = settings.yAxisName
    out.data = []

    for (let i = 0; i < settings.rowValues.length; i++) {
        //   model.cashFlow[0].
        const row = []
        for (let j = 0; j < settings.columnValues.length; j++) {
            row.push(calcCell(cashFlow, settings.columnValues[j], settings.rowValues[i], model))
        }
        out.data.push(row)
    }
    calcSeverity(out.data)
    return out
}
function calcCell(cashFlow: any, vacancy: number, rate: number, model: OriginationModelReworked) {
    let newModel: OriginationModelReworked = cloneDeep(model)
    const years = newModel.underwritingAssumption.organicRentGrowth.length
    newModel.underwritingAssumption.vacancy = new Array(years).fill(vacancy)
    newModel.debtAssumptionSenior.rate = rate
    const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
    let cashFlowData = regenerateCashFlow({ cashFlow, model: newModel, unitMix: unitMix })
    const noi = calcAvgValue(cashFlowData.noi.years)
    const cf = calcAvgValue(cashFlowData.cashFlowAfterDebtService.years)
    cashFlowData = null
    newModel = null
    return {
        value: `${formatCurrency(noi)} / ${formatCurrency(cf)}`,
        sValue: cf,
        severity: 0.5,
    }
}
