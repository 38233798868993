import React, { useEffect, useMemo, useState } from 'react'
import { calcMinMax } from '../../../hooks/useModel'
import { CardWidget } from './CardWidget'
import numeral from 'numeral'
import { useMetrics } from '../../../hooks/useMetrics'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { calcArrayAverage } from 'origination-model'
type Props = any

export function GrossPotentialRent(props: Props) {
    const metrics = useMetrics()
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const [loading, setLoading] = useState(true)
    const values = useMemo(() => {
        if (!cashFlow || !project) return { min: 0, max: 0, avg: 0 }
        const grossPotentialRent = cashFlow.totalPotentialGrossRent
        if (project.accountType == 'borrower') {
            const { min, max } = calcMinMax(grossPotentialRent.years)
            return {
                min: min / metrics.numberOfUnits,
                max: max / metrics.numberOfUnits,
                avg: calcArrayAverage(grossPotentialRent.years),
            }
        } else {
            const { min, max } = calcMinMax(grossPotentialRent.aprDetails)
            return {
                min: (min / metrics.numberOfUnits) * 12,
                max: (max / metrics.numberOfUnits) * 12,
                avg: calcArrayAverage(grossPotentialRent.aprDetails) * 12,
            }
        }
    }, [project, cashFlow, metrics.numberOfUnits])
    useEffect(() => {
        if (project && cashFlow && metrics.numberOfUnits) {
            setLoading(false)
        }
    }, [project, cashFlow, metrics.numberOfUnits])

    return (
        <CardWidget
            title={'Gross Potential Rent'}
            largeItems={[
                {
                    title: 'Avg / Unit',
                    value: `${numeral(values.avg / metrics.numberOfUnits).format('$0,0')}`,
                },
                {
                    title: 'Avg / Unit / Month',
                    value: `${numeral(values.avg / metrics.numberOfUnits / 12).format('$0,0')}`,
                },
            ]}
            smallItems={[
                { min: numeral(values.min).format('$0,0'), max: numeral(values.max).format('$0,0') },
                { min: numeral(values.min / 12).format('$0,0'), max: numeral(values.max / 12).format('$0,0') },
            ]}
            loading={loading}
        />
    )
}
