export const parseUnitMixData = (project) => {
    let totalunits = 0
    const bybedrooms = [0, 0, 0, 0, 0]
    const byselection = {}
    for (let k = 0; k < project.unitmixs.length; k++) {
        const unitmix = project.unitmixs[k]
        totalunits += parseInt(unitmix.numofunits)
        bybedrooms[unitmix.bedrooms - 1] += parseInt(unitmix.numofunits)
        const key = unitmix.bedrooms + '-' + unitmix.baths
        let x = byselection[key]
        if (!x) {
            x = {}
            x.bedrooms = unitmix.bedrooms
            x.baths = unitmix.baths
            x.numofunits = parseInt(unitmix.numofunits)
            x.minprice = x.maxprice = unitmix.rentprice
            x.minsize = x.maxsize = unitmix.unitsize
        } else {
            x.numofunits += parseInt(unitmix.numofunits)
            if (unitmix.rentprice < x.minprice) x.minprice = unitmix.rentprice
            else if (unitmix.rentprice > x.maxprice) x.maxprice = unitmix.rentprice
            if (unitmix.unitsize < x.minsize) x.minsize = unitmix.unitsize
            else if (unitmix.unitsize > x.maxsize) x.maxsize = unitmix.unitsize
        }

        byselection[key] = x
    }

    const tableRows = []
    const chartData = {
        series: [],
        labels: [],
    }
    for (let i = 5; i >= -2; i--) {
        for (let j = -1; j <= 4; j++) {
            const x = byselection[i + '-' + j]
            if (x) {
                let price = x.minprice == x.maxprice ? x.minprice : x.minprice + '-' + x.maxprice
                if (!price) price = ' -- '
                let pricesqft =
                    x.minprice == x.maxprice
                        ? (x.minprice / x.minsize).toFixed(2)
                        : (x.minprice / x.minsize).toFixed(2) + '-' + (x.maxprice / x.maxsize).toFixed(2)

                if (!pricesqft) pricesqft = ' -- '

                const size = x.minsize == x.maxsize ? x.minsize : x.minsize + '-' + x.maxsize
                const label = i == -1 && j == -1 ? 'Retail' : i + ' Bedroom / ' + j + ' - Bath'
                tableRows.push({
                    unit: label,
                    numOfUnits: x.numofunits,
                    percent: ((x.numofunits / totalunits) * 100).toFixed(2),
                    size,
                    price,
                    pricesqft,
                })

                //chart data
                chartData.series.push(+((x.numofunits / totalunits) * 100).toFixed(2))
                chartData.labels.push(label)
            }
        }
    }

    return { chartData, tableRows }
}
