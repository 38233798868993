import React from 'react'
import { theme } from '../../../../theme'
import { ApexChart } from '../../../charts/ApexChart'
type Props = {
    title: string
    value: number
    className?: string
}
export function RadialBar(props: Props) {
    const chartOptions = {
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: 70,
                },
                track: {
                    show: true,
                    background: '#d5e0e5',
                },
                dataLabels: {
                    show: false,
                },
            },
        },
        stroke: {
            width: 4,
        },
        fill: {
            type: 'solid',
            colors: [theme.colors.green],
        },
    }

    return (
        <div className={`radial-bar ${props.className}`}>
            <div className={'radial-bar-chart'}>
                {/*// @ts-ignore*/}
                <ApexChart options={chartOptions} series={[props.value]} type={'radialBar'} height={200} width={200} />
            </div>
            <div className={'percent'}>{props.value}%</div>
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <div className={'value-label'} dangerouslySetInnerHTML={{ __html: props.title }} />
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .radial-bar {
                        position: relative;
                        display: block;
                        width: 136px;
                        height: 136px;
                        text-align: center;
                    }
                    .radial-bar-chart {
                        position: absolute;
                        top: -22px;
                        left: -35px;
                    }
                    .percent {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        color: $black;
                        font-weight: 500;
                        font-size: 25px;
                        top: 33px;
                    }
                    .value-label {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 14px;
                        top: 70px;
                        color: #676f7a;
                    }
                `}
            </style>
        </div>
    )
}
