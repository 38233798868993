import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { regenerateCashFlow } from 'origination-model'
import {
    nmGetLTPP,
    nmGetLTV,
    nmGetMaximumBuiltUpCost,
    nmGetMaximumCashNeutralAmount,
    nmGetTotalCostBasis,
} from '../northMarkFormulas'

import createDecorator from 'final-form-calculate'
import { Form, useForm, useFormState } from 'react-final-form'
import { Spinner } from '../../../../../controls/Spinner'
import { TextField } from '../../../components/TextField'
import { DateField } from '../../../../../controls/inputs/generalinputs/DateField'
import { FormApi } from 'final-form'
import { NumberField } from '../../../components/NumberField'
type Props = any

const cloneDeep = require('lodash.clonedeep')

export const RefinanceAssumption = React.memo(function RefinanceAssumption(props: Props) {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const localContext = useMemo(() => ({ form: null as FormApi }), [])

    const loanAmount = useMemo(() => {
        return generalInputs?.loanDetails?.requestedLoanAmount
    }, [generalInputs?.loanDetails?.requestedLoanAmount])

    const noi = useMemo(() => {
        return cashFlow?.noi?.adjustedT12
    }, [cashFlow?.noi?.adjustedT12])

    const capRate = useMemo(() => {
        if (model?.fanniePricing?.valuationComparison?.length > 0) {
            for (const v of model.fanniePricing.valuationComparison) {
                if (v.selected) return v.capRate
            }
            return 0.0525
        }
        return 0.0525
    }, [model?.fanniePricing?.valuationComparison])

    const dealType = useMemo(() => {
        return generalInputs?.loanDetails?.acquisitionOrRefinance || 'acquisition'
    }, [generalInputs?.loanDetails?.acquisitionOrRefinance])

    const isAcquisition = useMemo(() => {
        return dealType.toLowerCase() == 'acquisition'
    }, [dealType])

    const issupplamental = useMemo(() => {
        return project?.type == 'miltifamily.value_add_supplemental'
    }, [project?.type])

    const ltv = useMemo(() => {
        if (capRate) {
            if (isAcquisition) {
                const v = noi / capRate
                return model?.uses?.purchasePrice / v
            }
            return nmGetLTV(capRate, noi, loanAmount)
        }
        return 0
    }, [noi, capRate, isAcquisition])
    const totalCostBasis = useMemo(() => {
        return nmGetTotalCostBasis(
            model?.uses?.purchasePrice,
            generalInputs?.dealDetails?.escrowedImprovements,
            model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
        )
    }, [
        model?.uses?.purchasePrice,
        generalInputs?.dealDetails?.escrowedImprovements,
        model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
    ])

    const maximumBuiltUpCost = useMemo(() => {
        return nmGetMaximumBuiltUpCost(totalCostBasis)
    }, [totalCostBasis])

    const { LTPP, LTC } = useMemo(() => {
        const LTPP = nmGetLTPP(model?.uses?.purchasePrice, loanAmount)
        let LTC = 0
        if (maximumBuiltUpCost) {
            LTC = loanAmount / maximumBuiltUpCost
        } else {
            const uwValue = capRate ? noi / capRate : 0
            if (uwValue) {
                LTC = loanAmount / uwValue
            }
        }
        return { LTPP, LTC }
    }, [loanAmount, model?.uses?.purchasePrice, maximumBuiltUpCost, capRate])

    const maximumCashNeutralAmount = useMemo(() => {
        //generalInputs?.dealDetails?.cashout

        return nmGetMaximumCashNeutralAmount(
            loanAmount,
            generalInputs?.dealDetails?.currentUPB,
            generalInputs?.dealDetails?.prepay,
            generalInputs?.dealDetails?.escrowedImprovements,
            model?.uses?.purchasePrice,
            model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
            isAcquisition,
        )
    }, [
        isAcquisition,
        model?.uses?.purchasePrice,
        model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
        loanAmount,
        generalInputs?.dealDetails?.currentUPB,
        generalInputs?.dealDetails?.prepay,
        generalInputs?.dealDetails?.escrowedImprovements,
    ])

    const cashInOut = useMemo(() => {
        //generalInputs?.dealDetails?.cashout
        return maximumCashNeutralAmount - loanAmount
    }, [loanAmount, maximumCashNeutralAmount])

    const combinedLTV = useMemo(() => {
        if (!issupplamental) return 0
        let defCombined = generalInputs?.loanDetails?.requestedLoanAmount
        if (model?.fanniePricing?.supplemental)
            for (const sup of model.fanniePricing.supplemental) {
                defCombined += sup.priorLien.estimatedUpbAtSupClosing
            }
        const combinedUPB = pricing?.fanniePricing?.pricingOptions?.[0]?.sizer?.underwritten?.combinedUPB || defCombined

        if (isAcquisition) {
            return combinedUPB / maximumBuiltUpCost
        } else {
            if (!capRate) return 0
            else return combinedUPB / (noi / capRate)
        }
    }, [
        issupplamental,
        isAcquisition,
        maximumBuiltUpCost,
        noi,
        capRate,
        pricing?.fanniePricing?.pricingOptions?.[0],
        model?.fanniePricing?.supplemental,
        generalInputs?.loanDetails?.requestedLoanAmount,
    ])

    const isModel = model ? true : false
    //closingCostsMultiplier
    const onSubmit = useCallback(
        (values: any) => {
            //console.log('submit ValuationComparison', cloneDeep(values.fanniePricing.valuationComparison))
            ReduxHelper.setIn(
                ['lender', 'originationModel', 'fanniePricing', 'purchaseAndRefinanceAssumptions'],
                cloneDeep(values.fanniePricing.purchaseAndRefinanceAssumptions),
            )
        },
        [model],
    )
    const initialValues = useMemo(() => {
        // console.log('initialValues model.fanniePricing', model)
        if (model && !model.fanniePricing.purchaseAndRefinanceAssumptions)
            model.fanniePricing.purchaseAndRefinanceAssumptions = { closingCostsMultiplier: 1.015 }
        else if (model && !model.fanniePricing.purchaseAndRefinanceAssumptions.closingCostsMultiplier)
            model.fanniePricing.purchaseAndRefinanceAssumptions.closingCostsMultiplier = 1.015
        return {
            ...cloneDeep(model),
        }
    }, [isModel])
    const computedValues = useMemo(() => {
        return createDecorator()
    }, [])
    const mutators = useMemo(() => ({}), [])

    if (issupplamental && !isAcquisition) return null

    return (
        <>
            <div className={'pricingAnalyticsComponent'}>
                <div className={'title'}>{isAcquisition ? 'Recent Purchase Assumption' : 'Refinance Assumption'}</div>
                <div className={'mainPart'}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Current / Recent Acquisition</td>
                                <td>{dealType}</td>
                            </tr>
                            <tr>
                                <td>Property {isAcquisition ? 'Purchase Price' : 'Valuation'} </td>
                                <td>{numeral(model?.uses?.purchasePrice).format('$0,0')}</td>
                            </tr>
                            {!isAcquisition && (
                                <tr>
                                    <td>Unpaid Principal</td>
                                    <td>{numeral(generalInputs?.dealDetails?.currentUPB).format('$0,0')}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Escrowed Improvements</td>
                                <td>{numeral(generalInputs?.dealDetails?.escrowedImprovements).format('$0,0')}</td>
                            </tr>
                            {!isAcquisition && (
                                <>
                                    <tr>
                                        <td>Prepay / Additional Payoffs</td>
                                        <td>{numeral(generalInputs?.dealDetails?.prepay).format('$0,0')}</td>
                                    </tr>

                                    <tr>
                                        <td>Maximum Cash Neutral Amount</td>
                                        <td>{numeral(maximumCashNeutralAmount).format('$0,0')}</td>
                                    </tr>
                                    <tr>
                                        <td>Cash In | (Cash Out)</td>
                                        <td>{numeral(cashInOut).format('$0,0')}</td>
                                    </tr>

                                    <tr>
                                        <td className={'impText'}>LTV</td>
                                        <td className={'impVal'}>{numeral(ltv).format('0.[00]%')}</td>
                                    </tr>
                                </>
                            )}
                            {isAcquisition && (
                                <>
                                    {!issupplamental && (
                                        <tr>
                                            <td>Total Cost Basis</td>
                                            <td>{numeral(totalCostBasis).format('$0,0')}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>Maximum Built-Up Cost</td>
                                        <td>{numeral(maximumBuiltUpCost).format('$0,0')}</td>
                                    </tr>
                                    {model ? (
                                        <Form
                                            onSubmit={onSubmit}
                                            initialValues={initialValues}
                                            decorators={[computedValues]}
                                            mutators={mutators}
                                        >
                                            {({ form }) => {
                                                localContext.form = form
                                                return (
                                                    <PurchaseAndRefinanceAssumptionsForm
                                                        loanAmount={loanAmount}
                                                        ncf={cashFlow?.noi?.adjustedT12}
                                                    />
                                                )
                                            }}
                                        </Form>
                                    ) : (
                                        <tr>
                                            <td>
                                                <div>
                                                    <Spinner />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td style={{ textIndent: '20px' }}>per unit</td>
                                        <td>
                                            {numeral(
                                                model?.numberOfUnits ? maximumBuiltUpCost / model?.numberOfUnits : 0,
                                            ).format('$0,0')}
                                        </td>
                                    </tr>
                                    {!issupplamental && (
                                        <>
                                            <tr>
                                                <td className={'impText bludBG'}>LTPP</td>
                                                <td className={'impVal bludBG'}>{numeral(LTPP).format('0.[00]%')}</td>
                                            </tr>
                                            <tr>
                                                <td className={'impText' + (LTC >= 0.9 ? ' redBG' : ' bludBG')}>LTC</td>
                                                <td className={'impVal' + (LTC >= 0.9 ? ' redBG' : ' bludBG')}>
                                                    {numeral(LTC).format('0.[00]%')}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {issupplamental && (
                                        <tr>
                                            <td className={'impText bludBG'}> Combined LTV</td>
                                            <td className={'impVal bludBG'}>
                                                {numeral(combinedLTV).format('0.[00]%')}
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .redBG {
                    background-color: rgba(241, 75, 68, 0.15);
                }

                .bludBG {
                    background-color: rgba(68, 134, 255, 0.1);
                }

                .pricingAnalyticsComponent {
                    background: white;
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-bottom: 20px;
                    width: 600px;
                    height: 366px;
                    margin-right: 20px;
                }

                .title {
                    border-bottom: 1px solid #d9e0e5;
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }

                table {
                    margin-top: 20px;
                    text-indent: 20px;
                }

                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;

                    tr {
                        height: 35px;

                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }

                    td {
                        width: 300px;

                        &:nth-of-type(even) {
                            text-align: right;
                            padding-right: 20px;
                        }
                    }
                }

                .impText {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 27px;
                    color: #161c26;
                }

                .impVal {
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 27px;
                    color: #161c26;
                }
            `}</style>
        </>
    )
})

const PurchaseAndRefinanceAssumptionsForm: FunctionComponent<Props> = (props: Props) => {
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    useEffect(() => {
        // console.log('useEffect')
        if (form) form.submit()
    }, [formValues.fanniePricing?.purchaseAndRefinanceAssumptions])
    return (
        <>
            <tr>
                <td>Closing Costs Multiplier </td>
                <td>
                    <NumberField
                        name={`fanniePricing.purchaseAndRefinanceAssumptions.closingCostsMultiplier`}
                        numberFormat={'percent'}
                        cssClassName={'closingcostmulti'}
                    />
                </td>
            </tr>

            {/*language=SCSS*/}
            <style jsx>{`
                :global(.closingcostmulti) {
                    position: relative;
                    float: right;
                    right: 15px;
                }
            `}</style>
        </>
    )
}
