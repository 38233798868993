import React, { useMemo } from 'react'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'

type Props = any

export function PropertyOverview(props: Props) {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const unitMix = useSelector((state: ReduxStoreState) => state.lender.unitMix)
    const { numOfUnits, rentableSF, averageUnitSize } = useMemo(() => {
        const out = {
            numOfUnits: 0,
            rentableSF: 0,
            averageUnitSize: 0,
        }
        for (const unit of unitMix) {
            out.numOfUnits += unit.numofunits
            out.rentableSF += unit.numofunits * unit.unitsize
        }
        out.averageUnitSize = Math.round(out.rentableSF / out.numOfUnits)
        return out
    }, [unitMix])

    const tdstyle = {
        paddingLeft: '10px',
    }
    return (
        <WidgetWrapper title={'Property Overview'}>
            <table>
                <tbody>
                    <tr>
                        <td>Address:</td>
                        <td className={'value'} style={tdstyle}>
                            {project?.address}
                        </td>
                    </tr>
                    <tr>
                        <td>Number of Units:</td>
                        <td className={'value'} style={tdstyle}>
                            {numeral(numOfUnits).format('0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td>Average Unit Size (SF):</td>
                        <td className={'value'} style={tdstyle}>
                            {numeral(averageUnitSize).format('0,0')}
                        </td>
                    </tr>
                    <tr>
                        <td>Total Rentable (SF):</td>
                        <td className={'value'} style={tdstyle}>
                            {numeral(rentableSF).format('0,0')}
                        </td>
                    </tr>
                </tbody>
            </table>
            {/*language=SCSS*/}
            <style jsx>{`
                .value {
                    font-weight: bold;
                }
            `}</style>
        </WidgetWrapper>
    )
}
