import createDecorator from 'final-form-calculate'

import { calcAvgValue } from '../../hooks/useModel'
import { CashFlow, CashFlowRow } from '../../../../../store/types/OriginationModelRedux'

import { CashFlowGraphQl, OriginationModelReworked } from '@generated/graphql'
import { regenerateCashFlow, getMetrics } from 'origination-model'
import { ReduxHelper } from '../../../../../store'
const cloneDeep = require('lodash.clonedeep')
let lastUpdate = 0
export const computedValues = createDecorator(
    {
        field: /(uses|sources|underwritingAssumption|propertyTaxAssumption|exitAssumption|debtAssumptionSenior|debtAssumptionMez).*/, // when minimum changes...
        updates: (value, name, values: OriginationModelReworked & { cashFlow: any }) => {
            // console.log('name', name, value, cloneDeep(values))
            if (isNaN(value)) return {}
            if (typeof value === 'undefined') return {}

            //console.log(' values.uses.purchasePric', values.uses.purchasePrice)
            // disable too frequient values recalc
            const dif = new Date().valueOf() - lastUpdate
            if (dif < 50) return {}
            lastUpdate = new Date().valueOf()
            //console.log('RENT GROWTH', values.underwritingAssumption.organicRentGrowth)
            const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
            const cashFlow = regenerateCashFlow({ model: values, cashFlow: values.cashFlow, unitMix: unitMix })
            values.cashFlow = cashFlow as CashFlowGraphQl
            const computed = calcComputed(null, values)

            //   console.log('RENT income', values.cashFlow.rentalIncome[0])

            return {
                cashFlow,
                computed,
            }
        },
    },

    {
        field: /(exitAssumption\.exitCapRate|uses\.purchasePrice)/,
        updates: {
            'exitAssumption.capRateSpread': (v, values: any) => {
                //console.log('eee', v)
                if (isNaN(v)) return 0
                if (typeof v === 'undefined') return 0
                const cashFlow = values.cashFlow as CashFlow
                const goingCapRate = cashFlow.noi.apr / values.uses.purchasePrice
                return Math.abs(goingCapRate - values.exitAssumption.exitCapRate)
            },
        },
    },
)

function calcComputed(v: any, values: any) {
    const cashFlow = values.cashFlow as CashFlow
    const metrics = getMetrics({ cashFlow, model: values })

    const noi = cashFlow.noi.years[cashFlow.noi.years.length - 1]
    const exitCapRate = values.exitAssumption.exitCapRate

    const loanPerUnit = values.sources.seniorDebt > 0 ? values.sources.seniorDebt / values.numberOfUnits : 0
    const loanPerSqFt = values.sources.seniorDebt > 0 ? values.sources.seniorDebt / values.totalSF : 0

    const loanPerUnitMez =
        values.sources.seniorDebt > 0
            ? (values.sources.seniorDebt + (values.sources.mezzanine > 0 ? values.sources.mezzanine : 0)) /
              values.numberOfUnits
            : 0
    const loanPerSqFtMez =
        values.sources.seniorDebt > 0
            ? (values.sources.seniorDebt + (values.sources.mezzanine > 0 ? values.sources.mezzanine : 0)) /
              values.totalSF
            : 0

    let totalNOI = 0

    for (let i = 0; i < values.cashFlow?.noi.years.length; i++) {
        const noi = values.cashFlow?.noi?.years[i]
        totalNOI += noi
    }

    const avgnoi = values.cashFlow?.noi?.years ? totalNOI / values.cashFlow.noi.years.length : 0

    const debtYieldMez =
        values.sources.seniorDebt > 0
            ? +(
                  (avgnoi /
                      (values.sources.seniorDebt + (values.sources.mezzanine > 0 ? values.sources.mezzanine : 0))) *
                  100
              ).toFixed(2) / 100
            : 0

    //First Year DSCR
    //const calculateddscr = (avgDSCR>0) ? (values.cashFlow?.noi.years[0]/debtServiceInterest.years[0]).toFixed(2)/-100: 0

    return {
        ...values.computed,
        coc: metrics.cocPerYear,
        roe: metrics.roePerYear,
        avgCoc: metrics.coc,
        avgRoe: metrics.roe,
        avgCapRate: calcAvgValue(cashFlow.capRate),
        ltv: metrics.ltvSenior,
        ltc: metrics.ltcSenior,
        ltvMez: metrics.ltv,
        ltcMez: metrics.ltc,
        loanperunit: loanPerUnit,
        loanpersft: loanPerSqFt,
        loanperunitMez: loanPerUnitMez,
        loanpersftMez: loanPerSqFtMez,
        dscr: metrics.dscr,
        debtyield: metrics.debtYield,
        debtYieldMez: debtYieldMez,
        dealEquityMultiply: metrics.dealEquityMultiply,
        salePrice: Math.round(noi / exitCapRate),
        dealIRR: metrics.dealIRR,
        netIRR: metrics.netIRR,
        npv: metrics.npv,
    }
}
