import React, { useCallback } from 'react'
import { Field } from 'react-final-form'
import { NumberField } from '../../../components/NumberField'
import { DateField } from '../../../components/DateField'
type Field = {
    fld: string
    type?: string
    label: string
    readOnly?: boolean
}
type Props = {
    fieldGroup: string
    fields: Array<Field>
}
export const DefaultFieldsComponent = (props: Props) => {
    const renderField = useCallback((field: Field) => {
        let fieldName = `${props.fieldGroup}.${field.fld}`
        if (field.fld.includes('.')) {
            fieldName = field.fld
        }
        switch (field.type) {
            case 'currency':
            case 'percent':
            case 'years':
            case 'decimal':
                return <NumberField name={fieldName} numberFormat={field.type} readOnly={field.readOnly == true} />
            case 'date':
                return <DateField name={fieldName} />
            default:
                return <NumberField name={fieldName} numberFormat={'currency'} readOnly={field.readOnly == true} />
        }
        return null
    }, [])
    return (
        <div className="container" style={{ paddingLeft: 27 }}>
            {props.fields?.map((field, i) => (
                <div className="row model-field-wrapper" key={i}>
                    <div className="col-8 p-0 ">
                        <div className="model-field-title">{field.label}</div>
                    </div>
                    <div className="col-4 p-0 ">{renderField(field)}</div>
                </div>
            ))}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .model-field-wrapper {
                    margin-bottom: 20px;

                    .model-field-title {
                        font-weight: 500;
                        font-size: 15px;
                        color: $black;
                    }

                    .model-field-value {
                        font-weight: 500;
                        font-size: 15px;
                        color: $black;
                        border-bottom: 1px solid $light-gray;
                        text-align: right;
                        padding: 5px 0 10px;
                    }
                }
            `}</style>
        </div>
    )
}
