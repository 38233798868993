import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { regenerateCashFlow } from 'origination-model'
import { KpiCard } from '../../../components/KpiCard'
import { Spinner } from '../../../../../controls/Spinner'

const FANNIE_PROFORMA_LABEL = 'Fannie Original UW'

type Props = any

export const Trends = React.memo(function Trends(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const proformas = useSelector((store: ReduxStoreState) => store.lender.proforma)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })

    const originalNCF = useMemo(() => {
        if (!!proformas) {
            const fannieProforma = proformas.find((elem) => elem.label === FANNIE_PROFORMA_LABEL)
            if (!!fannieProforma) {
                return fannieProforma.noi.years[0]
            }
        }

        return null
    }, [proformas])

    const underwrittenNCF = useMemo(() => {
        return cashFlow?.noi?.adjustedT12
    }, [cashFlow?.noi?.adjustedT12])

    const NCFChange_OldCap = useMemo(() => {
        return (underwrittenNCF - originalNCF) / model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraisedCapRate
    }, [originalNCF, underwrittenNCF, model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraisedCapRate])

    const NCFChange_NewCap = useMemo(() => {
        return (underwrittenNCF - originalNCF) / model?.fanniePricing?.valuationComparison?.[0]?.capRate
    }, [originalNCF, underwrittenNCF, model?.fanniePricing?.valuationComparison?.[0]?.capRate])

    const newNCF_OldCap = useMemo(() => {
        return underwrittenNCF / model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraisedCapRate
    }, [underwrittenNCF, model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraisedCapRate])

    const newNCF_NewCap = useMemo(() => {
        return underwrittenNCF / model?.fanniePricing?.valuationComparison?.[0]?.capRate
    }, [underwrittenNCF, model?.fanniePricing?.valuationComparison?.[0]?.capRate])

    const growsFromCapRateComparison = useMemo(() => {
        return newNCF_NewCap - newNCF_OldCap
    }, [newNCF_NewCap, newNCF_OldCap])

    const NCFGrowth = useMemo(() => {
        return (underwrittenNCF - originalNCF) / model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraisedCapRate
    }, [originalNCF, underwrittenNCF, model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraisedCapRate])

    const estimatedValue = useMemo(() => {
        return growsFromCapRateComparison + NCFGrowth + model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraised
    }, [growsFromCapRateComparison, NCFGrowth, model?.fanniePricing?.supplemental?.[0]?.valueChanges?.appraised])

    const underwrittenNRI = useMemo(() => {
        const sumOfRentalIncome = cashFlow?.rentalIncome.reduce((sum, item) => sum + item.adjustedT12, 0)
        const sumOfRentalLoss = cashFlow?.rentalLoss.reduce((sum, item) => sum + item.adjustedT12, 0)
        return sumOfRentalIncome + sumOfRentalLoss
    }, [cashFlow?.rentalIncome, cashFlow?.rentalLoss])

    const originalNRI = useMemo(() => {
        if (!!proformas) {
            const fannieProforma = proformas.find((elem) => elem.label === FANNIE_PROFORMA_LABEL)
            if (!!fannieProforma) {
                const sumOfRentalIncome = fannieProforma.rentalIncome.reduce((sum, item) => sum + item.years[0], 0)
                const sumOfRentalLoss = fannieProforma.rentalLoss.reduce((sum, item) => sum + item.years[0], 0)
                return sumOfRentalIncome + sumOfRentalLoss
            }
        }
        return null
    }, [proformas])

    const NRI = useMemo(() => {
        return (underwrittenNRI - originalNRI) / originalNRI
    }, [underwrittenNRI, originalNRI])

    const underwrittenEGI = useMemo(() => {
        const sumOfOtherIncome = cashFlow?.otherIncome.reduce((sum, item) => sum + item.adjustedT12, 0)
        return underwrittenNRI + sumOfOtherIncome
    }, [underwrittenNRI])

    const originalEGI = useMemo(() => {
        if (!!proformas) {
            const fannieProforma = proformas.find((elem) => elem.label === FANNIE_PROFORMA_LABEL)
            if (!!fannieProforma) {
                const sumOfOtherIncome = fannieProforma.otherIncome.reduce((sum, item) => sum + item.years[0], 0)
                return originalNRI + sumOfOtherIncome
            }
        }
        return null
    }, [originalNRI, proformas])

    const EGI = useMemo(() => {
        return (underwrittenEGI - originalEGI) / originalEGI
    }, [underwrittenEGI, originalEGI])

    const underwrittenOperatingExpenses = useMemo(() => {
        return cashFlow?.operatingExpenses.reduce((sum, item) => sum + item.adjustedT12, 0)
    }, [cashFlow?.operatingExpenses])

    const originalOperatingExpenses = useMemo(() => {
        if (!!proformas) {
            const fannieProforma = proformas.find((elem) => elem.label === FANNIE_PROFORMA_LABEL)
            if (!!fannieProforma) {
                return fannieProforma.operatingExpenses.reduce((sum, item) => sum + item.years[0], 0)
            }
        }
        return null
    }, [proformas])

    const expenses = useMemo(() => {
        const fannieProforma = proformas.find((elem) => elem.label === FANNIE_PROFORMA_LABEL)
        const underwrittenCashflowReserve = cashFlow?.operatingExpenses?.find(
            (item) => item.id === 'replacementReserves',
        )
        const originalFannieCashflowReserve = fannieProforma?.operatingExpenses?.find(
            (item) => item.id === 'replacementReserves',
        )

        if (underwrittenOperatingExpenses && originalOperatingExpenses) {
            return (
                (underwrittenOperatingExpenses -
                    (underwrittenCashflowReserve?.adjustedT12 || 0) -
                    (originalOperatingExpenses - (originalFannieCashflowReserve?.years?.[0] || 0))) /
                (originalOperatingExpenses - (originalFannieCashflowReserve?.years?.[0] || 0))
            )
        }
        return null
    }, [underwrittenOperatingExpenses, originalOperatingExpenses, cashFlow?.operatingExpenses, proformas])

    const NCF = useMemo(() => {
        return (underwrittenNCF - originalNCF) / originalNCF
    }, [underwrittenNCF, originalNCF])

    return (
        <>
            {model ? (
                <div className={'trendsComponent'}>
                    <div className={'title'}>Trends From Original Underwriting</div>
                    <div className={'stats'}>
                        <KpiCard title="NRI" value={NRI * 100} />
                        <KpiCard title="EGI" value={EGI * 100} />
                        <KpiCard title="Expenses" value={expenses * 100} />
                        <KpiCard title="NCF" value={NCF * 100} />
                    </div>
                    <div className={'title'}>Value Build Up</div>
                    <div className={'mainPart'}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>NCF Change / Old Cap</td>
                                    <td>{numeral(NCFChange_OldCap).format('$0,0')}</td>
                                </tr>
                                <tr>
                                    <td>NCF Change / New Cap</td>
                                    <td>{numeral(NCFChange_NewCap).format('$0,0')}</td>
                                </tr>
                                <tr />
                                <tr className={'bludBG'}>
                                    <td>
                                        <b>Change in NCF</b>
                                    </td>
                                    <td className={'biggerFont'}>
                                        <b>{numeral(underwrittenNCF - originalNCF).format('$0,0')}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td>New NCF / Old Cap</td>
                                    <td>{numeral(newNCF_OldCap).format('$0,0')}</td>
                                </tr>
                                <tr>
                                    <td>New NCF / New Cap</td>
                                    <td>{numeral(newNCF_NewCap).format('$0,0')}</td>
                                </tr>
                                <tr />
                                <tr className={'bludBG'}>
                                    <td>
                                        <div className={'centerText'}>
                                            <div>
                                                <b>Growth From Cap Rate</b>
                                            </div>
                                            <div>
                                                <b>Comparison</b>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={'biggerFont'}>
                                        <b>{numeral(growsFromCapRateComparison).format('$0,0')}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                <tr>
                                    <td>Original Value</td>
                                    <td>
                                        {numeral(model?.fanniePricing?.supplemental[0]?.valueChanges?.appraised).format(
                                            '$0,0',
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>NCF Growth</td>
                                    <td>{numeral(NCFGrowth).format('$0,0')}</td>
                                </tr>
                                <tr>
                                    <td>Cap Rate Compression</td>
                                    <td>{numeral(growsFromCapRateComparison).format('$0,0')}</td>
                                </tr>
                                <tr className={'bludBG'}>
                                    <td>
                                        <b>Estimated Value</b>
                                    </td>
                                    <td className={'biggerFont'}>
                                        <b>{numeral(estimatedValue).format('$0,0')}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                .bludBG {
                    background-color: rgba(68, 134, 255, 0.1);
                }
                .stats {
                    display: flex;
                    gap: 20px;
                    padding: 20px;
                }
                .trendsComponent {
                    background: white;
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    margin-bottom: 20px;
                    width: 1330px;
                    margin-right: 20px;
                }
                .title {
                    border-bottom: 1px solid #d9e0e5;
                    font-family: 'Inter';
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                .mainPart {
                    display: flex;
                    gap: 10px;
                    padding: 10px 0px;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                    flex: 1;
                }

                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;
                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                    td {
                        width: 300px;
                        &:nth-of-type(even) {
                            text-align: right;
                            padding-right: 20px;
                        }
                    }
                }
                .biggerFont {
                    font-size: 22px;
                }
                .centerText {
                    width: fit-content;
                    text-align-last: center;
                }
            `}</style>
        </>
    )
})
