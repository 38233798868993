import React, { useEffect, useState } from 'react'
import { Gauge } from './Gauge'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { calcAvgValue } from '../../../hooks/useModel'
import { useSelector } from 'react-redux'
import { useMetrics } from '../../../hooks/useMetrics'
import { calcArrayAverage } from 'origination-model'

type Props = {
    projectId: number
}

export function FreeCashFlowBeforeDebtGauge(props: Props) {
    const [loading, setLoading] = useState(true)
    const metrics = useMetrics()
    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState({ value: 0, percent: 0, threshold: 0, originalValue: 0 })
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)

    useEffect(() => {
        if (settings?.freeCashFlowBeforeDebt == null) return
        if (!cashFlow) return
        const avgGrossPotentialRent = calcArrayAverage(cashFlow.totalPotentialGrossRent.years)
        const avgCashFlowBeforeDebt = calcAvgValue(metrics.cashFlowBeforeDebt.years)
        const value = (avgCashFlowBeforeDebt / avgGrossPotentialRent) * 100
        let percent = value / (settings.freeCashFlowBeforeDebt * 2)
        percent = percent > 1 ? 1 : percent
        setData({
            percent,
            value: value,
            threshold: settings?.freeCashFlowBeforeDebt,
            originalValue: avgCashFlowBeforeDebt,
        })
        setLoading(false)
    }, [cashFlow, settings?.freeCashFlowBeforeDebt])
    //return null

    const customColor = data.value < settings?.freeCashFlowBeforeDebt ? '#C93838' : '#67d554'

    return (
        <Gauge
            title={'Avg. FCF vs Avg. GPR'}
            badge={'before debt'}
            value={`${data.value.toFixed(0)}%`}
            percent={data.percent}
            leftHint={'Cash Flow'}
            leftValue={`$${numeral(data.originalValue).format('0,0')}`}
            rightHint={'Threshold'}
            rightValue={`${settings?.freeCashFlowBeforeDebt}%`}
            hover={'gauge'}
            error={error}
            loading={loading}
            customColor={customColor}
        />
    )
}
