import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import closeIcon from './close.svg'
import openDnd from '../RightBar/open-dnd.svg'
import openRightFineTuneImg from './open-right-fine-tune-tab.svg'
import { NumberField } from '../../../../../components/NumberField'
import { LoadDealWindow } from '../LoadDealWindow/LoadDealWindow'
import { BooleanField } from '../../../../../components/BooleanField'
/**
 * Component shows side menu with react portal api,
 * host divs can be found in OriginationModelWrapper
 * content is rendered to #right-dock div
 * and main content is shifted by #right-dock-placeholder div
 */
type BottomBarProps = {
    form?: any
    hide?: () => void
    expanded?: boolean
    localContext?: any
    toggleLoadDeal?: boolean
    changeFineTuneMode?: (v: string) => void
    setToggleLoadDeal?: (v: boolean) => void
    setSaveDealModalVisible?: (v: boolean) => void
    showMez?: boolean
    useNCFnotNOI: boolean
}
export const BottomBar = forwardRef((props: BottomBarProps, ref) => {
    const [activeTab, setActiveTab] = useState<string>('Senior')
    const {
        hide,
        form,
        expanded,
        localContext,
        toggleLoadDeal,
        setToggleLoadDeal,
        changeFineTuneMode,
        setSaveDealModalVisible,
        showMez,
        useNCFnotNOI,
    } = props

    return (
        <PortalWrapper>
            <div className={`fine-tune  ${expanded ? 'fine-tune-expanded' : ''}`}>
                <div className={'panel'}>
                    <img src={closeIcon} onClick={hide} className={'close-button'} />
                    <div className={'content'}>
                        <div className={'title'}>
                            Fine-tune deal
                            <div className={'heading-buttons'}>
                                <div className={'button'} onClick={() => changeFineTuneMode('RightBar')}>
                                    <img src={openRightFineTuneImg} />
                                </div>
                                <div className={'button'} onClick={() => changeFineTuneMode('DragDrop')}>
                                    <img src={openDnd} />
                                </div>
                            </div>
                        </div>
                        {showMez && (
                            <div className={'tabs'}>
                                <div
                                    className={activeTab == 'Senior' ? 'tab active' : 'tab'}
                                    onClick={() => setActiveTab('Senior')}
                                >
                                    Senior
                                </div>
                                <div
                                    className={activeTab == 'Mezzanine' ? 'tab active' : 'tab'}
                                    onClick={() => setActiveTab('Mezzanine')}
                                >
                                    Mezzanine
                                </div>
                            </div>
                        )}
                        <div className={'fine-tune-form'}>
                            {activeTab === 'Senior' && (
                                <>
                                    <div className={'form-block'}>
                                        <div className={'subTitle'}>Restrictions {showMez ? 'Senior' : ''}</div>
                                        <div className={'block-content'}>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Min DSCR</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'decimal'} name={'minDSCR'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <BooleanField
                                                    name={'useSeniorMaxLTC'}
                                                    label={'Max LTC'}
                                                    className={'radio'}
                                                    disableUncheck={true}
                                                />
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'percent'} name={'maxLTC'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <BooleanField
                                                    name={'useSeniorMaxLTV'}
                                                    label={'Max LTV'}
                                                    className={'radio'}
                                                    disableUncheck={true}
                                                />
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'percent'} name={'maxLTV'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'form-block'}>
                                        <div className={'subTitle'}>Loan Terms {showMez ? 'Senior' : ''}</div>
                                        <div className={'block-content'}>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Amortization Period</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'years'} name={'amortizationTerm'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Term</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'years'} name={'loanTerm'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <div className={'label'}>IO Period</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'years'} name={'ioPeriod'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Rate</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'percent'} name={'rate'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {showMez && activeTab === 'Mezzanine' && (
                                <>
                                    <div className={'form-block'}>
                                        <div className={'subTitle'}>Restrictions Mezzanine</div>
                                        <div className={'block-content'}>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Min DSCR</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'decimal'} name={'mezMinDSCR'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <BooleanField
                                                    name={'useMezMaxLTC'}
                                                    label={'Max LTC'}
                                                    className={'radio'}
                                                    disableUncheck={true}
                                                />
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'percent'} name={'mezMaxLTC'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <BooleanField
                                                    name={'useMezMaxLTV'}
                                                    label={'Max LTV'}
                                                    className={'radio'}
                                                    disableUncheck={true}
                                                />
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'percent'} name={'mezMaxLTV'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={'form-block'}>
                                        <div className={'subTitle'}>Loan Terms Mezzanine</div>
                                        <div className={'block-content'}>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Amortization Period</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'years'} name={'mezAmortizationTerm'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Term</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'years'} name={'mezLoanTerm'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <div className={'label'}>IO Period</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'years'} name={'mezIoPeriod'} />
                                                </div>
                                            </div>
                                            <div className={'form-row'}>
                                                <div className={'label'}>Rate</div>
                                                <div className={'value'}>
                                                    <NumberField numberFormat={'percent'} name={'mezRate'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className={'form-block'}>
                                <div className={'subTitle'}>Vacancy</div>
                                <div className={'block-content'}>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Annualized vacancy</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'vacancy'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'form-block'}>
                                <div className={'subTitle'}>Exit Assumptions</div>
                                <div className={'block-content'}>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Exit Cap rate</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'exitCapRate'} />
                                        </div>
                                    </div>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Refinanced LTV</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'refinanceLTV'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'form-block'}>
                                <div className={'subTitle'}>Estimated Valuation</div>
                                <div className={'block-content'}>
                                    <div className={'form-row'}>
                                        <div className={'label'}>Valuation Cap rate</div>
                                        <div className={'value'}>
                                            <NumberField numberFormat={'percent'} name={'valuationCapRate'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'form-block'}>
                                <div className={'subTitle'}>Proforma Comparison</div>
                                <div className={'block-content'}>
                                    <div className={'form-row'}>
                                        <BooleanField
                                            name={'useBorrowerNOI'}
                                            label={useNCFnotNOI ? 'Net CF' : 'NOI'}
                                            className={'radio'}
                                        />
                                        <div className={'value'}>
                                            <NumberField numberFormat={'currency'} name={'borrowerNOI'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'bottom-buttons'}>
                            <div className={'deal-buttons'}>
                                <div className={'link'} onClick={() => setSaveDealModalVisible(true)}>
                                    Save Scenario
                                </div>
                                <div
                                    className={'link'}
                                    onClick={() => setToggleLoadDeal(true)}
                                    style={{ color: toggleLoadDeal ? 'black' : '#4485ff' }}
                                >
                                    Load Scenario
                                </div>
                                {toggleLoadDeal && (
                                    <LoadDealWindow setToggleLoadDeal={setToggleLoadDeal} localContext={localContext} />
                                )}
                            </div>
                            <div className={'close-reset-buttons'}>
                                <div className={'link'} onClick={() => form.reset()}>
                                    Reset
                                </div>
                                <div className={'apply'} onClick={hide}>
                                    Close
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                $panel-width: 100%;
                .fine-tune {
                    width: 0px;
                    //transition: width 300ms;
                    overflow: hidden;
                }
                .panel {
                    position: relative;
                    width: $panel-width;
                    height: 345px;
                    .content {
                        height: 100%;
                        background: #ffffff;
                        border: 1px solid #d9e0e5;
                        border-bottom: 0;
                        border-radius: 5px 5px 0 0;
                        box-shadow: 5px 0px 26px rgba(83, 112, 129, 0.15);
                        padding-top: 19px;
                    }
                    .fine-tune-form {
                        width: 100%;
                        overflow-y: auto;
                        padding-left: 19px;
                        padding-right: 19px;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                        grid-gap: 30px;

                        .form-block {
                            display: flex;
                            flex-direction: column;

                            .block-content {
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                    .close-button {
                        position: absolute;
                        top: 1px;
                        left: 40px;
                        z-index: 1100;
                        cursor: pointer;
                        transform: translateY(-100%);
                    }
                    .title {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 29px;
                        margin: 0 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        .heading-buttons {
                            display: flex;
                            justify-content: flex-end;
                            .button {
                                padding: 10px 11px 12px;
                                border: 1px solid #4486ff;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                &:not(:last-child) {
                                    margin-right: 16px;
                                }
                            }
                        }
                    }
                    .subTitle {
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 21px;
                    }
                    .form-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 8px 0;
                        .label {
                            flex: 2;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                        }
                        .value {
                            flex: 1;
                            :global(.input-container:after) {
                                border-bottom: 1px solid #97a2b4;
                            }
                            :global(.input) {
                                font-size: 15px;
                                line-height: 18px;
                            }
                            :global(.input:focus) {
                                border-width: 0;
                            }
                        }
                        :global(.radio) {
                            margin-right: 25px;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                        }
                    }
                    .bottom-buttons {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        z-index: 500;
                        position: relative;
                        border-top: 1px solid #d9e0e5;
                        padding: 8px 0;
                        .deal-buttons {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            position: relative;

                            .link {
                                cursor: pointer;
                                border: none;
                                font-size: 15px;
                                line-height: 18px;
                                color: $accent;
                                background: none;
                                height: 18px;
                                margin-right: 10px;
                                user-select: none;
                                padding-left: 10px;
                                padding-right: 10px;
                            }
                            :global(.load-deal-window) {
                                top: -20px;
                                left: 50%;
                            }
                        }
                        .close-reset-buttons {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            .link {
                                cursor: pointer;
                                border: none;
                                font-size: 15px;
                                line-height: 18px;
                                color: #687893;
                                background: none;
                                height: 18px;
                                margin-right: 10px;
                                user-select: none;
                            }
                            .apply {
                                color: white;
                                background: $accent;
                                padding: 8px 30px;
                                border-radius: 4px;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                cursor: pointer;
                                margin-right: 10px;
                                user-select: none;
                            }
                        }
                    }
                }
                .fine-tune-expanded {
                    width: $panel-width;
                    overflow: visible;
                }
                .tabs {
                    display: inline-flex;
                    justify-content: space-between;
                    background: $white;
                    padding: 13px 19px;
                    .tab {
                        padding-right: 15px;
                        display: block;
                        font-size: 15px;
                        line-height: 18px;
                        color: $dark-gray;
                        position: relative;
                        cursor: pointer;
                        &.active {
                            color: #4485ff;
                            cursor: default;
                            &:before {
                                position: absolute;
                                content: '';
                                width: 100%;
                                height: 3px;
                                background: $blue;
                                bottom: -7px;
                                left: 0;
                                border-radius: 30px 30px 0 0;
                            }
                        }
                    }
                }
            `}</style>
        </PortalWrapper>
    )
})
type PortalWrapperProps = React.PropsWithChildren<any>
export function PortalWrapper(props: PortalWrapperProps) {
    const ref = useRef()
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        ref.current = document.querySelector('#bottom-dock')
        setMounted(true)
    }, [])

    return mounted ? createPortal(props.children, ref.current) : null
}
