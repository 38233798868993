import React from 'react'
import { MixedChart } from './MixedChart'

type Props = {
    data?: any
}

export const CollectionAnalysisChart = (props: Props) => {
    const { data } = props
    const series = [
        {
            name: 'NET RENTAL INCOME',
            data: [],
            type: 'line',
        },
        {
            name: 'ECONOMIC VACANCIES',
            data: [],
            type: 'area',
        },
    ]
    const category = []

    data.map((item, ind) => {
        category.push(item.id)
        series[0].data.push(item.netRental)
        series[1].data.push(item.vacancy * 100)
    })

    return <MixedChart series={series} customCategories={category} enableCustomCategories={true} />
}
