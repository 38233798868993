import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import numeral from 'numeral'
import { OriginationModelReworked } from '@generated/graphql'
import { getTotalUses, mortgagePaymentSchedule } from 'origination-model'

type Props = {
    isFineTuneOn?: boolean
}

export const Debtanalysis = React.memo(function LoanSizing(props: Props) {
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)

    const debtServiceSenior = mortgagePaymentSchedule({
        amortizationTermYears: model?.debtAssumptionSenior?.amortizationTerm,
        ioPeriodYears: model?.debtAssumptionSenior?.ioPeriod,
        loanAmount: model?.sources?.seniorDebt,
        rate: model?.debtAssumptionSenior?.rate,
        unit: 'year',
        periodYears: model?.exitAssumption?.saleYearAnalysisPeriod,
    }).schedule[0]?.total
    const debtServiceMez = mortgagePaymentSchedule({
        amortizationTermYears: model?.debtAssumptionMez?.amortizationTerm,
        ioPeriodYears: model?.debtAssumptionMez?.ioPeriod,
        loanAmount: model?.sources?.mezzanine,
        rate: model?.debtAssumptionMez?.rate,
        unit: 'year',
        periodYears: model?.exitAssumption?.saleYearAnalysisPeriod,
    }).schedule[0]?.total

    const totalUses = useMemo(() => {
        return getTotalUses(model)
    }, [model?.uses])
    return (
        <div className={'loan-sizing'}>
            <WidgetWrapper
                style={{ padding: 0 }}
                title={'Debt Analysis'}
                showProgress={!model || !cashFlow || !settings}
            >
                <div className={'top-grid-block'}>
                    <GreyDataBlock
                        title={'Total Loan Amount'}
                        value={numeral(model?.sources.seniorDebt + model?.sources.mezzanine).format('$0,0')}
                        subValue={''}
                    />
                    <GreyDataBlock
                        title={'Senior Interest Rate'}
                        value={numeral(model?.debtAssumptionSenior?.rate).format('0.[00]%')}
                    />
                    <GreyDataBlock
                        title={'Mezzanine Interest Rate'}
                        value={numeral(model?.debtAssumptionMez?.rate).format('0.[00]%')}
                    />
                    <GreyDataBlock
                        title={'Total Rate'}
                        value={numeral(
                            (debtServiceSenior + debtServiceMez) /
                                (model?.sources.seniorDebt + model?.sources.mezzanine),
                        ).format('0.[00]%')}
                    />
                </div>
                <div className={'sourceTable'}>
                    <table>
                        <thead>
                            <tr>
                                <th>SOURCE</th>
                                <th>AMOUNT</th>
                                <th>{model?.useNCFnotNOI ? 'Net CF / DEBT SERVICE' : 'NOI / DEBT SERVICE'}</th>
                                <th>YEILD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'sourceTitle'}>Total Uses</td>
                                <td className={'price'}>{numeral(totalUses).format('$0,0')}</td>
                                <td className={'price'}>{numeral(cashFlow?.noi?.adjustedT12).format('$0,0')}</td>
                                <td className={'price'}>
                                    {numeral(
                                        cashFlow?.noi?.adjustedT12 /
                                            (model?.sources?.totalEquity +
                                                model?.sources?.seniorDebt +
                                                model?.sources?.mezzanine),
                                    ).format('%0.[00]')}
                                </td>
                            </tr>
                            <tr>
                                <td className={'sourceTitle'}>Senior</td>
                                <td className={'price'}>{numeral(model?.sources?.seniorDebt).format('$0,0')}</td>
                                <td className={'price'}>{numeral(debtServiceSenior).format('$0,0')}</td>
                                <td className={'price'}>
                                    {numeral(cashFlow?.noi?.adjustedT12 / model?.sources?.seniorDebt).format('%0.[00]')}
                                </td>
                            </tr>
                            <tr>
                                <td className={'sourceTitle'}>Mezzanine</td>
                                <td className={'price'}>{numeral(model?.sources?.mezzanine).format('$0,0')}</td>
                                <td className={'price'}>{numeral(debtServiceMez).format('$0,0')}</td>
                                <td className={'price'}>
                                    {numeral(
                                        model?.sources?.mezzanine > 0
                                            ? cashFlow?.noi?.adjustedT12 /
                                                  (model?.sources?.mezzanine + model?.sources?.seniorDebt)
                                            : 0,
                                    ).format('%0.[00]')}
                                </td>
                            </tr>
                            <tr>
                                <td className={'sourceTitle'}>Total Equity</td>
                                <td className={'price'}>{numeral(model?.sources?.totalEquity).format('$0,0')}</td>
                                <td className={'price'}>
                                    {numeral(cashFlow?.noi?.adjustedT12 - debtServiceSenior - debtServiceMez).format(
                                        '$0,0',
                                    )}
                                </td>
                                <td className={'price'}>
                                    {numeral(
                                        (cashFlow?.noi?.adjustedT12 - debtServiceSenior - debtServiceMez) /
                                            model?.sources?.totalEquity,
                                    ).format('%0.[00]')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </WidgetWrapper>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .loan-sizing {
                    :global(.widget-container) {
                        height: 344px;
                    }
                    :global(.widget-body) {
                        display: flex;
                        flex-direction: column;
                        .top-grid-block {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            grid-template-rows: 1fr;
                            grid-gap: 20px;
                            margin-bottom: 20px;

                            @media screen and (max-width: 1440px) {
                                grid-template-columns: ${props.isFineTuneOn ? '1fr 1fr' : '1fr 1fr 1fr 1fr'};
                                grid-template-rows: ${props.isFineTuneOn ? '1fr 1fr' : '1fr'};
                            }
                        }
                        .grid-block {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            grid-template-rows: auto;
                            grid-gap: 20px;
                            &:not(:last-child) {
                                @media screen and (max-width: 1440px) {
                                    grid-template-columns: ${props.isFineTuneOn ? '1fr 1fr' : '1fr 1fr 1fr 1fr'};
                                    grid-template-rows: ${props.isFineTuneOn ? '1fr 1fr' : '1fr'};
                                }
                            }
                        }
                    }
                }
                .sourceTable table {
                    width: 100%;
                }
                tbody tr:nth-child(odd) {
                    background-color: rgba(217, 224, 229, 0.15);
                }
                tr {
                    height: 35px;
                }
                th {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #676f7a;
                    &:last-child {
                        width: 100px;
                    }
                    &:not(:first-child) {
                        text-align: right;
                    }
                }
                .price {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: right;
                    width: 10%;
                    color: #161c26;
                }
                .sourceTitle {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: #262b35;
                    width: 10%;
                }
                .title {
                    width: 100%;
                    padding-bottom: 10px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: #161c26;
                    border-bottom: 1px solid #d9e0e5;
                }
            `}</style>
        </div>
    )
})
type GrayBarProps = {
    title: string
    value: string
    subValue?: string
    style?: React.CSSProperties
}
function GreyDataBlock(props: GrayBarProps) {
    return (
        <div className={'grey-bar'} style={props.style}>
            <div className={'title'}>{props.title}</div>
            <div className={'value'}>{props.value}</div>
            <div className={'subValue'}>{props.subValue}</div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .grey-bar {
                        background: #f5f6f9;
                        border-radius: 4px;
                        height: 109px;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 15px;
                        .title {
                            font-size: 14px;
                            line-height: 18px;
                            color: #97a2b4;
                        }
                        .value {
                            font-weight: 500;
                            font-size: 21px;
                            line-height: 27px;
                            color: $default-text-color;
                            margin-top: 5px;
                        }
                        .subValue {
                            font-size: 15px;
                            line-height: 18px;
                            color: #687893;
                        }
                    }
                `}
            </style>
        </div>
    )
}
type DataBlockProps = {
    title: string
    value: string
}
function DataBlock(props: DataBlockProps) {
    return (
        <div className={'data-block'}>
            <div className={'title'}>{props.title}</div>
            <div className={'value'}>{props.value}</div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .data-block {
                    flex-grow: 1;
                    .title {
                        font-size: 13px;
                        line-height: 17px;
                        color: #97a2b4;
                    }
                    .value {
                        font-weight: 500;
                        font-size: 21px;
                        line-height: 25px;
                        color: $default-text-color;
                    }
                }
            `}</style>
        </div>
    )
}
