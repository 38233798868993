import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import numeral from 'numeral'
import { ExpensesGauge } from './ExpencesGauge'
import { ExpensesBulletChart } from './ExpensesBulletChart'
import { Spinner } from '../../../../../../controls/Spinner'
import { CashFlowRowGraphQl } from '@generated/graphql'
import { orgenizeCFForPresentation } from 'origination-model'

type Props = any
const numberOfSteps = 9
export function ExpensesByCategory(props: Props) {
    const numOfUnits = useSelector((state: ReduxStoreState) => state.lender.originationModel?.numberOfUnits)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const storCashFlow = useSelector((store: ReduxStoreState) => store.lender.originationModel?.cashFlow)
    const cashflow = useMemo(() => {
        return orgenizeCFForPresentation({
            cashFlow: storCashFlow,
        })
    }, [storCashFlow])
    const expenses: Array<CashFlowRowGraphQl & { avg?: number }> = cashflow?.operatingExpenses || []
    const maxExpense = expenses.reduce((m: number, item) => {
        let max = 0
        for (let i = 0; i <= item.years.length; i++) {
            const v = Math.abs(numeral(item.years[i]).value()) || 0
            max = max < v ? v : max
        }
        return m < max ? max : m
    }, 0)
    let total = 0
    for (const exp of expenses) {
        let avg = 0
        for (let i = 0; i <= exp.years.length; i++) {
            let v = numeral(exp.years[i]).value()
            v = Math.abs(v)
            avg += v
        }
        exp.avg = avg / exp.years.length
        total += exp.avg
    }
    // calc step
    let step = 0

    const modifier = [5, 1, 1.5, 2, 3]
    let multiplier = 100
    let k = 0

    do {
        step = modifier[k] * multiplier
        k++
        if (k == 1) {
            multiplier = multiplier * 10
        }
        if (k == modifier.length) {
            k = 0
        }
    } while (step * numberOfSteps < maxExpense)

    if (step == 0) {
        step = maxExpense / numberOfSteps
    }
    // normilize step

    return (
        <>
            <div className={'card-wrapper'}>
                <div className={'card-title'}>
                    Expenses by Category
                    <div className="legend">
                        <span className={'average'}>
                            <span className={'circle'} />
                            Average
                        </span>
                        <span className={'range'}>
                            <span className={'circle'} />
                            Range
                        </span>
                    </div>
                </div>
                {expenses.length > 0 ? (
                    <table className={'chart-table'} cellSpacing={0} cellPadding={0}>
                        <thead>
                            <tr>
                                <th className={'table-header title-header'}>CATEGORY</th>
                                <th className={'table-header'}>AVG/UNIT</th>
                                <th className={'table-header cell-border-right'}>% OF TOTAL</th>
                                <th className={'table-header'} style={{ width: '60%' }}>
                                    <ExpensesGauge step={step} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {expenses.map((row, i) => {
                                let value = 0
                                if (project?.accountType == 'borrower') {
                                    value = numeral(row.years[0]).value()
                                } else {
                                    value = row.adjustedT12
                                }
                                const avg = value / numOfUnits
                                return (
                                    <tr key={`${i}`}>
                                        <td className={'row-title'}>{row.title}</td>
                                        <td className={'cell-value'}>{numeral(avg).format('$0,0')}</td>
                                        <td className={'cell-value cell-border-right'}>
                                            {numeral(row.avg / total).format('0.(0)%')}
                                        </td>
                                        <td style={{ position: 'relative' }}>
                                            <ExpensesBulletChart total={step * numberOfSteps} data={row} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className={'preloader'}>
                        <Spinner />
                    </div>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .card-wrapper {
                        width: 100%;
                        margin: 0;
                        border: 1px solid $border;
                        background-color: $white;
                        border-radius: 3px;
                        margin-bottom: 20px;
                        .card-title {
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                            padding: 10px 20px;
                            position: relative;
                            .legend {
                                position: absolute;
                                right: 20px;
                                top: 14px;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                color: $dark-gray;
                                .circle {
                                    width: 12px;
                                    height: 12px;
                                    display: inline-block;
                                    border-radius: 6px;
                                    margin-right: 9px;
                                }
                            }
                            .legend > span:nth-child(1) {
                                .circle {
                                    background-color: $light-blue;
                                }
                                margin-right: 33px;
                            }
                            .legend > span:nth-child(2) {
                                .circle {
                                    background-color: rgba($light-blue, 0.3);
                                }
                            }
                        }
                        .chart-table {
                            width: 100%;
                            margin: 23px 3px 18px 3px;
                        }

                        .chart-table > tbody > tr:nth-child(2n + 1) {
                            background-color: rgba($light-gray, 0.2);
                            border-spacing: 0;
                        }
                        .chart-table > tbody td {
                            padding: 8px 0;
                        }
                        .chart-table > tbody {
                            position: relative;
                        }
                        .table-header {
                            font-size: 12px;
                            line-height: 15px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            color: $gray-nevada;
                            font-weight: 400;
                            padding-bottom: 13px;
                        }
                        .table-header:nth-child(2),
                        .table-header:nth-child(3) {
                            text-align: right;
                        }
                        td.row-title {
                            font-size: 15px;
                            line-height: 18px;
                            font-weight: normal;
                            color: $dark-gray-charade;
                            padding-left: 16px !important;
                        }
                        .cell-value {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 18px;
                            text-align: right;
                            color: $dark-gray-charade;
                        }
                        .title-header {
                            padding-left: 16px;
                        }
                        .cell-border-right {
                            border-right: 1px solid $gray-mystic;
                            padding-right: 30px !important;
                        }
                        .preloader {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 200px;
                        }
                    }
                `}
            </style>
        </>
    )
}
