import React from 'react'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'

type Props = any

export function PropertyImage(props: Props) {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    return (
        <WidgetWrapper title={'Property Image'}>
            <div style={{ textAlign: 'center' }}>
                {project?.filePath && <img className="image " src={project?.filePath} />}
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    .image {
                        width: auto;
                        height: 200px;
                    }
                `}
            </style>
        </WidgetWrapper>
    )
}
