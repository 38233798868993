import React, { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import dateFormat from 'dateformat'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { ProjectGraphQl } from '@generated/graphql'
import { ModalLayout } from '../../../../ModalLayout'
import { Spinner } from '../../../../controls/Spinner'
import { SaveNarrativeVersion } from './SaveNarrativeVersion'

type Props = {
    project: ProjectGraphQl
    close: () => void
}

export function VersionHistoryModal(props: Props) {
    const [versionsLoading, setVersionsLoading] = useState(false)
    const [versions, setVersions] = useState(null)
    const [selectedVersion, setSelectedVersion] = useState(null)
    const [currentMainVersionId, setCurrentMainVersionId] = useState(null)
    const [showLoadVersionModal, setShowLoadVersionModal] = useState(false)
    const [showCopies, setShowCopies] = useState(true)
    const [defaultNarrativeId, setDefaultNarrativeId] = useState(null)

    const router = useRouter()

    useEffect(() => {
        const getDefaultNarrativeId = async () => {
            return await SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                projectId: props.project.id,
            })
        }
        if (!!props.project) {
            if (props.project.defaultNarrativeId === 0 || !!props.project.defaultNarrativeId) {
                setDefaultNarrativeId(props.project.defaultNarrativeId)
            } else {
                getDefaultNarrativeId().then((narrativeId) => {
                    if (!!narrativeId) setDefaultNarrativeId(narrativeId)
                })
            }
        }
    }, [props.project])

    useEffect(() => {
        async function fetchVersions() {
            setVersionsLoading(true)
            const versionsResponse = await SagaHelper.run(['lenderProjects', 'getNarrativeVersions'], {
                projectId: props.project.id,
            })
            setVersionsLoading(false)

            if (!!versionsResponse.length) {
                setCurrentMainVersionId(versionsResponse.find((version) => version.isMainVersion)?.versionId || null)
                setVersions(versionsResponse)
            }
        }

        if (props.project.id === 0 || !!props.project.id) {
            fetchVersions()
        }
    }, [props.project.id])

    const loadVersion = useCallback(
        (narrativeIdToLoad: number, nameForCopy: string, descriptionForCopy: string, dateForCopy: string) => {
            async function loadVersionFn() {
                setVersionsLoading(true)
                const response = await SagaHelper.run(['lenderProjects', 'loadNarrativeVersion'], {
                    projectId: props.project.id,
                    currentNarrativeId: currentMainVersionId,
                    narrativeIdToLoad,
                    nameForCopy,
                    descriptionForCopy,
                    dateForCopy,
                    defaultNarrativeId,
                })

                if (!!response) {
                    router.push(
                        `/dealnarrative/report?id=${props.project.originalProjectId}&narrativeId=${narrativeIdToLoad}`,
                    )
                } else {
                    setVersionsLoading(false)
                }
            }

            if (
                (props.project.id === 0 || !!props.project.id) &&
                (currentMainVersionId === 0 || !!currentMainVersionId) &&
                (defaultNarrativeId === 0 || !!defaultNarrativeId)
            ) {
                loadVersionFn()
            }
        },
        [props.project, currentMainVersionId, defaultNarrativeId],
    )

    const showVersion = useCallback(
        (narrativeId: number) => {
            if (
                (props.project.originalProjectId === 0 || !!props.project.originalProjectId) &&
                (narrativeId === 0 || !!narrativeId)
            ) {
                window.open(
                    `/dealnarrative/report?id=${props.project.originalProjectId}&narrativeId=${narrativeId}`,
                    '_blank',
                )
            }
        },
        [props.project.originalProjectId],
    )

    const handleCloseClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        props.close()
    }

    const handleDotsClick = (version) => {
        if (selectedVersion?.versionId === version.versionId) {
            setSelectedVersion(null)
        } else {
            setSelectedVersion(version)
        }
    }

    const handleLoadVersionModalClose = () => {
        setShowLoadVersionModal(false)
    }

    const handleCheckboxChange = (e) => {
        setShowCopies(e.target.checked)
    }

    return (
        <ModalLayout title={`Versions History (${props.project.name || props.project.address})`}>
            {versionsLoading ? (
                <Spinner />
            ) : (
                <>
                    <div className="row checkbox-row">
                        <label>
                            <input type="checkbox" checked={showCopies} onChange={handleCheckboxChange} />
                            <span className="checkbox-label">Show copies</span>
                        </label>
                    </div>
                    <div className="row headers">
                        <div className={'col-1'}>ID</div>
                        <div className={'col-3'}>Version Name</div>
                        <div className={'col-4'}>Description</div>
                        <div className={'col-2'}>Created by</div>
                        <div className="col-1">Date</div>
                    </div>
                    {!!versions && (
                        <div className="versions-wrapper">
                            {versions.map((version) => (
                                <React.Fragment key={version.versionId}>
                                    {(showCopies || !version.isCopy) && (
                                        <div className="row version-row" key={version.versionId}>
                                            <div className="col-1">{version.versionId}</div>
                                            <div className={'col-3'} style={{ position: 'relative' }}>
                                                {version.isMainVersion && (
                                                    <div style={{ fontSize: 30, position: 'absolute', left: '-2px' }}>
                                                        &#183;
                                                    </div>
                                                )}
                                                {version.name}
                                                {version.versionId === defaultNarrativeId
                                                    ? !!version.name
                                                        ? version.name.search(/default/i) === -1
                                                            ? ' (default)'
                                                            : ''
                                                        : 'Default'
                                                    : null}
                                                {version.isMainVersion && ' - loaded for changes'}
                                            </div>
                                            <div className={'col-4'}>{version.description}</div>
                                            <div className={'col-2'}>{version.userName || '-'}</div>
                                            <div className="col-1">
                                                {!!version.date &&
                                                    dateFormat(new Date(+version.date), 'mm/dd/yyyy HH:MM:ss')}
                                            </div>
                                            <div className="col-1 submenu">
                                                <div className="icon-wrapper" onClick={() => handleDotsClick(version)}>
                                                    <i className="material-icons" style={{ fontSize: 30 }}>
                                                        more_vert
                                                    </i>
                                                </div>
                                            </div>
                                            {selectedVersion?.versionId === version.versionId && (
                                                <div className="popover">
                                                    <div
                                                        className="popover-item-1"
                                                        onClick={() => {
                                                            setShowLoadVersionModal(true)
                                                        }}
                                                    >
                                                        Set Main Version
                                                    </div>
                                                    <div
                                                        className="popover-item-2"
                                                        onClick={() => showVersion(version.versionId)}
                                                    >
                                                        Show Version
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}{' '}
                                </React.Fragment>
                            ))}
                        </div>
                    )}

                    <div className="row buttons-row">
                        <div className="col-12 d-flex justify-content-end lender-control-buttons">
                            <button className="btn-blue btn btn-primary ghost" onClick={handleCloseClick}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    {showLoadVersionModal && !!selectedVersion && (
                        <div className="save-version-modal">
                            <SaveNarrativeVersion
                                project={props.project}
                                close={handleLoadVersionModalClose}
                                isCopy
                                version={selectedVersion}
                                customSubmitFn={loadVersion}
                            />
                        </div>
                    )}
                </>
            )}

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .headers {
                    font-weight: 700;
                    margin-bottom: 20px;
                }
                .version-row {
                    margin-bottom: 10px;
                    position: relative;
                    div {
                        display: flex;
                        align-items: center;
                    }
                    div.popover-item-1,
                    div.popover-item-2 {
                        display: flex;
                        align-items: start;
                        width: 100%;
                        padding: 10px;
                        &:hover {
                            background: $border;
                        }
                    }
                    &:hover {
                        background: $border;
                        cursor: pointer;
                    }
                }
                .icon-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    flex: 1;

                    &:hover {
                        cursor: pointer;
                    }
                }
                .popover {
                    width: 135px;
                    background-color: $background;
                    position: absolute;
                    left: calc(100% - 175px);
                    white-space: nowrap;
                    flex-direction: column;
                    cursor: pointer;
                }
                .popover-item-1 {
                    padding-bottom: 5px;
                }
                .popover-item-2 {
                    padding-top: 5px;
                }
                .submenu {
                    position: relative;
                }
                :global(.lender-modal-layout) {
                    :global(.lender-modal) {
                        max-height: 80vh;
                        max-width: 80vw;
                        width: 2000px !important;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                    }
                }
                :global(.lender-modal-content) {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                }
                .save-version-modal {
                    :global(.lender-modal-layout) {
                        :global(.lender-modal) {
                            width: 550px !important;
                        }
                    }
                }
                .versions-wrapper {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    overflow: scroll;
                    padding-bottom: 50px;
                }
                :global(.row) {
                    margin-right: 0px;
                    margin-left: 0px;
                }
                .checkbox-row {
                    margin-bottom: 20px;
                    label {
                        display: flex;
                        gap: 10px;
                        font-size: 16px;
                        .checkbox-label {
                            line-height: 20px;
                        }
                    }
                }
                .versions-wrapper::-webkit-scrollbar {
                    display: none;
                }
                .versions-wrapper {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
            `}</style>
        </ModalLayout>
    )
}
