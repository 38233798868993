import React from 'react'
import numeral from 'numeral'
type Props = {
    step: number
}

export function ExpensesGauge(props: Props) {
    return (
        <>
            <div className={'gauge'}>
                {[...Array(10)].map((x, i) => (
                    <div key={`${i}`} className={'gauge-item'}>
                        {numeral(props.step * i).format('$0,0.[0]a')}
                    </div>
                ))}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .gauge {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .gauge-item {
                    width: 10%;
                    text-align: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: $dark-gray;
                }
            `}</style>
        </>
    )
}
