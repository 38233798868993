import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    getRentRollGroupedByWeek,
    getAvgWeeklyMoveIns,
    getNumOfWeeksToReachFullCO,
    getConcession,
    getOtherRentalLosses,
    getSumOfOtherIncome,
    getExpenses,
    getFutureRentRollEarliestWeek,
    getHistoricalCumulativeMoveInsAmount,
    getProjectionData,
    getDefaultProjectionStartWeek,
    getAllUnitsAvailableWeek,
    getRateLockItem,
    getStabilizationItem,
} from 'origination-model'
import { ReduxStoreState, ReduxHelper } from 'store'
import { NoLeaseUpScreen } from './NoLeaseUpScreen'
import { CreateLeaseUpModal } from './CreateLeaseUpModal'
import { Spinner } from 'components/controls/Spinner'
import { showErrorNotification } from 'components/pages/fullproject/notifications'
import { SagaHelper } from 'store/sagaHelper'
import { StabilizationProjection } from './StabilizationProjection'
import { RateLock } from './RateLock'
import { HistoricalMoveIns } from './HistoricalMoveIns'
import { StabilizationKPIs } from './StabilizationKPIs'
import { ProjectionChart } from './Graph/ProjectionChart'
import { NewLeaseUpModal } from './NewLeaseUpModal'
import { getDateString } from './utils/dates'
import { isEmpty } from 'lodash'

export const LeaseUpPage = () => {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const leaseUpData = useSelector((state: ReduxStoreState) => state.lender.leaseUp)
    const rentRoll = useSelector((store: ReduxStoreState) => store.operatingAssumptions.rentRoll)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)

    const leaseUp = leaseUpData?.data
    const submarketData = leaseUpData?.submarketData
    const futureRentRoll = leaseUpData?.futureRentRoll

    const rentRollDate = useMemo(() => {
        // @ts-ignore
        return model?.rentRollTab?.fileDate || null
    }, [model])

    const defaultProjectionStartDate = useMemo(() => {
        return rentRollDate ? new Date(rentRollDate) : new Date()
    }, [model, rentRollDate])

    useEffect(() => {
        if (leaseUp && leaseUp.defaultProjectionStartDate !== defaultProjectionStartDate) {
            ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'defaultProjectionStartDate'], defaultProjectionStartDate)
        }
    }, [defaultProjectionStartDate, leaseUp])

    const selectedPricingOption =
        ((leaseUp?.pricingOptionId || leaseUp?.pricingOptionId === 0) &&
            pricing.fanniePricing.pricingOptions.find((option) => option.id === leaseUp.pricingOptionId)) ||
        null

    const firstPricingOption = pricing?.fanniePricing?.pricingOptions?.[0] || null

    const defaultStabilizationRate = useMemo(() => {
        const concessions =
            submarketData?.concessions?.submarket?.mean || submarketData?.concessions?.market?.mean || null
        const vacancy = submarketData?.vacancy?.submarket?.mean || submarketData?.vacancy?.market?.mean || null
        if (concessions && vacancy) {
            const value = 1 - (vacancy / 100 + concessions / 12)
            if (value > 0.75) {
                return value
            } else {
                return 0.95
            }
        } else {
            return 0.95
        }
    }, [submarketData, leaseUp])

    useEffect(() => {
        if (leaseUp && leaseUp.defaultStabilizationRate !== defaultStabilizationRate) {
            ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'defaultStabilizationRate'], defaultStabilizationRate)
        }
    }, [defaultStabilizationRate, leaseUp])

    const loadLeaseUpSubmarketData = useCallback(async (projectId, model) => {
        try {
            const yearBuilt = model.yearRenovated || model.yearBuilt || 0
            SagaHelper.run(['lenderLeaseUp', 'getLeaseUpSubmarketData'], {
                projectId,
                yearBuilt,
            })
        } catch (e) {
            showErrorNotification(e.message)
        }
    }, [])

    useEffect(() => {
        if (project?.id && model && !submarketData && (leaseUp?.id || leaseUp?.id === 0)) {
            loadLeaseUpSubmarketData(project.id, model)
        }
    }, [project?.id, model, leaseUp?.id, submarketData])

    const loadFutureRentRoll = useCallback(async (projectId) => {
        try {
            SagaHelper.run(['lenderLeaseUp', 'getFutureRentRoll'], {
                projectId,
            })
        } catch (e) {
            showErrorNotification(e.message)
        }
    }, [])

    useEffect(() => {
        if (project?.id && !futureRentRoll) {
            loadFutureRentRoll(project.id)
        }
    }, [project?.id, leaseUp?.id, futureRentRoll])

    // move-ins
    const rentRollGroupedByLeaseStartDate = useMemo(() => getRentRollGroupedByWeek(rentRoll, 'leaseStart'), [rentRoll])

    const [showNoHistoricalError, setShowNoHistoricalError] = useState(false)
    useEffect(() => {
        if (!rentRollGroupedByLeaseStartDate || isEmpty(rentRollGroupedByLeaseStartDate)) {
            setShowNoHistoricalError(true)
        } else if (showNoHistoricalError) {
            setShowNoHistoricalError(false)
        }
    }, [rentRollGroupedByLeaseStartDate])

    // move-outs
    const rentRollGroupedByLeaseExpireDate = useMemo(
        () => getRentRollGroupedByWeek(rentRoll, 'leaseExpire'),
        [rentRoll],
    )

    // future move-ins
    const futureRentRollByLeaseStartDate = useMemo(
        () => getRentRollGroupedByWeek(futureRentRoll, 'leaseStart'),
        [futureRentRoll],
    )

    // future move-outs
    const futureRentRollByLeaseExpireDate = useMemo(
        () => getRentRollGroupedByWeek(futureRentRoll, 'leaseExpire'),
        [futureRentRoll],
    )

    const futureRentRollEarliestWeek = useMemo(
        () => getFutureRentRollEarliestWeek(futureRentRollByLeaseStartDate, futureRentRollByLeaseExpireDate),
        [futureRentRollByLeaseStartDate, futureRentRollByLeaseExpireDate],
    )

    const avgWeeklyMoveIns = useMemo(
        () => getAvgWeeklyMoveIns(rentRollGroupedByLeaseStartDate),
        [rentRollGroupedByLeaseStartDate],
    )

    const defaultProjectionStartWeek = useMemo(
        () => getDefaultProjectionStartWeek(defaultProjectionStartDate),
        [defaultProjectionStartDate],
    )

    const allUnitsAvailableWeek = useMemo(
        () => getAllUnitsAvailableWeek(leaseUp?.allUnitsAvailableDate),
        [leaseUp?.allUnitsAvailableDate],
    )

    const numberOfUnits = useMemo(() => model?.numberOfUnits || null, [])

    const weeksToReachFullCO = useMemo(
        () => getNumOfWeeksToReachFullCO(allUnitsAvailableWeek, defaultProjectionStartWeek),
        [allUnitsAvailableWeek, defaultProjectionStartWeek],
    )

    const GPR = useMemo(() => {
        return model?.t12AdjustmentsIncome?.find((item) => item.id === 'grossPotentialRent')?.value || null
    }, [model])

    const concession = useMemo(
        () => getConcession(model?.cashFlow?.rentalLoss, GPR),
        [model?.cashFlow?.rentalLoss, GPR],
    )

    const otherRentalLosses = useMemo(
        () => getOtherRentalLosses(model?.cashFlow?.rentalLoss),
        [model?.cashFlow?.rentalLoss],
    )

    const sumOfOtherIncome = useMemo(
        () => getSumOfOtherIncome(model?.cashFlow?.otherIncome),
        [model?.cashFlow?.rentalLoss],
    )

    const expenses = useMemo(() => getExpenses(model?.cashFlow), [model?.cashFlow])

    const historicalCumulativeMoveInsAmount = useMemo(
        () =>
            getHistoricalCumulativeMoveInsAmount(
                rentRollGroupedByLeaseStartDate,
                rentRollGroupedByLeaseExpireDate,
                futureRentRollByLeaseStartDate,
                futureRentRollByLeaseExpireDate,
            ),
        [
            rentRollGroupedByLeaseStartDate,
            rentRollGroupedByLeaseExpireDate,
            futureRentRollByLeaseStartDate,
            futureRentRollByLeaseExpireDate,
        ],
    )

    const projectionData = useMemo(
        () =>
            getProjectionData(
                defaultProjectionStartWeek,
                leaseUp,
                numberOfUnits,
                allUnitsAvailableWeek,
                rentRollGroupedByLeaseExpireDate,
                weeksToReachFullCO,
                defaultStabilizationRate,
                GPR,
                concession,
                otherRentalLosses,
                sumOfOtherIncome,
                expenses,
                selectedPricingOption,
                firstPricingOption,
                futureRentRollByLeaseExpireDate,
                futureRentRollByLeaseStartDate,
                historicalCumulativeMoveInsAmount,
            ),
        [
            defaultProjectionStartWeek,
            leaseUp,
            numberOfUnits,
            allUnitsAvailableWeek,
            rentRollGroupedByLeaseExpireDate,
            weeksToReachFullCO,
            defaultStabilizationRate,
            GPR,
            concession,
            otherRentalLosses,
            sumOfOtherIncome,
            expenses,
            selectedPricingOption,
            futureRentRollByLeaseExpireDate,
            futureRentRollByLeaseStartDate,
            historicalCumulativeMoveInsAmount,
            firstPricingOption
        ],
    )

    const [showReachAllUnitsAvailableDateError, setShowReachAllUnitsAvailableDateError] = useState(false)
    useEffect(() => {
        if (weeksToReachFullCO && projectionData?.length && projectionData.length < weeksToReachFullCO) {
            setShowReachAllUnitsAvailableDateError(true)
        } else if (showReachAllUnitsAvailableDateError) {
            setShowReachAllUnitsAvailableDateError(false)
        }
    }, [projectionData, weeksToReachFullCO])

    const [showTotalUnitsError, setShowTotalUnitsError] = useState(false)
    useEffect(() => {
        if (
            numberOfUnits &&
            projectionData?.length &&
            projectionData.every((elem) => elem.totalUnitsCos !== numberOfUnits)
        ) {
            setShowTotalUnitsError(true)
        } else if (showReachAllUnitsAvailableDateError) {
            setShowTotalUnitsError(false)
        }
    }, [projectionData, numberOfUnits])

    const [showHighOccupancyError1, setShowHighOccupancyError1] = useState(false)
    const [showHighOccupancyError2, setShowHighOccupancyError2] = useState(false)

    useEffect(() => {
        if (projectionData?.length && projectionData.some((item) => item.projectedOccupancyOnTotalUnitsCo > 1)) {
            setShowHighOccupancyError2(true)
        } else {
            setShowHighOccupancyError2(false)
        }
    }, [projectionData])

    const [showCreateLeaseUpModal, setShowCreateLeaseUpModal] = useState(false)
    const [leaseUpLoading, setLeaseUpLoading] = useState(false)

    const loadLeaseUp = useCallback(async (projectId) => {
        setLeaseUpLoading(true)
        try {
            await SagaHelper.run(['lenderLeaseUp', 'getLeaseUp'], {
                projectId,
            })
        } catch (e) {
            showErrorNotification(e.message)
        } finally {
            setLeaseUpLoading(false)
        }
    }, [])

    useEffect(() => {
        if (project?.id && !leaseUp) {
            loadLeaseUp(project.id)
        }
    }, [project?.id, leaseUp])

    const [rentRollLoading, setRentRollLoading] = useState(false)

    const loadRentRoll = useCallback(async (projectId) => {
        setRentRollLoading(true)
        try {
            await SagaHelper.run(['rentRoll', 'loadRentRoll'], { projectId })
        } catch (e) {
            showErrorNotification(e.message)
        } finally {
            setRentRollLoading(false)
        }
    }, [])

    useEffect(() => {
        if (project?.id && !rentRoll) {
            loadRentRoll(project.id)
        }
    }, [project?.id, leaseUp?.id])

    const rateLockItem = useMemo(() => getRateLockItem(projectionData), [projectionData])

    const stabilizationItem = useMemo(
        () => getStabilizationItem(defaultStabilizationRate, projectionData, leaseUp),
        [defaultStabilizationRate, projectionData, leaseUp],
    )

    const [historicalWeeksForGraph, setHistoricalWeeksForGraph] = useState([])
    const [historicalOccupancyForGraph, setHistoricalOccupancyForGraph] = useState([])

    const hiddenHistoricalRows = useMemo(() => {
        if (leaseUp?.projectionCustomDate && historicalWeeksForGraph?.length) {
            const projectionCustomDate = new Date(leaseUp.projectionCustomDate)
            let hiddenRowsCount = 0
            historicalWeeksForGraph.forEach((week) => {
                if (+week < projectionCustomDate.getTime()) hiddenRowsCount++
            })
            return hiddenRowsCount
        } else {
            return 0
        }
    }, [leaseUp?.projectionCustomDate, historicalWeeksForGraph])

    const hiddenProjectionRows = useMemo(() => {
        if (leaseUp?.projectionCustomDate && projectionData?.length) {
            const projectionCustomDate = new Date(leaseUp.projectionCustomDate)
            let hiddenRowsCount = 0
            projectionData.forEach((item) => {
                if (+item.week < projectionCustomDate.getTime()) hiddenRowsCount++
            })
            return hiddenRowsCount
        } else {
            return 0
        }
    }, [leaseUp?.projectionCustomDate, projectionData])

    const graphCategories = useMemo(() => {
        if (projectionData?.length) {
            const projectionWeeks = projectionData.map((item) => getDateString(item.week))
            if (!historicalWeeksForGraph?.length) {
                return projectionWeeks
            } else {
                const historicalWeeks = historicalWeeksForGraph.map((week) => getDateString(+week))
                return [...historicalWeeks.slice(hiddenHistoricalRows), ...projectionWeeks.slice(hiddenProjectionRows)]
            }
        } else {
            return []
        }
    }, [historicalWeeksForGraph, projectionData, hiddenHistoricalRows, hiddenProjectionRows])

    const graphSeries = useMemo(() => {
        if (projectionData?.length) {
            const projectionOccupancy = projectionData.map((item) => item.projectedOccupancyOnTotalUnitsCo)
            if (!historicalOccupancyForGraph?.length) {
                return projectionOccupancy
            } else {
                return [
                    ...historicalOccupancyForGraph.slice(hiddenHistoricalRows),
                    ...projectionOccupancy.slice(hiddenProjectionRows),
                ]
            }
        } else {
            return []
        }
    }, [historicalOccupancyForGraph, projectionData, hiddenHistoricalRows, hiddenProjectionRows])

    const rateLockWeekForGraph = useMemo(() => {
        if (rateLockItem) {
            return getDateString(+rateLockItem.week)
        } else {
            return null
        }
    }, [rateLockItem])

    const stabilizationForGraph = useMemo(() => {
        if (stabilizationItem) {
            return stabilizationItem.projectedOccupancyOnTotalUnitsCo
        } else {
            return null
        }
    }, [stabilizationItem])

    const projectionStartForGraph = useMemo(() => {
        if (projectionData?.length) {
            return projectionData[0].projectedOccupancyOnTotalUnitsCo
        } else {
            return null
        }
    }, [projectionData])

    const [showGraph, setShowGraph] = useState(false)

    const [newLeaseUpModalVisible, setNewLeaseUpModalVisible] = useState(false)

    return (
        <div className="page-wrapper">
            {!leaseUpLoading && !rentRollLoading && leaseUp && (
                <div className="new-lease-up-btn-wrapper">
                    <div onClick={() => setNewLeaseUpModalVisible(true)} className={'new-lease-up-btn'}>
                        New Lease-Up
                    </div>
                </div>
            )}
            {!leaseUpLoading && !rentRollLoading && !leaseUp && (
                <>
                    <NoLeaseUpScreen
                        setShowCreateLeaseUpModal={setShowCreateLeaseUpModal}
                        rentRoll={rentRoll}
                        rentRollDate={rentRollDate}
                    />
                    {showCreateLeaseUpModal && (
                        <CreateLeaseUpModal
                            close={() => {
                                setShowCreateLeaseUpModal(false)
                            }}
                            avgWeeklyMoveIns={avgWeeklyMoveIns}
                            rentRollDate={rentRollDate}
                        />
                    )}
                </>
            )}
            {!leaseUpLoading && !rentRollLoading && leaseUp && (
                <>
                    <p className="section-header">RATE LOCK</p>
                    <RateLock
                        rateLockItem={rateLockItem}
                        allUnitsAvailableDate={leaseUp?.allUnitsAvailableDate}
                        stabilizationItem={stabilizationItem}
                        rentRollDate={rentRollDate}
                    />
                    <p className="section-header">STABILIZATION</p>
                    <StabilizationKPIs stabilizationItem={stabilizationItem} />
                    <StabilizationProjection
                        avgWeeklyMoveIns={avgWeeklyMoveIns}
                        rentRollGroupedByLeaseStartDate={rentRollGroupedByLeaseStartDate}
                        rentRollByLeaseExpireDate={rentRollGroupedByLeaseExpireDate}
                        numberOfUnits={numberOfUnits}
                        projectionData={projectionData}
                        weeksToReachFullCO={weeksToReachFullCO}
                        GPR={GPR}
                        concession={concession}
                        otherRentalLosses={otherRentalLosses}
                        sumOfOtherIncome={sumOfOtherIncome}
                        expenses={expenses}
                        selectedPricingOption={selectedPricingOption}
                        futureRentRollEarliestWeek={futureRentRollEarliestWeek}
                        futureRentRollByLeaseStartDate={futureRentRollByLeaseStartDate}
                        futureRentRollByLeaseExpireDate={futureRentRollByLeaseExpireDate}
                        rateLockItem={rateLockItem}
                        stabilizationItem={stabilizationItem}
                        currentWeek={defaultProjectionStartWeek}
                        showNoHistoricalError={showNoHistoricalError}
                        showReachAllUnitsAvailableDateError={showReachAllUnitsAvailableDateError}
                        setHistoricalWeeksForGraph={setHistoricalWeeksForGraph}
                        setHistoricalOccupancyForGraph={setHistoricalOccupancyForGraph}
                        showTotalUnitsError={showTotalUnitsError}
                        showGraph={showGraph}
                        setShowGraph={setShowGraph}
                        setShowHighOccupancyError1={setShowHighOccupancyError1}
                        showHighOccupancyError1={showHighOccupancyError1}
                        showHighOccupancyError2={showHighOccupancyError2}
                        firstPricingOption={firstPricingOption}
                    />
                    {showGraph && (
                        <div style={{ width: '100%', maxWidth: 2500, background: '#fff', padding: '0px 20px' }}>
                            <ProjectionChart
                                graphCategories={graphCategories}
                                graphSeries={graphSeries}
                                rateLockWeekForGraph={rateLockWeekForGraph}
                                stabilizationForGraph={stabilizationForGraph}
                                projectionStartForGraph={projectionStartForGraph}
                            />
                        </div>
                    )}
                    <HistoricalMoveIns
                        rentRollGroupedByLeaseStartDate={rentRollGroupedByLeaseStartDate}
                        futureRentRollByLeaseStartDate={futureRentRollByLeaseStartDate}
                        projectionData={projectionData}
                        futureRentRollEarliestWeek={futureRentRollEarliestWeek}
                        numberOfUnits={numberOfUnits}
                        rentRoll={rentRoll}
                        futureRentRoll={futureRentRoll}
                    />
                </>
            )}
            {newLeaseUpModalVisible && project?.id && (
                <NewLeaseUpModal
                    projectId={project.id}
                    close={() => {
                        setNewLeaseUpModalVisible(false)
                    }}
                />
            )}
            {(leaseUpLoading || rentRollLoading) && (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            )}
            {/*language=scss*/}
            <style jsx>{`
                .page-wrapper {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .section-header {
                        color: #687893;
                        font-size: 12px;
                        margin-top: 20px;
                        margin-bottom: 0px;
                    }

                    .spinner-wrapper {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .new-lease-up-btn-wrapper {
                        display: flex;
                        justify-content: flex-end;

                        .new-lease-up-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 500;
                            font-size: 14px;
                            color: #ffffff;
                            width: 150px;
                            height: 45px;
                            background: #4486ff;
                            border-radius: 4px;
                            cursor: pointer;
                            margin-top: 20px;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
