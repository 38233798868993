export const TAB = Object.freeze({
    DASHBOARD: 'DASHBOARD',
    PLAYGROUND: 'PLAYGROUND',
    CASHFLOW: 'CASHFLOW',
    QUOTE_EDITOR: 'QUOTE_EDITOR',
    COMPARABLES: 'COMPARABLES',
    PRICING: 'PRICING',
    PRICING_DEAL_ANALYTICS: 'PRICING_DEAL_ANALYTICS',
    EXIT_ANALYSIS: 'EXIT_ANALYSIS',
    LEASE_UP: 'LEASE_UP',
})
