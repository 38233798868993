import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { NumberField } from '../../../components/NumberField'

type Props = any

export function UnderwritingAssumptions(props: Props) {
    const activeYear = useSelector((state: ReduxStoreState) => state.lenderDashboard.expensesTab.activeYear)

    return (
        <>
            <div>
                <div className={'fields-header'}>
                    <div style={{ width: '50%' }} />
                    <div className={'year'} style={{ width: '25%' }}>
                        YEAR {activeYear.years[0]}
                    </div>
                    <div
                        className={'year'}
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        YEAR {activeYear.years[1]}
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Organic Rent Growth
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.organicRentGrowth[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.organicRentGrowth[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>
                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Other Income Growth
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.otherIncomeGrowth[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.otherIncomeGrowth[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Loss to Lease
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.lossToLease[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.lossToLease[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Vacancy
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.vacancy[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.vacancy[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Bad Debt
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.badDebt[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.badDebt[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Concession
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.concession[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>

                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.concession[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Replacement Reserves
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.replacementReserves[${activeYear.years[0] - 1}]`}
                            numberFormat={'unit'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.replacementReserves[${activeYear.years[1] - 1}]`}
                            numberFormat={'unit'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Expense Grown
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.expenseGrown[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`underwritingAssumption.expenseGrown[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        GP Asset Management Fee
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.assetManagementFee.year1`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}></div>
                </div>
                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Property Managment Fee
                    </div>

                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`underwritingAssumption.propertyManagmentFee.year1`}
                            numberFormat={'percent'}
                        />
                    </div>

                    <div className="model-field-value" style={{ width: '25%' }}></div>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    $col1: '50%';
                    .fields-header {
                        display: flex;
                        flex-direction: row;
                        margin-top: 17px;
                        margin-bottom: 17px;
                        font-size: 12px;
                        line-height: 15px;
                        div:first-child {
                            width: $col1;
                        }
                        div:nth-child(2) {
                            margin-right: 20px;
                        }
                        div:nth-child(3) {
                            margin-right: 20px;
                        }
                        .year {
                            text-align: right;
                            text-transform: uppercase;
                            color: $gray-nevada;
                        }
                    }
                    .field-values {
                        display: flex;
                        flex-direction: row;
                        margin-top: 13px;
                        .model-field-title {
                            width: $col1;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                            color: $black;
                        }

                        .model-field-value {
                            text-align: right;
                            &:nth-child(2) {
                                margin-right: 20px;
                            }
                            &:nth-child(3) {
                                margin-right: 20px;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
