import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ROUTE } from '../../../../utils/constants/routes'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showNotification } from '../../fullproject/notifications'
import { ReduxHelper, ReduxStoreState } from '../../../../store'
import { SagaHelper } from '../../../../store/sagaHelper'
import { useSelector } from 'react-redux'

import { TAB } from './tabs'
import { regenerateCashFlow } from 'origination-model'
import { useRouter } from 'next/router'

type Props = {
    project: any
    isAdmin: boolean
    setActiveTab?: (string) => void
    setComparableLinkAnchor?: (string) => void
}
function isPromise(p) {
    return p && Object.prototype.toString.call(p) === '[object Promise]'
}
export const DashboardActions = (props: Props) => {
    const { project, isAdmin, setActiveTab, setComparableLinkAnchor } = props
    const [showActions, setShowActions] = useState(false)
    const toggleActions = useCallback(() => {
        setShowActions(!showActions)
    }, [showActions])
    const hideActions = useCallback(() => setShowActions(false), [])
    const [shareLink, setShareLink] = useState('')
    const [filesLink, setFilesLink] = useState('')
    // const [rentCompsLink, setRentCompsLink] = useState('')
    // const [saleCompsLink, setSaleCompsLink] = useState('')

    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    let narrativeId = project?.defaultNarrativeId || 0
    useEffect(() => {
        // console.log('narrativeId', projectId, project?.defaultNarrativeId)
        if (!project?.defaultNarrativeId) {
            SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                projectId: projectId,
            }).then((res) => {
                //console.log('narrativeId', res)
                narrativeId = res
            })
        } else narrativeId = project?.defaultNarrativeId
    }, [project, project?.defaultNarrativeId])

    useEffect(() => {
        if (!project) return
        setShareLink(
            `${window.location.protocol}//${window.location.host}/expose/${project?.id}/${ROUTE.MINI_SITE.SUMMARY}`,
        )
        /*
        setSaleCompsLink(
            `${window.location.protocol}//${window.location.host}/expose/${project.projectId}/${ROUTE.MINI_SITE.SALECOMPS}`,
        )
        setRentCompsLink(
            `${window.location.protocol}//${window.location.host}/expose/${project.projectId}/${ROUTE.MINI_SITE.RENTCOMPS}`,
        )
         */
        setFilesLink(
            `${window.location.protocol}//${window.location.host}/expose/${project?.id}/${ROUTE.MINI_SITE.DOCUMENTS}`,
        )
    }, [project])

    const onCopy = useCallback(() => {
        hideActions()
        showNotification('Link is copied to clipboard').finally()
    }, [])

    const isDataScrapped = useMemo(() => {
        return true
        //return project?.isDataScraped || false
    }, [project])

    const generate = useCallback(() => {
        const { cashFlow, ...originationModel } = reduxModel
        const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
        const cf = regenerateCashFlow({ cashFlow, model: originationModel, unitMix: unitMix })
        const additionalData = {
            dealIRR: cf.dealIRR,
            cashFlow: cf,
        }
        SagaHelper.run(['lenderProjects', 'scrapAllData'], {
            projectId: +project?.id,
            additionalData: JSON.stringify(additionalData),
        })
        SagaHelper.run(
            ['notification', 'show'],
            'Generating memo - can take up to 10 Minutes, will notify you with Email',
        )
    }, [project])

    const isModelChanged = useSelector((state: ReduxStoreState) => state.lender.isModelChanged)
    const reduxModel = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const fannieQuotes = useSelector((state: ReduxStoreState) => state.lender.fannieQuotes)

    const update = useCallback(async () => {
        const { cashFlow, ...originationModel } = reduxModel
        const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
        const cf = regenerateCashFlow({ cashFlow, model: originationModel, unitMix: unitMix })
        const additionalData = {
            dealIRR: cf.dealIRR,
            cashFlow: cf,
        }
        if (isModelChanged) {
            const confirmation = confirm('The model was changed, do you want to save it before updating?')
            if (confirmation) {
                if (originationModel['cashflow']) {
                    delete originationModel['cashflow']
                }

                await SagaHelper.run(['lenderOriginationModel', 'saveCashFlow'], {
                    projectId: +project.id,
                    cashFlow,
                })

                await SagaHelper.run(['lenderOriginationModel', 'setOriginationModel'], {
                    projectId: +project.id,
                    originationModel,
                })
                ReduxHelper.setIn(['lender', 'isModelChanged'], false)

                await SagaHelper.run(['lenderProjects', 'updateOMProforma'], {
                    projectId: +project.id,
                    additionalData: JSON.stringify(additionalData),
                })
            }
        } else {
            await SagaHelper.run(['lenderProjects', 'updateOMProforma'], {
                projectId: +project.id,
                additionalData: JSON.stringify(additionalData),
            })
        }

        if (originationModel.allowFannieAPricing) {
            if (pricing?.fanniePricing?.pricingOptions?.length > 0)
                await SagaHelper.run(['lenderPricing', 'setPricing'], {
                    projectId: +project.id,
                    pricing: pricing,
                })

            if (fannieQuotes?.quotes?.length > 0)
                await SagaHelper.run(['lenderFannieQuotes', 'setFannieQuotes'], {
                    projectId: +project.id,
                    fannieQuotes: fannieQuotes,
                })
        }
        await SagaHelper.run(['notification', 'show'], 'Update is completed')
    }, [project, isModelChanged, reduxModel, pricing, fannieQuotes])

    const openNarrative = useCallback(
        (mode) => {
            // console.log('openNarrative', mode, projectId, project?.id, narrativeId)
            const go = (nid) => {
                const win = window.open(
                    ROUTE.NARRATIVE_OM + '?id=' + project.originalProjectId + '&narrativeId=' + nid,
                    '_blank',
                )
                win?.focus()
            }

            if (!narrativeId) {
                SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                    projectId: projectId,
                }).then((res) => {
                    const narrativeIdRes = res
                    go(narrativeIdRes)
                })
            } else {
                if (isPromise(narrativeId)) {
                    Promise.resolve(narrativeId).then((res) => {
                        go(res)
                    })
                } else go(narrativeId)
            }
        },
        [narrativeId, project],
    )

    const exportPDF = useCallback(async () => {
        // await exportToExcel(model.cashFlow, model, project.accountType)
        window.open(`/export-pdf/${project.id}`, '_blank')
    }, [project])
    const summaryPDF = useCallback(async () => {
        // await exportToExcel(model.cashFlow, model, project.accountType)
        window.open(`/summary-pdf/${project.id}`, '_blank')
    }, [project])
    return (
        <div className={'select-wrapper'} onMouseLeave={() => hideActions()}>
            <button className="btn dropdown-toggle" onClick={toggleActions}>
                Credit Memo (OM)
            </button>
            {showActions && (
                <div className={'actions-wrapper'}>
                    <div className="actions-wrapper__item">
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                e.stopPropagation()

                                isDataScrapped ? update() : generate()
                                hideActions()
                            }}
                        >
                            {isDataScrapped ? `Update` : `Generate`}
                        </a>
                    </div>
                    {isAdmin && (
                        <div className="actions-wrapper__item">
                            <a
                                onClick={(e: SyntheticEvent) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    generate()
                                    hideActions()
                                }}
                            >
                                Regenerate
                            </a>
                        </div>
                    )}
                    <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e?.stopPropagation()
                                e?.preventDefault()
                                const win = window.open(shareLink, '_blank')
                                win?.focus()
                                hideActions()
                            }}
                        >
                            View
                        </a>
                    </div>
                    <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                const win = window.open(`${ROUTE.PROJECT}?edit=${project.id}`, '_blank')
                                win.focus()
                                hideActions()
                            }}
                        >
                            Edit
                        </a>
                    </div>
                    <div className={`actions-wrapper__item`}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                const win = window.open(filesLink, '_blank')
                                win.focus()
                                hideActions()
                            }}
                        >
                            (Files)
                        </a>
                    </div>
                    <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                // const win = window.open(saleCompsLink, '_blank')
                                // win.focus()
                                setComparableLinkAnchor('sales')
                                setActiveTab(TAB.COMPARABLES)
                                hideActions()
                            }}
                        >
                            (Sales comps)
                        </a>
                    </div>
                    <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                // const win = window.open(rentCompsLink, '_blank')
                                // win.focus()
                                setComparableLinkAnchor('rent')
                                setActiveTab(TAB.COMPARABLES)
                                hideActions()
                            }}
                        >
                            (Rent Comps)
                        </a>
                    </div>
                    <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                        <CopyToClipboard text={shareLink} onCopy={onCopy}>
                            <a>Share</a>
                        </CopyToClipboard>
                    </div>
                    {isAdmin && (
                        <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                            <a onClick={openNarrative}>
                                Export to PDF <span className="exportBeta">* Beta</span>
                            </a>
                        </div>
                    )}

                    {false && isAdmin && (
                        <React.Fragment>
                            <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                                <a onClick={exportPDF}>
                                    Export to PDF <span className="exportBeta">* Beta</span>
                                </a>
                            </div>
                            <div className={`actions-wrapper__item ${isDataScrapped ? '' : 'disabled'}`}>
                                <a onClick={summaryPDF}>
                                    Summary PDF <span className="exportBeta">* Beta</span>
                                </a>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .exportBeta {
                    color: red;
                    font-size: 9px;
                    top: -4px;
                    position: relative;
                }
                .select-wrapper {
                    //left: 370px;
                    position: relative;
                    //top: -7px;
                    margin-right: 20px;
                    .dropdown-toggle {
                        border: 1px solid $border;
                        font-size: 15px;
                        width: 200px;
                        font-weight: 300;
                        outline: none;
                    }

                    .actions-wrapper {
                        position: absolute;
                        background-color: $white;
                        border: 1px solid $border;
                        border-radius: 3px;
                        width: 100%;
                        z-index: 1000000;
                        &__item {
                            color: $dark-gray;
                            font-size: 13px;
                            cursor: pointer;
                            transition: all linear 0.2s;
                            a {
                                padding: 10px;
                                display: block;
                            }
                            &:hover {
                                background-color: $border;
                            }
                            &.disabled {
                                pointer-events: none;
                                cursor: not-allowed;
                                opacity: 0.5;
                            }
                        }
                    }
                }
                .disabled-link {
                    opacity: 0.5;
                }

                @media (max-width: 1025px) {
                    :global(.dropdown-toggle) {
                    }
                    :global(.dropdown-toggle::after) {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        bottom: auto;
                    }
                }
            `}</style>
        </div>
    )
}
