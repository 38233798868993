import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import numeral from 'numeral'
import { OriginationModelReworked } from '@generated/graphql'
import { mortgagePaymentSchedule } from 'origination-model'

export const ExitAnalysis = React.memo(function ExitAnalysis() {
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)

    const newPropertyValue = useMemo(() => {
        return model?.refinance?.newPropertyValue
            ? model?.refinance?.newPropertyValue
            : cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1] / model?.exitAssumption?.exitCapRate
    }, [
        model?.exitAssumption?.exitCapRate,
        model?.refinance?.newPropertyValue,
        cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1],
        model?.debtAssumptionSenior,
    ])

    const refinanceDSCR = useMemo(() => {
        const newAmortizationPayment = mortgagePaymentSchedule({
            loanAmount: model?.refinance?.ltv * newPropertyValue,
            amortizationTermYears: model?.refinance?.amortizationTerm,
            rate: model?.refinance?.refinanceIntrestRate
                ? model?.refinance?.refinanceIntrestRate
                : model?.debtAssumptionSenior?.rate,
            ioPeriodYears: 0,
            unit: 'year',
            periodYears: model?.refinance?.amortizationTerm,
        })

        return cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1] / newAmortizationPayment?.schedule[0]?.total
    }, [
        cashFlow,
        model,
        cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1],
        model?.refinance?.exitCapRate,
        model?.debtAssumptionSenior?.rate,
        model?.refinance?.ltv,
        model?.debtAssumptionSenior,
    ])

    const exitCapRate = useMemo(() => {
        return model?.refinance?.exitCapRate ? model?.refinance?.exitCapRate : model?.exitAssumption?.exitCapRate
    }, [
        cashFlow,
        model,
        cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1],
        model?.refinance?.exitCapRate,
        model?.refinance?.ltv,
        model?.debtAssumptionSenior,
    ])

    const ltv = useMemo(() => {
        return model?.refinance?.ltv ? model?.refinance?.ltv : 0.65
    }, [
        cashFlow,
        model,
        cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1],
        model?.refinance?.exitCapRate,
        model?.refinance?.ltv,
        model?.debtAssumptionSenior,
    ])

    const refinanceType = useMemo(() => {
        return model?.refinance?.refinanceType ? model?.refinance?.refinanceType : 'end_holding'
    }, [
        cashFlow,
        model,
        cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1],
        model?.refinance?.exitCapRate,
        model?.refinance?.ltv,
        model?.debtAssumptionSenior,
    ])

    const noi = useMemo(() => {
        if (refinanceType == 'end_holding') return cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1]
        else if (refinanceType == 'end_io') return cashFlow?.noi.years[model?.debtAssumptionSenior.ioPeriod - 1]
        else return cashFlow?.noi.years[parseInt(refinanceType) - 1]
    }, [
        cashFlow,
        model,
        cashFlow?.noi.years[cashFlow?.noi?.years?.length - 1],
        model?.refinance?.exitCapRate,
        model?.refinance?.ltv,
        model?.debtAssumptionSenior,
    ])

    //console.log(model)
    return (
        <div className={'exit-analysis'}>
            <WidgetWrapper style={{ padding: 0 }} title={'Exit Analysis'} showProgress={!model || !cashFlow}>
                <DataBlock title={'Exit Period'} value={`${model?.exitAssumption?.saleYearAnalysisPeriod} years`} />
                <DataBlock title={'Exit Cap Rate'} value={numeral(exitCapRate).format('0.[00]%')} />
                <DataBlock title={'Asset Value'} value={numeral(newPropertyValue).format('$0,0')} />
                <div className={'diviver'}></div>
                <DataBlock title={'Refinance LTV'} value={numeral(ltv).format('0.[00]%')} />
                <DataBlock title={'Refinance DSCR'} value={numeral(refinanceDSCR).format('0.[00]')} />
                <DataBlock
                    title={model?.useNCFnotNOI ? 'Exit Net CF' : 'Exit NOI'}
                    value={numeral(noi).format('$0,0')}
                />
                {/* need put line seperator */}
            </WidgetWrapper>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .exit-analysis {
                    flex: 1;
                    .diviver {
                        width: 100%;
                        background: #d9e0e5;
                        opacity: 0.5;
                        height: 1px;
                        grid-row-start: 2;
                        grid-row-end: 2;
                        grid-column-start: 1;
                        grid-column-end: 4;
                    }
                    :global(.widget-body) {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-template-rows: 1fr 1px 1fr;
                        gap: 20px 20px;
                    }
                    :global(.widget-container) {
                        //height: 344px;
                    }
                    .metrics-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        :global(.grey-bar) {
                            margin-right: 20px;
                        }
                        :global(.grey-bar:last-child) {
                            margin-right: 0;
                        }
                    }
                }
            `}</style>
        </div>
    )
})

type DataBlockProps = {
    title: string
    value: string
}
function DataBlock(props: DataBlockProps) {
    return (
        <div className={'data-block'}>
            <div className={'title'}>{props.title}</div>
            <div className={'value'}>{props.value}</div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .data-block {
                    .title {
                        font-size: 14px;
                        line-height: 17px;
                        color: #97a2b4;
                        margin-bottom: 5px;
                    }
                    .value {
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 25px;
                        color: $default-text-color;
                    }
                }
            `}</style>
        </div>
    )
}
