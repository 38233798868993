import numeral from 'numeral'
export function formatCurrency(v: number) {
    if (v < 100000) {
        return numeral(v).format('$0,0')
    } else if (v > 100000 && v < 100000000) {
        return numeral(v / 1000000).format('$0.[000]') + 'M'
    } else {
        return numeral(v / 1000000000).format('$0.[000]') + 'T'
    }
}
