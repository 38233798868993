import { useState, useMemo, useEffect, useRef, useLayoutEffect, forwardRef, MutableRefObject, useContext } from 'react'
import numeral from 'numeral'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import SelectBox from 'devextreme-react/select-box'
import { useFormState } from 'react-final-form'
import { ReduxStoreState } from '../../../../../../store'
import refresh from '../icons/refresh-icon.svg'
import { TextField } from '../../../components/TextField'
import { NumberField } from '../../../components/NumberField'
import { OnlyNumberField } from '../../../components/OnlyNumberField'
import {
    exercisingTierDroppingOptionList,
    InterestBasisList,
    rateLockTypeList,
    refIndexListFixed,
    refIndexListARM,
    supplementalTypeList,
    tierList,
    prepaymentOptionsList,
} from './lib'
import { WhiteReactSelectAdapter } from '../../../../../controls/inputs/pricing/whiteReactSelectAdapter'
import { propertyTypeList } from '../../../../project-wizard/steps/GeneralInputs/PropertyAndBorrower'
import { greenFinancingTypeList } from '../../../../project-wizard/steps/GeneralInputs/DealDetails'
import minimize from '../icons/minimize.svg'
import trash from '../icons/trash.svg'
import { isLoanSizerDSCRAlert, isLoanScnarionDSCRAlert, isMax1stPoistionAlert, isAnyAlert } from './lib'
import { regenerateCashFlow } from 'origination-model'
import {
    pi as calcPI,
    balloon as calcBalloon,
    exitRefiRate as calcExitRefiRate,
    exitCapRate as calcExitCapRate,
} from '../northMarkFormulas'
import { calcDebtService } from '../ExitAnalysis/RefinanceAssumptions/lib'
import { FormContext } from '../../../Dashboard'
import { usePrevious } from 'utils/usePrevious'

type Props = any

export function SizerTitlesCol({ isSupplemental, isArm, isNM }: Props) {
    return (
        <div className={'pricingComponent'}>
            <div className={'sizerCol'}>
                <div className={'title grouptitle'}>Financing Option</div>
                {isNM && !isArm && <div className={'title'}>Borrower Requested Loan Amount</div>}
                <div className={'title loanBold'}>
                    {isNM && !isArm ? 'NM ' : ''}
                    {isSupplemental ? 'Requested Supplemental Loan Amount ' : 'Requested Loan Amount'}
                </div>
                {isSupplemental && (
                    <>
                        <div className={'title'}>UPB of 1st (plus Supp if applicable</div>
                        <div className={'title loanBold blueBG'}>Combined UPB</div>
                    </>
                )}

                <div className={'title'}>
                    {isSupplemental
                        ? 'DSCR Combined Loan Amount'
                        : 'DSCR at' + (isNM && !isArm ? ' NM' : '') + ' requested loan amount'}
                </div>
                <div className={'title'}>
                    {isSupplemental ? 'Required Cap Rate at Combined Loan Amount' : 'Required Cap at Requested Loan'}
                </div>
                <div className={'title loanBold blueBG'}>Debt Service Constrained Loan</div>
                <div className={'title indent20'}> DSCR Loan per unit</div>
                <div className={'title'}>Required Cap at DSCR Loan</div>
                <div className={'title loanBold'}>Value Constrained Loan</div>
                {isArm && <div className={'title'}>Fixed Rate Sizing</div>}
                <div className={'title '}>Recent Acquisition Loan Limit</div>
                <div className={'title loanBold pos1Loan'}>Maximum 1st Pos Loan</div>
                <div className={'title'}>Constraint</div>

                {isSupplemental && (
                    <>
                        <div className={'title sepratorLine'}></div>
                        <div className={'title '}>Underwritten NCF</div>
                        <div className={'title '}>Available for Debt Service</div>
                        <div className={'title '}>Current Debt Service</div>
                        <div className={'title loanBold'}>Available for Supplemental Debt Service</div>
                    </>
                )}
                <div className={'title selectOfferRaw'}></div>
            </div>
            {/*language=scss*/}
            <style jsx>{``}</style>
        </div>
    )
}

export function ScenarioTitlesCol() {
    return (
        <div className={'pricingComponent scenarioCol'}>
            <div className={'title grouptitle'}>Loan Terms</div>
            <div className={'title'}>Loan Term</div>
            <div className={'title'}>Interest Type</div>
            <div className={'title'}>Tier</div>
            <div className={'title'}>IO</div>
            <div className={'title'}>Minimum DSCR</div>
            <div className={'title'}>Maximum LTV</div>
            <div className={'title'}>Amortization</div>
            <div className={'title'}>Pre-Payment</div>
            <div className={'title'}>Interest Basis</div>
        </div>
    )
}

export function RateBreakdownTitlesCol({ refreshRates, isArm }: Props) {
    return (
        <div className={'pricingComponent'}>
            <div className={'title grouptitle'}>Loan Pricing</div>
            <div className={'title'}>Pricing Method</div>
            <div className={'title'}>Ref Index</div>
            <div className={'title'}>
                Index Rate{' '}
                <span className={'refresh'}>
                    <img src={refresh} onClick={refreshRates} />
                </span>{' '}
            </div>
            <div className={'title'}>Investor Spread</div>
            <div className={'title'}>Fannie Mae Guaranty Fee</div>
            <div className={'title'}>Lender Servicing Fee</div>
            <div className={'title loanBold'}>Total Spread</div>
            {isArm && (
                <>
                    <div className={'title empty'}></div>
                    <div className={'title'}>Stress Factor</div>
                    <div className={'title'}>Strike Rate</div>
                    <div className={'title'}>Cap Cost Factor</div>
                    <div className={'title empty'}></div>
                </>
            )}
            <div className={'title'}>Sizing Interest Rate</div>
            <div className={'title blueBG loanBold'}>UW Floor (if applicable)</div>
            <div className={'title'}>Actual Interest Rate</div>
            {isArm && <div className={'title'}>Underwritten Loan Constant</div>}
            <div className={'title'}>Calculated Loan Constant</div>
            {/*language=scss*/}
            <style jsx>{`
                .refresh {
                    margin-left: 10px;
                    cursor: pointer;
                    img {
                        width: 15px;
                    }
                }
            `}</style>
        </div>
    )
}

export function CreaditFeesTitlesCol({ isSupplemental }: Props) {
    return (
        <div className={'pricingComponent creditFeesCol'}>
            <div className={'title grouptitle'}>Loan Terms</div>
            <div className={'title'}>Amortization</div>
            <div className={'title'}>Loan Term</div>
            <div className={'title'}>IO</div>
            <div className={'title'}>Prepayment Period</div>
            <div className={'title'}>Tier</div>
            {isSupplemental && <div className={'title'}>Supplemental Type</div>}
            {isSupplemental && <div className={'title'}>Exercising Tier Dropping Option</div>}
            <div className={'title'} style={{ display: 'none' }}>
                Property Type
            </div>
            <div className={'title empty'}></div>
            <div className={'title grouptitle'}>Pricing</div>
            <div className={'title'}>Targeted G&S Indicative Pricing</div>
            <div className={'title'}>Green Financing Type</div>
            <div className={'title'}>Rate Lock Type</div>
            <div className={'title'}>Investor Spread</div>
            <div className={'title'}>Fannie Mae Guaranty Fee</div>
            <div className={'title'}>Lender Servicing Fee</div>
            <div className={'title empty'}></div>
            <div className={'title grouptitle'}>Spread</div>
            <div className={'title'}>Investor Spread +/-</div>
            <div className={'title'}>Fannie Mae Guaranty Fee +/-</div>
            <div className={'title'}>Lender Servicing Fee +/-</div>
            <div className={'title'} style={{ fontStyle: 'italic' }}>
                Comment
            </div>
        </div>
    )
}

export function ExitAnalysisSummaryTitlesCol() {
    return (
        <div className={'pricingComponent'}>
            <div className={'title grouptitle'}>Refi-Standard</div>
            <div className={'title'}>NCF</div>
            <div className={'title'}>Exit Refi Rate</div>
            <div className={'title'}>Exit Cap Rate</div>
            <div className={'title empty'}></div>
            <div className={'title grouptitle'}>Refi-Alternative</div>
            <div className={'title'}>NCF</div>
            <div className={'title'}>Alt Refi Rate</div>
            <div className={'title'}>Alt Cap Rate</div>
        </div>
    )
}

export function CapCostTitlesCol() {
    return (
        <>
            <div className={'pricingComponent capCostCol reduceWidth'}>
                <div className={'title'}>Cap Cost</div>
                <div className={'title'}>Cap Cost Term (Yrs)</div>
                <div className={'title'}>Cost per Year</div>
                <div className={'title'}>Loan Amount</div>
                <div className={'title blueBG loanBold'}>Cap Cost Factor</div>
                <div className={'title'}>Monthly Escrow</div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .reduceWidth {
                    width: 360px !important;
                    border-left: 1px solid #eceff2;
                    .title {
                        width: 360px !important;
                    }
                }
            `}</style>
        </>
    )
}

export const OptionTitle = forwardRef((props: Props, ref: MutableRefObject<any>) => {
    const {
        option,
        allOptions,
        armOptionId,
        idx,
        changeOptionCollapsed,
        removeOption,
        armReferenceToFixed,
        changeFixedOptionRef,
        changeArmOptionsRefs,
        isArm,
        form,
        orderSelectboxOptions,
        fixedDealSelectOptions,
    }: Props = props

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const showRightMargin = useMemo(() => allOptions?.filter((item) => item.isArm).length > 1, [allOptions])

    const handleValueChange = (event) => {
        changeFixedOptionRef(armOptionId, event.value)
        changeArmOptionsRefs(event.value, armOptionId)
    }

    const isRemovable = useMemo(
        () => !allOptions.some((item) => item.isArm && item.fixedOptionReferenceId === option.id),
        [allOptions, option],
    )

    const stickyHeader = useRef() as MutableRefObject<any>
    const outerComponent = useRef() as MutableRefObject<any>

    useLayoutEffect(() => {
        if (!!stickyHeader.current && !ref.current) {
            ref.current = stickyHeader.current.getBoundingClientRect().top
        }

        const onScroll = () => {
            if (!!stickyHeader.current && !!outerComponent.current && !!ref.current && !option.isCollapsed) {
                if (ref.current < window.scrollY) {
                    stickyHeader.current.style.position = 'fixed'
                    stickyHeader.current.style.top = 0
                    stickyHeader.current.style.left = outerComponent.current.getBoundingClientRect().left + 'px'
                } else {
                    stickyHeader.current.style.position = 'initial'
                }
            }
        }

        if (!!option) window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [option])

    useEffect(() => {
        if (option.isCollapsed && !!stickyHeader.current) {
            stickyHeader.current.style.position = 'initial'
        }
    }, [option.isCollapsed])

    const handleOrderChange = ({ value, previousValue }) => {
        const newIdx = allOptions.findIndex((option) => option.id === value)
        const currentIdx = allOptions.findIndex((option) => option.id === previousValue)
        form.mutators.changeOptionPosition(currentIdx, newIdx)
    }

    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: isArm && option.isArm && showRightMargin ? 20 : 0,
                        borderLeft: isArm && !option.isArm ? '1px solid #eceff2' : 'none',
                        width: option.isCollapsed ? 60 : 320,
                    }}
                    ref={outerComponent}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}>
                            {orderSelectboxOptions && (
                                <div className={'bgWhite order-selectbox-wrapper'}>
                                    <SelectBox
                                        value={option.id}
                                        valueExpr={'value'}
                                        displayExpr={'label'}
                                        placeholder={''}
                                        dataSource={orderSelectboxOptions}
                                        onValueChanged={handleOrderChange}
                                        name={'orderSelectbox'}
                                    />
                                </div>
                            )}
                            {isArm && !orderSelectboxOptions && <div style={{ height: 35, backgroundColor: '#fff' }} />}
                            <div
                                onClick={() => {
                                    if (formVisited?.pricing === false) {
                                        setFormVisited((prevState) => ({
                                            ...prevState,
                                            pricing: true,
                                        }))
                                    }
                                    if (!formModified.pricing) {
                                        setFormModified((prevState) => ({
                                            ...prevState,
                                            pricing: true,
                                        }))
                                    }
                                    changeOptionCollapsed(idx, false)
                                }}
                            >
                                <span className={'collapseSpan'}>
                                    <i className="material-icons-outlined">navigate_before</i>
                                    <i className="material-icons-outlined">navigate_next</i>
                                </span>
                            </div>
                            <div className={'collapsedColTitle'}> {option.name}</div>
                        </div>
                    ) : (
                        <div
                            className={'titleCol' + (option.isSelected ? ' selectedOptions' : '')}
                            style={{ backgroundColor: 'white !important', zIndex: 1000 }}
                            ref={stickyHeader}
                        >
                            {orderSelectboxOptions && (
                                <div className={'bgWhite order-selectbox-wrapper'}>
                                    <SelectBox
                                        value={option.id}
                                        valueExpr={'value'}
                                        displayExpr={'label'}
                                        placeholder={''}
                                        dataSource={orderSelectboxOptions}
                                        onValueChanged={handleOrderChange}
                                        name={'orderSelectbox'}
                                    />
                                </div>
                            )}
                            {isArm && !orderSelectboxOptions && !option.isCollapsed && (
                                <div style={{ height: 35, backgroundColor: '#fff' }} />
                            )}
                            <div className={'tableCol bgWhite'}>
                                <div className={isAnyAlert(option) ? 'redBottomBorder' : 'blueBottomBorder'}>
                                    {fixedDealSelectOptions ? (
                                        <SelectBox
                                            value={armReferenceToFixed}
                                            valueExpr={'value'}
                                            displayExpr={'label'}
                                            placeholder={''}
                                            dataSource={fixedDealSelectOptions}
                                            onValueChanged={handleValueChange}
                                            name={'fixedOptionsSelect'}
                                        />
                                    ) : (
                                        <TextField
                                            name={`fanniePricing.pricingOptions.${idx}.name`}
                                            className={'loanTitle'}
                                        />
                                    )}
                                    {isRemovable && (
                                        <span
                                            className={'trashSpan'}
                                            onClick={() => {
                                                removeOption(idx)
                                                if (!!outerComponent.current) {
                                                    outerComponent.current.scroll(0, 1)
                                                }
                                            }}
                                            style={{ marginLeft: 10 }}
                                        >
                                            <img className={'trashImg'} src={trash} />
                                        </span>
                                    )}
                                    <span
                                        className={'minimizeSpan'}
                                        onClick={() => {
                                            if (formVisited?.pricing === false) {
                                                setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    pricing: true,
                                                }))
                                            }
                                            if (!formModified.pricing) {
                                                setFormModified((prevState) => ({
                                                    ...prevState,
                                                    pricing: true,
                                                }))
                                            }
                                            changeOptionCollapsed(idx, true)
                                            if (!!stickyHeader.current) {
                                                stickyHeader.current.style.position = 'initial'
                                            }
                                            if (!!outerComponent.current) {
                                                outerComponent.current.scroll(0, 1)
                                            }
                                        }}
                                        style={{ marginLeft: isRemovable ? 0 : 10 }}
                                    >
                                        <img className={'minimizeImg'} src={minimize} />
                                    </span>
                                </div>
                            </div>
                            <div className={'tableCol grouptitle'}>
                                <div className={'shortCol shortTitle'}>Underwritten</div>
                                <div className={'shortCol shortTitle'}>{option.isArm ? 'Strike' : 'Actual'}</div>
                            </div>
                        </div>
                    )}
                    {/*language=scss*/}
                    <style jsx>{`
                        .collapsedColTitle {
                            writing-mode: tb;
                            padding-left: 10px;
                            padding-top: 10px;
                        }
                        .collapseSpan {
                            cursor: pointer;
                            cursor: pointer;
                            top: 5px;
                            position: relative;
                            margin-right: 5px;
                        }
                        .titleCol {
                            //padding-right: 5px;
                        }
                        .blueBottomBorder {
                            background-color: white !important;
                            border-bottom: 4px solid #4486ff;
                            display: flex;
                            justify-content: center;
                            width: 100%;
                        }
                        .redBottomBorder {
                            background-color: white !important;
                            border-bottom: 4px solid #f14b44;
                            display: flex;
                            justify-content: center;
                            width: 100%;
                        }
                        .loanTitle {
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500 !important;
                            font-size: 15px;
                            line-height: 18px;
                            color: #161c26;
                            text-align: center !important;
                        }
                        .trashSpan {
                            cursor: pointer;
                            padding-right: 5px;
                            .trashImg {
                            }
                        }
                        .minimizeSpan {
                            cursor: pointer;
                            padding-right: 5px;
                            .minimizeImg {
                            }
                        }
                        .order-selectbox-wrapper {
                            height: 35px;
                            :global(input) {
                                text-align: center !important;
                            }
                        }
                    `}</style>
                </div>
            )}
        </>
    )
})

export function LoanSizerColumn({
    option,
    idx,
    isSupplemental,
    getUnderwrittenDSCRperUnit,
    getActualDSCRperUnit,
    changeOptionSelected,
    selectOffer,
    isArm,
    allOptions,
    isAcquisition,
    isNM,
    setNmLoanAmountManuallyChanged,
}: Props) {
    const isReadOnly = isArm && !option.isDefaultFixedRate && !option.isArm
    const showRightMargin = useMemo(() => allOptions?.filter((item) => item.isArm).length > 1, [allOptions])
    const selectOption = (!isArm && option.isSelected) || (isArm && option.isSelected && option.isArm)
    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: isArm && option.isArm && showRightMargin ? 20 : 0,
                        borderLeft: isArm && !option.isArm ? '1px solid #eceff2' : 'none',
                    }}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}></div>
                    ) : (
                        <div className={'sizerCol' + (selectOption ? ' selectedOptions' : '')}>
                            <div className={'tableCol grouptitle'}></div>
                            {isNM && !isArm && (
                                <div className={'tableCol'}>
                                    <div className={'shortCol'}>
                                        <NumberField
                                            name={`computed.borrowerLoanAmount`}
                                            numberFormat={'currency'}
                                            cssClassName={''}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={'shortCol'}>
                                        <NumberField
                                            name={`computed.borrowerLoanAmount`}
                                            numberFormat={'currency'}
                                            cssClassName={''}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.loanAmount`}
                                        numberFormat={'currency'}
                                        cssClassName={'loanBold'}
                                        readOnly={isReadOnly}
                                        onChange={() => !isArm && setNmLoanAmountManuallyChanged(idx)}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.loanAmount`}
                                        numberFormat={'currency'}
                                        cssClassName={'loanBold'}
                                        readOnly={isReadOnly}
                                        onChange={() => !isArm && setNmLoanAmountManuallyChanged(idx)}
                                    />
                                </div>
                            </div>

                            {isSupplemental && (
                                <>
                                    <div className={'tableCol'}>
                                        <div className={'shortCol'}>
                                            <NumberField
                                                name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.upbOfFirst`}
                                                numberFormat={'currency'}
                                                readOnly={isReadOnly}
                                            />
                                        </div>
                                        <div className={'shortCol'}>
                                            <NumberField
                                                name={`fanniePricing.pricingOptions.${idx}.sizer.actual.upbOfFirst`}
                                                numberFormat={'currency'}
                                                readOnly={isReadOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className={'tableCol blueBG'}>
                                        <div className={'shortCol'}>
                                            <NumberField
                                                name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.combinedUPB`}
                                                numberFormat={'currency'}
                                                cssClassName={'loanBold'}
                                                readOnly={isReadOnly}
                                            />
                                        </div>
                                        <div className={'shortCol'}>
                                            <NumberField
                                                name={`fanniePricing.pricingOptions.${idx}.sizer.actual.combinedUPB`}
                                                numberFormat={'currency'}
                                                cssClassName={'loanBold'}
                                                readOnly={isReadOnly}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className={'tableCol'}>
                                <div className={'shortCol' + (isLoanSizerDSCRAlert(option) ? ' redBG' : '')}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.dscRequestedLoanAmount`}
                                        numberFormat={'decimal'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.dscRequestedLoanAmount`}
                                        numberFormat={'decimal'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.capRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.capRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol blueBG'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.debtServiceConstrainedLoan`}
                                        numberFormat={'currency'}
                                        cssClassName={'loanBold'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.debtServiceConstrainedLoan`}
                                        numberFormat={'currency'}
                                        cssClassName={'loanBold'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol indent20'}>
                                <div className={'shortCol'}>
                                    {numeral(getUnderwrittenDSCRperUnit(idx)).format('$0,0')}
                                </div>
                                <div className={'shortCol'}>{numeral(getActualDSCRperUnit(idx)).format('$0,0')}</div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.requiredCapRateAtDSCRLoan`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.requiredCapRateAtDSCRLoan`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.valueConstrainedLoan`}
                                        numberFormat={'currency'}
                                        cssClassName={'loanBold'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.valueConstrainedLoan`}
                                        numberFormat={'currency'}
                                        cssClassName={'loanBold'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            {isArm && option.isArm && (
                                <div className={'tableCol'}>
                                    <div className={'shortCol'}>
                                        <NumberField
                                            name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.fixedRateSizing`}
                                            numberFormat={'currency'}
                                            cssClassName={'loanBold'}
                                        />
                                    </div>
                                    <div className={'shortCol'}>
                                        <NumberField
                                            name={`fanniePricing.pricingOptions.${idx}.sizer.actual.fixedRateSizing`}
                                            numberFormat={'currency'}
                                            cssClassName={'loanBold'}
                                        />
                                    </div>
                                </div>
                            )}
                            {isArm && !option.isArm && <div className={'tableCol empty'}></div>}
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    {isAcquisition ? (
                                        <NumberField
                                            name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.recentAquisitionLoanLimit`}
                                            numberFormat={'currency'}
                                            readOnly={true}
                                        />
                                    ) : (
                                        'N/A'
                                    )}
                                </div>
                                <div className={'shortCol'}>
                                    {isAcquisition ? (
                                        <NumberField
                                            name={`fanniePricing.pricingOptions.${idx}.sizer.actual.recentAquisitionLoanLimit`}
                                            numberFormat={'currency'}
                                            readOnly={true}
                                        />
                                    ) : (
                                        'N/A'
                                    )}
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div
                                    className={
                                        'shortCol ' +
                                        (isMax1stPoistionAlert(option, 'underwritten') ? ' redBG' : ' greenBG')
                                    }
                                >
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.underwritten.maxFirstPosLoan`}
                                        numberFormat={'currency'}
                                        cssClassName={'pos1Loan '}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div
                                    className={
                                        'shortCol ' + (isMax1stPoistionAlert(option, 'actual') ? ' redBG' : ' greenBG')
                                    }
                                >
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.sizer.actual.maxFirstPosLoan`}
                                        numberFormat={'currency'}
                                        cssClassName={'pos1Loan'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>{option.sizer.underwritten.constraint}</div>
                                <div className={'shortCol'}>{option.sizer.actual.constraint}</div>
                            </div>

                            {isSupplemental && (
                                <>
                                    <div className={'tableCol sepratorLine'}>
                                        <div className={'shortCol'}></div>
                                        <div className={'shortCol'}></div>
                                    </div>

                                    <div className={'tableCol'}>
                                        <div className={'shortCol'}>
                                            {numeral(option.sizer.underwritten.underwrittenNCF).format('$0,0')}
                                        </div>
                                        <div className={'shortCol'}>
                                            {numeral(option.sizer.actual.underwrittenNCF).format('$0,0')}
                                        </div>
                                    </div>

                                    <div className={'tableCol'}>
                                        <div className={'shortCol'}>
                                            {numeral(option.sizer.underwritten.availableForDebtService).format('$0,0')}
                                        </div>
                                        <div className={'shortCol'}>
                                            {numeral(option.sizer.actual.availableForDebtService).format('$0,0')}
                                        </div>
                                    </div>

                                    <div className={'tableCol'}>
                                        <div className={'shortCol'}>
                                            {numeral(option.sizer.underwritten.currentDebtService).format('$0,0')}
                                        </div>
                                        <div className={'shortCol'}>
                                            {numeral(option.sizer.actual.currentDebtService).format('$0,0')}
                                        </div>
                                    </div>

                                    <div className={'tableCol'}>
                                        <div className={'shortCol loanBold'}>
                                            {numeral(
                                                option.sizer.underwritten.availableForSupplementalDebtService,
                                            ).format('$0,0')}
                                        </div>
                                        <div className={'shortCol loanBold'}>
                                            {numeral(option.sizer.actual.availableForSupplementalDebtService).format(
                                                '$0,0',
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {((isArm && option.isArm) || !isArm) && (
                                <div className={'tableCol selectOfferRaw'}>
                                    <div className={'shortCol'}></div>
                                    <div className={'shortCol'}>
                                        <div className={'selectOfferDiv'}>
                                            <div
                                                className={'selectOfferBtn'}
                                                onClick={() => {
                                                    if (option.isSelected) {
                                                        changeOptionSelected(idx, false)
                                                    } else {
                                                        selectOffer(idx)
                                                    }
                                                }}
                                            >
                                                {option.isSelected ? 'Deselect' : 'Select Offer'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export function LoanScenariosColumn({ option, idx, isArm, allOptions, isSupplemental, form }: Props) {
    const isReadOnly = isArm && !option.isDefaultFixedRate && !option.isArm
    const showRightMargin = useMemo(() => allOptions?.filter((item) => item.isArm).length > 1, [allOptions])
    const selectOption = (!isArm && option.isSelected) || (isArm && option.isSelected && option.isArm)

    const { formVisited, setFormVisited } = useContext(FormContext)

    let prepaymentOptions = []
    const prepaymentYears = [5, 7, 10, 12, 15, 18, 30]
    const loanTerm = option.scenario.underwritten.financeOptions.loanTerm

    if (!!loanTerm) {
        if (prepaymentYears.includes(loanTerm)) {
            prepaymentOptions = prepaymentOptionsList[`${loanTerm}`]
        } else if (loanTerm >= 19 && loanTerm <= 29) {
            prepaymentOptions = prepaymentOptionsList['19-29']
        }
    }

    const [firstLoad, setFirstLoad] = useState(true)

    const prevLoanTerm = usePrevious(loanTerm)

    useEffect(() => {
        if (!option.isArm && !isSupplemental) {
            if (
                !firstLoad &&
                // @ts-ignore
                option.scenario.underwritten.financeOptions.loanTerm?.toString() !== prevLoanTerm?.toString()
            ) {
                if (loanTerm) {
                    if (prepaymentOptions.length) {
                        const elem = prepaymentOptions.find(({ value }) => +value === loanTerm - 0.5)
                        if (elem) {
                            form.batch(() => {
                                form.change(
                                    `fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`,
                                    elem.value,
                                )
                                form.change(
                                    `fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepayment`,
                                    elem.value,
                                )
                            })
                        } else {
                            form.batch(() => {
                                form.change(
                                    `fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`,
                                    null,
                                )
                                form.change(
                                    `fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepayment`,
                                    null,
                                )
                            })
                        }
                    } else {
                        form.batch(() => {
                            form.change(
                                `fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`,
                                loanTerm - 0.5,
                            )
                            form.change(
                                `fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepayment`,
                                loanTerm - 0.5,
                            )
                        })
                    }
                } else {
                    form.batch(() => {
                        form.change(
                            `fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`,
                            null,
                        )
                        form.change(
                            `fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepayment`,
                            null,
                        )
                    })
                }
            } else {
                setFirstLoad(false)
            }
        }
    }, [loanTerm])

    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: isArm && option.isArm && showRightMargin ? 20 : 0,
                        borderLeft: isArm && !option.isArm ? '1px solid #eceff2' : 'none',
                    }}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}></div>
                    ) : (
                        <div className={'scenarioCol' + (selectOption ? ' selectedOptions' : '')}>
                            <div className={'tableCol grouptitle'}>{/* loan terms section */}</div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.loanTerm`}
                                        numberFormat={'decimal'}
                                        readOnly={isSupplemental || isReadOnly}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.loanTerm`}
                                        numberFormat={'decimal'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <TextField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.intrestType`}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <TextField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.intrestType`}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <Field
                                        component={WhiteReactSelectAdapter}
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.tier`}
                                        defaultValue={tierList[0].value}
                                        options={tierList}
                                        isDisabled={isReadOnly}
                                        onOpenedCb={() => {
                                            if (formVisited?.pricing === false) {
                                                setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    pricing: true,
                                                }))
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.tier`}
                                        numberFormat={'number'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.io`}
                                        numberFormat={'decimal'}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.io`}
                                        numberFormat={'decimal'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol' + (isLoanScnarionDSCRAlert(option) ? ' redBG' : '')}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.minDSCR`}
                                        numberFormat={'decimal'}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.minDSCR`}
                                        numberFormat={'decimal'}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.maxLTV`}
                                        numberFormat={'percent'}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.maxLTV`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.amortization`}
                                        numberFormat={'decimal'}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.amortization`}
                                        numberFormat={'decimal'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    {option.isArm ? (
                                        <TextField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepaymentAdjusted`}
                                            readOnly={isReadOnly}
                                        />
                                    ) : isSupplemental ? (
                                        <TextField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepayment`}
                                            readOnly={true}
                                        />
                                    ) : !prepaymentOptions.length ? (
                                        <TextField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`}
                                            readOnly={true}
                                        />
                                    ) : (
                                        <Field
                                            component={WhiteReactSelectAdapter}
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`}
                                            options={prepaymentOptions}
                                            isDisabled={isReadOnly || !prepaymentOptions.length}
                                            onOpenedCb={() => {
                                                if (formVisited?.pricing === false) {
                                                    setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        pricing: true,
                                                    }))
                                                }
                                            }}
                                            initialValue={
                                                prepaymentOptions.find(
                                                    ({ value }) =>
                                                        value?.toString() ===
                                                        option?.scenario?.underwritten?.financeOptions?.prepayment?.toString(),
                                                )?.value ||
                                                prepaymentOptions.find(({ value }) => +value === loanTerm - 0.5)
                                                    ?.value ||
                                                null
                                            }
                                        />
                                    )}
                                </div>

                                <div className={'shortCol'}>
                                    {option.isArm ? (
                                        <TextField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepaymentAdjusted`}
                                            readOnly={true}
                                        />
                                    ) : (
                                        <TextField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.prepayment`}
                                            readOnly={true}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <Field
                                        component={WhiteReactSelectAdapter}
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.intrestBasis`}
                                        defaultValue={InterestBasisList[0].value}
                                        options={InterestBasisList}
                                        isDisabled={isReadOnly}
                                        onOpenedCb={() => {
                                            if (formVisited?.pricing === false) {
                                                setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    pricing: true,
                                                }))
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <TextField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.intrestBasis`}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export function RateBreakdownColumn({ option, idx, isArm, allOptions }: Props) {
    const isReadOnly = isArm && !option.isDefaultFixedRate && !option.isArm
    const showRightMargin = useMemo(() => allOptions?.filter((item) => item.isArm).length > 1, [allOptions])
    const selectOption = (!isArm && option.isSelected) || (isArm && option.isSelected && option.isArm)

    const { formVisited, setFormVisited } = useContext(FormContext)

    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: isArm && option.isArm && showRightMargin ? 20 : 0,
                        borderLeft: isArm && !option.isArm ? '1px solid #eceff2' : 'none',
                    }}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}></div>
                    ) : (
                        <div className={'scenarioCol' + (selectOption ? ' selectedOptions' : '')}>
                            <div className={'tableCol grouptitle'}>{/* loan pricing section */}</div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <TextField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.pricingMethod`}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <TextField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.pricingMethod`}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <Field
                                        component={WhiteReactSelectAdapter}
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.refIndex`}
                                        defaultValue={
                                            option.isArm ? refIndexListARM[0].value : refIndexListFixed[0].value
                                        }
                                        options={option.isArm ? refIndexListARM : refIndexListFixed}
                                        isDisabled={isReadOnly}
                                        onOpenedCb={() => {
                                            if (formVisited?.pricing === false) {
                                                setFormVisited((prevState) => ({
                                                    ...prevState,
                                                    pricing: true,
                                                }))
                                            }
                                        }}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <TextField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.refIndex`}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.indexRate`}
                                        numberFormat={'percent'}
                                        readOnly={isReadOnly}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.indexRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.investorSpread`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.investorSpread`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.fannieMaeGuarantyFee`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.fannieMaeGuarantyFee`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.lenderServiceFee`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.lenderServiceFee`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.totalSpread`}
                                        numberFormat={'percent'}
                                        cssClassName={'loanBold'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.totalSpread`}
                                        numberFormat={'percent'}
                                        cssClassName={'loanBold'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            {isArm && (
                                <>
                                    <div className={'tableCol empty'}></div>
                                    {option.isArm ? (
                                        <div className={'tableCol'}>
                                            <div className={'shortCol'}>
                                                <NumberField
                                                    name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.stressFactor`}
                                                    numberFormat={'percent'}
                                                    readOnly={isReadOnly}
                                                />
                                            </div>
                                            <div className={'shortCol empty'}></div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className={'tableCol empty'}></div>
                                        </>
                                    )}
                                </>
                            )}
                            {isArm && (
                                <>
                                    {option.isArm ? (
                                        <div className={'tableCol'}>
                                            <div className={'shortCol empty'}></div>
                                            <div className={'shortCol'}>
                                                <NumberField
                                                    name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.strikeRate`}
                                                    numberFormat={'percent'}
                                                    readOnly={isReadOnly}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className={'tableCol empty'}></div>
                                        </>
                                    )}
                                </>
                            )}
                            {isArm && (
                                <>
                                    {option.isArm ? (
                                        <div className={'tableCol'}>
                                            <div className={'shortCol'}>
                                                <NumberField
                                                    name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.capCostFactor`}
                                                    numberFormat={'percent'}
                                                    readOnly={isReadOnly}
                                                />
                                            </div>
                                            <div className={'shortCol'}>
                                                <NumberField
                                                    name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.capCostFactor`}
                                                    numberFormat={'percent'}
                                                    readOnly={isReadOnly}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className={'tableCol empty'}></div>
                                        </>
                                    )}
                                    <div className={'tableCol empty'}></div>
                                </>
                            )}
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.todayEstimateIntrestRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                        alwaysShowTwoDecimals
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.todayEstimateIntrestRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                        alwaysShowTwoDecimals
                                    />
                                </div>
                            </div>
                            <div className={'tableCol blueBG'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.uwFloor`}
                                        numberFormat={'percent'}
                                        readOnly={isReadOnly}
                                        cssClassName={'pos1Loan'}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.uwFloor`}
                                        numberFormat={'percent'}
                                        readOnly={isReadOnly}
                                        cssClassName={'pos1Loan'}
                                    />
                                </div>
                            </div>
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.actualintrestRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                        alwaysShowTwoDecimals
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.actualintrestRate`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                        alwaysShowTwoDecimals
                                    />
                                </div>
                            </div>
                            {isArm && (
                                <div className={'tableCol'}>
                                    <div className={'shortCol'}>
                                        <NumberField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.uwLoanConstant`}
                                            numberFormat={'percent'}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className={'shortCol'}>
                                        <NumberField
                                            name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.uwLoanConstant`}
                                            numberFormat={'percent'}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={'tableCol'}>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.actualLoanConstant`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className={'shortCol'}>
                                    <NumberField
                                        name={`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.actualLoanConstant`}
                                        numberFormat={'percent'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export function FeesColumn({ option, idx, isSupplemental, isArm, allOptions }: Props) {
    const isReadOnly = isArm && !option.isDefaultFixedRate && !option.isArm
    const showRightMargin = useMemo(() => allOptions?.filter((item) => item.isArm).length > 1, [allOptions])
    const selectOption = (!isArm && option.isSelected) || (isArm && option.isSelected && option.isArm)

    const { formVisited, setFormVisited } = useContext(FormContext)

    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: isArm && option.isArm && showRightMargin ? 20 : 0,
                        borderLeft: isArm && !option.isArm ? '1px solid #eceff2' : 'none',
                    }}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}></div>
                    ) : (
                        <div className={'creditFeesCol' + (selectOption ? ' selectedOptions' : '')}>
                            <div className={'tableCol grouptitle'}></div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.amortization`}
                                    numberFormat={'decimal'}
                                    readOnly={isReadOnly}
                                    cssClassName={isReadOnly ? 'alignRight' : ''}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.loanTerm`}
                                    numberFormat={'decimal'}
                                    readOnly={isReadOnly}
                                    cssClassName={isReadOnly ? 'alignRight' : ''}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.io`}
                                    numberFormat={'decimal'}
                                    readOnly={isReadOnly}
                                    cssClassName={isReadOnly ? 'alignRight' : ''}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <TextField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.prepayment`}
                                    readOnly={true}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <Field
                                    component={WhiteReactSelectAdapter}
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.tier`}
                                    defaultValue={tierList[0].value}
                                    options={tierList}
                                    isDisabled={isReadOnly}
                                    onOpenedCb={() => {
                                        if (formVisited?.pricing === false) {
                                            setFormVisited((prevState) => ({
                                                ...prevState,
                                                pricing: true,
                                            }))
                                        }
                                    }}
                                />
                            </div>
                            {isSupplemental && (
                                <>
                                    <div className={'tableCol'}>
                                        <Field
                                            component={WhiteReactSelectAdapter}
                                            name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.supplementalType`}
                                            defaultValue={supplementalTypeList[0].value}
                                            options={supplementalTypeList}
                                            isDisabled={isReadOnly}
                                            onOpenedCb={() => {
                                                if (formVisited?.pricing === false) {
                                                    setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        pricing: true,
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={'tableCol'}>
                                        <Field
                                            component={WhiteReactSelectAdapter}
                                            name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.exercisingTierDroppingOption`}
                                            defaultValue={exercisingTierDroppingOptionList[0].value}
                                            options={exercisingTierDroppingOptionList}
                                            isDisabled={isReadOnly}
                                            onOpenedCb={() => {
                                                if (formVisited?.pricing === false) {
                                                    setFormVisited((prevState) => ({
                                                        ...prevState,
                                                        pricing: true,
                                                    }))
                                                }
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <div className={'tableCol'} style={{ display: 'none' }}>
                                <Field
                                    component={WhiteReactSelectAdapter}
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.propertyType`}
                                    defaultValue={propertyTypeList[0].value}
                                    options={propertyTypeList}
                                    isDisabled={isReadOnly}
                                    onOpenedCb={() => {
                                        if (formVisited?.pricing === false) {
                                            setFormVisited((prevState) => ({
                                                ...prevState,
                                                pricing: true,
                                            }))
                                        }
                                    }}
                                />
                            </div>
                            <div className={'tableCol emoty'}></div>
                            <div className={'tableCol grouptitle'}></div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.pricingFees.targetedGS`}
                                    numberFormat={'number'}
                                    readOnly={isReadOnly}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <Field
                                    component={WhiteReactSelectAdapter}
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.pricingFees.greenFinancingType`}
                                    defaultValue={greenFinancingTypeList[0].value}
                                    options={greenFinancingTypeList}
                                    isDisabled={isReadOnly}
                                    onOpenedCb={() => {
                                        if (formVisited?.pricing === false) {
                                            setFormVisited((prevState) => ({
                                                ...prevState,
                                                pricing: true,
                                            }))
                                        }
                                    }}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <Field
                                    component={WhiteReactSelectAdapter}
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.pricingFees.rateLockType`}
                                    defaultValue={rateLockTypeList[0].value}
                                    options={rateLockTypeList}
                                    isDisabled={isReadOnly}
                                    onOpenedCb={() => {
                                        if (formVisited?.pricing === false) {
                                            setFormVisited((prevState) => ({
                                                ...prevState,
                                                pricing: true,
                                            }))
                                        }
                                    }}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.pricingFees.investorSpread`}
                                    numberFormat={'number'}
                                    readOnly={isReadOnly}
                                    allowNumberNegative
                                />
                            </div>
                            <div className={'tableCol alighRight'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.pricingFees.fannieMaeGuarantyFee`}
                                    readOnly={isReadOnly}
                                    numberFormat={'decimal'}
                                />
                            </div>
                            <div className={'tableCol alighRight'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.pricingFees.lenderServiceFee`}
                                    readOnly={isReadOnly}
                                    numberFormat={'decimal'}
                                />
                            </div>
                            <div className={'tableCol emoty'}></div>
                            <div className={'tableCol grouptitle'}></div>
                            <div className={'tableCol'}>
                                <OnlyNumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.spreadFees.investorSpread`}
                                    readOnly={isReadOnly}
                                    numberFormat={'decimal'}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <OnlyNumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.spreadFees.fannieMaeGuarantyFee`}
                                    readOnly={isReadOnly}
                                    numberFormat={'decimal'}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <OnlyNumberField
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.spreadFees.lenderServiceFee`}
                                    readOnly={isReadOnly}
                                    numberFormat={'decimal'}
                                />
                            </div>
                            <div className={'tableCol'} style={{ height: 120, paddingBottom: 10 }}>
                                <Field
                                    className="spreadFeesCommentBox"
                                    name={`fanniePricing.pricingOptions.${idx}.creditFees.spreadFees.comment`}
                                    readOnly={isReadOnly}
                                    component="textarea"
                                    style={{ ...(isReadOnly && { border: 0 }) }}
                                />
                            </div>
                        </div>
                    )}
                    <style jsx>{`
                        :global(.pricingComponent) {
                            :global(span.alignRight) {
                                display: block !important;
                            }
                        }
                        :global(.spreadFeesCommentBox) {
                            width: 100%;
                            border: 1px solid transparent;
                            background-color: transparent;
                            resize: none;
                            border: 1px solid lightgrey;
                            &:focus {
                                border: 1px solid #4485ff !important;
                                border-radius: 3px;
                                outline: none;
                            }
                        }
                    `}</style>
                </div>
            )}
        </>
    )
}

export function CapCostColumn({ option, idx }: Props) {
    const selectOption = option.isSelected && option.isArm
    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: 20,
                    }}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}></div>
                    ) : (
                        <div className={'capCostCol' + (selectOption ? ' selectedOptions' : '')}>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.capCost.capCost`}
                                    numberFormat={'currency'}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.capCost.capCostTerm`}
                                    numberFormat={'int'}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.capCost.costPerYear`}
                                    numberFormat={'currency'}
                                    readOnly={true}
                                    cssClassName={'alignRight'}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.capCost.loanAmount`}
                                    numberFormat={'currency'}
                                />
                            </div>
                            <div className={'tableCol blueBG'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.capCost.capCostFactor`}
                                    numberFormat={'percent'}
                                    readOnly={true}
                                    cssClassName={'alignRight pos1Loan'}
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.capCost.monthlyEscrow`}
                                    numberFormat={'currency'}
                                    readOnly={true}
                                    cssClassName={'alignRight'}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export function ExitAnalysisSummaryCol({ option, isArm, allOptions, changeField, idx, isSupplemental }: Props) {
    const formState = useFormState()
    const formValues = formState.values

    const refinanceAssumptions = useSelector(
        (state: ReduxStoreState) => state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions,
    )
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })

    const selectOption = (!isArm && option.isSelected) || (isArm && option.isSelected && option.isArm)

    const pi = useMemo(() => {
        const maxFirstPosLoan = option?.sizer?.actual?.maxFirstPosLoan
        const actualLoanConstant = option?.scenario?.actual?.loanPricing?.actualLoanConstant
        return calcPI(maxFirstPosLoan, actualLoanConstant)
    }, [option?.sizer?.actual?.maxFirstPosLoan, option?.scenario?.actual?.loanPricing?.actualLoanConstant])

    // const balloon = useMemo(() => {
    //     const actualRate = option?.scenario?.actual?.loanPricing?.actualintrestRate
    //     const loanTerm = option?.scenario?.actual?.financeOptions?.loanTerm
    //     const io = option?.scenario?.actual?.financeOptions?.io
    //     const maxFirstPosLoan = option?.sizer?.actual?.maxFirstPosLoan
    //     const factor = 1
    //     return calcBalloon(actualRate, loanTerm, io, maxFirstPosLoan, pi, factor)
    // }, [
    //     option?.scenario?.actual?.loanPricing?.actualintrestRate,
    //     option?.scenario?.actual?.financeOptions?.loanTerm,
    //     option?.scenario?.actual?.financeOptions?.io,
    //     pi,
    //     option?.sizer?.actual?.maxFirstPosLoan,
    // ])

    const balloon = useMemo(() => {
        const loanTerm = option?.scenario?.actual?.financeOptions?.loanTerm
        const loanAmount = option?.sizer?.actual?.loanAmount || 0
        const amortizationTermYears = option?.scenario?.actual?.financeOptions?.amortization || 30
        const rate = option?.scenario?.actual?.loanPricing?.actualintrestRate || 0
        const ioPeriodYears = option?.scenario?.actual?.financeOptions?.io || 0
        //const unit = 'year'

        const unit = 'month'
        const startDate = model?.fanniePricing?.loanInfo?.dateOfPricing
            ? new Date(model.fanniePricing.loanInfo.dateOfPricing)
            : new Date()
        const debtService = calcDebtService({
            loanAmount,
            amortizationTermYears,
            rate,
            ioPeriodYears,
            unit,
            periodYears: amortizationTermYears,
            firstYear: startDate.getFullYear(),
            firstMonth: startDate.getMonth(),
        })

        // const balloon = debtService?.[loanTerm ? +loanTerm.toFixed(0) - 1 : 0]?.startingBalance
        const balloon = debtService?.[loanTerm ? +loanTerm.toFixed(0) * 12 : 0]?.startingBalance

        return balloon
    }, [
        model?.fanniePricing?.loanInfo?.dateOfPricing,
        option?.scenario?.actual?.financeOptions?.loanTerm,
        option?.sizer?.actual?.loanAmount,
        option?.scenario?.actual?.financeOptions?.amortization,
        option?.scenario?.actual?.loanPricing?.actualintrestRate,
        option?.scenario?.actual?.financeOptions?.io,
    ])

    const originalLoanBallon = useMemo(() => {
        if (isSupplemental && !!model?.fanniePricing?.supplemental?.[0]) {
            const originalLoan = model.fanniePricing.supplemental[0]

            const loanTerm = option?.scenario?.actual?.financeOptions?.loanTerm

            const originalClosingDate = new Date(originalLoan?.priorLien?.closed)
            const currentClosingDate = model?.fanniePricing?.loanInfo?.dateOfPricing
                ? new Date(model.fanniePricing.loanInfo.dateOfPricing)
                : new Date()
            const timeDiff = currentClosingDate.getTime() - originalClosingDate.getTime()
            const monthDiff = timeDiff / (1000 * 3600 * 24) / 30

            const loanAmount = originalLoan?.priorLien?.upbOriginalLoanAmount || 0
            const amortizationTermYears = originalLoan?.priorLien?.amortizationYears || 30
            const rate = originalLoan?.priorLien?.intrestRate || 0
            const ioPeriodYears = originalLoan?.priorLien?.ioYears - +(monthDiff / 12).toFixed(0) - 1 || 0
            //const unit = 'year'

            const unit = 'month'

            const periodYears = originalLoan?.terms?.termYears || 10
            const debtService = calcDebtService({
                loanAmount,
                amortizationTermYears,
                rate,
                ioPeriodYears,
                unit,
                periodYears: amortizationTermYears,
                firstYear: originalClosingDate.getFullYear(),
                firstMonth: originalClosingDate.getMonth(),
            })
            // const balloon =  debtService?.[loanTerm ? +loanTerm.toFixed(0) - 1 : 0]?.startingBalance
            const balloon = debtService?.[loanTerm ? +loanTerm.toFixed(0) * 12 : 0]?.startingBalance

            return balloon
        }
        return 0
    }, [
        model?.fanniePricing?.supplemental,
        isSupplemental,
        option?.scenario?.actual?.financeOptions?.loanTerm,
        model?.fanniePricing?.loanInfo?.dateOfPricing,
    ])

    // const totalBallon = useMemo(() => {
    //     if (!isSupplemental) return 0

    // }, [isSupplemental])

    useEffect(() => {
        const loanTerm = option?.scenario?.actual?.financeOptions?.loanTerm
        if (!!loanTerm && (!!refinanceAssumptions?.[0]?.ncf?.years || !!cashFlow?.noi?.years)) {
            const value = !!refinanceAssumptions?.[0]?.ncf?.years
                ? refinanceAssumptions[0].ncf.years[loanTerm ? +loanTerm.toFixed(0) : 0]
                : cashFlow?.noi?.years[loanTerm ? +loanTerm.toFixed(0) : 0]
            if (!!value) {
                changeField(idx, 'standard', 'ncf', value)
            }
        }
    }, [
        refinanceAssumptions?.[0]?.ncf?.years,
        option?.scenario?.actual?.financeOptions?.loanTerm,
        cashFlow?.noi?.years,
    ])

    useEffect(() => {
        const loanTerm = option?.scenario?.actual?.financeOptions?.loanTerm
        if (!!loanTerm && (!!refinanceAssumptions?.[1]?.ncf?.years || !!cashFlow?.noi?.years)) {
            const value = !!refinanceAssumptions?.[1]?.ncf?.years
                ? refinanceAssumptions[1].ncf.years[loanTerm ? +loanTerm.toFixed(0) : 0]
                : cashFlow?.noi?.years[loanTerm ? +loanTerm.toFixed(0) : 0]
            if (!!value) changeField(idx, 'alternative', 'ncf', value)
        }
    }, [
        refinanceAssumptions?.[1]?.ncf?.years,
        option?.scenario?.actual?.financeOptions?.loanTerm,
        cashFlow?.noi?.years,
    ])

    useEffect(() => {
        const amortization = refinanceAssumptions?.[0]?.assumptions?.amortization || 30
        const dscr = refinanceAssumptions?.[0]?.assumptions?.dscr || 1.25
        const ncf = formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.ncf

        const result = calcExitRefiRate(amortization, ncf, dscr, balloon + originalLoanBallon)
        if (!!result) changeField(idx, 'standard', 'refiRate', result)
    }, [
        refinanceAssumptions?.[0]?.assumptions?.amortization,
        formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.ncf,
        refinanceAssumptions?.[0]?.assumptions?.dscr,
        balloon,
        originalLoanBallon,
    ])

    useEffect(() => {
        const amortization = refinanceAssumptions?.[1]?.assumptions?.amortization || 30
        const dscr = refinanceAssumptions?.[1]?.assumptions?.dscr || 1.25
        const ncf = formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.ncf
        const result = calcExitRefiRate(amortization, ncf, dscr, balloon + originalLoanBallon)
        if (!!result) changeField(idx, 'alternative', 'refiRate', result)
    }, [
        refinanceAssumptions?.[1]?.assumptions?.amortization,
        formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.ncf,
        refinanceAssumptions?.[1]?.assumptions?.dscr,
        balloon,
        originalLoanBallon,
    ])

    useEffect(() => {
        const ltv = refinanceAssumptions?.[0]?.assumptions?.ltv || 0.8
        const ncf = formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.ncf
        const result = calcExitCapRate(ncf, balloon + originalLoanBallon, ltv)
        if (!!result) changeField(idx, 'standard', 'capRate', result)
    }, [
        formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.ncf,
        balloon,
        refinanceAssumptions?.[0]?.assumptions?.ltv,
        originalLoanBallon,
    ])

    useEffect(() => {
        const ltv = refinanceAssumptions?.[1]?.assumptions?.ltv || 0.8
        const ncf = formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.ncf
        const result = calcExitCapRate(ncf, balloon + originalLoanBallon, ltv)
        if (!!result) changeField(idx, 'alternative', 'capRate', result)
    }, [
        formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.ncf,
        balloon,
        refinanceAssumptions?.[1]?.assumptions?.ltv,
        originalLoanBallon,
    ])

    const showRightMargin = useMemo(() => allOptions?.filter((item) => item.isArm).length > 1, [allOptions])

    const standardRefiRate = useMemo(
        () => formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.refiRate,
        [formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.refiRate],
    )

    const standardCapRate = useMemo(
        () => formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.capRate,
        [formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.standard?.capRate],
    )

    const alternativeRefiRate = useMemo(
        () => formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.refiRate,
        [formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.refiRate],
    )

    const alternativeCapRate = useMemo(
        () => formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.capRate,
        [formValues.fanniePricing?.pricingOptions?.[idx]?.exitAnalysis?.alternative?.capRate],
    )

    return (
        <>
            {option && (
                <div
                    className={'pricingComponent'}
                    style={{
                        marginRight: isArm && option.isArm && showRightMargin ? 20 : 0,
                        borderLeft: isArm && !option.isArm ? '1px solid #eceff2' : 'none',
                    }}
                >
                    {option.isCollapsed ? (
                        <div className={'collapsedCol'}></div>
                    ) : (
                        <div className={'capCostCol' + (selectOption ? ' selectedOptions' : '')}>
                            <div className={'tableCol grouptitle'}></div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.exitAnalysis.standard.ncf`}
                                    numberFormat={'currency'}
                                    readOnly
                                />
                            </div>

                            <div className={`tableCol ${!!standardRefiRate && standardRefiRate < 0.06 ? 'redBG' : ''}`}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.exitAnalysis.standard.refiRate`}
                                    numberFormat={'percent'}
                                    readOnly
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.exitAnalysis.standard.capRate`}
                                    numberFormat={'percent'}
                                    readOnly
                                />
                            </div>
                            <div className={'tableCol empty'}></div>
                            <div className={'tableCol grouptitle'}></div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.exitAnalysis.alternative.ncf`}
                                    numberFormat={'currency'}
                                    readOnly
                                />
                            </div>

                            <div
                                className={`tableCol ${
                                    !!alternativeRefiRate && alternativeRefiRate < 0.06 ? 'redBG' : ''
                                }`}
                            >
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.exitAnalysis.alternative.refiRate`}
                                    numberFormat={'percent'}
                                    readOnly
                                />
                            </div>
                            <div className={'tableCol'}>
                                <NumberField
                                    name={`fanniePricing.pricingOptions.${idx}.exitAnalysis.alternative.capRate`}
                                    numberFormat={'percent'}
                                    readOnly
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
