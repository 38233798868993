import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../store'
import { FixedHeaderWrapper } from './components/FixedHeaderWraper'
import { CashFlowHeader } from './components/CashFlowHeader'
import { RowTitles } from './components/RowTitles'
import { RowValues } from './components/RowValues'
import { CashFlowCommonButtons } from '../../../../components/CashFlowCommonButtons/CashFlowCommonButtons'
import { SelectBox } from './components/AnnualizSelectBox/SelectBox'
import { CheckboxItem } from './components/Checkbox/CheckboxItem'
import { CommentWrapper } from './components/Comment/Comment'

type Props = any
export const LenderCashFlow = React.memo(function LenderCashFlow(props: Props) {
    const t12History = useSelector((store: ReduxStoreState) => store.lender.t12History)
    const proformas = useSelector((store: ReduxStoreState) => store.lender.proforma)

    const cashFlowColumns = useMemo(() => {
        const out = [
            { value: 't1', label: 'Annualized T1', header: 'T1' },
            { value: 't3', label: 'Annualized T3', header: 'T3' },
            { value: 't6', label: 'Annualized T6', header: 'T6' },
            { value: 't9', label: 'Annualized T9', header: 'T9' },
            { value: 't12', label: 'Annualized T12', header: 'T12' },
        ]
        for (const item of t12History) {
            out.push({ value: `history${item.year}`, label: `Year ${item.year}`, header: `${item.year}` })
        }
        if (proformas.length > 0) {
            for (const proforma of proformas) {
                for (const i in proforma.noi.years) {
                    if (proforma.label && proforma.label != '') {
                        out.push({
                            value: 'proforma' + proforma.label + (proforma.noi.years.length > 1 ? '' + (+i + 1) : ''),
                            label: proforma.label + (proforma.noi.years.length > 1 ? ' Year ' + (+i + 1) : ''),
                            header: proforma.label + (proforma.noi.years.length > 1 ? ' Y' + (+i + 1) : ''),
                        })
                    } else {
                        out.push({
                            value: `proforma${+i + 1}`,
                            label: `Proforma Year ${+i + 1}`,
                            header: `proforma Y${+i + 1}`,
                        })
                    }
                }
            }
        }
        //console.log('cashFlowColumns', out)
        return out
    }, [t12History, proformas])
    const cashFlowSelectedColumns = useSelector(
        (store: ReduxStoreState) => store.lenderDashboard.lenderCashFlowTab?.columnsSelected,
    )
    const defaultPercentageStatus = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard?.lenderCashFlowTab?.showPercentage,
    )
    const defaultCommentsStatus = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard?.lenderCashFlowTab?.showComments,
    )
    const [togglePercentage, setTogglePercentage] = useState(defaultPercentageStatus)
    const [toggleComments, setToggleComments] = useState(defaultCommentsStatus)
    const changePercentageStatus = useCallback(
        (newStatus) => {
            setTogglePercentage(newStatus)
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showPercentage'], newStatus)
        },
        [togglePercentage],
    )
    const changeCommentsStatus = useCallback(
        (newStatus) => {
            setToggleComments(newStatus)
            ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showComments'], newStatus)
        },
        [toggleComments],
    )

    return (
        <>
            {!props.isPDF && (
                <div className={'cash-flow-option-container'}>
                    <div className={'select-wrapper'}>
                        <span>Compare to</span>
                        <SelectBox data={cashFlowColumns} defaultSelectedColumns={cashFlowSelectedColumns} />
                    </div>
                    <div className={'checkbox-block'}>
                        <CheckboxItem
                            changeCheckedItem={changePercentageStatus}
                            name={'Show as percentage'}
                            checked={togglePercentage}
                        />
                    </div>
                    <div className={'checkbox-block'}>
                        <CheckboxItem
                            changeCheckedItem={changeCommentsStatus}
                            name={'Comments'}
                            checked={toggleComments}
                        />
                    </div>
                </div>
            )}
            <div className={'content-container'}>
                {!props.isPDF && <CashFlowCommonButtons saveDeal={props.saveDeal} />}
                <FixedHeaderWrapper
                    leftHeader={<div style={{ background: 'white' }} />}
                    scrollableHeader={<CashFlowHeader columns={cashFlowColumns} isPDF={props.isPDF} />}
                    leftContent={<RowTitles isPDF={props.isPDF} />}
                    content={<RowValues isPDF={props.isPDF} />}
                    leftWidth={props.isPDF ? '550px' : '300px'}
                    rightContent={props.isPDF ? null : <CommentWrapper />}
                    isPDF={props.isPDF}
                />
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .content-container {
                        background: white;
                        width: 100%;
                    }
                    .notes-button-wrapper {
                        display: inline-flex;
                        align-items: center;
                        height: 36px;
                        padding: 0 18.5px;
                        border-radius: 3px;
                        margin-right: 8px;
                        span {
                            cursor: pointer;
                        }
                        .edit-notes-button {
                            width: 10px;
                            height: auto;
                            border-top: 0.3em solid;
                            border-right: 0.3em solid transparent;
                            border-bottom: 0;
                            border-left: 0.3em solid transparent;
                        }
                    }

                    .buttons-bar {
                        position: absolute;
                        right: 0;
                        top: -60px;
                        display: flex;
                        align-items: center;
                    }
                    @media (max-width: 1025px) {
                        .buttons-bar {
                            position: relative;
                            right: 0;
                            top: 0;
                            margin-bottom: 10px;
                        }
                    }
                    .cash-flow-option-container {
                        margin-top: 23px;
                        margin-bottom: 18px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .select-wrapper {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-right: 30px;

                            > span {
                                font-size: 15px;
                                line-height: 18px;
                                color: $dark-gray;
                                margin-right: 10px;
                            }
                        }
                        .checkbox-block {
                            &:not(:last-child) {
                                margin-right: 22px;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
})
