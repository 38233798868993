import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import { FormApi } from 'final-form'
import { Form } from 'react-final-form'
import { Spinner } from '../../../../../controls/Spinner'
import { removeItemFromArray } from '../../../../../../utils'
import { getPricingDecorator } from './calculations'
import { getIndexIntrstRate, newPricingOptions, refIndexListARM, refIndexListFixed } from './lib'
import { propertyTypeList } from '../../../../project-wizard/steps/GeneralInputs/PropertyAndBorrower'
import { greenFinancingTypeList } from '../../../../project-wizard/steps/GeneralInputs/DealDetails'
import { FormContext } from '../../../Dashboard'
import {
    regenerateCashFlow,
    calcDscRequestedLoanAmount,
    getRecentAquisitionLoanLimit,
    getValueConstrainedLoan,
    getCapRate,
    getDebtServiceConstrainedLoan,
} from 'origination-model'

const cloneDeep = require('lodash.clonedeep')

enum ARMType {
    structured = 'Structured',
    notStructured = 'ARM 7-6',
}

type Props = any

export const FormWrapper = React.memo(function FormWrapper({ isArmPage, isNM, component: Component }: Props) {
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })
    const localContext = useMemo(() => ({ form: null as FormApi }), [])
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const { formModified, setFormModified, setFormModifiedViaMutators, formVisited, setFormVisited } =
        useContext(FormContext)

    const isPricing = useMemo(() => {
        const isModel = model != null ? true : false
        const isgeneralInputs = generalInputs != null ? true : false
        const isProject = project != null ? true : false
        return pricing?.fanniePricing?.pricingOptions && isgeneralInputs && isModel && isProject
    }, [pricing?.fanniePricing?.pricingOptions, generalInputs, model, project])

    const isSupplemental = useMemo(() => {
        if (project?.type == 'miltifamily.value_add_supplemental') return true
        return false
    }, [project?.type])

    const borrowerLoanAmount = useMemo(() => {
        const borrowerLoanAmount = generalInputs?.loanDetails?.requestedLoanAmount || 0
        if (localContext.form) {
            localContext.form.change('computed.borrowerLoanAmount', borrowerLoanAmount)
        }
        return borrowerLoanAmount
    }, [generalInputs?.loanDetails?.requestedLoanAmount, localContext.form])

    const ncf = useMemo(() => {
        const currentNcf = cashFlow?.noi?.adjustedT12 || 0
        if (localContext.form) {
            localContext.form.change('computed.ncf', cashFlow?.noi?.adjustedT12 || 0)
        }
        return currentNcf
    }, [cashFlow?.noi, localContext.form])

    const purchasePrice = useMemo(() => {
        const cPurchasePrice = model?.uses?.purchasePrice || 0
        if (localContext.form) {
            localContext.form.change('computed.purchasePrice', model?.uses?.purchasePrice || 0)
        }
        return cPurchasePrice
    }, [model?.uses?.purchasePrice, localContext.form])

    const underwrittenPropertyValue = useMemo(() => {
        let capRate = 0.0525
        if (model?.fanniePricing?.valuationComparison?.length > 0) {
            for (const v of model?.fanniePricing.valuationComparison) {
                if (v.selected) capRate = v.capRate
            }
        }
        const cUnderwrittenPropertyValue = capRate ? ncf / capRate : 0
        if (localContext.form) {
            localContext.form.change('computed.underwrittenPropertyValue', cUnderwrittenPropertyValue)
        }
        return cUnderwrittenPropertyValue
    }, [ncf, model?.fanniePricing?.valuationComparison, localContext.form])

    const getUPBatClosing = useCallback(() => {
        if (!isSupplemental || !model?.fanniePricing?.supplemental) return 0
        let total = 0
        if (model?.fanniePricing?.supplemental)
            for (const sup of model?.fanniePricing.supplemental) {
                total += sup.priorLien.estimatedUpbAtSupClosing
            }
        return total
    }, [isSupplemental, model?.fanniePricing.supplemental])

    const getOldLoanDebtService = useCallback(() => {
        if (!isSupplemental || !model?.fanniePricing?.supplemental) return 0
        let total = 0
        if (model?.fanniePricing?.supplemental)
            for (const sup of model?.fanniePricing.supplemental) {
                /*
          const ds = calcDebtService({
              loanAmount: sup.priorLien.upbOriginalLoanAmount,
              amortizationTermYears: sup.priorLien.amortizationYears
              rate: sup.priorLien.intrestRate,
              ioPeriodYears: sup.priorLien.ioYears,
              unit?: 'year',
              periodYears: sup.priorLien.amortizationYears

          })
          total += ds

           */
                total += sup.priorLien.anualAmortization
            }
        return total
    }, [isSupplemental, model?.fanniePricing.supplemental])

    const proposedEstimatedFirstPayment = useMemo(() => {
        const date = new Date(generalInputs.loanDetails.targetLoanClosing)
        const newDate = new Date(date)
        newDate.setDate(1)
        newDate.setMonth(newDate.getMonth() + (date.getDate() === 1 ? 1 : 2))
        return newDate.toString()
    }, [generalInputs.loanDetails.targetLoanClosing])

    const calculatorMaturityDate = useMemo(() => {
        return model?.fanniePricing?.supplemental?.[0]?.terms.maturityDate
        // const targetLoanClosing = new Date(generalInputs.loanDetails.targetLoanClosing)
        // const requestedLoanTerm = generalInputs.loanDetails.requestedLoanTerm || 10

        // return new Date(targetLoanClosing.setMonth(targetLoanClosing.getMonth() + requestedLoanTerm * 12)).toString()
    }, [
        generalInputs.loanDetails.targetLoanClosing,
        generalInputs.loanDetails.requestedLoanTerm,
        model?.fanniePricing?.supplemental,
    ])

    const loanTermInMonths = useMemo(() => {
        // const requestedLoanTerm = generalInputs.loanDetails.requestedLoanTerm || 10
        // return requestedLoanTerm * 12
        const date = new Date(proposedEstimatedFirstPayment)
        const timeDiff = new Date(calculatorMaturityDate).getTime() - date.getTime()
        const monthDiff = timeDiff / (1000 * 3600 * 24) / 30

        return monthDiff
    }, [calculatorMaturityDate, proposedEstimatedFirstPayment])

    const initialValues = useMemo(() => {
        if (!isPricing) return null

        const loanTermInYearsSupplemental = parseFloat((loanTermInMonths / 12).toFixed(2))

        if (!isArmPage && pricing?.fanniePricing?.pricingOptions && pricing.fanniePricing.pricingOptions.length == 0) {
            const UPBatClosing = getUPBatClosing()
            const obj = newPricingOptions(
                generalInputs?.propertyAndBorrower?.propertyType || propertyTypeList[0].value,
                generalInputs?.dealDetails?.greenFinancingType || greenFinancingTypeList[0].value,
                generalInputs?.loanDetails?.requestedLoanAmount,
                isSupplemental,
                UPBatClosing,
                undefined,
                undefined,
                loanTermInYearsSupplemental,
            )
            obj.id = 1
            obj.name = isSupplemental ? 'FNMA Supplemental Fixed #1' : 'FNMA Fixed #1'
            obj.sizer.underwritten.valueConstrainedLoan = getValueConstrainedLoan(
                underwrittenPropertyValue,
                obj.scenario.underwritten.financeOptions.maxLTV,
            )
            obj.sizer.actual.valueConstrainedLoan = getValueConstrainedLoan(
                underwrittenPropertyValue,
                obj.scenario.actual.financeOptions.maxLTV,
            )

            obj.sizer.underwritten.recentAquisitionLoanLimit =
                getRecentAquisitionLoanLimit(purchasePrice, obj.scenario.underwritten.financeOptions.maxLTV) -
                UPBatClosing

            obj.sizer.actual.recentAquisitionLoanLimit =
                getRecentAquisitionLoanLimit(purchasePrice, obj.scenario.actual.financeOptions.maxLTV) - UPBatClosing

            if (isSupplemental) {
                obj.sizer.underwritten.underwrittenNCF = obj.sizer.actual.underwrittenNCF = ncf

                const oldLoanDebtService = getOldLoanDebtService()

                obj.sizer.underwritten.currentDebtService = obj.sizer.actual.currentDebtService = oldLoanDebtService

                const underwrittenAvailableForDebtService = obj.scenario.underwritten.financeOptions.minDSCR
                    ? ncf / obj.scenario.underwritten.financeOptions.minDSCR
                    : 0

                obj.sizer.underwritten.availableForDebtService = underwrittenAvailableForDebtService

                const actualAvailableForDebtService = obj.scenario.actual.financeOptions.minDSCR
                    ? ncf / obj.scenario.actual.financeOptions.minDSCR
                    : 0

                obj.sizer.actual.availableForDebtService = actualAvailableForDebtService

                obj.sizer.underwritten.availableForSupplementalDebtService =
                    underwrittenAvailableForDebtService - oldLoanDebtService

                obj.sizer.actual.availableForSupplementalDebtService =
                    actualAvailableForDebtService - oldLoanDebtService
            }

            const types = ['underwritten', 'actual']
            for (const type of types) {
                const loanAmount = obj.sizer?.[type]?.loanAmount || 0
                const combinedUPB = obj.sizer?.[type]?.combinedUPB || 0
                const maxLTV = obj.scenario?.[type]?.financeOptions?.maxLTV || 0
                if (((!isSupplemental && loanAmount) || (isSupplemental && combinedUPB)) && maxLTV) {
                    const capRate = getCapRate(ncf, isSupplemental ? combinedUPB : loanAmount, maxLTV)
                    obj.sizer[type].capRate = parseFloat(capRate)
                }
            }

            pricing.fanniePricing.pricingOptions.push(obj)
        }

        const cloned = cloneDeep(pricing)
        // console.log('init', pricing)
        if (isSupplemental) {
            cloned.fanniePricing.pricingOptions.forEach((option) => {
                option.scenario.underwritten.financeOptions.loanTerm = loanTermInYearsSupplemental
                option.scenario.actual.financeOptions.loanTerm = loanTermInYearsSupplemental
                option.creditFees.loanTerms.loanTerm = loanTermInYearsSupplemental
                option.creditFees.loanTerms.prepayment = loanTermInYearsSupplemental - 0.5
                option.scenario.underwritten.financeOptions.prepayment = loanTermInYearsSupplemental - 0.5
                option.scenario.actual.financeOptions.prepayment = loanTermInYearsSupplemental - 0.5
            })
        }

        //until first reply from server pricing is null

        const computed = {
            ncf: ncf,
            purchasePrice: purchasePrice,
            underwrittenPropertyValue: underwrittenPropertyValue,
            refreshIndexRate: 0,
        }
        return {
            ...cloned,
            computed: computed,
            armType: generalInputs.loanDetails.ARMType,
        }
    }, [isPricing, isSupplemental, underwrittenPropertyValue, purchasePrice, loanTermInMonths])

    const onSubmit = useCallback(
        (values: any) => {
            const cloned = cloneDeep(values)
            // console.log('cloned', cloned)

            if (!formModified.pricing && formVisited?.pricing) {
                setFormModified((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }

            ReduxHelper.setIn(['lender', 'pricing'], cloned)
        },
        [isPricing, formVisited?.pricing, formModified?.pricing],
    )

    const dealType = useMemo(() => {
        return generalInputs?.loanDetails?.acquisitionOrRefinance || 'acquisition'
    }, [generalInputs?.loanDetails?.acquisitionOrRefinance])

    const isAcquisition = useMemo(() => {
        return dealType.toLowerCase() == 'acquisition'
    }, [dealType])

    const [isDebugMode, setDebugMode] = useState(false)

    /*
    useEffect(() => {
        if (localStorage) {
            const debug = localStorage.getItem('debug.pricing')
            console.log(debug)
            if (debug == 'true') {
                setDebugMode(true)
                console.log('set debug')
            }
        }
    }, [])

     */
    const computedValues = useMemo(() => {
        //  console.log('computedValues  ', cloneDeep(pricing))
        if (!isPricing) return null
        const origUnderwritenLTV = {}
        const origUnderwritenDSCRs = {}
        const origActualDSCRs = {}
        const origIndexRate = {}
        if (pricing?.fanniePricing?.pricingOptions)
            for (let i = 0; i < pricing?.fanniePricing?.pricingOptions?.length; i++) {
                const option = pricing?.fanniePricing.pricingOptions[i]
                origUnderwritenLTV[i] = option.scenario.underwritten.financeOptions.maxLTV
                origUnderwritenDSCRs[i] = option.scenario.underwritten.financeOptions.minDSCR
                origActualDSCRs[i] = option.scenario.actual.financeOptions.minDSCR
                origIndexRate[i] = option.scenario.underwritten.loanPricing.indexRate
            }

        return getPricingDecorator(
            origUnderwritenLTV,
            origUnderwritenDSCRs,
            origActualDSCRs,
            origIndexRate,
            isSupplemental,
            getUPBatClosing(),
            getOldLoanDebtService(),
            generalInputs?.loanDetails?.requestedLoanAmount,
            generalInputs?.dealDetails?.escrowedImprovements || 0,
            isNM,
            generalInputs?.loanDetails?.ARMType,
            isArmPage,
            model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
            isAcquisition,
            isDebugMode,
        )
    }, [
        isPricing,
        isSupplemental,
        generalInputs?.loanDetails?.requestedLoanAmount,
        isNM,
        isArmPage,
        generalInputs?.loanDetails?.ARMType,
        model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
        isAcquisition,
        isDebugMode,
    ])

    const savedMaxPosLoan = useRef(null)

    const mutators = useMemo(() => {
        if (!isPricing) return null
        return {
            addItem(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
                const loanTermInYearsSupplemental = parseFloat((loanTermInMonths / 12).toFixed(2))
                const UPBatClosing = getUPBatClosing()
                const obj = newPricingOptions(
                    generalInputs?.propertyAndBorrower?.propertyType || propertyTypeList[0].value,
                    generalInputs?.dealDetails?.greenFinancingType || greenFinancingTypeList[0].value,
                    generalInputs?.loanDetails?.requestedLoanAmount,
                    isSupplemental,
                    UPBatClosing,
                    params[0]?.isArm,
                    params[0]?.fixedOptionReferenceId,
                    loanTermInYearsSupplemental,
                    params[0]?.fixedOptionLoanTerm
                )
                form.changeValue(state, `fanniePricing.pricingOptions`, (list) => {
                    let maxId = 0
                    for (const o of list) {
                        if (o.id > maxId) maxId = o.id
                    }

                    const idx = maxId + 1
                    obj.id = idx
                    obj.name =
                        (params[0]?.isArm
                            ? generalInputs?.loanDetails?.ARMType === ARMType.structured
                                ? 'Structured ARM #'
                                : 'ARM 7-6 #'
                            : isSupplemental
                            ? 'FNMA Supplemental Fixed #'
                            : 'FNMA Fixed #') + idx
                    obj.sizer.underwritten.valueConstrainedLoan = getValueConstrainedLoan(
                        underwrittenPropertyValue,
                        obj.scenario.underwritten.financeOptions.maxLTV,
                    )
                    obj.sizer.actual.valueConstrainedLoan = getValueConstrainedLoan(
                        underwrittenPropertyValue,
                        obj.scenario.underwritten.financeOptions.maxLTV,
                    )
                    obj.sizer.underwritten.recentAquisitionLoanLimit =
                        getRecentAquisitionLoanLimit(purchasePrice, obj.scenario.underwritten.financeOptions.maxLTV) -
                        UPBatClosing

                    obj.sizer.actual.recentAquisitionLoanLimit =
                        getRecentAquisitionLoanLimit(purchasePrice, obj.scenario.actual.financeOptions.maxLTV) -
                        UPBatClosing

                    if (params[0]?.isDefaultFixedRate) obj.isDefaultFixedRate = true

                    if (isSupplemental) {
                        obj.sizer.underwritten.underwrittenNCF = obj.sizer.actual.underwrittenNCF = ncf

                        const oldLoanDebtService = getOldLoanDebtService()

                        obj.sizer.underwritten.currentDebtService = obj.sizer.actual.currentDebtService =
                            oldLoanDebtService

                        const underwrittenAvailableForDebtService = obj.scenario.underwritten.financeOptions.minDSCR
                            ? ncf / obj.scenario.underwritten.financeOptions.minDSCR
                            : 0

                        obj.sizer.underwritten.availableForDebtService = underwrittenAvailableForDebtService

                        const actualAvailableForDebtService = obj.scenario.actual.financeOptions.minDSCR
                            ? ncf / obj.scenario.actual.financeOptions.minDSCR
                            : 0

                        obj.sizer.actual.availableForDebtService = actualAvailableForDebtService

                        obj.sizer.underwritten.availableForSupplementalDebtService =
                            underwrittenAvailableForDebtService - oldLoanDebtService

                        obj.sizer.actual.availableForSupplementalDebtService =
                            actualAvailableForDebtService - oldLoanDebtService
                    }

                    const types = ['underwritten', 'actual']
                    for (const type of types) {
                        const loanAmount = obj.sizer?.[type]?.loanAmount || 0
                        const valueConstrainedLoan = obj.sizer?.[type]?.valueConstrainedLoan || 0
                        const recentAquisitionLoanLimit = obj.sizer?.[type]?.recentAquisitionLoanLimit || 0
                        const fixedRateSizing = obj.sizer?.[type]?.fixedRateSizing || 0
                        const combinedUPB = obj.sizer?.[type]?.combinedUPB || 0
                        const maxLTV = obj.scenario?.[type]?.financeOptions?.maxLTV || 0
                        const actualLoanConstant = obj.scenario?.[type]?.loanPricing?.actualLoanConstant || 0
                        const minDSCR = obj.scenario?.[type].financeOptions?.minDSCR
                        const dscRequestedLoanAmount = calcDscRequestedLoanAmount(
                            ncf,
                            loanAmount,
                            actualLoanConstant,
                            getOldLoanDebtService(),
                        )

                        const debtServiceConstrainedLoan = getDebtServiceConstrainedLoan(
                            ncf,
                            minDSCR,
                            actualLoanConstant,
                            getOldLoanDebtService(),
                        )

                        if (isFinite(+debtServiceConstrainedLoan) && !isNaN(+debtServiceConstrainedLoan)) {
                            obj.sizer[type].debtServiceConstrainedLoan = debtServiceConstrainedLoan
                        }

                        if (((!isSupplemental && loanAmount) || (isSupplemental && combinedUPB)) && maxLTV) {
                            const capRate = getCapRate(ncf, isSupplemental ? combinedUPB : loanAmount, maxLTV)
                            obj.sizer[type].capRate = parseFloat(capRate)
                        }

                        if (!!dscRequestedLoanAmount) {
                            obj.sizer[type].dscRequestedLoanAmount = dscRequestedLoanAmount
                        }

                        // set maxFirstPos
                        let maxFirstPosLoan = loanAmount
                        let constraint = 'Requested Amount'

                        if (debtServiceConstrainedLoan < maxFirstPosLoan) {
                            maxFirstPosLoan = debtServiceConstrainedLoan
                            constraint = 'DSCR'
                        }
                        if (valueConstrainedLoan < maxFirstPosLoan) {
                            maxFirstPosLoan = valueConstrainedLoan
                            constraint = 'LTV'
                        }
                        if (recentAquisitionLoanLimit < maxFirstPosLoan) {
                            maxFirstPosLoan = recentAquisitionLoanLimit
                            constraint = 'Purchase Price'
                        }

                        if (!params[0]?.isArm) {
                            obj.sizer[type].maxFirstPosLoan = maxFirstPosLoan
                            obj.sizer[type].constraint = constraint
                            savedMaxPosLoan.current = maxFirstPosLoan
                        }

                        if (!!params[0]?.isArm && !!savedMaxPosLoan.current) {
                            obj.sizer[type].fixedRateSizing = savedMaxPosLoan.current

                            if (obj.sizer[type].fixedRateSizing < maxFirstPosLoan) {
                                maxFirstPosLoan = fixedRateSizing
                                constraint = 'Fixed Sizing'
                            }

                            obj.sizer[type].maxFirstPosLoan = maxFirstPosLoan
                            obj.sizer[type].constraint = constraint
                        }
                    }

                    if (!!params[0]?.isArm && !!savedMaxPosLoan.current) {
                        savedMaxPosLoan.current = null
                    }

                    list.push(obj)
                    return list
                })

                const defaultRefIndex = params[0]?.isArm ? refIndexListARM[0].value : refIndexListFixed[0].value

                getIndexIntrstRate(defaultRefIndex).then((dailyIndexRate) => {
                    obj.scenario.underwritten.loanPricing.indexRate = dailyIndexRate
                    obj.scenario.actual.loanPricing.indexRate = dailyIndexRate
                    localContext.form.submit()
                })

                return obj
            },
            removeItem(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
                form.changeValue(state, `fanniePricing.pricingOptions`, (list) => {
                    return removeItemFromArray(list, params[0])
                })
                localContext.form.submit()
            },
            selectOffer(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
                form.changeValue(state, `fanniePricing.pricingOptions`, (list) => {
                    list.map((option, idx) => {
                        if (idx == params[0]) option.isSelected = true
                    })
                    return list
                })
            },
            changeArmOptionRef(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
                form.changeValue(state, `fanniePricing.pricingOptions`, (list) => {
                    list.map((option, idx) => {
                        if (option.id == params[0]) option.armOptionsReferenceIds = params[1]
                    })
                    return list
                })
            },
            changeFixedOptionRef(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
                form.changeValue(state, `fanniePricing.pricingOptions`, (list) => {
                    list.map((option, idx) => {
                        if (option.id == params[0]) option.fixedOptionReferenceId = params[1]
                    })
                    return list
                })
            },
            changeOptionPosition(params, state, form) {
                setFormModifiedViaMutators((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
                form.changeValue(state, `fanniePricing.pricingOptions`, (list) => {
                    const element = list[params[0]]
                    list.splice(params[0], 1)
                    list.splice(params[1], 0, element)
                    return list
                })
            },
        }
    }, [isPricing, isSupplemental])
    
    useEffect(() => {
        return () =>
            setFormVisited((prevState) => ({
                ...prevState,
                pricing: false,
            }))
    }, [])

    return (
        <>
            {isPricing && initialValues && computedValues && mutators ? (
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    decorators={[computedValues]}
                    mutators={mutators}
                >
                    {({ form, visited }) => {
                        if (visited) {
                            if (Object.values(visited).includes(true)) {
                                if (formVisited?.pricing === false) {
                                    setFormVisited((prevState) => ({
                                        ...prevState,
                                        pricing: true,
                                    }))
                                }
                            }
                        }
                        localContext.form = form
                        return <Component isSupplemental={isSupplemental} isAcquisition={isAcquisition} isNM={isNM} />
                    }}
                </Form>
            ) : (
                <Spinner />
            )}
        </>
    )
})
