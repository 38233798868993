import React, { useMemo, useState } from 'react'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { FinancingTable } from './FinancingTable'
import { FinancingChart } from './FinancingChart'
import { regenerateCashFlow } from 'origination-model'
import { useMetrics } from '../../../../hooks/useMetrics'
const cloneDeep = require('lodash.clonedeep')

export const FinancingTab = (props) => {
    const TAB = Object.freeze({
        VISUAL: 'VISUAL',
        DETAILED: 'DETAILED',
    })
    const [activeTab, setActiveTabs] = useState(TAB.VISUAL)

    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashFlow = regenerateCashFlow({ model: model, cashFlow: model.cashFlow, unitMix: unitMix })

    // console.log('FinancingTab', 'model', model)
    const debtAssumptionSenior = model?.debtAssumptionSenior

    const usesTotal = useMemo(() => {
        let other = 0
        if (model?.uses?.other) {
            for (const o of model?.uses?.other) other += o.abs
        }
        return (
            (model?.uses.purchasePrice || 0) +
            (model?.uses.transactionCosts || 0) +
            (model?.uses.lenderFee || 0) +
            (model?.uses.renovationProgram || 0) +
            (model?.uses.preFunded || 0) +
            other
        )
    }, [model])

    const ltv = useMemo(() => {
        return model.sources.seniorDebt / model.uses.purchasePrice
    }, [model])

    const ltc = useMemo(() => {
        return model.sources.seniorDebt / usesTotal
    }, [model, usesTotal])
    const metrics = useMetrics()
    const schedule: any = useMemo(() => {
        /*
        //old way before loan draw
        const senior1 = mortgagePaymentSchedule({
            loanAmount: model?.sources?.seniorDebt,
            rate: debtAssumptionSenior?.rate,
            amortizationTermYears: debtAssumptionSenior?.amortizationTerm,
            ioPeriodYears: debtAssumptionSenior?.ioPeriod,
            periodYears: model?.exitAssumption?.saleYearAnalysisPeriod,
        })
        console.log('senior1', senior1)

         */

        const senior = {
            endBalance: 0,
            schedule: new Array(model.exitAssumption.saleYearAnalysisPeriod * 12).fill(null),
        }
        // console.log('model', model)
        //console.log('cashFlow.seniorLoans', cashFlow.seniorLoans)
        for (const b of cashFlow.seniorLoans) {
            //  console.log('b', b)
            senior.endBalance += b.schedule.endBalance
            for (let i = 0; i < senior.schedule.length; i++) {
                if (!senior.schedule[i]) {
                    senior.schedule[i] = {
                        interest: 0,
                        principal: 0,
                        total: 0,
                        startingBalance: 0,
                        endingBalance: 0,
                    }
                }
                // console.log(i, b.schedule.schedule[i])
                if (b?.schedule?.schedule[i]) {
                    senior.schedule[i].interest += b.schedule.schedule[i].interest
                    senior.schedule[i].principal += b.schedule.schedule[i].principal
                    senior.schedule[i].total += b.schedule.schedule[i].total
                    senior.schedule[i].startingBalance += b.schedule.schedule[i].startingBalance
                    senior.schedule[i].endingBalance += b.schedule.schedule[i].endingBalance
                }
            }
        }
        //console.log('senior', senior, cashFlow.seniorLoans)
        //console.log('senior', senior)

        let mezzanine = null
        if (model?.sources?.mezzanine) {
            mezzanine = {
                endBalance: 0,
                schedule: new Array(model.exitAssumption.saleYearAnalysisPeriod * 12).fill(null),
            }

            if (cashFlow.mezLoans && cashFlow.mezLoans.length > 0)
                cashFlow.mezLoans.map((b) => {
                    mezzanine.endBalance += b.schedule.endBalance

                    for (let i = 0; i < senior.schedule.length; i++) {
                        if (!mezzanine.schedule[i]) {
                            mezzanine.schedule[i] = {
                                interest: 0,
                                principal: 0,
                                total: 0,
                                startingBalance: 0,
                                endingBalance: 0,
                            }
                        }
                        if (b?.schedule?.schedule[i]) {
                            mezzanine.schedule[i].interest += b.schedule.schedule[i].interest
                            mezzanine.schedule[i].principal += b.schedule.schedule[i].principal
                            mezzanine.schedule[i].total += b.schedule.schedule[i].total
                            mezzanine.schedule[i].startingBalance += b.schedule.schedule[i].startingBalance
                            mezzanine.schedule[i].endingBalance += b.schedule.schedule[i].endingBalance
                        }
                    }
                })
        }
        return { senior, mezzanine }
    }, [model, model?.debtAssumptionSenior])
    const startBalance = useMemo(() => {
        // return (model?.sources?.seniorDebt || 0) + (model?.sources?.mezzanine || 0)
        return (
            (schedule?.senior?.schedule[0]?.startingBalance || 0) +
            (schedule?.mezzanine?.schedule[0]?.startingBalance || 0)
        )
    }, [model, schedule])
    const endBalance = useMemo(() => {
        //console.log('(schedule', schedule)
        return (schedule?.senior?.endBalance || 0) + (schedule?.mezzanine?.endBalance || 0)

        //return schedule.senior.endingBalance + (schedule?.mezzanine?.startingBalance || 0)
    }, [model, schedule])
    // console.log(startBalance, model)
    return (
        <div className="cash-flow-tab-wrapper">
            <div className={'table-2-cols'}>
                <div className={'debt-assumption-table-wrapper'}>
                    <div className="debt-assumption-title">Debt Assumption - Senior</div>
                    <div className="custom-table-wrapper container-fluid">
                        <div className="custom-table-title row">
                            <div className="col"></div>
                            <div className="col custom-title text-right">Amount</div>
                            <div className="col custom-title text-right">Ltv</div>
                            <div className="col custom-title text-right">Ltc</div>
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Loan Proceeds</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">
                                    {numeral(model?.sources?.seniorDebt).format('$0,0')}
                                </div>
                            </div>
                            <div className="col text-right bold-500">{numeral(ltv).format('%0.[00]')}</div>
                            <div className="col text-right bold-500">{numeral(ltc).format('%0.[00]')}</div>
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Rate Type</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">Fixed</div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Basis</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">{'-'}</div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Spread</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">{'-'}</div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Rate</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">
                                    {numeral(debtAssumptionSenior?.rate).format('%0.[00]')}
                                </div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-title row">
                            <div className="col"></div>
                            <div className="col custom-title text-right"># year</div>
                            <div className="col custom-title text-right"># month</div>
                            <div className="col"></div>
                            <div className="col-6"></div>
                        </div>

                        <div className="custom-table-row row">
                            <div className="col">Amortization Term</div>
                            <div className="col text-right bold-500">
                                {debtAssumptionSenior?.amortizationTerm} Years
                            </div>
                            <div className="col text-right bold-500">
                                {debtAssumptionSenior?.amortizationTerm * 12} Month
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>

                        <div className="custom-table-row row">
                            <div className="col">Loan Term</div>
                            <div className="col text-right bold-500">{debtAssumptionSenior?.loanTerm} Years</div>
                            <div className="col text-right bold-500">{debtAssumptionSenior?.loanTerm * 12} Month</div>
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>

                        <div className="custom-table-row row">
                            <div className="col">I/O Period</div>
                            <div className="col text-right bold-500">{debtAssumptionSenior?.ioPeriod} Years</div>
                            <div className="col text-right bold-500">{debtAssumptionSenior?.ioPeriod * 12} Month</div>
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                    </div>
                </div>
                <div className={'debt-assumption-table-wrapper'}>
                    <div className="debt-assumption-title">Debt Assumption - Mezaninne</div>
                    <div className="custom-table-wrapper container-fluid">
                        <div className="custom-table-title row">
                            <div className="col"></div>
                            <div className="col custom-title text-right">Amount</div>
                            <div className="col custom-title text-right">Ltv</div>
                            <div className="col custom-title text-right">Ltc</div>
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Loan Proceeds</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">
                                    {numeral(model?.sources?.mezzanine).format('$0,0')}
                                </div>
                            </div>
                            <div className="col text-right bold-500">{numeral(metrics.ltvMez).format('%0.[00]')}</div>
                            <div className="col text-right bold-500">{numeral(metrics.ltcMez).format('%0.[00]')}</div>
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Rate Type</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">Fixed</div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Basis</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">{'-'}</div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Spread</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">{'-'}</div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-row row">
                            <div className="col">Rate</div>
                            <div className="col text-right">
                                <div className="col text-right bold-500">
                                    {numeral(model?.debtAssumptionMez?.rate).format('%0.[00]')}
                                </div>
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                        <div className="custom-table-title row">
                            <div className="col"></div>
                            <div className="col custom-title text-right"># year</div>
                            <div className="col custom-title text-right"># month</div>
                            <div className="col"></div>
                            <div className="col-6"></div>
                        </div>

                        <div className="custom-table-row row">
                            <div className="col">Amortization Term</div>
                            <div className="col text-right bold-500">
                                {model?.debtAssumptionMez?.amortizationTerm} Years
                            </div>
                            <div className="col text-right bold-500">
                                {model?.debtAssumptionMez?.amortizationTerm * 12} Month
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>

                        <div className="custom-table-row row">
                            <div className="col">Loan Term</div>
                            <div className="col text-right bold-500">{model?.debtAssumptionMez?.loanTerm} Years</div>
                            <div className="col text-right bold-500">
                                {model?.debtAssumptionMez?.loanTerm * 12} Month
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>

                        <div className="custom-table-row row">
                            <div className="col">I/O Period</div>
                            <div className="col text-right bold-500">{model?.debtAssumptionMez?.ioPeriod} Years</div>
                            <div className="col text-right bold-500">
                                {model?.debtAssumptionMez?.ioPeriod * 12} Month
                            </div>
                            <div className="col text-right bold-500" />
                            <div className="col-6"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="widget-title">Acquisition Financing</div>
            <div className="widget-tabs">
                <div
                    className={`widget-tab ${activeTab == TAB.VISUAL ? 'active' : ''} `}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setActiveTabs(TAB.VISUAL)
                    }}
                >
                    Visual
                </div>
                <div
                    className={`widget-tab ${activeTab == TAB.DETAILED ? 'active' : ''} `}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setActiveTabs(TAB.DETAILED)
                    }}
                >
                    Detailed breakdown
                </div>
            </div>
            {activeTab == TAB.VISUAL && (
                <>
                    <div className="kpi-block">
                        <div className="kpi-item">
                            <div className="kpi-item__title">Starting balance</div>
                            <div className="kpi-item__value">{numeral(startBalance).format('$0,0')}</div>
                        </div>
                        <div className="kpi-item">
                            <div className="kpi-item__title">Ending balance</div>
                            <div className="kpi-item__value">{numeral(endBalance).format('$0,0')}</div>
                        </div>
                    </div>
                    <FinancingChart schedule={schedule} startBalance={startBalance} />
                </>
            )}
            {activeTab == TAB.DETAILED && <FinancingTable data={schedule} startBalance={startBalance} />}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .cash-flow-tab-wrapper {
                    .custom-table-wrapper {
                        .custom-table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            padding: 7px;
                        }
                        .custom-table-row {
                            font-size: 14px;
                            padding: 7px;
                            align-items: center;
                            &:nth-of-type(2n) {
                                background-color: $row-background-2;
                            }
                        }
                    }
                    .bold-500 {
                        font-weight: 500;
                    }
                    .debt-assumption-table-wrapper {
                        border: 1px solid $border;
                        background-color: $white;
                        margin-bottom: 20px;
                        .debt-assumption-title {
                            padding: 14px 22px;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            border-bottom: 1px solid $border;
                        }
                    }
                    .widget-title {
                        padding: 12px 20px;
                        border: 1px solid $border;
                        border-bottom: 0;
                        background: $white;
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                    }
                    .widget-tabs {
                        display: flex;
                        background-color: $white;
                        padding: 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        border-top: 1px solid $border;
                        .widget-tab {
                            font-size: 15px;
                            margin-right: 32px;
                            font-weight: 500;
                            cursor: pointer;
                            &.active {
                                color: $blue;
                                position: relative;
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 100%;
                                    height: 3px;
                                    bottom: -3px;
                                    left: 0;
                                    border-top: 3px solid #4485ff;
                                    border-top-right-radius: 15px;
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                    }
                    .kpi-block {
                        display: flex;
                        background-color: $white;
                        padding: 20px 10px;
                        border-left: 1px solid $border;
                        border-right: 1px solid $border;
                        .kpi-item {
                            margin-right: 40px;
                            &__title {
                                font-weight: normal;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 5px;
                            }
                            &__value {
                                font-weight: 500;
                                font-size: 22px;
                                color: $black;
                            }
                        }
                    }
                }
                .table-2-cols {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 20px;
                    padding-top: 20px;
                }
            `}</style>
        </div>
    )
}
