import createDecorator from 'final-form-calculate'
import { Finance } from 'financejs'
import { getIndexIntrstRate, getBondsRate, getRateFromBonds, defaultTierValues } from './lib'
import {
    calcDscRequestedLoanAmount,
    getCapRate,
    getRecentAquisitionLoanLimit,
    getValueConstrainedLoan,
    getDebtServiceConstrainedLoan,
    getRequiredCapRateAtDSCRLoan,
    calcDebtService,
    calcLoanSizeFromDebtService,
} from 'origination-model'
import { nmGetMaximumBuiltUpCost, nmGetTotalCostBasis } from '../northMarkFormulas'
const cloneDeep = require('lodash.clonedeep')

//we use original values to make sure that onload we not override user values
export const getPricingDecorator = (
    origUnderwritenLTV: any,
    origUnderwritenDSCRs: any,
    origActualDSCRs: any,
    origIndexRate: any,
    isSupplemental: boolean,
    uPBatClosing: number,
    oldLoanDebtService: number,
    originalRequestedLoanAmount: number,
    escrowedImprovements: number,
    isNM: boolean,
    armType: string,
    isArmPage: boolean,
    closingCostsMultiplier: number,
    isAcquisition: boolean,
    isDebugMode: boolean,
) => {
    //  console.log('new decorator', origUnderwritenLTV, origUnderwritenDSCRs, origActualDSCRs, origIndexRate)
    const DEBUG = false || isDebugMode
    const ENABLE_NV_328_CHECK_LOAN_AMOUNT = isNM
    const ENABLE_NV_322_CHECK_LOAN_AMOUNT_TEIR3AND4 = false
    const TRUNCATE_LOAN_RATIO = 10000
    return createDecorator(
        {
            field: /(capCost.capCost)|(capCost.capCostTerm)/,
            updates: (value, name, values: any) => {
                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const capCost = values.fanniePricing?.pricingOptions?.[idx]?.capCost?.capCost
                const capCostTerm = values.fanniePricing?.pricingOptions?.[idx]?.capCost?.capCostTerm
                const result = capCost / capCostTerm
                if (isNaN(result) || !isFinite(result)) return {}
                out[`fanniePricing.pricingOptions.${idx}.capCost.costPerYear`] = result
                return out
            },
        },
        {
            field: /(capCost.loanAmount)/,
            updates: (value, name, values: any) => {
                if (isNaN(value)) return {}
                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                out[`fanniePricing.pricingOptions.${idx}.capCost.loanAmount`] = value
                out[`fanniePricing.pricingOptions.${idx}.sizer.underwritten.loanAmount`] = value
                return out
            },
        },
        {
            field: /(capCost.costPerYear)|(capCost.loanAmount)/,
            updates: (value, name, values: any) => {
                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const costPerYear = values.fanniePricing?.pricingOptions?.[idx]?.capCost?.costPerYear
                const loanAmount = values.fanniePricing?.pricingOptions?.[idx]?.capCost?.loanAmount
                const result = costPerYear / loanAmount
                if (isNaN(result) || !isFinite(result)) return {}
                out[`fanniePricing.pricingOptions.${idx}.capCost.capCostFactor`] = result
                return out
            },
        },
        {
            field: /capCost.costPerYear/,
            updates: (value, name, values: any) => {
                if (isNaN(value)) return {}
                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                out[`fanniePricing.pricingOptions.${idx}.capCost.monthlyEscrow`] = value / 12
                return out
            },
        },
        {
            field: /capCost.capCostFactor/,
            updates: (value, name, values: any) => {
                if (isNaN(value)) return {}
                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                out[`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.capCostFactor`] = value
                out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.capCostFactor`] = value
                return out
            },
        },
        {
            field: /scenario.underwritten/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator scenario.underwritten', name, value, value)
                if (isNaN(value)) return {}
                // if (isNaN(value) && name.indexOf('prepayment') === -1) return {}
                const idx = name.split('.')[2]
                const out = {}

                let allowChange = true
                if (name.indexOf('minDSCR') > -1) {
                    if (origActualDSCRs?.[idx]) {
                        allowChange = false
                    }
                } else if (
                    name.indexOf('uwFloor') > -1 ||
                    name.indexOf('actualintrestRate') > -1 ||
                    name.indexOf('actualLoanConstant') > -1 ||
                    name.indexOf('stressFactor') > -1 ||
                    name.indexOf('strikeRate') > -1
                ) {
                    allowChange = false
                }
                if (allowChange) {
                    out[name.replace(/underwritten/, 'actual')] = value
                }
                return out
            },
        },
        {
            field: /scenario.underwritten.financeOptions.(loanTerm|io|amortization|prepayment)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator scenario.underwritten spcial', name, value, value)
                // if (value == null) return {}
                //const idx = name.split('.')[2]
                const out = {}
                out[name.replace(/scenario.underwritten.financeOptions/, 'creditFees.loanTerms')] = value

                return out
            },
        },
        {
            field: /scenario.underwritten.financeOptions.prepaymentAdjusted/,
            updates: (value, name, values: any) => {
                const out = {}
                out[name.replace(/underwritten/, 'actual')] = value
                out[
                    name.replace(
                        /scenario.underwritten.financeOptions.prepaymentAdjusted/,
                        'creditFees.loanTerms.prepayment',
                    )
                ] = value

                return out
            },
        },
        {
            field: /scenario.underwritten.financeOptions.prepayment/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator scenario.underwritten spcial', name, value, value)
                // if (value == null) return {}
                //const idx = name.split('.')[2]
                const out = {}
                out[name.replace(/underwritten/, 'actual')] = value

                return out
            },
        },
        {
            field: /tier/,
            updates: (value, name, values: any) => {
                const idx = name.split('.')[2]
                const out = {}
                const armOptionsReferenceIds = values.fanniePricing?.pricingOptions?.[idx]?.armOptionsReferenceIds
                if (!!armOptionsReferenceIds?.length) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (armOptionsReferenceIds.includes(elem.id)) {
                            if (elem.fixedOptionReferenceId === values.fanniePricing?.pricingOptions?.[idx].id) {
                                out[`fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.tier`] =
                                    value
                                out[`fanniePricing.pricingOptions.${index}.creditFees.loanTerms.tier`] = value
                            }
                        }
                    })
                }
                if (
                    values.fanniePricing?.pricingOptions?.[idx]?.isArm &&
                    values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId
                ) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (elem.id === values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId) {
                            out[`fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.tier`] =
                                value
                            out[`fanniePricing.pricingOptions.${index}.creditFees.loanTerms.tier`] = value
                        }
                    })
                }
                out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.tier`] = value
                out[`fanniePricing.pricingOptions.${idx}.creditFees.loanTerms.tier`] = value

                return out
            },
        },
        //--------------------------

        {
            field: /(scenario.*.financeOptions.tier)|(creditFees.loanTerms.propertyType)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator financeOptions.tier|propertyType', name, value, cloneDeep(values))
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const out = {}
                const isArm = values.fanniePricing?.pricingOptions?.[idx]?.isArm

                if (isArm) {
                    if (name.indexOf('tier') > -1) {
                        let defaults = defaultTierValues['tier' + value]['ARMSARM']
                        out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.minDSCR`] =
                            defaults.dscr
                        out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.maxLTV`] =
                            defaults.ltv
                    }
                } else {
                    let proprtyType =
                        name.indexOf('propertyType') > -1
                            ? value
                            : values.fanniePricing?.pricingOptions?.[idx]?.creditFees?.loanTerms?.propertyType ||
                              'ConventionalMultifamily'
                    proprtyType = proprtyType.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '')
                    const tier =
                        name.indexOf('tier') > -1
                            ? value || 2
                            : values.fanniePricing?.pricingOptions?.[idx]?.scenario?.underwritten?.financeOptions
                                  ?.tier || 2

                    let defaults = defaultTierValues['tier' + tier][proprtyType]
                    if (!defaults) defaults = defaultTierValues['tier' + tier].ConventionalMultifamily
                    const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                    if (type == 'underwritten') {
                        if (true && !origUnderwritenDSCRs[idx]) {
                            out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.minDSCR`] =
                                defaults.dscr
                        } else {
                            origUnderwritenDSCRs[idx] = null
                        }
                        if (true && !origUnderwritenLTV[idx]) {
                            out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.maxLTV`] =
                                defaults.ltv
                        } else {
                            origUnderwritenLTV[idx] = null
                        }
                    }
                    if (type == 'actual') {
                        if (true && !origActualDSCRs[idx]) {
                            out[`fanniePricing.pricingOptions.${idx}.scenario.actual.financeOptions.minDSCR`] =
                                defaults.dscr
                        } else {
                            origActualDSCRs[idx] = null
                        }
                    }

                    // console.log('out investorSpread', out)
                }

                return out
            },
        },
        {
            field: /creditFees.loanTerms.(loanTerm|io|amortization)/,
            updates: (value, name, values: any) => {
                if (DEBUG)
                    console.log(
                        'mainDecorator creditFees.loanTerms.(loanTerm|io|amortization|tier)',
                        name,
                        value,
                        value,
                    )
                // if (value == null) return {}
                //const idx = name.split('.')[2]
                const out = {}
                out[name.replace(/creditFees.loanTerms/, 'scenario.underwritten.financeOptions')] = value

                return out
            },
        },
        {
            field: /creditFees.pricingFees.targetedGS/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator creditFees.pricingFees.targetedGS', name, value, value)
                // if (value == null) return {}
                const idx = name.split('.')[2]
                const out = {}
                const value60 = parseFloat((value * 0.6).toFixed(4))
                const value40 = value - value60
                if (
                    values?.fanniePricing?.pricingOptions?.[idx]?.creditFees?.pricingFees?.lenderServiceFee +
                        values?.fanniePricing?.pricingOptions?.[idx]?.creditFees?.pricingFees?.fannieMaeGuarantyFee ===
                    value
                )
                    return out
                out[name.replace(/targetedGS/, 'fannieMaeGuarantyFee')] = value60
                out[
                    name.replace(
                        /creditFees.pricingFees.targetedGS/,
                        'scenario.underwritten.loanPricing.fannieMaeGuarantyFee',
                    )
                ] =
                    (value60 +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.fannieMaeGuarantyFee ||
                            0)) /
                    10000
                out[name.replace(/targetedGS/, 'lenderServiceFee')] = value40
                out[
                    name.replace(
                        /creditFees.pricingFees.targetedGS/,
                        'scenario.underwritten.loanPricing.lenderServiceFee',
                    )
                ] =
                    (value40 +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.lenderServiceFee || 0)) /
                    10000

                // console.log('out', out)
                return out
            },
        },
        {
            field: /creditFees.pricingFees.fannieMaeGuarantyFee/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator creditFees.pricingFees.fannieMaeGuarantyFee', name, value, value)
                // if (value == null) return {}
                if (isNaN(value)) return {}
                const idx = name.split('.')[2]
                const out = {}
                const previousLenderFee =
                    values.fanniePricing.pricingOptions[idx]?.creditFees?.pricingFees?.lenderServiceFee
                const lenderServiceFee =
                    values.fanniePricing.pricingOptions[idx]?.creditFees?.pricingFees?.targetedGS - value
                if (Math.abs(previousLenderFee - lenderServiceFee) > 0.1)
                    out[name.replace(/fannieMaeGuarantyFee/, 'lenderServiceFee')] = lenderServiceFee

                out[
                    name.replace(
                        /creditFees.pricingFees.fannieMaeGuarantyFee/,
                        'scenario.underwritten.loanPricing.fannieMaeGuarantyFee',
                    )
                ] =
                    (value +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.fannieMaeGuarantyFee ||
                            0)) /
                    10000
                out[
                    name.replace(
                        /creditFees.pricingFees.fannieMaeGuarantyFee/,
                        'scenario.underwritten.loanPricing.lenderServiceFee',
                    )
                ] =
                    (lenderServiceFee +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.lenderServiceFee || 0)) /
                    10000

                // console.log('out', out)
                return out
            },
        },
        {
            field: /creditFees.pricingFees.lenderServiceFee/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator creditFees.pricingFees.fannieMaeGuarantyFee', name, value, value)
                // if (value == null) return {}
                const idx = name.split('.')[2]
                const out = {}
                const previousFanneiGurantee =
                    values.fanniePricing.pricingOptions[idx]?.creditFees?.pricingFees?.fannieMaeGuarantyFee
                const fanneiGurantee =
                    values.fanniePricing.pricingOptions[idx]?.creditFees?.pricingFees?.targetedGS - value

                if (Math.abs(previousFanneiGurantee - fanneiGurantee) > 0.1)
                    out[name.replace(/lenderServiceFee/, 'fannieMaeGuarantyFee')] = fanneiGurantee
                out[
                    name.replace(
                        /creditFees.pricingFees.lenderServiceFee/,
                        'scenario.underwritten.loanPricing.lenderServiceFee',
                    )
                ] =
                    (value +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.fannieMaeGuarantyFee ||
                            0)) /
                    10000
                out[
                    name.replace(
                        /creditFees.pricingFees.lenderServiceFee/,
                        'scenario.underwritten.loanPricing.fannieMaeGuarantyFee',
                    )
                ] =
                    (fanneiGurantee +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.fannieMaeGuarantyFee ||
                            0)) /
                    10000

                // console.log('out', out)
                return out
            },
        },

        {
            field: /creditFees.pricingFees.investorSpread/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator creditFees.pricingFees.investorSpread', name, value, value)
                //  if (value == null) return {}
                const idx = name.split('.')[2]
                const out = {}
                out[
                    name.replace(
                        /creditFees.pricingFees.investorSpread/,
                        'scenario.underwritten.loanPricing.investorSpread',
                    )
                ] =
                    ((+value || 0) +
                        (+values.fanniePricing.pricingOptions[idx]?.creditFees?.spreadFees?.investorSpread || 0)) /
                    10000

                //console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /creditFees.spreadFees/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator creditFees.spreadFees', name, value, value)
                //if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const param = nameArr[nameArr.length - 1]
                const out = {}
                if (param !== 'comment') {
                    out[name.replace(/creditFees.spreadFees/, 'scenario.underwritten.loanPricing')] =
                        ((+value || 0) +
                            (values.fanniePricing.pricingOptions[idx]?.creditFees?.pricingFees?.[param] || 0)) /
                        10000
                }

                // console.log('out spreadFees', out)
                return out
            },
        },
        {
            field: /scenario.underwritten.loanPricing.(investorSpread|fannieMaeGuarantyFee|lenderServiceFee)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecoratorscenario.underwritten.loanPricing spread', name, value, value)
                // if (value == null) return {}
                if (isNaN(value)) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const param = nameArr[nameArr.length - 1]
                const out = {}
                let baseValue = 0
                if (param == 'investorSpread') {
                    baseValue =
                        (+values.fanniePricing.pricingOptions[idx]?.scenario?.underwritten?.loanPricing
                            ?.fannieMaeGuarantyFee || 0) +
                        (+values.fanniePricing.pricingOptions[idx]?.scenario?.underwritten?.loanPricing
                            ?.lenderServiceFee || 0)
                } else if (param == 'fannieMaeGuarantyFee') {
                    baseValue =
                        (+values.fanniePricing.pricingOptions[idx]?.scenario?.underwritten?.loanPricing
                            ?.investorSpread || 0) +
                        (+values.fanniePricing.pricingOptions[idx]?.scenario?.underwritten?.loanPricing
                            ?.lenderServiceFee || 0)
                } else {
                    baseValue =
                        (+values.fanniePricing.pricingOptions[idx]?.scenario?.underwritten?.loanPricing
                            ?.fannieMaeGuarantyFee || 0) +
                        (+values.fanniePricing.pricingOptions[idx]?.scenario?.underwritten?.loanPricing
                            ?.investorSpread || 0)
                }
                out[name.replace(param, 'totalSpread')] = baseValue + value

                return out
            },
        },
        {
            field: /scenario.underwritten.loanPricing.refIndex/,
            updates: async (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator refIndex', name, value, values)
                //  if (value == null) return {}
                const idx = name.split('.')[2]
                const out: any = {}
                if (origIndexRate[idx]) {
                    origIndexRate[idx] = null
                    return out
                }
                out.bondsRate = cloneDeep(values.bondsRate)
                const dailyIndexRate = await getIndexIntrstRate(value, out)
                out[name.replace('underwritten', 'actual')] = value
                out[name.replace(/refIndex/, 'indexRate')] = dailyIndexRate
                out[name.replace('underwritten', 'actual').replace(/refIndex/, 'indexRate')] = dailyIndexRate

                //console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /computed.refreshIndexRate/,
            updates: async (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator refreshIndexRate', name, value)
                const out = {}
                if (!value) return out
                const idxArr = []
                const types = []

                types.push('underwritten', 'actual')
                if (values?.fanniePricing?.pricingOptions)
                    for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)

                for (const idx of idxArr) {
                    for (const type of types) {
                        const rateType =
                            values.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing?.refIndex
                        const b = await getBondsRate(rateType)
                        const rete = getRateFromBonds(rateType, b)

                        out[`fanniePricing.pricingOptions.${idx}.scenario.${type}.loanPricing.indexRate`] = rete
                    }
                }
                //console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /scenario.*.loanPricing.(indexRate|totalSpread|stressFactor|capCostFactor|strikeRate)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator indexRate|totalSpread', name, value, cloneDeep(values))
                //  if (value == null) return {}
                if (isNaN(value)) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const param = nameArr[nameArr.length - 1]
                const out = {}
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const isArm = values.fanniePricing.pricingOptions[idx].isArm
                const indexRate =
                    param == 'indexRate'
                        ? value
                        : values.fanniePricing.pricingOptions[idx].scenario.underwritten.loanPricing.indexRate
                const totalSpread =
                    param == 'totalSpread'
                        ? value
                        : values.fanniePricing.pricingOptions[idx].scenario.underwritten.loanPricing.totalSpread

                let todayEstimateIntrestRate = indexRate + totalSpread

                if (isArm) {
                    out[name.replace('' + param, 'actualintrestRate')] = todayEstimateIntrestRate
                    if (armType === 'Structured') {
                        const capCostFactor =
                            param == 'capCostFactor'
                                ? value
                                : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.capCostFactor
                        if (type === 'underwritten') {
                            const stressFactor =
                                param == 'stressFactor'
                                    ? value
                                    : values.fanniePricing.pricingOptions[idx].scenario.underwritten.loanPricing
                                          .stressFactor

                            todayEstimateIntrestRate =
                                todayEstimateIntrestRate + (stressFactor || 0) + (capCostFactor || 0)
                        } else {
                            const strikeRate =
                                param == 'strikeRate'
                                    ? value
                                    : values.fanniePricing.pricingOptions[idx].scenario.actual.loanPricing.strikeRate
                            todayEstimateIntrestRate =
                                todayEstimateIntrestRate - indexRate + (strikeRate || 0) + (capCostFactor || 0)
                        }
                    }
                }

                // console.log(indexRate, totalSpread, todayEstimateIntrestRate)
                out[name.replace('' + param, 'todayEstimateIntrestRate')] = todayEstimateIntrestRate
                //  console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /scenario.*.loanPricing.(todayEstimateIntrestRate|uwFloor)/,
            updates: (value, name, values: any) => {
                if (isNaN(value)) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const isArm = values.fanniePricing.pricingOptions[idx].isArm
                const out = {}

                if (isArm) {
                    return out
                }
                const param = nameArr[nameArr.length - 1]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'

                const todayEstimateIntrestRate =
                    param == 'uwFloor'
                        ? values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing
                              .todayEstimateIntrestRate || 0
                        : value

                const uwFloor =
                    param == 'uwFloor'
                        ? value
                        : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.uwFloor || 0
                const actualintrestRate = Math.max(todayEstimateIntrestRate, uwFloor)

                out[name.replace('' + param, 'actualintrestRate')] = actualintrestRate
                //console.log('out investorSpread', out)
                return out
            },
        },

        {
            field: /(scenario.*.loanPricing.(uwFloor|actualintrestRate|todayEstimateIntrestRate))|(scenario.*.financeOptions.amortization)/,
            updates: (value, name, values: any) => {
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const isArm = values.fanniePricing?.pricingOptions?.[idx]?.isArm
                const armType = values.armType

                if (isArm) {
                    //should use this formula: =IF(I303>I302;PMT(I303/o_twelve;I311*o_twelve;1)*-12;PMT(I302/o_twelve;I311*o_twelve;1)*-12)
                    const finance = new Finance()
                    // console.log('now')
                    const todayEstimateIntrestRate =
                        values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.todayEstimateIntrestRate ||
                        0
                    const uwFloor = values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.uwFloor || 0
                    const higherRate = uwFloor > todayEstimateIntrestRate ? uwFloor : todayEstimateIntrestRate

                    const amortization =
                        values.fanniePricing.pricingOptions[idx].scenario[type].financeOptions.amortization || 30

                    const result = finance.PMT(higherRate / 12, amortization * 12, 1) * -12

                    // console.log(
                    //     type,
                    //     `todayEstimateIntrestRate`,
                    //     todayEstimateIntrestRate,
                    //     'uwFloor',
                    //     uwFloor,
                    //     'higherRate',
                    //     higherRate,
                    // )
                    //console.log('higherRate', higherRate)
                    // console.log('type', type, 'todayEstimateIntrestRate', todayEstimateIntrestRate)
                    if (!isNaN(result))
                        out[`fanniePricing.pricingOptions.${idx}.scenario.${type}.loanPricing.uwLoanConstant`] = result
                }

                return out
            },
        },

        {
            field: /scenario.*.loanPricing.(indexRate|totalSpread|strikeRate|stressFactor|capCostFactor)/,
            updates: (value, name, values: any) => {
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const isArm = values.fanniePricing?.pricingOptions?.[idx]?.isArm
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}

                if (isArm) {
                    const indexRate =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing?.indexRate || 0
                    const totalSpread =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing?.totalSpread || 0
                    const strikeRate =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing?.strikeRate || 0
                    const stressFactor =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing?.stressFactor || 0
                    const capCostFactor =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing?.capCostFactor || 0

                    // out[`fanniePricing.pricingOptions.${idx}.scenario.${type}.loanPricing.actualintrestRate`] =
                    //     indexRate + totalSpread + capCostFactor + (type === 'underwritten' ? stressFactor : strikeRate)
                }

                return out
            },
        },

        {
            field: /scenario.underwritten.loanPricing.(fannieMaeGuarantyFee|lenderServiceFee)/,
            updates: (value, name, values: any) => {
                if (isNaN(value)) return {}
                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const isArm = values.fanniePricing?.pricingOptions?.[idx]?.isArm

                if (isArm && values.armType === 'ARM 7-6') {
                    const fannieMaeGuarantyFee =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.underwritten?.loanPricing
                            ?.fannieMaeGuarantyFee || 0
                    const lenderServiceFee =
                        values.fanniePricing?.pricingOptions?.[idx]?.scenario?.underwritten?.loanPricing
                            ?.lenderServiceFee || 0

                    out[`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.uwFloor`] =
                        fannieMaeGuarantyFee + lenderServiceFee + 0.06
                }
                return out
            },
        },

        {
            field: /(scenario.*.loanPricing.(actualintrestRate|uwLoanConstant))|(sizer.*.loanAmount)|(scenario.*.financeOptions.amortization)|(scenario.*.financeOptions.loanTerm)/,
            updates: (value, name, values: any) => {
                if (DEBUG)
                    console.log(
                        'mainDecorator actualintrestRate|loanAmount|amortization|loanTerm ',
                        name,
                        value,
                        cloneDeep(values),
                    )
                if (isNaN(value)) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                // const isArm = values.fanniePricing?.pricingOptions?.[idx]?.isArm

                let rate =
                    name.indexOf('actualintrestRate') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.actualintrestRate
                const amortization =
                    name.indexOf('amortization') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].scenario[type].financeOptions.amortization

                if (false) {
                    rate =
                        name.indexOf('uwLoanConstant') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.uwLoanConstant
                }
                /* const loanAmount =
                    name.indexOf('loanAmount') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer[type].loanAmount

                const loanTerm =
                    name.indexOf('loanTerm') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].scenario[type].financeOptions.loanTerm

                if (loanAmount && rate) {
                    const pObj: any = {
                        loanAmount: loanAmount,
                        amortizationTermYears: amortization,
                        rate: rate,
                        ioPeriodYears: 0,
                        periodYears: loanTerm,
                        unit: 'year',
                    }

                    // console.log('p', p)
                    const debtService = calcDebtService(pObj)
                    // console.log('debtService', debtService)
                    const actualLoanConstant = debtService / loanAmount

                    //  console.log('actualLoanConstant', actualLoanConstant)
                    out[`fanniePricing.pricingOptions.${idx}.scenario.${type}.loanPricing.actualLoanConstant`] =
                        actualLoanConstant
                }
                */
                if (rate) {
                    const finance = new Finance()

                    const actualLoanConstant = finance.PMT(rate / 12, amortization * 12, 1) * -12
                    if (!isNaN(actualLoanConstant))
                        out[`fanniePricing.pricingOptions.${idx}.scenario.${type}.loanPricing.actualLoanConstant`] =
                            actualLoanConstant
                }

                return out
            },
        },

        {
            field: /(scenario.*.loanPricing.(actualLoanConstant|uwLoanConstant))|(computed.ncf)|(scenario.*.financeOptions.tier)|(sizer.*.loanAmount)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator actualLoanConstant|ncf ', name, value, cloneDeep(values))
                if (isNaN(value)) return {}
                //  if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const ncf = name.indexOf('ncf') > -1 ? value : values.computed.ncf

                const idxArr = []
                const types = []
                const isArmOption = values.fanniePricing?.pricingOptions?.[idx]?.isArm
                if (idx) {
                    idxArr.push(idx)
                    types.push(type)
                } else {
                    types.push('underwritten', 'actual')
                    if (values?.fanniePricing?.pricingOptions)
                        for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)
                }
                for (const idx of idxArr) {
                    for (const type of types) {
                        const loanAmount = values?.fanniePricing?.pricingOptions?.[idx]?.sizer?.[type]?.loanAmount
                        if (loanAmount) {
                            let actualLoanConstant =
                                name.indexOf('actualLoanConstant') > -1
                                    ? value
                                    : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing
                                          .actualLoanConstant

                            if (isArmOption) {
                                actualLoanConstant =
                                    name.indexOf('uwLoanConstant') > -1
                                        ? value
                                        : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing
                                              .uwLoanConstant
                            }

                            const dscRequestedLoanAmount = calcDscRequestedLoanAmount(
                                ncf,
                                loanAmount,
                                actualLoanConstant,
                                oldLoanDebtService,
                            )
                            // console.log('dscRequestedLoanAmount', dscRequestedLoanAmount)
                            const option = values?.fanniePricing?.pricingOptions?.[idx]
                            if (
                                ENABLE_NV_322_CHECK_LOAN_AMOUNT_TEIR3AND4 &&
                                type == 'underwritten' &&
                                option &&
                                option?.scenario?.underwritten?.financeOptions?.tier > 2 &&
                                option?.sizer?.underwritten?.dscRequestedLoanAmount <
                                    option?.scenario?.underwritten?.financeOptions?.minDSCR
                            ) {
                                /*
                                const maxDebtService = ncf / option?.scenario?.underwritten?.financeOptions?.minDSCR
                                const p = {
                                    debtService: maxDebtService,
                                    loanTerm: option?.scenario?.underwritten?.financeOptions.loanTerm,
                                    ioPeriod: option?.scenario?.underwritten?.financeOptions.io,
                                    amortizationTerm: option?.scenario?.underwritten?.financeOptions.amortization,
                                    rate: option?.scenario?.underwritten?.loanPricing.actualLoanConstant,
                                }
                                const newLoanSize = calcLoanSizeFromDebtService(p)

                                 */
                                const newLoanSize =
                                    values?.fanniePricing?.pricingOptions?.[idx]?.sizer?.[type]
                                        ?.debtServiceConstrainedLoan
                                out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.loanAmount`] =
                                    Math.round(newLoanSize)
                                out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.dscRequestedLoanAmount`] =
                                    option?.scenario?.underwritten?.financeOptions?.minDSCR
                            } else {
                                out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.dscRequestedLoanAmount`] =
                                    dscRequestedLoanAmount
                            }
                        }
                    }
                }

                // console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /(scenario.*.financeOptions.maxLTV)|(sizer.*.loanAmount)|(sizer.*.combinedUPB)|(computed.ncf)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator maxLTV|loanAmount|ncf ', name, value, cloneDeep(values))
                //  if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const ncf = name.indexOf('ncf') > -1 ? value : values.computed.ncf

                const idxArr = []
                const types = []
                if (idx) {
                    idxArr.push(idx)
                    types.push(type)
                } else {
                    types.push('underwritten', 'actual')
                    if (values?.fanniePricing?.pricingOptions)
                        for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)
                }
                for (const idx of idxArr) {
                    for (const type of types) {
                        const loanAmount =
                            name.indexOf('loanAmount') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.sizer?.[type]?.loanAmount || 0

                        const combinedUPB =
                            name.indexOf('combinedUPB') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.sizer?.[type]?.combinedUPB || 0

                        const maxLTV =
                            name.indexOf('maxLTV') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.financeOptions
                                      ?.maxLTV || 0

                        //console.log('maxLTV', maxLTV, 'loanAmount', loanAmount)
                        if (((!isSupplemental && loanAmount) || (isSupplemental && combinedUPB)) && maxLTV) {
                            const capeRate = getCapRate(ncf, isSupplemental ? combinedUPB : loanAmount, maxLTV)
                            //console.log('capeRate', capeRate)
                            out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.capRate`] = parseFloat(capeRate)
                        }
                    }
                }
                // console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /(scenario.*.financeOptions.maxLTV)|(computed.purchasePrice)|(computed.underwrittenPropertyValue)/,
            updates: (value, name, values: any) => {
                if (DEBUG)
                    console.log(
                        'mainDecorator maxLTV|purchasePrice|underwrittenPropertyValue ',
                        name,
                        value,
                        cloneDeep(values),
                    )
                //  if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}

                const purchasePrice = name.indexOf('purchasePrice') > -1 ? value : values.computed.purchasePrice || 0
                const underwrittenPropertyValue =
                    name.indexOf('underwrittenPropertyValue') > -1
                        ? value
                        : values?.computed?.underwrittenPropertyValue || 0

                const idxArr = []
                const types = []
                if (idx) {
                    idxArr.push(idx)
                    types.push(type)
                } else {
                    types.push('underwritten', 'actual')
                    if (values?.fanniePricing?.pricingOptions)
                        for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)
                }
                for (const idx of idxArr) {
                    for (const type of types) {
                        const maxLTV =
                            name.indexOf('maxLTV') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.financeOptions
                                      ?.maxLTV || 0

                        if (maxLTV) {
                            const totalCostBasis = nmGetTotalCostBasis(
                                purchasePrice,
                                escrowedImprovements,
                                closingCostsMultiplier,
                            )

                            const maximumBuiltUpCost = nmGetMaximumBuiltUpCost(totalCostBasis)

                            //console.log('maxLTV', maxLTV, 'loanAmount', loanAmount)
                            const recentAquisitionLoanLimit =
                                getRecentAquisitionLoanLimit(maximumBuiltUpCost, maxLTV) - uPBatClosing

                            //console.log('capeRate', capeRate)
                            // out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.recentAquisitionLoanLimit`] =
                            //     isAcquisition ? recentAquisitionLoanLimit : 0
                            out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.recentAquisitionLoanLimit`] =
                                recentAquisitionLoanLimit + uPBatClosing

                            if (isAcquisition && isSupplemental) {
                                out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.valueConstrainedLoan`] =
                                    recentAquisitionLoanLimit
                            } else {
                                const valueConstrainedLoan = getValueConstrainedLoan(underwrittenPropertyValue, maxLTV)
                                //console.log('valueConstrainedLoan', valueConstrainedLoan, underwrittenPropertyValue)
                                out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.valueConstrainedLoan`] =
                                    valueConstrainedLoan - uPBatClosing
                            }
                        }
                    }
                }
                // console.log('out investorSpread', out)
                return out
            },
        },
        {
            field: /(scenario.*.financeOptions.minDSCR)|(scenario.*.loanPricing.(actualLoanConstant|uwLoanConstant))|(computed.ncf)/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator minDSCR|actualLoanConstant|ncf ', name, value, cloneDeep(values))
                //  if (value == null) return {}
                if (isNaN(value)) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const ncf = name.indexOf('ncf') > -1 ? value : values?.computed?.ncf || 0
                const isArmOption = values.fanniePricing?.pricingOptions?.[idx]?.isArm

                const idxArr = []
                const types = []
                if (idx) {
                    idxArr.push(idx)
                    types.push(type)
                } else {
                    types.push('underwritten', 'actual')
                    if (values?.fanniePricing?.pricingOptions)
                        for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)
                }
                for (const idx of idxArr) {
                    for (const type of types) {
                        let actualLoanConstant =
                            name.indexOf('actualLoanConstant') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.loanPricing
                                      ?.actualLoanConstant || 0

                        if (isArmOption) {
                            actualLoanConstant =
                                name.indexOf('uwLoanConstant') > -1
                                    ? value
                                    : values.fanniePricing.pricingOptions[idx].scenario[type].loanPricing.uwLoanConstant
                        }
                        const minDSCR =
                            name.indexOf('minDSCR') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.financeOptions
                                      ?.minDSCR || 0

                        // console.log('minDSCR', minDSCR, 'loanAmount', actualLoanConstant, 'ncf', ncf)
                        if (actualLoanConstant && !isNaN(actualLoanConstant) && minDSCR) {
                            const debtServiceConstrainedLoan = getDebtServiceConstrainedLoan(
                                ncf,
                                minDSCR,
                                actualLoanConstant,
                                oldLoanDebtService,
                            )

                            //console.log('debtServiceConstrainedLoan', debtServiceConstrainedLoan)
                            out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.debtServiceConstrainedLoan`] =
                                parseFloat(debtServiceConstrainedLoan)
                        }
                    }
                }
                //console.log('out investorSpread', out)
                return out
            },
        },

        {
            field: /scenario.*.financeOptions.amortization/,
            updates: (value, name, values: any) => {
                const idx = name.split('.')[2]
                const out = {}
                const armOptionsReferenceIds = values.fanniePricing?.pricingOptions?.[idx]?.armOptionsReferenceIds
                if (!!armOptionsReferenceIds?.length) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (armOptionsReferenceIds.includes(elem.id)) {
                            if (elem.fixedOptionReferenceId === values.fanniePricing?.pricingOptions?.[idx].id) {
                                out[
                                    `fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.amortization`
                                ] = value
                            }
                        }
                    })
                }
                if (
                    values.fanniePricing?.pricingOptions?.[idx]?.isArm &&
                    values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId
                ) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (elem.id === values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId) {
                            out[
                                `fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.amortization`
                            ] = value
                        }
                    })
                }

                return out
            },
        },

        {
            field: /scenario.*.financeOptions.intrestBasis/,
            updates: (value, name, values: any) => {
                const idx = name.split('.')[2]
                const out = {}
                const armOptionsReferenceIds = values.fanniePricing?.pricingOptions?.[idx]?.armOptionsReferenceIds
                if (!!armOptionsReferenceIds?.length) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (armOptionsReferenceIds.includes(elem.id)) {
                            if (elem.fixedOptionReferenceId === values.fanniePricing?.pricingOptions?.[idx].id) {
                                out[
                                    `fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.intrestBasis`
                                ] = value
                                out[
                                    `fanniePricing.pricingOptions.${index}.scenario.actual.financeOptions.intrestBasis`
                                ] = value
                            }
                        }
                    })
                }
                if (
                    values.fanniePricing?.pricingOptions?.[idx]?.isArm &&
                    values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId
                ) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (elem.id === values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId) {
                            out[
                                `fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.intrestBasis`
                            ] = value
                            out[`fanniePricing.pricingOptions.${index}.scenario.actual.financeOptions.intrestBasis`] =
                                value
                        }
                    })
                }

                return out
            },
        },

        /****************
         * start of Sizer section
         *************/
        {
            field: /sizer.underwritten.loanAmount/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator loanAmount', name, value, values)
                // if (value == null) return {}
                if (isNaN(value)) return {}
                const idx = name.split('.')[2]
                const out = {}
                out[name.replace(/underwritten/, 'actual')] = value
                if (isSupplemental) {
                    const upbOfFirst = values.fanniePricing.pricingOptions[idx].sizer.underwritten.upbOfFirst || 0
                    out[`fanniePricing.pricingOptions.${idx}.sizer.underwritten.combinedUPB`] = upbOfFirst + value
                    out[`fanniePricing.pricingOptions.${idx}.sizer.actual.combinedUPB`] = upbOfFirst + value
                }
                return out
            },
        },
        {
            field: /sizer.underwritten.upbOfFirst/,
            updates: (value, name, values: any) => {
                if (DEBUG) console.log('mainDecorator loanAmount', name, value, values)
                // if (value == null) return {}
                if (isNaN(value)) return {}
                const idx = name.split('.')[2]
                const out = {}
                out[name.replace(/underwritten/, 'actual')] = value
                if (isSupplemental) {
                    const loanAmount = values.fanniePricing.pricingOptions[idx].sizer.underwritten.loanAmount || 0
                    out[`fanniePricing.pricingOptions.${idx}.sizer.underwritten.combinedUPB`] = loanAmount + value
                    out[`fanniePricing.pricingOptions.${idx}.sizer.actual.combinedUPB`] = loanAmount + value
                }
                return out
            },
        },

        {
            field: /(sizer.*.debtServiceConstrainedLoan)|(scenario.*.financeOptions.maxLTV)|(computed.ncf)/,
            updates: (value, name, values: any) => {
                if (DEBUG)
                    console.log('mainDecorator debtServiceConstrainedLoan|maxLTV|ncf ', name, value, cloneDeep(values))
                //  if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const ncf = name.indexOf('ncf') > -1 ? value : values?.computed?.ncf

                const idxArr = []
                const types = []
                if (idx) {
                    idxArr.push(idx)
                    types.push(type)
                } else {
                    types.push('underwritten', 'actual')
                    if (values?.fanniePricing?.pricingOptions)
                        for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)
                }
                for (const idx of idxArr) {
                    for (const type of types) {
                        const debtServiceConstrainedLoan =
                            name.indexOf('debtServiceConstrainedLoan') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.sizer?.[type]
                                      ?.debtServiceConstrainedLoan || 0
                        const maxLTV =
                            name.indexOf('maxLTV') > -1
                                ? value
                                : values?.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.financeOptions
                                      ?.maxLTV || 0

                        //console.log('minDSCR', minDSCR, 'loanAmount', actualLoanConstant, 'ncf', ncf)
                        if (debtServiceConstrainedLoan && maxLTV) {
                            const requiredCapRateAtDSCRLoan = getRequiredCapRateAtDSCRLoan(
                                ncf,
                                debtServiceConstrainedLoan,
                                maxLTV,
                            )
                            //console.log('requiredCapRateAtDSCRLoan', requiredCapRateAtDSCRLoan)

                            out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.requiredCapRateAtDSCRLoan`] =
                                parseFloat(requiredCapRateAtDSCRLoan)
                        }
                    }
                }
                //console.log('out investorSpread', out)
                return out
            },
        },

        /*
         * NV-328
         */
        {
            field: /(sizer.*.debtServiceConstrainedLoan)|(sizer.actual.valueConstrainedLoan)|(sizer.actual.recentAquisitionLoanLimit)|(sizer.actual.fixedRateSizing)/,
            updates: (value, name, values: any) => {
                const out = {}
                if (!ENABLE_NV_328_CHECK_LOAN_AMOUNT) return out
                if (isSupplemental) return out

                if (DEBUG)
                    console.log(
                        'mainDecorator actual.debtServiceConstrainedLoan ||  sizer.actual.valueConstrainedLoan',
                        name,
                        value,
                        cloneDeep(values),
                    )
                //  if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]

                const isArmOption = values.fanniePricing.pricingOptions[idx].isArm
                if (
                    !isArmOption &&
                    (name.indexOf('recentAquisitionLoanLimit') > -1 || name.indexOf('fixedRateSizing') > -1)
                )
                    return out

                if (isArmPage) {
                    return out
                }

                const debtServiceConstrainedLoan =
                    name.indexOf('debtServiceConstrainedLoan') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer.actual.debtServiceConstrainedLoan
                const valueConstrainedLoan =
                    name.indexOf('valueConstrainedLoan') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer.actual.valueConstrainedLoan

                let setLoan = false
                let minLoanAmount = -1
                let constraint = null
                if (!setLoan && originalRequestedLoanAmount && isAcquisition) {
                    constraint = 'Requested Amount'
                    minLoanAmount = originalRequestedLoanAmount
                    setLoan = true
                }
                if (
                    (!setLoan && debtServiceConstrainedLoan) ||
                    (setLoan && debtServiceConstrainedLoan && debtServiceConstrainedLoan < minLoanAmount)
                ) {
                    constraint = 'DSCR'
                    minLoanAmount = debtServiceConstrainedLoan
                    setLoan = true
                }
                if (
                    (!setLoan && valueConstrainedLoan) ||
                    (setLoan && valueConstrainedLoan && valueConstrainedLoan < minLoanAmount)
                ) {
                    constraint = 'LTV'
                    minLoanAmount = valueConstrainedLoan
                    setLoan = true
                }

                if (DEBUG)
                    console.log(
                        'min min min',
                        'originalRequestedLoanAmount',
                        originalRequestedLoanAmount,
                        'debtServiceConstrainedLoan',
                        debtServiceConstrainedLoan,
                        'valueConstrainedLoan',
                        valueConstrainedLoan,
                    )

                const option = values?.fanniePricing?.pricingOptions?.[idx]
                if (
                    // ENABLE_NV_322_CHECK_LOAN_AMOUNT_TEIR3AND4 &&
                    option &&
                    option?.scenario?.underwritten?.financeOptions?.tier > 2 &&
                    option?.sizer?.underwritten?.dscRequestedLoanAmount <
                        option?.scenario?.underwritten?.financeOptions?.minDSCR
                ) {
                    /*
                     const maxDebtService = ncf / option?.scenario?.underwritten?.financeOptions?.minDSCR
                     const p = {
                         debtService: maxDebtService,
                         loanTerm: option?.scenario?.underwritten?.financeOptions.loanTerm,
                         ioPeriod: option?.scenario?.underwritten?.financeOptions.io,
                         amortizationTerm: option?.scenario?.underwritten?.financeOptions.amortization,
                         rate: option?.scenario?.underwritten?.loanPricing.actualLoanConstant,
                     }
                     const newLoanSize = calcLoanSizeFromDebtService(p)

                      */
                    const underwrittenDebtServiceConstrainedLoan =
                        values?.fanniePricing?.pricingOptions?.[idx]?.sizer?.underwritten?.debtServiceConstrainedLoan
                    minLoanAmount = Math.min(minLoanAmount, underwrittenDebtServiceConstrainedLoan)

                    constraint = 'DSCR'
                }

                if (isArmOption) {
                    const recentAquisitionLoanLimit =
                        name.indexOf('recentAquisitionLoanLimit') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer.actual.recentAquisitionLoanLimit
                    const fixedRateSizing =
                        name.indexOf('fixedRateSizing') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer.actual.fixedRateSizing
                    if (
                        (!setLoan && recentAquisitionLoanLimit && isAcquisition) ||
                        (setLoan &&
                            recentAquisitionLoanLimit &&
                            isAcquisition &&
                            recentAquisitionLoanLimit < minLoanAmount)
                    ) {
                        constraint = 'Purchase Price'
                        minLoanAmount = recentAquisitionLoanLimit
                        setLoan = true
                    }

                    if (
                        (!setLoan && fixedRateSizing) ||
                        (setLoan && fixedRateSizing && fixedRateSizing < minLoanAmount)
                    ) {
                        constraint = 'Fixed'
                        minLoanAmount = fixedRateSizing
                        setLoan = true
                    }

                    if (DEBUG)
                        console.log(
                            'min min min',
                            'recentAquisitionLoanLimit',
                            recentAquisitionLoanLimit,
                            'fixedRateSizing',
                            fixedRateSizing,
                        )
                }

                const nmLoanAmountManuallyChanged = values.fanniePricing.pricingOptions[idx].nmLoanAmountManuallyChanged
                // if (DEBUG) console.log('1', minLoanAmount)
                if (value < TRUNCATE_LOAN_RATIO) {
                    minLoanAmount = TRUNCATE_LOAN_RATIO
                } else {
                    minLoanAmount = Math.floor(minLoanAmount / TRUNCATE_LOAN_RATIO) * TRUNCATE_LOAN_RATIO
                }
                if (DEBUG) console.log('2', minLoanAmount)
                if (minLoanAmount && !nmLoanAmountManuallyChanged) {
                    out[`fanniePricing.pricingOptions.${idx}.sizer.underwritten.loanAmount`] = minLoanAmount
                    out[`fanniePricing.pricingOptions.${idx}.sizer.underwritten.constraint`] = constraint
                    out[`fanniePricing.pricingOptions.${idx}.sizer.actual.constraint`] = constraint
                }
                return out
            },
        },
        {
            field: /(sizer.*.loanAmount)|(sizer.*.debtServiceConstrainedLoan)|(sizer.*.valueConstrainedLoan)|(sizer.*.recentAquisitionLoanLimit)/,
            updates: (value, name, values: any) => {
                if (DEBUG)
                    console.log(
                        'mainDecorator loanAmount|debtServiceConstrainedLoan|valueConstrainedLoan|recentAquisitionLoanLimit ',
                        name,
                        value,
                        cloneDeep(values),
                    )
                //  if (value == null) return {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const loanAmount =
                    name.indexOf('loanAmount') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer[type].loanAmount
                const debtServiceConstrainedLoan =
                    name.indexOf('debtServiceConstrainedLoan') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer[type].debtServiceConstrainedLoan
                const valueConstrainedLoan =
                    name.indexOf('valueConstrainedLoan') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer[type].valueConstrainedLoan
                const recentAquisitionLoanLimit =
                    name.indexOf('recentAquisitionLoanLimit') > -1
                        ? value
                        : values.fanniePricing.pricingOptions[idx].sizer[type].recentAquisitionLoanLimit

                let maxFirstPosLoan = originalRequestedLoanAmount

                if (loanAmount && loanAmount < maxFirstPosLoan) {
                    maxFirstPosLoan = loanAmount
                }

                let constraint = maxFirstPosLoan == originalRequestedLoanAmount ? 'Requested Amount' : null

                if (debtServiceConstrainedLoan && debtServiceConstrainedLoan < maxFirstPosLoan) {
                    maxFirstPosLoan = debtServiceConstrainedLoan
                    constraint = 'DSCR'
                }
                if (valueConstrainedLoan && valueConstrainedLoan < maxFirstPosLoan) {
                    maxFirstPosLoan = valueConstrainedLoan
                    constraint = 'LTV'
                }

                if (isAcquisition) {
                    if (recentAquisitionLoanLimit < maxFirstPosLoan) {
                        maxFirstPosLoan = recentAquisitionLoanLimit
                        constraint = 'Purchase Price'
                    }
                }

                if (DEBUG)
                    console.log(
                        '1',
                        type,
                        constraint,
                        values.fanniePricing.pricingOptions[idx].sizer.underwritten.maxFirstPosLoan === maxFirstPosLoan,
                        type === 'actual',
                    )
                out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.maxFirstPosLoan`] = maxFirstPosLoan
                if (
                    values.fanniePricing.pricingOptions[idx].sizer.underwritten.maxFirstPosLoan === maxFirstPosLoan &&
                    type === 'actual'
                ) {
                    out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.constraint`] =
                        values.fanniePricing.pricingOptions[idx].sizer.underwritten.constraint
                } else {
                    if (constraint) out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.constraint`] = constraint
                }
                //console.log('out investorSpread', out)
                return out
            },
        },

        {
            field: /sizer.*.maxFirstPosLoan/,
            updates: (value, name, values: any) => {
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                let connectedArmOptionIdx = null

                if (!!values.fanniePricing?.pricingOptions?.[idx]?.armOptionsReferenceIds?.length) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (values.fanniePricing?.pricingOptions?.[idx]?.armOptionsReferenceIds.includes(elem.id)) {
                            if (elem.fixedOptionReferenceId === values.fanniePricing?.pricingOptions?.[idx].id) {
                                connectedArmOptionIdx = index
                            }
                        }
                    })
                }

                if (connectedArmOptionIdx !== null) {
                    out[`fanniePricing.pricingOptions.${connectedArmOptionIdx}.sizer.${type}.fixedRateSizing`] = value
                }

                return out
            },
        },

        {
            field: /(sizer.*.loanAmount)|(sizer.*.debtServiceConstrainedLoan)|(sizer.*.valueConstrainedLoan)|(sizer.*.recentAquisitionLoanLimit)|(sizer.*.fixedRateSizing)/,
            updates: (value, name, values: any) => {
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const out = {}
                const isArm = values.fanniePricing?.pricingOptions?.[idx]?.isArm

                if (isArm) {
                    const loanAmount =
                        name.indexOf('loanAmount') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer[type].loanAmount
                    const debtServiceConstrainedLoan =
                        name.indexOf('debtServiceConstrainedLoan') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer[type].debtServiceConstrainedLoan
                    const valueConstrainedLoan =
                        name.indexOf('valueConstrainedLoan') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer[type].valueConstrainedLoan
                    const recentAquisitionLoanLimit =
                        name.indexOf('recentAquisitionLoanLimit') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer[type].recentAquisitionLoanLimit
                    const fixedRateSizing =
                        name.indexOf('fixedRateSizing') > -1
                            ? value
                            : values.fanniePricing.pricingOptions[idx].sizer[type].fixedRateSizing

                    let maxFirstPosLoan = originalRequestedLoanAmount

                    if (loanAmount && loanAmount < maxFirstPosLoan) {
                        maxFirstPosLoan = loanAmount
                    }

                    let constraint = maxFirstPosLoan == originalRequestedLoanAmount ? 'Requested Amount' : null

                    if (debtServiceConstrainedLoan < maxFirstPosLoan) {
                        maxFirstPosLoan = debtServiceConstrainedLoan
                        constraint = 'DSCR'
                    }
                    if (valueConstrainedLoan < maxFirstPosLoan) {
                        maxFirstPosLoan = valueConstrainedLoan
                        constraint = 'LTV'
                    }
                    if (recentAquisitionLoanLimit < maxFirstPosLoan) {
                        maxFirstPosLoan = recentAquisitionLoanLimit
                        constraint = 'Purchase Price'
                    }
                    if (fixedRateSizing < maxFirstPosLoan) {
                        maxFirstPosLoan = fixedRateSizing
                        constraint = 'Fixed Sizing'
                    }

                    out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.maxFirstPosLoan`] = maxFirstPosLoan
                    out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.constraint`] = constraint
                }

                return out
            },
        },

        {
            field: /scenario.*.financeOptions.loanTerm/,
            updates: (value, name, values: any) => {
                const idx = name.split('.')[2]
                const out = {}
                const armOptionsReferenceIds = values.fanniePricing?.pricingOptions?.[idx]?.armOptionsReferenceIds
                if (!!armOptionsReferenceIds?.length) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (armOptionsReferenceIds.includes(elem.id)) {
                            if (elem.fixedOptionReferenceId === values.fanniePricing?.pricingOptions?.[idx].id) {
                                out[
                                    `fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.loanTerm`
                                ] = value
                                out[`fanniePricing.pricingOptions.${index}.scenario.actual.financeOptions.loanTerm`] =
                                    value
                                out[`fanniePricing.pricingOptions.${index}.creditFees.loanTerms.loanTerm`] = value
                            }
                        }
                    })
                }
                if (
                    values.fanniePricing?.pricingOptions?.[idx]?.isArm &&
                    values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId
                ) {
                    values.fanniePricing?.pricingOptions?.forEach((elem, index) => {
                        if (elem.id === values.fanniePricing?.pricingOptions?.[idx]?.fixedOptionReferenceId) {
                            out[`fanniePricing.pricingOptions.${index}.scenario.underwritten.financeOptions.loanTerm`] =
                                value
                        }
                    })
                }

                return out
            },
        },

        {
            field: /computed.ncf/,
            updates: (value, name, values: any) => {
                //  console.log('mainDecorator computed.ncf ', name, value, values)
                if (value == null) return {}
                //const idx = name.split('.')[2]
                const out = {}
                const idxArr = []
                const types = []

                types.push('underwritten', 'actual')
                if (values?.fanniePricing?.pricingOptions)
                    for (let i = 0; i < values?.fanniePricing?.pricingOptions?.length; i++) idxArr.push(i)
                for (const idx of idxArr) {
                    for (const type of types) {
                        out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.underwrittenNCF`] = value
                    }
                }

                return out
            },
        },
        {
            field: /(computed.ncf)|(scenario.*.financeOptions.minDSCR)/,
            updates: (value, name, values: any) => {
                //  console.log('mainDecorator computed.ncf|mindscr ', name, value, values)
                if (value == null) return {}

                const out = {}
                const nameArr = name.split('.')
                const idx = nameArr[2]
                const type = name.indexOf('underwritten') > -1 ? 'underwritten' : 'actual'
                const ncf = name.indexOf('ncf') > -1 ? value : values?.computed?.ncf || 0
                const minDSCR =
                    name.indexOf('minDSCR') > -1
                        ? value
                        : values?.fanniePricing?.pricingOptions?.[idx]?.scenario?.[type]?.financeOptions?.minDSCR || 0

                const result = minDSCR ? ncf / minDSCR : 0
                // console.log(type, 'ncf', ncf, 'minDSCR', minDSCR, 'result', result)
                //console.log(type, 'oldLoanDebtService', oldLoanDebtService)

                if (result) {
                    out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.currentDebtService`] = oldLoanDebtService
                    out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.availableForDebtService`] = result
                    out[`fanniePricing.pricingOptions.${idx}.sizer.${type}.availableForSupplementalDebtService`] =
                        result - oldLoanDebtService
                }

                return out
            },
        },
        {
            field: /.*/,
            updates: (value, name, values: any) => {
                // console.log('**********')
                //console.log(value)
                //console.log(name)
                //console.log(values)
                if (value == null) return {}
                //const idx = name.split('.')[2]
                const out = {}

                if (value) {
                }

                return out
            },
        },
    )
}
