import React, { useEffect, useState } from 'react'
import { TrafficLights } from '../../../components/TrafficLights'
import { RadialBar } from '../../../components/RadialBar'
import { SpinnerOrError } from '../../../components/SpinnerOrError'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { calcArrayAverage } from 'origination-model'
type Props = {
    projectId: number
    className?: string
    style?: React.CSSProperties
    title?: string
}
const warningMap = {
    low: 'green',
    medium: 'yellow',
    high: 'red',
}
export function OperatingExpenses(props: Props) {
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({ value: 0, warning: 'low' })
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    useEffect(() => {
        if (!project || !cashFlow) return
        let value = 0
        if (project.accountType == 'borrower') {
            const avgTotalExpenses = calcArrayAverage(cashFlow.totalOperatingExpenses.years)
            const avgEffectiveGrossIncome = calcArrayAverage(cashFlow.effectiveGrossIncome.years)
            value = +((avgTotalExpenses / avgEffectiveGrossIncome) * 100).toFixed(1)
        } else {
            value = +(
                (cashFlow.totalOperatingExpenses.adjustedT12 / cashFlow.effectiveGrossIncome.adjustedT12) *
                100
            ).toFixed(1)
        }
        let warning = ''
        if (value > 60) {
            warning = 'high'
        } else if (value > 50) {
            warning = 'medium'
        } else {
            warning = 'low'
        }
        setData({ value, warning })
        setLoading(false)
    }, [project, cashFlow])
    return (
        <div className={`oe-container ` + props.className} style={props.style}>
            <div className={'oe-title'}>{props?.title || `Operating Expenses`}</div>
            {!loading ? (
                <React.Fragment>
                    <TrafficLights color={warningMap[data.warning]} className={`traffic-lights`} />
                    <RadialBar title={'of &nbsp;total<br/>revenue'} value={data.value} className={'chart'} />
                </React.Fragment>
            ) : (
                <SpinnerOrError height={'254px'} error={error} />
            )}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .oe-container {
                        border: 1px solid $border;
                        background-color: $white;
                        border-radius: 3px;
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        height: 271px;
                        :global(.traffic-lights) {
                            position: absolute;
                            bottom: 0;
                            right: 52px;
                        }
                        :global(.chart) {
                            position: absolute;
                            bottom: 56px;
                            left: 34px;
                        }
                    }
                    .oe-title {
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                        border-bottom: 1px solid $border;
                        padding: 10px 20px;
                    }
                `}
            </style>
        </div>
    )
}
