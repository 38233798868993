import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
import CurrentWeekLine from 'images/lease-up-current-week-line.svg'
import StabilizationLine from 'images/lease-up-stabilization-line.svg'

// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

export function ProjectionChart({
    graphCategories,
    graphSeries,
    rateLockWeekForGraph,
    stabilizationForGraph,
    projectionStartForGraph,
}) {
    const categories = graphCategories
    const series = [
        {
            name: 'Occupancy',
            data: graphSeries,
        },
    ]

    const yAnnotations = useMemo(() => {
        const result = []
        if (stabilizationForGraph) {
            result.push({
                y: stabilizationForGraph,
                borderColor: '#44E183',
                label: {
                    show: false,
                },
            })
        }
        if (projectionStartForGraph) {
            result.push({
                y: projectionStartForGraph,
                borderColor: '#FBB96D',
                label: {
                    show: false,
                },
            })
        }
        return result
    }, [stabilizationForGraph, projectionStartForGraph])

    const options = {
        series: series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        xaxis: {
            categories,
        },
        yaxis: [
            {
                seriesName: 'Occupancy',
                min: 0,
                max: 1,
                tickAmount: 10,
                title: {
                    text: 'Occupancy (%)',
                },
                labels: {
                    formatter: function (val) {
                        return Math.round(val * 100) + '%'
                    },
                },
            },
        ],
        annotations: {
            yaxis: yAnnotations,
            xaxis: rateLockWeekForGraph
                ? [
                      {
                          x: rateLockWeekForGraph,
                          tickPlacement: 'on',
                          strokeDashArray: 0,
                          borderColor: '#4486FF',
                          label: {
                              borderColor: 'transparent',
                              style: {
                                  color: '#4486FF',
                                  background: 'transparent',
                              },
                              text: 'Rate Lock',
                              position: 'bottom',
                              orientation: 'horizontal',
                              offsetX: 30,
                              offsetY: -20
                          },
                      },
                  ]
                : [],
        },
    }

    return (
        <>
            <div className="legends-wrapper">
                <div className="legend">
                    <div className="blue-circle"></div>
                    <span>Projected Move-Ins</span>
                </div>
                <div className="legend">
                    <img className="fx-icon" src={CurrentWeekLine} />
                    <span>Current Week</span>
                </div>
                <div className="legend">
                    <img className="fx-icon" src={StabilizationLine} />
                    <span>Stabilization</span>
                </div>
            </div>
            <Chart options={options as ApexOptions} series={series} type="area" height={700} />
            {/*language=SCSS*/}
            <style jsx>{`
                .legends-wrapper {
                    display: flex;
                    gap: 20px;

                    .legend {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .blue-circle {
                            width: 8px;
                            height: 8px;
                            background: #5791ff;
                            border-radius: 50%;
                        }
                    }
                }
            `}</style>
        </>
    )
}
