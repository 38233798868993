import React, { PropsWithChildren } from 'react'
import { FunctionalComponent } from 'preact'
type Props = {
    title?: string
    style?: any
}
export const ModalLayout: FunctionalComponent<PropsWithChildren<Props>> = (props: any) => {
    return (
        <div className={'lender-modal-layout'} onClick={close}>
            <div className="lender-modal" onClick={(e) => e.stopPropagation()}>
                <h2 className="lender-modal-title" style={props.style ? props.style : {}}>
                    {props.title || `Enter project's name`}
                </h2>
                <div className="lender-modal-content">{props?.children}</div>
            </div>

            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';

                .lender-address-wrapper {
                    width: 100%;

                    .lender-address-title {
                        color: $gray-blue;
                        font-size: 14px;
                        margin-bottom: 10px;
                    }

                    .lender-address-input {
                        width: 380px;

                        input {
                            outline: none;
                            padding: 10px 10px 10px 40px;
                            font-size: 14px;
                            width: 100%;
                            border: 1px solid $border;
                            border-radius: 3px;
                            background: $white url('/_img/lenderDashboard/mapMarker.svg') no-repeat 15px center;
                        }
                    }
                }

                .lender-modal-layout {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(14px);
                    z-index: 10000;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lender-modal {
                        background: $white;
                        width: 500px;
                        border: 1px solid $border;
                        border-radius: 4px;
                        padding: 40px;
                        &-title {
                            color: $black;
                            font-size: 14px;
                            font-weight: 400;
                            margin-bottom: 30px;
                        }
                        &-content {
                        }
                    }

                    :global(.lender-modal-input) {
                        border-radius: 3px;
                        background: $white;
                        border: 1px solid $border;
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                    }

                    .lender-control-buttons {
                        button {
                            margin-left: 10px;
                            font-size: 13px;
                            box-shadow: none;
                            &.ghost {
                                color: $blue;
                                border: 1px solid $blue;
                                background: $white;
                                font-weight: 400;
                            }
                            &.regular {
                                font-weight: 500;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
