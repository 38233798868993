import React, { FunctionComponent } from 'react'
import { Spinner } from '../../../../../controls/Spinner'

type LargeItem = {
    title: string
    value: string | number
}
type SmallItem = {
    min: string | number
    max: string | number
}

type Props = {
    title: string
    largeItems?: Array<LargeItem>
    smallItems?: Array<SmallItem>
    loading?: boolean
}

export const CardWidget: FunctionComponent<Props> = (props: Props) => {
    const { title, largeItems, smallItems } = props

    return (
        <div className={'card-wrapper'}>
            <div className="card-title">{title}</div>

            {props.loading ? (
                <div className={'loader'}>
                    <Spinner />
                </div>
            ) : (
                <div className="card-body">
                    <div className="large-item-wrapper">
                        {largeItems?.map((item, i) => (
                            <div className="large-item" key={i}>
                                <div className="title">{item.title}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        ))}
                    </div>
                    <div className="small-item-wrapper">
                        {smallItems?.map((item, j) => (
                            <div className="small-item" key={j}>
                                <div className="min">
                                    {item.min} <span>min</span>
                                </div>
                                <div className="max">
                                    {item.max} <span>max</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .card-wrapper {
                    width: 100%;
                    margin: 0;
                    border: 1px solid $border;
                    background-color: $white;
                    border-radius: 3px;
                    height: 270px;
                    .card-title {
                        font-size: 15px;
                        font-weight: 500;
                        color: $black;
                        border-bottom: 1px solid $border;
                        padding: 10px 20px;
                    }
                    .card-body {
                        padding: 20px;
                        .large-item-wrapper {
                            display: flex;
                            .large-item {
                                flex-grow: 1;
                                min-width: 50%;
                                padding-bottom: 15px;
                                margin-bottom: 15px;
                                border-bottom: 1px solid $border;
                                .title {
                                    color: $gray;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                                .value {
                                    font-size: 22px;
                                    color: $black;
                                    font-weight: 500;
                                }
                            }
                        }
                        .small-item-wrapper {
                            display: flex;
                            .small-item {
                                flex-grow: 1;
                                max-width: 50%;
                                color: $dark-gray;
                                .min,
                                .max {
                                    font-weight: 500;
                                    font-size: 16px;
                                    span {
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: $gray;
                                    }
                                }
                            }
                        }
                    }
                    .loader {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: calc(100% - 60px);
                    }
                }
            `}</style>
        </div>
    )
}
