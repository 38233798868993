import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonBlueEdge } from '../../tabs/Cashflow/ButtonBlueEdge'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../store'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showNotification } from '../../../fullproject/notifications'
import { CreateNoteModal } from '../../tabs/Cashflow/CreateNoteModal'
import downloadIcon from './download-icon.svg'
import shareIcon from './share-icon.svg'

export const CashFlowCommonButtons = React.memo(function CashFlowOverviewButtons(props: React.PropsWithChildren<any>) {
    const router = useRouter()
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const settings = useSelector((store: ReduxStoreState) => store.lender.projectSettings)
    const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false)
    const exportExcel = useCallback(async () => {
        // await exportToExcel(model.cashFlow, model, project.accountType)
        window.open(`/export-cash-flow/${project.id}`, '_blank')
    }, [model, project])

    const exportExcelNMWK = useCallback(async () => {
        // await exportToExcel(model.cashFlow, model, project.accountType)
        if (props.saveDeal) {
            await props.saveDeal()
        }
        window.open(`/northmarqExportProject/${project.id}`, '_blank')
    }, [model, project])
    const [shareLink, setShareLink] = useState('')
    useEffect(() => {
        setShareLink(`${window.location.protocol}//${window.location.host}/cashflow/${project?.hashId}`)
    }, [project])
    const onCopy = useCallback(() => {
        showNotification('Link is copied to clipboard').finally()
    }, [])

    const [isNM, setIsNM] = useState(ReduxHelper.store.getState()?.user.isAdmin || false)
    useEffect(() => {
        const isAdmin = ReduxHelper.store.getState()?.user.isAdmin
        if (isAdmin) setIsNM(true)
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [model])
    return (
        <>
            {project && noteModalVisible && (
                <CreateNoteModal
                    {...props}
                    notesUrl={project?.notesUrl}
                    setNoteModalVisible={setNoteModalVisible}
                    projectId={+router.query.id}
                    close={(e: SyntheticEvent) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setNoteModalVisible(false)
                    }}
                />
            )}
            <div className={'buttons-bar'}>
                <div
                    className={'notes-button-wrapper'}
                    style={
                        project?.notesUrl
                            ? { backgroundColor: '#4486ff', color: '#fff' }
                            : {
                                  backgroundColor: '#e0e2e6',
                                  color: '#161c26',
                              }
                    }
                >
                    <span
                        className={'notes-button'}
                        style={
                            project?.notesUrl
                                ? { marginRight: '8px', color: '#fff' }
                                : {
                                      marginRight: '8px',
                                      color: '#161c26',
                                      pointerEvents: 'none',
                                  }
                        }
                        onClick={() => {
                            const win = window.open(project?.notesUrl, '_blank')
                            win?.focus()
                        }}
                    >
                        Notes
                    </span>
                    <span
                        className={'edit-notes-button'}
                        onClick={() => {
                            setNoteModalVisible(true)
                        }}
                    ></span>
                </div>
                {isNM && (
                    <ButtonBlueEdge
                        onClick={exportExcelNMWK}
                        style={{ marginRight: '8px', color: '#97A2B4', border: '1px solid #97A2B4', padding: '0 12px' }}
                    >
                        Download NM WB
                        <img src={downloadIcon} />
                    </ButtonBlueEdge>
                )}
                {(!isNM || ReduxHelper.store.getState()?.user.isAdmin) && (
                    <ButtonBlueEdge
                        onClick={exportExcel}
                        style={{ marginRight: '8px', color: '#97A2B4', border: '1px solid #97A2B4', padding: '0 12px' }}
                    >
                        Download Excel
                        <img src={downloadIcon} />
                    </ButtonBlueEdge>
                )}

                {!isNM && (
                    <CopyToClipboard text={shareLink} onCopy={onCopy}>
                        <ButtonBlueEdge
                            onClick={() => null}
                            style={{
                                marginRight: '8px',
                                color: '#97A2B4',
                                border: '1px solid #97A2B4',
                                padding: '0 12px',
                            }}
                        >
                            Share
                            <img src={shareIcon} />
                        </ButtonBlueEdge>
                    </CopyToClipboard>
                )}
                {props.children}
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .notes-button-wrapper {
                        display: inline-flex;
                        align-items: center;
                        height: 36px;
                        padding: 0 18.5px;
                        border-radius: 3px;
                        margin-right: 8px;
                        span {
                            cursor: pointer;
                        }
                        .edit-notes-button {
                            width: 10px;
                            height: auto;
                            border-top: 0.3em solid;
                            border-right: 0.3em solid transparent;
                            border-bottom: 0;
                            border-left: 0.3em solid transparent;
                        }
                    }

                    .buttons-bar {
                        position: absolute;
                        right: 0;
                        top: -10px;
                        display: flex;
                        align-items: center;
                    }
                    @media (max-width: 1025px) {
                        .buttons-bar {
                            position: relative;
                            right: 0;
                            top: 0;
                            margin-bottom: 10px;
                        }
                    }
                `}
            </style>
        </>
    )
})
