import React from 'react'
import dynamic from 'next/dynamic'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})
type Props = {
    options: any
    series: any
    type: 'line' | 'bar' | 'radialBar'
    height?: string | number
    width?: string | number
}
export function ApexChart(props: Props) {
    const height = props.height || 'auto'
    const width = props.width || '100%'
    return <Chart options={props.options} series={props.series} type={props.type} height={height} width={width} />
}
