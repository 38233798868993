import React, { useEffect, useMemo, useState } from 'react'
import { CardWidget } from './CardWidget'
import { calcMinMax } from '../../../hooks/useModel'
import numeral from 'numeral'
import { useMetrics } from '../../../hooks/useMetrics'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { calcArrayAverage } from 'origination-model'

type Props = any

export function TotalOperatingExpensesWOReserves(props: Props) {
    const metrics = useMetrics()
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects?.project)
    const [loading, setLoading] = useState(true)
    const values = useMemo(() => {
        if (!cashFlow || !project) return { min: 0, max: 0, avg: 0 }
        const row = cashFlow?.totalOperatingExpenses
        if (project.accountType == 'borrower') {
            const { min, max } = calcMinMax(row?.years)
            return {
                min: min / metrics.numberOfUnits,
                max: max / metrics.numberOfUnits,
                avg: calcArrayAverage(row?.years),
            }
        } else {
            const { min, max } = calcMinMax(row.aprDetails)
            return {
                min: (min / metrics.numberOfUnits) * 12,
                max: (max / metrics.numberOfUnits) * 12,
                avg: calcArrayAverage(row.aprDetails) * 12,
            }
        }
    }, [cashFlow, project, metrics.numberOfUnits])
    useEffect(() => {
        if (project && cashFlow && metrics.numberOfUnits) {
            setLoading(false)
        }
    }, [project, cashFlow, metrics.numberOfUnits])
    return (
        <>
            <CardWidget
                title={'Total Operating Expenses w/o Reserves'}
                largeItems={[
                    {
                        title: 'Avg / Unit',
                        value: numeral(values.avg / metrics.numberOfUnits).format('$0,0'),
                    },
                ]}
                smallItems={[{ min: numeral(values.min).format('$0,0'), max: numeral(values.max).format('$0,0') }]}
                loading={loading}
            />
        </>
    )
}
