import React, {
    forwardRef,
    SyntheticEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'
import $ from 'jquery'
import { Form } from 'react-final-form'
import { OriginationModelReworked } from '@generated/graphql'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import { calcArrayAverage, getGoingInCapRate, getLoanMetrics } from 'origination-model'
import { fineTuneDealDecorator } from './fineTuneDealDecorator'
import { FormApi } from 'final-form'
import { useRouter } from 'next/router'
import { RightBar } from './components/RightBar/RightBar'
import { BottomBar } from './components/BottomBar/BottomBar'
import { DragDropWindow } from './components/DragDropWindow/DragDropWindow'
import Draggable from 'react-draggable'
import { SaveDealModal } from './components/SaveDealModal/SaveDealModal'
import { myStorage } from '../../../../../../store/storage'
import { NumberField } from '../../../components/NumberField'
/**
 * Component shows side menu with react portal api,
 * host divs can be found in OriginationModelWrapper
 * content is rendered to #right-dock div
 * and main content is shifted by #right-dock-placeholder div
 */
type FineTuneDealProps = {
    onVisibilityChange?: (v: boolean) => void
}
export const FineTuneDeal = forwardRef((props: FineTuneDealProps, ref) => {
    const router = useRouter()
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const proformas = useSelector((store: ReduxStoreState) => store.lender.proforma)
    const quotes = useSelector((state: ReduxStoreState) => state.lender.quoteData)
    const localContext = useMemo(() => ({ form: null as FormApi }), [])
    const [expanded, setExpanded] = useState(false)
    const [formValues, setFormValues] = useState(null)
    const [saveDealModalVisible, setSaveDealModalVisible] = useState(false)
    const [toggleLoadDeal, setToggleLoadDeal] = useState(false)
    const [fineTuneMode, setFineTuneMode] = useState('RightBar')
    const hide = useCallback(() => {
        $('#right-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
        $('#bottom-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
        setExpanded(false)
        if (props.onVisibilityChange) props.onVisibilityChange(false)
    }, [])
    useImperativeHandle(ref, () => ({
        show: () => {
            fineTuneMode === 'RightBar' && $('#right-dock-placeholder').addClass('fine-tune-placeholder-expanded')
            fineTuneMode === 'BottomBar' && $('#bottom-dock-placeholder').addClass('fine-tune-placeholder-expanded')
            setExpanded(true)
            if (props.onVisibilityChange) props.onVisibilityChange(true)
        },
        hide: () => {
            hide()
        },
        recalc: () => {
            localContext.form.change('tmp', new Date().getTime())
        },
    }))
    const changeFineTuneMode = useCallback((newMode) => {
        setFineTuneMode(newMode)
        if (newMode === 'BottomBar') {
            $('#right-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
            $('#bottom-dock-placeholder').addClass('fine-tune-placeholder-expanded')
        } else if (newMode === 'RightBar') {
            $('#right-dock-placeholder').addClass('fine-tune-placeholder-expanded')
            $('#bottom-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
        } else {
            $('#right-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
            $('#bottom-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
        }
    }, [])
    useEffect(() => {
        if (!model || !cashFlow || !quotes) return
        const loanMetrics = getLoanMetrics(model, cashFlow)
        const goingInCapRate = model && cashFlow ? getGoingInCapRate(cashFlow, model) : 0

        if (!formValues) {
            const defaultQuote = quotes?.find((item) => item.default) || null
            const valuationCapRate = defaultQuote?.valuationCapRate || goingInCapRate
            ReduxHelper.setIn(['lender', 'loanSizer', 'valuationCapRate'], valuationCapRate)

            let useSeniorLTC = true
            let useMezLTC = true

            if (defaultQuote?.useSeniorMaxLTCorV && defaultQuote?.useSeniorMaxLTCorV == 'ltc') useSeniorLTC = true
            else if (defaultQuote?.useSeniorMaxLTCorV && defaultQuote?.useSeniorMaxLTCorV == 'ltv') useSeniorLTC = false

            if (defaultQuote?.useMezMaxLTCorV && defaultQuote?.useMezMaxLTCorV == 'ltc') useMezLTC = true
            else if (defaultQuote?.useMezMaxLTCorV && defaultQuote?.useMezMaxLTCorV == 'ltv') useMezLTC = false

            setFormValues({
                name: defaultQuote?.name,
                id: defaultQuote?.id,
                minDSCR: defaultQuote?.minimumDSCR || +loanMetrics.dscr.toFixed(2),
                maxLTC: defaultQuote?.maximumLTC || loanMetrics.ltc,
                maxLTV: defaultQuote?.maximumLTV,
                rate: defaultQuote?.rate || model.debtAssumptionSenior.rate,
                ioPeriod: defaultQuote?.interestOnly || model.debtAssumptionSenior.ioPeriod,
                loanTerm: defaultQuote?.loanPeriod || model.debtAssumptionSenior.loanTerm,
                amortizationTerm: defaultQuote?.amortization || model.debtAssumptionSenior.amortizationTerm,

                mezMinDSCR: defaultQuote?.mezMinimumDSCR || +loanMetrics.dscrMez.toFixed(2),
                mezMaxLTC: defaultQuote?.mezMaximumLTC || loanMetrics.ltcMez,
                mezMaxLTV: defaultQuote?.mezMaximumLTV,
                mezRate: defaultQuote?.mezRate || model.debtAssumptionMez?.rate,
                mezIoPeriod: defaultQuote?.mezInterestOnly || model.debtAssumptionMez?.ioPeriod,
                mezLoanTerm: defaultQuote?.mezLoanPeriod || model.debtAssumptionMez?.loanTerm,
                mezAmortizationTerm: defaultQuote?.mezAmortization || model.debtAssumptionSenior?.amortizationTerm,

                borrowerNOI: defaultQuote?.borrowerNOI || proformas[0]?.noi?.adjustedT12 || cashFlow?.noi?.t12,

                vacancy: defaultQuote?.vacancy || calcArrayAverage(model.underwritingAssumption.vacancy),
                refinanceLTV: defaultQuote?.refinanceLTV || model?.refinance?.ltv || 0.65,
                exitCapRate:
                    defaultQuote?.exitCapRate || model?.refinance?.exitCapRate || model?.exitAssumption?.exitCapRate,
                valuationCapRate: valuationCapRate,

                useSeniorMaxLTC: useSeniorLTC,
                useSeniorMaxLTV: !useSeniorLTC,

                useMezMaxLTC: useMezLTC,
                useMezMaxLTV: !useMezLTC,
            })
        }
    }, [model, cashFlow, quotes])
    useEffect(() => {
        return () => {
            $('#right-dock-placeholder').removeClass('fine-tune-placeholder-expanded')
        }
    }, [])
    useEffect(() => {
        !expanded && setToggleLoadDeal(false)
    }, [expanded])
    const apply = useCallback((values) => null, [])
    const decorators = useMemo(() => [fineTuneDealDecorator], [])

    const projectId = +router.query.id || +router.query.edit

    const showMez = useMemo(() => {
        // @ts-ignore
        const sobj = myStorage.getItem('hasMez_' + projectId)
        if (sobj != null && sobj.val) {
            return true
        } else {
            myStorage.setItem('hasMez_' + projectId, { val: false })
        }
        return false
    }, [model?.sources])
    return (
        <>
            {formValues && (
                <Form onSubmit={apply} initialValues={formValues} decorators={decorators}>
                    {({ form }) => {
                        localContext.form = form

                        return (
                            <>
                                {fineTuneMode === 'RightBar' && (
                                    <RightBar
                                        hide={hide}
                                        form={form}
                                        expanded={expanded}
                                        localContext={localContext}
                                        toggleLoadDeal={toggleLoadDeal}
                                        setToggleLoadDeal={setToggleLoadDeal}
                                        changeFineTuneMode={changeFineTuneMode}
                                        setSaveDealModalVisible={setSaveDealModalVisible}
                                        showMez={showMez}
                                        useNCFnotNOI={model?.useNCFnotNOI}
                                    />
                                )}

                                {fineTuneMode === 'DragDrop' && expanded && (
                                    <Draggable handle="strong" bounds=".origination-model-wrapper">
                                        <div className="dnd-block dnd-absolute no-cursor">
                                            <DragDropWindow
                                                hide={hide}
                                                form={form}
                                                expanded={expanded}
                                                localContext={localContext}
                                                toggleLoadDeal={toggleLoadDeal}
                                                setToggleLoadDeal={setToggleLoadDeal}
                                                changeFineTuneMode={changeFineTuneMode}
                                                setSaveDealModalVisible={setSaveDealModalVisible}
                                                showMez={showMez}
                                                useNCFnotNOI={model?.useNCFnotNOI}
                                            />
                                        </div>
                                    </Draggable>
                                )}
                                {fineTuneMode === 'BottomBar' && expanded && (
                                    <BottomBar
                                        hide={hide}
                                        form={form}
                                        expanded={expanded}
                                        localContext={localContext}
                                        toggleLoadDeal={toggleLoadDeal}
                                        setToggleLoadDeal={setToggleLoadDeal}
                                        changeFineTuneMode={changeFineTuneMode}
                                        setSaveDealModalVisible={setSaveDealModalVisible}
                                        showMez={showMez}
                                        useNCFnotNOI={model?.useNCFnotNOI}
                                    />
                                )}
                                {saveDealModalVisible && (
                                    <SaveDealModal
                                        {...props}
                                        localContext={localContext}
                                        projectId={+router.query.id}
                                        close={(e: SyntheticEvent) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            setSaveDealModalVisible(false)
                                        }}
                                        setNoteModalVisible={setSaveDealModalVisible}
                                    />
                                )}
                                <div style={{ display: 'none' }}>
                                    <NumberField numberFormat={'number'} name={'tmp'} />
                                </div>
                            </>
                        )
                    }}
                </Form>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                .tab-container {
                    width: 100%;
                    display: grid;
                    grid-gap: 20px 20px;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                    }
                    .sub-grid-block {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: auto 1fr;
                        grid-gap: 20px;
                    }
                }
                .dnd-block {
                    filter: drop-shadow(0px 10px 35px rgba(83, 112, 129, 0.25));
                    width: fit-content;

                    &.dnd-absolute {
                        position: fixed;
                        z-index: 99;
                        top: 35px;
                        left: 45px;
                    }
                }
            `}</style>
        </>
    )
})
