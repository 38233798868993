import { useState } from 'react'
import { WeeklyMoveIns } from './WeeklyMoveIns'
import { MonthlyMoveIns } from './MonthlyMoveIns'

export function HistoricalMoveIns({
    rentRollGroupedByLeaseStartDate,
    futureRentRollByLeaseStartDate,
    projectionData,
    futureRentRollEarliestWeek,
    numberOfUnits,
    rentRoll,
    futureRentRoll,
}) {
    const [selectedTab, setSelectedTab] = useState(0)
    const [showSection, setShowSection] = useState(true)

    const handleTabClick = (tab) => {
        setSelectedTab(tab)
    }

    return (
        <div className="section-wrapper">
            <div className="title-section">
                <span className={'collapseSpan'} onClick={() => setShowSection((prevState) => !prevState)}>
                    <i className="material-icons-outlined" style={{ fontSize: 30, color: '#97A2B4' }}>
                        {showSection ? 'expand_more' : 'expand_less'}
                    </i>
                </span>
                Historical Move-Ins (Based on Rent Roll)
            </div>
            {showSection && (
                <>
                    <div className="historical-tabs-section">
                        <div className={selectedTab === 0 ? 'selectedTab' : ''} onClick={() => handleTabClick(0)}>
                            Monthly
                        </div>
                        <div className={selectedTab === 1 ? 'selectedTab' : ''} onClick={() => handleTabClick(1)}>
                            Weekly
                        </div>
                    </div>
                    {selectedTab === 1 && (
                        <WeeklyMoveIns
                            rentRollGroupedByLeaseStartDate={rentRollGroupedByLeaseStartDate}
                            futureRentRollByLeaseStartDate={futureRentRollByLeaseStartDate}
                            projectionData={projectionData}
                            futureRentRollEarliestWeek={futureRentRollEarliestWeek}
                            numberOfUnits={numberOfUnits}
                        />
                    )}
                    {selectedTab === 0 && (
                        <MonthlyMoveIns
                            futureRentRollEarliestWeek={futureRentRollEarliestWeek}
                            numberOfUnits={numberOfUnits}
                            rentRoll={rentRoll}
                            futureRentRoll={futureRentRoll}
                        />
                    )}
                </>
            )}
            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';
                .section-wrapper {
                    background-color: #fff;
                    width: 100%;
                    max-width: 2500px;
                    font-family: 'Inter';
                    font-style: normal;
                    margin-top: 20px;
                    margin-bottom: 50px;

                    .title-section {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 10px 20px;
                        font-weight: 500;
                        border-bottom: 1px solid #d9e0e5;

                        .collapseSpan {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }
                    }

                    .historical-tabs-section {
                        display: flex;
                        gap: 30px;
                        color: #687893;
                        padding: 20px;

                        div:hover {
                            color: #4486ff;
                            cursor: pointer;
                        }

                        .selectedTab {
                            color: #4486ff;
                            border-bottom: 2px solid #4486ff;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
