import React from 'react'
import { WidgetWrapper } from '../../../components/WidgetWrapper'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { getGoingInCapRate, getUnitMixMetrics } from 'origination-model'

type Props = { isFineTuneOn?: boolean }

export const PropertyDetails = React.memo(function PropertyDetails(props: Props) {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const unitMix = useSelector((state: ReduxStoreState) => state.lender?.unitMix)
    const unitMixMetrics = unitMix ? getUnitMixMetrics(unitMix) : null
    const goingInCapRate = model && cashFlow ? getGoingInCapRate(cashFlow, model) : 0
    const valuationCapRate = useSelector((state: ReduxStoreState) => state.lender?.loanSizer?.valuationCapRate)
    const valuation = cashFlow?.noi?.adjustedT12 ? cashFlow?.noi?.adjustedT12 / valuationCapRate : 0

    const isNewDev = project?.type && project.type == 'miltifamily.new_dev'
    return (
        <div className={'property-details'}>
            <WidgetWrapper style={{ padding: 0 }} showProgress={!model || !cashFlow}>
                <img src={project?.filePath} />
                <div className={'content'}>
                    <div className={'address'}>{project?.address}</div>
                    <div className={'widget-row'}>
                        <div className={'cell'}>
                            <div className={'metric-title'}>
                                {isNewDev ? 'Stabilized Value' : 'Estimated Valuation'}
                            </div>
                            <div className={'metric-value-large'}>{numeral(valuation).format('$0,0')}</div>
                        </div>
                        <div className={'cell'}>
                            <div className={'metric-title'}>Valuation Cap Rate</div>
                            <div className={'metric-value-large'}>{numeral(valuationCapRate).format('0.[00]%')}</div>
                        </div>
                    </div>
                    <div className={'divider'} />

                    <div className={'widget-row'}>
                        {!isNewDev && (
                            <>
                                <div className={'cell'}>
                                    <div className={'metric-title'}>Purchase Price</div>
                                    <div className={'metric-value-large'}>
                                        {numeral(model?.uses.purchasePrice).format('$0,0')}
                                    </div>
                                </div>
                                <div className={'cell'}>
                                    <div className={'metric-title'}>Going In Cap Rate</div>
                                    <div className={'metric-value-large'}>
                                        {numeral(goingInCapRate).format('0.[00]%')}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={'divider'} />
                    <div className={'widget-row'}>
                        <div className={'cell'}>
                            <div className={'metric-title'}>Number of Units</div>
                            <div className={'metric-value'}>{numeral(unitMixMetrics?.numOfUnits).format('0,0')}</div>
                        </div>
                        <div className={'cell'}>
                            <div className={'metric-title'}>Average Unit Size (SF)</div>
                            <div className={'metric-value'}>
                                {numeral(unitMixMetrics?.averageUnitSize).format('0.[00]')}
                            </div>
                        </div>
                    </div>
                    <div className={'widget-row'}>
                        <div className={'cell'}>
                            <div className={'metric-title'}>Total Rentable (SF)</div>
                            <div className={'metric-value'}>{numeral(unitMixMetrics.rentableSizeSF).format('0,0')}</div>
                        </div>
                        <div className={'cell'}>
                            <div className={'metric-title'}>Year Built</div>
                            <div className={'metric-value'}>{model?.yearBuilt}</div>
                        </div>
                    </div>
                </div>
            </WidgetWrapper>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .property-details {
                        flex: 1;
                        :global(.widget-body) {
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                            flex-grow: 1;
                        }
                        :global(.widget-container) {
                            //height: 344px;
                        }
                        img {
                            width: 45%;
                            object-fit: cover;
                            height: 100%;
                        }
                        .content {
                            width: 55%;
                            padding: 20px;
                            color: $default-text-color;
                            .address {
                                font-size: 15px;
                                line-height: 18px;
                                margin-bottom: 30px;
                            }
                            .widget-row {
                                flex-direction: row;
                                display: flex;
                                margin-top: 20px;
                                @media screen and (max-width: 1368px) {
                                    flex-direction: ${props.isFineTuneOn ? 'column' : 'row'};
                                    grid-template-rows: auto auto;
                                }
                                .cell {
                                    flex: 1;
                                    &:not(:last-child) {
                                        @media screen and (max-width: 1368px) {
                                            margin-bottom: ${props.isFineTuneOn ? '10px' : '0'};
                                        }
                                    }
                                }
                            }
                            .metric-title {
                                color: #97a2b4;
                                font-size: 14px;
                            }
                            .metric-value {
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 18px;
                            }
                            .metric-value-large {
                                font-weight: 500;
                                font-size: 21px;
                                line-height: 26px;
                            }
                            .divider {
                                border-bottom: 1px solid #d9e0e5;
                                margin-top: 20px;
                            }
                        }
                    }
                `}
            </style>
        </div>
    )
})
