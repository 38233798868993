import React, { useEffect, useCallback, useRef } from 'react'
import { ReduxStoreState } from '../../../../../../../../store'
import { SagaHelper } from '../../../../../../../../store/sagaHelper'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useRouter } from 'next/router'
import closeIcon from '../SaveDealModal/close.svg'

type Props = {
    setToggleLoadDeal?: (boolean) => void
    localContext: any
}

export const LoadDealWindow = (props: Props) => {
    const { localContext, setToggleLoadDeal } = props
    const router = useRouter()
    const quotes = useSelector((state: ReduxStoreState) => state.lender.quoteData)
    const loadDeal = useCallback(
        async (id) => {
            const quote = quotes?.find((item) => item.id == +id)
            if (quote) {
                let useSeniorLTC = true
                let useMezLTC = true

                if (quote.useSeniorMaxLTCorV && quote.useSeniorMaxLTCorV == 'ltc') useSeniorLTC = true
                else if (quote.useSeniorMaxLTCorV && quote.useSeniorMaxLTCorV == 'ltv') useSeniorLTC = false

                if (quote.useMezMaxLTCorV && quote.useMezMaxLTCorV == 'ltc') useMezLTC = true
                else if (quote.useMezMaxLTCorV && quote.useMezMaxLTCorV == 'ltv') useMezLTC = false

                localContext.form.reset({
                    id: quote.id,
                    name: quote.name,
                    minDSCR: quote.minimumDSCR,
                    mezMinDSCR: quote.mezMinimumDSCR,
                    maxLTC: quote?.maximumLTC,
                    maxLTV: quote?.maximumLTV,
                    rate: quote?.rate,
                    ioPeriod: quote?.interestOnly,
                    loanTerm: quote?.loanPeriod,
                    amortizationTerm: quote?.amortization,

                    mezMaxLTC: quote?.mezMaximumLTC,
                    mezMaxLTV: quote?.mezMaximumLTV,
                    mezRate: quote?.mezRate,
                    mezIoPeriod: quote?.mezInterestOnly,
                    mezLoanTerm: quote?.mezLoanPeriod,
                    mezAmortizationTerm: quote?.mezAmortization,

                    vacancy: quote?.vacancy,
                    refinanceLTV: quote?.refinanceLTV,
                    exitCapRate: quote?.exitCapRate,
                    valuationCapRate: quote?.valuationCapRate,
                    borrowerNOI: quote?.borrowerNOI,

                    useSeniorMaxLTC: useSeniorLTC,
                    useSeniorMaxLTV: !useSeniorLTC,

                    useMezMaxLTC: useMezLTC,
                    useMezMaxLTV: !useMezLTC,
                })
                localContext.form.change('lastUpdate', 0)
                quote.default = true
                SagaHelper.run(['quote', 'setQuoteAsDefault'], {
                    quoteId: quote.id,
                    projectId: +router.query.id,
                }).finally()
            }
            setToggleLoadDeal(false)
        },
        [quotes],
    )

    const loadDealRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (loadDealRef.current && !loadDealRef.current.contains(event.target)) {
                setToggleLoadDeal(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
    }, [loadDealRef])

    return (
        <div className={'load-deal-window'} ref={loadDealRef}>
            <div className="close" onClick={() => setToggleLoadDeal(false)}>
                <img src={closeIcon} />
            </div>
            <div className={'load-deal-block'}>
                <span>Load deal version</span>
                {quotes.map((item, ind) => {
                    return (
                        <p className={'load-item'} key={item.id} onClick={(event) => loadDeal(item.id)}>
                            <span>{`${item.name}`}</span>
                            <span>{`${moment(item.createdAt).format('MM/DD/YYYY')}`}</span>
                        </p>
                    )
                })}
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .load-deal-window {
                    background: #fff;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: -20%;
                    transform: translateY(-96%);
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.7);
                    border-radius: 3px;
                    .close {
                        position: absolute;
                        top: 0;
                        right: 10px;
                        color: #8691a2;
                        cursor: pointer;
                    }
                    .load-deal-block {
                        padding: 20px;
                        > span {
                            display: inline-block;
                            font-size: 14px;
                            color: #808fa7;
                            margin-bottom: 24px;
                        }
                        .load-item {
                            font-size: 14px;
                            line-height: 17px;
                            color: #161c26;
                            padding: 8px 4px;
                            margin: 0 -4px 8px -4px;
                            display: flex;
                            justify-content: space-between;
                            &:hover {
                                cursor: pointer;
                                background: #f9fafb;
                            }

                            span:last-child {
                                margin-left: 16px;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
