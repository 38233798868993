import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxStoreState } from '../../../../../../../../store'

type Props = {
    page: 0 | 1
}

export const KPI: FunctionComponent<Props> = React.memo(({ page }) => {
    const refinanceAssumptions = useSelector(
        (state: ReduxStoreState) =>
            state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions[page],
    )
    const { calculatedExitRefiRate, calculatedTerminalCapRate } = refinanceAssumptions?.rateAssumptions
    const { amortization, dscr, ltv } = refinanceAssumptions?.assumptions

    return (
        <div className={'sectionWrapper'}>
            <div className={'greyBox'}>
                <div className={'boxTitle'}>Exit Refi Rate</div>
                <div className={'boxValue'}>
                    {!!calculatedExitRefiRate && numeral(calculatedExitRefiRate).format('0.[00]%')}
                </div>
            </div>
            <div className={'greyBox'}>
                <div className={'boxTitle'}>Exit Cap Rate</div>
                <div className={'boxValue'}>
                    {calculatedTerminalCapRate && numeral(calculatedTerminalCapRate).format('0.[00]%')}
                </div>
            </div>
            <div className={'greyBox'}>
                <div className={'boxTitle'}>Amortization</div>
                <div className={'boxValue'}>{amortization}</div>
            </div>
            <div className={'greyBox'}>
                <div className={'boxTitle'}>DSCR</div>
                <div className={'boxValue'}>{dscr}</div>
            </div>
            <div className={'greyBox'}>
                <div className={'boxTitle'}>LTV</div>
                <div className={'boxValue'}>{ltv && numeral(ltv).format('0.[00]%')}</div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .sectionWrapper {
                    display: flex;
                    flex: 1;
                    max-width: 1350px;
                    gap: 20px;
                    margin: 20px;
                    margin-bottom: 10px;
                }
                .greyBox {
                    background: #f5f6f9;
                    border-radius: 4px;
                    text-indent: 20px;
                    flex: 1;
                    height: 85px;

                    .boxTitle {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #97a2b4;
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                    .boxValue {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 27px;
                        color: #161c26;
                    }
                }
            `}</style>
        </div>
    )
})
