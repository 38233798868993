import React, { useCallback, useMemo, useState } from 'react'
import Accordion from 'devextreme-react/accordion'
import { ModelTitle } from '../ModelTitle'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'
import { UnderwritingAssumptions } from './UnderwritingAssumptions'
import { PropertyTaxAssumptions } from './PropertyTaxAssumptions'
import { DebtAssumption } from './DebtAssumption'
import { SaleAssumption } from './SaleAssumption'

type Props = any

export function ExpensesTab(props: Props) {
    const activeYear = useSelector((state: ReduxStoreState) => state.lenderDashboard.expensesTab.activeYear)

    const collapsedPanels = useMemo(
        () => [
            {
                title: 'Growth Assumptions (Based on market performance)',
                component: <UnderwritingAssumptions />,
            },
            {
                title: 'Property Tax Assumption (Based on T12)',
                component: <PropertyTaxAssumptions />,
            },
            {
                title: 'Debt Assumptions',
                component: <DebtAssumption />,
            },
            /*   {
                title: 'Sale Assumptions',
                component: <SaleAssumption />,
            },*/
        ],
        [props.model],
    )
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel.cashFlow)
    // console.log('model', model)
    const yearsCount = model?.cashFlowAfterDebtService?.years?.length || 0
    // console.log('yearsCount', yearsCount)
    const years = []
    for (let i = 1; i <= yearsCount; i = i + 2) {
        if (i + 1 <= yearsCount) {
            years.push({ label: `${i}-${i + 1}`, years: [i, i + 1] })
        } else {
            years.push({ label: `${i}`, years: [i] })
        }
    }

    const changeYear = useCallback(async (y) => {
        await ReduxHelper.setIn(['lenderDashboard', 'expensesTab', 'activeYear'], y)
    }, [])
    return (
        <>
            <div className="items-wrapper">
                <div className={'years-container'}>
                    Years:
                    {years.map((y) => (
                        <div
                            key={y.label}
                            className={`year ${activeYear.label == y.label ? 'active' : ''} `}
                            onClick={() => changeYear(y)}
                        >
                            {y.label}
                        </div>
                    ))}
                </div>
                <Accordion
                    dataSource={collapsedPanels}
                    collapsible={true}
                    itemRender={(item) => item.component}
                    itemTitleRender={ModelTitle}
                />
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';

                    .years-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        font-size: 15px;
                        line-height: 18px;
                        color: $black;
                        .year {
                            margin-left: 12px;
                            cursor: pointer;
                            &.active {
                                font-weight: 600;
                                color: $blue;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
