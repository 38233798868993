import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { OriginationModelWrapper } from '../operating-assumptions/layouts/OriginationModelWrapper'
import { Dashboard } from './tabs/Dashboard/Dashboard'
import { CashFlowTab } from './tabs/Cashflow/CashFlowTab'
import { ButtonStyle, FixedFooter } from '../operating-assumptions/layouts/FixedFooter'
import { ModifyModel } from './tabs/ModifyModel'
import { ModifyButton } from './tabs/ModifyButton'
import { useRouter } from 'next/router'
import { ReduxHelper, ReduxStoreState } from '../../../store'
import { SagaHelper } from '../../../store/sagaHelper'
import { useSelector } from 'react-redux'
import 'devextreme/dist/css/dx.light.css'
import { ROUTE } from '../../../utils/constants/routes'
import { QuoteEditor } from './tabs/QuoteEditor'
import { StatusMessage } from '../../controls/StatusMessage'
import { useBeforeUnload } from 'utils/useBeforeUnload'
import { SaveDialog } from 'components/controls/SaveDialog'
import { LoadingOverlay } from 'components/LoadingOverlay'

import 'devextreme/dist/css/dx.light.css'
import { DashboardActions } from './tabs/DashboardActions'
import { DashboardComparablesTab } from './tabs/Comparables/DashboardComparablesTab'
import { useNotificationSubscription } from '../../../utils/notification'
import { Playground } from './tabs/Playground/Playground'
import { FannieTab } from './tabs/Fannie/FannieTab'
import { TAB } from './tabs/tabs'
import { ExitAnasysisPage } from './tabs/Fannie/ExitAnalysis/ExitAnalysisPage'
import { LeaseUpPage } from './tabs/Fannie/LeaseUp/LeaseUpPage'
import { DealNarrativeActions } from './tabs/DealNarrativeActions'
const sagaName = 'lenderOriginationModel'
const cloneDeep = require('lodash.clonedeep')
const debounce = require('lodash.debounce')

type Props = {
    accountType: string
    env: any
    user: any
    sensitivity: any
    useFanniePricing: boolean
}

export const FormContext = React.createContext(null)

const defaultFormModifiedValues = {
    valuationComparison: false,
    loanInfo: false,
    priorLoans: false,
    pricing: false,
    cashflow: false,
    refAssumptions: false,
    rateAssumptions: false,
    quote: false,
}

const defaultSaveDialogMessage = 'Do you want to save changes?'
const noActivitySaveDialogMessage = 'Still there? Need a hand saving your masterpiece?'
const defaultDialogConfirmBtnLabel = 'Yes'
const noActivityDialogConfirmBtnLabel = 'Save my work'
const defaultDialogRefuseBtnLabel = 'No'
const noActivityDialogRefuseBtnLabel = 'Cancel'

export const ProjectDashboard = (props: Props) => {
    const [showSaveDialogWithDelay, setShowSaveDialogWithDelay] = useState(false)
    const [showSaveDialog, setShowSaveDialog] = useState(false)
    const [showSaveLoader, setShowSaveLoader] = useState(false)

    const [saveDialogMessage, setSaveDialogMessage] = useState(defaultSaveDialogMessage)
    const [saveDialogConfirmBtnLabel, setSaveDialogConfirmBtnLabel] = useState(defaultDialogConfirmBtnLabel)
    const [saveDialogRefuseBtnLabel, setSaveDialogRefuseBtnLabel] = useState(defaultDialogRefuseBtnLabel)

    const [backToAssumptionsClicked, setBackToAssumptionsClicked] = useState(false)
    const [goToProjectsListClicked, setGoToProjectsListClicked] = useState(false)

    const [dealWasSaved, setDealWasSaved] = useState(false)
    const [formModified, setFormModified] = useState(defaultFormModifiedValues)
    const [formModifiedViaMutators, setFormModifiedViaMutators] = useState(defaultFormModifiedValues)
    const [formVisited, setFormVisited] = useState(defaultFormModifiedValues)

    useEffect(() => {
        if (dealWasSaved) setDealWasSaved(false)
    }, [dealWasSaved])

    const dealModified = useMemo(() => Object.values(formModified).includes(true), [formModified])
    const dealModifiedViaMutators = useMemo(
        () => Object.values(formModifiedViaMutators).includes(true),
        [formModifiedViaMutators],
    )

    useEffect(() => {
        if (showSaveDialogWithDelay && !showSaveDialog) {
            setShowSaveDialogWithDelay(false)
            setTimeout(() => {
                setSaveDialogMessage(defaultSaveDialogMessage)
                setSaveDialogConfirmBtnLabel(defaultDialogConfirmBtnLabel)
                setSaveDialogRefuseBtnLabel(defaultDialogRefuseBtnLabel)
                setShowSaveDialog(true)
            }, 1500)
        }
    }, [showSaveDialogWithDelay])

    useBeforeUnload(() => {
        if (dealModified || dealModifiedViaMutators) {
            setShowSaveDialogWithDelay(true)
            return true
        }
    })

    useEffect(() => {
        const handleUnload = () => {
            setShowSaveDialogWithDelay(false)
            setShowSaveDialog(false)
        }

        window.addEventListener('unload', handleUnload)
        return () => window.removeEventListener('unload', handleUnload)
    }, [])

    // const [resetTimer, setResetTimer] = useState(false)

    // const delay = 10000
    // const timerRef = useRef(null)

    // useEffect(() => {
    //     if (resetTimer) {
    //         if (timerRef.current) clearTimeout(timerRef.current)
    //         timerRef.current = setTimeout(() => setShowSaveDialog(true), delay)
    //         setResetTimer(false)
    //     }
    // }, [resetTimer])

    // useEffect(() => {
    //     if (!showSaveDialog && (dealModified || dealModifiedViaMutators)) {
    //         setResetTimer(true)
    //     }
    //     if (!dealModified && !dealModifiedViaMutators) {
    //         if (timerRef.current) clearTimeout(timerRef.current)
    //     }
    //     if (showSaveDialog) {
    //         if (timerRef.current) clearTimeout(timerRef.current)
    //     }
    // }, [showSaveDialog, dealModified, dealModifiedViaMutators])

    const [showNoActivityModal, setShowNoActivityModal] = useState(false)

    useEffect(() => {
        if (showNoActivityModal) {
            if (!showSaveDialog && (dealModified || dealModifiedViaMutators)) {
                setSaveDialogMessage(noActivitySaveDialogMessage)
                setSaveDialogConfirmBtnLabel(noActivityDialogConfirmBtnLabel)
                setSaveDialogRefuseBtnLabel(noActivityDialogRefuseBtnLabel)
                setShowSaveDialog(true)
            }
            setShowNoActivityModal(false)
        }
    }, [showNoActivityModal])

    useEffect(() => {
        const cb = debounce(() => {
            setShowNoActivityModal(true)
        }, 600000)
        window.addEventListener('mousemove', cb)
        return () => window.removeEventListener('mousemove', cb)
    }, [])

    const OFFSET = Object.freeze({
        INITIAL: -600,
        VISIBLE: 0,
    })
    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const [activeTab, setActiveTab] = useState<string>(
        props?.useFanniePricing
            ? TAB.PRICING_DEAL_ANALYTICS
            : props.accountType == 'lender'
            ? TAB.PLAYGROUND
            : /*TAB.DASHBOARD*/ TAB.PLAYGROUND,
    )
    const [comparableLinkAnchor, setComparableLinkAnchor] = useState<string>('')
    const [modifyOffset, setModifyOffset] = useState<number>(OFFSET.INITIAL)
    const reduxModel = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const fannieQuotes = useSelector((state: ReduxStoreState) => state.lender.fannieQuotes)

    const loadData = useCallback(() => {
        if (projectId) {
            SagaHelper.run(['lenderOriginationModel', 'getData'], { projectId: projectId })
            SagaHelper.run(['lenderOriginationModel', 'loadProforma'], { projectId: +projectId })
            SagaHelper.run(['lenderOriginationModel', 'loadQuoteData'], { projectId: projectId })
            SagaHelper.run(['lenderProjects', 'getProjectInfo'], {
                projectId,
            })
            SagaHelper.run(['lenderGeneralInputs', 'getData'], { projectId: projectId })
            SagaHelper.run(['lenderProjects', 'getUnitMix'], { projectId })
            SagaHelper.run(['lenderProjectSettings', 'getData'], { projectId })
            SagaHelper.run(['dashboardSettings', 'getData'], { projectId })
            SagaHelper.run(['lenderOriginationModel', 'loadT12History'], { projectId: +projectId })
        }
        const showNotification = localStorage?.getItem(`showLenderNotification${projectId}`)
        if (showNotification) {
            const notificationMessage = 'It can take up to 48 hour to validate the data (we will notify you by email)'
            SagaHelper.run(['notification', 'showAndHold'], notificationMessage)
            setTimeout(() => {
                SagaHelper.run(['notification', 'hideHolded'])
                localStorage.removeItem(`showLenderNotification${projectId}`)
            }, 5000)
        }
    }, [projectId])

    useEffect(() => {
        loadData()
        if (window.gtag)
            window.gtag('config', props.env.GOOGLE_ANALYTICS_KEY, {
                page_path: window.location.pathname + window.location.search + '&tab=' + activeTab.toLowerCase(),
            })
        return () => {
            SagaHelper.run([sagaName, 'clearData'])
            SagaHelper.run(['dashboardSettings', 'clearData'])
        }
    }, [projectId])
    //Reload data if project was updated in another window
    useNotificationSubscription('PROJECT_CASH_FLOW_AND_MODEL_UPDATED', async (notification) => {
        if (notification.payload.projectId == projectId) {
            await SagaHelper.run([sagaName, 'clearData'])
            loadData()
        }
    })
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)
    const settings = useSelector((store: ReduxStoreState) => store.lender.projectSettings)

    useEffect(() => {
        return () => {
            SagaHelper.run(['lenderPricing', 'resetIsPricingLoaded'])
        }
    }, [])

    useEffect(() => {
        if (window.gtag)
            window.gtag('config', props.env.GOOGLE_ANALYTICS_KEY, {
                page_path: window.location.pathname + window.location.search + '&tab=' + activeTab.toLowerCase(),
            })
    }, [activeTab])

    const saveDeal = useCallback(async () => {
        const { cashFlow, ...originationModel } = reduxModel

        await SagaHelper.run(['lenderOriginationModel', 'saveCashFlow'], {
            projectId,
            cashFlow,
        })
        if (originationModel['cashflow']) {
            delete originationModel['cashflow']
        }

        const originationModelTmp = cloneDeep(originationModel)
        if (originationModelTmp?.cfTrends) {
            for (const cfTrend of originationModelTmp.cfTrends) {
                for (const t of cfTrend.tax) {
                    delete t.previousVal
                }
            }
        }
        if (
            originationModelTmp?.fanniePricing?.supplemental &&
            originationModelTmp?.fanniePricing?.supplemental.length > 0
        ) {
            for (const sup of originationModelTmp?.fanniePricing.supplemental) {
                if (typeof sup.priorLien.maturityDate == 'string' && sup.priorLien.maturityDate.match(/^[0-9]*$/))
                    sup.priorLien.maturityDate = new Date(parseInt(sup.priorLien.maturityDate))
                if (typeof sup.priorLien.closed == 'string' && sup.priorLien.closed.match(/^[0-9]*$/))
                    sup.priorLien.closed = new Date(parseInt(sup.priorLien.closed))
            }
        }

        await SagaHelper.run(['lenderOriginationModel', 'setOriginationModel'], {
            projectId,
            originationModel: originationModelTmp,
        })

        if (originationModel.allowFannieAPricing) {
            if (pricing?.fanniePricing?.pricingOptions?.length > 0)
                await SagaHelper.run(['lenderPricing', 'setPricing'], {
                    projectId: projectId,
                    pricing: pricing,
                })
            const fannieQuotes = ReduxHelper.store.getState().lender.fannieQuotes
            if (fannieQuotes?.quotes?.length > 0)
                await SagaHelper.run(['lenderFannieQuotes', 'setFannieQuotes'], {
                    projectId: projectId,
                    fannieQuotes: fannieQuotes,
                })
        }

        let leaseUp = ReduxHelper.store.getState().lender.leaseUp
        if (leaseUp.data) {
            await SagaHelper.run(['lenderLeaseUp', 'updateLeaseUp'], {
                projectId,
                data: leaseUp.data,
            })
        }

        ReduxHelper.setIn(['lender', 'isModelChanged'], false)
        if (!dealWasSaved) setDealWasSaved(true)
        setFormModified(defaultFormModifiedValues)
        setFormModifiedViaMutators(defaultFormModifiedValues)
        setFormVisited(defaultFormModifiedValues)
        loadData()
        await SagaHelper.run(['notification', 'show'], 'Data saved')
    }, [projectId, reduxModel, pricing, dealWasSaved, loadData])

    const showModifyModel = useMemo(() => {
        if (props?.useFanniePricing) return false
        return true
    }, [props?.useFanniePricing])

    const handleSaveDialogClose = async () => {
        setShowSaveDialogWithDelay(false)
        setShowSaveDialog(false)
        if (backToAssumptionsClicked) await router.push(`${ROUTE.ASSUMPTIONS}?id=${projectId}`)
        if (goToProjectsListClicked) {
            const projectsListRoute = project?.accountType == 'lender' ? ROUTE.LENDER_PROJECTS : ROUTE.PROJECTS
            await router.push(projectsListRoute)
        }
    }

    const handleSaveDialogConfirm = async () => {
        setShowSaveDialogWithDelay(false)
        setShowSaveDialog(false)
        setShowSaveLoader(true)
        await saveDeal()
        setShowSaveLoader(false)
        if (backToAssumptionsClicked) await router.push(`${ROUTE.ASSUMPTIONS}?id=${projectId}`)
        if (goToProjectsListClicked) {
            const projectsListRoute = project?.accountType == 'lender' ? ROUTE.LENDER_PROJECTS : ROUTE.PROJECTS
            await router.push(projectsListRoute)
        }
    }

    return (
        <>
            {showSaveLoader && <LoadingOverlay />}
            <FormContext.Provider
                value={{
                    dealModified,
                    dealWasSaved,
                    formModified,
                    setFormModified,
                    formModifiedViaMutators,
                    setFormModifiedViaMutators,
                    formVisited,
                    setFormVisited,
                }}
            >
                <OriginationModelWrapper title={`${project?.name}`} subtitle={`${project?.address}`}>
                    <div className="dashboard-tabs-wrapper">
                        {props?.useFanniePricing && (
                            <>
                                <div
                                    className={`dashboard-tab ${
                                        activeTab == TAB.PRICING_DEAL_ANALYTICS ? 'active' : ''
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab(TAB.PRICING_DEAL_ANALYTICS)
                                    }}
                                >
                                    Deal Analytics
                                </div>
                                <div
                                    className={`dashboard-tab ${activeTab == TAB.PRICING ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab(TAB.PRICING)
                                    }}
                                >
                                    Pricing
                                </div>
                                <div
                                    className={`dashboard-tab ${activeTab == TAB.EXIT_ANALYSIS ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab(TAB.EXIT_ANALYSIS)
                                    }}
                                >
                                    Exit Analysis
                                </div>
                                <div
                                    className={`dashboard-tab ${activeTab == TAB.LEASE_UP ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab(TAB.LEASE_UP)
                                    }}
                                >
                                    Lease-Up
                                </div>
                            </>
                        )}
                        {
                            /*props.accountType == 'lender' &&*/ !props?.useFanniePricing && (
                                <div
                                    className={`dashboard-tab ${activeTab == TAB.PLAYGROUND ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setActiveTab(TAB.PLAYGROUND)
                                    }}
                                >
                                    Loan Sizer
                                </div>
                            )
                        }

                        {!props?.useFanniePricing && (
                            <div
                                className={`dashboard-tab ${activeTab == TAB.DASHBOARD ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setActiveTab(TAB.DASHBOARD)
                                }}
                            >
                                Deal Summary
                            </div>
                        )}
                        {!props?.useFanniePricing && (
                            <div
                                className={`dashboard-tab ${activeTab == TAB.CASHFLOW ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setActiveTab(TAB.CASHFLOW)
                                }}
                            >
                                Cash Flow
                            </div>
                        )}

                        <div
                            className={`dashboard-tab ${activeTab == TAB.QUOTE_EDITOR ? 'active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setActiveTab(TAB.QUOTE_EDITOR)
                            }}
                        >
                            Quote Editor
                        </div>

                        {(!props?.useFanniePricing || !!props?.user?.isAdmin) && (
                            <DashboardActions
                                project={project}
                                isAdmin={!!props?.user?.isAdmin}
                                setActiveTab={setActiveTab}
                                setComparableLinkAnchor={setComparableLinkAnchor}
                            />
                        )}
                        {(props?.useFanniePricing || !!props?.user?.isAdmin) && (
                            <DealNarrativeActions
                                project={project}
                                isAdmin={!!props?.user?.isAdmin}
                                setActiveTab={setActiveTab}
                            />
                        )}
                    </div>
                    {activeTab == TAB.PRICING_DEAL_ANALYTICS && (
                        <FannieTab tab={TAB.PRICING_DEAL_ANALYTICS} saveDeal={saveDeal} />
                    )}
                    {activeTab == TAB.PRICING && <FannieTab tab={TAB.PRICING} saveDeal={saveDeal} />}
                    {activeTab == TAB.PLAYGROUND && <Playground />}
                    {activeTab == TAB.DASHBOARD && <Dashboard {...props} />}
                    {activeTab == TAB.CASHFLOW && <CashFlowTab model={model} saveDeal={saveDeal} />}
                    {activeTab == TAB.EXIT_ANALYSIS && <ExitAnasysisPage saveDeal={saveDeal} />}
                    {activeTab == TAB.LEASE_UP && <LeaseUpPage />}

                    {activeTab == TAB.QUOTE_EDITOR && !props?.useFanniePricing && <QuoteEditor model={model} />}
                    {activeTab == TAB.QUOTE_EDITOR && props?.useFanniePricing && (
                        <FannieTab tab={TAB.QUOTE_EDITOR} saveDeal={saveDeal} />
                    )}
                    {activeTab == TAB.COMPARABLES && <DashboardComparablesTab linkAnchor={comparableLinkAnchor} />}
                    {showModifyModel && activeTab != TAB.PLAYGROUND && activeTab != TAB.PRICING && (
                        <>
                            <ModifyButton
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (window.gtag)
                                        window.gtag('config', props.env.GOOGLE_ANALYTICS_KEY, {
                                            page_path:
                                                window.location.pathname + window.location.search + '&tab=modify_model',
                                        })
                                    setModifyOffset(OFFSET.VISIBLE)
                                }}
                            />
                            <ModifyModel
                                offset={modifyOffset}
                                close={(e) => {
                                    e.preventDefault()
                                    if (window.gtag)
                                        window.gtag('config', props.env.GOOGLE_ANALYTICS_KEY, {
                                            page_path:
                                                window.location.pathname +
                                                window.location.search +
                                                '&tab=' +
                                                activeTab.toLowerCase(),
                                        })
                                    setModifyOffset(OFFSET.INITIAL)
                                }}
                            />
                        </>
                    )}

                    <StatusMessage />

                    <FixedFooter
                        buttons={[
                            {
                                title: 'Back to assumptions',
                                type: ButtonStyle.ghost,
                                callback: async (e) => {
                                    e?.preventDefault()
                                    e?.stopPropagation()
                                    if (dealModified || dealModifiedViaMutators) {
                                        setBackToAssumptionsClicked(true)
                                        setSaveDialogMessage(defaultSaveDialogMessage)
                                        setSaveDialogConfirmBtnLabel(defaultDialogConfirmBtnLabel)
                                        setSaveDialogRefuseBtnLabel(defaultDialogRefuseBtnLabel)
                                        setShowSaveDialog(true)
                                    } else {
                                        await router.push(`${ROUTE.ASSUMPTIONS}?id=${projectId}`)
                                    }
                                },
                                style: {
                                    marginRight: 'auto',
                                },
                                disabled: settings?.blockRecalculationOfAssumptions || false,
                            },
                            {
                                title: 'Go to projects list',
                                type: ButtonStyle.ghost,
                                callback: async (e) => {
                                    e?.preventDefault()
                                    e?.stopPropagation()
                                    if (dealModified || dealModifiedViaMutators) {
                                        setGoToProjectsListClicked(true)
                                        setSaveDialogMessage(defaultSaveDialogMessage)
                                        setSaveDialogConfirmBtnLabel(defaultDialogConfirmBtnLabel)
                                        setSaveDialogRefuseBtnLabel(defaultDialogRefuseBtnLabel)
                                        setShowSaveDialog(true)
                                    } else {
                                        const projectsListRoute =
                                            project?.accountType == 'lender' ? ROUTE.LENDER_PROJECTS : ROUTE.PROJECTS
                                        await router.push(projectsListRoute)
                                    }
                                },
                            },
                            {
                                title: 'Save Deal',
                                type: ButtonStyle.regular,
                                callback: async (e) => {
                                    e?.preventDefault()
                                    e?.stopPropagation()
                                    saveDeal()
                                },
                            },
                        ]}
                    />
                    <div id={'left-dock'} />
                    {showSaveDialog && (
                        <SaveDialog
                            handleClose={handleSaveDialogClose}
                            handleConfirm={handleSaveDialogConfirm}
                            message={saveDialogMessage}
                            confirmBtnLabel={saveDialogConfirmBtnLabel}
                            refuseBtnLabel={saveDialogRefuseBtnLabel}
                        />
                    )}
                    {/*language=scss*/}
                    <style jsx>{`
                        $blue: #4485ff;
                        $gray: #4f5662;
                        .dashboard-tabs-wrapper {
                            display: flex;
                            align-items: center;
                            .dashboard-tab {
                                font-size: 15px;
                                color: $gray;
                                margin-right: 40px;
                                position: relative;
                                cursor: pointer;
                                &.active {
                                    color: $blue;
                                    font-weight: 600;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 3px;
                                        bottom: -3px;
                                        left: 0;
                                        border-top: 3px solid $blue;
                                        border-top-right-radius: 15px;
                                        border-top-left-radius: 15px;
                                    }
                                }
                            }
                            .edit-img {
                                width: auto;
                                height: 20px;
                                position: relative;
                                top: -3px;
                                cursor: pointer;
                            }
                            :global(.share-img) {
                                display: block;
                                width: auto;
                                height: 20px;
                                cursor: pointer;
                                position: relative;
                                left: 10px;
                                top: 3px;
                            }
                            .button-wrapper {
                                border: 1px solid #d9e0e5;
                                display: flex;
                                padding: 5px 15px;
                                border-radius: 3px;
                                position: relative;
                                top: -5px;
                            }
                        }
                    `}</style>
                </OriginationModelWrapper>
            </FormContext.Provider>
        </>
    )
}
