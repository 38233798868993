import React from 'react'

export const ModelTitle = (data) => {
    return (
        <div className={'model-group-title'}>
            {data.title}
            {/*language=scss*/}
            <style jsx global>{`
                @import './src/scss/colors.scss';
                .model-group-title {
                    color: $black;
                    font-weight: 500;
                    font-size: 16px;
                }
                .dx-accordion-item-opened {
                    border: none;
                    .model-group-title {
                        color: $blue;
                    }
                }
                .dx-accordion-item,
                .dx-accordion-item:last-child {
                    border: none;
                }
            `}</style>
        </div>
    )
}
