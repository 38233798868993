import React, { FunctionComponent, useCallback, useEffect, useState, useContext } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'
import { NumberField } from '../../../../../components/NumberField'
import { FormContext } from '../../../../../Dashboard'
const cloneDeep = require('lodash.clonedeep')

type AssumptionsProps = {
    page: 0 | 1
}

export const Assumptions: FunctionComponent<AssumptionsProps> = React.memo((props) => {
    const assumptions = useSelector(
        (state: ReduxStoreState) =>
            state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions[props.page].assumptions,
    )
    const [initialValues, setInitialValues] = useState<any>()

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const onSubmit = useCallback(
        (values: any) => {
            if (!formModified.refAssumptions && formVisited?.refAssumptions) {
                setFormModified((prevState) => ({
                    ...prevState,
                    refAssumptions: true,
                }))
            }

            ReduxHelper.setIn(
                // @ts-ignore
                [
                    'lender',
                    'originationModel',
                    'fannieExitScenarioRefinance',
                    'refinanceAssumptions',
                    `${props.page}`,
                    'assumptions',
                ],
                cloneDeep(values),
            )
        },
        [formModified?.refAssumptions, formVisited?.refAssumptions],
    )

    useEffect(() => {
        if (!!assumptions && !initialValues) {
            setInitialValues({
                amortization: assumptions.amortization || 30,
                dscr: assumptions.dscr || 1.25,
                ltv: assumptions.ltv || 0.8,
            })
        }
    }, [assumptions, initialValues])

    useEffect(() => {
        return () =>
            setFormVisited((prevState) => ({
                ...prevState,
                refAssumptions: false,
            }))
    }, [])

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ visited }) => {
                if (visited) {
                    if (Object.values(visited).includes(true)) {
                        if (formVisited?.refAssumptions === false) {
                            setFormVisited((prevState) => ({
                                ...prevState,
                                refAssumptions: true,
                            }))
                        }
                    }
                }
                return <AssumptionsForm />
            }}
        </Form>
    )
})

type AssumptionsFormProps = any
const AssumptionsForm: FunctionComponent<AssumptionsFormProps> = () => {
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    useEffect(() => {
        if (form) form.submit()
    }, [formValues])

    return (
        <>
            <div className={'tableWrapper'}>
                <div className={'title'}>Refinance Assumptions</div>
                <div className={'mainPart'}>
                    <table>
                        <thead>
                            <tr>
                                <td />
                                <td />
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'rowLabel'}>Amortization</td>
                                <td className={'rowValue'}>
                                    <NumberField
                                        name={`amortization`}
                                        numberFormat={'int'}
                                        cssClassName={'refiAssumptionField'}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>DSCR</td>
                                <td>
                                    <NumberField
                                        name={`dscr`}
                                        numberFormat={'decimal'}
                                        cssClassName={'refiAssumptionField'}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>LTV</td>
                                <td>
                                    <NumberField
                                        name={`ltv`}
                                        numberFormat={'percent'}
                                        cssClassName={'refiAssumptionField'}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .tableWrapper {
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    flex: 1;
                    box-sizing: content-box;
                    margin-bottom: 30px;
                    background: white;
                }
                .rowLabel {
                    width: 70%;
                }
                .rowValue {
                    width: 30%;
                }
                .title {
                    font-family: 'Inter';
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #d9e0e5;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                    width: 100%;
                }
                thead {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    color: #676f7a;
                }
                td {
                    text-align: left;
                }
                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;

                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                }
                :global(.refiAssumptionField) {
                    text-align: left !important;
                    position: relative;
                    width: initial !important;
                }
            `}</style>
        </>
    )
}
