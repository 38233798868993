import React, { useEffect, useState } from 'react'
import { Gauge } from './Gauge'
import { ReduxStoreState } from '../../../../../../store'
import numeral from 'numeral'
import { useModel } from '../../../hooks/useModel'
import { useSelector } from 'react-redux'
type Props = {
    projectId: number
}

export function FreeCashFlowAfterDebtGauge(props: Props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState({ value: 0, percent: 0, threshold: 0, originalValue: 0 })
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)
    const model = useModel()
    useEffect(() => {
        if (settings?.freeCashFlowAfterDebt == null || !model.avgCashFlowAfterDebtWithoutSale) return
        const value = (model.avgCashFlowAfterDebtWithoutSale / model.avgGrossPotentialRent) * 100
        let percent = value / (settings.freeCashFlowAfterDebt * 2 || 1)
        percent = percent > 1 ? 1 : percent
        setData({
            percent,
            value,
            threshold: settings.freeCashFlowAfterDebt,
            originalValue: model.avgCashFlowAfterDebtWithoutSale,
        })
        setLoading(false)
    }, [model?.avgCashFlowAfterDebt, settings?.freeCashFlowAfterDebt])

    const customColor = data.value < data.threshold ? '#C93838' : '#67d554'

    return (
        <Gauge
            title={'Avg. FCF vs Avg. GPR'}
            badge={'after debt'}
            value={`${data.value.toFixed(0)}%`}
            percent={data.percent}
            leftHint={'Cash Flow'}
            leftValue={`$${numeral(data.originalValue).format('0,0')}`}
            rightHint={'Threshold'}
            rightValue={`${data.threshold}%`}
            hover={'gauge'}
            error={error}
            loading={loading}
            customColor={customColor}
        />
    )
}
