import React, { useEffect, useState } from 'react'
import { Gauge } from './Gauge'
import { ReduxStoreState } from '../../../../../../store'
import { SagaHelper } from '../../../../../../store/sagaHelper'
import { useModel } from '../../../hooks/useModel'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
type Props = {
    projectId: number
}

export function CapRateGauge(props: Props) {
    const [loading, setLoading] = useState(true)

    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState({ value: 0, percent: 0, threshold: 0 })

    const model = useModel()
    /*
    const marketAvgCapRate = useSelector(
        (state: ReduxStoreState) => state.lender.originationModel?.marketCapRate || 0.05,
    )

     */
    const [marketAvgCapRate, setmarketAvgCapRate] = useState(null)
    useEffect(() => {
        SagaHelper.run(['lenderDashboard', 'capRateVsMarketCapRate'], props.projectId)
            .then((d) => {
                setmarketAvgCapRate(d.threshold)
            })
            .catch((e) => {
                // setError(e.message)
                setmarketAvgCapRate(0.05)
            })
    }, [props.projectId])

    useEffect(() => {
        if (!model.avgNoi || !marketAvgCapRate) return
        setLoading(false)
        const capRate = +((model.avgNoi / model.purchasePrice) * 100).toFixed(2)
        let percent = capRate / (marketAvgCapRate * 100 * 2)
        percent = percent > 1 ? 1 : percent
        setData({
            percent,
            value: capRate,
            threshold: marketAvgCapRate,
        })
    }, [marketAvgCapRate, model.avgNoi, model.purchasePrice])

    const customColor = data.value > data.threshold * 100 ? '#C93838' : '#67d554'

    return (
        <Gauge
            title={'Project Cap Rate vs Market Avg Cap Rate'}
            value={`${data.value}%`}
            percent={data.percent}
            leftHint={null}
            leftValue={`${data.value}%`}
            rightHint={'Avg. Cap Rate'}
            rightValue={numeral(data.threshold).format('0.[00]%')}
            hover={'gauge'}
            loading={loading}
            error={error}
            customColor={customColor}
        />
    )
}
