import { useMemo } from 'react'
import numeral from 'numeral'
import { getHistoricalWeeks, getHistoricalMoveIns } from 'origination-model'
import { KPIBox } from '../KPIBox'
import { getDateString } from '../utils/dates'

export function WeeklyMoveIns({
    rentRollGroupedByLeaseStartDate,
    futureRentRollByLeaseStartDate,
    projectionData,
    futureRentRollEarliestWeek,
    numberOfUnits,
}) {
    const historicalWeeks = useMemo(
        () => getHistoricalWeeks(rentRollGroupedByLeaseStartDate),
        [rentRollGroupedByLeaseStartDate],
    )

    const historicalMoveIns = useMemo(
        () => getHistoricalMoveIns(historicalWeeks, rentRollGroupedByLeaseStartDate, futureRentRollByLeaseStartDate),
        [historicalWeeks, rentRollGroupedByLeaseStartDate, futureRentRollByLeaseStartDate],
    )

    const filteredProjectionData = useMemo(() => {
        if (projectionData?.length) {
            let lastHistoricalMoveInIdx = null
            projectionData.forEach((item, idx) => {
                if (item.historicalMoveIns) lastHistoricalMoveInIdx = idx
            })
            if (!lastHistoricalMoveInIdx && lastHistoricalMoveInIdx !== 0) {
                return []
            } else {
                const filteredData = projectionData.slice(0, lastHistoricalMoveInIdx + 1)
                return filteredData
            }
        } else {
            return []
        }
    }, [projectionData])

    const historicalCumulativeMoveIns = useMemo(() => {
        if (historicalMoveIns?.length) {
            let sum = 0
            return historicalMoveIns.map((item: any) => {
                sum += item
                return sum
            })
        } else {
            return []
        }
    }, [historicalMoveIns])

    const historicalOccupancy = useMemo(() => {
        if (historicalCumulativeMoveIns?.length && numberOfUnits) {
            return historicalCumulativeMoveIns.map((item) => item / numberOfUnits)
        } else {
            return []
        }
    }, [historicalCumulativeMoveIns, numberOfUnits])

    const projectionCumulativeMoveIns = useMemo(() => {
        if (filteredProjectionData?.length && historicalCumulativeMoveIns?.length) {
            const historicalSum = historicalCumulativeMoveIns[historicalCumulativeMoveIns.length - 1]
            let sum = historicalSum
            return filteredProjectionData.map((item: any) => {
                sum += item.historicalMoveIns
                return sum
            })
        } else {
            return []
        }
    }, [filteredProjectionData, historicalCumulativeMoveIns])

    const projectionOccupancy = useMemo(() => {
        if (projectionCumulativeMoveIns?.length && numberOfUnits) {
            return projectionCumulativeMoveIns.map((item) => item / numberOfUnits)
        } else {
            return []
        }
    }, [projectionCumulativeMoveIns, numberOfUnits])

    const avgMoveIns = useMemo(() => {
        let sum = 0
        if (historicalMoveIns?.length) {
            historicalMoveIns.forEach((item) => (sum += item))
        }
        if (filteredProjectionData?.length) {
            filteredProjectionData.forEach((item) => (sum += item.historicalMoveIns))
        }
        if (!sum) {
            return null
        } else {
            return Math.round(sum / ((historicalMoveIns?.length || 0) + (filteredProjectionData?.length || 0)))
        }
    }, [historicalMoveIns, filteredProjectionData])

    return (
        <>
            <div style={{ paddingLeft: 20 }}>
                <KPIBox className="avg-move-ins" title="Historical Average Move-Ins" value={avgMoveIns?.toString() || '-'} />
            </div>

            <div className="weekly-move-ins-wrapper">
                <div
                    className="custom-table-wrapper container-fluid"
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        display: 'flex',
                    }}
                >
                    <div className="first-column">
                        <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                            <div className="custom-cell year-cell">
                                <div>WEEK OF MOVE-IN</div>
                            </div>
                        </div>

                        {historicalWeeks.map((week) => (
                            <div className="custom-table-row row" key={week}>
                                <div className="col-12">
                                    {getDateString(+week)}{' '}
                                    {+futureRentRollEarliestWeek === +week && (
                                        <span style={{ paddingLeft: 20 }}>Future Move-Ins</span>
                                    )}
                                </div>
                            </div>
                        ))}

                        {filteredProjectionData.map((data) => (
                            <div className="custom-table-row row" key={data.week}>
                                <div className="col-12">
                                    {getDateString(+data.week)}
                                    {+futureRentRollEarliestWeek === +data.week && (
                                        <span style={{ paddingLeft: 20 }}>Future Move-Ins</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div>
                        <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                            <div className="custom-cell year-cell">
                                <div>NUMBER OF</div>
                                <div>MOVE-INS</div>
                            </div>
                        </div>

                        {historicalMoveIns.map((item, idx) => (
                            <div className="custom-table-row row" key={idx}>
                                <div className="col-12">{item}</div>
                            </div>
                        ))}

                        {filteredProjectionData.map((data) => (
                            <div className="custom-table-row row" key={data.week}>
                                <div className="col-12">{data.historicalMoveIns}</div>
                            </div>
                        ))}
                    </div>

                    <div>
                        <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                            <div className="custom-cell year-cell">
                                <div>CUMULATIVE</div>
                                <div>MOVE-INS</div>
                            </div>
                        </div>
                        {historicalCumulativeMoveIns.map((item, idx) => (
                            <div className="custom-table-row row" key={idx}>
                                <div className="col-12">{item}</div>
                            </div>
                        ))}
                        {projectionCumulativeMoveIns.map((item, idx) => (
                            <div className="custom-table-row row" key={idx}>
                                <div className="col-12">{item}</div>
                            </div>
                        ))}
                    </div>

                    <div>
                        <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                            <div className="custom-cell year-cell">
                                <div>OCCUPANCY</div>
                            </div>
                        </div>
                        {historicalOccupancy.map((item, idx) => (
                            <div className="custom-table-row row" key={idx}>
                                <div className="col-12">{numeral(item).format('0.[0]%')}</div>
                            </div>
                        ))}
                        {projectionOccupancy.map((item, idx) => (
                            <div className="custom-table-row row" key={idx}>
                                <div className="col-12">{numeral(item).format('0.[0]%')}</div>
                            </div>
                        ))}
                    </div>
                </div>
                {/*language=scss*/}
                <style jsx global>{`
                    @import './src/scss/colors.scss';
                    .avg-move-ins {
                        width: 300px;
                        padding: 15px 20px;
                        border-radius: 4px;
                        background: #F5F6F9;

                        .title {
                            color: #97a2b4;
                            margin-bottom: 5px;
                        }

                        .value {
                            font-size: 22px;
                            font-weight: 500;
                        }
                    }
                    .weekly-move-ins-wrapper {
                        display: flex;
                        background-color: #fff;

                        .row {
                            margin: initial;
                        }

                        .custom-table-wrapper {
                            background-color: $row-background-1;
                            border: none;
                            text-align: right;

                            .first-column {
                                text-align: initial;
                            }

                            .custom-table-title {
                                text-transform: uppercase;
                                font-size: 12px;
                                color: #676f7a;
                                padding: 7px;
                                display: flex;
                                flex-wrap: nowrap;
                                .custom-cell {
                                    padding: 0;
                                    min-width: 170px;
                                    width: 170px;
                                    display: flex;
                                    align-items: center;
                                }
                                .year-cell {
                                    display: block;
                                }
                            }
                            .custom-table-row {
                                font-size: 14px;
                                padding: 7px;
                                align-items: center;
                                display: flex;
                                flex-wrap: nowrap;
                                height: 40px;
                                &:nth-of-type(2n) {
                                    background-color: $row-background-2;
                                }
                                .custom-cell {
                                    padding: 0 10px;
                                    min-width: 170px;
                                    width: 170px;

                                    .biggerFont {
                                        font-size: 1.25rem;
                                    }
                                }
                            }
                        }
                    }
                `}</style>
            </div>
        </>
    )
}
