import React from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { NumberField } from '../../../components/NumberField'
import { BooleanField } from '../../../components/BooleanField'

type Props = any

export function PropertyTaxAssumptions(props: Props) {
    const activeYear = useSelector((state: ReduxStoreState) => state.lenderDashboard.expensesTab.activeYear)

    return (
        <>
            <div>
                <div className={'fields-header'}>
                    <div style={{ width: '50%' }} />
                    <div className={'year'} style={{ width: '25%' }}>
                        YEAR {activeYear.years[0]}
                    </div>
                    <div
                        className={'year'}
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        YEAR {activeYear.years[1]}
                    </div>
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Property tax growth rates
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField
                            name={`propertyTaxAssumption.taxGrowthRates[${activeYear.years[0] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                    <div
                        className="model-field-value"
                        style={{ width: '25%', visibility: !activeYear.years[1] ? 'hidden' : 'visible' }}
                    >
                        <NumberField
                            name={`propertyTaxAssumption.taxGrowthRates[${activeYear.years[1] - 1}]`}
                            numberFormat={'percent'}
                        />
                    </div>
                </div>
                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Mileage Rate
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField name={`propertyTaxAssumption.mileageRate`} numberFormat={'percent'} />
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }} />
                </div>
                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Percent Value Assessed
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField name={`propertyTaxAssumption.percentValueAssessed`} numberFormat={'percent'} />
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }} />
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Reassessed Upon Sale
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <BooleanField name={`propertyTaxAssumption.reassessedUponSale`} />
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }} />
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Current Assessed Value
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField name={`propertyTaxAssumption.currentAssessedValue`} numberFormat={'currency'} />
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }} />
                </div>

                <div className="field-values">
                    <div className="model-field-title" style={{ width: '50%' }}>
                        Fixed Charge Assessments
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }}>
                        <NumberField name={`propertyTaxAssumption.fixedChargeAssessments`} numberFormat={'currency'} />
                    </div>
                    <div className="model-field-value" style={{ width: '25%' }} />
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    $col1: '50%';
                    .fields-header {
                        display: flex;
                        flex-direction: row;
                        margin-top: 17px;
                        margin-bottom: 17px;
                        font-size: 12px;
                        line-height: 15px;
                        div:first-child {
                            width: $col1;
                        }
                        div:nth-child(2) {
                            margin-right: 20px;
                        }
                        div:nth-child(3) {
                            margin-right: 20px;
                        }
                        .year {
                            text-align: right;
                            text-transform: uppercase;
                            color: $gray-nevada;
                        }
                    }
                    .field-values {
                        display: flex;
                        flex-direction: row;
                        margin-top: 13px;
                        .model-field-title {
                            width: $col1;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                            color: $black;
                        }

                        .model-field-value {
                            text-align: right;
                            &:nth-child(2) {
                                margin-right: 20px;
                            }
                            &:nth-child(3) {
                                margin-right: 20px;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
