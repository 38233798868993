import React, { useMemo } from 'react'
import { FieldPlusPerUnitComponent } from './FieldPlusPerUnitComponent'
import { DefaultFieldsComponent } from './DefaultFieldsComponent'
import Accordion from 'devextreme-react/accordion'
import { ModelTitle } from '../ModelTitle'
import { ReturnMetrics } from './ReturnMetrics'

export function IncomeTab() {
    const collapsedPanels = useMemo(
        () => [
            {
                title: 'Uses',
                component: (
                    <FieldPlusPerUnitComponent
                        fieldGroup={'uses'}
                        fields={[
                            { fld: 'purchasePrice', label: 'Purchase Price' },
                            { fld: 'transactionCosts', label: 'Transaction Costs' },
                            { fld: 'lenderFee', label: 'Lender Fee' },
                            { fld: 'renovationProgram', label: 'Renovation Program' },
                            { fld: 'preFunded', label: 'Pre Funded' },
                        ]}
                    />
                ),
            },
            {
                title: 'Exit Assumption',
                component: (
                    <DefaultFieldsComponent
                        fieldGroup={'exitAssumption'}
                        fields={[
                            { fld: 'saleYearAnalysisPeriod', label: 'Sale Year Analysis Period', type: 'years' },
                            { fld: 'saleDate', label: 'Sale Date', type: 'date' },
                            { fld: 'computed.salePrice', label: 'Sale Price', type: 'currency', readOnly: true },
                            { fld: 'saleCost', label: 'Sale Cost', type: 'percent' },
                            { fld: 'exitCapRate', label: 'Exit Cap Rate', type: 'percent' },
                            { fld: 'capRateSpread', label: 'Cap Rate Spread', type: 'percent', readOnly: true },
                        ]}
                    />
                ),
            },

            {
                title: 'Sources',
                component: (
                    <FieldPlusPerUnitComponent
                        fieldGroup={'sources'}
                        fields={[
                            { fld: 'seniorDebt', label: 'Senior Debt' },
                            { fld: 'mezzanine', label: 'Mezzanine' },
                            { fld: 'totalEquity', label: 'Total Equity' },
                        ]}
                    />
                ),
            },
            {
                title: 'Return Metrics',
                component: <ReturnMetrics />,
            },
            /*
            {
                title: 'Deal Level Fees',
                component: (
                    <DefaultFieldsComponent
                        fieldGroup={'dealLevelFees'}
                        fieldsTypes={{ asset_management_fee: 'percent' }}
                        fields={model?.dealLevelFees}
                    />
                ),
            },*/
        ],
        [],
    )

    return (
        <>
            <div className="items-wrapper">
                <Accordion
                    dataSource={collapsedPanels}
                    collapsible={true}
                    itemRender={(item) => item.component}
                    itemTitleRender={ModelTitle}
                />
            </div>
        </>
    )
}
