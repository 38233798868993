import React, { FunctionComponent, useCallback, useEffect, useState, useMemo } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'
const cloneDeep = require('lodash.clonedeep')

type Props = any

export const LoanDetails: FunctionComponent<Props> = React.memo(({ selectedPricingOption, page }) => {
    const [initialValues, setInitialValues] = useState<any>()

    const onSubmit = useCallback((values: any) => {
        ReduxHelper.setIn(
            // @ts-ignore
            [
                'lender',
                'originationModel',
                'fannieExitScenarioRefinance',
                'refinanceAssumptions',
                `${page}`,
                'loanDetails',
            ],
            cloneDeep(values),
        )
    }, [])

    useEffect(() => {
        if (!!selectedPricingOption && !initialValues) {
            setInitialValues({
                yearsOfIo: selectedPricingOption.scenario?.actual?.financeOptions?.io,
                termInYears: selectedPricingOption.scenario?.actual?.financeOptions?.loanTerm,
                maturityBalance: null,
                maturityNcf: null,
            })
        }
    }, [selectedPricingOption, initialValues])

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {() => <LoanDetailsForm page={page} selectedPricingOption={selectedPricingOption} />}
        </Form>
    )
})

const LoanDetailsForm: FunctionComponent<Props> = (props) => {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const refinanceAssumptions = useSelector(
        (state: ReduxStoreState) => state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions[props.page],
    )

    const isSupplemental = useMemo(() => project?.type == 'miltifamily.value_add_supplemental', [project?.type])

    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    const selectedPricingOption = props.selectedPricingOption

    useEffect(() => {
        if (isSupplemental) {
            if (!!formValues.termInYears && !!refinanceAssumptions?.balanceCombined?.years) {
                const maturityBalance = refinanceAssumptions?.balanceCombined?.years[+formValues.termInYears.toFixed(0) - 1]
                if (!!maturityBalance) {
                    form.change('maturityBalance', maturityBalance)
                }
            }

        } else {
            if (!!formValues.termInYears && !!refinanceAssumptions?.balanceNewLoan?.years) {
                const maturityBalance = refinanceAssumptions.balanceNewLoan.years[+formValues.termInYears.toFixed(0) - 1]
                if (!!maturityBalance) {
                    form.change('maturityBalance', maturityBalance)
                }
            }
        }
        
    }, [refinanceAssumptions?.balanceNewLoan?.years, formValues.termInYears, selectedPricingOption, refinanceAssumptions?.balanceCombined?.years])

    useEffect(() => {
        if (!!formValues.termInYears && refinanceAssumptions?.ncf?.years) {
            const maturityNcf = refinanceAssumptions.ncf.years[+formValues.termInYears.toFixed(0)]
            if (!!maturityNcf) {
                form.change('maturityNcf', maturityNcf)
            }
        }
    }, [refinanceAssumptions?.ncf?.years, formValues.termInYears, selectedPricingOption])

    useEffect(() => {
        if (!!selectedPricingOption?.scenario?.actual?.financeOptions?.io) {
            form.change('yearsOfIo', selectedPricingOption.scenario.actual.financeOptions.io)
        }
    }, [selectedPricingOption])

    useEffect(() => {
        if (!!selectedPricingOption?.scenario?.actual?.financeOptions?.loanTerm) {
            form.change('termInYears', selectedPricingOption.scenario.actual.financeOptions.loanTerm)
        }
    }, [selectedPricingOption])

    useEffect(() => {
        if (form) form.submit()
    }, [formValues])

    return (
        <>
            <div className={'tableWrapper'}>
                <div className={'title'}>Loan Details</div>
                <div className={'mainPart'}>
                    <table>
                        <thead>
                            <tr>
                                <td />
                                <td />
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'rowLabel'}>Years of Interest Only</td>
                                <td className={'rowValue'}>{formValues.yearsOfIo}</td>
                            </tr>
                            <tr>
                                <td className={'rowLabel'}>Term in Years</td>
                                <td className={'rowValue'}>{formValues.termInYears}</td>
                            </tr>
                            <tr>
                                <td className={'rowLabel'}>Maturity Balance</td>
                                <td className={'rowValue'}>
                                    {!!formValues.maturityBalance && numeral(formValues.maturityBalance).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className={'rowLabel'}>Maturity NCF</td>
                                <td className={'rowValue'}>
                                    {!!formValues.maturityNcf && numeral(formValues.maturityNcf).format('$0,0')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .tableWrapper {
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    flex: 1;
                    box-sizing: content-box;
                    margin-bottom: 30px;
                    background: white;
                }
                .rowLabel {
                    width: 70%;
                }
                .rowValue {
                    width: 30%;
                }
                .title {
                    font-family: 'Inter';
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #d9e0e5;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                    width: 100%;
                }
                thead {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    color: #676f7a;
                }
                td {
                    text-align: left;
                }
                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;

                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                }
                :global(.refiAssumptionField) {
                    text-align: left !important;
                    position: relative;
                    width: initial !important;
                }
            `}</style>
        </>
    )
}
