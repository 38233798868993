import React from 'react'

import { CashFlowEdit } from './CashFlowEdit'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { regenerateCashFlow } from 'origination-model'

type Props = any
export const RetailCashFlow = React.memo(function RetailCashFlow(props: Props) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    const cashfloe = regenerateCashFlow({ model: model, cashFlow: model.cashFlow, unitMix: unitMix })
    const viewRef = React.createRef<any>()
    //console.log('cashfloe', cashfloe)
    return (
        <>
            <React.Fragment>
                <CashFlowEdit key="view" data={cashfloe} ref={viewRef} />

                {/*language=SCSS*/}
                <style jsx>
                    {`
                        @import './src/scss/colors.scss';
                        .notes-button-wrapper {
                            display: inline-flex;
                            align-items: center;
                            height: 36px;
                            padding: 0 18.5px;
                            border-radius: 3px;
                            margin-right: 8px;
                            span {
                                cursor: pointer;
                            }
                            .edit-notes-button {
                                width: 10px;
                                height: auto;
                                border-top: 0.3em solid;
                                border-right: 0.3em solid transparent;
                                border-bottom: 0;
                                border-left: 0.3em solid transparent;
                            }
                        }

                        .buttons-bar {
                            position: absolute;
                            right: 0;
                            top: -60px;
                            display: flex;
                            align-items: center;
                        }
                        @media (max-width: 1025px) {
                            .buttons-bar {
                                position: relative;
                                right: 0;
                                top: 0;
                                margin-bottom: 10px;
                            }
                        }
                    `}
                </style>
            </React.Fragment>
        </>
    )
})
