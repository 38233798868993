import React from 'react'

type Props = {
    onClick: () => void
    className?: string
}

export function DeleteColumn(props: Props) {
    return (
        <div className={'delete-column ' + props.className} onClick={props.onClick}>
            <img className={'normal'} src={'/_img/lenderDashboard/trash.svg'} />
            <img className={'hover'} src={'/_img/lenderDashboard/trash-red.svg'} />
            {/*language=SCSS*/}
            <style jsx>{`
                .delete-column {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    .normal {
                        display: block;
                    }
                    .hover {
                        display: none;
                    }
                }
                .delete-column:hover {
                    .normal {
                        display: none;
                    }
                    .hover {
                        display: block;
                    }
                }
            `}</style>
        </div>
    )
}
