import { GeneralInputs, Pricing, PricingOption, ProjectGraphQl } from '@generated/graphql'
import numeral from 'numeral'
import { nmGetMaximumBuiltUpCost, nmGetTotalCostBasis } from '../../northMarkFormulas'

const EXPORT_TO_FILE = true
export function downloadCSVImp(
    pricing: Pricing,
    project: ProjectGraphQl,
    generalInputs: GeneralInputs,
    cashFlow: any,
    model: any,
) {
    const isSupplamental = project?.type == 'miltifamily.value_add_supplemental'
    const rows = [
        [
            'Loan_Amount',
            'Interest_Type',
            'Variable_Product_Type',
            'Lien_Position',
            'Supplemental_Type',
            'Exercising_Tier_Dropping_Option',
            'Loan_Term_months',
            'Interest_Only_Period_months',
            'Amortization_Term_months',
            'Loss_Sharing_Percent',
            'Prepayment_Component_1',
            'Prepayment_Component_to_Months_1',
            'Prepayment_Component_2',
            'Prepayment_Component_to_Months_2',
            'Declining_Premium_Schedule',
            'Other_Prepayment_Premium_Schedule',
            'Property_Gross_Potential_Rent',
            'Property_Economic_Vacancy_Amount',
            'Property_Other_Income',
            'Property_Management_Fee',
            'Property_Real_Estate_Taxes',
            'Property_Insurance',
            'Property_Other_Expenses',
            'Property_Replacement_Reserves',
            'Property_Actual_Cooperative_NCF',
            'Property_Purchase_Price',
            'Property_Cost',
            'Property_Underwritten_Value',
            'Property_Unit_Count',
            'X1st_Lien_Interest_Type',
            'X1st_Lien_Original_Loan_Amount',
            'X1st_Lien_Actual_Fixed_Fixed_Equivalent_Interest_Rate',
            'X1st_Lien_Loan_Term_months',
            'X1st_Lien_Interest_Only_Period_months',
            'X1st_Lien_Amortization_Term_months',
            'X1st_Lien_Variable_UW_Interest_Rate',
            'X1st_Lien_Purchased_Cap_Strike_Rate',
            'X1st_Lien_Interest_Basis',
            'X1st_Lien_Loan_Maturity_Date',
            'X1st_Lien_Current_UPB_Amount',
            'X1st_Lien_Required_Min_DSCR_for_Loan_Sizing',
            'X2nd_Lien_Interest_Type',
            'X2nd_Lien_Original_Loan_Amount',
            'X2nd_Lien_Actual_Fixed_Fixed_Equivalent_Interest_Rate',
            'X2nd_Lien_Loan_Term_months',
            'X2nd_Lien_Interest_Only_Period_months',
            'X2nd_Lien_Amortization_Term_months',
            'X2nd_Lien_Variable_UW_Interest_Rate',
            'X2nd_Lien_Purchased_Cap_Strike_Rate',
            'X2nd_Lien_Interest_Basis',
            'X2nd_Lien_Loan_Maturity_Date',
            'X2nd_Lien_Current_UPB_Amount',
            'X2nd_Lien_Required_Min_DSCR_for_Loan_Sizing',
            'Pricing_Method',
            'Green_Financing_Type',
            'Latest_Pricing_Memo_Guaranty_Fee',
            'Latest_Pricing_Memo_Servicing_Fee',
            'Commitment_Period_days',
            'Rate_Lock_Type',
            'Interest_Basis',
            'Loan_Purchase_Price_of_par',
            'Excess_OriginationPremium_Amount',
            'Estimated_Origination_Fee',
            'Origination_Fee_Paid_to_Broker',
            'Origination_Fee_Paid_to_Correspondent',
            'Fixed_Index_Rate_Type',
            'Treasury_Rate',
            'Total_Fixed_Rate_Investor_Spread',
            'Requested_Fixed_Rate_Guaranty_Fee',
            'Requested_Fixed_Rate_Servicing_Fee',
            'Other_Fixed_RateFeeSpread',
            'Index_Rate_Type',
            'Index_Rate',
            'Total_Variable_Rate_Investor_Spread',
            'Requested_Variable_Rate_Guaranty_Fee',
            'Requested_Variable_Rate_Servicing_Fee',
            'Cap_Cost_Factor',
            'Underwritten_Stress_Max_Lifetime_Pass_Thru_Rate',
            'SARM_Initial_Cap_Term_months',
            'SARM_Min_Cap_Strike_Rate',
            'UW_Interest_Rate_Floor_per_Guide',
            'Guide_Tier_Level',
            'Guide_Min_Actual_Cooperative_DSCR',
            'Guide_Min_UW_DSCR_per_Guide',
            'Guide_Maximum_LTV',
            'Guide_FRT_Min_Actual_Cooperative_DSCR',
            'Guide_FRT_Min_UW_DSCR_per_Guide',
            'Guide_FRT_LTV',
            'Requested_Tier_Level',
            'Requested_Min_Actual_Cooperative_DSCR',
            'Requested_Min_UW_DSCR_per_Guide',
            'Requested_Min_Amortizing_UW_NCF_DSCR',
            'Requested_Maximum_LTV',
            'Requested_Maximum_LTC',
            'Requested_FRT_Min_Actual_NCF_COOP_DSCR',
            'Requested_FRT_Min_UW_DSCR_per_Guide',
            'Requested_FRT_Min_Amortizing_UW_NCF_DSCR',
            'Requested_FRT_LTV',
            'Expected_Exit_Refinance_Costs',
            'Expected_Exit_Amortization_Term',
            'Expected_Exit_Min_UW_DSCR_per_Guide',
            'Expected_Exit_Maximum_LTV',
            'Expected_Exit_Refinance_Interest_Rate',
            'Expected_Exit_Terminal_Cap_Rate',
            'Mezzanine_and_Preferred_Equity_Debt_Service',
            'Subordinate_Financing_1',
            'Subordinate_Financing_2',
            'Subordinate_Financing_3',
            'MezzPreferred_Equity_1',
            'MezzPreferred_Equity_2',
            'LIHTC_Equity',
            'Other_Sources',
            'Payoff_Existing_Mortgage_Loan',
            'Prepayment_Premium',
            'Property_Development_Construction_Cost',
            'Affiliated_Mezzanine_Debt_or_Preferred_Equity_Payoff',
            'NonAffiliated_Mezzanine_Debt_or_Preferred_Equity_Payoff',
            'Property_Funded_Capital_Improvements',
            'Property_Funded_Capital_Expenditures_Replacement_Reserve_Account',
            'Property_Funded_Completion_Repairs',
            'Property_Funded_Other_Escrows',
            'Interest_Rate_CapHedging_Fees',
            'Origination_Fees',
            'Lender_ClosingTransaction_Fees',
            'Third_Party_ClosingTransaction_Fees',
            'Other_Uses',
        ],
    ]

    if (pricing?.fanniePricing?.pricingOptions.length > 0) {
        const optionsFixed = pricing.fanniePricing.pricingOptions.filter((pricingOption) => {
            return pricingOption.isSelected && pricingOption.scenario.underwritten.financeOptions.intrestType == 'Fixed'
        })

        const optionsAdjustable = pricing.fanniePricing.pricingOptions.filter((pricingOption) => {
            return pricingOption.isSelected && pricingOption.scenario.underwritten.financeOptions.intrestType != 'Fixed'
        })
        for (const options of [optionsFixed || [], optionsAdjustable || []])
            for (const option of options) {
                let fixedRelatedToArm: PricingOption = null

                const fixedOptionReferenceId = option.fixedOptionReferenceId
                if (fixedOptionReferenceId) {
                    for (const p of pricing.fanniePricing.pricingOptions) {
                        if (p.id == fixedOptionReferenceId) {
                            fixedRelatedToArm = p
                            break
                        }
                    }
                }

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Loan_Amount = option.sizer.actual.maxFirstPosLoan
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Interest_Type =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed' ? 'Fixed Rate' : 'Variable Rate'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Variable_Product_Type =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? ''
                        : generalInputs.loanDetails.ARMType == 'Structured'
                        ? 'SARM'
                        : 'ARM 7/6'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Lien_Position = generalInputs.loanDetails.lienPosition
                    ? generalInputs.loanDetails.lienPosition
                    : isSupplamental
                    ? 2
                    : 1
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Supplemental_Type = isSupplamental ? option.creditFees.loanTerms.supplementalType : 'No'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Exercising_Tier_Dropping_Option = option.creditFees.loanTerms.exercisingTierDroppingOption
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Loan_Term_months = option.scenario.actual.financeOptions.loanTerm * 12
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Interest_Only_Period_months = option.scenario.actual.financeOptions.io * 12
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Amortization_Term_months = option.scenario.actual.financeOptions.amortization * 12
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Loss_Sharing_Percent = (function () {
                    if (generalInputs.loanDetails.requestedLoanAmount <= 7500000) return '100%'
                    if (generalInputs.loanDetails.requestedLoanAmount <= 10000000) return '75%'
                    if (generalInputs.loanDetails.requestedLoanAmount <= 15000000) return '50%'
                    else return '25%'
                })()
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Prepayment_Component_1 =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 'Yield Maintenance'
                        : 'Prepayment Lockou'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Prepayment_Component_to_Months_1 =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? option.scenario.actual.financeOptions.prepayment * 12
                        : 12
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Prepayment_Component_2 = '1%  Fixed Prepayment'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Prepayment_Component_to_Months_2 = Interest_Only_Period_months - 3
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Declining_Premium_Schedule = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Other_Prepayment_Premium_Schedule = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Gross_Potential_Rent = cashFlow.rentalIncome.find(
                    (r) => r.id == 'grossPotentialRent',
                ).adjustedT12
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Economic_Vacancy_Amount = (function () {
                    let economicVacancy = 0

                    const ecline = cashFlow.rentalLoss.find((r) => r.id == 'economic-vacancy')

                    const lines = ecline ? ecline.subRows : cashFlow.rentalLoss
                    for (const key of ['vacancy', 'badDebt', 'concession', 'prepaids-adjustments']) {
                        const line = lines.find((r) => r.id == key)
                        if (line) {
                            economicVacancy += line.adjustedT12
                        }
                    }
                    // console.log('Property_Economic_Vacancy_Amount', economicVacancy)
                    return -1 * Math.abs(economicVacancy)
                })()
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Other_Income = (function () {
                    let otherIncome = 0

                    const lines = cashFlow.otherIncome
                    const keys = [
                        'laundry-vendin',
                        'parking',
                        'rubs',
                        'commercial',
                        'otherIncomeMain',
                        'short-term-premiums',
                    ]
                    for (const line of lines) {
                        if (keys.includes(line.id) || line.id.startsWith('otherIncome-')) {
                            otherIncome += line.adjustedT12
                        }
                    }

                    //console.log('Property_Other_Income', otherIncome)
                    return Math.abs(otherIncome)
                })()
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Management_Fee = Math.abs(
                    cashFlow.operatingExpenses?.find((o) => o.id == 'assetManagementFee')?.adjustedT12 || 0,
                )
                // console.log('Property_Management_Fee', Property_Management_Fee)
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Real_Estate_Taxes = Math.abs(
                    cashFlow.operatingExpenses?.find((o) => o.id == 'taxes')?.adjustedT12 || 0,
                )
                //console.log('Property_Real_Estate_Taxes', Property_Real_Estate_Taxes)
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Insurance = Math.abs(
                    cashFlow.operatingExpenses?.find((o) => o.id == 'insurance')?.adjustedT12 || 0,
                )
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Other_Expenses = (function () {
                    let operatingExpenses = 0

                    const lines = cashFlow.operatingExpenses
                    const keys = ['assetManagementFee', 'taxes', 'insurance', 'replacementReserves']
                    for (const line of lines) {
                        if (keys.includes(line.id)) continue
                        //   console.log('add', line.id, line.adjustedT12)
                        operatingExpenses += line.adjustedT12 || 0
                    }

                    //console.log('Property_Other_Expenses', operatingExpenses)
                    return Math.abs(operatingExpenses)
                })()

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Replacement_Reserves = (function () {
                    let replacementReserves = 0

                    const keys = ['operatingExpenses', 'other']
                    for (const key of keys) {
                        const line = cashFlow[key]?.find((o) => o.id == 'replacementReserves')
                        replacementReserves += line?.adjustedT12 || 0
                    }

                    // console.log('Property_Replacement_Reserves', replacementReserves)
                    return Math.abs(replacementReserves)
                })()

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Actual_Cooperative_NCF = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Purchase_Price =
                    generalInputs?.loanDetails?.acquisitionOrRefinance?.toLowerCase() == 'acquisition'
                        ? generalInputs.dealDetails.purchasePrice
                        : ''
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Cost =
                    generalInputs?.loanDetails?.acquisitionOrRefinance?.toLowerCase() == 'acquisition'
                        ? nmGetMaximumBuiltUpCost(
                              nmGetTotalCostBasis(
                                  model?.uses?.purchasePrice,
                                  generalInputs?.dealDetails?.escrowedImprovements,
                                  model?.fanniePricing?.purchaseAndRefinanceAssumptions?.closingCostsMultiplier,
                              ),
                          )
                        : ''
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Underwritten_Value = (function () {
                    let capRate = 0
                    if (model?.fanniePricing?.valuationComparison?.length > 0) {
                        for (const v of model?.fanniePricing?.valuationComparison) {
                            if (v.selected) {
                                capRate = v.capRate
                                break
                            }
                        }
                    }
                    if (!capRate) capRate = 0.0525
                    return cashFlow.noi.adjustedT12 / capRate
                })()
                //  console.log('Property_Underwritten_Value', Property_Underwritten_Value)
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Unit_Count = model.numberOfUnits
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Interest_Type = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0].terms?.interestType || 'Fixed Rate'
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Original_Loan_Amount = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.priorLien?.upbOriginalLoanAmount || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Actual_Fixed_Fixed_Equivalent_Interest_Rate = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.priorLien?.intrestRate.toFixed(4) || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Loan_Term_months = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.terms?.termMonths || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Interest_Only_Period_months = isSupplamental
                    ? (model?.fanniePricing?.supplemental?.[0]?.priorLien?.ioYears || 0) * 12
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Amortization_Term_months = isSupplamental
                    ? (model?.fanniePricing?.supplemental?.[0]?.priorLien?.amortizationYears || 0) * 12
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Variable_UW_Interest_Rate = isSupplamental ? 0 : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Purchased_Cap_Strike_Rate = isSupplamental ? 0 : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Interest_Basis = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.priorLien?.intrestBasis
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Loan_Maturity_Date = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.priorLien?.maturityDate
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Current_UPB_Amount = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.priorLien?.estimatedUpbAtSupClosing || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X1st_Lien_Required_Min_DSCR_for_Loan_Sizing = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[0]?.priorLien?.minDSCR
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Interest_Type = isSupplamental ? 'Fixed Rate' : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Original_Loan_Amount = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.priorLien?.upbOriginalLoanAmount || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Actual_Fixed_Fixed_Equivalent_Interest_Rate = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.priorLien?.intrestRate.toFixed(4) || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Loan_Term_months = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.terms?.termMonths || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Interest_Only_Period_months = isSupplamental
                    ? (model?.fanniePricing?.supplemental?.[1]?.priorLien?.ioYears || 0) * 12
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Amortization_Term_months = isSupplamental
                    ? (model?.fanniePricing?.supplemental?.[1]?.priorLien?.amortizationYears || 0) * 12
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Variable_UW_Interest_Rate = isSupplamental ? 0 : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Purchased_Cap_Strike_Rate = isSupplamental ? 0 : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Interest_Basis = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.priorLien?.intrestBasis
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Loan_Maturity_Date = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.priorLien?.maturityDate || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Current_UPB_Amount = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.priorLien?.estimatedUpbAtSupClosing || 0
                    : 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const X2nd_Lien_Required_Min_DSCR_for_Loan_Sizing = isSupplamental
                    ? model?.fanniePricing?.supplemental?.[1]?.priorLien?.minDSCR || 0
                    : 0

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Pricing_Method =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? option.scenario.actual.loanPricing.pricingMethod
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.loanPricing.pricingMethod
                        : option.scenario.underwritten.loanPricing.pricingMethod

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Green_Financing_Type = option.creditFees.pricingFees.greenFinancingType

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Latest_Pricing_Memo_Guaranty_Fee =
                    (option.creditFees.pricingFees.fannieMaeGuarantyFee || 0) / 10000
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Latest_Pricing_Memo_Servicing_Fee = (option.creditFees.pricingFees.lenderServiceFee || 0) / 10000

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Commitment_Period_days = (function () {
                    const d = generalInputs?.loanDetails?.targetLoanClosing
                        ? new Date(generalInputs?.loanDetails?.targetLoanClosing)
                        : null
                    if (d) {
                        d.setHours(0)
                        d.setMinutes(0)
                        d.setSeconds(0)
                        d.setMilliseconds(0)

                        const today = new Date()
                        today.setHours(0)
                        today.setMinutes(0)
                        today.setSeconds(0)
                        today.setMilliseconds(0)
                        const difference = d.getTime() - today.getTime()
                        return Math.ceil(difference / (1000 * 60 * 60 * 24))
                    }
                    return 60
                })()
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Rate_Lock_Type =
                    option.creditFees.pricingFees.rateLockType != 'None'
                        ? option.creditFees.pricingFees.rateLockType
                        : 'Standard'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Interest_Basis = option.scenario.actual.financeOptions.intrestBasis
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Loan_Purchase_Price_of_par = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Excess_OriginationPremium_Amount = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Estimated_Origination_Fee = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Origination_Fee_Paid_to_Broker = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Origination_Fee_Paid_to_Correspondent = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Fixed_Index_Rate_Type = (function () {
                    const tmp =
                        option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                            ? option.scenario.actual.loanPricing.refIndex
                            : fixedRelatedToArm
                            ? fixedRelatedToArm.scenario.underwritten.loanPricing.refIndex
                            : option.scenario.underwritten.loanPricing.refIndex

                    return tmp.replace(/year/i, '').replace(/days/i, '') + 'Years Treasury Note'
                })()
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Treasury_Rate =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? option.scenario.actual.loanPricing.indexRate
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.loanPricing.indexRate
                        : option.scenario.underwritten.loanPricing.indexRate
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Total_Fixed_Rate_Investor_Spread =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? option.scenario.actual.loanPricing.investorSpread
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.loanPricing.investorSpread
                        : option.scenario.underwritten.loanPricing.investorSpread
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Fixed_Rate_Guaranty_Fee =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? option.scenario.actual.loanPricing.fannieMaeGuarantyFee
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.loanPricing.fannieMaeGuarantyFee
                        : option.scenario.underwritten.loanPricing.fannieMaeGuarantyFee
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Fixed_Rate_Servicing_Fee =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? option.scenario.actual.loanPricing.lenderServiceFee
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.loanPricing.lenderServiceFee
                        : option.scenario.underwritten.loanPricing.lenderServiceFee
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Other_Fixed_RateFeeSpread = '0%'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Index_Rate_Type =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed' ? '0' : '30 Day Average SOFR'
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Index_Rate =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.actual.loanPricing.indexRate
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Total_Variable_Rate_Investor_Spread =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.actual.loanPricing.investorSpread
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Variable_Rate_Guaranty_Fee =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.actual.loanPricing.fannieMaeGuarantyFee
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Variable_Rate_Servicing_Fee =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.actual.loanPricing.lenderServiceFee
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Cap_Cost_Factor =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.actual.loanPricing.capCostFactor
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Underwritten_Stress_Max_Lifetime_Pass_Thru_Rate =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.underwritten.loanPricing.strikeRate
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const SARM_Initial_Cap_Term_months =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : (option.capCost?.capCostTerm || 0) * 12
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const SARM_Min_Cap_Strike_Rate =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : option.scenario.actual.loanPricing.strikeRate
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const UW_Interest_Rate_Floor_per_Guide = option.scenario.underwritten.loanPricing.uwFloor

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_Tier_Level = 'Tier ' + option.scenario.actual.financeOptions.tier
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_Min_Actual_Cooperative_DSCR = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_Min_UW_DSCR_per_Guide = option.scenario.underwritten.financeOptions.minDSCR
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_Maximum_LTV = option.scenario.underwritten.financeOptions.maxLTV
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_FRT_Min_Actual_Cooperative_DSCR = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_FRT_Min_UW_DSCR_per_Guide =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.financeOptions.minDSCR
                        : option.scenario.underwritten.financeOptions.minDSCR
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Guide_FRT_LTV =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.underwritten.financeOptions.maxLTV
                        : option.scenario.underwritten.financeOptions.maxLTV
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Tier_Level = 'Tier ' + option.scenario.actual.financeOptions.tier
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Min_Actual_Cooperative_DSCR = 0

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Min_UW_DSCR_per_Guide = (function () {
                    let val = 0
                    try {
                        const minDcrOption = option.scenario.actual.financeOptions.minDSCR
                        const dscRequestedLoanAmount = option.sizer.underwritten.dscRequestedLoanAmount
                        const fl = Math.floor(dscRequestedLoanAmount * 100 + Number.EPSILON) / 100
                        if (fl < minDcrOption) {
                            val = Math.floor(dscRequestedLoanAmount / 0.05) * 0.05
                        } else {
                            val = minDcrOption
                        }
                    } catch (e) {
                        val = 0
                    }

                    return val
                })()
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Min_Amortizing_UW_NCF_DSCR = option.scenario.actual.financeOptions.minDSCR
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Maximum_LTV = option.scenario.actual.financeOptions.maxLTV
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_Maximum_LTC = Requested_Maximum_LTV
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_FRT_Min_Actual_NCF_COOP_DSCR = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_FRT_Min_UW_DSCR_per_Guide = 0 //todo
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_FRT_Min_Amortizing_UW_NCF_DSCR =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.actual.financeOptions.minDSCR
                        : option.scenario.actual.financeOptions.minDSCR
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Requested_FRT_LTV =
                    option.scenario.underwritten.financeOptions.intrestType == 'Fixed'
                        ? 0
                        : fixedRelatedToArm
                        ? fixedRelatedToArm.scenario.actual.financeOptions.maxLTV
                        : option.scenario.actual.financeOptions.maxLTV
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Expected_Exit_Refinance_Costs = 0

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Expected_Exit_Amortization_Term = 360 // hard codded based on Nancy requierments
                //    (model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[0]?.assumptions?.amortization || 0) * 12

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Expected_Exit_Min_UW_DSCR_per_Guide =
                    model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[0]?.assumptions?.dscr || 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Expected_Exit_Maximum_LTV =
                    model?.fannieExitScenarioRefinance?.refinanceAssumptions?.[0]?.assumptions?.ltv || 0

                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Expected_Exit_Refinance_Interest_Rate = option.exitAnalysis?.alternative?.refiRate || 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Expected_Exit_Terminal_Cap_Rate = option.exitAnalysis?.alternative?.capRate || 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Mezzanine_and_Preferred_Equity_Debt_Service = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Subordinate_Financing_1 = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Subordinate_Financing_2 = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Subordinate_Financing_3 = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const MezzPreferred_Equity_1 = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const MezzPreferred_Equity_2 = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const LIHTC_Equity = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Other_Sources = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Payoff_Existing_Mortgage_Loan = generalInputs?.dealDetails?.currentUPB || 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Prepayment_Premium = generalInputs?.dealDetails?.prepay || 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Development_Construction_Cost = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Affiliated_Mezzanine_Debt_or_Preferred_Equity_Payoff = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const NonAffiliated_Mezzanine_Debt_or_Preferred_Equity_Payoff = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Funded_Capital_Improvements = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Funded_Capital_Expenditures_Replacement_Reserve_Account = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Funded_Completion_Repairs = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Property_Funded_Other_Escrows = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Interest_Rate_CapHedging_Fees = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Origination_Fees = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Lender_ClosingTransaction_Fees = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Third_Party_ClosingTransaction_Fees = 0
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const Other_Uses = 0

                const row = []
                row.push(
                    Loan_Amount,
                    Interest_Type,
                    Variable_Product_Type,
                    Lien_Position,
                    Supplemental_Type,
                    Exercising_Tier_Dropping_Option,
                    Loan_Term_months,
                    Interest_Only_Period_months,
                    Amortization_Term_months,
                    Loss_Sharing_Percent,
                    Prepayment_Component_1,
                    Prepayment_Component_to_Months_1,
                    Prepayment_Component_2,
                    Prepayment_Component_to_Months_2,
                    Declining_Premium_Schedule,
                    Other_Prepayment_Premium_Schedule,
                    Property_Gross_Potential_Rent,
                    Property_Economic_Vacancy_Amount,
                    Property_Other_Income,
                    Property_Management_Fee,
                    Property_Real_Estate_Taxes,
                    Property_Insurance,
                    Property_Other_Expenses,
                    Property_Replacement_Reserves,
                    Property_Actual_Cooperative_NCF,
                    Property_Purchase_Price,
                    Property_Cost,
                    Property_Underwritten_Value,
                    Property_Unit_Count,
                    X1st_Lien_Interest_Type,
                    X1st_Lien_Original_Loan_Amount,
                    numeral(X1st_Lien_Actual_Fixed_Fixed_Equivalent_Interest_Rate).format('0.[00]%'),
                    X1st_Lien_Loan_Term_months,
                    X1st_Lien_Interest_Only_Period_months,
                    X1st_Lien_Amortization_Term_months,
                    numeral(X1st_Lien_Variable_UW_Interest_Rate).format('0.[00]%'),
                    numeral(X1st_Lien_Purchased_Cap_Strike_Rate).format('0.[00]%'),
                    X1st_Lien_Interest_Basis,
                    X1st_Lien_Loan_Maturity_Date,
                    X1st_Lien_Current_UPB_Amount,
                    X1st_Lien_Required_Min_DSCR_for_Loan_Sizing,
                    X2nd_Lien_Interest_Type,
                    X2nd_Lien_Original_Loan_Amount,
                    numeral(X2nd_Lien_Actual_Fixed_Fixed_Equivalent_Interest_Rate).format('0.[00]%'),
                    X2nd_Lien_Loan_Term_months,
                    X2nd_Lien_Interest_Only_Period_months,
                    X2nd_Lien_Amortization_Term_months,
                    numeral(X2nd_Lien_Variable_UW_Interest_Rate).format('0.[00]%'),
                    numeral(X2nd_Lien_Purchased_Cap_Strike_Rate).format('0.[00]%'),
                    X2nd_Lien_Interest_Basis,
                    X2nd_Lien_Loan_Maturity_Date,
                    X2nd_Lien_Current_UPB_Amount,
                    X2nd_Lien_Required_Min_DSCR_for_Loan_Sizing,
                    Pricing_Method,
                    Green_Financing_Type,
                    numeral(Latest_Pricing_Memo_Guaranty_Fee).format('0.[00]%'),
                    numeral(Latest_Pricing_Memo_Servicing_Fee).format('0.[00]%'),
                    Commitment_Period_days,
                    Rate_Lock_Type,
                    Interest_Basis,
                    Loan_Purchase_Price_of_par,
                    Excess_OriginationPremium_Amount,
                    Estimated_Origination_Fee,
                    numeral(Origination_Fee_Paid_to_Broker).format('0.[00]%'),
                    numeral(Origination_Fee_Paid_to_Correspondent).format('0.[00]%'),
                    Fixed_Index_Rate_Type,
                    numeral(Treasury_Rate).format('0.[00]%'),
                    numeral(Total_Fixed_Rate_Investor_Spread).format('0.[00]%'),
                    numeral(Requested_Fixed_Rate_Guaranty_Fee).format('0.[00]%'),
                    numeral(Requested_Fixed_Rate_Servicing_Fee).format('0.[00]%'),
                    numeral(Other_Fixed_RateFeeSpread).format('0.[00]%'),
                    Index_Rate_Type,
                    numeral(Index_Rate).format('0.[00]%'),
                    numeral(Total_Variable_Rate_Investor_Spread).format('0.[00]%'),
                    numeral(Requested_Variable_Rate_Guaranty_Fee).format('0.[00]%'),
                    numeral(Requested_Variable_Rate_Servicing_Fee).format('0.[00]%'),
                    Cap_Cost_Factor,
                    numeral(Underwritten_Stress_Max_Lifetime_Pass_Thru_Rate).format('0.[00]%'),
                    SARM_Initial_Cap_Term_months,
                    numeral(SARM_Min_Cap_Strike_Rate).format('0.[00]%'),
                    numeral(UW_Interest_Rate_Floor_per_Guide).format('0.[00]%'),
                    Guide_Tier_Level,
                    Guide_Min_Actual_Cooperative_DSCR,
                    Guide_Min_UW_DSCR_per_Guide,
                    numeral(Guide_Maximum_LTV).format('0.[00]%'),
                    Guide_FRT_Min_Actual_Cooperative_DSCR,
                    Guide_FRT_Min_UW_DSCR_per_Guide,
                    numeral(Guide_FRT_LTV).format('0.[00]%'),
                    Requested_Tier_Level,
                    Requested_Min_Actual_Cooperative_DSCR,
                    Requested_Min_UW_DSCR_per_Guide,
                    Requested_Min_Amortizing_UW_NCF_DSCR,
                    numeral(Requested_Maximum_LTV).format('0.[00]%'),
                    numeral(Requested_Maximum_LTC).format('0.[00]%'),
                    Requested_FRT_Min_Actual_NCF_COOP_DSCR,
                    Requested_FRT_Min_UW_DSCR_per_Guide,
                    Requested_FRT_Min_Amortizing_UW_NCF_DSCR,
                    numeral(Requested_FRT_LTV).format('0.[00]%'),
                    Expected_Exit_Refinance_Costs,
                    Expected_Exit_Amortization_Term,
                    Expected_Exit_Min_UW_DSCR_per_Guide,
                    numeral(Expected_Exit_Maximum_LTV).format('0.[00]%'),
                    numeral(Expected_Exit_Refinance_Interest_Rate).format('0.[00]%'),
                    numeral(Expected_Exit_Terminal_Cap_Rate).format('0.[00]%'),
                    Mezzanine_and_Preferred_Equity_Debt_Service,
                    Subordinate_Financing_1,
                    Subordinate_Financing_2,
                    Subordinate_Financing_3,
                    MezzPreferred_Equity_1,
                    MezzPreferred_Equity_2,
                    LIHTC_Equity,
                    Other_Sources,
                    Payoff_Existing_Mortgage_Loan,
                    Prepayment_Premium,
                    Property_Development_Construction_Cost,
                    Affiliated_Mezzanine_Debt_or_Preferred_Equity_Payoff,
                    NonAffiliated_Mezzanine_Debt_or_Preferred_Equity_Payoff,
                    Property_Funded_Capital_Improvements,
                    Property_Funded_Capital_Expenditures_Replacement_Reserve_Account,
                    Property_Funded_Completion_Repairs,
                    Property_Funded_Other_Escrows,
                    Interest_Rate_CapHedging_Fees,
                    Origination_Fees,
                    Lender_ClosingTransaction_Fees,
                    Third_Party_ClosingTransaction_Fees,
                    Other_Uses,
                )
                rows.push(row)
            }
    }
    //console.log("rows",rows)
    if (EXPORT_TO_FILE) exportToCsv('export_fannie_' + project.id + '.csv', rows)
}

function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = ''
        for (var j = 0; j < row.length; j++) {
            //console.log('row j', j, row[j])
            var innerValue = row[j] === null || row[j] === undefined ? '' : row[j].toString()
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString()
            }
            var result = innerValue.replace(/"/g, '""')
            if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
            if (j > 0) finalVal += ','
            finalVal += result
        }
        return finalVal + '\n'
    }

    var csvFile = ''
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i])
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename)
    } else {
        var link = document.createElement('a')
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}
