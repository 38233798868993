import React, { FunctionComponent } from 'react'
import { LenderDashboardChart } from '../../LenderDashboardChart'
import { MortgageSchedule } from './types'

type Props = {
    schedule: { senior: MortgageSchedule; mezzanine?: MortgageSchedule }
    startBalance: number
}

export const FinancingChart: FunctionComponent<Props> = (props: Props) => {
    const { schedule } = props
    let { startBalance } = props
    const series = [
        /* {
            name: 'Total Loan payment',
            data: [],
        },
        {
            name: 'Principal payment',
            data: [],
        },*/
        {
            name: 'Interest Payment Senior',
            data: [],
        },
        {
            name: 'Balance',
            data: [],
        },
    ]

    startBalance = 0
    if (schedule?.senior?.schedule[0]) startBalance += schedule?.senior?.schedule[0].startingBalance
    if (schedule?.mezzanine?.schedule[0]) startBalance += schedule?.mezzanine?.schedule[0].startingBalance

    for (const item of schedule?.senior?.schedule) {
        //series[0].data.push(item.total)
        // series[1].data.push(item.principal)
        series[0].data.push(item.interest)
    }

    if (schedule?.senior?.schedule) {
        // @ts-ignore
        for (const [i, item] of schedule?.senior?.schedule.entries()) {
            startBalance = item.startingBalance
            if (schedule?.mezzanine) {
                startBalance += schedule?.mezzanine?.schedule?.[i]?.startingBalance
            }

            startBalance -= item.principal
            if (schedule?.mezzanine) {
                startBalance -= schedule?.mezzanine?.schedule?.[i]?.principal
            }
            series[1].data.push(startBalance)
        }
    }
    if (schedule?.mezzanine) {
        series.splice(1, 0, {
            name: 'Interest Payment Mezzanine',
            data: [],
        })
        series[1].data = schedule?.mezzanine?.schedule?.map((item) => item?.interest)
    }

    return <LenderDashboardChart series={series} xaxisTitle={'Months'} yaxisTitle={'Amount'} />
}
