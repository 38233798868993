import React, { useEffect } from 'react'
import { useSyncScroll } from '../../../../../../../utils/useSyncScroll'

type Props = {
    titles: Array<any>
    visible: boolean
}

export const MonthlyCashFlowFixedHeader = (props: Props) => {
    const { titles, visible } = props

    useSyncScroll('header-scrollable-container', 'table-scrollable-container')

    useEffect(() => {
        const elem = document.getElementById('table-scrollable-container')
        //scroll inverted elem to beginning
        elem.scrollLeft = elem.scrollWidth
    }, [])

    if (!visible) {
        return null
    }
    return (
        <>
            <div className={'monthly-cash-flow-header-wrapper'}>
                <div className="fixed-container"></div>
                <div className="scrollable-container" id={'header-scrollable-container'}>
                    <div className="rotate-wrapper" style={{ width: titles?.length * 130 }}>
                        {titles?.map((item, ind) => (
                            <div className="scrollable-container-item" key={ind}>
                                {item?.title1}
                                <br />
                                {item?.title2}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .monthly-cash-flow-header-wrapper {
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    z-index: 100;
                    background-color: $white;
                    margin: 0 45px;
                    display: flex;
                    border: 1px solid $border;
                    .fixed-container {
                        width: 300px;
                        min-width: 300px;
                        max-width: 300px;
                    }
                    .scrollable-container {
                        overflow: auto;
                        width: 100%;
                        transform: rotate(180deg);
                        .rotate-wrapper {
                            display: flex;
                            transform: rotate(180deg);
                        }
                        .scrollable-container-item {
                            text-align: right;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            width: 130px;
                            min-width: 130px;
                            max-width: 130px;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: #676f7a;
                        }
                        /* width */
                        &::-webkit-scrollbar {
                            height: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            border-radius: 2px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $gray-blue;
                            border-radius: 2px;
                        }
                    }
                }
            `}</style>
        </>
    )
}
