import React, { FunctionComponent, useEffect, useState } from 'react'
import numeral from 'numeral'
import { CHART_COLOR } from '../../../../../../../utils/constants/chartColors'
import { Tooltip } from 'devextreme-react/tooltip'

type Props = {
    id?: string
    value?: number
    label?: string
    percentValue?: string
    percentWidth?: number
}

export const PercentBlock: FunctionComponent<Props> = (props: Props) => {
    const { id, label, percentValue, percentWidth, value } = props
    const [toggleTooltip, setToggleTooltip] = useState(false)

    return (
        <div
            className={'percent-block'}
            onMouseEnter={(e) => setToggleTooltip(!toggleTooltip)}
            onMouseLeave={(e) => setToggleTooltip(!toggleTooltip)}
        >
            <div id={`${id}`} className={'percent'} style={{ width: `${percentWidth}%` }} />
            <Tooltip
                target={`#${id}`}
                visible={toggleTooltip}
                closeOnOutsideClick={false}
                position="top"
                elementAttr={{ class: 'percent-tooltip-wrapper123' }}
            >
                <div className={'percent-tooltip'}>
                    <div>
                        <span className="tooltip-colored-circle"></span>
                        {label}
                    </div>
                    <div>{`${numeral(value).format('$0,0')} (${percentValue})`}</div>
                </div>
            </Tooltip>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .percent {
                    background: $accent;
                    border-radius: 2px;
                    opacity: 0.9;
                    height: 14px;
                    position: relative;
                    &:hover {
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }
                .percent-tooltip {
                    //background: #fff;
                    padding-left: 20px;
                    //border: 1px solid #d9e0e5;
                    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    //border-radius: 3px;
                    color: #262b35;
                    .tooltip-colored-circle {
                        background: $accent;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 5px;
                        margin-left: -20px;
                    }
                    > div:first-child {
                        font-size: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    > div:last-child {
                        font-weight: 500;
                        font-size: 15px;
                    }
                }
                :global(.dx-popover-arrow) {
                    display: none;
                }
            `}</style>
        </div>
    )
}
