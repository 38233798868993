import { useMemo } from 'react'
import numeral from 'numeral'
import { addMonths } from 'date-fns'
import { getRentRollGroupedByMonth } from 'origination-model'
import { KPIBox } from '../KPIBox'
import { getDateString } from '../utils/dates'

export function MonthlyMoveIns({ futureRentRollEarliestWeek, numberOfUnits, rentRoll, futureRentRoll }) {
    const rentRollGroupedByLeaseStartDate = useMemo(() => getRentRollGroupedByMonth(rentRoll, 'leaseStart'), [rentRoll])

    const futureRentRollByLeaseStartDate = useMemo(
        () => getRentRollGroupedByMonth(futureRentRoll, 'leaseStart'),
        [futureRentRoll],
    )

    const historicalAndFutureMoveIns = useMemo(() => {
        if (rentRollGroupedByLeaseStartDate && futureRentRollByLeaseStartDate) {
            const entries = Object.entries(rentRollGroupedByLeaseStartDate)
                .sort((a, b) => +a[0] - +b[0])
                .map((elem: any) => [elem[0], elem[1].length])

            const futureEntries = Object.entries(futureRentRollByLeaseStartDate)
                .sort((a, b) => +a[0] - +b[0])
                .map((elem: any) => [elem[0], elem[1].length])

            futureEntries.forEach((entry) => {
                if (rentRollGroupedByLeaseStartDate[entry[0]]) {
                    const elem = entries.find((item) => item[0] === entry[0])
                    elem[1] += entry[1]
                } else {
                    entries.push(entry)
                }
            })

            return entries
        } else {
            return []
        }
    }, [rentRollGroupedByLeaseStartDate, futureRentRollByLeaseStartDate])

    const months = useMemo(() => {
        if (historicalAndFutureMoveIns?.length) {
            const lastMonth = historicalAndFutureMoveIns[historicalAndFutureMoveIns.length - 1][0]
            let currentMonth = historicalAndFutureMoveIns[0][0]
            const result = [currentMonth]

            while (+currentMonth !== +lastMonth) {
                const newMonth = addMonths(new Date(+currentMonth), 1).getTime()
                result.push(newMonth)
                currentMonth = newMonth
            }
            return result
        } else {
            return []
        }
    }, [historicalAndFutureMoveIns])

    const cummulativeMoveIns = useMemo(() => {
        if (months?.length && historicalAndFutureMoveIns?.length) {
            let sum = 0
            return months.map((item: any) => {
                sum += historicalAndFutureMoveIns.find((elem) => +elem[0] === +item)?.[1] || 0
                return sum
            })
        } else {
            return []
        }
    }, [months, historicalAndFutureMoveIns])

    const occupancy = useMemo(() => {
        if (cummulativeMoveIns?.length && numberOfUnits) {
            return cummulativeMoveIns.map((item) => item / numberOfUnits)
        } else {
            return []
        }
    }, [cummulativeMoveIns, numberOfUnits])

    const avgMoveIns = useMemo(() => {
        let sum = 0
        if (months?.length && historicalAndFutureMoveIns) {
            months.forEach((item) => {
                sum += historicalAndFutureMoveIns.find((elem) => +elem[0] === +item)?.[1] || 0
            })
        }
        if (!sum || !months?.length) {
            return null
        } else {
            return Math.round(sum / months.length)
        }
    }, [months, historicalAndFutureMoveIns])

    return (
        <>
            <div style={{ paddingLeft: 20 }}>
                <KPIBox
                    className="avg-move-ins"
                    title="Historical Average Move-Ins"
                    value={avgMoveIns?.toString() || '-'}
                />
            </div>
            <div className="monthly-move-ins-wrapper">
                <div className="scrollable-wrapper">
                    <div className="scrollable-part">
                        <div
                            className="custom-table-wrapper container-fluid"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex',
                            }}
                        >
                            <div className="first-column">
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>WEEK OF MOVE-IN</div>
                                    </div>
                                </div>

                                {months.map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">
                                            {getDateString(+item)}{' '}
                                            {+futureRentRollEarliestWeek === +item && (
                                                <span style={{ paddingLeft: 20 }}>Future Move-Ins</span>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>NUMBER OF</div>
                                        <div>MOVE-INS</div>
                                    </div>
                                </div>

                                {months.map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">
                                            {historicalAndFutureMoveIns.find((elem) => +elem[0] === +item)?.[1] || 0}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>CUMMULATIVE</div>
                                        <div>MOVE-INS</div>
                                    </div>
                                </div>

                                {cummulativeMoveIns.map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>OCCUPANCY</div>
                                    </div>
                                </div>
                                {occupancy.map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{numeral(item).format('0.[0]%')}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/*language=scss*/}
                <style jsx global>{`
                    @import './src/scss/colors.scss';
                    .avg-move-ins {
                        width: 300px;
                        padding: 15px 20px;
                        border-radius: 4px;
                        background: #f5f6f9;

                        .title {
                            color: #97a2b4;
                            margin-bottom: 5px;
                        }

                        .value {
                            font-size: 22px;
                            font-weight: 500;
                        }
                    }
                    .monthly-move-ins-wrapper {
                        display: flex;
                        background-color: #fff;

                        .row {
                            margin: initial;
                        }

                        .custom-table-wrapper {
                            background-color: $row-background-1;
                            border: none;
                            text-align: right;

                            .first-column {
                                text-align: initial;
                            }

                            .custom-table-title {
                                text-transform: uppercase;
                                font-size: 12px;
                                color: #676f7a;
                                padding: 7px;
                                display: flex;
                                flex-wrap: nowrap;
                                .custom-cell {
                                    padding: 0;
                                    min-width: 170px;
                                    width: 170px;
                                    display: flex;
                                    align-items: center;
                                }
                                .year-cell {
                                    display: block;
                                }
                            }
                            .custom-table-row {
                                font-size: 14px;
                                padding: 7px;
                                align-items: center;
                                display: flex;
                                flex-wrap: nowrap;
                                height: 40px;
                                &:nth-of-type(2n) {
                                    background-color: $row-background-2;
                                }
                                .custom-cell {
                                    padding: 0 10px;
                                    min-width: 170px;
                                    width: 170px;

                                    .biggerFont {
                                        font-size: 1.25rem;
                                    }
                                }
                            }
                        }
                    }
                `}</style>
            </div>
        </>
    )
}
