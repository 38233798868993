import React, { useEffect, useMemo, useState } from 'react'
import { CardWidget } from './CardWidget'
import { calcMinMax } from '../../../hooks/useModel'
import numeral from 'numeral'
import { useMetrics } from '../../../hooks/useMetrics'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { calcArrayAverage } from 'origination-model'
type Props = any

export function CapexReplacementReserves(props: Props) {
    const metrics = useMetrics()
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const [loading, setLoading] = useState(true)

    const values = useMemo(() => {
        if (!cashFlow || !project) return { min: 0, max: 0, avg: 0 }
        const row = cashFlow?.operatingExpenses.find((r) => r.id == 'maintenance')
        if (!row) return { min: 0, max: 0, avg: 0 }
        const v = calcMinMax(row.years)
        return {
            min: v.min / metrics.numberOfUnits,
            max: v.max / metrics.numberOfUnits,
            avg: calcArrayAverage(row.years),
        }
    }, [project, cashFlow, metrics.numberOfUnits])
    useEffect(() => {
        if (project && cashFlow && metrics.numberOfUnits) {
            setLoading(false)
        }
    }, [project, cashFlow, metrics.numberOfUnits])
    return (
        <CardWidget
            title={'CapEx / Replacement Reserves'}
            loading={loading}
            largeItems={[
                {
                    title: 'Avg / Unit',
                    value: numeral(values.avg / metrics.numberOfUnits).format('$0,0'),
                },
            ]}
            smallItems={[{ min: numeral(values.min).format('$0,0'), max: numeral(values.max).format('$0,0') }]}
        />
    )
}
