import React, { useCallback, useRef, useState } from 'react'

import { Editor } from '@tinymce/tinymce-react'
import { FieldRenderProps } from 'react-final-form'

export function TinyMceAdapter({ input, meta, ...rest }: FieldRenderProps<string>) {
    const init = {
        height: 500,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount 2ColsPlugin',
        ],
        // toolbar: 'undo redo | formatselect | styleselect | bullist | link image | fullscreen code',
        //toolbar: 'undo redo | styleselect bold| bullist | link image | fullscreen code',
        toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | table | bullist numlist outdent indent | ' +
            'removeformat | fullscreen code | 2ColsBtn 3ColsBtn',
        formats: {
            subtitle: {
                inline: 'span',
                styles: {
                    color: '#fff',
                    fontSize: '1.35em',
                    lineHeight: '1.55em',
                    boxSizing: 'border-box',
                    fontFamily: 'Poppins, Arial, Helvetica Neue, Helvetica, sans-serif;',
                },
                attributes: { title: 'Subtitle format' },
                // classes: 'animatedin',
            },
            regular: {
                inline: 'span',
                styles: {
                    color: '#fff',
                    fontSize: '16px',
                    lineHeight: '1.65em',
                    boxSizing: 'border-box',
                    fontFamily: 'Poppins, Arial, Helvetica Neue, Helvetica, sans-serif;',
                },
                attributes: { title: 'Regular format' },
                // classes: 'example2',
            },
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        style_formats: [
            { title: 'Subtitle', format: 'subtitle' },
            { title: 'Regular', format: 'regular' },
        ],

        /* enable automatic uploads of images represented by blob or data URIs*/
        // eslint-disable-next-line @typescript-eslint/camelcase
        automatic_uploads: true,
        /*
          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
          images_upload_url: 'postAcceptor.php',
          here we add custom filepicker only to Image dialog
        */
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_picker_types: 'image',
        /* and here's our custom image picker*/
        // eslint-disable-next-line @typescript-eslint/camelcase
        file_picker_callback: function (cb, value, meta) {
            const input = document.createElement('input')
            input.setAttribute('type', 'file')
            input.setAttribute('accept', 'image/*')
            input.setAttribute('name', 'tinyMceFileInput')

            /*
              Note: In modern browsers input[type="file"] is functional without
              even adding it to the DOM, but that might not be the case in some older
              or quirky browsers like IE, so you might want to add it to the DOM
              just in case, and visually hide it. And do not forget do remove it
              once you do not need it anymore.
            */

            input.onchange = function (inputEvent: any) {
                // console.log('inputEvent', inputEvent)
                const file = inputEvent.target.files[0]

                const reader = new FileReader()
                reader.onload = function () {
                    /*
                      Note: Now we need to register the blob in TinyMCEs image blob
                      registry. In the next release this part hopefully won't be
                      necessary, as we are looking to handle it internally.
                    */
                    const id = 'blobid' + new Date().getTime()
                    const blobCache = tinymce.activeEditor.editorUpload.blobCache
                    // @ts-ignore
                    const base64 = reader.result.split(',')[1]
                    const blobInfo = blobCache.create(id, file, base64)
                    blobCache.add(blobInfo)

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name })
                }
                reader.readAsDataURL(file)
            }
            input.click()
        },

        relative_urls: false,

        setup: function (editor) {
            tinymce.PluginManager.add('2ColsPlugin', function (editor, url) {
                function doColumns(n: number) {
                    editor.execCommand('SelectAll')
                    let text = editor.selection.getContent()
                    if (text.startsWith('<p') && text.endsWith('</p>')) {
                        text = text.substring(text.indexOf('>') + 1).slice(0, -4)
                    }
                    editor.execCommand(
                        'mceReplaceContent',
                        true,
                        '<div style="column-count:' + n + '">' + text + '</div>',
                    )
                    return false
                }
                editor.ui.registry.addButton('2ColsBtn', {
                    onAction: function () {
                        doColumns(2)
                    },
                    text: '2Col',
                    tooltip: 'wrap 2 columns',
                })
                editor.ui.registry.addMenuItem('2ColsMenu', {
                    text: '2Col',
                    onAction: function () {
                        doColumns(2)
                    },
                })

                editor.ui.registry.addButton('3ColsBtn', {
                    onAction: function () {
                        doColumns(3)
                    },
                    text: '3Col',
                    tooltip: 'wrap 2 columns',
                })
                editor.ui.registry.addMenuItem('3ColsMenu', {
                    text: '3Col',
                    onAction: function () {
                        doColumns(3)
                    },
                })

                return {
                    getMetadata: function () {
                        return {
                            name: '2Col plugin',
                            url: 'http://2ColsPlugin.com',
                        }
                    },
                }
            })
        },
    } as any

    const [editorData, setEditorData] = useState(input.value || '<p></p>')
    const onEditorChange = useCallback(
        (value, editor) => {
            // console.log('onEditorChange value', value)
            setEditorData(value)
        },
        [input, editorData],
    )

    const onBlure = useCallback(
        (value, editor) => {
            input.onChange(editorData)
        },
        [input, editorData],
    )
    const editorRef = useRef(null)
    return (
        <React.Fragment>
            {/*// @ts-ignore*/}
            <Editor
                apiKey={'srqxu904f3c63ohcwuo37lqhq6l2jlw193vz9cdnbelq5a91'}
                initialValue={input.value || '<p></p>'}
                init={init}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={onEditorChange}
                onBlur={onBlure}
            />

            {/*language=SCSS*/}
            <style jsx>{`
                :global(.tox-notifications-container) {
                    display: none; //disable notification about missed api-key
                }
            `}</style>
        </React.Fragment>
    )
}
