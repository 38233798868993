import React, { useCallback, useMemo, useRef, useState } from 'react'
import { PropertyDetails } from './widgets/PropertyDetails'
import { LoanSizing } from './widgets/LoanSizing'
import { UnitMix } from './widgets/UnitMix/UnitMix'
import { Expenses } from './widgets/Expenses/Expenses'
import { ExitAnalysis } from './widgets/ExitAnalysis'
import { CollectionAnalysis } from './widgets/CollectionAnalysis/CollectionAnalysis'
import { UsesAndSources } from './widgets/UsesAndSources/UsesAndSources'
import { FineTuneButton } from './FineTuneButton/FineTuneButton'
import { FineTuneDeal } from './FineTuneDeal/FineTuneDeal'
import { OriginationModelReworked } from '@generated/graphql'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../store'
import { Debtanalysis } from './widgets/DebtAnalysis'
import { useRouter } from 'next/router'
import { myStorage } from '../../../../../store/storage'
import { BorrowerDebtanalysis } from './widgets/BorrowerDebtanalysis'

type Props = any

/**
 * Displays playground tab only for lender
 */
export function Playground(props: Props) {
    const [fineTuneVisible, setFineTuneVisible] = useState(false)
    const [showBorrowerComparision, setShowBorrowerComparision] = useState(false)
    const fineTune = useRef<any>()
    const fineTuneShow = useCallback(() => {
        fineTune.current?.show()
    }, [])
    const onFineTuneStateChanged = useCallback((v) => {
        setFineTuneVisible(v)
    }, [])

    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)

    const borrowerDebt = useSelector((state: ReduxStoreState) => state.lender.borrowerDebt)

    useMemo(() => {
        // console.log('borrowerDebt changes', borrowerDebt)
        setShowBorrowerComparision(borrowerDebt.show)
    }, [borrowerDebt])
    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit

    const hasMez = useMemo(() => {
        const sobj = myStorage.getItem('hasMez_' + projectId)
        if (sobj != null && sobj.val) {
            myStorage.setItem('hasMez_' + projectId, { val: true })
            return true
        }
        return false
    }, [model?.sources])

    return (
        <>
            {!fineTuneVisible && <FineTuneButton onClick={fineTuneShow} />}
            <div className={'tab-container'}>
                {showBorrowerComparision ? (
                    <BorrowerDebtanalysis isFineTuneOn={fineTuneVisible} />
                ) : (
                    <PropertyDetails isFineTuneOn={fineTuneVisible} />
                )}
                {hasMez ? (
                    <>
                        <Debtanalysis isFineTuneOn={fineTuneVisible} />
                        <LoanSizing debt={'Senior'} isFineTuneOn={fineTuneVisible} />
                        <LoanSizing debt={'Mezzanine'} isFineTuneOn={fineTuneVisible} />
                    </>
                ) : (
                    <LoanSizing debt={'Senior'} isFineTuneOn={fineTuneVisible} />
                )}
                <UsesAndSources />
                <Expenses form={fineTune} />
                <div className={'sub-grid-block'}>
                    <ExitAnalysis />
                    <UnitMix isFineTuneOn={fineTuneVisible} />
                </div>
                <CollectionAnalysis isFineTuneOn={fineTuneVisible} />
                <div id={'bottom-dock-placeholder'} />
            </div>
            <FineTuneDeal ref={fineTune} onVisibilityChange={onFineTuneStateChanged} />
            {/*language=SCSS*/}
            <style jsx>{`
                .tab-container {
                    width: 100%;
                    display: grid;
                    grid-gap: 20px 20px;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto;
                    }
                    .sub-grid-block {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: auto 1fr;
                        grid-gap: 20px;
                    }
                    #bottom-dock-placeholder.fine-tune-placeholder-expanded {
                        height: 300px;
                    }
                }
                .dnd-block {
                    filter: drop-shadow(0px 10px 35px rgba(83, 112, 129, 0.25));
                    width: fit-content;

                    &.dnd-absolute {
                        position: absolute;
                        z-index: 99;
                        top: 0;
                        left: 0;
                    }
                }
            `}</style>
        </>
    )
}
