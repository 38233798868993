import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { WidgetWrapper } from '../../../../components/WidgetWrapper'
import { CollectionAnalysisChart } from './CollectionAnalysisChart'
import numeral from 'numeral'
import { OriginationModelReworked } from '@generated/graphql'
type Props = {
    isFineTuneOn?: boolean
}
export const CollectionAnalysis = React.memo(function CollectionAnalysis(props: Props) {
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const [collectionByPeriod, setCollectionByPeriod] = useState([])
    useEffect(() => {
        if (!cashFlow) return
        if (collectionByPeriod.length > 0) return
        // gross rent t12 calc
        let arr = []
        cashFlow?.rentalIncome?.map((itm, ind) => {
            if (itm.id == 'grossPotentialRent') arr = itm.aprDetails
        })
        const grossRentT12Monthly = [...arr].reverse()
        //net Rent T12 Monthly calc
        arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        cashFlow?.rentalIncome?.map((itm, ind) => {
            itm?.aprDetails?.map((itm1, ind1) => {
                arr[ind1] += itm1 != null ? itm1 : 0
            })
        })
        cashFlow?.rentalLoss?.map((itm, ind) => {
            itm?.aprDetails?.map((itm1, ind1) => {
                arr[ind1] += itm1 != null ? itm1 : 0
            })
        })
      //  console.log('netRentT12Monthly', arr)
        const netRentT12Monthly = arr.reverse()

        const periods = [12, 9, 6, 3, 2, 1]
        const out = []
        periods.map((itm, ind) => {
            let gross = 0
            let net = 0
            for (let i = 0; i < itm; i++) {
                gross += grossRentT12Monthly[i]
                net += netRentT12Monthly[i]
            }
            gross = (gross / itm) * 12
            net = (net / itm) * 12
            //console.log('T' + itm, gross, net, 1 - net / gross)
            out.push({ id: 'T' + itm, netRental: net, vacancy: 1 - net / gross })
        })
        setCollectionByPeriod(out)
    }, [cashFlow])

    return (
        <div className={'collection-analysis'}>
            <WidgetWrapper style={{ padding: 0 }} title={'Collection Analysis'} showProgress={!model || !cashFlow}>
                <div className={'CollectionTableContent'}>
                    <div className={'CollectionTable'}>
                        <table>
                            <thead>
                                <tr>
                                    <th>PERIOD</th>
                                    <th>NET RENTAL INCOME</th>
                                    <th>ECONOMIC VACANCIES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {collectionByPeriod.map((itm, ind) => {
                                    return (
                                        <React.Fragment key={ind.toString()}>
                                            <tr>
                                                <td className={'period'}>{itm.id}</td>
                                                <td className={'price'}>{numeral(itm.netRental).format('$0,0')}</td>
                                                <td className={'price'}>{numeral(itm.vacancy).format('0.[00]%')}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={'unitMixGraph'}>
                        <CollectionAnalysisChart data={collectionByPeriod} />
                    </div>
                </div>
            </WidgetWrapper>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .collection-analysis {
                    height: ${props.isFineTuneOn ? 'calc(100% - 1px)' : 'auto'};
                }
                .CollectionTableContent {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 20px;

                    @media screen and (max-width: 1440px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: auto auto;
                    }

                    @media screen and (max-width: 1200px) {
                        grid-template-columns: 1fr 0.8fr;
                    }
                }
                .CollectionTable {
                    margin-left: -20px;
                }
                table {
                    width: 100%;

                    tbody {
                        tr {
                            height: 35px;

                            &:nth-child(2n - 1) {
                                background: #f9fafb;
                            }
                            td:last-child {
                                padding-right: 16px;
                            }
                        }
                    }
                }
                th {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #676f7a;
                    padding: 0 8px 12px;
                    &:not(:first-child) {
                        text-align: right;
                    }
                }
                .period,
                th:first-child {
                    padding-left: 20px;
                }
                .price {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: right;

                    color: #161c26;
                }
                .bedroom {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: #262b35;
                }
                .title {
                    width: 100%;
                    padding-bottom: 10px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: #161c26;
                    border-bottom: 1px solid #d9e0e5;
                }
            `}</style>
        </div>
    )
})
