import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'

type Props = {
    leftHeader: React.ReactNode
    scrollableHeader: React.ReactNode
    leftContent: React.ReactNode
    content: React.ReactNode
    rightContent: React.ReactNode
    leftWidth: string
    style?: React.CSSProperties
    isPDF?: boolean
}

export const FixedHeaderWrapper = React.memo(function FixedHeaderWraper(props: Props) {
    const componentContainerRef = useRef<HTMLDivElement>()
    const containerRef = useRef<HTMLDivElement>()
    const scrollableHeaderRef = useRef<HTMLDivElement>()
    const scrollableHeaderFixedRef = useRef<HTMLDivElement>()
    const leftWidth = props.leftWidth || '30%'
    const [fixed, setFixed] = useState(false)
    const [isContentScrolled, setContentScrolled] = useState(false)
    const [isContentScrolledForComments, setContentScrolledForComments] = useState(false)
    const [wrapperId, setWrapperId] = useState(`${Math.random()}`)
    const { leftHeader, scrollableHeader, leftContent, content, rightContent } = props
    const showComments = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showComments)
    const isScrollbarEnable = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showScrollbar,
    )
    const [width, setWidth] = useState(`0px`)
    const handleWindowScroll = useCallback(
        (e) => {
            if (props.isPDF) return
            const rect = componentContainerRef.current.getBoundingClientRect()
            if (rect.top <= 0) {
                setFixed(true)
                scrollableHeaderFixedRef.current.style.width = `${
                    scrollableHeaderRef.current.getBoundingClientRect().width
                }px`
                scrollableHeaderFixedRef.current.scrollLeft = containerRef.current.scrollLeft
            } else {
                setFixed(false)
            }
        },
        [props.isPDF],
    )
    useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll, false)
        return () => {
            window.removeEventListener('scroll', handleWindowScroll)
        }
    }, [])

    useEffect(() => {
        const width =
            componentContainerRef?.current?.getBoundingClientRect().width -
            (scrollableHeaderRef?.current?.getBoundingClientRect().width + 300)
        setWidth(`${width}px`)

        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'showScrollbar'], width <= 90)
    }, [content])

    const handleHeaderScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement>) => {
            // @ts-ignore
            const scrollLeft = e.target.scrollLeft
            containerRef.current.scrollLeft = scrollLeft
            setContentScrolled(scrollLeft > 0)
            // @ts-ignore
            setContentScrolledForComments(e.target.scrollWidth - (e.target.scrollLeft + e.target.clientWidth) > 0)
        },
        [fixed],
    )
    const handleContentScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement>) => {
            // @ts-ignore
            const scrollLeft = e.target.scrollLeft
            scrollableHeaderRef.current.scrollLeft = scrollLeft
            if (scrollableHeaderFixedRef.current) {
                scrollableHeaderFixedRef.current.scrollLeft = scrollLeft
            }
            setContentScrolled(scrollLeft > 0)
            // @ts-ignore
            setContentScrolledForComments(e.target.scrollWidth - (e.target.scrollLeft + e.target.clientWidth) > 0)
        },
        [fixed],
    )
    return (
        <div className={'fixed-header-wrapper'} id={wrapperId} ref={componentContainerRef} style={props.style}>
            {fixed && (
                <div className={'pos-fixed-header scrollable-header'}>
                    <div
                        className={`left-header ${isContentScrolled ? 'shadow-box' : ''}`}
                        style={{ width: leftWidth }}
                    >
                        {leftHeader}
                    </div>
                    <div className={'scrollable-header'} ref={scrollableHeaderFixedRef} onScroll={handleHeaderScroll}>
                        {scrollableHeader}
                    </div>
                    {showComments && (
                        <div
                            className={`comment-header ${isContentScrolledForComments ? 'shadow-box' : ''}`}
                            style={{ maxWidth: width, width: '100%' }}
                        >
                            {!isScrollbarEnable && (
                                <div className={'title-section'}>
                                    <div className={'title'}>Comments</div>
                                    <div className={'delimiter'} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className={'in-place-header scrollable-header'} style={{ opacity: fixed ? 0 : 1 }}>
                <div className={`left-header ${isContentScrolled ? 'shadow-box' : ''}`} style={{ width: leftWidth }}>
                    {leftHeader}
                </div>
                <div className={'scrollable-header'} onScroll={handleHeaderScroll} ref={scrollableHeaderRef}>
                    {scrollableHeader}
                </div>
                {showComments && (
                    <div
                        className={`comment-header ${isContentScrolledForComments ? 'shadow-box' : ''}`}
                        style={{ maxWidth: width, width: '100%' }}
                    >
                        {!isScrollbarEnable && (
                            <div className={'title-section'}>
                                <div className={'title'}>Comments</div>
                                <div className={'delimiter'} />
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className={'content-container'}>
                <div className={`left-content ${isContentScrolled ? 'shadow-box' : ''}`} style={{ width: leftWidth }}>
                    {leftContent}
                </div>
                <div className={'scrollable-content'} ref={containerRef} onScroll={handleContentScroll}>
                    {content}
                </div>
                {showComments && (
                    <div
                        className={`comments ${isContentScrolledForComments ? 'shadow-box' : ''}`}
                        style={{ maxWidth: width, width: '100%' }}
                    >
                        {rightContent}
                    </div>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .fixed-header-wrapper {
                    width: 100%;
                    .scrollable-header {
                        flex: 1;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                    }
                    .in-place-header {
                        display: flex;
                        flex-direction: row;
                        overflow: hidden;
                    }
                    .pos-fixed-header {
                        position: fixed;
                        top: 0;
                        overflow: hidden;
                        background: white;
                        z-index: 2000;
                        display: grid;
                        grid-template-columns: ${showComments ? `${leftWidth} auto ${width}` : `${leftWidth} auto`};
                    }

                    .scrollable-header::-webkit-scrollbar {
                        height: 10px; /* height of horizontal scrollbar ← You're missing this */
                        width: 10px; /* width of vertical scrollbar */
                    }
                    .scrollable-header::-webkit-scrollbar-thumb {
                        background: #dce0e4;
                        height: 10px;
                        border-radius: 6px;
                    }
                    .in-place-header.scrollable-header {
                        width: 100%;
                        display: grid;
                        grid-template-columns: ${showComments ? `${leftWidth} auto 1fr` : `${leftWidth} auto`};
                    }

                    .scrollable-header,
                    .scrollable-content {
                        overflow-y: auto;
                    }

                    .scrollable-content {
                        flex: 1;
                        scrollbar-width: none;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    .content-container {
                        width: 100%;
                        overflow: hidden;
                        display: grid;
                        grid-template-columns: ${showComments ? `${leftWidth} 1fr ${width}` : `${leftWidth} 1fr`};
                    }
                }

                .left-header {
                    z-index: 500;
                    transition-property: box-shadow;
                    transition-duration: 150ms;
                    &.shadow-box {
                        box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
                        border-right: 1px solid rgba(0, 0, 0, 0.3);
                    }
                }
                .left-content {
                    z-index: 450;
                    transition-property: box-shadow;
                    transition-duration: 150ms;
                    &.shadow-box {
                        box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
                        border-right: 1px solid rgba(0, 0, 0, 0.3);
                    }
                }
                .comment-header {
                    min-width: 40px;
                    z-index: 450;
                    transition-property: box-shadow;
                    transition-duration: 150ms;
                    display: block;
                    background: #fff;
                    border-left: ${isScrollbarEnable ? '1px solid rgba(0, 0, 0, 0.3)' : 'none'};
                    &.shadow-box {
                        box-shadow: ${isScrollbarEnable ? '0px 0px 20px 10px rgba(0, 0, 0, 0.1)' : 'none'};
                    }

                    .title-section {
                        width: 100%;
                        .title {
                            padding-top: 20px;
                            text-align: left;
                            margin-bottom: 4px;
                            text-transform: capitalize;
                        }
                        .delimiter {
                            height: 4px;
                            background: #eaeef1;
                        }
                    }
                }
                .comments {
                    min-width: 40px;
                    z-index: 450;
                    transition-property: box-shadow;
                    transition-duration: 150ms;
                    background: #fff;
                    border-left: ${isScrollbarEnable ? '1px solid rgba(0, 0, 0, 0.3)' : 'none'};
                    &.shadow-box {
                        box-shadow: ${isScrollbarEnable ? '0px 0px 20px 10px rgba(0, 0, 0, 0.1)' : 'none'};
                    }
                }
            `}</style>
        </div>
    )
})
