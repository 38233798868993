import React from 'react'

type Props = any

export const HistoricalTabs = React.memo(function HistoricalTabs({ tabs, activeTab, onChange }: Props) {
    return (
        <div className={'historical-tabs'}>
            {tabs.map((tab, i) => (
                <div
                    key={tab + i}
                    className={`historical-tab ${tab.value === activeTab ? 'active' : ''}`}
                    onClick={(e) => {
                        onChange(tab.value)
                    }}
                >
                    {tab.label}
                </div>
            ))}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .historical-tabs {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 20px;
                    .historical-tab {
                        border-radius: 3px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 8px;
                        border: 1px solid #d9e0e5;
                        background: #d9e0e5;
                        color: $blue-gray;
                        cursor: pointer;
                        font-size: 15px;
                        line-height: 16px;
                        &.active {
                            background: #dae7ff;
                            border: 1px solid #4486ff;
                            color: #4486ff;
                        }
                        margin-right: 4px;
                    }
                }
            `}</style>
        </div>
    )
})
