import LeaseUpLogo from 'images/lease-up-logo.svg'

export const NoLeaseUpScreen = ({ setShowCreateLeaseUpModal, rentRoll, rentRollDate }) => {
    return (
        <div className="screen-wrapper">
            <img src={LeaseUpLogo} alt={'lease-up-logo'} />
            <h1>No Lease-Up Created Yet</h1>
            <p>
                You haven’t created your Lease-Up projection yet. Please enter your parameters and tweak them as you go.
            </p>
            <div
                onClick={() => {
                    if (rentRoll && rentRollDate) setShowCreateLeaseUpModal(true)
                }}
                className={`btn${!rentRoll || !rentRollDate ? ' disabled-btn' : ''}`}
            >
                Create Lease-Up
            </div>
            <div className="errors-wrapper">
                {!rentRoll && <div className="error-msg">Please upload a RentRoll to continue</div>}
                {rentRoll && !rentRollDate && (
                    <div className="error-msg">Please select a RentRoll date in Operating Assumptions to continue</div>
                )}
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .screen-wrapper {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .errors-wrapper {
                        margin-top: 20px;

                        .error-msg {
                            color: #f14b44;
                        }
                    }

                    h1 {
                        font-size: 20px;
                        color: #161c26;
                        margin-bottom: 20px;
                    }

                    p {
                        font-size: 17px;
                        color: #808fa7;
                        margin-bottom: 20px;
                        max-width: 30%;
                        text-align: center;
                    }

                    img {
                        width: 70px;
                        height: 60px;
                        margin-bottom: 20px;
                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 500;
                        font-size: 14px;
                        color: #ffffff;
                        width: 180px;
                        height: 45px;
                        background: #4486ff;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    .disabled-btn {
                        background: #4486ff78;
                        cursor: initial;
                    }
                }
            `}</style>
        </div>
    )
}
