import React from 'react'
import { NumberField } from '../../../components/NumberField'
import { TextField } from '../../../components/TextField'
import { SelectField } from '../../../components/SelectField'
import { useFormState } from 'react-final-form'

type Props = any

const loanTypes = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'ARM', value: 'arm' },
]
export function DebtAssumption(props: Props) {
    const values = useFormState().values
    const loanTypeSenior = values?.debtAssumptionSenior.rateType || 'fixed'
    const loanTypeMez = values?.debtAssumptionMez.rateType || 'fixed'
    return (
        <>
            <div className={'field-values'}>Senior Debt</div>
            <div className={'fields-header'}>
                <div style={{ width: '40%' }} />
                <div className={'year'} style={{ width: '30%' }}>
                    AMOUNT
                </div>
                <div className={'year'} style={{ width: '15%' }}>
                    LTV
                </div>
                <div className={'year'} style={{ width: '15%' }}>
                    LTC
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan Proceeds
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`sources.seniorDebt`} numberFormat={'currency'} readOnly={true} />
                </div>
                <div className="model-field-value" style={{ width: '15%' }}>
                    <NumberField name={`computed.ltv`} numberFormat={'percent'} readOnly={true} />
                </div>
                <div className="model-field-value" style={{ width: '15%' }}>
                    <NumberField name={`computed.ltc`} numberFormat={'percent'} readOnly={true} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Rate Type
                </div>
                <div className="model-field-value" style={{ width: '60%' }}>
                    <SelectField
                        name={`debtAssumptionSenior.rateType`}
                        className={'selectRateType'}
                        innerclassName={'selectRateTypeInner'}
                        values={loanTypes}
                        showClearButton={false}
                        placeholder={'fixed'}
                        initialValue={loanTypeSenior}
                    />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Rate
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionSenior.rate`} numberFormat={'percent'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Amortization Term
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionSenior.amortizationTerm`} numberFormat={'years'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan term
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionSenior.loanTerm`} numberFormat={'years'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    I/O Period
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionSenior.ioPeriod`} numberFormat={'years'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan Per Unit
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`computed.loanperunit`} numberFormat={'currency'} readOnly={true} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan Per SF
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`computed.loanpersft`} numberFormat={'currency'} readOnly={true} />
                </div>
            </div>

            <div className={'fields-header'}></div>
            <div className={'fields-header'}></div>
            <div className={'field-values'}>Mezzanine</div>
            <div className={'fields-header'}>
                <div style={{ width: '40%' }} />
                <div className={'year'} style={{ width: '30%' }}>
                    AMOUNT
                </div>
                <div className={'year'} style={{ width: '15%' }}>
                    LTV
                </div>
                <div className={'year'} style={{ width: '15%' }}>
                    LTC
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan Proceeds
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`sources.mezzanine`} numberFormat={'currency'} readOnly={true} />
                </div>
                <div className="model-field-value" style={{ width: '15%' }}>
                    <NumberField name={`computed.ltvMez`} numberFormat={'percent'} readOnly={true} />
                </div>
                <div className="model-field-value" style={{ width: '15%' }}>
                    <NumberField name={`computed.ltcMez`} numberFormat={'percent'} readOnly={true} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Rate Type
                </div>
                <div className="model-field-value" style={{ width: '60%' }}>
                    <SelectField
                        name={`debtAssumptionMez.rateType`}
                        className={'selectRateType'}
                        innerclassName={'selectRateTypeInner'}
                        values={loanTypes}
                        showClearButton={false}
                        placeholder={'fixed'}
                        initialValue={loanTypeMez}
                    />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Rate
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionMez.rate`} numberFormat={'percent'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Amortization Term
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionMez.amortizationTerm`} numberFormat={'years'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan term
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionMez.loanTerm`} numberFormat={'years'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    I/O Period
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`debtAssumptionMez.ioPeriod`} numberFormat={'years'} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan Per Unit
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`computed.loanperunitMez`} numberFormat={'currency'} readOnly={true} />
                </div>
            </div>
            <div className="field-values">
                <div className="model-field-title" style={{ width: '40%' }}>
                    Loan Per SF
                </div>
                <div className="model-field-value" style={{ width: '30%' }}>
                    <NumberField name={`computed.loanpersftMez`} numberFormat={'currency'} readOnly={true} />
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    $col1: 40%;
                    :global(.selectRateTypeInner) {
                        width: 60%;
                    }
                    .fields-header {
                        display: flex;
                        flex-direction: row;
                        margin-top: 17px;
                        margin-bottom: 17px;
                        font-size: 12px;
                        line-height: 15px;
                        div:first-child {
                            width: $col1;
                        }
                        div:nth-child(2) {
                            margin-right: 20px;
                        }
                        div:nth-child(3) {
                            margin-right: 20px;
                        }
                        .year {
                            text-align: right;
                            text-transform: uppercase;
                            color: $gray-nevada;
                        }
                    }
                    .field-values {
                        display: flex;
                        flex-direction: row;
                        margin-top: 13px;
                        .model-field-title {
                            width: $col1;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 18px;
                            color: $black;
                        }

                        .model-field-value {
                            text-align: right;
                            &:nth-child(2) {
                                margin-right: 20px;
                            }
                            &:nth-child(3) {
                                margin-right: 20px;
                            }
                        }
                    }
                `}
            </style>
        </>
    )
}
