import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { CashFlowRowGraphQl } from '@generated/graphql'
import { useSelector } from 'react-redux'
import { Tooltip } from 'devextreme-react/tooltip'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../../store'
import { SagaHelper } from '../../../../../../../../../store/sagaHelper'
import commentIcon from './comment-icon.svg'
import commentIconHover from './comment-icon-hover.svg'
import addCommentIcon from './add-comment-icon.svg'
import deleteCommentIcon from './delete-comment-icon.svg'
import editCommentIcon from './edit-comment-icon.svg'
import { Field, Form } from 'react-final-form'
import $ from 'jquery'
import { useRouter } from 'next/router'
import { orgenizeCFForPresentation } from 'origination-model'
type Props = any
const cloneDeep = require('lodash.clonedeep')
export const CommentWrapper = React.memo(function CommentWrapper(props: Props) {
    const storCashFlow = useSelector((store: ReduxStoreState) => store.lender.originationModel?.cashFlow)
    const cashFlow = useMemo(() => {
        return orgenizeCFForPresentation({
            cashFlow: storCashFlow,
        })
    }, [storCashFlow])

    const cashFlowBeforeDebtService = useMemo(() => {
        const beforeRow: CashFlowRowGraphQl = cloneDeep(cashFlow.cashFlowAfterDebtService)
        beforeRow.title = 'Cash Flow Before Debt Service'
        beforeRow.id = 'cashFlowBeforeDebtService'
        return beforeRow
    }, [cashFlow])
    const totalExpensesRatio = useMemo(
        () => ({
            id: 'totalExpensesRatio',
            title: 'Total expenses ratio (from effective gross income)',
            comment: null,
            subRows: [],
        }),
        [],
    )
    return (
        <>
            <div className={'empty-section'} />
            <CommentGroup rows={cashFlow.rentalIncome} address={'rentalIncome'} />
            <CommentSingle row={cashFlow.totalPotentialGrossRent} address={'totalPotentialGrossRent'} />
            <CommentGroup rows={cashFlow.rentalLoss} address={'rentalLoss'} />
            <CommentSingle row={cashFlow.effectiveGrossRent} type={'total-blue'} address={'effectiveGrossRent'} />
            <CommentGroup rows={cashFlow.otherIncome} address={'otherIncome'} />
            <CommentSingle row={cashFlow.effectiveGrossIncome} type={'total-green'} address={'effectiveGrossIncome'} />
            <div className={'operating-expenses'} />
            <CommentGroup rows={cashFlow.operatingExpenses} address={'operatingExpenses'} />
            <CommentSingle
                row={cashFlow.totalOperatingExpenses}
                type={'total-blue'}
                address={'totalOperatingExpenses'}
            />
            <CommentSingle row={totalExpensesRatio} />
            <CommentSingle row={cashFlow.noi} address={'noi'} />
            <CommentGroup
                rows={cashFlow.other.filter(
                    (row) => row.id != 'debtServiceInterest' && row.id != 'debtServiceInterestMez',
                )}
                address={'other'}
            />
            <CommentSingle row={cashFlowBeforeDebtService} type={'total-green'} address={'cashFlowAfterDebtService'} />
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .empty-section {
                    height: 24px;
                    //padding: 12px 0;
                }
                .operating-expenses {
                    height: 100px;
                }
            `}</style>
        </>
    )
})
type CommentGroupProps = {
    rows: CashFlowRowGraphQl[]
    address?: string
}
const CommentGroup = React.memo(function CommentGroup(props: CommentGroupProps) {
    return (
        <>
            {props.rows.map((row, i) => (
                <CommentSingle address={props.address} row={row} key={i} ind={i} />
            ))}
        </>
    )
})

type TypesList = 'total-blue' | 'total-green'
type CommentSingleProps = {
    row: CashFlowRowGraphQl
    type?: TypesList
    address?: string
    ind?: number
}
const CommentSingle = React.memo(function CommentSingle(props: CommentSingleProps) {
    const expanded = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.rowsExpanded[props.row.id],
    )
    const idHover = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.rowIdHover)
    // sync height with title rows
    //sync row height with title

    return (
        <>
            <Comment
                rowId={props.row?.id}
                type={props.type}
                idHover={idHover}
                expanded={expanded}
                isHoverBg={true}
                address={props.address}
                ind={props.ind}
            />

            {expanded &&
                props.row.subRows.map((subRow, ind) => {
                    const subId = `${props.row.id}-${ind}`
                    return (
                        <Comment
                            rowId={subId}
                            idHover={`${idHover}`}
                            key={ind}
                            isHoverBg={subId == idHover}
                            address={props.address}
                            ind={props.ind}
                            subRowInd={ind}
                        />
                    )
                })}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
            `}</style>
        </>
    )
})

type CommentProps = {
    rowId: string
    type?: TypesList
    idHover?: string
    expanded?: any
    isHoverBg: boolean
    address?: string
    ind?: number
    subRowInd?: number
}

type FormTypes = {
    inputCommentText: string
}

const Comment = React.memo(function Comment(props: CommentProps) {
    const { rowId, type, idHover, expanded, isHoverBg, address, ind, subRowInd } = props
    const router = useRouter()
    const projectId = +router.query.id
    const isScrollEnable = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showScrollbar,
    )
    const isOM = router.asPath.indexOf('#compare') > 0

    const commentWindowRef = useRef(null)
    const [hoverComment, setHoverComment] = useState(false)
    const [showCommentWindow, setShowCommentWindow] = useState(false)
    const [toggleTooltip, setToggleTooltip] = useState(false)
    const handleMouseImgIn = useCallback(() => {
        setHoverComment(true)
        setToggleTooltip(true)
    }, [])
    const handleMouseImgOut = useCallback(() => {
        setHoverComment(false)
        setToggleTooltip(false)
    }, [hoverComment])
    const handleMouseCommentIn = useCallback(() => {
        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowIdHover'], rowId)
    }, [])
    const handleMouseCommentOut = useCallback(() => {
        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowIdHover'], null)
    }, [hoverComment])
    useEffect(() => {
        function handleClickOutside(event) {
            if (commentWindowRef.current && !commentWindowRef.current.contains(event.target)) {
                setShowCommentWindow(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
    const CommentText = useSelector((store: ReduxStoreState) => {
        if (rowId == 'totalExpensesRatio') return ''
        const tmpStoreCashflow = orgenizeCFForPresentation({ cashFlow: store.lender.originationModel?.cashFlow })

        return subRowInd != undefined
            ? tmpStoreCashflow[address][ind]['subRows'][subRowInd]['comment']
            : ind != undefined
            ? tmpStoreCashflow[address][ind]['comment']
            : tmpStoreCashflow[address]['comment']
    })
    const deleteComment = useCallback(() => {
        // prettier-ignore
        subRowInd != undefined
            // @ts-ignore
            ? ReduxHelper.setIn(['lender', 'originationModel','cashFlow', `${address}`, `${ind}`, 'subRows', `${subRowInd}`, 'comment',], '',)
            : ind != undefined
            ? // @ts-ignore
            ReduxHelper.setIn(['lender', 'originationModel', 'cashFlow', `${address}`, `${ind}`, 'comment'], '')
            : // @ts-ignore
            ReduxHelper.setIn(['lender', 'originationModel', 'cashFlow', `${address}`, 'comment'], '')
        SagaHelper.run(['lenderOriginationModel', 'saveCashFlowComments'], { projectId }).finally()
        setShowCommentWindow(false)
    }, [CommentText])
    //additionally pushed up comment edit window in bottom of page
    useEffect(() => {
        if (showCommentWindow && !isOM) {
            const contentBlockEl = document.querySelector('.fixed-header-wrapper').getBoundingClientRect?.()
            const commentBlockEl = document.getElementById(`comment-${rowId}`).getBoundingClientRect?.()
            const distanceBetweenEls =
                contentBlockEl.top + contentBlockEl.height - (commentBlockEl.top + commentBlockEl.height)
            const commentWindowElId = `comment-${rowId}`
            const commentWindowEl = $(`[id="${commentWindowElId}"] .comment-edit-window`)
            if (commentWindowEl) {
                const additionalHeight = commentWindowEl[0].getBoundingClientRect?.().height - distanceBetweenEls
                if (additionalHeight >= 0) {
                    commentWindowEl[0].style.transform = `translate(0%, -${additionalHeight}px)`
                    commentWindowEl[0].style.right = `100%`
                } else if (isScrollEnable) {
                    commentWindowEl[0].style.transform = `translate(-100%, 0)`
                    commentWindowEl[0].style.right = `0`
                }
            }
        }
    }, [showCommentWindow])
    //additionaly pushed up comment edit window in bottom of page

    const onSubmit = useCallback(async (values: FormTypes) => {
        // prettier-ignore
        if (subRowInd != undefined) {
            ReduxHelper.setIn(
                // @ts-ignore
                ['lender', 'originationModel', 'cashFlow', `${address}`, `${ind}`, 'subRows', `${subRowInd}`, 'comment',],
                values.inputCommentText,
            )
        } else {
            ind != undefined
                ? ReduxHelper.setIn(
                // @ts-ignore
                ['lender', 'originationModel', 'cashFlow', `${address}`, `${ind}`, 'comment'],
                values.inputCommentText,
                )
                : ReduxHelper.setIn(
                // @ts-ignore
                ['lender', 'originationModel', 'cashFlow', `${address}`, 'comment'],
                values.inputCommentText,
                )
        }
        setShowCommentWindow(false)
        setHoverComment(false)
        SagaHelper.run(['lenderOriginationModel', 'saveCashFlowComments'], { projectId }).finally()
    }, [])

    useEffect(() => {
        let titleEl = document.getElementById(`row-title-${props.rowId}`)
        if (!titleEl) {
            titleEl = document.getElementById(`subrow-title-${props.rowId}`)
        }
        const valueEl = document.getElementById(`comment-${props.rowId}`)
        if (titleEl) {
            const height = titleEl.getBoundingClientRect().height
            valueEl.style.height = `${height}px`
        }
    }, [])
    const selectedIds = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.selectedRowIds)
    return (
        <div
            className={`comment-block ${type ? type : ''} ${rowId == idHover ? 'hover' : ''} 
            ${expanded || selectedIds.includes(rowId) ? 'expanded' : ''}`}
            ref={commentWindowRef}
            id={`comment-${rowId}`}
            onMouseEnter={isOM ? null : handleMouseCommentIn}
            onMouseLeave={isOM ? null : handleMouseCommentOut}
        >
            <div className={'comment-inner-content'}>
                <div>
                    {CommentText ? (
                        <>
                            {isScrollEnable ? (
                                <>
                                    <img
                                        src={hoverComment ? commentIconHover : commentIcon}
                                        onClick={() => (isOM ? null : setShowCommentWindow(!showCommentWindow))}
                                        onMouseEnter={handleMouseImgIn}
                                        onMouseLeave={handleMouseImgOut}
                                    />
                                    <Tooltip
                                        target={`#comment-${rowId.replace(/\s|&/g, '')}`}
                                        visible={toggleTooltip && !showCommentWindow}
                                        closeOnOutsideClick={true}
                                        position="left"
                                    >
                                        <div className={'comment-tooltip'}>{CommentText}</div>
                                    </Tooltip>
                                </>
                            ) : (
                                <div
                                    className={'comment-text-expanded'}
                                    onMouseEnter={handleMouseImgIn}
                                    onMouseLeave={handleMouseImgOut}
                                    onClick={() => (isOM ? null : setShowCommentWindow(!showCommentWindow))}
                                >
                                    <span>{CommentText}</span>
                                    {hoverComment && !isOM && <img src={editCommentIcon} />}
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {!isOM && rowId != 'totalExpensesRatio' && (
                                <div
                                    className={'add-comment-block'}
                                    onClick={() => setShowCommentWindow(!showCommentWindow)}
                                >
                                    <img src={addCommentIcon} />
                                    {!isScrollEnable && <span>Add comment</span>}
                                </div>
                            )}
                        </>
                    )}
                </div>

                {showCommentWindow && !isOM && (
                    <div className={'comment-edit-window'}>
                        <Form
                            onSubmit={onSubmit}
                            render={({ handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            component={'textarea'}
                                            className="comment-text"
                                            name="inputCommentText"
                                            placeholder="Enter comment...."
                                            initialValue={CommentText ? CommentText : ''}
                                        />

                                        <div className={'buttons'}>
                                            <button className={'delete-button btn'} onClick={deleteComment}>
                                                <img src={deleteCommentIcon} />
                                            </button>
                                            <div>
                                                <button
                                                    className={'btn btn-primary ghost'}
                                                    onClick={() => setShowCommentWindow(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button className={'btn btn-primary regular'} onClick={handleSubmit}>
                                                    {CommentText ? 'Save' : 'Post'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        />
                    </div>
                )}
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .comment-block {
                    justify-content: ${isScrollEnable ? 'center' : 'left'};
                    position: ${isScrollEnable ? 'relative' : 'initial'};
                    display: flex;
                    align-items: center;
                    .comment-inner-content {
                        display: flex;
                        align-items: stretch;
                        position: ${isScrollEnable ? 'initial' : 'relative'};
                        height: 100%;
                        > div {
                            overflow: hidden;
                            display: flex;
                            flex: 1;
                            justify-content: ${isScrollEnable ? 'center' : 'unset'};

                            align-items: center;
                        }
                    }
                    &.total-blue {
                        background: rgba(68, 134, 255, 0.1);
                    }
                    &.total-green {
                        background: rgba(68, 225, 131, 0.1);
                    }
                    &.hover {
                        cursor: pointer;
                        background: ${isHoverBg ? 'rgba(225, 226, 229, 0.5)' : 'none'};
                        .add-comment-block {
                            opacity: 1;
                            span {
                                font-weight: 500;
                                font-size: 13px;
                                color: #4486ff;
                                margin-left: 5px;
                            }
                        }
                    }
                    &.expanded {
                        background: rgba(225, 226, 229, 1);
                    }
                    img {
                        position: initial;
                        margin: 0;
                    }
                    .add-comment-block {
                        opacity: 0;
                        user-select: none;
                    }
                    .comment-text-expanded {
                        flex: 1;
                        text-align: left;
                        display: grid;
                        grid-template-columns: auto 1fr;
                        overflow: hidden;
                        height: 100%;
                        align-self: center;
                        align-items: center;
                        span {
                            display: block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        img {
                            margin: 0 5px;
                        }
                    }
                    .comment-edit-window {
                        position: absolute;
                        width: 290px;
                        background: $white;
                        border: 1px solid $border;
                        box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                        border-radius: 3px;
                        padding: 24px 20px 20px 20px;
                        display: flex;
                        z-index: 1;
                        left: ${isScrollEnable ? '-4px' : 'initial'};
                        top: ${isScrollEnable ? '0' : '100%'};
                        right: ${isScrollEnable ? 'initial' : '0'};
                        form {
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            :global(.comment-text) {
                                flex: 1;
                                min-height: 80px;
                                border: 1px solid $border;
                                border-radius: 3px;
                                resize: none;
                                font-weight: 500;
                                font-size: 13px;
                                color: $black;
                                padding: 10px 12px;

                                &::placeholder {
                                    color: #d9e0e5;
                                }
                            }
                            .buttons {
                                flex: 0;
                                margin-top: 20px;
                                display: flex;
                                justify-content: space-between;

                                > div {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    .btn {
                                        font-weight: 500;
                                        font-size: 13px;
                                        line-height: 14px;
                                        padding: 10px 12px;
                                    }
                                    .ghost {
                                        color: #4485ff;
                                        border: 1px solid #4485ff;
                                        background: #fff;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
                :global(.dx-popover-arrow) {
                    display: none;
                }
                :global(.dx-popup-content) {
                    border-radius: 4px;
                    padding: 6px 12px !important;
                    .comment-tooltip {
                        font-size: 15px;
                        line-height: 18px;
                        color: #ffffff;
                    }
                }
                :global(.dx-tooltip-wrapper .dx-overlay-content) {
                    border: none !important;
                    background: rgba(104, 120, 147, 1);
                }
            `}</style>
        </div>
    )
})
