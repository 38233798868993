import { useRef, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import {
    getHistoricalWeeks,
    getHistoricalMoveIns,
    getHistoricalMoveOuts,
    getHistoricalProjectedMoveIns,
    getHistoricalProjectedNetMoveIns,
    getHistoricalProjectedCumulativeMoveIns,
    getHistoricalNewCOSDelivered,
    getHistoricalTotalUnitsCOS,
    getHistoricalProjectedOccupancyOnTotalUnitsCOS,
    getHistoricalProjectedOccupancyOnTotalUnitsCount,
    getHistoricalNRI,
    getHistoricalEconomicOccupancy,
    getHistoricalConcessions,
    getHistoricalOtherRentalLosses,
    getHistoricalOtherIncome,
    getHistoricalExpenses,
    getHistoricalNOI,
    getHistoricalDSCR,
    getHistoricalIoDSCR,
} from 'origination-model'
import { ReduxHelper, ReduxStoreState } from 'store'
import { getDateString } from '../utils/dates'

export const Table = ({
    rentRollGroupedByLeaseStartDate,
    rentRollByLeaseExpireDate,
    numberOfUnits,
    projectionData,
    weeksToReachFullCO,
    GPR,
    concession,
    otherRentalLosses,
    sumOfOtherIncome,
    expenses,
    selectedPricingOption,
    rateLockItem,
    stabilizationItem,
    currentWeek,
    futureRentRollEarliestWeek,
    futureRentRollByLeaseStartDate,
    futureRentRollByLeaseExpireDate,
    setHistoricalWeeksForGraph,
    setHistoricalOccupancyForGraph,
    setShowHighOccupancyError1,
    firstPricingOption
}) => {
    const leaseUp = useSelector((state: ReduxStoreState) => state.lender.leaseUp.data)
    const projectedMoveIns = leaseUp?.projectedMoveIns
    const newCosDelivered = leaseUp?.newUnitsDelivered
    const availableUnits = leaseUp?.availableUnits

    const historicalWeeks = useMemo(
        () => getHistoricalWeeks(rentRollGroupedByLeaseStartDate),
        [rentRollGroupedByLeaseStartDate],
    )

    useEffect(() => {
        setHistoricalWeeksForGraph(historicalWeeks || [])
    }, [historicalWeeks])

    const hiddenHistoricalRows = useMemo(() => {
        if (leaseUp.projectionCustomDate && historicalWeeks?.length) {
            const projectionCustomDate = new Date(leaseUp.projectionCustomDate)
            let hiddenRowsCount = 0
            historicalWeeks.forEach((week) => {
                if (+week < projectionCustomDate.getTime()) hiddenRowsCount++
            })
            return hiddenRowsCount
        } else {
            return 0
        }
    }, [leaseUp?.projectionCustomDate, historicalWeeks])

    const hiddenProjectionRows = useMemo(() => {
        if (leaseUp?.projectionCustomDate && projectionData?.length) {
            const projectionCustomDate = new Date(leaseUp.projectionCustomDate)
            let hiddenRowsCount = 0
            projectionData.forEach((item) => {
                if (+item.week < projectionCustomDate.getTime()) hiddenRowsCount++
            })
            return hiddenRowsCount
        } else {
            return 0
        }
    }, [leaseUp?.projectionCustomDate, projectionData])

    const historicalMoveIns = useMemo(
        () => getHistoricalMoveIns(historicalWeeks, rentRollGroupedByLeaseStartDate, futureRentRollByLeaseStartDate),
        [historicalWeeks, rentRollGroupedByLeaseStartDate, futureRentRollByLeaseStartDate],
    )

    const historicalMoveOuts = useMemo(
        () => getHistoricalMoveOuts(historicalWeeks, rentRollByLeaseExpireDate, futureRentRollByLeaseExpireDate),
        [historicalWeeks, rentRollByLeaseExpireDate, futureRentRollByLeaseExpireDate],
    )

    const historicalProjectedMoveIns = useMemo(() => getHistoricalProjectedMoveIns(historicalWeeks), [historicalWeeks])

    const historicalProjectedNetMoveIns = useMemo(
        () => getHistoricalProjectedNetMoveIns(historicalWeeks, historicalMoveIns, historicalMoveOuts),
        [historicalWeeks, historicalMoveIns, historicalMoveOuts],
    )

    const historicalProjectedCumulativeMoveIns = useMemo(
        () => getHistoricalProjectedCumulativeMoveIns(historicalProjectedNetMoveIns),
        [historicalProjectedNetMoveIns],
    )

    const historicalNewCOSDelivered = useMemo(
        () => getHistoricalNewCOSDelivered(historicalWeeks, availableUnits),
        [historicalWeeks, availableUnits],
    )

    const historicalTotalUnitsCOS = useMemo(
        () => getHistoricalTotalUnitsCOS(historicalWeeks, availableUnits),
        [historicalWeeks, availableUnits],
    )

    const historicalProjectedOccupancyOnTotalUnitsCOS = useMemo(
        () =>
            getHistoricalProjectedOccupancyOnTotalUnitsCOS(
                historicalProjectedCumulativeMoveIns,
                historicalTotalUnitsCOS,
            ),
        [historicalProjectedCumulativeMoveIns, historicalTotalUnitsCOS],
    )

    useEffect(() => {
        if (
            historicalProjectedOccupancyOnTotalUnitsCOS?.length &&
            historicalProjectedOccupancyOnTotalUnitsCOS.some((item) => item > 1)
        ) {
            setShowHighOccupancyError1(true)
        } else {
            setShowHighOccupancyError1(false)
        }
    }, [historicalProjectedOccupancyOnTotalUnitsCOS])

    useEffect(() => {
        setHistoricalOccupancyForGraph(historicalProjectedOccupancyOnTotalUnitsCOS || [])
    }, [historicalProjectedOccupancyOnTotalUnitsCOS])

    const historicalProjectedOccupancyOnTotalUnitsCount = useMemo(
        () => getHistoricalProjectedOccupancyOnTotalUnitsCount(historicalProjectedCumulativeMoveIns, numberOfUnits),
        [historicalProjectedCumulativeMoveIns, numberOfUnits],
    )

    const historicalNRI = useMemo(
        () => getHistoricalNRI(historicalProjectedOccupancyOnTotalUnitsCOS, GPR, availableUnits, numberOfUnits),
        [historicalProjectedOccupancyOnTotalUnitsCOS, GPR, selectedPricingOption, availableUnits, numberOfUnits],
    )

    const historicalEconomicOccupancy = useMemo(
        () => getHistoricalEconomicOccupancy(historicalNRI, GPR),
        [historicalNRI, GPR, selectedPricingOption],
    )

    const scrollableContainerRef = useRef(null)
    const topScrollbar = useRef(null)
    const bottomScrollbar = useRef(null)

    useEffect(() => {
        if (topScrollbar.current && bottomScrollbar.current) {
            const handleTopScroll = () => (bottomScrollbar.current.scrollLeft = topScrollbar.current.scrollLeft)
            topScrollbar.current.addEventListener('scroll', handleTopScroll)

            return () => {
                if (topScrollbar.current) {
                    topScrollbar.current.removeEventListener('scroll', handleTopScroll)
                }
            }
        }
    }, [topScrollbar.current, bottomScrollbar.current])

    useEffect(() => {
        if (topScrollbar.current && bottomScrollbar.current) {
            const handleBottomScroll = () => (topScrollbar.current.scrollLeft = bottomScrollbar.current.scrollLeft)
            bottomScrollbar.current.addEventListener('scroll', handleBottomScroll)

            return () => {
                if (bottomScrollbar.current) {
                    bottomScrollbar.current.removeEventListener('scroll', handleBottomScroll)
                }
            }
        }
    }, [topScrollbar.current, bottomScrollbar.current])

    const handleProjectedMoveInsChange = (week, value) => {
        ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'projectedMoveIns'], {
            ...projectedMoveIns,
            [week]: value || value === '0' ? +value : null,
        })
    }

    const handleNewCosDeliveredChange = (week, value) => {
        ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'newUnitsDelivered'], {
            ...newCosDelivered,
            [week]: value || value === '0' ? +value : null,
        })
    }

    const historicalConcessions = useMemo(
        () => getHistoricalConcessions(concession, historicalWeeks),
        [concession, historicalWeeks],
    )

    const historicalOtherRentalLosses = useMemo(
        () => getHistoricalOtherRentalLosses(otherRentalLosses, historicalWeeks),
        [otherRentalLosses, historicalWeeks],
    )

    const historicalOtherIncome = useMemo(
        () => getHistoricalOtherIncome(sumOfOtherIncome, historicalProjectedOccupancyOnTotalUnitsCount),
        [sumOfOtherIncome, historicalProjectedOccupancyOnTotalUnitsCount],
    )

    const historicalExpenses = useMemo(
        () => getHistoricalExpenses(historicalWeeks, expenses),
        [historicalWeeks, expenses],
    )

    const historicalNOI = useMemo(
        () => getHistoricalNOI(historicalNRI, historicalOtherIncome, historicalExpenses),
        [historicalNRI, historicalOtherIncome, historicalExpenses],
    )

    const historicalDSCR = useMemo(
        () => getHistoricalDSCR(selectedPricingOption, firstPricingOption, historicalNOI),
        [selectedPricingOption, firstPricingOption, historicalNOI],
    )

    const historicalIoDSCR = useMemo(
        () => getHistoricalIoDSCR(selectedPricingOption, historicalNOI),
        [selectedPricingOption, historicalNOI],
    )

    const rateLockIdx = useMemo(() => {
        if (projectionData?.length && rateLockItem) {
            const idx = projectionData.findIndex((item) => +item.week === +rateLockItem.week)
            if (idx || idx === 0) {
                return idx
            } else {
                return null
            }
        } else {
            return null
        }
    }, [rateLockItem, projectionData])

    const stabilizationIdx = useMemo(() => {
        if (projectionData?.length && stabilizationItem) {
            const idx = projectionData.findIndex((item) => +item.week === +stabilizationItem.week)
            if (idx || idx === 0) {
                return idx
            } else {
                return null
            }
        } else {
            return null
        }
    }, [stabilizationItem, projectionData])

    return (
        <>
            <div className="scrollable-container-ptax" ref={scrollableContainerRef}>
                <div className="fixed-part">
                    <div className="custom-table-wrapper container-fluid">
                        <div className="custom-table-title custom-table-row row" style={{ height: '100px' }}>
                            <div className="col-12">Week of move-in</div>
                        </div>
                        {historicalWeeks.slice(hiddenHistoricalRows).map((week) => (
                            <div className="custom-table-row row" key={week}>
                                <div className="col-12">
                                    {getDateString(+week)}{' '}
                                    {+futureRentRollEarliestWeek === +week && (
                                        <span style={{ paddingLeft: 20 }}>Future Move-Ins</span>
                                    )}
                                </div>
                            </div>
                        ))}
                        {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                            <div className="custom-table-row row" key={i}>
                                <div className="col-12">
                                    {getDateString(+data.week)}
                                    {+data.week === +currentWeek && (
                                        <span style={{ paddingLeft: 20 }}>Current Week</span>
                                    )}
                                    {rateLockItem && rateLockItem.week === data.week && (
                                        <span style={{ paddingLeft: 20 }}>Rate Lock</span>
                                    )}
                                    {stabilizationItem && stabilizationItem.week === data.week && (
                                        <span style={{ paddingLeft: 20 }}>Stabilization</span>
                                    )}
                                    {+futureRentRollEarliestWeek === +data.week && (
                                        <span style={{ paddingLeft: 20 }}>Future Move-Ins</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="scrollable-wrapper">
                    <div className="topScroll" ref={topScrollbar}>
                        <div
                            className="topScrollContent"
                            style={{
                                width: selectedPricingOption ? 184 * 18 : 184 * 9,
                            }}
                        ></div>
                    </div>
                    <div className="scrollable-part" ref={bottomScrollbar}>
                        <div
                            className="custom-table-wrapper container-fluid"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex',
                            }}
                        >
                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>HISTORICAL</div>
                                        <div>MOVE-INS</div>
                                    </div>
                                </div>

                                {historicalMoveIns.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}

                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">{data.historicalMoveIns}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>PROJECTED</div>
                                        <div>MOVE-INS</div>
                                    </div>
                                </div>
                                {historicalProjectedMoveIns.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{0}</div>
                                    </div>
                                ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">
                                            <input
                                                type="number"
                                                onChange={(event) =>
                                                    handleProjectedMoveInsChange(data.week, event.target.value)
                                                }
                                                value={
                                                    data.projectedMoveIns || data.projectedMoveIns === 0
                                                        ? data.projectedMoveIns
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>MOVE-OUTS</div>
                                    </div>
                                </div>
                                {historicalMoveOuts.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">{data.moveOuts}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>PROJECTED</div>
                                        <div>NET</div>
                                        <div>MOVE-INS</div>
                                    </div>
                                </div>
                                {historicalProjectedNetMoveIns.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">{data.projectedNetMoveIns}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>PROJECTED</div>
                                        <div>CUMULATIVE</div>
                                        <div>MOVE-INS</div>
                                    </div>
                                </div>
                                {historicalProjectedCumulativeMoveIns.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">{data.projectedCumulativeMoveIns}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>NEW CO'S</div>
                                        <div>DELIVERED</div>
                                    </div>
                                </div>
                                {historicalNewCOSDelivered.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, idx) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === idx
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === idx
                                                ? 'rate-lock-row'
                                                : idx === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={idx}
                                    >
                                        <div className="col-12">
                                            {hiddenProjectionRows + idx >= weeksToReachFullCO ? (
                                                <div>0</div>
                                            ) : (
                                                <input
                                                    type="number"
                                                    onChange={(event) =>
                                                        handleNewCosDeliveredChange(data.week, event.target.value)
                                                    }
                                                    value={
                                                        data.newCosDelivered || data.newCosDelivered === 0
                                                            ? data.newCosDelivered
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>TOTAL</div>
                                        <div>UNITS CO'D</div>
                                    </div>
                                </div>
                                {historicalTotalUnitsCOS.slice(hiddenHistoricalRows).map((item, i) => (
                                    <div className="custom-table-row row" key={i}>
                                        <div className="col-12">{item}</div>
                                    </div>
                                ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">{data.totalUnitsCos}</div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>PROJECTED</div>
                                        <div>OCCUPANCY</div>
                                        <div>ON TOTAL</div>
                                        <div>UNITS CO'D</div>
                                    </div>
                                </div>
                                {historicalProjectedOccupancyOnTotalUnitsCOS
                                    .slice(hiddenHistoricalRows)
                                    .map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('0.[00]%')}</div>
                                        </div>
                                    ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">
                                            {numeral(data.projectedOccupancyOnTotalUnitsCo).format('0.[00]%')}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div>
                                <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                    <div className="custom-cell year-cell">
                                        <div>PROJECTED</div>
                                        <div>OCCUPANCY</div>
                                        <div>ON TOTAL</div>
                                        <div>UNITS COUNT</div>
                                    </div>
                                </div>
                                {historicalProjectedOccupancyOnTotalUnitsCount
                                    .slice(hiddenHistoricalRows)
                                    .map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('0.[00]%')}</div>
                                        </div>
                                    ))}
                                {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                    <div
                                        className={`custom-table-row row ${
                                            stabilizationIdx !== null && stabilizationIdx === i
                                                ? 'stabilization-row'
                                                : rateLockIdx !== null && rateLockIdx === i
                                                ? 'rate-lock-row'
                                                : i === 0
                                                ? 'current-week-row'
                                                : ''
                                        }`}
                                        key={i}
                                    >
                                        <div className="col-12">
                                            {numeral(data.projectedOccupancyOnTotalUnitsCount).format('0.[00]%')}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>ECONOMIC OCCUPANCY</div>
                                        </div>
                                    </div>
                                    {historicalEconomicOccupancy.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('0.[00]%')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">
                                                {numeral(data.economicOccupancy).format('0.[00]%')}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>CONCESSIONS</div>
                                        </div>
                                    </div>
                                    {historicalConcessions.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('0.[00]%')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{numeral(data.concession).format('0.[00]%')}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>OTHER</div>
                                            <div>RENTAL</div>
                                            <div>LOSSES</div>
                                        </div>
                                    </div>
                                    {historicalOtherRentalLosses.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('$0,0')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">
                                                {numeral(data.otherRentalLosses).format('$0,0')}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>NRI</div>
                                        </div>
                                    </div>
                                    {historicalNRI.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('$0,0')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{numeral(data.NRI).format('$0,0')}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>OTHER</div>
                                            <div>INCOME</div>
                                        </div>
                                    </div>
                                    {historicalOtherIncome.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('$0,0')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{numeral(data.otherIncome).format('$0,0')}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>EXPENSES</div>
                                        </div>
                                    </div>
                                    {historicalExpenses.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('$0,0')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{numeral(data.expenses).format('$0,0')}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>NOI</div>
                                        </div>
                                    </div>
                                    {historicalNOI.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{numeral(item).format('$0,0')}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{numeral(data.NOI).format('$0,0')}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedPricingOption && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>IO DSCR</div>
                                        </div>
                                    </div>
                                    {historicalIoDSCR.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{item?.toFixed(2)}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{data.ioDSCR?.toFixed(2)}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {(selectedPricingOption || firstPricingOption) && (
                                <div>
                                    <div className="custom-table-title custom-table-row" style={{ height: '100px' }}>
                                        <div className="custom-cell year-cell">
                                            <div>DSCR</div>
                                        </div>
                                    </div>
                                    {historicalDSCR.slice(hiddenHistoricalRows).map((item, i) => (
                                        <div className="custom-table-row row" key={i}>
                                            <div className="col-12">{item?.toFixed(2)}</div>
                                        </div>
                                    ))}
                                    {projectionData.slice(hiddenProjectionRows).map((data, i) => (
                                        <div
                                            className={`custom-table-row row ${
                                                stabilizationIdx !== null && stabilizationIdx === i
                                                    ? 'stabilization-row'
                                                    : rateLockIdx !== null && rateLockIdx === i
                                                    ? 'rate-lock-row'
                                                    : i === 0
                                                    ? 'current-week-row'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <div className="col-12">{data.DSCR?.toFixed(2)}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .scrollable-container-ptax {
                    display: flex;
                    background-color: #fff;
                    border: 1px solid $border;
                    .fixed-part {
                        width: 20%;
                        border-right: 1px solid #161c264f;
                        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
                        clip-path: inset(0px -15px 0px 0px);

                        .custom-table-row {
                            width: auto;
                        }
                    }

                    .scrollable-wrapper {
                        width: 80%;
                        text-align: right;

                        .row {
                            margin: initial;
                        }

                        input {
                            background-color: transparent;
                            border: none;
                            border-bottom: 1px solid #000000;
                            outline: none;
                            max-width: 100px;
                            text-align: right;
                            border-radius: initial;
                        }

                        .scrollable-part {
                            overflow-x: scroll;
                            height: 100%;
                            background-color: $row-background-1;
                            .custom-table-row {
                                width: 100%;
                                min-width: fit-content;
                            }

                            &::-webkit-scrollbar {
                                height: 5px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                border-radius: 2px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $gray-blue;
                                border-radius: 2px;
                            }
                        }

                        .topScroll {
                            overflow-x: scroll;
                            overflow-y: hidden;
                            height: 6px;
                            margin-top: -6px;

                            .topScrollContent {
                                height: 6px;
                            }

                            &::-webkit-scrollbar {
                                height: 5px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                border-radius: 2px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $gray-blue;
                                border-radius: 2px;
                            }
                        }
                    }

                    .custom-cell-manual-title {
                        width: 90% !important;
                    }
                    .custom-cell-title {
                        text-transform: uppercase;
                        font-size: 12px !important;
                        color: #676f7a !important;
                    }
                    .custom-table-wrapper {
                        background-color: $row-background-1;
                        border: none;
                        .custom-table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            padding: 7px;
                            display: flex;
                            flex-wrap: nowrap;
                            .custom-cell {
                                padding: 0;
                                min-width: 170px;
                                width: 170px;
                                display: flex;
                                align-items: center;
                            }
                            .year-cell {
                                display: block;
                            }
                        }
                        .custom-table-row {
                            font-size: 14px;
                            padding: 7px;
                            align-items: center;
                            display: flex;
                            flex-wrap: nowrap;
                            height: 40px;
                            &:nth-of-type(2n) {
                                background-color: $row-background-2;
                            }
                            .custom-cell {
                                padding: 0 10px;
                                min-width: 170px;
                                width: 170px;

                                .biggerFont {
                                    font-size: 1.25rem;
                                }
                            }
                        }
                    }
                }

                .stabilization-row {
                    background: #44e1831a !important;
                }

                .rate-lock-row {
                    background: #4486ff17 !important;
                }

                .current-week-row {
                    background: #d9e0e587 !important;
                }
            `}</style>
        </>
    )
}
