import { CashFlow, OriginationModelRedux } from '../../../../../../../store/types/OriginationModelRedux'
import { OriginationModelReworked, SensitivityChartData } from '@generated/graphql'
import numeral from 'numeral'

import { getMetrics, regenerateCashFlow } from 'origination-model'
import { getCFRow } from './irrSensitivity'
import { ReduxHelper } from '../../../../../../../store'
const cloneDeep = require('lodash.clonedeep')

export function exitPriceSensitivity(cashFlow: CashFlow, model: OriginationModelRedux): SensitivityChartData {
    //Exit cap rate- ( 5.5%- 7%, increase factor 0.25%)
    const exitCapRates = []
    for (let i = 0.055; i <= 0.07; i += 0.0025) {
        exitCapRates.push(i)
    }
    //Rent growth- (1%-4% , increase factor 0.5%)
    const rentGrowth = []
    for (let i = 0.01; i <= 0.04; i += 0.005) {
        rentGrowth.push(+i.toFixed(3))
    }
    const data = []
    for (const row in rentGrowth) {
        const tableRow = []
        for (const col in exitCapRates) {
            tableRow.push(calcCell(cashFlow, model, rentGrowth[row], exitCapRates[col]))
        }
        data.push(tableRow)
    }
    const out = {
        xAxisName: 'Exit cap rate',
        yAxisName: 'Rent growth',
        rowTitles: rentGrowth.map((el) => numeral(el).format('0.[00]%')),
        columnTitles: exitCapRates.map((el) => numeral(el).format('0.[00]%')),
        rowValues: rentGrowth,
        columnValues: exitCapRates,
        data,
    }

    return out
}
function calcCell(cashFlow: any, model: any, rentGrowth: number, exitCapRate: number) {
    let newModel: OriginationModelReworked = cloneDeep(model)
    const years = newModel.exitAssumption.saleYearAnalysisPeriod
    newModel.underwritingAssumption.organicRentGrowth = new Array(years).fill(rentGrowth)
    newModel.exitAssumption.exitCapRate = exitCapRate
    const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
    let newCF = regenerateCashFlow({ cashFlow, model: newModel, unitMix: unitMix })
    const proceedFromSaleRow = getCFRow(newCF.other, 'proceedsFromSale')
    const salePrice = proceedFromSaleRow.years[years - 1]
    newCF = null
    newModel = null
    return {
        value: numeral(salePrice).format('$0,0'),
        severity: 0,
    }
}
