import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { OriginationModelReworked } from '@generated/graphql'
import { WidgetWrapper } from '../../../../components/WidgetWrapper'
import { PercentBlock } from '../Expenses/PercentBlock'
import numeral from 'numeral'
import { getLenderFee, getTotalUses } from 'origination-model'
export function UsesAndSources() {
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)

    const totalUses = useMemo(() => {
        return getTotalUses(model)
    }, [model])
    const totalSources = useMemo(() => {
        return (
            model?.sources.seniorDebt +
            (model?.sources?.mezzanine > 0 ? model?.sources?.mezzanine : 0) +
            model?.sources.totalEquity
        )
    }, [model])

    return (
        <div className={'uses-and-sources'}>
            <WidgetWrapper style={{ padding: 0 }} title={'Uses and Sources'} showProgress={!model || !cashFlow}>
                <HeaderBlock title={'Total Uses'} value={numeral(totalUses).format('$0,0')} />
                <div className={'delimiter'} />
                <HeaderBlock title={'Total Sources'} value={numeral(totalSources).format('$0,0')} />
                <UsesBlock />
                <SourcesBlock />
            </WidgetWrapper>
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .uses-and-sources {
                        /* without this property css is not processed it right way*/
                        display: block;
                        .delimiter {
                            background: #d9e0e5;
                            opacity: 0.5;
                            grid-row-start: 1;
                            grid-row-end: 3;
                            grid-column: 2;
                        }
                        :global(.widget-body) {
                            display: grid;
                            grid-template-columns: 1fr 1px 1fr;
                            grid-template-rows: auto auto;
                            gap: 20px 20px;
                        }
                    }
                `}
            </style>
        </div>
    )
}
type HeaderBlockProps = {
    title: string
    value: string
}
function HeaderBlock(props: HeaderBlockProps) {
    return (
        <div>
            <div className={'title'}>{props.title}</div>
            <div className={'value'}>{props.value}</div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .title {
                    color: $hint-text-color;
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 5px;
                }
                .value {
                    color: $default-text-color;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 27px;
                }
            `}</style>
        </div>
    )
}
function UsesBlock() {
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)
    const isNewDev = project?.type && project.type == 'miltifamily.new_dev'
    const usesList = useMemo(() => {
        if (!model) return []
        const other = []
        if (model?.uses?.other) {
            for (const o of model?.uses?.other) other.push({ name: o.title, value: o.abs, display: true })
        }
        return [
            { name: isNewDev ? 'Land Cost' : 'Purchase price', value: model?.uses.purchasePrice, display: true },
            { name: 'Transaction Сosts', value: model?.uses.transactionCosts, display: !isNewDev },
            { name: 'Lender Fee', value: getLenderFee(model), display: !isNewDev },
            { name: 'CapEx', value: model?.uses.renovationProgram, display: !isNewDev },
            { name: 'Pre-Funded', value: model?.uses.preFunded, display: !isNewDev },

            { name: 'Hard Cost', value: model?.uses.hardCost, display: isNewDev },
            { name: 'Soft  Cost', value: model?.uses.softCost, display: isNewDev },
            { name: 'Financing Cost', value: model?.uses.financingCost, display: isNewDev },
        ].concat(other)
    }, [model])
    return (
        <div>
            <div className={'title'}>Uses</div>
            <div className={'details'}>
                {usesList
                    .filter((item) => item.display)
                    .map((item, ind) => (
                        <React.Fragment key={ind}>
                            <div className={'label'}>{item.name}</div>
                            <div className={'value'}>{numeral(item.value).format('$0,0')}</div>
                        </React.Fragment>
                    ))}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .details {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    margin-top: 16px;
                    grid-row-gap: 16px;
                }
                .title {
                    color: $default-text-color;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                }
                .label {
                    color: $default-text-color;
                    font-size: 15px;
                    line-height: 18px;
                }
                .value {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: right;
                }
            `}</style>
        </div>
    )
}
function SourcesBlock() {
    const model: OriginationModelReworked = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const sourcesList = useMemo(() => {
        if (!model) return []
        const out = [
            {
                name: 'Senior Debt',
                value: model?.sources.seniorDebt,
                percent: 0,
                tooltipPercent: numeral(model?.sources.seniorDebtPercent).format('0%'),
            },
            {
                name: 'Mezzanine',
                value: model?.sources.mezzanine,
                percent: 0,
                tooltipPercent: numeral(model?.sources.mezzanine).format('0%'),
            },
            {
                name: 'Total Equity',
                value: model?.sources.totalEquity,
                percent: 0,
                tooltipPercent: numeral(model?.sources.totalEquityPercent).format('0%'),
            },
        ]
        const maxV = Math.max(...out.map((item) => item.value))
        for (const item of out) {
            item.percent = Math.round((item.value / maxV) * 100)
        }
        return out
    }, [model])
    return (
        <div>
            <div className={'title'}>Sources</div>
            <div className={'details'}>
                {sourcesList.map((item, ind) => (
                    <React.Fragment key={ind}>
                        <div className={'label'}>{item.name}</div>
                        <div className={'value'}>{numeral(item.value).format('$0,0')}</div>
                        <PercentBlock
                            id={`Sources_${ind}`}
                            value={item.value}
                            label={item.name}
                            percentWidth={item.percent}
                            percentValue={item.tooltipPercent}
                        />
                    </React.Fragment>
                ))}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .details {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto;
                    margin-top: 16px;
                    grid-row-gap: 16px;
                    grid-column-gap: 10px;
                }
                .title {
                    color: $default-text-color;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                }
                .label {
                    color: $default-text-color;
                    font-size: 15px;
                    line-height: 18px;
                    grid-column: 1;
                }
                .value {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: right;
                }
            `}</style>
        </div>
    )
}
