import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { PropertyDetails } from './widgets/PropertyDetails'
import { ConstructionAssumption } from './widgets/ConstructionAssumption'
import { ValuationComparison } from './widgets/ValuationComparison'
import { RefinanceAssumption } from './widgets/RefinanceAssumption'
import { ValuationAssumption } from './widgets/ValuationAssumption'
import { LoanInfo } from './widgets/LoanInfo'
import { Trends } from './widgets/Trends'
import { PriorLoans } from './widgets/PriorLoans/PriorLoans'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../store'
import { useRouter } from 'next/router'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { useNotificationSubscription } from '../../../../../utils/notification'
import { TAB } from '../tabs'
import { FannieQuoteEditor } from './quote/FannieQuoteEditor'
import { Pricing } from './Pricing'
import { CashFlowCommonButtons } from '../../components/CashFlowCommonButtons/CashFlowCommonButtons'

type Props = any

export function FannieTab(props: Props) {
    const tab = props.tab
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const isPricingLoaded = useSelector((state: ReduxStoreState) => state.lender.isPricingLoaded)
    const loadData = useCallback(() => {
        //console.log('loadData')
        if (projectId) {
            SagaHelper.run(['lenderPricing', 'getData'], { projectId: projectId })
            SagaHelper.run(['lenderFannieQuotes', 'getData'], { projectId: projectId })
        }
    }, [projectId])

    const [isNM, setIsNM] = useState(ReduxHelper.store.getState()?.user.isAdmin || false)
    useEffect(() => {
        const isAdmin = ReduxHelper.store.getState()?.user.isAdmin
        if (isAdmin) setIsNM(true)
        if (localStorage) {
            const accountName = localStorage.getItem('accountName')
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [project])

    useEffect(() => {
        if (!isPricingLoaded) loadData()
    }, [projectId, isPricingLoaded])
    //Reload data if project was updated in another window
    // useNotificationSubscription('PROJECT_CASH_FLOW_AND_MODEL_UPDATED', async (notification) => {
    //     if (notification.payload.projectId == projectId) {
    //         await SagaHelper.run(['lenderPricing', 'clearData'])
    //         await SagaHelper.run(['lenderFannieQuotes', 'clearData'])
    //         loadData()
    //     }
    // })

    const issupplamental = useMemo(() => {
        return project?.type == 'miltifamily.value_add_supplemental'
    }, [project?.type])
    return (
        <>
            <CashFlowCommonButtons saveDeal={props.saveDeal} />
            <div className="PricingTabWrapper">
                {tab == TAB.PRICING && (
                    <>
                        <div className="dealanalyticsWrapper">
                            <Pricing isNM={isNM} />
                        </div>
                    </>
                )}
                {tab == TAB.QUOTE_EDITOR && (
                    <>
                        <div className="dealanalyticsWrapper">
                            <FannieQuoteEditor saveDeal={props.saveDeal} />
                        </div>
                    </>
                )}
                {tab == TAB.PRICING_DEAL_ANALYTICS && (
                    <>
                        <PropertyDetails />
                        <div style={{ display: 'flex' }}>
                            <ValuationComparison />
                            <ValuationAssumption />
                            <LoanInfo />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <RefinanceAssumption />
                            {!issupplamental && <ConstructionAssumption />}
                        </div>

                        {project?.type === 'miltifamily.value_add_supplemental' && (
                            <>
                                <Trends />
                                <PriorLoans />
                            </>
                        )}
                    </>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .PricingTabWrapper {
                    margin-top: 20px;
                }
            `}</style>
        </>
    )
}
