import React, { useCallback, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
type Props = {
    color: 'red' | 'green' | 'yellow'
    className?: string
    style?: React.CSSProperties
}
export function TrafficLights(props: Props) {
    const colors = {
        green: '#67D554',
        yellow: '#a39900',
        red: '#800505',
    }
    // Build a spring and catch its ref
    const [spring, set] = useSpring(() => ({
        config: {
            duration: 500,
        },
        color: colors[props.color],
        from: {
            color: '#B8C3CB',
        },
    }))
    const repeatAnimation = useCallback(() => {
        set({ color: '#B8C3CB' }).then(() => {
            set({ color: colors[props.color] })
        })
    }, [set])
    return (
        <svg
            width="58px"
            height="199px"
            viewBox="0 0 58 199"
            version="1.1"
            className={props.className}
            style={props.style}
            onMouseEnter={repeatAnimation}
        >
            <title>traffic-lights</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="dashboard2">
                    <g id="traffic-lights">
                        <g id="light">
                            <rect id="Rectangle" fill="#d9e0e5" x="0" y="0" width="58" height="151" rx="29" />
                            <animated.circle
                                id="green"
                                fill={props.color == 'green' ? spring.color : '#B8C3CB'}
                                cx="29"
                                cy="122.652174"
                                r="20"
                            />
                            <animated.circle
                                id="yellow"
                                fill={props.color == 'yellow' ? spring.color : '#B8C3CB'}
                                cx="29"
                                cy="74.826087"
                                r="20"
                            />
                            <animated.circle
                                id="red"
                                fill={props.color == 'red' ? spring.color : '#B8C3CB'}
                                cx="29"
                                cy="27"
                                r="20"
                            />
                        </g>
                        <rect id="Rectangle" fill="#d9e0e5" x="23" y="154" width="12" height="45" />
                    </g>
                </g>
            </g>
        </svg>
    )
}
