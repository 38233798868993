import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'

type Props = {
    onClick: (any) => any
}

export const ModifyButton = (props: Props) => {
    const { onClick } = props
    return (
        <div className="toggle-button" onClick={onClick}>
            Modify Model
            <span className="fa-wrapper">
                <FontAwesomeIcon icon={faSlidersH} width={15} height={15} />
            </span>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .toggle-button {
                    position: fixed;
                    background-color: $blue;
                    color: $white;
                    cursor: pointer;
                    left: -63px;
                    top: 200px;
                    font-size: 15px;
                    transform: rotate(-90deg);
                    padding: 5px 15px;
                    width: 155px;
                    .fa-wrapper {
                        color: $white;
                        padding-left: 10px;
                    }
                }
            `}</style>
        </div>
    )
}
