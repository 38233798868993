import React, { useState, useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import SelectBox from 'devextreme-react/select-box'
import { ReduxStoreState, ReduxHelper } from '../../../../../../../store'
import { Table } from './Table'
import { Assumptions } from './Assumptions'
import { RateAssumptions } from './RateAssumptions'
import { LoanDetails } from './LoanDetails'
import { SupplementalMaturityBalance } from './SupplementalMaturityBalance'
import { KPI } from './KPI'
import { defaultRefinanceAssumptions } from './lib'
import { FormContext } from '../../../../Dashboard'
import { isAnyAlert } from '../../main/lib'

enum ARMType {
    structured = 'Structured',
    notStructured = 'ARM 7-6',
}

type Props = any
export const RefinanceAssumptions = React.memo((props: Props) => {
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const [autoSelectedPricingOptionId, setAutoSelectedPricingOptionId] = useState(null)

    const isSupplemental = useMemo(() => project?.type == 'miltifamily.value_add_supplemental', [project?.type])

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const isArm = useMemo(
        () =>
            generalInputs?.loanDetails?.ARMType === ARMType.structured ||
            generalInputs?.loanDetails?.ARMType === ARMType.notStructured,
        [generalInputs?.loanDetails?.ARMType],
    )

    const userSelectedOptionId = model?.fannieExitScenarioRefinance?.selectedOptionId

    const allSelectedPricingOptions = useMemo(() => {
        const pricingOptions = pricing?.fanniePricing?.pricingOptions
        if (!!pricingOptions && !!pricingOptions?.length) {
            const selectedOptions = pricingOptions
                .filter((item) => item.isSelected)
                .map((item) => ({ label: item.name, value: item.id }))

            if (!!selectedOptions.length) {
                setAutoSelectedPricingOptionId(selectedOptions[0].value)
                return selectedOptions
            } else {
                const savedOption = pricingOptions.find(
                    (option) =>
                        (userSelectedOptionId || userSelectedOptionId === 0) && option.id === userSelectedOptionId,
                )
                if (savedOption) {
                    setAutoSelectedPricingOptionId(savedOption.id)
                    return [
                        {
                            label: savedOption.name,
                            value: savedOption.id,
                        },
                    ]
                } else {
                    const firstOption = [
                        {
                            label: pricingOptions[0].name,
                            value: pricingOptions[0].id,
                        },
                    ]
                    setAutoSelectedPricingOptionId(pricingOptions[0].id)
                    return firstOption
                }
            }
        } else {
            setAutoSelectedPricingOptionId(null)
            return []
        }
    }, [pricing?.fanniePricing?.pricingOptions, userSelectedOptionId])

    const selectedPricingOption = useMemo(() => {
        const pricingOptions = pricing?.fanniePricing?.pricingOptions
        const isUserSelectedOptionAvailable =
            (!!userSelectedOptionId || userSelectedOptionId === 0) &&
            !!allSelectedPricingOptions &&
            !!allSelectedPricingOptions?.length &&
            !!allSelectedPricingOptions.find((option) => option.value === userSelectedOptionId)

        if (isUserSelectedOptionAvailable) {
            return pricingOptions?.find((option) => option.id === userSelectedOptionId)
        } else {
            ReduxHelper.setIn(['lender', 'originationModel', 'fannieExitScenarioRefinance', 'selectedOptionId'], null)
            const isAutoSelectedOptionAvailable =
                (!!autoSelectedPricingOptionId || autoSelectedPricingOptionId === 0) &&
                !!allSelectedPricingOptions &&
                !!allSelectedPricingOptions?.length &&
                !!allSelectedPricingOptions.find((option) => option.value === autoSelectedPricingOptionId)

            if (isAutoSelectedOptionAvailable) {
                return pricingOptions?.find((option) => option.id === autoSelectedPricingOptionId)
            } else {
                return null
            }
        }
    }, [
        allSelectedPricingOptions,
        pricing?.fanniePricing?.pricingOptions,
        autoSelectedPricingOptionId,
        userSelectedOptionId,
    ])

    const handlePricingOptionsChange = (event) => {
        if (formVisited?.refAssumptions === false) {
            setFormVisited((prevState) => ({
                ...prevState,
                refAssumptions: true,
            }))
        }
        if (!formModified.refAssumptions) {
            setFormModified((prevState) => ({
                ...prevState,
                refAssumptions: true,
            }))
        }

        if (formVisited?.rateAssumptions === false) {
            setFormVisited((prevState) => ({
                ...prevState,
                rateAssumptions: true,
            }))
        }
        if (!formModified.rateAssumptions) {
            setFormModified((prevState) => ({
                ...prevState,
                rateAssumptions: true,
            }))
        }

        ReduxHelper.setIn(
            ['lender', 'originationModel', 'fannieExitScenarioRefinance', 'selectedOptionId'],
            event.value,
        )
    }

    return (
        <div className={'sectionWrapper'}>
            <div className={'topSection'}>
                <div
                    className={`selectWrapper ${
                        !!selectedPricingOption
                            ? isAnyAlert(selectedPricingOption)
                                ? 'redBottomBorder'
                                : 'blueBottomBorder'
                            : ''
                    }`}
                >
                    <div className={'selectLabel'}>Loan Scenario</div>
                    <div className={'selectbox'}>
                        {props.page === 0 && (
                            <SelectBox
                                value={selectedPricingOption?.id}
                                valueExpr={'value'}
                                displayExpr={'label'}
                                placeholder={''}
                                dataSource={allSelectedPricingOptions}
                                onValueChanged={handlePricingOptionsChange}
                                name={'pricingOptionsSelect'}
                            />
                        )}
                        {props.page === 1 && (
                            <SelectBox
                                value={selectedPricingOption?.id}
                                valueExpr={'value'}
                                displayExpr={'label'}
                                placeholder={''}
                                dataSource={allSelectedPricingOptions}
                                onValueChanged={handlePricingOptionsChange}
                                name={'pricingOptionsSelect'}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={'middleSection'}>
                {props.page === 0 && <Assumptions page={0} />}
                {props.page === 1 && <Assumptions page={1} />}

                {props.page === 0 && <RateAssumptions isSupplemental={isSupplemental} page={0} />}
                {props.page === 1 && <RateAssumptions isSupplemental={isSupplemental} page={1} />}

                {props.page === 0 && <LoanDetails selectedPricingOption={selectedPricingOption} page={0} />}
                {props.page === 1 && <LoanDetails selectedPricingOption={selectedPricingOption} page={1} />}

                {props.page === 0 && isSupplemental && <SupplementalMaturityBalance page={0} />}
                {props.page === 1 && isSupplemental && <SupplementalMaturityBalance page={1} />}
            </div>
            <div className={'bottomSection'}>
                <p className={'bottomSectionTitle'}>Refinance Assumptions Analysis</p>
                {props.page === 0 && <KPI page={0} />}
                {props.page === 1 && <KPI page={1} />}

                {props.page === 0 && (
                    <Table
                        defaultData={defaultRefinanceAssumptions}
                        selectedPricingOption={selectedPricingOption}
                        isSupplemental={isSupplemental}
                        page={0}
                    />
                )}
                {props.page === 1 && (
                    <Table
                        defaultData={defaultRefinanceAssumptions}
                        selectedPricingOption={selectedPricingOption}
                        isSupplemental={isSupplemental}
                        page={1}
                    />
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .sectionWrapper {
                    width: 100%;
                }
                .topSection {
                    display: flex;
                    padding-left: 20px;
                    padding-bottom: 30px;
                }
                .middleSection {
                    display: flex;
                    max-width: 2000px;
                    gap: 20px;
                }
                .selectbox {
                    background-color: #fff;
                }
                .selectWrapper {
                    width: 350px;
                    margin-top: 20px;
                    padding-bottom: 5px;
                }
                .bottomSection {
                    background-color: #fff;
                }
                .bottomSectionTitle {
                    font-family: 'Inter';
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #d9e0e5;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                .selectWrapper.redBottomBorder {
                    border-bottom: 4px solid #f14b44;
                }
                .selectWrapper.blueBottomBorder {
                    border-bottom: 4px solid #4486ff;
                }
                .selectLabel {
                    color: #687893;
                    margin-bottom: 10px;
                    height: 25px;
                }
            `}</style>
        </div>
    )
})
