import React, { useCallback, useMemo } from 'react'
import { CashFlowRowGraphQl } from '@generated/graphql'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'
import { SagaHelper } from '../../../../../../../../store/sagaHelper'
import arrowExpand from './arrow-expand.svg'
import { orgenizeCFForPresentation } from 'origination-model'
type Props = any
const cloneDeep = require('lodash.clonedeep')
export const RowTitles = React.memo(function RowTitles(props: Props) {
    const storCashFlow = useSelector((store: ReduxStoreState) => store.lender.originationModel?.cashFlow)
    const cashFlow = useMemo(() => {
        return orgenizeCFForPresentation({
            cashFlow: storCashFlow,
        })
    }, [storCashFlow])
    //console.log('RowTitles ', cashFlow)
    //   cashFlow.totalPotentialGrossRent.title = 'Total Potential Gross rent'
    const cashFlowBeforeDebtService = useMemo(() => {
        const beforeRow: CashFlowRowGraphQl = cloneDeep(cashFlow.cashFlowAfterDebtService)
        beforeRow.title = 'Cash Flow Before Debt Service'
        beforeRow.id = 'cashFlowBeforeDebtService'
        return beforeRow
    }, [cashFlow])

    const totalExpensesRatio = useMemo(
        () => ({ id: 'totalExpensesRatio', title: 'Total expenses ratio (from effective gross income)' }),
        [],
    )
    return (
        <>
            <div className={'rental-income'}>Rental Income</div>
            <RowGroup rows={cashFlow.rentalIncome} isPDF={props.isPDF} />
            <RowTitle row={cashFlow.totalPotentialGrossRent} type={'bold'} />
            <RowGroup rows={cashFlow.rentalLoss} isPDF={props.isPDF} />
            <RowTitle row={cashFlow.effectiveGrossRent} type={'total-blue'} />
            <RowGroup rows={cashFlow.otherIncome} isPDF={props.isPDF} />
            <RowTitle row={cashFlow.effectiveGrossIncome} type={'total-green'} />
            <div className={'operating-expenses'}>Operating Expenses</div>
            <RowGroup rows={cashFlow.operatingExpenses} isPDF={props.isPDF} />
            <RowTitle row={cashFlow.totalOperatingExpenses} type={'total-blue'} />
            <RowTitle row={totalExpensesRatio} type={'total'} />
            <RowTitle row={cashFlow.noi} type={'total'} />
            {!props.isPDF && (
                <>
                    <RowGroup
                        rows={cashFlow.other.filter(
                            (row) => row.id != 'debtServiceInterest' && row.id != 'debtServiceInterestMez',
                        )}
                    />
                    <RowTitle row={cashFlowBeforeDebtService} type={'total-green'} />
                </>
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .rental-income {
                    font-size: 20px;
                    line-height: 24px;
                    color: $default-text-color;
                    margin-left: 19px;
                    height: 24px;
                }
                .operating-expenses {
                    height: 100px;
                    font-size: 20px;
                    line-height: 24px;
                    color: $default-text-color;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 19px;
                }
            `}</style>
        </>
    )
})
type TypesList = 'default' | 'bold' | 'total' | 'total-large' | 'total-blue' | 'total-green'
type RowGroupProps = {
    rows: CashFlowRowGraphQl[]
    type?: TypesList
    isPDF?: boolean
}
const RowGroup = React.memo(function RowGroup(props: RowGroupProps) {
    return (
        <>
            {props.rows.map((row, i) => (
                <RowTitle row={row} key={`${i}`} type={props.type} isPDF={props.isPDF} />
            ))}
        </>
    )
})
type RowTitleProps = {
    row: CashFlowRowGraphQl
    type?: TypesList
    isPDF?: boolean
}
const RowTitle = React.memo(function RowTitle(props: RowTitleProps) {
    const type = props.type || ''
    const expanded = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.rowsExpanded[props.row.id],
    )
    const selectedIds = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.selectedRowIds)
    const toggle = useCallback(() => {
        if (!props.row.subRows) return
        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowsExpanded', props.row.id], !expanded)
    }, [expanded])

    const idHover = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.rowIdHover)
    const handleMouseIn = useCallback((id) => {
        ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowIdHover'], id)
    }, [])
    const handleMouseOut = useCallback(
        (id) => {
            if (idHover == id) ReduxHelper.setIn(['lenderDashboard', 'lenderCashFlowTab', 'rowIdHover'], null)
        },
        [idHover],
    )
    const toggleRow = useCallback((id) => {
        SagaHelper.run(['lenderDashboard', 'lenderCashFlowToggleSelectedRow'], { rowId: id }).finally()
    }, [])
    return (
        <>
            <div
                className={`row-title ${type} ${props.row.subRows ? 'sub-rows' : ''}  
                ${props.row.id == idHover ? 'hover' : ''} ${
                    expanded || selectedIds.includes(props.row.id) ? 'expanded' : ''
                }`}
                id={`row-title-${props.row.id}`}
                onMouseEnter={() => handleMouseIn(props.row.id)}
                onMouseLeave={() => handleMouseOut(props.row.id)}
                onClick={() => toggleRow(props.row.id)}
            >
                {!props.isPDF && props.row.subRows && (
                    <img className={expanded ? 'expanded' : ''} src={arrowExpand} onClick={toggle} />
                )}
                <div onClick={toggle}>{props.row.title}</div>
            </div>
            {expanded &&
                props.row.subRows.map((subRow, ind) => {
                    const subId = `${props.row.id}-${ind}`
                    return (
                        <div
                            className={`subrow row-title ${subId == idHover ? 'hover' : ''}
                            ${selectedIds.includes(subId) ? 'expanded' : ''}
                            `}
                            id={`subrow-title-${props.row.id}-${ind}`}
                            key={ind}
                            onMouseEnter={() => handleMouseIn(subId)}
                            onMouseLeave={() => handleMouseOut(subId)}
                            onClick={() => toggleRow(subId)}
                        >
                            {subRow.title}
                        </div>
                    )
                })}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .row-title {
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    align-items: center;
                    position: relative;
                    > div {
                        margin-left: 50px;
                        cursor: ${props.row.subRows ? 'pointer' : 'auto'};
                    }
                    &.hover {
                        background: #f0f0f2;
                    }
                    &.expanded {
                        background: #e1e2e5;
                    }
                }

                .subrow {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 70px;
                    word-wrap: break-word;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;
                }
                .bold {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    span {
                        margin-left: 10px;
                    }
                }
                .total {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    span {
                        margin-left: 10px;
                    }
                }
                .total-blue {
                    @extend .total;
                    background: rgba(68, 134, 255, 0.1);
                }
                .total-green {
                    @extend .total;
                    background: rgba(68, 225, 131, 0.1);
                }

                img {
                    position: absolute;
                    top: 14px;
                    left: 20px;
                    cursor: pointer;
                    transition-duration: 200ms;
                    transition-property: transform;
                }
                img.expanded {
                    transform: rotate(180deg);
                }
            `}</style>
        </>
    )
})
