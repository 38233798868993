import { useState, useMemo, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxHelper, ReduxStoreState } from 'store'
import { NumberField } from '../../../../../dashboard/components/NumberField'
import { Table } from './Table'
import { Submarket } from './Submarket'
import DateBox from 'devextreme-react/date-box'
import SelectBox from 'devextreme-react/select-box'

export const StabilizationProjection = ({
    avgWeeklyMoveIns,
    rentRollGroupedByLeaseStartDate,
    rentRollByLeaseExpireDate,
    numberOfUnits,
    projectionData,
    weeksToReachFullCO,
    GPR,
    concession,
    otherRentalLosses,
    sumOfOtherIncome,
    expenses,
    selectedPricingOption,
    futureRentRollEarliestWeek,
    rateLockItem,
    stabilizationItem,
    currentWeek,
    futureRentRollByLeaseStartDate,
    futureRentRollByLeaseExpireDate,
    setHistoricalWeeksForGraph,
    setHistoricalOccupancyForGraph,
    showNoHistoricalError,
    showReachAllUnitsAvailableDateError,
    showTotalUnitsError,
    setShowGraph,
    showGraph,
    setShowHighOccupancyError1,
    showHighOccupancyError1,
    showHighOccupancyError2,
    firstPricingOption
}) => {
    const leaseUpData = useSelector((state: ReduxStoreState) => state.lender.leaseUp)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)

    const leaseUp = leaseUpData?.data
    const submarketData = leaseUpData?.submarketData

    const [showCustomDateBox, setShowCustomDateBox] = useState(!!leaseUp?.projectionCustomDate)
    const [showDealPicker, setShowDealPicker] = useState(!!leaseUp?.pricingOptionId || leaseUp?.pricingOptionId === 0)

    const quotesSelectboxOptions = useMemo(() => {
        const pricingOptions = pricing?.fanniePricing?.pricingOptions
        if (!!pricingOptions && !!pricingOptions?.length) {
            return pricingOptions.map((item) => ({ label: item.name, value: item.id }))
        } else {
            return []
        }
    }, [pricing?.fanniePricing?.pricingOptions])

    useEffect(() => {
        if (
            showDealPicker &&
            !leaseUp?.pricingOptionId &&
            leaseUp?.pricingOptionId !== 0 &&
            quotesSelectboxOptions?.length
        ) {
            ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'pricingOptionId'], quotesSelectboxOptions[0].value)
        }
    }, [showDealPicker, quotesSelectboxOptions, leaseUp])

    const handleInputChange = (event) => {
        const { name, value } = event.target
        // @ts-ignore
        ReduxHelper.setIn(['lender', 'leaseUp', 'data', `${name}`], numeral(value).value())
    }

    const handlePricingOptionsChange = (event) => {
        ReduxHelper.setIn(['lender', 'leaseUp', 'data', 'pricingOptionId'], event.value)
    }

    return (
        <div className="projection-wrapper">
            <div className="title-section">Stabilization Projection</div>
            <div className="controls-and-submarket-section">
                <div className="controls-section">
                    <Form
                        onSubmit={() => {}}
                        render={() => {
                            return (
                                <>
                                    <div className="top-section">
                                        <div className="inputs-section">
                                            <div className="input-item">
                                                <div className="input-label">Projected Weekly Move-InS</div>
                                                <div className="input-wrapper">
                                                    <input
                                                        type="number"
                                                        name="avgProjectedWeeklyMoveIns"
                                                        onChange={handleInputChange}
                                                        value={leaseUp?.avgProjectedWeeklyMoveIns || ''}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault()
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-item">
                                                <div className="input-label">Historical Weekly Move-InS</div>
                                                <div className="input-wrapper">
                                                    <input
                                                        readOnly
                                                        value={avgWeeklyMoveIns || '-'}
                                                        style={{ border: 'none', cursor: 'initial' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-item">
                                                <div className="input-label">Stabilization Occupancy Rate</div>
                                                <div className="input-wrapper">
                                                    <NumberField
                                                        name="stabilizationOccupancyRate"
                                                        numberFormat={'percent'}
                                                        onChange={handleInputChange}
                                                        value={
                                                            leaseUp?.stabilizationOccupancyRate
                                                                ? leaseUp?.stabilizationOccupancyRate * 100
                                                                : ''
                                                        }
                                                        containerClassName="number-field-container"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="submarket-section"></div>
                                    </div>
                                    <div className="middle-section">
                                        <div className="left-controls">
                                            <div className="custom-date-checkbox-wrapper">
                                                <input
                                                    id={'custom-date-checkbox'}
                                                    checked={showCustomDateBox}
                                                    type={'checkbox'}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setShowCustomDateBox(false)
                                                            ReduxHelper.setIn(
                                                                ['lender', 'leaseUp', 'data', 'projectionCustomDate'],
                                                                null,
                                                            )
                                                        } else {
                                                            setShowCustomDateBox(true)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="custom-date-checkbox">Show from custom date</label>
                                            </div>
                                            <DateBox
                                                className={'date-field'}
                                                value={leaseUp?.projectionCustomDate}
                                                type="date"
                                                onValueChanged={(e) => {
                                                    ReduxHelper.setIn(
                                                        ['lender', 'leaseUp', 'data', 'projectionCustomDate'],
                                                        e.value,
                                                    )
                                                }}
                                                displayFormat="shortDate"
                                                useMaskBehavior={true}
                                                disabled={!showCustomDateBox}
                                                placeholder="Select custom date"
                                            />
                                        </div>

                                        <div className="right-controls">
                                            <div className="loan-calculations-checkbox-wrapper">
                                                <input
                                                    id={'loan-calculations-checkbox'}
                                                    checked={showDealPicker}
                                                    type={'checkbox'}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setShowDealPicker(false)
                                                            ReduxHelper.setIn(
                                                                ['lender', 'leaseUp', 'data', 'pricingOptionId'],
                                                                null,
                                                            )
                                                        } else {
                                                            setShowDealPicker(true)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="loan-calculations-checkbox">
                                                    Include Loan Scenario Calculations
                                                </label>
                                            </div>
                                            {showDealPicker && (
                                                <SelectBox
                                                    value={
                                                        leaseUp?.pricingOptionId || quotesSelectboxOptions?.[0]?.value
                                                    }
                                                    valueExpr={'value'}
                                                    displayExpr={'label'}
                                                    placeholder={''}
                                                    dataSource={quotesSelectboxOptions}
                                                    onValueChanged={handlePricingOptionsChange}
                                                    name={'pricingOptionsSelect'}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        }}
                    />
                </div>

                <div className="submarket-section">
                    {submarketData && (
                        <Submarket submarketData={submarketData} showSubmarket={!!leaseUp?.showSubmarketInfo} />
                    )}
                </div>
            </div>

            {showNoHistoricalError && (
                <div className="error-message">
                    No historical data. Can't find any lease start date in the RentRoll.
                </div>
            )}

            {showReachAllUnitsAvailableDateError && (
                <div className="error-message">Incorrect values. Can't reach 100% CO's date in the table.</div>
            )}

            {!stabilizationItem && <div className="error-message">Incorrect values. Can't reach stabilization.</div>}

            {showTotalUnitsError && (
                <div className="error-message">
                    Incorrect values. Total units in the table are lower or higher than actual number of units (
                    {numberOfUnits}).
                </div>
            )}

            {(showHighOccupancyError1 || showHighOccupancyError2) && (
                <div className="error-message">Incorrect values. Occupancy is higher than 100%.</div>
            )}

            <div className="table-or-graph">
                <div className={!showGraph ? 'selectedTab' : ''} onClick={() => setShowGraph(false)}>
                    Table
                </div>
                <div className={showGraph ? 'selectedTab' : ''} onClick={() => setShowGraph(true)}>
                    Graph
                </div>
            </div>

            {!showGraph && (
                <Table
                    rentRollGroupedByLeaseStartDate={rentRollGroupedByLeaseStartDate}
                    rentRollByLeaseExpireDate={rentRollByLeaseExpireDate}
                    numberOfUnits={numberOfUnits}
                    projectionData={projectionData}
                    weeksToReachFullCO={weeksToReachFullCO}
                    GPR={GPR}
                    concession={concession}
                    otherRentalLosses={otherRentalLosses}
                    sumOfOtherIncome={sumOfOtherIncome}
                    expenses={expenses}
                    selectedPricingOption={selectedPricingOption}
                    futureRentRollEarliestWeek={futureRentRollEarliestWeek}
                    rateLockItem={rateLockItem}
                    stabilizationItem={stabilizationItem}
                    currentWeek={currentWeek}
                    futureRentRollByLeaseStartDate={futureRentRollByLeaseStartDate}
                    futureRentRollByLeaseExpireDate={futureRentRollByLeaseExpireDate}
                    setHistoricalWeeksForGraph={setHistoricalWeeksForGraph}
                    setHistoricalOccupancyForGraph={setHistoricalOccupancyForGraph}
                    setShowHighOccupancyError1={setShowHighOccupancyError1}
                    firstPricingOption={firstPricingOption}
                />
            )}

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .projection-wrapper {
                    background-color: #fff;
                    width: 100%;
                    max-width: 2500px;
                    font-family: 'Inter';
                    font-style: normal;
                    margin-top: 20px;

                    .table-or-graph {
                        padding: 0px 20px;
                        margin: 20px 0px;
                        display: flex;
                        gap: 30px;
                        color: #687893;

                        div:hover {
                            color: #4486ff;
                            cursor: pointer;
                        }

                        .selectedTab {
                            color: #4486ff;
                            border-bottom: 2px solid #4486ff;
                        }
                    }

                    .error-message {
                        color: #f14b44;
                        padding-left: 20px;
                        padding-bottom: 20px;
                    }

                    .title-section {
                        padding: 10px 20px;
                        font-weight: 500;
                        border-bottom: 1px solid #d9e0e5;
                        margin-bottom: 10px;
                    }

                    .controls-and-submarket-section {
                        display: flex;

                        .submarket-section {
                            flex: 1;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }

                        .controls-section {
                            padding: 0px 20px;
                            padding-top: 53px;
                            flex: 1;

                            .top-section {
                                .inputs-section {
                                    display: flex;
                                    gap: 10px;

                                    .input-item {
                                        width: 250px;

                                        .input-label {
                                            margin-bottom: 5px;
                                            text-transform: uppercase;
                                            letter-spacing: 0.5px;
                                            font-size: 12px;
                                        }

                                        .input-wrapper {
                                            background-color: #d9e0e52e;
                                            padding: 5px 10px;

                                            :global(.number-field-container) {
                                                &:after {
                                                    border: none;
                                                }
                                            }

                                            :global(input) {
                                                background-color: transparent;
                                                border: none;
                                                border-bottom: 1px solid #000000;
                                                outline: none;
                                                max-width: 100px;
                                                text-align: initial;
                                                border-radius: initial;
                                            }
                                        }
                                    }
                                }
                            }
                            .middle-section {
                                display: flex;
                                gap: 30px;
                                margin-top: 150px;
                                margin-bottom: 20px;
                                align-items: center;

                                .left-controls {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;

                                    .date-field {
                                        width: 180px;
                                        height: 38px;
                                        border: 1px solid #97a2b4;
                                        border-radius: 4px;
                                        background-color: white !important;
                                        .dx-dropdowneditor-icon:before {
                                            color: $black !important;
                                        }
                                        .dx-texteditor-input {
                                            color: $black !important;
                                            font-weight: 500 !important;
                                            font-size: 14px !important;
                                        }
                                        &.dx-dropdowneditor-active {
                                            border: 1px solid $blue !important;
                                            border-radius: 3px !important;
                                        }
                                    }
                                    .dx-calendar-caption-button {
                                        .dx-button-text {
                                            color: $blue !important;
                                        }
                                    }
                                    .dx-calendar-navigator-next-view.dx-button .dx-icon-chevronright {
                                        color: $blue;
                                    }
                                }

                                .right-controls {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                }

                                .custom-date-checkbox-wrapper,
                                .loan-calculations-checkbox-wrapper {
                                    display: flex;
                                    align-items: center;

                                    label {
                                        margin-bottom: 0px;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
