import React, { FunctionComponent, SyntheticEvent, useCallback, useEffect, useState, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../store'
import { useForm, useFormState } from 'react-final-form'
import { OnlyNumberField } from '../../../components/OnlyNumberField'
import {
    SizerTitlesCol,
    ScenarioTitlesCol,
    CreaditFeesTitlesCol,
    OptionTitle,
    LoanSizerColumn,
    LoanScenariosColumn,
    FeesColumn,
    ExitAnalysisSummaryTitlesCol,
    ExitAnalysisSummaryCol,
    RateBreakdownTitlesCol,
    RateBreakdownColumn,
} from './FormParts'
import { FormContext } from '../../../Dashboard'

type Props = any
export const MainPricingForm: FunctionComponent<Props> = (props: Props) => {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values
    const isSupplemental = props.isSupplemental

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const addPricing = useCallback(() => {
        form.mutators.addItem()
    }, [])

    useEffect(() => {
        if (form) form.submit()
    }, [formValues])

    const optionTitleTopOffset = useRef()

    const refreshRates = useCallback(async () => {
        formValues?.fanniePricing?.pricingOptions?.map((t, idx) => {
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.underwritten.loanPricing.indexRate`, 0)
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.actual.loanPricing.indexRate`, 0)
        })
        form.change('computed.refreshIndexRate', new Date().getTime())
        form.submit()
    }, [])

    const changeOptionSelected = useCallback(
        (idx, value) => {
            form.change(`fanniePricing.pricingOptions.${idx}.isSelected`, value)
            form.submit()
        },
        [form],
    )

    const resetPrepayment = useCallback(
        (idx) => {
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.prepayment`, null)
            form.submit()
        },
        [form],
    )

    const selectOffer = useCallback(
        (idx) => {
            form.mutators.selectOffer(idx)
            form.submit()
        },
        [form],
    )

    const getUnderwrittenDSCRperUnit = useCallback(
        (idx) => {
            return model?.numberOfUnits
                ? formValues?.fanniePricing.pricingOptions[idx].sizer.underwritten.debtServiceConstrainedLoan /
                      model.numberOfUnits
                : 0
        },
        [model, formValues],
    )

    const getActualDSCRperUnit = useCallback(
        (idx) => {
            return model?.numberOfUnits
                ? formValues?.fanniePricing.pricingOptions[idx].sizer.actual.debtServiceConstrainedLoan /
                      model.numberOfUnits
                : 0
        },
        [model, formValues],
    )

    const changeExitAnalysisField = useCallback(
        (idx, type, field, value) => {
            form.change(`fanniePricing.pricingOptions.${idx}.exitAnalysis.${type}.${field}`, value)
        },
        [form],
    )

    const [showLoanSizerExpand, setshowLoanSizerExpand] = useState(true)
    const toggleshowLoanSizerExpand = useCallback(
        (e: SyntheticEvent) => {
            if (formVisited?.pricing === false) {
                setFormVisited((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            if (!formModified.pricing) {
                setFormModified((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            setshowLoanSizerExpand(!showLoanSizerExpand)
        },
        [showLoanSizerExpand, formModified, formVisited?.pricing],
    )

    const [showLoanScenarioExpand, setshowLoanScenariorExpand] = useState(true)
    const toggleshowLoanScenarioExpand = useCallback(
        (e: SyntheticEvent) => {
            if (formVisited?.pricing === false) {
                setFormVisited((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            if (!formModified.pricing) {
                setFormModified((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            setshowLoanScenariorExpand(!showLoanScenarioExpand)
        },
        [showLoanScenarioExpand, formModified, formVisited?.pricing],
    )

    const [showRateBreakdownExpand, setshowRateBreakdownExpand] = useState(true)
    const toggleshowRateBreakdownExpand = useCallback(
        (e: SyntheticEvent) => {
            if (formVisited?.pricing === false) {
                setFormVisited((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            if (!formModified.pricing) {
                setFormModified((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            setshowRateBreakdownExpand(!showRateBreakdownExpand)
        },
        [showRateBreakdownExpand, formModified, formVisited?.pricing],
    )

    const [showCreditFeesExpand, setshowCreditFeesExpand] = useState(true)
    const toggleshowCreditFeesExpand = useCallback(
        (e: SyntheticEvent) => {
            if (formVisited?.pricing === false) {
                setFormVisited((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            if (!formModified.pricing) {
                setFormModified((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            setshowCreditFeesExpand(!showCreditFeesExpand)
        },
        [showCreditFeesExpand, formModified, formVisited?.pricing],
    )

    const [showExitAnalysisSummaryExpand, setshowExitAnalysisSummaryExpand] = useState(true)
    const toggleshowExitAnalysisSummaryExpand = useCallback(
        (e: SyntheticEvent) => {
            if (formVisited?.pricing === false) {
                setFormVisited((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            if (!formModified.pricing) {
                setFormModified((prevState) => ({
                    ...prevState,
                    pricing: true,
                }))
            }
            setshowExitAnalysisSummaryExpand((state) => !state)
        },
        [showExitAnalysisSummaryExpand, formModified, formVisited?.pricing],
    )

    const changeLoanTermSupplemental = useCallback(
        (idx, value) => {
            form.change(`fanniePricing.pricingOptions.${idx}.scenario.underwritten.financeOptions.loanTerm`, value)
        },
        [form],
    )

    const setNmLoanAmountManuallyChanged = (idx) => {
        const option = formValues.fanniePricing?.pricingOptions?.[idx]
        if (option && !option.nmLoanAmountManuallyChanged) {
            form.change(`fanniePricing.pricingOptions.${idx}.nmLoanAmountManuallyChanged`, true)
        }
    }

    return (
        <>
            <div className={'MainPricingForm'}>
                <div className={'addDiv'}>
                    <div onClick={addPricing} className={'addBtn'}>
                        New Loan Pricing
                    </div>
                </div>
                <div id={'hiddenValues'} style={{ display: 'none' }}>
                    {Object.keys(formValues.computed).map((name, index) => (
                        <div key={'hiddendiv' + index}>
                            {name}
                            <OnlyNumberField name={`computed.${name}`} />
                        </div>
                    ))}
                </div>
                <div className={'MainTables'}>
                    <div className={'sizerAndScenarioTablesMain'}>
                        <div className={'titlesMain'}>
                            <div className={'titlesColumns'}>
                                <div className={'titleCol'}>
                                    <div className={'title empty'} style={{ backgroundColor: 'white' }}></div>
                                </div>
                                {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                    if (option.isArm || option.isDefaultFixedRate) return null
                                    const pricingOptionsToShow = formValues.fanniePricing.pricingOptions.filter(
                                        (option) => !option.isArm && !option.isDefaultFixedRate,
                                    )

                                    const orderSelectboxOptions = pricingOptionsToShow.map((option, i) => ({
                                        label: i + 1,
                                        value: option.id,
                                    }))
                                    return (
                                        <OptionTitle
                                            key={'titleoption' + idx}
                                            option={option}
                                            ref={optionTitleTopOffset}
                                            allOptions={formValues?.fanniePricing?.pricingOptions}
                                            idx={idx}
                                            changeOptionCollapsed={(index, value) =>
                                                form.change(`fanniePricing.pricingOptions.${index}.isCollapsed`, value)
                                            }
                                            removeOption={() => form.mutators.removeItem(idx)}
                                            form={form}
                                            orderSelectboxOptions={orderSelectboxOptions}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                        <div className={'sizerMain'}>
                            <div className={'sectionsTitle'}>
                                <span className={'collapseSpan'} onClick={toggleshowLoanSizerExpand}>
                                    <i className="material-icons-outlined">
                                        {showLoanSizerExpand ? 'expand_less' : 'expand_more'}
                                    </i>
                                </span>
                                Loan Sizer
                            </div>

                            {showLoanSizerExpand && (
                                <div className={'sizerColumns'}>
                                    <SizerTitlesCol isSupplemental={isSupplemental} isNM={props.isNM} />

                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm || option.isDefaultFixedRate) return null
                                        return (
                                            <LoanSizerColumn
                                                key={'sizeroption' + idx}
                                                option={option}
                                                idx={idx}
                                                isSupplemental={isSupplemental}
                                                getUnderwrittenDSCRperUnit={getUnderwrittenDSCRperUnit}
                                                getActualDSCRperUnit={getActualDSCRperUnit}
                                                changeOptionSelected={changeOptionSelected}
                                                selectOffer={selectOffer}
                                                isAcquisition={props.isAcquisition}
                                                isNM={props.isNM}
                                                setNmLoanAmountManuallyChanged={setNmLoanAmountManuallyChanged}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                        <div className={'scenarioMain'}>
                            <div className={'sectionsTitle'}>
                                <span className={'collapseSpan'} onClick={toggleshowLoanScenarioExpand}>
                                    <i className="material-icons-outlined">
                                        {showLoanScenarioExpand ? 'expand_less' : 'expand_more'}
                                    </i>
                                </span>
                                Loan Scenario
                            </div>
                            {showLoanScenarioExpand && (
                                <div className={'scenariosColumns'}>
                                    <ScenarioTitlesCol />
                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm || option.isDefaultFixedRate) return null
                                        return (
                                            <LoanScenariosColumn
                                                key={'scenariooption' + idx}
                                                option={option}
                                                idx={idx}
                                                resetPrepayment={resetPrepayment}
                                                isSupplemental={isSupplemental}
                                                changeLoanTermSupplemental={changeLoanTermSupplemental}
                                                form={form}
                                                allOptions={formValues?.fanniePricing?.pricingOptions}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </div>

                        <div className={'creditFeesTablsMain'}>
                            <div className={'titlesMain'}>
                                <div className={'titlesColumns'}>
                                    <div className={'titleCol'}>
                                        <div className={'title empty'} style={{ backgroundColor: 'white' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className={'sectionsTitle'}>
                                <span className={'collapseSpan'} onClick={toggleshowCreditFeesExpand}>
                                    <i className="material-icons-outlined">
                                        {showCreditFeesExpand ? 'expand_less' : 'expand_more'}
                                    </i>
                                </span>
                                Fees & Waivers
                            </div>
                            {showCreditFeesExpand && (
                                <div className={'creditFeesColumns'}>
                                    <CreaditFeesTitlesCol isSupplemental={isSupplemental} />
                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm || option.isDefaultFixedRate) return null
                                        return (
                                            <FeesColumn
                                                key={'creditFeesOption' + idx}
                                                option={option}
                                                idx={idx}
                                                isSupplemental={isSupplemental}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </div>

                        <div className={'rateBreakdownMain'}>
                            <div className={'sectionsTitle'}>
                                <span className={'collapseSpan'} onClick={toggleshowRateBreakdownExpand}>
                                    <i className="material-icons-outlined">
                                        {showRateBreakdownExpand ? 'expand_less' : 'expand_more'}
                                    </i>
                                </span>
                                Rate Breakdown
                            </div>
                            {showRateBreakdownExpand && (
                                <div className={'rateBreakdownColumns'}>
                                    <RateBreakdownTitlesCol refreshRates={refreshRates} />
                                    {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                        if (option.isArm || option.isDefaultFixedRate) return null
                                        return (
                                            <RateBreakdownColumn
                                                key={'rateBreakdownOption' + idx}
                                                option={option}
                                                idx={idx}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={'exitAnalysisSummaryMain'}>
                        <div className={'titlesMain'}>
                            <div className={'titlesColumns'}>
                                <div className={'titleCol'}>
                                    <div className={'title empty'} style={{ backgroundColor: 'white' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className={'sectionsTitle'}>
                            <span className={'collapseSpan'} onClick={toggleshowExitAnalysisSummaryExpand}>
                                <i className="material-icons-outlined">
                                    {showExitAnalysisSummaryExpand ? 'expand_less' : 'expand_more'}
                                </i>
                            </span>
                            Exit Analysis Summary
                        </div>
                        {showExitAnalysisSummaryExpand && (
                            <div className={'exitAnalysisSummaryColumns'}>
                                <ExitAnalysisSummaryTitlesCol />
                                {formValues?.fanniePricing?.pricingOptions?.map((option, idx) => {
                                    if (option.isArm || option.isDefaultFixedRate) return null
                                    return (
                                        <ExitAnalysisSummaryCol
                                            option={option}
                                            key={'ExitAnalysisSummaryCol' + idx}
                                            changeField={changeExitAnalysisField}
                                            idx={idx}
                                            isSupplemental={isSupplemental}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ height: '40px' }}></div>
            </div>
            {/*language=scss*/}
            <style jsx>
                {`
                    .MainPricingForm {
                    }
                    .MainTables {
                        background-color: white;
                        width: fit-content;
                        padding-bottom: 20px;
                    }
                    .sizerAndScenarioTablesMain {
                        background-color: white;
                    }
                    .creditFeesTablsMain {
                        margin-left: 0px;
                        background-color: white;
                    }
                    .creditFeesTabls {
                        display: flex;
                    }
                    .titlesColumns {
                        display: flex;
                    }
                    .sizerColumns {
                        display: flex;
                    }
                    .scenarioMain {
                        margin-top: 40px;
                    }
                    .rateBreakdownMain {
                        margin-top: 40px;
                    }
                    .scenariosColumns {
                        display: flex;
                    }
                    .rateBreakdownColumns {
                        display: flex;
                    }
                    .creditFeesColumns {
                        display: flex;
                    }
                    .exitAnalysisSummaryColumns {
                        display: flex;
                    }
                    .capCostColumns {
                        display: flex;
                    }
                    .title {
                        width: 360px;
                        height: 40px;
                        text-indent: 30px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 18px;
                        padding-top: 10px;
                    }
                    .title:nth-child(even) {
                        background-color: rgba(217, 224, 229, 0.15);
                    }
                    .sectionsTitle {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                    .collapseSpan {
                        cursor: pointer;
                        cursor: pointer;
                        top: 5px;
                        position: relative;
                        margin-right: 5px;
                    }
                    .addDiv {
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    .addBtn {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        color: #ffffff;
                        width: 144px;
                        height: 36px;
                        background: #4486ff;
                        border-radius: 4px;
                        padding-top: 10px;
                        cursor: pointer;
                    }
                `}
            </style>
        </>
    )
}
