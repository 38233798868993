import React, { FunctionComponent, useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'

import { FormApi } from 'final-form'
import { Field, Form, useForm, useFormState } from 'react-final-form'
import { Spinner } from '../../../../../controls/Spinner'
import createDecorator from 'final-form-calculate'
import { PricingQuotes } from './PricingQuotes'
import { QuoteCFSummary } from './QuoteCFSummary'
import { QuoteHeader } from './QuoteHeader'
import { TinyMceAdapter } from '../../../../../controls/inputs/dealnarrative/TinyMceAdapter'
import { TextField } from '../../../components/TextField'
import trash from '../icons/trash.svg'
import download from '../icons/download.svg'
import edit from '../icons/edit.svg'
import plus from '../icons/plus.svg'
import { removeItemFromArray } from '../../../../../../utils'
import { useRouter } from 'next/router'
import { SagaHelper } from '../../../../../../store/sagaHelper'
import { downloadCSVImp } from './lib/downloadCSVImp'
import { FormContext } from '../../../Dashboard'
import { orgenizeCFForPresentation, regenerateCashFlow } from 'origination-model'
import { DownloadActions } from './DownloadActions'

const cloneDeep = require('lodash.clonedeep')

type Props = any

const defconsiderations =
    '<ul>\n' +
    '<li>- Loan amounts are based on provided net cash flow. Lender/FNMA has not reviewed. Terms are subject to Lender/FNMA due diligence and 3rd party reports.</li>\n' +
    '<li>- This is a soft indication subject to Fannie Mae final approval. For Fannie Mae approval, please submit requisite borrower experience (minimum PFS and SREO), historical operating statements, and rent roll.</li>\n' +
    '<li>- Interest Only (I/O) periods are subject to final underwriting support and Lender/FNMA approval.</li>\n' +
    '<li>- Subject to review of tenancy and employment profile/concentrations.</li>\n' +
    '<li>- Subject to no material change in property performance (including delinquency).</li>\n' +
    '<li>- Quotes include a 1.00% UST floor subject to Fannie Mae review and approval.</li>\n' +
    '<li>- Loan terms subject to appraisal support for the underwritten assessed value and annual taxes. NorthMarq Capital Finance Estimated Underwriting</li>\n' +
    '<li>- Subject to appraisal and borrower expense comps for underwritten expenses.</li>\n' +
    '<li>- Subject to appropriate borrower net worth/liquidity</li>\n' +
    '<li>- Subject to Fannie Mae compliant insurance premium supporting underwritten insurance expense.</li>\n' +
    '</ul>'

const defaultLegal =
    ' The terms and conditions outlined herein are a general outline of loan option(s). Borrower and\n' +
    '                    Lender will agree that finalizing any options is subject in all respects to the discretion of Fannie\n' +
    '                    Mae and NorthMarq Capital Finance after their complete review of proposed financing structure(s) and\n' +
    '                    all applicable information. The above referenced summary of terms does not in any way constitute a\n' +
    '                    commitment to provide financing on the part of NorthMarq Capital Finance or Fannie Mae.'
const defualtFooter = ''

export const FannieQuoteEditor = React.memo(function MainPricing(props: Props) {
    const router = useRouter()
    const fannieQuotes = useSelector((state: ReduxStoreState) => state.lender.fannieQuotes)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const localContext = useMemo(() => ({ form: null as FormApi }), [])
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const { formModified, setFormModified, formVisited, setFormVisited } = useContext(FormContext)

    const isQuotes = useMemo(() => {
        const isModel = model != null ? true : false
        const isgeneralInputs = generalInputs != null ? true : false
        const isProject = project != null ? true : false
        return fannieQuotes?.quotes && isgeneralInputs && isModel && isProject
    }, [fannieQuotes?.quotes, generalInputs, model, project])

    const [isNM, setIsNM] = useState(false)
    useEffect(() => {
        if (localStorage) {
            let accountNameFromUrl = router?.query?.accountName
            if (Array.isArray(accountNameFromUrl)) accountNameFromUrl = accountNameFromUrl[0]
            const accountName = accountNameFromUrl || localStorage.getItem('accountName')
            //console.log('accountName', accountName)
            if (accountName?.toLowerCase().indexOf('northmarq') > -1) setIsNM(true)
            if (accountName?.toLowerCase().indexOf('Kobi test') > -1) setIsNM(true)
        }
    }, [])

    useEffect(() => {
        if (props.viewMode) {
            document.body.style.backgroundColor = 'white'
        }
    }, [props.viewMode])
    const initialValues = useMemo(() => {
        if (!isQuotes) return null
        const cloned = cloneDeep(fannieQuotes)
        if (cloned.quotes.length == 0) {
            cloned.quotes.push({
                id: 0,
                name: 'quote 0',
                to: (generalInputs?.team?.producer || '') + isNM ? ' - Northmarq' : '',
                from: (generalInputs?.team?.productionManager || '') + isNM ? ' - NCF ' : '',
                date: new Date(),
                details: [
                    { name: 'Operating Deficit Escrow (Principal & Interest):', value: 'N/A' },
                    { name: 'Ongoing Monthly Escrows:', value: 'N/A' },
                    {
                        name: 'Appraisal, Environmental, BNA Review:',
                        value: 'Third-party reports that meet FNMA standards are required.',
                    },
                    {
                        name: 'Loan Fees:',
                        value: ' Minimum origination fee as required by FNMA plus funds for 3rd party reports and Lender legal costs. All other transaction costs unrelated to the loan are to be paid by Borrower.',
                    },
                    {
                        name: 'Other Fees:',
                        value:
                            'Fannie Mae Delivery Fee ' +
                            (generalInputs?.loanDetails?.requestedLoanAmount < 6000000 ? 0.1 : 0.05) +
                            '%',
                    },
                ],
                considerations: defconsiderations.replace('and NorthMarq', isNM ? 'and NorthMarq' : ''),
                legal: defaultLegal.replace('and NorthMarq', isNM ? 'and NorthMarq' : ''),
                footer: defualtFooter,
            })
        } else {
            if (cloned.quotes[0].to == ' - Northmarq')
                cloned.quotes[0].to = (generalInputs?.team?.producer || '') + cloned.quotes[0].to
            if (cloned.quotes[0].from == ' - NCF ')
                cloned.quotes[0].from = (generalInputs?.team?.productionManager || '') + cloned.quotes[0].from
        }
        return {
            ...cloned,
        }
    }, [isQuotes, isNM])

    const onSubmit = useCallback(
        (values: any) => {
            //   console.log('onSubmit', values)
            const cloned = cloneDeep(values)
            // console.log('submit cloned', cloned)
            ReduxHelper.setIn(['lender', 'fannieQuotes'], cloned)
        },
        [isQuotes],
    )

    const mutators = useMemo(() => {
        if (!isQuotes) return null
        return {
            addDetail(params, state, form) {
                form.changeValue(state, `quotes.0.details`, (list) => {
                    const obj: any = {
                        name: '',
                        value: '',
                    }

                    list.push(obj)
                    return list
                })
                localContext.form.submit()
            },
            removeDetail(params, state, form) {
                form.changeValue(state, `quotes.0.details`, (list) => {
                    //console.log('removeAdjustedItem', params[0], params[1], cloneDeep(t12Adjustments))
                    const newList = removeItemFromArray(list, params[0])

                    return newList
                })
                localContext.form.submit()
            },
        }
    }, [isQuotes])

    const computedValues = useMemo(() => {
        return createDecorator()
    }, [])

    const [isEditable, setEditMode] = useState(false)

    useEffect(() => {
        if (isEditable) {
            if (formVisited?.quote === false) {
                setFormVisited((prevState) => ({
                    ...prevState,
                    quote: true,
                }))
            }
            if (!formModified.quote) {
                setFormModified((prevState) => ({
                    ...prevState,
                    quote: true,
                }))
            }
        }
    }, [isEditable])

    const downloadPDF = useCallback(async () => {
        //console.log('pdf')
        await doSave()
        const url = `/northmarqQuoteToPdf/${router.query.id}?id=${router.query.id}`
        window.open(url, '_blank')
    }, [])

    const exportProjectToExcel = useCallback(async () => {
        const url = `/northmarqExportProject/${router.query.id}?id=${router.query.id}`
        window.open(url, '_blank')
    }, [router.query.id])

    const downloadCSV = useCallback(async () => {
        await doSave()
        const unitMix = ReduxHelper.store?.getState()?.lender?.unitMix
        const pricing = ReduxHelper.store?.getState()?.lender?.pricing
        const tmpCf = regenerateCashFlow({ model: model, cashFlow: model?.cashFlow, unitMix: unitMix })

        //console.log('modle.expense', cloneDeep(model.t12Adjustments))
        //console.log('tmpCf.expense', cloneDeep(tmpCf.operatingExpenses))
        const cashFlow = orgenizeCFForPresentation({
            cashFlow: tmpCf,
        })

        // console.log('cashFlow.expense', cloneDeep(cashFlow.operatingExpenses))
        downloadCSVImp(pricing, project, generalInputs, cashFlow, model)
    }, [project, generalInputs, model])

    const doSave = useCallback(async () => {
        if (localContext.form) await localContext.form.submit()
        const fannieQuotes = ReduxHelper.store.getState().lender.fannieQuotes
        // console.log('save btn', fannieQuotes)
        if (fannieQuotes?.quotes?.length > 0) {
            for (const q of fannieQuotes?.quotes) {
                if (typeof q.date == 'string' && q.date.match(/[0-9]+$/)) {
                    q.date = new Date(+q.date)
                }
            }
            await SagaHelper.run(['lenderFannieQuotes', 'setFannieQuotes'], {
                projectId: project.id,
                fannieQuotes: fannieQuotes,
            })
            if (props.saveDeal) props.saveDeal()
            else await SagaHelper.run(['notification', 'show'], 'Data saved')
        }
    }, [])
    const saveOrEdit = useCallback(async () => {
        //console.log('save', fannieQuotes?.quotes?.length)
        setEditMode(!isEditable)
        if (isEditable) await doSave()
    }, [isEditable])

    return (
        <>
            {isQuotes && initialValues && computedValues && mutators ? (
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    decorators={[computedValues]}
                    mutators={mutators}
                >
                    {({ form }) => {
                        // console.log('create form', isPricing, cloneDeep(form))
                        localContext.form = form
                        return (
                            <>
                                {!props.viewMode && (
                                    <div className={'btns'}>
                                        <div
                                            onClick={saveOrEdit}
                                            className={'baseBtn editBtn' + (isEditable ? ' saveBtn' : '')}
                                        >
                                            <span>{isEditable ? 'Save Changes' : 'Edit'}</span>
                                            {!isEditable && (
                                                <span>
                                                    <img src={edit} />
                                                </span>
                                            )}
                                        </div>
                                        {!isEditable && (
                                            <DownloadActions downloadPDF={downloadPDF} downloadCSV={downloadCSV} />
                                        )}
                                        {/* <div
                                            onClick={exportProjectToExcel}
                                            className={'baseBtn exportBtn'}
                                        >
                                            <span>Export Project</span>
                                        </div> */}
                                    </div>
                                )}
                                <div className={'FannieQuoteWrapper'}>
                                    <QuoteHeader isEditable={isEditable} isNM={isNM} />
                                    <PricingQuotes />
                                    <div className={'midPart'}>
                                        <div className={'midPartCol1'}>
                                            <MainQuoteForm isEditable={isEditable} isNM={isNM} />
                                            <MainQuoteDetailsForm isEditable={isEditable} isNM={isNM} />
                                        </div>
                                        <div className={'midPartCol2'}>
                                            <QuoteCFSummary isNM={isNM} />
                                        </div>
                                    </div>

                                    <QuoteFooter isEditable={isEditable} />
                                </div>
                            </>
                        )
                    }}
                </Form>
            ) : (
                <Spinner />
            )}

            {/*language=SCSS*/}
            <style jsx>{`
                @media print {
                    .FannieQuoteWrapper {
                        min-height: 500px;
                        max-height: 500px;
                    }
                }
                :global() {
                    @media print {
                        @page {
                            size: landscape;
                        }
                    }
                }
                @media screen {
                    .FannieQuoteWrapper {
                        min-height: 1500px;
                        // max-height: 1500px;
                    }
                }
                .FannieQuoteWrapper {
                    width: 1500px;

                    background-color: white;
                    padding: 30px;

                    .midPart {
                        display: flex;
                        margin-top: 30px;
                        margin-bottom: 20px;
                    }
                    .midPartCol1 {
                        width: 60%;
                        margin-right: 30px;
                    }
                    .midPartCol2 {
                        width: 35%;
                    }
                }
                .btns {
                    display: flex;
                    margin-bottom: 10px;
                    .baseBtn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 12px;
                        gap: 8px;
                        height: 36px;
                        border-radius: 4px;
                        cursor: pointer;
                        img {
                            width: 16px;
                            height: 16px;
                            margin: 0;
                        }
                    }

                    .saveBtn {
                        width: 135px !important;
                    }
                    .editBtn {
                        width: 90px;

                        color: #4486ff;
                        border: 1px solid #4486ff;

                        margin-right: 10px;
                    }
                    .exportBtn {
                        color: white;
                        background: #4486ff;
                    }
                }
            `}</style>
        </>
    )
})

const MainQuoteForm: FunctionComponent<Props> = (props: Props) => {
    const readOnly = !props.isEditable
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values
    useEffect(() => {
        //console.log('main useEffect', cloneDeep(formValues))
        if (form) form.submit()
    }, [formValues?.quotes])

    return (
        <>
            <div className={'MainQuoteForm'}>
                <div className={'consideration'}>
                    {((readOnly && formValues?.quotes?.[0]?.considerations) || !readOnly) && (
                        <div className={'considerationTitle'}> Considerations</div>
                    )}
                    <div className={'consideration-form'}>
                        {readOnly ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html:
                                        formValues?.quotes?.[0]?.considerations
                                            .replace('list-style-type: circle;', 'list-style-type: none;')
                                            .replace('list-style-type: unset;', 'list-style-type: none;')
                                            .replace('list-style-type: inherit;', 'list-style-type: none;')
                                            .replace('list-style-type: auto;', 'list-style-type: none;')
                                            .replace('list-style-type: decimal;', 'list-style-type: none;')
                                            .replace('list-style-type: disc;', 'list-style-type: none;')
                                            .replace('list-style-type: initial;', 'list-style-type: none;')
                                            .replace('list-style-type: square;', 'list-style-type: none;')
                                            .replace('<ul>', '<ul style="list-style-type: none;">') || '',
                                }}
                            />
                        ) : (
                            <Field
                                id={`considerations`}
                                component={TinyMceAdapter}
                                name={`quotes.0.considerations`}
                                initialValue={formValues?.quotes?.[0]?.considerations || ''}
                                className="form-control"
                                placeholder={'content'}
                            />
                        )}
                    </div>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .MainQuoteForm {
                }
                .consideration {
                }
                .consideration-form {
                    margin-top: 20px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 140%;

                    color: #161c26;
                }
                :global(.consideration-form ul) {
                    list-style-type: none !important;
                }
                :global(.consideration-form li) {
                    text-indent: -10px;
                }
                .considerationTitle {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;

                    color: #161c26;
                }
            `}</style>
        </>
    )
}

const MainQuoteDetailsForm: FunctionComponent<Props> = (props: Props) => {
    const readOnly = !props.isEditable
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values

    useEffect(() => {
        //console.log('main useEffect', cloneDeep(formValues))
        if (form) form.submit()
    }, [formValues?.quotes])

    const deleteDetail = useCallback(
        (idx) => {
            form.mutators.removeDetail(idx)
        },
        [formValues?.quotes?.[0]],
    )

    const addDetail = useCallback(() => {
        form.mutators.addDetail()
    }, [])
    return (
        <>
            <div className={'MainQuoteDetailsForm'}>
                <div className={'details'}>
                    {formValues.quotes[0].details.map((option, idx) => {
                        return (
                            <div className={'detailRow'} key={'qop' + idx}>
                                <div className={'title' + (!readOnly ? ' editLine' : '')}>
                                    <TextField
                                        name={`quotes.0.details.${idx}.name`}
                                        className={'cssQuoteTextFieldTitle'}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className={'dValue' + (!readOnly ? ' editLine' : '')}>
                                    {readOnly ? (
                                        <div className={'dValue'}>
                                            {formState.values?.quotes?.[0]?.details?.[idx]?.value}{' '}
                                        </div>
                                    ) : (
                                        <TextField
                                            name={`quotes.0.details.${idx}.value`}
                                            className={'cssQuoteTextField'}
                                            readOnly={readOnly}
                                        />
                                    )}
                                </div>
                                {!readOnly && (
                                    <div
                                        className={'deletediv'}
                                        onClick={() => {
                                            deleteDetail(idx)
                                        }}
                                    >
                                        <img src={trash} />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
                {!readOnly && (
                    <div onClick={addDetail} className={'addBtn'}>
                        <span>
                            <img src={plus} />
                        </span>
                        <span>Add Line</span>
                    </div>
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .MainQuoteDetailsForm {
                    .details {
                        margin-top: 50px;
                    }
                    .detailRow {
                        display: flex;
                        min-height: 30px;
                        margin-bottom: 5px;
                    }

                    .title {
                        font-weight: bold;
                        width: 55%;
                        font-family: 'Inter';
                        font-style: normal;
                        font-size: 15px;
                        line-height: 140%;
                        margin-right: 20px;
                    }
                    .dValue {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 140%;

                        color: #161c26;
                        width: 80%;
                    }
                    .editLine {
                        border-bottom: 1px solid lightgray;
                    }
                    .addBtn {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 12px;
                        gap: 8px;
                        width: 120px;
                        height: 36px;
                        color: #4486ff;
                        border: 1px solid #4486ff;
                        border-radius: 4px;
                        cursor: pointer;
                        img {
                            width: 16px;
                            height: 16px;
                            margin: 0;
                        }
                    }
                    .deletediv {
                        cursor: pointer;
                    }
                }
                :global(.cssQuoteTextFieldTitle) {
                    font-weight: bold;
                    height: 18px !important;
                }
                :global(.cssQuoteTextFieldTitle) {
                    border-bottom: 1px solid lightgray;
                }
            `}</style>
        </>
    )
}
const QuoteFooter: FunctionComponent<Props> = (props: Props) => {
    const readOnly = !props.isEditable
    const form = useForm()
    const formState = useFormState()
    const formValues = formState.values
    const generalInputs = useSelector((state: ReduxStoreState) => state.lender.generalInputs)
    const fannieQuotes = useSelector((state: ReduxStoreState) => state.lender.fannieQuotes)
    useEffect(() => {
        //console.log('main useEffect', cloneDeep(formValues))
        if (form) form.submit()
    }, [formValues?.quotes])

    const member = useMemo(() => {
        let member =
            generalInputs?.team?.productionManager && fannieQuotes?.team
                ? fannieQuotes?.team?.find((m) => m.memberName == generalInputs.team.productionManager)
                : null
        if (!member) member = fannieQuotes?.team?.[0]
        return member
    }, [fannieQuotes.team, generalInputs?.team?.productionManager])
    return (
        <>
            <div className={'FannieQuoteFooter'}>
                <div className={'legal'}>
                    {readOnly ? (
                        <div
                            dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __html: formValues.quotes[0].legal || '',
                            }}
                        />
                    ) : (
                        <Field
                            id={`considerations`}
                            component={TinyMceAdapter}
                            name={`quotes.0.legal`}
                            initialValue={formValues.quotes[0].legal || ''}
                            className="form-control"
                            placeholder={'content'}
                        />
                    )}
                </div>

                <div className={'sig'}>
                    <div className={'sigName'}>{member?.memberName}</div>
                    <div className={'sigTitle'}>{member?.memberTitle}</div>
                    <div className={'sigEmail'}>{member?.email}</div>
                    <div className={'sigPhone'}>{member?.phone}</div>
                </div>
                <div className={'legal2'}>
                    {readOnly ? (
                        <div
                            dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __html: formValues.quotes[0].footer || '',
                            }}
                        />
                    ) : (
                        <Field
                            id={`considerations`}
                            component={TinyMceAdapter}
                            name={`quotes.0.footer`}
                            initialValue={formValues.quotes[0].footer || ''}
                            className="form-control"
                            placeholder={'content'}
                        />
                    )}
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .FannieQuoteFooter {
                    margin-top: 10px;
                    margin-bottom: 20px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 140%;
                    color: #161c26;
                    .sigName {
                        font-weight: bold;
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
                    .sig {
                        margin-top: 50px;
                    }
                    .legal {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 140%;
                        /* or 21px */

                        /* main */

                        color: #161c26;
                    }
                    .legal2 {
                        font-size: 12px;
                        margin-top: 80px;
                        color: #777;
                    }
                }
            `}</style>
        </>
    )
}
