import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../store'
import { TextField } from '../../components/TextField'
import { NumberField } from '../../components/NumberField'
import { SelectField } from '../../components/SelectField'
import arrayMutators from 'final-form-arrays'
import { FormApi } from 'final-form'
import { InsertColumn } from './InsertColumn'
import { DeleteColumn } from './DeleteColumn'
import { QuoteGraphQl } from '@generated/graphql'

type Props = {
    mode: string
}
const yesNo = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
]
const rates = [
    { value: 'Fixed Rate', label: 'Fixed Rate' },
    { value: 'Adjustable Rate', label: 'Adjustable Rate' },
]

const ltvORc = [
    { value: 'ltc', label: 'LTC' },
    { value: 'ltv', label: 'LTV' },
]

const indexes = [
    { value: '1 Month LIBOR', label: '1 Month LIBOR' },
    { value: '1-Month Treasury Note', label: '1-Month Treasury Note' },
    { value: '2-Month Treasury Note', label: '2-Month Treasury Note' },
    { value: '3-Month Treasury Note', label: '3-Month Treasury Note' },
    { value: '6-Month Treasury Note', label: '6-Month Treasury Note' },
    { value: '1-Year Treasury Note', label: '1-Year Treasury Note' },
    { value: '2-Year Treasury Note', label: '2-Year Treasury Note' },
    { value: '3-Year Treasury Note', label: '3-Year Treasury Note' },
    { value: '5-Year Treasury Note', label: '5-Year Treasury Note' },
    { value: '7-Year Treasury Note', label: '7-Year Treasury Note' },
    { value: '10-Year Treasury Note', label: '10-Year Treasury Note' },
    { value: '20-Year Treasury Note', label: '20-Year Treasury Note' },
    { value: '30-Year Treasury Note', label: '30-Year Treasury Note' },
]
export const QuoteEdit = forwardRef(QuoteEditComp)
export function QuoteEditComp(props: Props, ref) {
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const dataDB: QuoteGraphQl[] = useSelector((state: ReduxStoreState) => state.lender.quoteData)

    const data = {
        id: [],
        default: [],
        title: [],
        loanType: [],
        rate: [],
        indexType: [],
        recourse: [],
        loanPeriod: [],
        maximumLTC: [],
        maximumLTV: [],
        minimumDSCR: [],
        amortization: [],
        estimatedNOI: [],
        interestOnly: [],
        repaymentType: [],
        isLoanAssumable: [],
        preliminaryValue: [],
        maximumLoanAmount: [],
        estimatedAllInRate: [],
        interestAccuralBase: [],
        replacementReserveAndTIEscrow: [],
        supplementalFinancingAvailable: [],
        createdAt: [],
        updatedAt: [],
        vacancy: [],
        exitCapRate: [],
        valuationCapRate: [],
        borrowerNOI: [],
        refinanceLTV: [],
        mezLoanType: [],
        mezRate: [],
        mezMaximumLTC: [],
        mezMaximumLTV: [],
        mezMinimumDSCR: [],
        mezAmortization: [],
        mezInterestOnly: [],
        mezLoanPeriod: [],
        mezMaximumLoanAmount: [],
        useSeniorMaxLTCorV: [],
        useMezMaxLTCorV: [],
    }

    if (!dataDB || dataDB.length == 0) {
        data.id.push(null)
        data.default.push(1)
        data.vacancy.push(null)
        data.exitCapRate.push(null)
        data.valuationCapRate.push(null)
        data.borrowerNOI.push(null)
        data.refinanceLTV.push(null)
        data.title.push(null)
        data.loanType.push('Fixed Rate')
        data.rate.push(null)
        data.indexType.push('10-Year Treasury Note')
        data.recourse.push(null)
        data.loanPeriod.push(null)
        data.maximumLTC.push(null)
        data.maximumLTV.push(null)
        data.minimumDSCR.push(null)
        data.amortization.push(null)
        data.estimatedNOI.push(null)
        data.interestOnly.push(null)
        data.repaymentType.push(null)
        data.isLoanAssumable.push(null)
        data.preliminaryValue.push(null)
        data.maximumLoanAmount.push(null)
        data.estimatedAllInRate.push(null)
        data.interestAccuralBase.push(null)
        data.replacementReserveAndTIEscrow.push(null)
        data.supplementalFinancingAvailable.push(null)
        data.createdAt.push('')
        data.updatedAt.push('')
        data.mezLoanType.push('Fixed Rate')
        data.mezRate.push(null)
        data.mezMaximumLTC.push(null)
        data.mezMaximumLTV.push(null)
        data.mezMinimumDSCR.push(null)
        data.mezAmortization.push(null)
        data.mezInterestOnly.push(null)
        data.mezLoanPeriod.push(null)
        data.mezMaximumLoanAmount.push(null)
        data.useSeniorMaxLTCorV.push('LTC')
        data.useMezMaxLTCorV.push('LTC')
    } else {
        dataDB?.map((itm, ind) => {
            data.id.push(itm.id)
            data.default.push(itm.default)
            data.vacancy.push(itm.vacancy)
            data.exitCapRate.push(itm.exitCapRate)
            data.valuationCapRate.push(itm.valuationCapRate)
            data.borrowerNOI.push(itm.borrowerNOI)
            data.refinanceLTV.push(itm.refinanceLTV)
            data.title.push(itm.name)
            data.loanType.push(itm.loanType != null && itm.loanType != '' ? itm.loanType : 'Fixed Rate')
            data.rate.push(itm.rate)
            data.indexType.push(
                itm.index != null && itm.index != ' ' && itm.index != '' ? itm.index : '10-Year Treasury Note',
            )
            data.recourse.push(itm.recourse)
            data.loanPeriod.push(itm.loanPeriod)
            data.maximumLTC.push(itm.maximumLTC)
            data.maximumLTV.push(itm.maximumLTV)
            data.minimumDSCR.push(itm.minimumDSCR)
            data.amortization.push(itm.amortization)
            data.estimatedNOI.push(Math.round(itm.estimatedNOI))
            data.interestOnly.push(itm.interestOnly)
            data.repaymentType.push(itm.repaymentType)
            data.isLoanAssumable.push(itm.isLoanAssumable)
            data.preliminaryValue.push(itm.preliminaryValue)
            data.maximumLoanAmount.push(itm.maximumLoanAmount)
            data.estimatedAllInRate.push(itm.estimatedAllInRate)
            data.interestAccuralBase.push(itm.interestAccuralBase)
            data.replacementReserveAndTIEscrow.push(itm.replacementReserveAndTIEscrow)
            data.supplementalFinancingAvailable.push(itm.supplementalFinancingAvailable)
            data.createdAt.push(
                itm.createdAt ? itm.createdAt.substr(0, itm.createdAt.indexOf('T')).split('-').reverse().join('-') : '',
            )
            data.updatedAt.push(
                itm.updatedAt ? itm.updatedAt.substr(0, itm.updatedAt.indexOf('T')).split('-').reverse().join('-') : '',
            )

            data.mezLoanType.push(itm.mezLoanType != null && itm.mezLoanType != '' ? itm.mezLoanType : 'Fixed Rate')
            data.mezRate.push(itm.mezRate)
            data.mezMaximumLTC.push(itm.mezMaximumLTC)
            data.mezMinimumDSCR.push(itm.mezMinimumDSCR)
            data.mezAmortization.push(itm.mezAmortization)
            data.mezInterestOnly.push(itm.mezInterestOnly)
            data.mezLoanPeriod.push(itm.mezLoanPeriod)
            data.mezMaximumLoanAmount.push(itm.mezMaximumLoanAmount)
            data.useSeniorMaxLTCorV.push(
                itm.useSeniorMaxLTCorV != null && itm.useSeniorMaxLTCorV != '' ? itm.useSeniorMaxLTCorV : 'ltc',
            )
            data.useMezMaxLTCorV.push(
                itm.useMezMaxLTCorV != null && itm.useMezMaxLTCorV != '' ? itm.useMezMaxLTCorV : 'ltc',
            )
        })
    }

    //console.log('QuoteEditComp', dataDB, data)

    const localContext = useMemo(() => ({ form: null as FormApi }), [])

    const readOnlyForm = props.mode == 'view'

    const insertQuote = useCallback(
        (col: number) => {
            if (localContext?.form.getState().values.title.length >= 5) return
            for (const key in data) {
                localContext?.form.mutators.insertAt(key, col + 1, null)
            }
        },
        [localContext?.form],
    )
    const deleteColumn = useCallback(
        (col: number) => {
            if (localContext?.form.getState().values.title.length == 1) return
            for (const key in data) {
                // console.log('key', key)
                // console.log('col', col)
                localContext?.form.mutators.remove(key, col)
            }
            console.log(localContext?.form?.mutators)
        },
        [localContext?.form],
    )
    useImperativeHandle(ref, () => ({
        addQuote: () => {
            insertQuote(localContext?.form.getState().values.title.length)
        },
        getValues: () => {
            return localContext.form?.getState()?.values || {}
        },
    }))
    if (data?.title.length == 0) return null
    return (
        <Form
            key={props.mode}
            onSubmit={() => ({})}
            initialValues={data}
            mutators={{
                ...arrayMutators,
                insertAt: ([name, index, value], state, { changeValue }) => {
                    changeValue(state, name, (array) => {
                        const copy = [...(array || [])]
                        copy.splice(index, 0, value)
                        return copy
                    })
                },
            }}
        >
            {(formProps) => {
                localContext.form = formProps.form
                return (
                    <>
                        <table className={`quote-table ${props.mode}-mode`}>
                            <thead>
                                <tr>
                                    <th></th>
                                    {formProps.values.title.map((s, ind) => (
                                        <th key={ind}>
                                            {props.mode == 'edit' && ind < formProps.values.title.length - 1 && (
                                                <InsertColumn
                                                    className={'add-quote'}
                                                    onClick={() => {
                                                        insertQuote(ind)
                                                    }}
                                                />
                                            )}
                                            {props.mode == 'edit' && (
                                                <DeleteColumn
                                                    onClick={() => {
                                                        deleteColumn(ind)
                                                    }}
                                                    className={'remove-quote'}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField name={`title[${ind}]`} readOnly={readOnlyForm} />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Created At</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField name={`createdAt[${ind}]`} readOnly={true} />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Last Update</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField name={`updatedAt[${ind}]`} readOnly={true} />
                                        </td>
                                    ))}
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td>Senior Loan Type</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`loanType[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={rates}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Senior Rate</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`rate[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td>Senior Maximum Loan Amount</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`maximumLoanAmount[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'currency'}
                                            />
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td>Senior Minimum DSCR</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`minimumDSCR[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>

                                <tr>
                                    <td>Senior Maximum LTC</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`maximumLTC[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Senior Maximum LTV</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`maximumLTV[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Senior Use Maximum LTC or LTV</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`useSeniorMaxLTCorV[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={ltvORc}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Senior Load Period (Years)</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`loanPeriod[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Senior Amortization (Years)</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`amortization[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Senior Interest Only (Years)</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`interestOnly[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td>Mezzanine Senior Loan Type</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`mezLoanType[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={rates}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Rate</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezRate[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Maximum Loan Amount</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezMaximumLoanAmount[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'currency'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Minimum DSCR</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezMinimumDSCR[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Maximum LTC</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezMaximumLTC[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Maximum LTV</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezMaximumLTV[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Use Maximum LTC or LTV</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`useMezMaxLTCorV[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={ltvORc}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Load Period (Years)</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezLoanPeriod[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Amortization (Years)</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezAmortization[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Mezzanine Interest Only (Years)</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`mezInterestOnly[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'decimal'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td>Index</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`indexType[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={indexes}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Estimated All-in Rate</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`estimatedAllInRate[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'percent'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <DelimeterRow span={+formProps.values.title.length + 1} />
                                <tr>
                                    <td>Repayment Type</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField name={`repaymentType[${ind}]`} readOnly={readOnlyForm} />
                                        </td>
                                    ))}
                                </tr>

                                <DelimeterRow span={+formProps.values.title.length + 1} />
                                <tr>
                                    <td>{model?.useNCFnotNOI ? 'Estimated Net CF' : 'Estimated NOI'}</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`estimatedNOI[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'currency'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>{model?.useNCFnotNOI ? 'Borrower Net CF' : 'Borrower NOI'}</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`estimatedNOIborrowerNOI[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'currency'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Preliminary Value</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <NumberField
                                                name={`preliminaryValue[${ind}]`}
                                                readOnly={readOnlyForm}
                                                numberFormat={'currency'}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Interest Accural Base</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField name={`interestAccuralBase[${ind}]`} readOnly={readOnlyForm} />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Recourse</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField name={`recourse[${ind}]`} readOnly={readOnlyForm} />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Is Loan Assumable?</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`isLoanAssumable[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={yesNo}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Supplemental Financing Available</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <SelectField
                                                name={`supplementalFinancingAvailable[${ind}]`}
                                                readOnly={readOnlyForm}
                                                values={yesNo}
                                            />
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>Replacement Reserve And T&I Escrow</td>
                                    {formProps.values.title.map((s, ind) => (
                                        <td key={ind} className={'value-cell'}>
                                            <TextField
                                                name={`replacementReserveAndTIEscrow[${ind}]`}
                                                readOnly={readOnlyForm}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        {/*language=SCSS*/}
                        <style jsx>{`
                            @import './src/scss/colors.scss';
                            .quote-table {
                                width: 100%;
                                :global(th) {
                                    text-align: right;
                                    padding-right: 15px;
                                    position: relative;
                                    :global(.add-quote) {
                                        position: absolute;
                                        right: -14px;
                                        top: -15px;
                                        cursor: pointer;
                                    }
                                    :global(.remove-quote) {
                                        position: relative;
                                        top: -5px;
                                        left: calc(50% + 10px);
                                    }
                                }
                                tbody {
                                    tr:nth-child(2n + 1) {
                                        background-color: rgba($light-gray, 0.2);
                                    }
                                    tr:first-child {
                                        :global(.value-cell) {
                                            :global(input) {
                                                text-align: center;
                                            }
                                        }
                                    }
                                    :global(.value-cell) {
                                        padding-left: 30px;
                                        border-right: 1px solid $blue;
                                        text-align: right;
                                        padding-right: 15px;
                                        :global(input) {
                                            text-align: right;
                                        }
                                        &:last-child {
                                            border-right-width: 0;
                                        }
                                    }
                                    td:first-child {
                                        padding: 8px 0px 8px 15px;
                                        font-weight: normal;
                                        font-size: 15px;
                                        line-height: 18px;
                                        color: $default-text-color;
                                    }
                                }
                                .delimiter-row {
                                    border-bottom: 1px solid $border;
                                }
                            }
                            .quote-table.view-mode {
                                :global(.value-cell) {
                                    border-right-width: 0 !important;
                                }
                            }
                        `}</style>
                    </>
                )
            }}
        </Form>
    )
}
function DelimeterRow(props: { span: number }) {
    const cells = []
    for (let i = 0; i < props.span || 0; i++) {
        cells.push(
            <td key={i} className={i > 0 ? 'value-cell' : ''} valign={'middle'}>
                <div className={'delimiter'} />
            </td>,
        )
    }

    return (
        <tr>
            {cells}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    td {
                        height: 26px;
                    }
                    .delimiter {
                        width: 100%;
                        height: 1px;
                        background-color: $border;
                    }
                `}
            </style>
        </tr>
    )
}
