import React, { useCallback, useRef, useState } from 'react'
import { ButtonBlueEdge } from '../Cashflow/ButtonBlueEdge'
import { GreenButton } from '../Cashflow/GreenButton'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { QuoteEdit } from './QuoteEdit'
import { useRouter } from 'next/router'
type Props = any

export function QuoteEditor(props: Props) {
    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const [mode, setMode] = useState('view') //edit
    const toggleMode = useCallback(() => {
        setMode(mode == 'view' ? 'edit' : 'view')
    }, [mode])
    const save = useCallback(async () => {
        const values = editor.current?.getValues()

        const valuesDB = []

        values.title.map((s, ind) => {
            valuesDB.push({
                id: values.id[ind],
                default: values.default[ind] ? true : false,
                vacancy: values.vacancy[ind],
                exitCapRate: values.exitCapRate[ind],
                valuationCapRate: values.valuationCapRate[ind],
                refinanceLTV: values.refinanceLTV[ind],
                name: values.title[ind],
                loanType: values.loanType[ind],
                rate: values.rate[ind],
                index: values.indexType[ind],
                recourse: values.recourse[ind],
                loanPeriod: values.loanPeriod[ind],
                maximumLTC: values.maximumLTC[ind],
                maximumLTV: values.maximumLTV[ind],
                minimumDSCR: values.minimumDSCR[ind],
                amortization: values.amortization[ind],
                estimatedNOI: values.estimatedNOI[ind],
                interestOnly: values.interestOnly[ind],
                repaymentType: values.repaymentType[ind],
                isLoanAssumable: values.isLoanAssumable[ind],
                preliminaryValue: values.preliminaryValue[ind],
                maximumLoanAmount: values.maximumLoanAmount[ind],
                estimatedAllInRate: values.estimatedAllInRate[ind],
                interestAccuralBase: values.interestAccuralBase[ind],
                replacementReserveAndTIEscrow: values.replacementReserveAndTIEscrow[ind],
                supplementalFinancingAvailable: values.supplementalFinancingAvailable[ind],
                mezLoanType: values.mezLoanType[ind],
                mezRate: values.mezRate[ind],
                mezLoanPeriod: values.mezLoanPeriod[ind],
                mezMaximumLTC: values.mezMaximumLTC[ind],
                mezMaximumLTV: values.mezMaximumLTV[ind],
                mezMinimumDSCR: values.mezMinimumDSCR[ind],
                mezAmortization: values.mezAmortization[ind],
                mezInterestOnly: values.mezInterestOnly[ind],
                mezMaximumLoanAmount: values.mezMaximumLoanAmount[ind],
                useSeniorMaxLTCorV: values.useSeniorMaxLTCorV[ind],
                useMezMaxLTCorV: values.useMezMaxLTCorV[ind],
                borrowerNOI: values.borrowerNOI[ind],
            })
        })

        //console.log('saving ', values, valuesDB)

        await SagaHelper.run(['lenderOriginationModel', 'saveQuotes'], { projectId: projectId, data: valuesDB })
        await SagaHelper.run(['lenderOriginationModel', 'loadQuoteData'], { projectId: projectId })

        setMode('view')
    }, [])

    const editor = useRef<any>()
    const addQuote = useCallback(() => {
        editor?.current?.addQuote()
    }, [editor.current])
    return (
        <>
            <div className="quote-editor-tab">
                <div className={'buttons-bar'}>
                    {mode == 'view' ? (
                        <>
                            <ButtonBlueEdge disabled={true} className={'share'} onClick={() => true}>
                                Share
                            </ButtonBlueEdge>
                            <ButtonBlueEdge onClick={toggleMode}>
                                Edit Quote
                                <img src="/_img/lenderDashboard/edit.svg" alt="edit" />
                            </ButtonBlueEdge>
                        </>
                    ) : (
                        <>
                            <ButtonBlueEdge className={'share'} onClick={addQuote}>
                                Add Quote
                                <img src="/_img/lenderDashboard/plus.svg" alt="add quote" />
                            </ButtonBlueEdge>
                            <ButtonBlueEdge onClick={toggleMode} className={'cancel'}>
                                Cancel
                            </ButtonBlueEdge>
                            <GreenButton onClick={save}>Save Changes</GreenButton>
                        </>
                    )}
                </div>
                {mode == 'view' ? (
                    <QuoteEdit mode={'view'} />
                ) : (
                    // @ts-ignore
                    <QuoteEdit mode={'edit'} ref={editor} />
                )}
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .quote-editor-tab {
                    width: 100%;
                    position: relative;
                    background: #ffffff;
                    border: 1px solid #d9e0e5;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 10px rgba($light-gray, 0.0684004);
                    border-radius: 3px;
                    margin-top: 20px;
                    padding: 39px 15px 27px 15px;
                    .buttons-bar {
                        position: absolute;
                        right: 0;
                        top: -50px;
                        :global(.share) {
                            margin-right: 29px;
                        }
                        :global(.cancel) {
                            margin-right: 10px;
                        }
                    }
                }
            `}</style>
        </>
    )
}
