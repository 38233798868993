import React, { CSSProperties, FunctionComponent } from 'react'
import numeral from 'numeral'
import { useSyncScroll } from '../../../../../utils/useSyncScroll'

export type TableTitle = {
    title1: string
    title2?: string
    style?: CSSProperties
}

export type TableRow = {
    title: string
    values: any[]
    cellStyle?: CSSProperties
    rowStyle?: CSSProperties
    rowTitleStyle?: CSSProperties
    rowNumeralFormat?: string
}

type Props = {
    titles: TableTitle[]
    rows: TableRow[]
}

export const ScrollableTable: FunctionComponent<Props> = (props: Props) => {
    const { titles, rows } = props

    useSyncScroll('table-scrollable-container', 'header-scrollable-container')

    return (
        <>
            <div className="table-wrapper">
                <div className="fixed-container">
                    <div className="table-title">
                        <div className="title-cell" />
                    </div>
                    {rows?.map((item, ind) => (
                        <div className="table-row" key={ind} style={item?.rowStyle}>
                            <div className="row-cell" style={item.rowTitleStyle}>
                                {item.title}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="scrollable-container" id={'table-scrollable-container'}>
                    <div className="rotate-wrapper" id={'rotate-wrapper'} style={{ width: titles?.length * 130 }}>
                        <div className="table-title">
                            {titles?.map((item, ind) => (
                                <div className="title-cell" style={item?.style} key={ind}>
                                    <div>{item.title1}</div>
                                    <div>{item.title2}</div>
                                </div>
                            ))}
                        </div>
                        {rows?.map((item, ind) => (
                            <div className="table-row" key={ind} style={item?.rowStyle}>
                                {item.values.map((cell, ynd) => (
                                    <div className="row-cell" key={ynd} style={item?.cellStyle}>
                                        {item?.rowNumeralFormat ? numeral(cell).format(item.rowNumeralFormat) : cell}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                $cell-width: 130px;
                $cell-width-large: 300px;

                .table-wrapper {
                    display: flex;

                    .fixed-container {
                        //border-right: 1px solid $border;
                        //box-shadow: 4px 0px 4px -1px rgba(0, 0, 0, 0.2);
                        z-index: 10;
                        position: relative;
                        top: 5px;

                        .table-title {
                            padding: 0;
                            .title-cell {
                                padding-left: 10px;
                                width: $cell-width-large;
                                min-width: $cell-width-large;
                                max-width: $cell-width-large;
                            }
                        }
                        .table-row {
                            .row-cell {
                                padding-left: 10px;
                                width: $cell-width-large;
                                min-width: $cell-width-large;
                                max-width: $cell-width-large;
                                justify-content: flex-start;
                            }
                        }
                    }

                    .scrollable-container {
                        flex-grow: 1;
                        width: 100%;
                        overflow-x: auto;
                        transform: rotate(180deg);
                        border-left: 3px solid $border;
                        .rotate-wrapper {
                            transform: rotate(180deg);
                        }
                        /* width */
                        &::-webkit-scrollbar {
                            height: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            border-radius: 2px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $gray-blue;
                            border-radius: 2px;
                        }
                    }

                    border: 1px solid $border;
                    background-color: $white;
                    border-radius: 3px;
                    .table-title {
                        display: flex;
                        padding: 15px;
                        height: 66px;
                        .title-cell {
                            text-align: right;
                            padding-right: 15px;
                            width: $cell-width;
                            min-width: $cell-width;
                            max-width: $cell-width;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: $gray-nevada;
                        }
                    }
                    .table-row {
                        display: flex;
                        height: 35px;
                        align-items: center;
                        .row-cell {
                            background-color: rgba(217, 224, 229, 0.15);
                            height: 100%;
                            display: flex;
                            align-items: center;
                            font-size: 15px;
                            color: $black;
                            font-weight: 500;
                            padding-left: 20px;
                        }

                        &:nth-of-type(2n) {
                            .row-cell {
                                background-color: rgba(217, 224, 229, 0.5);
                            }
                        }
                        .row-cell {
                            width: $cell-width;
                            min-width: $cell-width;
                            max-width: $cell-width;
                            justify-content: flex-end;
                        }
                    }
                }
            `}</style>
        </>
    )
}
