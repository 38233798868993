import React, { FunctionComponent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { ReduxStoreState } from '../../../../../../../../store'

type Props = any

export const SupplementalMaturityBalance: FunctionComponent<Props> = React.memo((props) => {
    const refinanceAssumptions = useSelector(
        (state: ReduxStoreState) => state.lender.originationModel.fannieExitScenarioRefinance.refinanceAssumptions[props.page],
    )

    const loanTerm = useMemo(
        () => refinanceAssumptions?.loanDetails?.termInYears,
        [refinanceAssumptions?.loanDetails?.termInYears],
    )

    const balanceNewLoan = useMemo(() => {
        if (!!loanTerm && !!refinanceAssumptions?.balanceNewLoan) {
            const result = refinanceAssumptions.balanceNewLoan.years?.[+loanTerm.toFixed(0) - 1]
            return result || null
        }
    }, [loanTerm, refinanceAssumptions?.balanceNewLoan])

    const balanceExistingLoan = useMemo(() => {
        if (!!loanTerm && !!refinanceAssumptions?.balanceExistingLoan) {
            const result = refinanceAssumptions.balanceExistingLoan.years?.[+loanTerm.toFixed(0) - 1]
            return result || null
        }
    }, [loanTerm, refinanceAssumptions?.balanceExistingLoan])

    const balanceCombined = useMemo(() => {
        if (!!loanTerm && !!refinanceAssumptions?.balanceCombined) {
            const result = refinanceAssumptions.balanceCombined.years?.[+loanTerm.toFixed(0) - 1]
            return result || null
        }
    }, [loanTerm, refinanceAssumptions?.balanceCombined])

    return (
        <>
            <div className={'tableWrapper'}>
                <div className={'title'}>Supplemental Maturity Balance</div>
                <div className={'mainPart'}>
                    <table>
                        <thead>
                            <tr>
                                <td />
                                <td />
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'rowLabel'}>Balance: New Loan</td>
                                <td className={'rowValue'}>
                                    {!!balanceNewLoan && numeral(balanceNewLoan).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className={'rowLabel'}>Balance: Existing Loan(s)</td>
                                <td className={'rowValue'}>
                                    {!!balanceExistingLoan && numeral(balanceExistingLoan).format('$0,0')}
                                </td>
                            </tr>
                            <tr>
                                <td className={'rowLabel'}>Balance: Combined</td>
                                <td className={'rowValue'}>
                                    {!!balanceCombined && numeral(balanceCombined).format('$0,0')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                .tableWrapper {
                    border: 1px solid #d9e0e5;
                    box-shadow: 0px 4px 10px rgba(92, 99, 110, 0.0684004);
                    border-radius: 3px;
                    flex: 1;
                    box-sizing: content-box;
                    margin-bottom: 30px;
                    background: white;
                }
                .rowLabel {
                    width: 70%;
                }
                .rowValue {
                    width: 30%;
                }
                .title {
                    font-family: 'Inter';
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #d9e0e5;
                    font-style: normal;
                    font-size: 15px;
                    line-height: 18px;
                    text-indent: 20px;
                    font-weight: bold;
                    color: #161c26;
                }
                table {
                    margin-top: 20px;
                    text-indent: 20px;
                    width: 100%;
                }
                thead {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    color: #676f7a;
                }
                td {
                    text-align: left;
                }
                tbody {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;

                    color: #262b35;

                    tr {
                        height: 35px;
                        &:nth-of-type(odd) {
                            background: rgba(217, 224, 229, 0.15);
                        }
                    }
                }
                :global(.refiAssumptionField) {
                    text-align: left !important;
                    position: relative;
                    width: initial !important;
                }
            `}</style>
        </>
    )
})
