import React from 'react'
import dynamic from 'next/dynamic'
import { ApexOptions } from 'apexcharts'
// eslint-disable-next-line @typescript-eslint/naming-convention
const Chart = dynamic(() => import('react-apexcharts'), {
    ssr: false,
})

type Props = {
    labels: any[]
    values: any[]
    width?: number | string
    customLabelColor?: string
}

export function ApexPieChart(props: Props) {
    const { customLabelColor } = props
    const options = {
        series: props.values,
        chart: {
            width: props.width || 600,
            type: 'pie',
        },
        // colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'], //default color scheme
        // colors: ['#3F51B5', '#5653FE', '#2983FF', '#00B1F2', '#81D4FA'], //blue color scheme
        colors: ['#dd0037', '#091d35', '#362339'],
        labels: props.labels,
        legend: {
            show: true,
            labels: {
                colors: [`${customLabelColor ? customLabelColor : '#fff'}`],
            },
        },
        tooltip: {
            theme: 'dark',
            x: {
                formatter: function (val) {
                    return val
                },
            },
            y: {
                formatter: function (val) {
                    return val
                },
            },
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        width: 300,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    }

    return (
        <>
            <Chart
                options={options as ApexOptions}
                series={options.series}
                type="pie"
                width={props.width ? props.width + 'px' : '600px'}
            />
            {/*language=scss*/}
            <style jsx>{`
                :global(.apexcharts-canvas) {
                    margin: 0 auto;
                }
            `}</style>
        </>
    )
}
