import React, {
    forwardRef,
    SyntheticEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react'
import { Form, useForm, useFormState } from 'react-final-form'

import { CashFlow } from '../../../../../../../store/types/OriginationModelRedux'

import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'

import { OriginationModelReworked } from '@generated/graphql'
import { FixedCashflowTableHeader } from './../../FixedCashflowTableHeader'
import { useSyncScroll } from '../../../../../../../utils/useSyncScroll'
import { useRouter } from 'next/router'

import arrayMutators from 'final-form-arrays'
import { CashFlowRowGroup } from './CashFlowRowGroup'
import { CashFlowRowComp } from './CashFlowRow'

const cloneDeep = require('lodash.clonedeep')

type Props = {
    data: CashFlow
    model?: OriginationModelReworked
}
export const CashFlowEdit = forwardRef(CashFlowEditFunc)
export function CashFlowEditFunc(props: Props, ref) {
    const model = useSelector((state: ReduxStoreState) => state.lender?.originationModel) || props.model
    //console.log('CF DATA', props.data)
    const formData = useMemo(() => {
        return {
            ...cloneDeep(props.data),
            readOnly: true,
        }
    }, [props.data])

    const context = useMemo(() => ({ form: null, componentLoaded: false, lastUpdate: null }), [])

    const onSubmit = useCallback(
        (values) => {
            try {
                return null
            } catch (e) {
                console.log(e.message)
            }
        },
        [props],
    )
    useImperativeHandle(ref, () => ({
        getValues: () => {
            return context.form?.getState()?.values || {}
        },
    }))

    const computedValues = useMemo(() => {
        return []
    }, [])

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={formData}
                decorators={computedValues}
                mutators={{
                    ...arrayMutators,
                    insertAt: ([name, index, value], state, { changeValue }) => {},
                    addCol: (params, state, form) => {},
                    removeCol: (params, state, form) => {},
                }}
            >
                {(formProps) => {
                    context.form = formProps.form
                    return <CashFlowEditForm mode={'view'} onSubmit={onSubmit} />
                }}
            </Form>
        </>
    )
}

const CashFlowEditForm = (props: { mode?: 'view'; onSubmit: any }) => {
    const form = useForm()
    const formState = useFormState()

    const colIterator = useMemo(() => {
        const formLength = formState?.values?.retailRentalIncome[0]?.years.length || 5
        return new Array(formLength).fill(0)
    }, [formState?.values?.retailRentalIncome[0]?.years.length])

    const tableTitles = []
    for (let i = 1; i <= (formState.values?.retailRentalIncome[0]?.years?.length || 5); i++) {
        tableTitles.push(<th key={i}>YEAR {i}</th>)
    }

    const [cashFlowTitlesVisible, setCashFlowTitlesVisible] = useState(false)

    useSyncScroll('cashflow-scrollable-container', 'scrollable-header')

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollPosition = window?.scrollY
            if (scrollPosition > 250) {
                setCashFlowTitlesVisible(true)
                const container = document?.getElementById('cashflow-scrollable-container')
                const header = document?.getElementById('scrollable-header')
                if (header && container) {
                    header.scrollLeft = container?.scrollLeft
                    header.addEventListener('scroll', () => (container.scrollLeft = header?.scrollLeft))
                }
            } else {
                setCashFlowTitlesVisible(false)
            }
        }
        window.addEventListener('scroll', handleScroll, false)
    }, [cashFlowTitlesVisible])

    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit

    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const accountType = useMemo(() => {
        return project?.accountType
    }, [project])

    return (
        <form
            className={'cashflow-container'}
            id={'cash-flow-form'}
            onSubmit={(e) => {
                e.preventDefault()
                props.onSubmit()
            }}
        >
            <FixedCashflowTableHeader
                yearsCount={formState.values?.retailRentalIncome[0]?.years?.length || 5}
                visible={cashFlowTitlesVisible}
                showTxAndAdjusted={true}
                showTrend={false}
            />
            <div className="tables-container">
                <table className="part-1 table-fixed" style={{ width: '350px' }} cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th style={{ width: 150, paddingRight: 25 }} colSpan={4}>
                                Adjusted {/*APR */}
                                {/*T3/T12*/}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={'group'} colSpan={4}>
                                Income
                            </td>
                        </tr>

                        <CashFlowRowGroup fieldName={'retailRentalIncome'} onlyTitles={true} />
                        <CashFlowRowComp
                            fieldName={'retailEffectiveGrossIncome'}
                            readOnly={true}
                            className={'readOnly'}
                            addRowGroup={'retailRentalIncome'}
                            onlyTitles={true}
                        />

                        <tr>
                            <td className={'group'} colSpan={4}>
                                Operating Expenses
                            </td>
                        </tr>
                        <CashFlowRowGroup
                            fieldName={'retailOperatingExpenses'}
                            onlyTitles={true}
                            inverseValues={props?.mode == 'view'}
                        />
                        <CashFlowRowComp
                            fieldName={'retailTotalOperatingExpenses'}
                            readOnly={true}
                            className={'readOnly'}
                            addRowGroup={'retailOperatingExpenses'}
                            inverseValues={props?.mode == 'view'}
                            onlyTitles={true}
                        />
                        <tr>
                            <td className={'group'} colSpan={4}>
                                {' '}
                            </td>
                        </tr>
                        <CashFlowRowComp fieldName={'retailNOI'} readOnly={true} onlyTitles={true} />
                    </tbody>
                </table>

                <div className={'scrollable-container'} id={'cashflow-scrollable-container'}>
                    <table
                        className="part-1 table-scrollable'"
                        id={'table-scrollable'}
                        style={{ width: '170px' }}
                        cellPadding={0}
                        cellSpacing={0}
                    >
                        <thead>
                            <tr>{tableTitles}</tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={'group'} colSpan={2} style={{ height: 54 }}></td>
                            </tr>
                            <CashFlowRowGroup fieldName={'retailRentalIncome'} />
                            <CashFlowRowComp
                                fieldName={'retailEffectiveGrossIncome'}
                                readOnly={true}
                                className={'readOnly'}
                                addRowGroup={'retailRentalIncome'}
                            />

                            <tr>
                                <td className={'group'} colSpan={2} style={{ height: 54 }}>
                                    {/*Operating Expenses*/}
                                </td>
                            </tr>

                            <CashFlowRowGroup
                                fieldName={'retailOperatingExpenses'}
                                inverseValues={props?.mode == 'view'}
                            />
                            <CashFlowRowComp
                                fieldName={'retailTotalOperatingExpenses'}
                                readOnly={true}
                                className={'readOnly'}
                                addRowGroup={'retailOperatingExpenses'}
                                inverseValues={props?.mode == 'view'}
                            />
                            <tr>
                                <td className={'group'} colSpan={4}>
                                    {' '}
                                </td>
                            </tr>
                            <CashFlowRowComp fieldName={'retailNOI'} readOnly={true} />
                        </tbody>
                    </table>
                </div>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .cashflow-container {
                    background-color: #fff;
                    border-radius: 3px;
                    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    padding-bottom: 0;
                    padding-top: 5px;
                    .add-col-button {
                        color: $white;
                        display: inline-block;
                        position: absolute;
                        left: calc(100% - 20px);
                        top: 20px;
                        transform: rotate(-90deg);
                        cursor: pointer;
                    }
                }

                table.part-1 {
                    width: 100%;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;

                    thead {
                        :global(th) {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 17px;
                            color: $secondary-text-color;
                            text-align: right;
                            position: relative;
                            height: 58px;

                            :global(.delete-column) {
                                position: absolute;
                                right: 15px;
                                bottom: 35px;
                                background: #fff;
                                padding: 0px 5px 10px 5px;
                                border-radius: 3px;
                                cursor: pointer;
                            }
                        }

                        th:first-child {
                            width: 300px;
                        }
                    }

                    .group {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 400;
                        color: $default-text-color;
                        padding-top: 20px;
                        padding-bottom: 10px;
                        padding-left: 10px;
                    }

                    :global(td:nth-child(2)),
                    th:nth-child(2),
                    .group {
                        padding-right: 24.5px;
                    }

                    :global(.cash_flow),
                    :global(.readOnly) {
                        :global(td:nth-child(2)) {
                            border-right-width: 0;
                        }
                    }
                }

                table.part-2 {
                    width: 100%;
                    margin-top: 14px;
                    table-layout: fixed;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;

                    td {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    td:first-child {
                        width: 135px;
                        padding-left: 20px;
                    }

                    td:nth-child(2) {
                        width: 75px;
                        padding-right: 15px;
                    }

                    td:nth-child(3) {
                        width: 90px;
                        padding-left: 15px;
                    }

                    td:nth-child(4) {
                        padding-right: 24.5px;
                    }

                    td:nth-child(n + 4):nth-child(-n + 10) {
                        width: calc((100% - 300px) / 7);
                        text-align: right;
                    }

                    tr:first-child,
                    tr:nth-child(2) {
                        border-bottom: 1px solid $grid-row-selected-bg;
                    }
                }

                table.part-1,
                table.part-2 {
                    :global(.input-container:after) {
                        bottom: 0px;
                        border-top: 1px solid #d9e0e5;
                        opacity: 1;
                    }

                    :global(.input-container) {
                        width: auto;
                        position: relative;
                        display: flex;

                        :global(input) {
                            flex: 1;
                        }
                    }

                    :global(td:nth-child(n + 2)) {
                        :global(.input-container) {
                            margin-left: 20%;
                        }
                    }

                    :global(.input-container.readOnly:after) {
                        display: none;
                    }
                }

                .table-title {
                    text-align: left;
                    font-size: 1.4rem;
                }

                .tables-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                }

                .table {
                    color: white;
                    border: 1px solid rgba(255, 255, 255, 0.45);

                    tr {
                        display: flex;

                        td,
                        th {
                            min-width: 90px;
                        }
                    }

                    .bold {
                        font-weight: bold;
                    }

                    .top-border {
                        border-top: 1px solid rgba(255, 255, 255, 0.45);
                    }

                    margin-bottom: 0;
                }

                .scrollable-container {
                    overflow-x: auto;
                    white-space: nowrap;
                    flex: 1;
                    padding-bottom: 2px;
                    border-left: 3px solid $grid-row-selected-bg;
                    overflow-y: hidden;
                    transform: rotateX(180deg);
                    //position: relative;
                    //top: -7px;
                    margin-top: -7px;

                    #table-scrollable {
                        transform: rotateX(180deg);
                    }

                    &::-webkit-scrollbar {
                        height: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        border-radius: 2px;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: $gray-blue;
                        border-radius: 2px;
                    }
                }

                .table-border-right {
                    position: absolute;
                    height: calc(100% - 12px);
                    width: 1px;
                    right: 0px;
                    top: 0;
                    background: rgba(255, 255, 255, 0.45);
                }
                .table-scrollable {
                    border-right-width: 0;
                    border-left-width: 0;
                }

                .table-fixed {
                    border-right-width: 0;
                }

                .bottom-row {
                    text-align: right;
                    font-weight: 500;
                    height: 50px;

                    .border-right {
                        border-right: 1px solid $grid-row-selected-bg;
                    }

                    .border-bottom {
                        border-bottom: 1px solid $grid-row-selected-bg;
                    }
                }
            `}</style>
        </form>
    )
}
