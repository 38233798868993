import React from 'react'

type Props = {
    title: string
    value?: string
    className?: string
    datebox?: any
}

export function KPIBox(props: Props) {
    return (
        <div className={`${props.className || 'card'}`}>
            <div className={'title'}>{props.title}</div>
            {props.value && <div className={'value'}>{props.value}</div>}
            {props.datebox && <div>{props.datebox}</div>}
            {/*language=SCSS*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .card {
                        width: 300px;
                        padding: 15px 20px;
                        border-radius: 4px;

                        .title {
                            color: #97a2b4;
                            margin-bottom: 5px;
                        }

                        .value {
                            font-size: 22px;
                            font-weight: 500;
                        }
                    }
                `}
            </style>
        </div>
    )
}
