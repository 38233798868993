import React, { FunctionComponent, useEffect } from 'react'
import { ReduxStoreState } from '../../../../../../../store'
import { SagaHelper } from '../../../../../../../store/sagaHelper'
import { useSelector } from 'react-redux'
import { parseUnitMixData } from '../../../../../../../utils/parseUnitMix'

type Props = {
    project: any
}

export const UnitMixTable: FunctionComponent<Props> = (props: Props) => {
    const { project } = props

    useEffect(() => {
        if (project.id) {
            SagaHelper.run(['fullProjectTheProperty', 'getData'], { projectId: project.id })
        }
    }, [project.id])

    const projectData = useSelector((state: ReduxStoreState) => state?.fullProject?.theProperty?.data)

    const tableRows = parseUnitMixData(projectData).tableRows

    return (
        <div className="chart-wrapper">
            <div className="title">Unit Mix Table</div>
            <table className="table table-borderless table-striped table--large table--smallmobile">
                <thead>
                    <tr>
                        <th>Unit type</th>
                        <th className="text-center">No.</th>
                        <th className="text-center">%</th>
                        <th className="text-center">Size (sf)</th>
                        <th className="text-right">Rent</th>
                        <th className="text-right">Rent (sf)</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows?.map((row, i) => (
                        <tr key={row.unit + i}>
                            <td>{row.unit}</td>
                            <td className="text-center">{row.numOfUnits}</td>
                            <td className="text-center">{row.percent}%</td>
                            <td className="text-center">{row.size} sf</td>
                            <td className="text-right font-weight-bold">${row.price}</td>
                            <td className="text-right font-weight-bold">{row.pricesqft} $/sf</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .chart-wrapper {
                    background-color: #fff;
                    margin: 0px;
                    border: 1px solid #d9e0e5;
                    height: 260px;
                    border-radius: 3px;
                    .title {
                        font-size: 15px;
                        font-weight: 500;
                        color: #161c26;
                        border-bottom: 1px solid #d9e0e5;
                        padding: 10px 20px;
                    }
                    table {
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    )
}
