import React from 'react'

import { calcMinMax } from '../../../../hooks/useModel'
import { CashFlowRowGraphQl } from '@generated/graphql'
type Props = {
    data: CashFlowRowGraphQl & { avg?: number }
    total: number
}

export function ExpensesBulletChart(props: Props) {
    const avg = props.data.avg
    const { min, max } = calcMinMax(props.data.years)
    const total = props.total
    const startPos = `${Math.round((min / total) * 100)}%`
    const width = `${Math.round(((max - min) / total) * 100)}%`

    const avgPos = `calc(${Math.round((avg / total) * 100)}% - 9px)`

    return (
        <>
            <div className={'divider-container'}>
                {jsxMap(10).map((i) => {
                    return <div key={`divider-${i}`} className={'divider'} style={{ left: `calc(${i * 10 + 5}% )` }} />
                })}
            </div>

            <div className={'chart-container'}>
                <div
                    className={'chart-bar'}
                    style={{
                        marginLeft: startPos,
                        width,
                    }}
                />
                <div className={'average'} style={{ left: avgPos }} />
            </div>
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .chart-container {
                    position: relative;
                    z-index: 100;
                    margin-left: 5%;
                    margin-right: 5%;
                }
                .average {
                    width: 18px;
                    height: 21px;
                    background-color: $light-blue;
                    border-radius: 3px;
                    position: absolute;
                    top: 0;
                }
                .chart-bar {
                    background-color: $light-blue;
                    height: 21px;
                    opacity: 0.3;
                    border-radius: 3px;
                }
                .divider {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    border-right: 1px solid rgba($gray-mystic, 0.7);
                }
                .divider-container {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            `}</style>
        </>
    )
}
function jsxMap(count: number) {
    const out = []
    for (let i = 0; i < count; i++) {
        out.push(i)
    }
    return out
}
