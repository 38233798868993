import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { REFinancingTab } from './REFinancingTab'
import { FinancingTab } from './FinancingTab'

export type MortgageSchedule = {
    endBalance: number
    schedule: Array<{ interest: number; principal: number; total: number }>
}

export const FinancingPage = (props) => {
    const TAB = Object.freeze({
        FINANCE: 'FINANCE',
        REFINANCE: 'REFINANCE',
    })
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)

    let testTab = TAB.FINANCE
    if (project.accountType == 'lender') testTab = TAB.REFINANCE

    const [activeTab, setActiveTabs] = useState(testTab)

    return (
        <div>
            {project.accountType == 'lender' && (
                <div className="widget-tabs">
                    <div
                        className={`widget-tab ${activeTab == TAB.REFINANCE ? 'active' : ''} `}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveTabs(TAB.REFINANCE)
                        }}
                    >
                        Refinance
                    </div>
                    <div
                        className={`widget-tab ${activeTab == TAB.FINANCE ? 'active' : ''} `}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveTabs(TAB.FINANCE)
                        }}
                    >
                        Finance
                    </div>
                </div>
            )}

            {project.accountType == 'borrower' && (
                <div className="widget-tabs">
                    <div
                        className={`widget-tab ${activeTab == TAB.FINANCE ? 'active' : ''} `}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveTabs(TAB.FINANCE)
                        }}
                    >
                        Finance
                    </div>
                    <div
                        className={`widget-tab ${activeTab == TAB.REFINANCE ? 'active' : ''} `}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setActiveTabs(TAB.REFINANCE)
                        }}
                    >
                        ReFinance
                    </div>
                </div>
            )}

            {activeTab == TAB.FINANCE && <FinancingTab />}
            {activeTab == TAB.REFINANCE && <REFinancingTab />}

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .widget-title {
                    padding: 12px 20px;
                    border: 1px solid $border;
                    border-bottom: 0;
                    background: $white;
                    font-size: 15px;
                    font-weight: 500;
                    color: $black;
                }
                .widget-tabs {
                    display: flex;
                    background-color: $white;
                    padding: 10px;
                    border-left: 1px solid $border;
                    border-right: 1px solid $border;
                    border-top: 1px solid $border;
                    .widget-tab {
                        font-size: 15px;
                        margin-right: 32px;
                        font-weight: 500;
                        cursor: pointer;
                        &.active {
                            color: $blue;
                            position: relative;
                            &:after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 3px;
                                bottom: -3px;
                                left: 0;
                                border-top: 3px solid #4485ff;
                                border-top-right-radius: 15px;
                                border-top-left-radius: 15px;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
