import { Notification } from '../store/types/notification'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../store'
import { useEffect } from 'react'

export function useNotificationSubscription(type: string, callBack: (notification: Notification) => void) {
    const lastNotification: Notification | null = useSelector(
        (state: ReduxStoreState) => state.notification.lastNotification,
    )
    useEffect(() => {
        if (lastNotification?.type == type) {
            try {
                callBack(lastNotification)
            } catch (e) {
                console.error(e)
            }
        }
    }, [lastNotification])
    return lastNotification
}
