import React, { useState, useCallback, SyntheticEvent, useMemo } from 'react'
import { HistoricalTabs } from '../../../../../controls/HistoricalTabs'
import { RefinanceAssumptions } from './RefinanceAssumptions'
import { BorrowerCashFlow } from '../../Cashflow/cashFlowTabs/BorrowerCashFlow/BorrowerCashFlow'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../store'

const tabs = [
    {
        value: 0,
        label: 'Standard',
    },
    {
        value: 1,
        label: 'Alternative',
    },
]

type Props = any

export const ExitAnasysisPage = React.memo(function ExitAnasysisPage(props: Props) {
    //console.log('ExitAnasysisPage')
    const selectedPage = useSelector(
        (state: ReduxStoreState) => state.lender.originationModel.fannieExitScenarioRefinance.selectedPage,
    )
    const [activeTab, setActiveTab] = useState(selectedPage || tabs[0].value)
    const [showCashFlowProjections, setShowCashFlowProjections] = useState(true)
    const [showRefinanceAssumptions, setShowRefinanceAssumptions] = useState(true)

    const toggleCashFlowProjections = useCallback((e: SyntheticEvent) => {
        setShowCashFlowProjections((state) => !state)
    }, [])

    const toggleRefinanceAssumptions = useCallback((e: SyntheticEvent) => {
        setShowRefinanceAssumptions((state) => !state)
    }, [])

    const trendsName = useMemo(() => {
        //console.log('ExitAnasysisPage trendsName', activeTab)
        if (!activeTab) return 'exitScr'
        else return 'exitScrAlterntive'
    }, [activeTab])
    const updateModelUnderwritingAssumption = useMemo(() => {
        return trendsName == 'exitScr'
    }, [trendsName])

    const handlePageChange = (value) => {
        setActiveTab(value)
        ReduxHelper.setIn(['lender', 'originationModel', 'fannieExitScenarioRefinance', 'selectedPage'], value)
    }
    return (
        <div style={{ marginTop: 10 }}>
            <HistoricalTabs tabs={tabs} activeTab={activeTab} onChange={handlePageChange} />
            <div className={'sectionTitle'}>
                <span className={'collapseSpan'} onClick={toggleCashFlowProjections}>
                    <i className="material-icons-outlined">{showCashFlowProjections ? 'expand_less' : 'expand_more'}</i>
                </span>
                Cash Flow Projections
            </div>
            {showCashFlowProjections && (
                <BorrowerCashFlow
                    showKPIs={false}
                    showOther={false}
                    showTrend={true}
                    showTxAndAdjusted={false}
                    trendsName={trendsName}
                    updateModelUnderwritingAssumption={updateModelUnderwritingAssumption}
                    saveDeal={props.saveDeal}
                    isDashboard
                />
            )}
            <div className={'sectionTitle'}>
                <span className={'collapseSpan'} onClick={toggleRefinanceAssumptions}>
                    <i className="material-icons-outlined">
                        {showRefinanceAssumptions ? 'expand_less' : 'expand_more'}
                    </i>
                </span>
                Refinance Analysis
            </div>
            {showRefinanceAssumptions && <RefinanceAssumptions page={activeTab} />}
            {/*language=scss*/}
            <style jsx>{`
                .sectionTitle {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 10px;
                    margin-top: 20px;
                }
                .collapseSpan {
                    cursor: pointer;
                    cursor: pointer;
                    top: 5px;
                    position: relative;
                    margin-right: 5px;
                }
            `}</style>
        </div>
    )
})
