import React, { useEffect, useState } from 'react'
import { SensitivityChartData } from '@generated/graphql'
import { ReduxStoreState } from '../../../../../../../store'
import { SpinnerOrError } from '../../../../components/SpinnerOrError'

import { irrSensitivity } from './irrSensitivity'
import { useSelector } from 'react-redux'
import { dscrSensivity } from './dscrSensivity'
import { exitPriceSensitivity } from './exitPriceSensitivity'

type Props = {
    projectId: number
}

export function SensitivityAnalysisBorrower(props: Props) {
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender?.originationModel?.cashFlow)
    const model = useSelector((state: ReduxStoreState) => state.lender?.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state.lender?.unitMix)
    const colors = {
        transparent: 'transparent',
        blue: '#27487d',
        purple: '#55466a',
        red: '#672f3f',
    }
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState<SensitivityChartData[]>([])

    useEffect(() => {
        if (cashFlow && cashFlow?.noi) {
            setLoading(false)
            setTableData([
                irrSensitivity(cashFlow as any, model),
                dscrSensivity(cashFlow, model, unitMix),
                exitPriceSensitivity(cashFlow, model),
            ])
        }
    }, [cashFlow, model, unitMix])

    const [activeTab, setActiveTab] = useState(1)
    const data = tableData ? tableData[activeTab - 1] : null
    if (!cashFlow) return null
    return (
        <div className={'lender-dashboard-table'}>
            <div className="table-nav">
                <div className="title">Sensitivity Analysis</div>
                <div className="items">
                    <div className={`item ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                        IRR
                    </div>
                    <div className={`item ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
                        DSCR
                    </div>
                    <div className={`item ${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>
                        Exit Price
                    </div>
                </div>
            </div>
            {!loading ? (
                <div className="table-body">
                    <div className="table-vertical-title">{data?.yAxisName}</div>
                    {/*// @ts-ignore*/}
                    <table border={1} bordercolor={'#161c26'}>
                        <thead>
                            <tr>
                                <th colSpan={1 + data?.columnTitles.length} className={'table-title'}>
                                    {data?.xAxisName}
                                </th>
                            </tr>
                            <tr>
                                <th style={{ width: '200px' }} />
                                {data?.columnTitles.map((title) => (
                                    <th
                                        key={title}
                                        style={{
                                            width: `${(100 / (data?.columnTitles.length + 1)).toFixed(0)}%`,
                                        }}
                                    >
                                        {title}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {/*@ts-ignore*/}
                            {data.data.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{data.rowTitles[index]}</td>
                                        {row.map((item, itemIndex) => {
                                            let color = colors.purple
                                            if (item.severity == 1) {
                                                color = colors.red
                                            }
                                            if (item.severity == 0) {
                                                color = colors.blue
                                            }
                                            return (
                                                <td key={itemIndex} style={{ backgroundColor: color, color: '#fff' }}>
                                                    {item.value}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <SpinnerOrError height={'254px'} error={error} />
            )}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .lender-dashboard-table {
                    border: 1px solid $border;
                    background: $white;
                    border-radius: 3px;
                    width: 100%;
                    padding: 0 0 20px 0;
                    .table-nav {
                        display: flex;
                        border-bottom: 1px solid $border;

                        .title {
                            flex-grow: 1;
                            font-size: 15px;
                            font-weight: 500;
                            color: $black;
                            padding: 10px;
                        }

                        .items {
                            flex-grow: 4;
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            top: 1px;
                            position: relative;

                            .item {
                                min-width: 280px;
                                padding: 10px 30px;
                                transition: 0.2s all linear;
                                cursor: pointer;
                                font-size: 14px;
                                color: #676f7a;
                                border-bottom: 1px solid transparent;
                                opacity: 0.7;
                                font-weight: 500;
                                text-align: center;

                                &.active {
                                    color: $blue;
                                    border-top-left-radius: 5px;
                                    border-top-right-radius: 5px;
                                    opacity: 1;
                                    border: 1px solid rgba(255, 255, 255, 0.1);
                                    border-bottom: transparent;
                                    position: relative;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        width: 100%;
                                        height: 3px;
                                        bottom: 0;
                                        left: 0;
                                        border-top: 3px solid $blue;
                                        border-top-right-radius: 15px;
                                        border-top-left-radius: 15px;
                                    }
                                }
                            }
                        }
                    }

                    .table-body {
                        background: $white;

                        .table-title {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #676f7a;
                            text-align: center !important;
                        }

                        .table-vertical-title {
                            font-size: 12px;
                            z-index: 100;
                            font-weight: bold;
                            color: #676f7a;
                            position: absolute;
                            top: calc(50% + 30px);
                            left: 30px;
                            text-transform: uppercase;
                        }

                        table {
                            width: 100%;
                            font-size: 0.9rem;
                            color: #ffffff;
                            border-color: transparent;

                            thead {
                                tr {
                                    th {
                                        text-align: right;
                                        padding: 5px 10px;
                                        color: $black;
                                        font-weight: 400;
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        text-align: right;
                                        padding: 5px 10px;
                                        transition: 0.2s all linear;
                                        position: relative;
                                        color: $black;
                                        &:hover {
                                            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
                                            z-index: 100;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
