import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { SagaHelper } from '../../../../../../../store/sagaHelper'
import { ApexPieChart } from '../../../../../../charts/ApexPieChart'
import { parseUnitMixData } from '../../../../../../../utils/parseUnitMix'

type Props = {
    project: any
}

export const UnitMixChart: FunctionComponent<Props> = (props: Props) => {
    const { project } = props

    useEffect(() => {
        if (project.id) {
            SagaHelper.run(['fullProjectTheProperty', 'getData'], { projectId: project.id })
        }
    }, [project.id])

    const projectData = useSelector((state: ReduxStoreState) => state?.fullProject?.theProperty?.data)

    const chartData = parseUnitMixData(projectData)?.chartData

    return (
        <div className="chart-wrapper">
            <div className="title">Unit Mix Chart</div>
            <ApexPieChart values={chartData.series} labels={chartData.labels} width={400} customLabelColor={'#000'} />
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .chart-wrapper {
                    background-color: #fff;
                    margin: 0px;
                    border: 1px solid #d9e0e5;
                    height: 260px;
                    border-radius: 3px;
                    .title {
                        font-size: 15px;
                        font-weight: 500;
                        color: #161c26;
                        border-bottom: 1px solid #d9e0e5;
                        padding: 10px 20px;
                    }
                }
            `}</style>
        </div>
    )
}
