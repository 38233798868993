import { PricingOption } from '@generated/graphql'
import { getTreasuryBondsQuote } from '../../../../../../utils/treasuryBonds'

type OptionString = {
    value: string
    label: string
}

type OptionNmber = {
    value: number
    label: string
}

export const rateLockTypeList: Array<OptionString> = [
    { value: 'None', label: 'None' },
    { value: 'Standard', label: 'Standard' },
    { value: 'Streamlined', label: 'Streamlined' },
]

export const constraintList: Array<OptionString> = [
    { value: 'Purchase Price', label: 'Purchase Price' },
    { value: 'DSCR', label: 'DSCR' },
    { value: 'LTV', label: 'LTV' },
    { value: 'Requested Amount', label: 'Requested Amount' },
]

export const tierList: Array<OptionNmber> = [
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
]

export const prepaymentOptionsList: any = {
    5: [
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 4.5,
            label: '4.5',
        },
        {
            value: '5-4-3-2-1',
            label: '5-4-3-2-1',
        },
    ],
    7: [
        {
            value: 3,
            label: '3',
        },
        {
            value: 4,
            label: '4',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 6,
            label: '6',
        },
        {
            value: 6.5,
            label: '6.5',
        },
        {
            value: '2 Loan Year Lock Out',
            label: '2 Loan Year Lock Out',
        },
        {
            value: '5-4-3-2-1',
            label: '5-4-3-2-1',
        },
    ],

    10: [
        {
            value: 5,
            label: '5',
        },
        {
            value: 6,
            label: '6',
        },
        {
            value: 7,
            label: '7',
        },
        {
            value: 8,
            label: '8',
        },
        {
            value: 9.5,
            label: '9.5',
        },
        {
            value: '5-5-4-4-3-3-2-2-1-1',
            label: '5-5-4-4-3-3-2-2-1-1',
        },
    ],

    12: [
        {
            value: 10,
            label: '10',
        },
        {
            value: 11.5,
            label: '11.5',
        },
        {
            value: '5% Loan Years 1 through 4',
            label: '5% Loan Years 1 through 4',
        },
        {
            value: '4% Loan Years 5 through 6',
            label: '4% Loan Years 5 through 6',
        },
        {
            value: '3% Loan Years 7 through 8',
            label: '3% Loan Years 7 through 8',
        },
        {
            value: '2% Loan Years 9 through 10',
            label: '2% Loan Years 9 through 10',
        },
        {
            value: '1% through the 90 days prior to Maturity Date',
            label: '1% through the 90 days prior to Maturity Date',
        },
    ],

    15: [
        {
            value: 10,
            label: '10',
        },
        {
            value: 14.5,
            label: '14.5',
        },
        {
            value: '5% Loan Years 1 through 7',
            label: '5% Loan Years 1 through 7',
        },
        {
            value: '4% Loan Years 8 through 9',
            label: '4% Loan Years 8 through 9',
        },
        {
            value: '3% Loan Years 10 through 11',
            label: '3% Loan Years 10 through 11',
        },
        {
            value: '2% Loan Years 12 through 13',
            label: '2% Loan Years 12 through 13',
        },
        {
            value: '1% through the 90 days prior to Maturity Date',
            label: '1% through the 90 days prior to Maturity Date',
        },
    ],

    18: [
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
        {
            value: '5% Loan Years 1 through 7',
            label: '5% Loan Years 1 through 7',
        },
        {
            value: '4% Loan Years 8 through 9',
            label: '4% Loan Years 8 through 9',
        },
        {
            value: '3% Loan Years 10 through 11',
            label: '3% Loan Years 10 through 11',
        },
        {
            value: '2% Loan Years 12 through 13',
            label: '2% Loan Years 12 through 13',
        },
        {
            value: '1% through the 90 days prior to Maturity Date',
            label: '1% through the 90 days prior to Maturity Date',
        },
    ],

    '19-29': [
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
    ],

    30: [
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
        {
            value: '5% Loan Years 1 through 7',
            label: '5% Loan Years 1 through 7',
        },
        {
            value: '4% Loan Years 8 through 9',
            label: '4% Loan Years 8 through 9',
        },
        {
            value: '3% Loan Years 10 through 11',
            label: '3% Loan Years 10 through 11',
        },
        {
            value: '2% Loan Years 12 through 13',
            label: '2% Loan Years 12 through 13',
        },
        {
            value: '1% through the 90 days prior to Maturity Date',
            label: '1% through the 90 days prior to Maturity Date',
        },
    ],
}

export const InterestBasisList: Array<OptionString> = [
    { value: 'None', label: 'None' },
    { value: 'Actual / 360', label: 'Actual / 360' },
    { value: '30/360', label: '30/360' },
]

export const refIndexListFixed: Array<OptionString> = [
    { value: '10 year', label: '10 Year' },
    { value: '7 year', label: '7 Year' },
    { value: '5 year', label: '5 Year' },
    { value: '30 Days', label: '30 Days' },
]

export const refIndexListARM: Array<OptionString> = [{ value: 'SOFR 30 Days AVG', label: 'SOFR 30 Days AVG' }]

export const supplementalTypeList: Array<OptionString> = [
    { value: 'Coterminous', label: 'Coterminous' },
    { value: 'New Loan Test', label: 'New Loan Test' },
    { value: 'Non-Coterminous', label: 'Non-Coterminous' },
    { value: 'DUS Split 2nd', label: 'DUS Split 2nd' },
]
export const exercisingTierDroppingOptionList: Array<OptionNmber> = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Yes' },
]

export const intrestTypeList: Array<OptionString> = [
    { value: 'Fixed', label: 'Fixed' },
    { value: 'ARM', label: 'ARM' },
]

export const defaultTierValues = {
    tier2: {
        ConventionalMultifamily: {
            dscr: 1.25,
            ltv: 0.8,
        },
        Military: {
            dscr: 1.3,
            ltv: 0.75,
        },
        StudentDedicated: {
            dscr: 1.3,
            ltv: 0.75,
        },
        StudentNonDedicated: {
            dscr: 1.3,
            ltv: 0.75,
        },
        Affordable: {
            dscr: 1.2,
            ltv: 0.8,
        },
        ARMSARM: {
            dscr: 1,
            ltv: 0.8,
        },
    },
    tier3: {
        ConventionalMultifamily: {
            dscr: 1.35,
            ltv: 0.65,
        },
        Military: {
            dscr: 1.35,
            ltv: 0.65,
        },
        StudentDedicated: {
            dscr: 1.35,
            ltv: 0.65,
        },
        StudentNonDedicated: {
            dscr: 1.35,
            ltv: 0.65,
        },
        Affordable: {
            dscr: 1.3,
            ltv: 0.7,
        },
        ARMSARM: {
            dscr: 1.1,
            ltv: 0.65,
        },
    },
    tier4: {
        ConventionalMultifamily: {
            dscr: 1.55,
            ltv: 0.55,
        },
        Military: {
            dscr: 1.55,
            ltv: 0.55,
        },
        StudentDedicated: {
            dscr: 1.55,
            ltv: 0.55,
        },
        StudentNonDedicated: {
            dscr: 1.55,
            ltv: 0.55,
        },
        Affordable: {
            dscr: 1.5,
            ltv: 0.6,
        },
        ARMSARM: {
            dscr: 1.3,
            ltv: 0.55,
        },
    },
}

export async function getBondsRate(type) {
    const b = await getTreasuryBondsQuote(type)
    return b
}

export function getRateFromBonds(type: string, bonds: any) {
    if (type == '10 year') return bonds.years10
    if (type == '7 year') return bonds.years7
    if (type == '5 year') return bonds.years5
    if (type == '30 Days') return bonds.days30
    if (type == 'SOFR') return bonds.sofr
    if (type == 'SOFR 30 Days AVG') return bonds.sofr30
}

export async function getIndexIntrstRate(type: string, out?: any) {
    //console.log('getIndexIntrstRate', type, out)
    const b = await getBondsRate(type)
    if (out) {
        if (out['boudsRate']) {
            out['bondsRate'] = { ...out['bondsRate'], ...b }
        } else {
            out['bondsRate'] = b
        }
    }
    return getRateFromBonds(type, b)
    // return 0.01 //1% until implement ajax to get rates
}

export const newPricingOptions = (
    propertyType: string,
    greenFinancingType: string,
    requestedLoanAmount: number,
    isSupplemental: boolean,
    upbAtClosing: number,
    isArm?: boolean,
    fixedOptionReferenceId?: number,
    loanTermInYearsSupplemental?: number,
    fixedOptionLoanTerm?: number,
) => {
    const newPricingOptions = {} as PricingOption
    // console.log('newPricingOptions loanTermInYearsSupplemental', loanTermInYearsSupplemental)
    newPricingOptions.creditFees = {}
    newPricingOptions.creditFees.loanTerms = {
        propertyType: propertyType,
        amortization: 30,
        loanTerm:
            isSupplemental && loanTermInYearsSupplemental && !isNaN(loanTermInYearsSupplemental)
                ? loanTermInYearsSupplemental
                : isArm && (fixedOptionReferenceId || fixedOptionReferenceId === 0)
                ? fixedOptionLoanTerm
                : 10,
        prepayment:
            isSupplemental && loanTermInYearsSupplemental && !isNaN(loanTermInYearsSupplemental)
                ? loanTermInYearsSupplemental - 0.5
                : isArm
                ? 'Lockout 1%'
                : 9.5,
    }
    //console.log('newPricingOptions.creditFees.loanTerms', newPricingOptions.creditFees.loanTerms)
    if (isSupplemental) {
        newPricingOptions.creditFees.loanTerms.supplementalType = supplementalTypeList[0].value
        newPricingOptions.creditFees.loanTerms.exercisingTierDroppingOption = exercisingTierDroppingOptionList[0].value
    }

    if (isArm) {
        newPricingOptions.isArm = true
        newPricingOptions.fixedOptionReferenceId = fixedOptionReferenceId
    } else {
        newPricingOptions.isArm = false
        newPricingOptions.fixedOptionReferenceId = null
    }

    newPricingOptions.creditFees.pricingFees = {
        greenFinancingType: greenFinancingType,
    }
    newPricingOptions.creditFees.spreadFees = {}

    newPricingOptions.sizer = {}
    newPricingOptions.sizer.underwritten = { constraint: '', loanAmount: requestedLoanAmount }
    newPricingOptions.sizer.actual = { constraint: '', loanAmount: requestedLoanAmount }

    if (isSupplemental) {
        newPricingOptions.sizer.underwritten.upbOfFirst = upbAtClosing
        newPricingOptions.sizer.actual.upbOfFirst = upbAtClosing

        newPricingOptions.sizer.underwritten.combinedUPB = requestedLoanAmount + upbAtClosing
        newPricingOptions.sizer.actual.combinedUPB = requestedLoanAmount + upbAtClosing
    }

    newPricingOptions.scenario = {}

    let defaults

    if (isArm) {
        defaults = defaultTierValues['tier' + tierList[0].value]['ARMSARM']
    } else {
        defaults =
            defaultTierValues['tier' + tierList[0].value][
                propertyType.replace(/ /g, '').replace(/-/g, '').replace(/\//g, '')
            ]
    }

    newPricingOptions.scenario.underwritten = {
        financeOptions: {
            intrestType: isArm ? 'SARM' : 'Fixed',
            intrestBasis: InterestBasisList[1].value,
            tier: tierList[0].value,
            minDSCR: defaults.dscr,
            maxLTV: defaults.ltv,
            amortization: 30,
            loanTerm:
                isSupplemental && loanTermInYearsSupplemental && !isNaN(loanTermInYearsSupplemental)
                    ? loanTermInYearsSupplemental
                    : isArm && (fixedOptionReferenceId || fixedOptionReferenceId === 0)
                    ? fixedOptionLoanTerm
                    : 10,
            prepayment:
                isSupplemental && loanTermInYearsSupplemental && !isNaN(loanTermInYearsSupplemental)
                    ? loanTermInYearsSupplemental - 0.5
                    : 9.5,
        },
        loanPricing: {
            pricingMethod: 'Standard',
            refIndex: isArm ? refIndexListARM[0].value : refIndexListFixed[0].value,
        },
    }

    newPricingOptions.scenario.actual = {
        financeOptions: {
            intrestType: isArm ? 'SARM' : 'Fixed',
            intrestBasis: InterestBasisList[1].value,
            tier: tierList[0].value,
            minDSCR: defaults.dscr,
            maxLTV: defaults.ltv,
            amortization: 30,
            loanTerm:
                isSupplemental && loanTermInYearsSupplemental && !isNaN(loanTermInYearsSupplemental)
                    ? loanTermInYearsSupplemental
                    : isArm && (fixedOptionReferenceId || fixedOptionReferenceId === 0)
                    ? fixedOptionLoanTerm
                    : 10,
            prepayment:
                isSupplemental && loanTermInYearsSupplemental && !isNaN(loanTermInYearsSupplemental)
                    ? loanTermInYearsSupplemental - 0.5
                    : 9.5,
        },
        loanPricing: {
            pricingMethod: 'Standard',
            refIndex: isArm ? refIndexListARM[0].value : refIndexListFixed[0].value,
        },
    }

    if (isArm) {
        newPricingOptions.scenario.underwritten.financeOptions.prepaymentAdjusted = 'Lockout 1%'
        newPricingOptions.scenario.actual.financeOptions.prepaymentAdjusted = 'Lockout 1%'

        newPricingOptions.capCost = {
            loanAmount: requestedLoanAmount,
        }
    }

    return newPricingOptions
}

export function isLoanSizerDSCRAlert(option: any) {
    if (option?.sizer?.underwritten?.dscRequestedLoanAmount < option?.scenario?.underwritten?.financeOptions?.minDSCR)
        return true
    return false
}

export function isLoanScnarionDSCRAlert(option: any) {
    if (option?.scenario?.underwritten?.financeOptions?.minDSCR < option?.scenario?.actual?.financeOptions?.minDSCR)
        return true
    return false
}

export function isMax1stPoistionAlert(option: any, type: string) {
    if (option?.sizer?.[type]?.maxFirstPosLoan < option?.sizer?.[type]?.loanAmount) return true
    return false
}

export function isAnyAlert(option: any) {
    if (isLoanSizerDSCRAlert(option)) return true
    if (isLoanScnarionDSCRAlert(option)) return true
    if (isMax1stPoistionAlert(option, 'underwritten')) return true
    if (isMax1stPoistionAlert(option, 'actual')) return true

    return false
}
