import numeral from 'numeral'
import { CashFlow } from '../../../../../../../store/types/OriginationModelRedux'
import { OriginationModelReworked } from '@generated/graphql'
import { getMetrics, regenerateCashFlow } from 'origination-model'
import { ReduxHelper } from '../../../../../../../store'
const cloneDeep = require('lodash.clonedeep')
export function calcCOCvsIRR(cashFlow: any, settings: any, model: OriginationModelReworked) {
    const out: any = {}
    out.columnTitles = settings.columnTitles
    out.rowTitles = settings.rowTitles
    out.xAxisName = settings.xAxisName
    out.yAxisName = settings.yAxisName
    out.data = []

    for (let i = 0; i < settings.rowValues.length; i++) {
        //   model.cashFlow[0].
        const row = []
        for (let j = 0; j < settings.columnValues.length; j++) {
            row.push(calcCell(cashFlow, settings.columnValues[j], settings.rowValues[i], model))
        }
        out.data.push(row)
    }
    return out
}
function calcCell(cashFlow: CashFlow, vacancy: number, rentGrowth: number, model: OriginationModelReworked) {
    let newModel: OriginationModelReworked = cloneDeep(model)
    const years = newModel.underwritingAssumption.organicRentGrowth.length
    newModel.underwritingAssumption.organicRentGrowth = new Array(years).fill(rentGrowth)
    newModel.underwritingAssumption.vacancy = new Array(years).fill(vacancy)
    const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
    let cashFlowData = regenerateCashFlow({ cashFlow, model: newModel, unitMix: unitMix })
    const metrics = getMetrics({ cashFlow: cashFlowData, model: newModel })
    const irr = metrics.netIRR
    cashFlowData = null
    newModel = null
    return {
        value: `${numeral(metrics.coc).format('0.[00]%')} / ${numeral(irr).format('0.[00]%')}`,
        sValue: 1,
        severity: 0.5,
    }
}
