import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { useRouter } from 'next/router'
import {
    hideProgress,
    showErrorNotification,
    showNotification,
    showProgress,
} from '../../../../../../fullproject/notifications'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../../store'
import { SagaHelper } from '../../../../../../../../store/sagaHelper'
import { useSelector } from 'react-redux'
import closeIcon from './close.svg'
import { OriginationModelReworked, QuoteInputGraphQl } from '@generated/graphql'
import numeral from 'numeral'

type FormTypes = {
    dealName: string
}

type Props = {
    projectId: number
    close: (e: SyntheticEvent) => void
    setNoteModalVisible?: (boolean) => void
    localContext: any
}

export const SaveDealModal = (props: Props) => {
    const { close, setNoteModalVisible, projectId, localContext } = props
    const dealName = localContext.form.getState().values.name

    const quotes = useSelector((state: ReduxStoreState) => state.lender.quoteData)
    const onSubmit = useCallback(
        async (formValues: FormTypes) => {
            await showProgress()
            const model: OriginationModelReworked = ReduxHelper.store.getState().lender.originationModel
            const cashFlow = ReduxHelper.store.getState().lender.originationModel.cashFlow

            const values = localContext.form.getState().values
            let quote: QuoteInputGraphQl
            if (values.id) {
                quote = quotes.find((item) => item.id == values.id)
                quote = { ...quote }
            } else {
                quote = {}
            }
            //create new quote, if name was changed
            if (quote.name != formValues.dealName) {
                quote.id = null
            }
            quotes.forEach((oldQuote) => {
                if (oldQuote.name === formValues.dealName) {
                    quote.id = oldQuote.id
                }
            })

            quote.name = formValues.dealName ? formValues.dealName : ''
            quote.minimumDSCR = values.minDSCR
            quote.maximumLTC = values.maxLTC
            quote.maximumLTV = values.maxLTV
            quote.amortization = values.amortizationTerm
            quote.loanPeriod = values.loanTerm
            quote.interestOnly = values.ioPeriod
            quote.rate = values.rate
            quote.vacancy = values.vacancy
            quote.exitCapRate = values.exitCapRate
            quote.valuationCapRate = values.valuationCapRate
            quote.borrowerNOI = values.borrowerNOI
            quote.refinanceLTV = values.refinanceLTV
            quote.default = true

            quote.mezMinimumDSCR = values.mezMinDSCR
            quote.mezMaximumLTC = values.mezMaxLTC
            quote.mezMaximumLTV = values.mezMaxLTV
            quote.mezAmortization = values.mezAmortizationTerm
            quote.mezLoanPeriod = values.mezLoanTerm
            quote.mezInterestOnly = values.mezIoPeriod
            quote.mezRate = values.mezRate

            if (values.useSeniorMaxLTV) quote.useSeniorMaxLTCorV = 'ltv'
            if (values.useSeniorMaxLTC) quote.useSeniorMaxLTCorV = 'ltc'

            if (values.useMezMaxLTV) quote.useMezMaxLTCorV = 'ltv'
            if (values.useMezMaxLTC) quote.useMezMaxLTCorV = 'ltc'

            quote.estimatedNOI = Math.round(+numeral(cashFlow?.noi?.years[0]).value().toFixed(2))

            quote.maximumLoanAmount = Math.round(+numeral(model?.sources?.seniorDebt).value().toFixed(2))
            quote.mezMaximumLoanAmount = Math.round(+numeral(model?.sources?.mezzanine).value().toFixed(2))

            console.log('save', quote)

            setNoteModalVisible(false)
            try {
                await SagaHelper.run(['quote', 'saveQuote'], { projectId: projectId, data: quote })
                await SagaHelper.run(['lenderOriginationModel', 'loadQuoteData'], { projectId })
                await showNotification('Deal is saved')
            } catch (e) {
                await showErrorNotification(e.message)
            }
        },
        [quotes],
    )

    return (
        <div className={'save-deal-modal-layout'} onClick={close}>
            <div className="save-deal-modal" onClick={(e) => e.stopPropagation()}>
                <div className="close" onClick={close}>
                    <img src={closeIcon} />
                </div>
                <h2 className="save-deal-modal-title">Save deal version</h2>
                <div className={'modal-info'}>
                    <p>By creating a deal variation you can provide multiple quotes to your clients</p>
                    <p>Please enter deal variation name</p>
                </div>
                <div className="save-deal-modal-content">
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Field
                                                component={'input'}
                                                className="save-deal-input"
                                                name="dealName"
                                                placeholder=""
                                                initialValue={dealName ? dealName : ''}
                                            />
                                        </div>

                                        <div
                                            style={{ marginTop: '20px' }}
                                            className="col-12 d-flex justify-content-end save-deal-control-buttons"
                                        >
                                            <button className="btn btn-primary ghost" onClick={close}>
                                                Cancel
                                            </button>
                                            <button className="btn btn-primary regular" onClick={handleSubmit}>
                                                Save variation
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .save-deal-modal-layout {
                    position: fixed;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.5);
                    backdrop-filter: blur(14px);
                    z-index: 10000;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    .save-deal-modal {
                        background: $white;
                        width: 500px;
                        border: 1px solid $border;
                        border-radius: 4px;
                        padding: 20px 40px;
                        position: relative;
                        top: 15%;
                        .close {
                            position: absolute;
                            top: 0;
                            right: 10px;
                            color: #8691a2;
                            cursor: pointer;
                        }
                        &-title {
                            color: $black;
                            font-size: 14px;
                            font-weight: 400;
                            margin-bottom: 24px;
                        }
                        &-content {
                        }
                        .modal-info {
                            p {
                                font-size: 14px;
                                color: #808fa7;

                                &:first-child {
                                    margin-bottom: 24px;
                                }
                                &:last-child {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }

                    :global(.save-deal-input) {
                        border-radius: 3px;
                        background: $white;
                        border: 1px solid $border;
                        width: 100%;
                        padding: 10px;
                        font-size: 14px;
                    }

                    .save-deal-control-buttons {
                        button {
                            margin-left: 10px;
                            font-size: 13px;
                            box-shadow: none;
                            height: 36px;
                            &.ghost {
                                color: $blue;
                                border: 1px solid $blue;
                                background: $white;
                                font-weight: 400;
                            }
                            &.regular {
                                font-weight: 500;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
