import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../../../store'
import numeral from 'numeral'
import { NumberField } from '../../../../components/NumberField'
import createDecorator from 'final-form-calculate'
import { Form } from 'react-final-form'
import { PercentBlock } from './PercentBlock'
import { EditableWidgetWrapper } from '../../../../components/EditableWidgetWrapper'
import {
    ADJUSTMENT_VALUE_TYPS,
    orgenizeCFForPresentation,
    regenerateCashFlow,
    getMultiplierByValueTypeForOrigTotal,
} from 'origination-model'
import { OriginationModelReworked } from '@generated/graphql'

const cloneDeep = require('lodash.clonedeep')

type Props = any

export function Expenses(props: Props) {
    /*
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)
    const cashFlow = useSelector((state: ReduxStoreState) => state?.lender?.originationModel?.cashFlow)
     const model = useModel()
    */
    const reducerSum = (previousValue, currentValue) => previousValue + currentValue
    const originationModel: OriginationModelReworked = useSelector(
        (state: ReduxStoreState) => state?.lender?.originationModel,
    )

    //const cashFlow = originationModel?.cashFlow || []

    const storCashFlow = useSelector((store: ReduxStoreState) => store.lender.originationModel?.cashFlow)
    const cashFlow = useMemo(() => {
        return orgenizeCFForPresentation({
            cashFlow: storCashFlow,
        })
    }, [storCashFlow])

    const totalUnits = useMemo(() => {
        return originationModel?.numberOfUnits
    }, [originationModel?.numberOfUnits])

    const settings = useMemo(() => {
        const settings: any = {}
        const incomeValueType = ADJUSTMENT_VALUE_TYPS.find(
            (item) => item.value == originationModel?.adjustmentsSettings?.incomeValueType,
        )

        settings.incomeValueType = incomeValueType || ADJUSTMENT_VALUE_TYPS[0]

        const expenseValueType = ADJUSTMENT_VALUE_TYPS.find(
            (item) => item.value == originationModel?.adjustmentsSettings?.expenseValueType,
        )
        settings.expenseValueType = expenseValueType || ADJUSTMENT_VALUE_TYPS[0]

        settings.multiplier =
            getMultiplierByValueTypeForOrigTotal(originationModel?.adjustmentsSettings?.expenseValueType, totalUnits) ||
            ADJUSTMENT_VALUE_TYPS[0]

        // console.log('settings.multiplier ', originationModel?.adjustmentsSettings, totalUnits, settings.multiplier)
        // console.log('settings', settings)
        return settings
    }, [originationModel?.adjustmentsSettings, totalUnits])
    const operatingExpenses = cashFlow?.operatingExpenses || []
    const { expenssesData, tax, totalExpenses } = useMemo(() => {
        const DEBUG = false
        if (DEBUG) console.log('*********** Expense Widget expenssesData, totalExpenses calculation')
        const expenssesData = []
        const totalExpenses = {
            total: 0,
            totalAdjusted: 0,
        }
        let tax = 0
        if (operatingExpenses.length == 0) return { expenssesData, tax }

        for (const row of operatingExpenses) {
            if (row?.id == 'taxes') {
                if (DEBUG) console.log('tax', row)
                tax = row?.adjustedT12 || row?.t12
                break
            }
        }

        const t12Adjustments = originationModel?.t12Adjustments || []
        if (DEBUG) console.log('t12Adjustments', t12Adjustments)
        for (const adj of t12Adjustments) {
            const expense = operatingExpenses.find((exp) => exp.id === adj.id)
            if (expense) {
                if (DEBUG) console.log('calc total of line item', adj.value, settings.multiplier, expense?.adjustedT12)
                const total = adj.value != null ? adj.value / settings.multiplier : expense?.adjustedT12
                if (DEBUG) console.log('adj', adj.id, adj.value, expense?.t12)
                const item = {
                    id: expense?.id,
                    title: expense?.title.replaceAll('.', ''),
                    total: total,
                }

                totalExpenses.total += expense?.adjustedT12
                totalExpenses.totalAdjusted += total * settings.multiplier
                if (DEBUG) console.log('adjusted adding', total, settings.multiplier)
                expenssesData.push(item)
            }
        }
        // console.log('expenssesData', expenssesData)
        if (DEBUG) console.log('##Expense Widget Total Adjusted', totalExpenses?.totalAdjusted)
        return { expenssesData, tax, totalExpenses }
    }, [operatingExpenses, originationModel?.t12Adjustments, settings])

    const potentialRent = useMemo(() => {
        //this is total yearly
        return cashFlow?.rentalIncome[0]?.adjustedT12
    }, [originationModel])

    const handleSubmit = useCallback(async (values) => null, [])
    const [isReadOnly, setIsReadOnly] = useState(true)

    const expensedecorator = useMemo(() => {
        return [
            createDecorator({
                //field: /^refinance.refinanceType/, // when a deeper field matching this pattern changes...
                field: /^value-/, // when a deeper field matching this pattern changes...
                updates: (value, name, allValues: any) => {
                    // console.log('Dec start', name, value, allValues)
                    //return {}
                    if (typeof value == 'string') {
                        value = parseInt(value.replace(/,/g, ''))
                    }
                    const out = {}
                    const originationModel: OriginationModelReworked = cloneDeep(
                        ReduxHelper.store.getState().lender.originationModel,
                    )
                    const storCashFlow = ReduxHelper.store.getState().lender.originationModel.cashFlow
                    const cashFlow = orgenizeCFForPresentation({
                        cashFlow: storCashFlow,
                    })

                    const totalUnits = originationModel?.numberOfUnits
                    const potentialRent = cashFlow?.rentalIncome[0]?.adjustedT12

                    const multiplier = getMultiplierByValueTypeForOrigTotal(
                        originationModel?.adjustmentsSettings?.expenseValueType,
                        totalUnits,
                    )

                    // console.log('Decorator t12Adjustments', cloneDeep(originationModel?.t12Adjustments))
                    try {
                        originationModel?.t12Adjustments?.map((itm, ind) => {
                            if (name && itm && name?.indexOf(itm?.title) > -1) {
                                let subtotalsHaveAdjustents = false
                                for (const expenseSub of itm?.subRows) {
                                    // console.log('expenseSub', expenseSub)
                                    if (expenseSub.value != null) {
                                        subtotalsHaveAdjustents = true
                                    }
                                }

                                if (Math.abs(value - itm.value) > 1) {
                                    if (subtotalsHaveAdjustents) {
                                        const sub = itm?.subRows.find(
                                            (sub) =>
                                                sub.id == 't12-adjustment-new-row-loadsizerExpenseWidgetAdjustment',
                                        )
                                        if (sub) {
                                            sub.value = sub.value + value - itm.value
                                        } else {
                                            itm?.subRows.push({
                                                id: 't12-adjustment-new-row-loadsizerExpenseWidgetAdjustment',
                                                title: 'Adjustment made on Loan Sizer Expense widget',
                                                value: value - itm.value,
                                            })
                                        }
                                    }

                                    itm.total = value / multiplier
                                    itm.value = value
                                }

                                // console.log('changed originationModel.t12Adjustments', itm.id, value)
                            }
                        })
                    } catch (e) {
                        //console.log(e)
                    }
                    ReduxHelper.setIn(['lender', 'originationModel'], originationModel)

                    let totalAdjusted = 0
                    const operatingExpenses = cashFlow?.operatingExpenses || []
                    for (const row of operatingExpenses) {
                        if (row?.id == 'taxes') {
                            // totalAdjusted += (row?.adjustedT12 || row?.t12 || row?.apr) / totalUnits
                            break
                        }
                    }
                    const DEUBG = false
                    if (DEUBG) console.log('*********** Expense Widget, Decorator ,Calc  Total')

                    if (DEUBG)
                        console.log(
                            'Decorator multiplier',
                            originationModel?.adjustmentsSettings?.expenseValueType,
                            totalUnits,
                            multiplier,
                        )

                    if (DEUBG) console.log('newmultiplier', multiplier)
                    for (const adj of originationModel?.t12Adjustments) {
                        if (adj?.value != null) {
                            totalAdjusted += adj?.value
                            if (DEUBG) console.log('Decorator 1  adj?.value', adj.id, adj?.value)
                        } else if (adj?.total) {
                            const total = adj?.total * multiplier
                            totalAdjusted += total
                            if (DEUBG) console.log('Decorator 2 adj?.total', adj.id, total)
                        } else {
                            const expense = operatingExpenses.find((exp) => exp.id === adj.id)
                            if (expense) {
                                const total = expense?.adjustedT12
                                if (DEUBG)
                                    console.log('Decorator 3 expense', adj.id, expense?.adjustedT12, multiplier, adj)
                                totalAdjusted += total * multiplier
                            }
                        }
                    }
                    if (DEUBG) console.log('Decorator  ', totalAdjusted)
                    out['kpi-total'] = numeral(totalAdjusted).format('$0,0')
                    out['kpi-pct'] = numeral(totalAdjusted / multiplier / potentialRent).format('0.[00]%')

                    /*
                    let total = 0
                    for (const itm in allValues) {
                        if (itm.indexOf('pct-') == -1 && allValues[itm]) {
                            try {
                                total += parseInt(allValues[itm].replace('$', '').replace(',', ''))
                            } catch (e) {
                                total += value
                            }
                        }
                    }

                     */
                    for (const itm in allValues) {
                        if (itm.indexOf('pct-') == -1) {
                            // if (itm.indexOf('pct-') == -1 && !Array.isArray(allValues[itm])) {
                            try {
                                out['pct-' + itm.replace('value-', '')] = numeral(
                                    parseInt(allValues[itm].replace('$', '').replace(',', '')) / totalAdjusted,
                                ).format('%0.[00]')
                            } catch (e) {
                                out['pct-' + itm.replace('value-', '')] = numeral(value / totalAdjusted).format(
                                    '0.[00]%',
                                )
                            }
                        }
                    }
                    return out
                },
            }),
        ]
    }, [])

    const getHigerPercent = useMemo(() => {
        let higerPercent = 0
        for (const row of expenssesData) {
            if ((row?.total * settings.multiplier) / totalExpenses?.totalAdjusted > higerPercent) {
                higerPercent = (row?.total * settings.multiplier) / totalExpenses?.totalAdjusted
            }
        }
        return { higerPercent }
    }, [expenssesData, totalUnits, totalExpenses?.totalAdjusted])

    const doEdit = useCallback((e: SyntheticEvent) => {
        setIsReadOnly(false)
    }, [])
    const doSave = useCallback((e: SyntheticEvent) => {
        setIsReadOnly(true)

        const model: OriginationModelReworked = cloneDeep(ReduxHelper.store.getState().lender.originationModel)
        if (model) {
            let cashFlow = ReduxHelper.store.getState().lender.originationModel.cashFlow
            const unitMix = ReduxHelper.store.getState().lender.unitMix

            cashFlow = regenerateCashFlow({ cashFlow, model, unitMix })

            ReduxHelper.setIn(['lender', 'originationModel', 'cashFlow'], cashFlow)
        }

        if (props.form) {
            props.form.current?.recalc()
        }
    }, [])

    return (
        <>
            <EditableWidgetWrapper
                title={'Expenses'}
                editCallback={doEdit}
                saveCallback={doSave}
                closeBtnStitle={'Close'}
            >
                <Form
                    onSubmit={handleSubmit}
                    mutators={{}}
                    decorators={expensedecorator}
                    initialValues={{}}
                    validate={(v) => {
                        //console.log('v', v)
                        //ReduxHelper.setIn(['lender', 'originationModel', 'refinance'], v.refinance)
                        return null
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values, submitError }) => {
                        //console.log('values', values)
                        //console.log('total ', totalExpenses.totalAdjusted, tax, totalExpenses.totalAdjusted + tax)
                        return (
                            <>
                                <div className={'block-head'}>
                                    <div className={'head-item'}>
                                        <span style={{ textTransform: 'capitalize' }}>
                                            Avg {settings.expenseValueType.label.toLowerCase()}
                                        </span>
                                        <span className={'kpi'}>
                                            <NumberField
                                                name={`kpi-total`}
                                                numberFormat={'currency'}
                                                readOnly={true}
                                                value={numeral(totalExpenses?.totalAdjusted).format('$0,0')}
                                                cssClassName={'kpi-input'}
                                            />
                                        </span>
                                    </div>
                                    <div className={'head-item'}>
                                        <span>Expenses Ratio</span>
                                        <span className={'kpi'}>
                                            <NumberField
                                                name={`kpi-pct`}
                                                numberFormat={'percent'}
                                                readOnly={true}
                                                value={numeral(
                                                    totalExpenses?.totalAdjusted / settings.multiplier / potentialRent,
                                                ).format('0.[00]%')}
                                                cssClassName={'kpi-input'}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className={'expensesTable'}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CATEGORY</th>
                                                <th>AVG/ {settings.expenseValueType.label}</th>
                                                <th>%OF TOTAL EXPENSES</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {expenssesData?.map((itm, ind) => {
                                                return (
                                                    <React.Fragment key={ind.toString()}>
                                                        <tr>
                                                            <td className={'expenseTitle'}>{itm.title}</td>
                                                            <td className={'price'}>
                                                                <NumberField
                                                                    name={`value-${itm.title}`}
                                                                    numberFormat={'currency'}
                                                                    readOnly={isReadOnly || itm.id == 'taxes'}
                                                                    initialValue={numeral(
                                                                        itm.total * settings.multiplier,
                                                                    ).format('0,0')}
                                                                />
                                                            </td>
                                                            <td className={'price'}>
                                                                {'aa' == 'aa' && (
                                                                    <NumberField
                                                                        name={`pct-${itm.title}`}
                                                                        numberFormat={'percent'}
                                                                        readOnly={true}
                                                                        initialValue={numeral(
                                                                            (itm.total * settings.multiplier) /
                                                                                totalExpenses.totalAdjusted,
                                                                        ).format('%0.[00]')}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>
                                                                <PercentBlock
                                                                    id={`Expenses_${ind}`}
                                                                    value={itm.total * settings.multiplier}
                                                                    label={itm.title}
                                                                    percentWidth={Math.round(
                                                                        ((itm.total * settings.multiplier) /
                                                                            totalExpenses?.totalAdjusted) *
                                                                            100,
                                                                    )}
                                                                    percentValue={numeral(
                                                                        (itm.total * settings.multiplier) /
                                                                            totalExpenses?.totalAdjusted,
                                                                    ).format('%0.[00]')}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })}
                                            <tr style={{ display: 'none' }}>
                                                <td className={'expenseTitle'}>Total Taxes</td>
                                                <td className={'price'}>
                                                    {tax > 0 && (
                                                        <NumberField
                                                            name={`value-tax`}
                                                            numberFormat={'currency'}
                                                            readOnly={true}
                                                            initialValue={numeral(tax).format('0,0')}
                                                        />
                                                    )}
                                                </td>
                                                <td className={'price'}>
                                                    {tax > 0 && (
                                                        <NumberField
                                                            name={`pct-tax`}
                                                            numberFormat={'percent'}
                                                            readOnly={true}
                                                            initialValue={numeral(
                                                                tax / (totalExpenses?.totalAdjusted + tax),
                                                            ).format('%0.[00]')}
                                                        />
                                                    )}
                                                </td>
                                                <td>
                                                    <PercentBlock
                                                        id={`Expenses_tax`}
                                                        value={tax}
                                                        label={'Total Tax'}
                                                        percentWidth={Math.round(
                                                            (tax /
                                                                (totalExpenses?.totalAdjusted + tax) /
                                                                getHigerPercent.higerPercent) *
                                                                100,
                                                        )}
                                                        percentValue={numeral(
                                                            tax / (totalExpenses?.totalAdjusted + tax),
                                                        ).format('%0.[00]')}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )
                    }}
                />
            </EditableWidgetWrapper>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .expensesTable table {
                    width: 100%;
                }
                .block-head {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 58px;
                    width: fit-content;

                    .head-item {
                        display: flex;
                        flex-direction: column;
                        span:first-child {
                            font-size: 14px;
                            color: #97a2b4;
                        }
                        .kpi {
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 27px;
                            color: #161c26;
                            :global(.kpi-input) {
                                font-family: Inter;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 22px;
                                line-height: 27px;
                                color: #161c26;
                            }
                        }
                    }
                }

                .avergae {
                }
                tbody tr:nth-child(odd) {
                    background-color: rgba(217, 224, 229, 0.15);
                }
                tr {
                    height: 35px;
                }
                th {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #676f7a;
                    &:last-child {
                        width: 100px;
                    }
                    &:not(:first-child) {
                        text-align: right;
                    }
                }
                .price {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: right;

                    color: #161c26;
                }
                .expenseTitle {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 18px;
                    color: #262b35;
                }
                .title {
                    width: 100%;
                    padding-bottom: 10px;
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: #161c26;
                    border-bottom: 1px solid #d9e0e5;
                }
            `}</style>
        </>
    )
}
