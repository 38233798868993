import React, { useEffect, useState } from 'react'
import { Gauge } from './Gauge'
import { ReduxStoreState } from '../../../../../../store'

import { useSelector } from 'react-redux'

import { useMetrics } from '../../../hooks/useMetrics'

type Props = {
    projectId: number
}

export function DebtYieldGauge(props: Props) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState({ value: 0, percent: 0, threshold: 0 })
    const metrics = useMetrics()
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    // console.log('ettings', settings)
    useEffect(() => {
        if (!settings || !cashFlow) return
        const threshold = settings.debtYield || 10
        const debtYield = +(metrics.debtYield * 100).toFixed(2)
        let percent = debtYield / (threshold * 2)
        percent = percent > 1 ? 1 : percent
        setData({
            percent,
            value: debtYield,
            threshold: threshold,
        })
        setLoading(false)
    }, [cashFlow, settings])

    const customColor = data?.value < data.threshold ? '#C93838' : '#67d554'
    // console.log(data)
    return (
        <Gauge
            title={'Debt Yield vs Threshold'}
            value={`${data.value}%`}
            percent={data.percent}
            leftHint={null}
            leftValue={`${data.value}%`}
            rightHint={'Threshold'}
            rightValue={`${data.threshold}%`}
            hover={'gauge'}
            loading={loading}
            error={error}
            customColor={customColor}
        />
    )
}
