import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../../store'

type Props = {
    columns: Array<{ label: string; value: string; header: string }>
    isPDF?: boolean
}
export const CashFlowHeader = React.memo(function CashFlowHeader(props: Props) {
    const unitTitle = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.unitTitle)
    const percentTitle = useSelector((state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.percentTitle)
    const showPercentage = useSelector(
        (state: ReduxStoreState) => state.lenderDashboard.lenderCashFlowTab.showPercentage,
    )
    const columnsSelected = useSelector(
        (store: ReduxStoreState) => store.lenderDashboard.lenderCashFlowTab.columnsSelected,
    )
    const subTitles = useMemo(() => {
        if (showPercentage) {
            return ['TOTAL', unitTitle, percentTitle]
        } else {
            return ['TOTAL', unitTitle]
        }
    }, [showPercentage, unitTitle, percentTitle])
    return (
        <div className={'cash-flow-row-title'}>
            {columnsSelected?.map((c, ind) => {
                const col = props.columns.find((item) => item.value == c)
                return <TitleSection title={col?.header || c} subTitles={subTitles} key={ind} />
            })}

            {/*language=SCSS*/}
            <style jsx>
                {`
                    .cash-flow-row-title {
                        display: inline-flex;
                        flex-direction: row;
                        background: white;
                        width: auto;
                    }
                `}
            </style>
        </div>
    )
})

type TitleSectionProps = {
    title: string
    subTitles: string[]
}
const TitleSection = React.memo(function TitleSection(props: TitleSectionProps) {
    const style: any = {}
    if (props.title.length > 26) {
        style.fontSize = '15px'
    }
    if (props.title.length > 40) {
        style.fontSize = '12px'
    }
    return (
        <div className={'title-section'}>
            <div className={'title'} style={style}>
                {props.title}
            </div>
            <div className={'delimiter'} />
            {props.subTitles.map((item, ind) => (
                <div className={'subTitle'} key={ind}>
                    {item}
                </div>
            ))}
            <div className={'delimiter-vertical'} />
            {/*language=SCSS*/}
            <style jsx>{`
                .title-section {
                    width: ${props.subTitles.length * 133 + 19}px;
                    display: grid;
                    grid-template-columns: repeat(${props.subTitles.length}, 1fr) 19px;
                    grid-template-rows: auto;
                    .title {
                        padding-top: 20px;
                        text-align: center;
                        grid-column-start: 1;
                        grid-column-end: 3;
                        margin-bottom: 4px;
                        text-transform: capitalize;
                    }
                    .delimiter {
                        grid-column: 1 / span ${props.subTitles.length};
                        height: 4px;
                        background: #eaeef1;
                    }
                    .subTitle {
                        text-align: right;
                        font-size: 12px;
                        line-height: 15px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        margin-top: 12px;
                        padding-bottom: 10px;
                    }
                    .subTitle:nth-child(3) {
                        grid-column-start: 1;
                    }
                    .delimiter-vertical {
                        grid-column: ${props.subTitles.length + 1};
                        grid-row: 1 / span 3;
                        align-self: end;
                        width: 1px;
                        height: 100%;
                        background: #eceff2;
                        justify-self: center;
                    }
                }
            `}</style>
        </div>
    )
})
