import React, { useCallback, useEffect, useState } from 'react'
import { useSpring } from 'react-spring'
import { AnimatedDebtCoverageComponent } from './DebdCoverageComponent'
import { ReduxStoreState } from '../../../../../../../store'
import { useSelector } from 'react-redux'
import { useMetrics } from '../../../../hooks/useMetrics'

type Props = {
    projectId: number
}

export function DashboardDebtCoverage(props: Props) {
    const [loading, setLoading] = useState(true)

    const metrics = useMetrics()

    //debt service coverage treshold
    const settings = useSelector((state: ReduxStoreState) => state.lender.settings)
    const model = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const cashFlow = useSelector((state: ReduxStoreState) => state.lender.originationModel?.cashFlow)
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const dscr = project?.accountType == 'borrower' ? metrics.dscr : metrics.dscrT12
    const [animatedValues, set] = useSpring(() => ({
        config: { duration: 500 },
        from: { percent: 0 },
        percent: 1,
    }))

    const repeatAnimation = useCallback(() => {
        if (!settings?.dscr || !dscr) return
        const percent = calcPercent(dscr, settings.dscr)
        set({ percent: 0 }).then(() => {
            set({ percent })
        })
    }, [settings?.dscr, set, dscr])

    useEffect(() => {
        if (!settings?.dscr || !metrics.dscr || !project) return
        setLoading(false)
        const percent = calcPercent(dscr, settings.dscr)
        set({ percent: 0 }).then(() => {
            set({ percent })
        })
    }, [settings?.dscr, dscr, project])

    return (
        <div onMouseEnter={repeatAnimation}>
            <AnimatedDebtCoverageComponent
                cashFlow={cashFlow?.noi?.years?.[0]}
                treshold={settings?.dscr || 1.2}
                percentage={animatedValues.percent}
                value={dscr}
                title={'Debt Service Coverage Ratio (DSCR) vs Threshold'}
                cashFlowTitle={model?.useNCFnotNOI ? 'Net CF' : 'NOI'}
                tresholdTitle={'Threshold'}
                loading={loading}
                error={''}
            />
        </div>
    )
}
function calcPercent(value: number, threshold: number): number {
    const percent = (value / (threshold * 1.6)) * 100
    if (percent > 100) return 100
    return percent
}
