import React, { useCallback, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { calcColor } from './calcColor'
import { SpinnerOrError } from '../../../../components/SpinnerOrError'

type Props = {
    title?: string
    badge?: string
    subtitle?: string
    value?: string
    /** position from 0 to 1*/
    percent: number
    leftValue?: string | number
    rightValue?: string | number
    leftHint?: string
    rightHint?: string
    hover?: string
    loading?: boolean
    error?: string
    customColor?: string
}
function GaugeComponent(props: Props) {
    const startColor = '#ffffff'
    const endColor = '#C93838'
    const averageColor = calcColor(startColor, endColor, props.percent)

    const [animatedValues, set] = useSpring(() => ({
        config: { duration: 500 },
        from: { percent: 0, color: startColor },
        percent: props?.percent || 0,
        color: calcColor('#0A1B2E', '#4485FF', props?.percent || 0),
    }))

    useEffect(() => {
        set({ percent: 0, color: startColor }).then(() => {
            set({ percent: props?.percent || 0, color: calcColor(startColor, endColor, props?.percent || 0) })
        })
    }, [props.percent])
    const repeatAnimation = useCallback(() => {
        set({ percent: 0, color: startColor }).then(() => {
            set({ percent: props.percent, color: calcColor(startColor, endColor, props.percent) })
        })
    }, [props.percent, set])

    return (
        <div className={'gauge-container'} onMouseEnter={repeatAnimation}>
            <div className={'gauge-title'}>
                {props.title} {props?.badge && <span className={'gauge-badge'}>{props?.badge}</span>}
            </div>
            <div className={'gauge-subtitle'}>{props.subtitle}</div>
            {!props.loading ? (
                <React.Fragment>
                    <div className={'gauge-chart'}>
                        <svg width="261px" height="143px" viewBox="0 0 261 143" version="1.1">
                            {/*hover value*/}
                            <title>{props.hover || 'gauge'}</title>
                            <defs>
                                <linearGradient x1="100%" y1="50%" x2="0%" y2="50%" id="linearGradient-1">
                                    <stop stopColor={endColor} offset="0%"></stop>
                                    <stop stopColor={startColor} offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="gauge">
                                    <path
                                        d="M129.49804,17 C192.017054,17 242.730261,67.5453133 243,129.999313 L224.996,130 L224.987804,128.912464 C224.144441,76.6253488 181.715201,34.5 129.49804,34.5 C76.9195063,34.5 34.2648377,77.210431 33.9967527,129.999487 L15.999,130 L16.010206,128.62307 C17.0125276,66.8044488 67.4389021,17 129.49804,17 Z"
                                        id="Combined-Shape"
                                        fill={'#E0E5E9'}
                                    />
                                    <g
                                        id="Group-4"
                                        transform="translate(0.000000, 13.000000)"
                                        stroke="#fff"
                                        strokeWidth="1.5"
                                    >
                                        <line x1="130.36097" y1="119.992259" x2="130.36097" y2="0" id="Path-4" />
                                        <line
                                            x1="137.039123"
                                            y1="119.992259"
                                            x2="130.017658"
                                            y2="0"
                                            id="Path-4-Copy-56"
                                            transform="translate(133.528391, 59.996130) scale(-1, 1) translate(-133.528391, -59.996130) "
                                        />
                                        <line
                                            x1="143.709283"
                                            y1="119.992259"
                                            x2="130.017658"
                                            y2="0"
                                            id="Path-4-Copy-55"
                                            transform="translate(136.863471, 59.996130) scale(-1, 1) translate(-136.863471, -59.996130) "
                                        />
                                        <line
                                            x1="150.017658"
                                            y1="119.992259"
                                            x2="130.017658"
                                            y2="3"
                                            id="Path-4-Copy-54"
                                            transform="translate(140.017658, 61.496130) scale(-1, 1) translate(-140.017658, -61.496130) "
                                        />
                                        <line
                                            x1="156.645486"
                                            y1="119.992259"
                                            x2="130.017658"
                                            y2="3"
                                            id="Path-4-Copy-53"
                                            transform="translate(143.331572, 61.496130) scale(-1, 1) translate(-143.331572, -61.496130) "
                                        />
                                        <line
                                            x1="162.858703"
                                            y1="119.992259"
                                            x2="130.017658"
                                            y2="5.89675146"
                                            id="Path-4-Copy-52"
                                            transform="translate(146.438181, 62.944505) scale(-1, 1) translate(-146.438181, -62.944505) "
                                        />
                                        <line
                                            x1="153.436274"
                                            y1="123.32821"
                                            x2="146.414809"
                                            y2="3.33595113"
                                            id="Path-4-Copy-51"
                                            transform="translate(149.925541, 63.332081) scale(-1, 1) rotate(-16.000000) translate(-149.925541, -63.332081) "
                                        />
                                        <line
                                            x1="159.977239"
                                            y1="124.247483"
                                            x2="146.285613"
                                            y2="4.25522378"
                                            id="Path-4-Copy-50"
                                            transform="translate(153.131426, 64.251353) scale(-1, 1) rotate(-16.000000) translate(-153.131426, -64.251353) "
                                        />
                                        <line
                                            x1="165.749969"
                                            y1="125.058787"
                                            x2="145.749969"
                                            y2="8.06652816"
                                            id="Path-4-Copy-49"
                                            transform="translate(155.749969, 66.562658) scale(-1, 1) rotate(-16.000000) translate(-155.749969, -66.562658) "
                                        />
                                        <line
                                            x1="172.249423"
                                            y1="125.972226"
                                            x2="145.621594"
                                            y2="8.97996672"
                                            id="Path-4-Copy-48"
                                            transform="translate(158.935508, 67.476096) scale(-1, 1) rotate(-16.000000) translate(-158.935508, -67.476096) "
                                        />
                                        <line
                                            x1="177.943068"
                                            y1="126.772416"
                                            x2="145.102023"
                                            y2="12.6769079"
                                            id="Path-4-Copy-47"
                                            transform="translate(161.522545, 69.724662) scale(-1, 1) rotate(-16.000000) translate(-161.522545, -69.724662) "
                                        />
                                        <line
                                            x1="170.528144"
                                            y1="130.507564"
                                            x2="163.506679"
                                            y2="10.5153047"
                                            id="Path-4-Copy-46"
                                            transform="translate(167.017412, 70.511434) scale(-1, 1) rotate(-29.000000) translate(-167.017412, -70.511434) "
                                        />
                                        <line
                                            x1="176.780151"
                                            y1="132.124443"
                                            x2="163.088526"
                                            y2="12.1321836"
                                            id="Path-4-Copy-45"
                                            transform="translate(169.934338, 72.128313) scale(-1, 1) rotate(-29.000000) translate(-169.934338, -72.128313) "
                                        />
                                        <line
                                            x1="181.965838"
                                            y1="133.465553"
                                            x2="161.965838"
                                            y2="16.4732935"
                                            id="Path-4-Copy-44"
                                            transform="translate(171.965838, 74.969423) scale(-1, 1) rotate(-29.000000) translate(-171.965838, -74.969423) "
                                        />
                                        <line
                                            x1="188.178167"
                                            y1="135.07217"
                                            x2="161.550339"
                                            y2="18.079911"
                                            id="Path-4-Copy-43"
                                            transform="translate(174.864253, 76.576041) scale(-1, 1) rotate(-29.000000) translate(-174.864253, -76.576041) "
                                        />
                                        <line
                                            x1="192.589906"
                                            y1="136.213123"
                                            x2="159.950724"
                                            y2="24.265164"
                                            id="Path-4-Copy-42"
                                            transform="translate(176.270315, 80.239144) scale(-1, 1) rotate(-29.000000) translate(-176.270315, -80.239144) "
                                        />
                                        <line
                                            x1="183.252114"
                                            y1="141.374749"
                                            x2="176.230649"
                                            y2="21.3824897"
                                            id="Path-4-Copy-41"
                                            transform="translate(179.741382, 81.378619) scale(-1, 1) rotate(-45.000000) translate(-179.741382, -81.378619) "
                                        />
                                        <line
                                            x1="188.945452"
                                            y1="143.733007"
                                            x2="175.253827"
                                            y2="23.7407474"
                                            id="Path-4-Copy-40"
                                            transform="translate(182.099639, 83.736877) scale(-1, 1) rotate(-45.000000) translate(-182.099639, -83.736877) "
                                        />
                                        <line
                                            x1="193.269326"
                                            y1="145.524014"
                                            x2="173.269326"
                                            y2="28.5317548"
                                            id="Path-4-Copy-39"
                                            transform="translate(183.269326, 87.027884) scale(-1, 1) rotate(-45.000000) translate(-183.269326, -87.027884) "
                                        />
                                        <line
                                            x1="198.926532"
                                            y1="147.867305"
                                            x2="172.298703"
                                            y2="30.875046"
                                            id="Path-4-Copy-38"
                                            transform="translate(185.612618, 89.371176) scale(-1, 1) rotate(-45.000000) translate(-185.612618, -89.371176) "
                                        />
                                        <line
                                            x1="199.706371"
                                            y1="148.190325"
                                            x2="168.646536"
                                            y2="39.692159"
                                            id="Path-4-Copy-37"
                                            transform="translate(184.176453, 93.941242) scale(-1, 1) rotate(-45.000000) translate(-184.176453, -93.941242) "
                                        />
                                        <line
                                            x1="208.844034"
                                            y1="152.877956"
                                            x2="182.216206"
                                            y2="35.885697"
                                            id="Path-4-Copy-36"
                                            transform="translate(195.530120, 94.381827) scale(-1, 1) rotate(-49.000000) translate(-195.530120, -94.381827) "
                                        />
                                        <line
                                            x1="211.918122"
                                            y1="154.278899"
                                            x2="179.278939"
                                            y2="42.3309393"
                                            id="Path-4-Copy-35"
                                            transform="translate(195.598531, 98.304919) scale(-1, 1) rotate(-49.000000) translate(-195.598531, -98.304919) "
                                        />
                                        <line
                                            x1="201.981276"
                                            y1="160.55898"
                                            x2="194.95981"
                                            y2="40.566721"
                                            id="Path-4-Copy-34"
                                            transform="translate(198.470543, 100.562851) scale(-1, 1) rotate(-65.000000) translate(-198.470543, -100.562851) "
                                        />
                                        <line
                                            x1="203.088822"
                                            y1="169.290535"
                                            x2="184.072946"
                                            y2="37.8803841"
                                            id="Path-4-Copy-33"
                                            transform="translate(193.580884, 103.585460) scale(-1, 1) rotate(-65.000000) translate(-193.580884, -103.585460) "
                                        />
                                        <line
                                            x1="206.858538"
                                            y1="170.27543"
                                            x2="180.25034"
                                            y2="42.4639935"
                                            id="Path-4-Copy-32"
                                            transform="translate(193.554439, 106.369712) scale(-1, 1) rotate(-65.000000) translate(-193.554439, -106.369712) "
                                        />
                                        <line
                                            x1="214.378628"
                                            y1="168.874861"
                                            x2="182.381129"
                                            y2="48.0438479"
                                            id="Path-4-Copy-31"
                                            transform="translate(198.379878, 108.459354) scale(-1, 1) rotate(-65.000000) translate(-198.379878, -108.459354) "
                                        />
                                        <line
                                            x1="213.103685"
                                            y1="169.338885"
                                            x2="175.827541"
                                            y2="55.1863447"
                                            id="Path-4-Copy-30"
                                            transform="translate(194.465613, 112.262615) scale(-1, 1) rotate(-65.000000) translate(-194.465613, -112.262615) "
                                        />
                                        <line
                                            x1="211.772311"
                                            y1="166.839134"
                                            x2="167.974513"
                                            y2="62.9226782"
                                            id="Path-4-Copy-29"
                                            transform="translate(189.873412, 114.880906) scale(-1, 1) rotate(-65.000000) translate(-189.873412, -114.880906) "
                                        />
                                        <line x1="130.36097" y1="119.992259" x2="123.339505" y2="0" id="Path-4-Copy" />
                                        <line
                                            x1="130.36097"
                                            y1="119.992259"
                                            x2="116.669345"
                                            y2="0"
                                            id="Path-4-Copy-2"
                                        />
                                        <line x1="130.36097" y1="119.992259" x2="110.36097" y2="3" id="Path-4-Copy-5" />
                                        <line
                                            x1="130.36097"
                                            y1="119.992259"
                                            x2="103.733141"
                                            y2="3"
                                            id="Path-4-Copy-4"
                                        />
                                        <line
                                            x1="130.36097"
                                            y1="119.992259"
                                            x2="97.5199248"
                                            y2="5.89675146"
                                            id="Path-4-Copy-3"
                                        />
                                        <line
                                            x1="113.963819"
                                            y1="123.32821"
                                            x2="106.942354"
                                            y2="3.33595113"
                                            id="Path-4-Copy-10"
                                            transform="translate(110.453087, 63.332081) rotate(-16.000000) translate(-110.453087, -63.332081) "
                                        />
                                        <line
                                            x1="114.093015"
                                            y1="124.247483"
                                            x2="100.401389"
                                            y2="4.25522378"
                                            id="Path-4-Copy-9"
                                            transform="translate(107.247202, 64.251353) rotate(-16.000000) translate(-107.247202, -64.251353) "
                                        />
                                        <line
                                            x1="114.628659"
                                            y1="125.058787"
                                            x2="94.6286585"
                                            y2="8.06652816"
                                            id="Path-4-Copy-8"
                                            transform="translate(104.628659, 66.562658) rotate(-16.000000) translate(-104.628659, -66.562658) "
                                        />
                                        <line
                                            x1="114.757034"
                                            y1="125.972226"
                                            x2="88.1292054"
                                            y2="8.97996672"
                                            id="Path-4-Copy-7"
                                            transform="translate(101.443120, 67.476096) rotate(-16.000000) translate(-101.443120, -67.476096) "
                                        />
                                        <line
                                            x1="115.276605"
                                            y1="126.772416"
                                            x2="82.4355599"
                                            y2="12.6769079"
                                            id="Path-4-Copy-6"
                                            transform="translate(98.856083, 69.724662) rotate(-16.000000) translate(-98.856083, -69.724662) "
                                        />
                                        <line
                                            x1="96.8719489"
                                            y1="130.507564"
                                            x2="89.8504836"
                                            y2="10.5153047"
                                            id="Path-4-Copy-19"
                                            transform="translate(93.361216, 70.511434) rotate(-29.000000) translate(-93.361216, -70.511434) "
                                        />
                                        <line
                                            x1="97.2901022"
                                            y1="132.124443"
                                            x2="83.5984768"
                                            y2="12.1321836"
                                            id="Path-4-Copy-18"
                                            transform="translate(90.444290, 72.128313) rotate(-29.000000) translate(-90.444290, -72.128313) "
                                        />
                                        <line
                                            x1="98.4127896"
                                            y1="133.465553"
                                            x2="78.4127896"
                                            y2="16.4732935"
                                            id="Path-4-Copy-17"
                                            transform="translate(88.412790, 74.969423) rotate(-29.000000) translate(-88.412790, -74.969423) "
                                        />
                                        <line
                                            x1="98.8282891"
                                            y1="135.07217"
                                            x2="72.2004606"
                                            y2="18.079911"
                                            id="Path-4-Copy-16"
                                            transform="translate(85.514375, 76.576041) rotate(-29.000000) translate(-85.514375, -76.576041) "
                                        />
                                        <line
                                            x1="100.427904"
                                            y1="136.213123"
                                            x2="67.7887221"
                                            y2="24.265164"
                                            id="Path-4-Copy-20"
                                            transform="translate(84.108313, 80.239144) rotate(-29.000000) translate(-84.108313, -80.239144) "
                                        />
                                        <line
                                            x1="84.147979"
                                            y1="141.374749"
                                            x2="77.1265136"
                                            y2="21.3824897"
                                            id="Path-4-Copy-15"
                                            transform="translate(80.637246, 81.378619) rotate(-45.000000) translate(-80.637246, -81.378619) "
                                        />
                                        <line
                                            x1="85.1248013"
                                            y1="143.733007"
                                            x2="71.4331758"
                                            y2="23.7407474"
                                            id="Path-4-Copy-14"
                                            transform="translate(78.278989, 83.736877) rotate(-45.000000) translate(-78.278989, -83.736877) "
                                        />
                                        <line
                                            x1="87.1093015"
                                            y1="145.524014"
                                            x2="67.1093015"
                                            y2="28.5317548"
                                            id="Path-4-Copy-13"
                                            transform="translate(77.109302, 87.027884) rotate(-45.000000) translate(-77.109302, -87.027884) "
                                        />
                                        <line
                                            x1="88.0799246"
                                            y1="147.867305"
                                            x2="61.452096"
                                            y2="30.875046"
                                            id="Path-4-Copy-12"
                                            transform="translate(74.766010, 89.371176) rotate(-45.000000) translate(-74.766010, -89.371176) "
                                        />
                                        <line
                                            x1="91.7320923"
                                            y1="148.190325"
                                            x2="60.6722569"
                                            y2="39.692159"
                                            id="Path-4-Copy-11"
                                            transform="translate(76.202175, 93.941242) rotate(-45.000000) translate(-76.202175, -93.941242) "
                                        />
                                        <line
                                            x1="78.1624223"
                                            y1="152.877956"
                                            x2="51.5345938"
                                            y2="35.885697"
                                            id="Path-4-Copy-27"
                                            transform="translate(64.848508, 94.381827) rotate(-49.000000) translate(-64.848508, -94.381827) "
                                        />
                                        <line
                                            x1="81.0996885"
                                            y1="154.278899"
                                            x2="48.4605062"
                                            y2="42.3309393"
                                            id="Path-4-Copy-26"
                                            transform="translate(64.780097, 98.304919) rotate(-49.000000) translate(-64.780097, -98.304919) "
                                        />
                                        <line
                                            x1="65.4188176"
                                            y1="160.55898"
                                            x2="58.3973523"
                                            y2="40.566721"
                                            id="Path-4-Copy-25"
                                            transform="translate(61.908085, 100.562851) rotate(-65.000000) translate(-61.908085, -100.562851) "
                                        />
                                        <line
                                            x1="76.3056821"
                                            y1="169.290535"
                                            x2="57.2898062"
                                            y2="37.8803841"
                                            id="Path-4-Copy-24"
                                            transform="translate(66.797744, 103.585460) rotate(-65.000000) translate(-66.797744, -103.585460) "
                                        />
                                        <line
                                            x1="80.1282879"
                                            y1="170.27543"
                                            x2="53.5200895"
                                            y2="42.4639935"
                                            id="Path-4-Copy-23"
                                            transform="translate(66.824189, 106.369712) rotate(-65.000000) translate(-66.824189, -106.369712) "
                                        />
                                        <line
                                            x1="77.9974993"
                                            y1="168.874861"
                                            x2="46"
                                            y2="48.0438479"
                                            id="Path-4-Copy-22"
                                            transform="translate(61.998750, 108.459354) rotate(-65.000000) translate(-61.998750, -108.459354) "
                                        />
                                        <line
                                            x1="84.5510874"
                                            y1="169.338885"
                                            x2="47.2749433"
                                            y2="55.1863447"
                                            id="Path-4-Copy-21"
                                            transform="translate(65.913015, 112.262615) rotate(-65.000000) translate(-65.913015, -112.262615) "
                                        />
                                        <line
                                            x1="92.4041153"
                                            y1="166.839134"
                                            x2="48.6063173"
                                            y2="62.9226782"
                                            id="Path-4-Copy-28"
                                            transform="translate(70.505216, 114.880906) rotate(-65.000000) translate(-70.505216, -114.880906) "
                                        />
                                    </g>
                                    <path
                                        d="M129.5,9 C195.385737,9 248.919765,62.0964057 249.983857,127.999071 L250,130 L248,130 C248,64.2771822 194.944806,11 129.5,11 C64.709642,11 12.0623428,63.2169663 11.0158759,128.03209 L11,130 L9,130 C9,63.1744778 62.9487508,9 129.5,9 Z"
                                        id="Path"
                                        fill="url(#linearGradient-1)"
                                        opacity="0.900000036"
                                    />
                                    <animated.circle
                                        id="Oval"
                                        stroke="#191C20"
                                        strokeWidth="0"
                                        fill={animatedValues.color}
                                        cx={animatedValues.percent.to((v) => {
                                            const degree = Math.PI * (1 - v)
                                            return 121 * Math.cos(degree) + 130
                                        })}
                                        cy={animatedValues.percent.to((v) => {
                                            const degree = Math.PI * (1 - v)
                                            return 130 - 120 * Math.sin(degree)
                                        })}
                                        r="9"
                                    />
                                    )
                                </g>
                            </g>
                        </svg>
                        <div
                            className={'gauge-value'}
                            style={{
                                backgroundColor: props?.customColor || averageColor,
                                color: props.percent > 0.01 ? '#fff' : '#000',
                            }}
                        >
                            {props.value}
                        </div>
                    </div>
                    <div className={'gauge-footer'}>
                        <div className={'legend'}>
                            {props.leftHint && (
                                <React.Fragment>
                                    <div className={'hint'}>{props.leftHint || 'Cashflow'}</div>
                                    <div className={'value'}>{props.leftValue}</div>
                                </React.Fragment>
                            )}
                        </div>
                        <div className={'legend'}>
                            <div className={'hint'}>{props.rightHint || 'Threshold'}</div>
                            <div className={'value'}>{props.rightValue}</div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <SpinnerOrError height={'224px'} error={props.error} />
            )}
            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .gauge-container {
                    width: 100%;
                    min-height: 258px;
                    background-color: $white;
                    border-radius: 3px;
                    position: relative;
                    border: 1px solid $border;
                    //padding-top: 16px;
                }
                .gauge-title {
                    font-size: 15px;
                    font-weight: 500;
                    padding: 10px 20px;
                    color: $black;
                    border-bottom: 1px solid $border;
                    display: flex;
                    justify-content: space-between;
                    .gauge-badge {
                        text-transform: uppercase;
                        padding: 2px 6px;
                        background-color: $light-gray;
                        font-weight: 400;
                        font-size: 13px;
                        border-radius: 2px;
                    }
                }
                .gauge-subtitle {
                    font-size: 13px;
                    min-height: 17px;
                    color: $gray;
                    margin-left: 22px;
                    margin-right: 19px;
                }
                .gauge-chart {
                    margin-top: 20px;
                    width: 100%;
                    text-align: center;
                    position: relative;
                    svg {
                        display: block;
                        margin: auto;
                    }
                }
                .gauge-value {
                    font-weight: bold;
                    font-size: 22px;
                    color: $white;
                    letter-spacing: 0;
                    text-align: center;
                    background-color: ${endColor};
                    display: inline-block;
                    min-width: 100px;
                    position: relative;
                    top: -45px;
                    border-radius: 3px;
                    padding: 0 10px;
                }
                .gauge-footer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-left: 10px;
                    padding-right: 10px;
                    top: -5px;
                    position: relative;
                    .legend {
                        text-align: center;
                        min-width: 90px;
                        .hint {
                            opacity: 0.7;
                            font-size: 12px;
                            color: $gray;
                            letter-spacing: 0;
                            text-align: center;
                        }
                        .value {
                            font-size: 16px;
                            color: $black;
                            letter-spacing: 0;
                            text-align: center;
                            font-weight: 500;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
export const Gauge = React.memo(GaugeComponent)
