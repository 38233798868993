import React from 'react'
import { useSyncScroll } from '../../../../../utils/useSyncScroll'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../store'

type Props = {
    yearsCount: number
    visible: boolean
    showTxAndAdjusted: boolean
    showTrend: boolean
    isPDF?: boolean
    isProforma?: boolean
}

export const FixedCashflowTableHeader = (props: Props) => {
    const { yearsCount, visible } = props
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)

    const isNewDev = project?.type && project?.type == 'miltifamily.new_dev'
    useSyncScroll('scrollable-header', 'cashflow-scrollable-container')
    //console.log('FixedCashflowTableHeader', props)
    if (!visible) {
        return null
    }

    return (
        <div className="fixed-header-wrapper">
            <div className="fixed-header">
                <table>
                    <thead>
                        <tr>
                            {!props.isPDF && props.showTxAndAdjusted && (
                                <th
                                    style={{
                                        width: 400,
                                        minWidth: 400,
                                        borderRight: '1px solid rgba(217,233,229,0.5)',
                                        paddingTop: 10,
                                    }}
                                >
                                    {isNewDev ? '' : 'T3/T12'}
                                </th>
                            )}

                            {!props.isPDF && props.showTxAndAdjusted && (
                                <th
                                    style={{
                                        borderRight: '1px solid rgba(217,233,229,0.5)',
                                        paddingTop: 10,
                                    }}
                                >
                                    {isNewDev ? 'Underwriting Projections' : 'Adjusted T3 Annualized'}
                                </th>
                            )}

                            {!props.isPDF && props.showTrend && (
                                <>
                                    <th
                                        style={{
                                            width: 200,
                                            minWidth: 200,
                                            paddingTop: 10,
                                        }}
                                    ></th>

                                    <th
                                        style={{
                                            borderRight: '1px solid rgba(217,233,229,0.5)',
                                            paddingTop: 10,
                                            right: '10px',
                                        }}
                                    >
                                        Trend
                                    </th>
                                </>
                            )}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="scrollable-header" id={'scrollable-header'}>
                <table>
                    <thead>
                        <tr>
                            {new Array(yearsCount).fill(0).map((item, ind) => (
                                <th key={ind}>YEAR {ind + 1}</th>
                            ))}
                        </tr>
                    </thead>
                </table>
            </div>

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .fixed-header-wrapper {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    background-color: white;
                    margin: 0px 45px 80px;
                    z-index: 10;
                    display: flex;
                    border-bottom: 1px solid rgba(217, 233, 229, 0.5);

                    th {
                        min-width: 200px;
                        width: 200px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #4f5662;
                        text-align: right;
                        position: relative;
                        height: 58px;
                        padding-right: 10px;
                    }
                    .fixed-header {
                        //todo:
                        nax-widh: 350px;
                    }
                    .scrollable-header {
                        overflow-x: auto;
                        transform: rotateX(180deg);
                        table {
                            transform: rotateX(180deg);
                        }
                        /* width */
                        &::-webkit-scrollbar {
                            height: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            border-radius: 2px;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: $gray-blue;
                            border-radius: 2px;
                        }
                    }
                }
            `}</style>
        </div>
    )
}
