import React, { useMemo } from 'react'
import { ScrollableTable, TableRow, TableTitle } from '../../ScrollableTable'
import { MortgageSchedule } from './types'
import { useSelector } from 'react-redux'
import { ReduxStoreState } from '../../../../../../../store'
import { orgenizeCFForPresentation, regenerateCashFlow } from 'origination-model'

type Props = {
    startBalance: number
    data: { senior: MortgageSchedule; mezzanine?: MortgageSchedule }
}

export const FinancingTable = (props: Props) => {
    let { startBalance } = props
    const { data } = props
    const storCashFlow = useSelector((store: ReduxStoreState) => store.lender.originationModel?.cashFlow)
    const model = useSelector((state: ReduxStoreState) => state?.lender?.originationModel)
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)

    const cashFlow = useMemo(() => {
        const cashFlowTmp = regenerateCashFlow({ model: model, cashFlow: storCashFlow, unitMix: unitMix })
        return orgenizeCFForPresentation({
            cashFlow: cashFlowTmp,
        })
    }, [storCashFlow])

    const loanPayoffMonths = useMemo(() => {
        const others = cashFlow?.other
        const loanPayOff = others.find((item) => item.id == 'loanPayOff')
        return loanPayOff?.months || []
    }, [cashFlow])

    const titles: TableTitle[] = []
    const rows: TableRow[] = useMemo(() => {
        const rows: Array<{
            title: string
            values: any[]
            rowNumeralFormat?: string
        }> = [
            {
                title: 'Date',
                values: [],
            },
            {
                title: 'Starting Balance',
                values: [],
                rowNumeralFormat: '$0,0',
            },
            {
                title: 'Loan Payment Senior',
                values: [],
                rowNumeralFormat: '$0,0',
            },
            {
                title: 'Principal Payment Senior',
                values: [],
                rowNumeralFormat: '$0,0',
            },
            {
                title: 'Interest Payment Senior',
                values: [],
                rowNumeralFormat: '$0,0',
            },
            {
                title: 'Loan Payoff',
                values: loanPayoffMonths,
                rowNumeralFormat: '($0,0)',
            },
            {
                title: 'Ending Balance',
                values: [],
                rowNumeralFormat: '$0,0',
            },
        ]
        //console.log('data', data)
        if (data?.senior.schedule) {
            // console.log('data?.senior.schedule', data?.senior.schedule)
            const dateToUse =
                model?.exitAssumption?.startAnalysisDate != null
                    ? new Date(model?.exitAssumption?.startAnalysisDate)
                    : new Date()
            let month = dateToUse.getMonth() + 1
            let year = dateToUse.getFullYear()

            // @ts-ignore
            for (const [ind, item] of data?.senior.schedule?.entries()) {
                titles.push({
                    title1: `Month ${ind + 1}`,
                    title2: `${month}/1/${year}`,
                })

                rows[0].values.push(`${month}/1/${year}`)

                //rows[1].values.push(startBalance)
                rows[1].values.push(item.startingBalance)
                rows[2].values.push(item.total)
                rows[3].values.push(item.principal)
                rows[4].values.push(item.interest)
                //startBalance = startBalance - item.principal
                //rows[6].values.push(startBalance)
                rows[6].values.push(item.endingBalance)

                month++
                if (month > 12) {
                    year++
                    month = 1
                }
            }
        }
        if (data?.mezzanine?.schedule) {
            rows.splice(5, 0, { title: 'Loan Payment Mezzanine', values: [], rowNumeralFormat: '$0,0' })
            rows.splice(6, 0, { title: 'Principal Payment Mezzanine', values: [], rowNumeralFormat: '$0,0' })
            rows.splice(7, 0, { title: 'Interest Payment Mezzanine', values: [], rowNumeralFormat: '$0,0' })
            let startBalance = rows[1].values[0]
            for (const [ind, item] of data?.mezzanine?.schedule?.entries()) {
                // rows[1].values[ind] = startBalance
                rows[1].values[ind] += item?.startingBalance
                rows[5].values.push(item?.total)
                rows[6].values.push(item?.principal)
                rows[7].values.push(item?.interest)
                // startBalance = startBalance - item.principal
                // rows[9].values[ind] = startBalance
                rows[9].values[ind] += item?.endingBalance
            }
        }
        // console.log('rows', rows)
        return rows
    }, [data])
    return <ScrollableTable rows={rows} titles={titles} />
}
