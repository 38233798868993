import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ROUTE } from '../../../../utils/constants/routes'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showNotification } from '../../fullproject/notifications'
import { ReduxHelper, ReduxStoreState } from '../../../../store'
import { SagaHelper } from '../../../../store/sagaHelper'
import { SaveNarrativeVersion } from './modals/SaveNarrativeVersion'
import { VersionHistoryModal } from './modals/NarrativeHistory'
import { useSelector } from 'react-redux'

import { TAB } from './tabs'
import { regenerateCashFlow } from 'origination-model'
import { useRouter } from 'next/router'

type Props = {
    project: any
    isAdmin: boolean
    setActiveTab?: (string) => void
}
function isPromise(p) {
    return p && Object.prototype.toString.call(p) === '[object Promise]'
}
export const DealNarrativeActions = (props: Props) => {
    const project = useSelector((store: ReduxStoreState) => store.lenderProjects.project)
    const [showActions, setShowActions] = useState(false)
    const toggleActions = useCallback(() => {
        setShowActions(!showActions)
    }, [showActions])
    const hideActions = useCallback(() => setShowActions(false), [])

    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit

    const [showVersionsSubmenu, setShowVersionsSubmenu] = useState(false)
    const [saveVersionModalVisible, setSaveVersionModalVisible] = useState<boolean>(false)
    const [historyModalVisible, setHistoryModalVisible] = useState<boolean>(false)

    const openSaveVersionModal = useCallback(() => {
        hideActions()
        setSaveVersionModalVisible(true)
    }, [])

    const closeSaveVersionModal = useCallback(() => {
        setSaveVersionModalVisible(false)
    }, [])

    const openHistoryModal = useCallback(() => {
        hideActions()
        setHistoryModalVisible(true)
    }, [])

    const closeHistoryModal = useCallback(() => {
        setHistoryModalVisible(false)
    }, [])

    const onCopy = useCallback(() => {
        hideActions()
        showNotification('Link is copied to clipboard').finally()
    }, [])

    const isModelChanged = useSelector((state: ReduxStoreState) => state.lender.isModelChanged)
    const reduxModel = useSelector((state: ReduxStoreState) => state.lender.originationModel)
    const pricing = useSelector((state: ReduxStoreState) => state.lender.pricing)
    const fannieQuotes = useSelector((state: ReduxStoreState) => state.lender.fannieQuotes)

    let narrativeId = project?.defaultNarrativeId || 0

    useEffect(() => {
        // console.log('narrativeId', projectId, project?.defaultNarrativeId)
        if (!project?.defaultNarrativeId) {
            SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                projectId: projectId,
            }).then((res) => {
                //console.log('narrativeId', res)
                narrativeId = res
            })
        } else narrativeId = project?.defaultNarrativeId
    }, [project, project?.defaultNarrativeId])

    const update = useCallback(async () => {
        const { cashFlow, ...originationModel } = reduxModel
        const unitMix = ReduxHelper.store.getState()?.lender?.unitMix
        const cf = regenerateCashFlow({ cashFlow, model: originationModel, unitMix: unitMix })
        const additionalData = {
            dealIRR: cf.dealIRR,
            cashFlow: cf,
        }
        if (isModelChanged) {
            const confirmation = confirm('The model was changed, do you want to save it before updating?')
            if (confirmation) {
                if (originationModel['cashflow']) {
                    delete originationModel['cashflow']
                }

                await SagaHelper.run(['lenderOriginationModel', 'saveCashFlow'], {
                    projectId: +project.id,
                    cashFlow,
                })

                await SagaHelper.run(['lenderOriginationModel', 'setOriginationModel'], {
                    projectId: +project.id,
                    originationModel,
                })
                ReduxHelper.setIn(['lender', 'isModelChanged'], false)

                await SagaHelper.run(['lenderProjects', 'updateOMProforma'], {
                    projectId: +project.id,
                    additionalData: JSON.stringify(additionalData),
                })
            }
        } else {
            await SagaHelper.run(['lenderProjects', 'updateOMProforma'], {
                projectId: +project.id,
                additionalData: JSON.stringify(additionalData),
            })
        }

        if (originationModel.allowFannieAPricing) {
            if (pricing?.fanniePricing?.pricingOptions?.length > 0)
                await SagaHelper.run(['lenderPricing', 'setPricing'], {
                    projectId: +project.id,
                    pricing: pricing,
                })

            if (fannieQuotes?.quotes?.length > 0)
                await SagaHelper.run(['lenderFannieQuotes', 'setFannieQuotes'], {
                    projectId: +project.id,
                    fannieQuotes: fannieQuotes,
                })
        }
        const go = (nid) => {
            SagaHelper.run(['lenderProjects', 'updateNarrativeTables'], {
                projectId: +project.id,
                narrativeId: nid,
            })
        }

        if (!narrativeId) {
            SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                projectId: projectId,
            }).then((res) => {
                const narrativeIdRes = res
                go(narrativeIdRes)
            })
        } else {
            if (isPromise(narrativeId)) {
                Promise.resolve(narrativeId).then((res) => {
                    go(res)
                })
            } else go(narrativeId)
        }

        await SagaHelper.run(['notification', 'show'], 'Update is completed')
    }, [project, narrativeId, isModelChanged, reduxModel, pricing, fannieQuotes])

    const openNarrative = useCallback(
        (mode) => {
            // console.log('openNarrative', mode, projectId, project?.id, narrativeId)
            const go = (nid) => {
                const win = window.open(
                    `${mode == 'edit' ? ROUTE.NARRATIVE_EDITOR : ROUTE.NARRATIVE_REPORT}?${
                        mode == 'edit' ? 'projectId' : 'id'
                    }=${props.project.originalProjectId}&narrativeId=${nid}`,
                    '_blank',
                )
                win?.focus()
            }

            if (!narrativeId) {
                SagaHelper.run(['lenderProjects', 'getDefaultNarrativeId'], {
                    projectId: projectId,
                }).then((res) => {
                    const narrativeIdRes = res
                    go(narrativeIdRes)
                })
            } else {
                if (isPromise(narrativeId)) {
                    Promise.resolve(narrativeId).then((res) => {
                        go(res)
                    })
                } else go(narrativeId)
            }
        },
        [narrativeId, project],
    )

    const handleMouseEnter = () => {
        setShowVersionsSubmenu(true)
    }

    const handleMouseLeave = () => {
        setShowVersionsSubmenu(false)
    }

    return (
        <div className={'select-wrapper'} onMouseLeave={() => hideActions()}>
            <button className="btn dropdown-toggle" onClick={toggleActions}>
                Deal Narrative
            </button>
            {showActions && (
                <div className={'actions-wrapper'}>
                    <div className="actions-wrapper__item">
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                e.stopPropagation()

                                update()
                                hideActions()
                            }}
                        >
                            Update
                        </a>
                    </div>

                    <div className={`actions-wrapper__item `}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e?.stopPropagation()
                                e?.preventDefault()
                                openNarrative('view')
                                hideActions()
                            }}
                        >
                            View
                        </a>
                    </div>
                    <div className={`actions-wrapper__item `}>
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                openNarrative('edit')
                                hideActions()
                            }}
                        >
                            Edit
                        </a>
                    </div>
                    <div
                        className={`actions-wrapper__item submenu`}
                        onMouseLeave={handleMouseLeave}
                        onMouseEnter={handleMouseEnter}
                    >
                        Versioning
                        <div className="arrow-wrapper">
                            <i className="material-icons arrow-icon" style={{ fontSize: 18 }}>
                                arrow_right
                            </i>
                        </div>
                        {showVersionsSubmenu && (
                            <div className="popover">
                                <div className="popover-item-1" onClick={openSaveVersionModal}>
                                    Save Version
                                </div>
                                <div className="popover-item-2" onClick={openHistoryModal}>
                                    Show History
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {saveVersionModalVisible && <SaveNarrativeVersion project={project} close={closeSaveVersionModal} />}
            {historyModalVisible && <VersionHistoryModal project={project} close={closeHistoryModal} />}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                .exportBeta {
                    color: red;
                    font-size: 9px;
                    top: -4px;
                    position: relative;
                }
                .select-wrapper {
                    //left: 370px;
                    position: relative;
                    //top: -7px;

                    .dropdown-toggle {
                        border: 1px solid $border;
                        font-size: 15px;
                        width: 200px;
                        font-weight: 300;
                        outline: none;
                    }

                    .actions-wrapper {
                        position: absolute;
                        background-color: $white;
                        border: 1px solid $border;
                        border-radius: 3px;
                        width: 100%;
                        z-index: 1000000;
                        &__item {
                            color: $dark-gray;
                            font-size: 13px;
                            cursor: pointer;
                            transition: all linear 0.2s;
                            a {
                                padding: 10px;
                                display: block;
                            }
                            &:hover {
                                background-color: $border;
                            }
                            &.disabled {
                                pointer-events: none;
                                cursor: not-allowed;
                                opacity: 0.5;
                            }
                        }
                    }
                }
                .disabled-link {
                    opacity: 0.5;
                }

                .submenu {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                }
                .arrow-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    flex: 1;
                }
                .popover {
                    width: 120px;
                    height: 80px;
                    background-color: $background;
                    position: absolute;
                    white-space: nowrap;
                    display: flex;
                    flex-direction: column;
                    left: 198px;
                }
                .popover-item-1,
                .popover-item-2 {
                    flex: 1;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    &:hover {
                        background: $border;
                    }
                }
                .popover-item-1 {
                    padding-bottom: 5px;
                }
                .popover-item-2 {
                    padding-top: 5px;
                }

                @media (max-width: 1025px) {
                    :global(.dropdown-toggle) {
                    }
                    :global(.dropdown-toggle::after) {
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        bottom: auto;
                    }
                }
            `}</style>
        </div>
    )
}
