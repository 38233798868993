import React, {
    forwardRef,
    SyntheticEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
    useContext,
} from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { NumberField } from '../../components/NumberField'
import { CashFlowRowGroup } from './CashFlowRowGroup'
import { CashFlow } from '../../../../../store/types/OriginationModelRedux'
import { CashFlowRowComp } from './CashFlowRow'
import { removeItemFromArray } from '../../../../../utils'
import { useSelector } from 'react-redux'
import { ReduxHelper, ReduxStoreState } from '../../../../../store'
import createDecorator from 'final-form-calculate'
import { OriginationModelReworked } from '@generated/graphql'
import { FixedCashflowTableHeader } from './FixedCashflowTableHeader'
import { useSyncScroll } from '../../../../../utils/useSyncScroll'
import { useRouter } from 'next/router'
import { regenerateCashFlow } from 'origination-model'
const cloneDeep = require('lodash.clonedeep')
import { getMetrics } from 'origination-model'
import { FormContext } from '../../Dashboard'
import { isFormModified } from 'utils/forms'

type Props = {
    data: CashFlow
    mode?: 'view' | 'edit'
    save: any
    // this props are used in view cashflow page
    model?: OriginationModelReworked
    showKPIs?: boolean
    showOther?: boolean
    showTrend?: boolean
    showTxAndAdjusted?: boolean
    cfTrends?: any
    cfTrendTax?: any
    updateTrends?: any
    trendName?: string
    isPDF?: boolean
    isProforma?: boolean
    isDashboard?: boolean
}
export const CashFlowEdit = forwardRef(CashFlowEditFunc)
export function CashFlowEditFunc(props: Props, ref) {
    const showKPIs = props.showKPIs != null ? props.showKPIs : true
    const showOther = props.showOther != null ? props.showOther : true
    const showTrend = props.showTrend != null ? props.showTrend : false
    const showTxAndAdjusted = props.showTxAndAdjusted != null ? props.showTxAndAdjusted : true
    const model = props.model
    const unitMix = useSelector((state: ReduxStoreState) => state?.lender?.unitMix)
    //console.log('CF DATA', cloneDeep(props.data))
    // console.log('cfTrends', props.cfTrends ? cloneDeep(props.cfTrends) : null)
    // console.log('totalPotentialGrossRent', cloneDeep(props.data.totalPotentialGrossRent.years))
    // console.log('tax', cloneDeep(props.data.operatingExpenses.find((t) => t.id == 'taxes')?.years))

    const { dealWasSaved, formModified, setFormModified, setFormModifiedViaMutators, formVisited, setFormVisited } =
        useContext(FormContext)

    const formData = useMemo(() => {
        // console.log('cf new form data', props)
        if (props.isProforma)
            return {
                ...cloneDeep(props.data),
            }
        return {
            ...cloneDeep(props.data),
            cfTrends: props?.cfTrends ? cloneDeep(props.cfTrends) : null,
            //cfTrendTax: props.cfTrendTax ? cloneDeep(props.cfTrendTax) : null,
            cfTrendTax: props?.cfTrends?.tax ? cloneDeep(props.cfTrends.tax) : null,
            readOnly: props.mode == 'view',
            dealIRR: props.data?.dealIRR || 0,
            netIRR: props.data?.netIRR || 0,
            totalEquity: model?.sources.totalEquity,
            capRate: props.data?.capRate || [],
            capRateApr: props.data?.capRateApr || 0,
            coc: props.data?.coc || [],
            roe: props.data?.roe || [],
        }
    }, [props.data, props.mode, props.cfTrends, props.isProforma])
    const context = useMemo(() => ({ form: null, componentLoaded: false, lastUpdate: null }), [])

    const onSubmit = useCallback(
        (values) => {
            try {
                if (props?.save) {
                    props.save()
                }
                return null
            } catch (e) {
                console.log(e.message)
            }
        },
        [props],
    )
    useImperativeHandle(ref, () => ({
        getValues: () => {
            return context.form?.getState()?.values || {}
        },
    }))

    const sections = ['rentalIncome', 'rentalLoss', 'otherIncome', 'operatingExpenses', 'other']
    const resultRows = [
        'totalPotentialGrossRent',
        'effectiveGrossRent',
        'effectiveGrossIncome',
        'totalOperatingExpenses',
        'cashFlowAfterDebtService',
        'noi',
    ]
    const calcRows = ['roe', 'coc', 'capRate']

    const computedValues = useMemo(() => {
        if (props.isProforma) return []
        // console.log('computedValues createDecorator')
        return [
            createDecorator(
                {
                    field: /cfTrends.trends*/,
                    updates: (value, name, values: any) => {
                        // console.log('docrator trend', name, value)
                        const idx = name.split('.')[1].replace('trends[', '').replace(']', '')
                        //  console.log('idx', idx)
                        if (props.updateTrends) props.updateTrends(idx, value, 'trend')
                        return {}
                    },
                },
                {
                    field: /cfTrends.gpr*/,
                    updates: (value, name, values: any) => {
                        // console.log('docrator gpr', name, value)
                        const idx = name.split('.')[1].replace('gpr[', '').replace(']', '')
                        //  console.log('idx', idx)
                        if (props.updateTrends) props.updateTrends(idx, value, 'gpr')
                        if (value == null) return {}
                        return {}
                    },
                },
                {
                    field: /cfTrendTax*/,
                    updates: (value, name, values: any) => {
                        // console.log('docrator tax', name, value)
                        const idx = name.split('.')[0].replace('cfTrendTax[', '').replace(']', '')
                        //  console.log('idx', idx)
                        if (props.updateTrends) props.updateTrends(idx, value, 'tax')
                        if (value == null) return {}
                        return {}
                    },
                },
                {
                    field: /(rentalIncome|rentalLoss|otherIncome|operatingExpenses|other).*/,
                    updates: (value: any, name: string, allValues: CashFlow) => {
                        if (props.showTrend) return {}
                        const dif = new Date().valueOf() - (context.lastUpdate || 0)
                        if (dif < 50) return {}
                        //console.log('decorator')
                        context.lastUpdate = new Date().valueOf()
                        const cashFlow = regenerateCashFlow({ cashFlow: allValues, model, unitMix: unitMix }) //cap rate

                        const totalFields = [
                            'totalPotentialGrossRent',
                            'effectiveGrossRent',
                            'effectiveGrossIncome',
                            'totalOperatingExpenses',
                            'noi',
                            'cashFlowAfterDebtService',
                        ]
                        const out: Record<string, any> = {}
                        for (const fld of totalFields) {
                            out[`${fld}.apr`] = cashFlow[fld].apr
                            for (const i in cashFlow.totalPotentialGrossRent.years) {
                                out[`${fld}.years[${i}]`] = cashFlow[fld].years[i]
                            }
                        }
                        const metrics = getMetrics({ model, cashFlow })
                        out['dealIRR'] = metrics.dealIRR
                        out['netIRR'] = metrics.netIRR
                        out['capRateApr'] = metrics.capRateApr
                        const caprRatePerYear = metrics.capRatePerYear
                        const coc = metrics.cocPerYear
                        const roe = metrics.roePerYear
                        for (const i in cashFlow.noi.years) {
                            out[`capRate[${i}]`] = caprRatePerYear[i]
                            out[`coc[${i}]`] = coc[i]
                            out[`roe[${i}]`] = roe[i]
                        }
                        const ind = cashFlow.other.findIndex((item) => item.id == 'proceedsFromSale')
                        if (!name.includes(`other`)) {
                            out[`other[${ind}].years`] = cashFlow.other[ind].years
                        }

                        out['capRateApr'] = cashFlow.capRateApr

                        const dscrPerYear = metrics.dscrPerYear
                        for (const [i, v] of dscrPerYear.entries()) {
                            out[`dscrA[${i}]`] = v
                        }
                        const debtYieldPerYear = metrics.debtYieldPerYear
                        for (const [i, v] of debtYieldPerYear.entries()) {
                            out[`debtYieldA[${i}]`] = v
                        }

                        out['dscr'] = metrics.dscr

                        out['debtYield'] = metrics.debtYield

                        return out
                    },
                },
                {
                    field: /.*/,
                    updates: (value, name, values: any) => {
                        //console.log('**********', name, value)
                        //console.log(value)
                        //console.log(name)
                        //console.log(values)
                        return {}
                    },
                },
            ),
        ]
    }, [props.updateTrends, props.trendName, props.isProforma])

    return (
        <>
            {((props.isDashboard && !dealWasSaved) || !props.isDashboard) && (
                <Form
                    onSubmit={onSubmit}
                    initialValues={formData}
                    decorators={computedValues}
                    mutators={{
                        ...arrayMutators,
                        insertAt: ([name, index, value], state, { changeValue }) => {
                            if (props.isDashboard)
                                setFormModifiedViaMutators((prevState) => ({
                                    ...prevState,
                                    cashflow: true,
                                }))
                            changeValue(state, name, (array) => {
                                const copy = [...(array || [])]
                                copy.splice(index, 0, value)
                                return copy
                            })
                        },
                        addCol: (params, state, form) => {
                            if (props.isDashboard)
                                setFormModifiedViaMutators((prevState) => ({
                                    ...prevState,
                                    cashflow: true,
                                }))
                            const newFieldValue = 0
                            for (const section of sections) {
                                for (const [i, row] of state.formState.values[section].entries()) {
                                    const fieldsCount = row.years.length
                                    for (let j = 0; j <= i; j++) {
                                        form.changeValue(state, `${section}.${j}.years.${fieldsCount}`, (val) => {
                                            return newFieldValue
                                        })
                                    }
                                }
                            }

                            for (const resultRow of resultRows) {
                                const rowLength = state.formState.values[resultRow].years.length
                                form.changeValue(state, `${resultRow}.years.${rowLength}`, (val) => {
                                    return newFieldValue
                                })
                            }

                            for (const calcRow of calcRows) {
                                const rowLength = state.formState.values[calcRow].length
                                form.changeValue(state, `${calcRow}.${rowLength}`, (val) => {
                                    return newFieldValue
                                })
                            }
                        },
                        removeCol: (params, state, form) => {
                            if (props.isDashboard)
                                setFormModifiedViaMutators((prevState) => ({
                                    ...prevState,
                                    cashflow: true,
                                }))
                            const colNumber = params[0]

                            for (const section of sections) {
                                for (const [i] of state.formState.values[section].entries()) {
                                    form.changeValue(state, `${section}.${i}.years`, (val) => {
                                        const newArr = [...val]
                                        return removeItemFromArray(newArr, colNumber)
                                    })
                                }
                            }

                            for (const resultRow of resultRows) {
                                form.changeValue(state, `${resultRow}.years`, (val) => {
                                    const newArr = [...val]
                                    return removeItemFromArray(newArr, colNumber)
                                })
                            }

                            for (const calcRow of calcRows) {
                                form.changeValue(state, `${calcRow}`, (val) => {
                                    const newArr = [...val]
                                    return removeItemFromArray(newArr, colNumber)
                                })
                            }
                        },
                    }}
                >
                    {(formProps) => {
                        if (props.isDashboard) {
                            if (isFormModified(formProps.visited, formProps.modified)) {
                                if (formVisited?.cashflow === false) {
                                    setFormVisited((prevState) => ({
                                        ...prevState,
                                        cashflow: true,
                                    }))
                                }
                                if (formModified?.cashflow === false) {
                                    setFormModified((prevState) => ({
                                        ...prevState,
                                        cashflow: true,
                                    }))
                                }
                            }
                        }
                        context.form = formProps.form
                        return (
                            <CashFlowEditForm
                                mode={props.mode}
                                onSubmit={props.save}
                                showKPIs={showKPIs}
                                showOther={showOther}
                                showTrend={showTrend}
                                showTxAndAdjusted={showTxAndAdjusted}
                                isPDF={props.isPDF}
                                isProforma={props.isProforma}
                            />
                        )
                    }}
                </Form>
            )}
        </>
    )
}

const CashFlowEditForm = (props: {
    mode?: 'view' | 'edit'
    onSubmit: any
    showKPIs: boolean
    showOther: boolean
    showTrend: boolean
    showTxAndAdjusted: boolean
    isPDF?: boolean
    isProforma?: boolean
}) => {
    //console.log('CashFlowEditForm', 'showTrend', props.showTrend, 'showTxAndAdjusted', props.showTxAndAdjusted)

    const form = useForm()
    const formState = useFormState()

    const colIterator = useMemo(() => {
        const formLength = formState?.values?.rentalIncome[0]?.years.length || 5
        return new Array(formLength).fill(0)
    }, [formState?.values?.rentalIncome[0]?.years.length])

    const addColumn = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()
            e.stopPropagation()
            form.mutators.addCol()
            const container = document.querySelector('.scrollable-container')
            setTimeout(() => {
                container.scrollLeft = 10000
            }, 1)
        },
        [form.mutators],
    )

    const removeColumn = useCallback(
        (i: number) => {
            form.mutators.removeCol(i)
        },
        [form],
    )

    const tableTitles = []
    for (let i = 1; i <= (formState.values?.rentalIncome[0]?.years?.length || 5); i++) {
        if (!props.isPDF || i <= 5)
            tableTitles.push(
                <th key={i}>
                    YEAR {i}
                    {props.mode !== 'view' && (
                        <div className={'delete-column'} onClick={removeColumn.bind(null, i - 1)}>
                            <img
                                className={'normal'}
                                src={'/_img/lenderDashboard/trash.svg'}
                                onMouseEnter={(e: SyntheticEvent) => {
                                    // @ts-ignore
                                    const elem: HTMLImageElement = e.target
                                    elem.src = '/_img/lenderDashboard/trash-red.svg'
                                }}
                                onMouseLeave={(e: SyntheticEvent) => {
                                    // @ts-ignore
                                    const elem: HTMLImageElement = e.target
                                    elem.src = '/_img/lenderDashboard/trash.svg'
                                }}
                            />
                        </div>
                    )}
                </th>,
            )
    }
    const organicRentGrowthForm = []
    for (let i = 0; i < (formState.values?.rentalIncome[0]?.years?.length || 5); i++) {
        organicRentGrowthForm.push(
            <td key={'organicRentGrowthForm' + i}>
                {i > 0 && <NumberField name={`cfTrends.gpr[${i}]`} numberFormat={'percent'} readOnly={false} />}
            </td>,
        )
    }

    const [cashFlowTitlesVisible, setCashFlowTitlesVisible] = useState(false)

    useSyncScroll('cashflow-scrollable-container', 'scrollable-header')

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollPosition = window?.scrollY
            if (scrollPosition > 250) {
                setCashFlowTitlesVisible(true)
                const container = document?.getElementById('cashflow-scrollable-container')
                const header = document?.getElementById('scrollable-header')
                if (header && container) {
                    header.scrollLeft = container?.scrollLeft
                    header.addEventListener('scroll', () => (container.scrollLeft = header?.scrollLeft))
                }
            } else {
                setCashFlowTitlesVisible(false)
            }
        }
        window.addEventListener('scroll', handleScroll, false)
    }, [cashFlowTitlesVisible])

    const router = useRouter()
    const projectId = +router.query.id || +router.query.edit
    const project = useSelector((state: ReduxStoreState) => state.lenderProjects.project)
    const isNewDev = project?.type && project?.type == 'miltifamily.new_dev'

    const accountType = useMemo(() => {
        return project?.accountType
    }, [project])

    return (
        <form
            className={'cashflow-container'}
            id={'cash-flow-form'}
            onSubmit={(e) => {
                e.preventDefault()
                props.onSubmit()
            }}
        >
            {props.mode !== 'view' && (
                <div className={'add-col-button'} onClick={addColumn}>
                    <img src="/_img/lenderDashboard/add_row.svg" />
                </div>
            )}
            {!props.isPDF && (
                <FixedCashflowTableHeader
                    yearsCount={formState.values?.rentalIncome[0]?.years?.length || 5}
                    visible={cashFlowTitlesVisible}
                    showTxAndAdjusted={props.showTxAndAdjusted}
                    showTrend={props.showTrend}
                    isPDF={props.isPDF}
                    isProforma={props.isProforma}
                />
            )}
            <div className="tables-container">
                <table className="part-1 table-fixed" style={{ width: '350px' }} cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            {!props.isPDF && props.showTxAndAdjusted && (
                                <th style={{ width: 150, paddingRight: 25 }} colSpan={4}>
                                    {isNewDev ? '' : 'T3/T12'}
                                </th>
                            )}
                            {!props.isPDF && props.showTxAndAdjusted && (
                                <th style={{ width: 150, paddingRight: 25 }} colSpan={4}>
                                    {isNewDev ? 'Underwriting Projections' : 'Adjusted T3 Annualized'}
                                </th>
                            )}
                            {!props.isPDF && props.showTrend && (
                                <th style={{ width: 150, paddingRight: 25 }} colSpan={4}>
                                    Trend
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {!props.isPDF && props.showTrend && (
                            <tr>
                                <td style={{ textIndent: '20px', height: '26px' }} colSpan={4}>
                                    Organic Rent Growth (affects GPR Only)
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className={'group'} colSpan={4}>
                                Rental Income
                            </td>
                        </tr>
                        <CashFlowRowGroup
                            fieldName={'rentalIncome'}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowComp
                            fieldName={'totalPotentialGrossRent'}
                            readOnly={true}
                            className={'readOnly'}
                            addRowGroup={'rentalIncome'}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowGroup
                            fieldName={'rentalLoss'}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowComp
                            fieldName={'effectiveGrossRent'}
                            readOnly={true}
                            className={'readOnly'}
                            addRowGroup={'rentalLoss'}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowGroup
                            fieldName={'otherIncome'}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowComp
                            fieldName={'effectiveGrossIncome'}
                            readOnly={true}
                            className={'readOnly'}
                            addRowGroup={'otherIncome'}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <tr>
                            <td className={'group'} colSpan={4}>
                                Operating Expenses
                            </td>
                        </tr>
                        <CashFlowRowGroup
                            fieldName={'operatingExpenses'}
                            onlyTitles={true}
                            inverseValues={props?.mode == 'view'}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowComp
                            fieldName={'totalOperatingExpenses'}
                            readOnly={true}
                            className={'readOnly'}
                            addRowGroup={'operatingExpenses'}
                            onlyTitles={true}
                            inverseValues={props?.mode == 'view'}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        <CashFlowRowComp
                            fieldName={'noi'}
                            readOnly={true}
                            onlyTitles={true}
                            isNewDev={isNewDev}
                            showTxAndAdjusted={props.showTxAndAdjusted}
                            showTrend={props.showTrend}
                            isPDF={props.isPDF}
                        />
                        {props.showOther && (
                            <CashFlowRowGroup
                                fieldName={'other'}
                                onlyTitles={true}
                                readOnly={true}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                        )}
                        {props.showOther && (
                            <CashFlowRowComp
                                fieldName={'cashFlowAfterDebtService'}
                                readOnly={true}
                                className={'readOnly'}
                                onlyTitles={true}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                        )}
                        {props.showKPIs && accountType == 'borrower' && (
                            <>
                                <tr className={'bottom-row border-bottom'}>
                                    <td className={'text-left'} style={{ paddingLeft: 30 }}>
                                        Deal IRR
                                    </td>
                                    <td className={'border-right'}>
                                        <NumberField name={`dealIRR`} numberFormat={'percent'} readOnly={true} />
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={'pr-1'}>CoC</td>
                                </tr>
                                <tr className={'bottom-row border-bottom'}>
                                    <td className={'text-left'} style={{ paddingLeft: 30 }}>
                                        Net IRR
                                    </td>
                                    <td className={'border-right'}>
                                        <NumberField name={`netIRR`} numberFormat={'percent'} readOnly={true} />
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={'pr-1'}>Roe</td>
                                </tr>
                            </>
                        )}
                        {props.showKPIs && accountType == 'lender' && (
                            <>
                                <tr className={'bottom-row border-bottom'}>
                                    <td className={'text-left'} style={{ paddingLeft: 30 }}>
                                        DSCR
                                    </td>
                                    <td className={'border-right'}>
                                        <NumberField name={`dscr`} numberFormat={'multiple'} readOnly={true} />
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={'pr-1'}>Per Year</td>
                                </tr>
                                <tr className={'bottom-row border-bottom'}>
                                    <td className={'text-left'} style={{ paddingLeft: 30 }}>
                                        Debt Yield
                                    </td>
                                    <td className={'border-right'}>
                                        <NumberField name={`debtYield`} numberFormat={'percent'} readOnly={true} />
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={'pr-1'}>Per Year</td>
                                </tr>
                            </>
                        )}
                        {props.showKPIs && (
                            <tr className={'bottom-row'}>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>Cap Rate</td>
                                <td className={'pr-1'}>
                                    <NumberField name={`capRateApr`} numberFormat={'percent'} readOnly={true} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div
                    className={'scrollable-container'}
                    id={'cashflow-scrollable-container'}
                    style={{ overflowX: props.isPDF ? 'hidden' : 'auto' }}
                >
                    <table
                        className="part-1 table-scrollable'"
                        id={'table-scrollable'}
                        style={{ width: '170px' }}
                        cellPadding={0}
                        cellSpacing={0}
                    >
                        <thead>
                            <tr>{tableTitles}</tr>
                        </thead>
                        <tbody>
                            {!props.isPDF && props.showTrend && <tr>{organicRentGrowthForm}</tr>}
                            {!props.isPDF && (
                                <tr>
                                    <td className={'group'} colSpan={2} style={{ height: 54 }}></td>
                                </tr>
                            )}
                            <CashFlowRowGroup
                                fieldName={'rentalIncome'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowComp
                                fieldName={'totalPotentialGrossRent'}
                                readOnly={true}
                                className={'readOnly'}
                                addRowGroup={'rentalIncome'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowGroup
                                fieldName={'rentalLoss'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowComp
                                fieldName={'effectiveGrossRent'}
                                readOnly={true}
                                className={'readOnly'}
                                addRowGroup={'rentalLoss'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowGroup
                                fieldName={'otherIncome'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowComp
                                fieldName={'effectiveGrossIncome'}
                                readOnly={true}
                                className={'readOnly'}
                                addRowGroup={'otherIncome'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <tr>
                                <td className={'group'} colSpan={2} style={{ height: 54 }}>
                                    {/*Operating Expenses*/}
                                </td>
                            </tr>
                            <CashFlowRowGroup
                                fieldName={'operatingExpenses'}
                                inverseValues={props?.mode == 'view'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowComp
                                fieldName={'totalOperatingExpenses'}
                                readOnly={true}
                                className={'readOnly'}
                                addRowGroup={'operatingExpenses'}
                                inverseValues={props?.mode == 'view'}
                                isNewDev={isNewDev}
                                showTxAndAdjusted={props.showTxAndAdjusted}
                                showTrend={props.showTrend}
                                isPDF={props.isPDF}
                            />
                            <CashFlowRowComp
                                fieldName={'noi'}
                                readOnly={true}
                                isNewDev={isNewDev}
                                isPDF={props.isPDF}
                            />
                            {props.showOther && (
                                <CashFlowRowGroup
                                    fieldName={'other'}
                                    isNewDev={isNewDev}
                                    showTxAndAdjusted={props.showTxAndAdjusted}
                                    showTrend={props.showTrend}
                                    isPDF={props.isPDF}
                                />
                            )}
                            {props.showOther && (
                                <CashFlowRowComp
                                    fieldName={'cashFlowAfterDebtService'}
                                    readOnly={true}
                                    className={'readOnly'}
                                    isNewDev={isNewDev}
                                    showTxAndAdjusted={props.showTxAndAdjusted}
                                    showTrend={props.showTrend}
                                    isPDF={props.isPDF}
                                />
                            )}
                            {props.showKPIs && accountType == 'borrower' && (
                                <>
                                    <tr>
                                        {colIterator.map((v, i) => {
                                            return (
                                                <td key={'coc' + i} className={'bottom-row border-bottom'}>
                                                    <NumberField
                                                        name={`coc[${i}]`}
                                                        numberFormat={'percent'}
                                                        readOnly={true}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        {/*<td>Net IRR</td>*/}
                                        {/*<td>*/}
                                        {/*    <NumberField name={`netIRR`} numberFormat={'percent'} readOnly={true} />*/}
                                        {/*</td>*/}
                                        {/*<td>&nbsp;</td>*/}
                                        {/*<td>Roe</td>*/}
                                        {colIterator.map((v, i) => {
                                            return (
                                                <td key={'roe' + i} className={'bottom-row border-bottom'}>
                                                    <NumberField
                                                        name={`roe[${i}]`}
                                                        numberFormat={'percent'}
                                                        readOnly={true}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </>
                            )}

                            {props.showKPIs && accountType == 'lender' && (
                                <>
                                    <tr>
                                        {colIterator.map((v, i) => {
                                            return (
                                                <td key={'coc' + i} className={'bottom-row border-bottom'}>
                                                    <NumberField
                                                        name={`dscrA[${i}]`}
                                                        numberFormat={'multiple'}
                                                        readOnly={true}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        {colIterator.map((v, i) => {
                                            return (
                                                <td key={'roe' + i} className={'bottom-row border-bottom'}>
                                                    <NumberField
                                                        name={`debtYieldA[${i}]`}
                                                        numberFormat={'percent'}
                                                        readOnly={true}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </>
                            )}
                            {props.showKPIs && (
                                <tr>
                                    {/*<td>&nbsp;</td>*/}
                                    {/*<td>&nbsp;</td>*/}
                                    {/*<td>Cap Rate</td>*/}
                                    {/*<td>*/}
                                    {/*    <NumberField name={`capRateApr`} numberFormat={'percent'} readOnly={true} />*/}
                                    {/*</td>*/}
                                    {colIterator.map((v, i) => {
                                        return (
                                            <td key={'capRate' + i} className={'bottom-row '}>
                                                <NumberField
                                                    name={`capRate[${i}]`}
                                                    numberFormat={'percent'}
                                                    readOnly={true}
                                                />
                                            </td>
                                        )
                                    })}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/*language=SCSS*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .cashflow-container {
                    background-color: #fff;
                    border-radius: 3px;
                    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    padding-bottom: 0;
                    padding-top: 5px;
                    .add-col-button {
                        color: $white;
                        display: inline-block;
                        position: absolute;
                        left: calc(100% - 20px);
                        top: 20px;
                        transform: rotate(-90deg);
                        cursor: pointer;
                    }
                }

                table.part-1 {
                    width: 100%;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;

                    thead {
                        :global(th) {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 17px;
                            color: $secondary-text-color;
                            text-align: right;
                            position: relative;
                            height: 58px;

                            :global(.delete-column) {
                                position: absolute;
                                right: 15px;
                                bottom: 35px;
                                background: #fff;
                                padding: 0px 5px 10px 5px;
                                border-radius: 3px;
                                cursor: pointer;
                            }
                        }

                        th:first-child {
                            width: 300px;
                        }
                    }

                    .group {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 400;
                        color: $default-text-color;
                        padding-top: 20px;
                        padding-bottom: 10px;
                        padding-left: 10px;
                    }

                    :global(td:nth-child(2)),
                    th:nth-child(2),
                    .group {
                        padding-right: 24.5px;
                    }

                    :global(.cash_flow),
                    :global(.readOnly) {
                        :global(td:nth-child(2)) {
                            border-right-width: 0;
                        }
                    }
                }

                table.part-2 {
                    width: 100%;
                    margin-top: 14px;
                    table-layout: fixed;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: $default-text-color;

                    td {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    td:first-child {
                        width: 135px;
                        padding-left: 20px;
                    }

                    td:nth-child(2) {
                        width: 75px;
                        padding-right: 15px;
                    }

                    td:nth-child(3) {
                        width: 90px;
                        padding-left: 15px;
                    }

                    td:nth-child(4) {
                        padding-right: 24.5px;
                    }

                    td:nth-child(n + 4):nth-child(-n + 10) {
                        width: calc((100% - 300px) / 7);
                        text-align: right;
                    }

                    tr:first-child,
                    tr:nth-child(2) {
                        border-bottom: 1px solid $grid-row-selected-bg;
                    }
                }

                table.part-1,
                table.part-2 {
                    :global(.input-container:after) {
                        bottom: 0px;
                        border-top: 1px solid #d9e0e5;
                        opacity: 1;
                    }

                    :global(.input-container) {
                        width: auto;
                        position: relative;
                        display: flex;

                        :global(input) {
                            flex: 1;
                        }
                    }

                    :global(td:nth-child(n + 2)) {
                        :global(.input-container) {
                            margin-left: 20%;
                        }
                    }

                    :global(.input-container.readOnly:after) {
                        display: none;
                    }
                }

                .table-title {
                    text-align: left;
                    font-size: 1.4rem;
                }

                .tables-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                }

                .table {
                    color: white;
                    border: 1px solid rgba(255, 255, 255, 0.45);

                    tr {
                        display: flex;

                        td,
                        th {
                            min-width: 90px;
                        }
                    }

                    .bold {
                        font-weight: bold;
                    }

                    .top-border {
                        border-top: 1px solid rgba(255, 255, 255, 0.45);
                    }

                    margin-bottom: 0;
                }

                .scrollable-container {
                    overflow-x: auto;
                    white-space: nowrap;
                    flex: 1;
                    padding-bottom: 2px;
                    border-left: 3px solid $grid-row-selected-bg;
                    overflow-y: hidden;
                    transform: rotateX(180deg);
                    //position: relative;
                    //top: -7px;
                    margin-top: -7px;

                    #table-scrollable {
                        transform: rotateX(180deg);
                    }

                    &::-webkit-scrollbar {
                        height: 5px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        border-radius: 2px;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: $gray-blue;
                        border-radius: 2px;
                    }
                }

                .table-border-right {
                    position: absolute;
                    height: calc(100% - 12px);
                    width: 1px;
                    right: 0px;
                    top: 0;
                    background: rgba(255, 255, 255, 0.45);
                }
                .table-scrollable {
                    border-right-width: 0;
                    border-left-width: 0;
                }

                .table-fixed {
                    border-right-width: 0;
                }

                .bottom-row {
                    text-align: right;
                    font-weight: 500;
                    height: 50px;

                    .border-right {
                        border-right: 1px solid $grid-row-selected-bg;
                    }

                    .border-bottom {
                        border-bottom: 1px solid $grid-row-selected-bg;
                    }
                }
            `}</style>
        </form>
    )
}
