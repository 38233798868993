export function calcColor(colorStart: string, colorEnd: string, percent: number) {
    const start = hexToRgb(colorStart)
    const end = hexToRgb(colorEnd)
    const color = {
        r: Math.round(start.r * (1 - percent) + end.r * percent),
        g: Math.round(start.g * (1 - percent) + end.g * percent),
        b: Math.round(start.b * (1 - percent) + end.b * percent),
    }
    // console.log('color', color, rgbToHex(color.r, color.g, color.b))
    return rgbToHex(color.r, color.g, color.b)
}
function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null
}
function componentToHex(c: number) {
    const hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
}

function rgbToHex(r: number, g: number, b: number) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}
