import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import download from '../icons/download.svg'

type Props = {
    downloadPDF: any
    downloadCSV: any
    isAdmin?: boolean
    setActiveTab?: (string) => void
}

export const DownloadActions = (props: Props) => {
    const { downloadPDF, downloadCSV } = props
    const [showActions, setShowActions] = useState(false)
    const toggleActions = useCallback(() => {
        setShowActions(!showActions)
    }, [showActions])
    const hideActions = useCallback(() => setShowActions(false), [])

    return (
        <div className={'select-wrapper-quote'} onMouseLeave={() => hideActions()}>
            <button className="btn dropdown-toggle" onClick={toggleActions}>
                <span>
                    <img src={download} />
                </span>
                Download
            </button>
            {showActions && (
                <div className={'actions-wrapper-quote'}>
                    <div className="actions-wrapper__item">
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                e.stopPropagation()

                                downloadPDF()
                                hideActions()
                            }}
                        >
                            PDF
                        </a>
                    </div>

                    <div className="actions-wrapper__item">
                        <a
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault()
                                e.stopPropagation()

                                downloadCSV()
                                hideActions()
                            }}
                        >
                            CSV
                        </a>
                    </div>
                </div>
            )}
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';

                .select-wrapper-quote {
                    //left: 370px;
                    position: relative;
                    //top: -7px;
                    border: 0 !important;
                    box-shadow: none;
                    .btn {
                        border: 0 !important;
                        box-shadow: none;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 10px 12px;
                        gap: 8px;
                        height: 36px;
                        border-radius: 4px;
                        cursor: pointer;
                        width: 140px;
                        color: white;
                        background: #4486ff;
                        margin-right: 10px;
                        img {
                            width: 16px;
                            height: 16px;
                            margin: 0;
                        }
                    }

                    .actions-wrapper-quote {
                        border: 0 !important;
                        box-shadow: none;
                        position: absolute;
                        background: #4486ff;
                        color: white;
                        width: 140px;
                        z-index: 1000000;
                        cursor: pointer;
                        .actions-wrapper__item {
                            border: 0 !important;
                            box-shadow: none;
                            color: white;
                            font-size: 15px;
                            cursor: pointer;

                            // transition: all linear 0.2s;
                            a {
                                padding: 10px;
                                display: block;
                                margin: 5px;
                            }
                            &:hover {
                                background: $border;
                            }
                        }
                    }
                }
            `}</style>
        </div>
    )
}
